import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import {
  saveIncidentWitness,
  generateIncidentWitness,
} from "actions/incidentActions";
import { getPublicUserToIncident as getPublicUserBasics } from "actions/userActions";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import DayPicker from "lib/DayPicker";

import { SpaceBlock } from "../AnalysisForm.style";
import { TwoInputWrapper } from "../AnalysisForm.style";

import { getFullResidencePlace } from "../common/index";
import saveBlobFile from "components/saveBlobFile";

import AppComponent from "components/AppComponent";
import SimpleAcceptModalDump from "components/Modal/WitnessesForm/Form";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;

const LocalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

StyledModal.displayName = "Modal";

class SimpleAcceptModal extends AppComponent {
  constructor(props) {
    super(props);

    this.getUser = this.getUser.bind(this);
    this.submit = this.submit.bind(this);
  }

  getUser(id, form) {
    const { dispatch } = this.props;
    getPublicUserBasics(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let user = action.payload.data.user;
        let keys = Object.keys(user);
        let { date_birth, birth_place, id_document_number } = user;
        let workplace_localization = user.division_name
          ? user.division_name
          : user.localization_name
          ? `${user.localization_name}${
              user.section_name ? `/${user.section_name}` : ""
            }`
          : null;

        form.change("birth_date", date_birth);
        form.change("birth_place", birth_place);
        form.change("residence_fulladdress", getFullResidencePlace(user));
        form.change("id_document_number", id_document_number);
        form.change("workplace_localization", workplace_localization);

        form.change("pesel", user.pesel);
        form.change("workplace_name", user.workplace_name);
      })
    );
  }

  submit(values, { closeModal }) {
    const { incident_id, dispatch } = this.props;
    let { file, ...data } = values;
    if (!data.witness_date) {
      data.witness_date = moment().format("YYYY-MM-DD");
    }
    saveIncidentWitness({
      incident_id,
      data,
    })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let { witness } = action.payload.data;
        let { id } = witness;
        if (file) {
          generateIncidentWitness({ incident_id, witness_id: id })(
            dispatch
          ).then((res) => {
            if (!res.error) {
              const file = res.payload.data;
              const fileName = `świadek_${witness.id}.pdf`;
              saveBlobFile(file, fileName);
              closeModal();
            }
          });
        } else {
          closeModal();
        }
      })
    );
  }

  render() {
    let { getUser, ...props } = this.props;

    return (
      <SimpleAcceptModalDump
        {...props}
        getUser={this.getUser}
        submit={this.submit}
      />
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
  workplace: store.workplace,
  users: store.users,
}))(SimpleAcceptModal);

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
