import { combineReducers } from "redux";

import account from "./accountReducers";
import accident_owtw from "./accidentOWTWReducer";
import audit from "./auditReducers";
import business from "./businessReducers";
import configuration from "./configurationReducers";
import characteristic from "./characteristicReducers";
import certificates from "./certificateReducers";
import dashboard from "./dashboardReducers";
import dictionary from "./dictionaryReducers";
import localization from "./localizationReducers";
import section from "./sectionReducers";
import mail from "./mailReducers";
import me from "./meReducers";
import modules from "./moduleReducers";
import message from "./messageReducers";
import notification from "./notificationReducers";
import osh from "./oshReducer";
import qe from "./qeReducer";
import page from "./pageReducers";
import responsibility from "./responsibilityReducers";
import riskAssessment from "./riskAssessmentReducers";
import users from "./usersReducers";
import superadmin from "./superAdminReducers";
import incident from "./incidentReducers";
import task from "./taskReducers";
import workplace from "./workplaceReducers";

export default combineReducers({
  account,
  accident_owtw,
  audit,
  business,
  certificates,
  configuration,
  characteristic,
  dashboard,
  dictionary,
  incident,
  localization,
  mail,
  me,
  message,
  modules,
  notification,
  osh,
  qe,
  page,
  responsibility,
  riskAssessment,
  section,
  superadmin,
  task,
  users,
  workplace,
});
