import React from "react";
import { connect } from "react-redux";

import { StyledInputBox } from "style/styleComponents/Form/input";
import { ButtonWrapper } from "components/ListComponents";
import { Button } from "components/Buttons";
import ModalResponsibility, {
  modalResponsibilityState,
} from "components/Modal/ResponsibilityUser";

import AppComponent from "components/AppComponent";
import { forwardIncident } from "actions/incidentActions";
import { setMessage } from "components/AppComponent";
import {
  getSearchedUsers,
  getUserByWorkplaceByUser,
} from "actions/userActions";
import { getResponsibleUser } from "actions/incidentActions";

class ForwardIncident extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        modalResponsibilityState,
      },
    };
    this.handleUserAssign = this.handleUserAssign.bind(this);
    this.setWorkplace = this.setWorkplace.bind(this);
    this.handleUserChoose = this.handleUserChoose.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    const { incident_id } = match.params;
    getResponsibleUser(incident_id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let { workplace_id, responsible_user_id, name } =
          action.payload.data.user || {};
        if (workplace_id) {
          getUserByWorkplaceByUser(workplace_id)(this.props.dispatch).then(
            this.handleServerResponse({}, () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  selectedWorkplace: workplace_id,
                  selectedUser: responsible_user_id,
                },
              });
            })
          );
        }
      })
    );
  }

  handleUserAssign() {
    const {
      incident: {
        singleData: { id: incident_id },
      },
      users: { data: usersData },
      match,
      dispatch,
    } = this.props;
    const { selectedUser: users_id } = this.state.modal;
    forwardIncident(incident_id, { users_id })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let {
          responsible_user_workplace_id,
          responsible_user_name,
          responsible_user_id,
        } = action.payload.data.incident || {};

        this.setState({
          modal: {
            ...this.state.modal,
            responsible_user_workplace_id,
            responsible_user_name,
            responsible_user_id,
          },
        });
        // if (!action.error) {
        // mutators.setField("responsible_user_id")(users_id);
        // const user =
        //   usersData[usersData.findIndex((item) => item.id === users_id)];
        // this.setState({
        //   selectedUserName: user.name,
        //   message: setMessage(action.payload.data.message, "success"),
        // });
        // }
      })
    );
  }

  setWorkplace(value) {
    this.setState(
      {
        modal: {
          ...this.state.modal,
          selectedWorkplace: value,
          selectedUser: null,
        },
      },
      () => {
        getUserByWorkplaceByUser(value)(this.props.dispatch).then(
          this.handleServerResponse()
        );
      }
    );
  }

  handleUserChoose(value) {
    this.setState({ modal: { ...this.state.modal, selectedUser: value } });
  }

  render() {
    let { modal } = this.state;
    const {
      incident: {
        singleData: {
          responsible_user_workplace_id,
          responsible_user_name,
          // stage,
          // type_id,
          // ...incidentData
        },
        responsible_user: { name: user_name },
        permission,
      },
      users: { data: usersData },
      workplace: { data: workplaceData },
    } = this.props;
    return (
      <>
        <StyledInputBox
          label="Osoba odpowiedzialna za analizę zdarzenia"
          disabled={true}
          value={user_name}
        />
        <ButtonWrapper>
          <Button type="button" onClick={this.openModal("modal")}>
            Przekaż zadanie
          </Button>
        </ButtonWrapper>
        <ModalResponsibility
          modal={modal}
          accept={this.handleUserAssign}
          closeModal={this.closeModal("modal")}
          workplaceData={workplaceData}
          usersData={usersData}
          setWorkplace={this.setWorkplace}
          setUser={this.handleUserChoose}
        />
      </>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
  workplace: store.workplace,
  users: store.users,
}))(ForwardIncident);
