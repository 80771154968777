import React from "react";
import { connect } from "react-redux";

import { RACharacteristic } from "../EntryAddEdit/WorkplaceCharacteristic";
import { setUrls } from "./_staticData";

class ArchivedDRACharacteristic extends RACharacteristic {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls(), readOnlyState: true };
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
  workers: store.riskAssessment.raWorkers,
  permission: store.riskAssessment.permission,
  section: store.section,
  localization: store.localization,
  workplaces: store.workplace.data,
}))(ArchivedDRACharacteristic);
