export default function reducer(
  state = {
    data: [],
    singleData: {},
    fetching: false,
    fetched: true,
  },
  action
) {
  switch (action.type) {
    case "GET_BUSINESS_REQUEST": {
      return { ...state, fetching: true };
    }
    case "GET_BUSINESS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.business,
      };
    }
    case "GET_BUSINESS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_BUSINESSES_REQUEST": {
      return { ...state, fetching: true };
    }
    case "GET_BUSINESSES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.businesses,
      };
    }
    case "GET_BUSINESSES_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "DELETE_BUSINESSES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data.filter(
          (item) => item.id != action.payload.data.business.id
        ),
      };
    }
    case "EDIT_BUSINESSES_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "EDIT_BUSINESSES_SUCCESS": {
      const singleData = action.payload.data.business;

      const newData = [...state.data];
      const index = newData.findIndex((item) => item.id == singleData.id);
      if (index > -1) {
        newData[index] = { ...newData[index], active: singleData.active };
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData,
        data: newData,
      };
    }
    case "EDIT_BUSINESSES_ERROR": {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case "SAVE_BUSINESSES_LOGO_SUCCESS": {
      const { logo_url } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {
          ...state.singleData,
          logo: null,
          logo_url: null,
        },
      };
    }
    case "DELETE_LOCALIZATIONS_LOGO_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {
          ...state.singleData,
          logo: null,
          logo_url: null,
        },
      };
    }
  }

  return state;
}
