import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import moment from "moment";
import "moment/locale/pl";
import { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG, LABELS } from "./locale_pl";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import {
  StyledInputMessage,
  StyledLabel,
} from "../../style/styleComponents/Form/input";
import "moment/locale/pl";
import { Button } from "components/Buttons";
const StyledSelectWrapper = styled.div`
  max-width: 80%;
  margin: 0 -5px;
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled.select`
  border-radius: 2px;
  margin: 0 5px 5px;
  padding: 2px 3px;

  & option {
  }
`;

const DayPickerWrapper = styled.div`
  display: flex;

  & .fa {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 30px;
    flex-shrink: 1;
  }
  & .DayPickerInput {
    display: flex;
    flex-direction: column;
    flex-basis: ${(props) => (props.fullWidth ? "100%" : "auto")};
  }
  & .DayPickerInput > input,
  input.disabled {
    border: 1px solid ${(props) => props.theme.tableBorderColor};
    line-height: 20px;
    padding: 6px 12px;
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  }
`;

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

function YearMonthForm({ date, localeUtils, onChange }) {
  const years = [];
  for (
    let i = fromMonth.getFullYear() - 100;
    i <= toMonth.getFullYear();
    i += 1
  ) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <StyledSelectWrapper>
        <StyledSelect
          name="month"
          onChange={handleChange}
          value={date.getMonth()}
        >
          {MONTHS.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </StyledSelect>
        <StyledSelect
          name="year"
          onChange={handleChange}
          value={date.getFullYear()}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </StyledSelect>
      </StyledSelectWrapper>
    </form>
  );
}

export default class DayPicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.updateInputRef = this.updateInputRef.bind(this);
    this.state = {
      // selectedDay: props.selectedDay ? props.selectedDay : formatDate(Date.now(), "YYYY-MM-DD"),
      isDisabled: false,
      month: new Date(),
    };
  }

  componentDidMount() {
    let { selectedDay } = this.props;
    if (this.state.selectedDay != selectedDay) {
      this.setState({
        selectedDay: selectedDay
          ? moment(selectedDay).format("YYYY-MM-DD")
          : undefined,
      });
    }
  }

  componentDidUpdate(prevProps) {
    let { selectedDay } = this.props;
    if (selectedDay == prevProps.selectedDay) {
      let newDate = selectedDay
        ? moment(selectedDay).format("YYYY-MM-DD")
        : null;

      if (newDate !== this.state.selectedDay) {
        this.setState({
          selectedDay: newDate,
        });
      }
    }
  }

  handleDayChange(selectedDay, modifiers = {}) {
    const { dateString } = this.props;
    const result = dateString
      ? selectedDay
      : selectedDay
      ? moment(selectedDay).format("YYYY-MM-DD")
      : null;
    this.props.handleChange(result);

    this.setState({
      selectedDay,
      isDisabled: modifiers.disabled === true,
    });
  }

  handleYearMonthChange(month) {
    this.setState({ month });
  }
  updateInputRef = (ref) => {
    if (ref) {
      // eslint-disable-next-line
      ref.input.focus = function () {};
    }
  };
  render() {
    const { selectedDay } = this.state;
    const {
      icon,
      fullWidth,
      disabled,
      error,
      helperText,
      onBlur,
      onFocus,
      canChangeYear,
      label,
      placeholder,
      required,
    } = this.props;
    return (
      <div>
        {label && <StyledLabel>{label}</StyledLabel>}
        <DayPickerWrapper
          fullWidth={fullWidth}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {disabled ? (
            <input className="disabled" disabled placeholder={selectedDay} />
          ) : (
            <>
              <DayPickerInput
                ref={this.updateInputRef}
                value={selectedDay}
                onDayChange={this.handleDayChange}
                formatDate={formatDate}
                format={"YYYY-MM-DD"}
                error={error}
                readOnly
                placeholder={
                  placeholder !== undefined
                    ? placeholder
                    : `${formatDate(new Date(), "YYYY-MM-DD")}`
                }
                inputProps={{ readOnly: true }}
                dayPickerProps={{
                  weekStartsOn: 1,
                  month: this.state.month,
                  selectedDays: [selectedDay],
                  todayButton: "Dzisiaj",
                  weekdaysShort: WEEKDAYS_SHORT,
                  months: MONTHS,
                  localeUtils: MomentLocaleUtils,
                  locale: "pl",
                  captionElement: canChangeYear
                    ? ({ date, localeUtils }) => (
                        <YearMonthForm
                          date={date}
                          localeUtils={localeUtils}
                          locale="pl"
                          onChange={this.handleYearMonthChange}
                        />
                      )
                    : undefined,
                }}
              />
              {required}
              {required ? null : (
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={() => {
                    this.handleDayChange(null);
                  }}
                >
                  X
                </Button>
              )}
            </>
          )}

          {icon && (
            <div style={{ padding: "0 5px" }} className="fa fa-calendar"></div>
          )}
        </DayPickerWrapper>
        <StyledInputMessage>{helperText}</StyledInputMessage>
      </div>
    );
  }
}

DayPicker.propTypes = {
  dateString: PropTypes.bool,
  icon: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};
