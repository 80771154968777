const createURLParams = function (data) {
  let params = new URLSearchParams();
  let keys = Object.keys(data);
  for (let k of keys) {
    params.set(k, data[k]);
  }

  return params;
};

const createURLParamsString = function (data = {}) {
  let params = createURLParams(data);
  return params ? `?${params}` : "";
};

module.exports = {
  createURLParams,
  createURLParamsString,
};
