import React, { Fragment } from "react";
import styled from "styled-components";
import { Field, FormSpy } from "react-final-form";
import DayPicker from "../../lib/DayPicker";

import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
} from "../../style/styleComponents/Form/input";
import AppComponent from "../AppComponent";
import validator from "../Validation/";
import Select from "../Select";
import { LeftPadding } from "components/Wrappers";
import { BoxesWrapper } from "./_stylesComponents";
import { Button } from "components/Buttons";

let AddressBoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 34px;
  line-height: 34px;
`;

export default class UserAccountsDetailData extends AppComponent {
  constructor(props) {
    super(props);

    this.copyAddress = this.copyAddress.bind(this);
  }

  copyAddress({ values, form }) {
    return () => {
      form.change("correspondence_postcode", values.residence_postcode);
      form.change("correspondence_place", values.residence_place);
      form.change("correspondence_street", values.residence_street);
      form.change(
        "correspondence_building_number",
        values.residence_building_number
      );
      form.change(
        "correspondence_apartament_number",
        values.residence_apartament_number
      );
    };
  }

  render() {
    let { readOnly = false } = this.props;
    return (
      <Fragment>
        <h3>Dane szczegółowe</h3>
        <BoxesWrapper>
          <div>
            <Field
              name="pesel"
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  disabled={readOnly}
                  label="PESEL"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                />
              )}
            />
          </div>
        </BoxesWrapper>
        <BoxesWrapper>
          <div>
            <Field
              name="id_document_name"
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  disabled={readOnly}
                  label="Rodzaj dokumentu potwierdzającego tożsamość"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                />
              )}
            />
          </div>
          <div>
            <Field
              name="id_document_number"
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  disabled={readOnly}
                  label="Numer dokumentu potwierdzającego tożsamość"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                />
              )}
            />
          </div>
        </BoxesWrapper>
        <BoxesWrapper>
          <div>
            <Field
              name="sex_id"
              render={({ input, meta, ...rest }) => (
                <Select
                  disabled={readOnly}
                  label="Płeć"
                  fullWidth
                  title="Brak"
                  propValue={"id"}
                  data={[
                    { id: 1, name: "kobieta" },
                    { id: 2, name: "mężczyzna" },
                  ]}
                  selected={input.value}
                  handleChange={(data) => {
                    input.onChange(data);
                  }}
                />
              )}
            />
            <Field
              name="date_birth"
              render={({ input, meta, ...rest }) => (
                <DayPicker
                  canChangeYear
                  label="Data urodzenia"
                  fullWidth
                  disabled={readOnly}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  selectedDay={input.value}
                  value={input.value}
                  handleChange={(value) => {
                    input.onChange(value);
                  }}
                />
              )}
            />
            <Field
              name="birth_place"
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  disabled={readOnly}
                  label="Miejsce urodzenia"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                />
              )}
            />
          </div>
        </BoxesWrapper>
        <BoxesWrapper>
          <div>
            <AddressBoxTitle>Adres zamieszkania</AddressBoxTitle>
            <LeftPadding>
              <Field
                name="residence_postcode"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Kod pocztowy"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="residence_place"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Miejscowość"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="residence_street"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Ulica"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="residence_building_number"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Numer domu"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="residence_apartament_number"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Nr mieszkania"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
            </LeftPadding>
          </div>
          <div>
            <AddressBoxTitle>
              <span>Adres do korespondencji</span>
              <FormSpy subscribe={{ form: true, values: true }}>
                {({ values, form }) => {
                  return (
                    <Button
                      disabled={readOnly}
                      onClick={this.copyAddress({ values, form })}
                    >
                      Kopiuj adres zamieszkania
                    </Button>
                  );
                }}
              </FormSpy>
            </AddressBoxTitle>
            <LeftPadding>
              <Field
                name="correspondence_postcode"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Kod pocztowy"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="correspondence_place"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Miejscowość"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="correspondence_street"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Ulica"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="correspondence_building_number"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Numer domu"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Field
                name="correspondence_apartament_number"
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    label="Nr mieszkania"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
            </LeftPadding>
          </div>
        </BoxesWrapper>
        <BoxesWrapper>
          <div>
            <Field
              name="date_employment"
              render={({ input, meta, ...rest }) => (
                <DayPicker
                  placeholder={null}
                  canChangeYear
                  label="Data zatrudnienia"
                  fullWidth
                  disabled={readOnly}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  selectedDay={input.value}
                  handleChange={(value) => {
                    input.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </BoxesWrapper>
      </Fragment>
    );
  }
}
