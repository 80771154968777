export function getIncidents() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENTS_REQUEST",
        "GET_INCIDENTS_SUCCESS",
        "GET_INCIDENTS_ERROR",
      ],
      payload: {
        request: {
          url: "/incident",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentToRegister(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_REQUEST",
        "GET_INCIDENT_SUCCESS",
        "GET_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/get-to-register/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentToAnalysis(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_REQUEST",
        "GET_INCIDENT_SUCCESS",
        "GET_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/get-to-analysis/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addIncident() {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_INCIDENT_REQUEST",
        "ADD_INCIDENT_SUCCESS",
        "ADD_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: "/incident/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function updateField(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "UPDATE_FIELD_INCIDENT_REQUEST",
        "UPDATE_FIELD_INCIDENT_SUCCESS",
        "UPDATE_FIELD_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/update-field/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function register(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_INCIDENT_REQUEST",
        "REGISTER_INCIDENT_SUCCESS",
        "REGISTER_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/register/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerSave(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_INCIDENT_REQUEST",
        "REGISTER_INCIDENT_SUCCESS",
        "REGISTER_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/register-save/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function analysis(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ANALYSIS_INCIDENT_REQUEST",
        "ANALYSIS_INCIDENT_SUCCESS",
        "ANALYSIS_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/analysis/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function saveAnalysisData(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_ANALYSIS_DATA_INCIDENT_REQUEST",
        "SAVE_ANALYSIS_DATA_INCIDENT_SUCCESS",
        "SAVE_ANALYSIS_DATA_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/analysis/${incident_id}/save-only`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function setNextStage(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "NEXT_STAGE_INCIDENT_REQUEST",
        "NEXT_STAGE_INCIDENT_SUCCESS",
        "NEXT_STAGE_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/set-next-stage/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function acceptAnalysis(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "NEXT_STAGE_INCIDENT_REQUEST",
        "NEXT_STAGE_INCIDENT_SUCCESS",
        "NEXT_STAGE_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/set-next-stage/${incident_id}/analysis`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function acceptTask(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "NEXT_STAGE_INCIDENT_REQUEST",
        "NEXT_STAGE_INCIDENT_SUCCESS",
        "NEXT_STAGE_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/set-next-stage/${incident_id}/task`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function acceptAttachments(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "NEXT_STAGE_INCIDENT_REQUEST",
        "NEXT_STAGE_INCIDENT_SUCCESS",
        "NEXT_STAGE_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/set-next-stage/${incident_id}/attachment`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAttachments(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_ATTACHMENTS_REQUEST",
        "GET_INCIDENT_ATTACHMENTS_SUCCESS",
        "GET_INCIDENT_ATTACHMENTS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/attachment/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRegisterAttachments(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_ATTACHMENTS_REQUEST",
        "GET_INCIDENT_ATTACHMENTS_SUCCESS",
        "GET_INCIDENT_ATTACHMENTS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/attachment/${incident_id}/register`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addAttachment(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_INCIDENT_ATTACHMENT_REQUEST",
        "ADD_INCIDENT_ATTACHMENT_SUCCESS",
        "ADD_INCIDENT_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/attachment/${incident_id}/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function addAttachmentRegister(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_INCIDENT_ATTACHMENT_REQUEST",
        "ADD_INCIDENT_ATTACHMENT_SUCCESS",
        "ADD_INCIDENT_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/attachment/${incident_id}/register/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteAttachment(incident_id, attachment_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_INCIDENT_ATTACHMENT_REQUEST",
        "DELETE_INCIDENT_ATTACHMENT_SUCCESS",
        "DELETE_INCIDENT_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/attachment/${incident_id}/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteAttachmentRegister(incident_id, attachment_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_INCIDENT_ATTACHMENT_REQUEST",
        "DELETE_INCIDENT_ATTACHMENT_SUCCESS",
        "DELETE_INCIDENT_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/attachment/${incident_id}/register/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function changeIncidentPersonalStatus(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "CHANGE_INCIDENT_PERSONAL_STATUS_REQUEST",
        "CHANGE_INCIDENT_PERSONAL_STATUS_SUCCESS",
        "CHANGE_INCIDENT_PERSONAL_STATUS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/change-incident-personal-status/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentNotification(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATIONS_REQUEST",
        "GET_NOTIFICATIONS_SUCCESS",
        "GET_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function registerNotifications(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_NOTIFICATIONS_REQUEST",
        "REGISTER_NOTIFICATIONS_SUCCESS",
        "REGISTER_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getProgresses(category) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_PROGRESS_REQUEST",
        "GET_INCIDENT_PROGRESS_SUCCESS",
        "GET_INCIDENT_PROGRESS_ERROR",
      ],
      payload: {
        request: {
          url: `/progress${category ? `/${category}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentByFilter(filter) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENTS_BY_FILTER_REQUEST",
        "GET_INCIDENTS_BY_FILTER_SUCCESS",
        "GET_INCIDENTS_BY_FILTER_ERROR",
      ],
      payload: {
        request: {
          url: "/incident",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: filter,
        },
      },
    });
}

export function deleteIncidentDraft(draft_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_DRAFT_INCIDENT_REQUEST",
        "DELETE_DRAFT_INCIDENT_SUCCESS",
        "DELETE_DRAFT_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/delete-draft/${draft_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteIncident(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_DRAFT_INCIDENT_REQUEST",
        "DELETE_DRAFT_INCIDENT_SUCCESS",
        "DELETE_DRAFT_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/delete/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function changeIncidentType(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "CHANGE_TYPE_INCIDENT_REQUEST",
        "CHANGE_TYPE_INCIDENT_SUCCESS",
        "CHANGE_TYPE_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/change-type/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function pingIncident() {
  return (dispatch) =>
    dispatch({
      types: [
        "PING_INCIDENT_REQUEST",
        "PING_INCIDENT_SUCCESS",
        "PING_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: "/incident/ping",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function forwardIncident(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "FORWARD_INCIDENT_REQUEST",
        "FORWARD_INCIDENT_SUCCESS",
        "FORWARD_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/forward/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteUserFromRegisterGroup(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_FROM_NOTIFICATION_GROUP_REQUEST",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_SUCCESS",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}/delete-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerNotification(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_NOTIFICATIONS_REQUEST",
        "ADD_NOTIFICATIONS_SUCCESS",
        "ADD_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}/add-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteRegisteredNotification(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_NOTIFICATION_REQUEST",
        "DELETE_USER_NOTIFICATION_SUCCESS",
        "DELETE_USER_NOTIFICATION_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}/delete-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addUserToRegisterGroup(incident_id, user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_USER_TO_NOTIFICATION_GROUP_REQUEST",
        "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS",
        "ADD_USER_TO_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}/add-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: { user_id },
        },
      },
    });
}

export function closeIncident(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "REALIZATION_INCIDENT_REQUEST",
        "REALIZATION_INCIDENT_REQUEST",
        "REALIZATION_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/close/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getHistory(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATION_HISTORY_REQUEST",
        "GET_NOTIFICATION_HISTORY_SUCCESS",
        "GET_NOTIFICATION_HISTORY_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/notification/${incident_id}/history`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function downloadInsert(url, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_INCIDENT_REQUEST",
        "DOWNLOAD_INCIDENT_SUCCESS",
        "DOWNLOAD_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            index,
          },
        },
      },
    });
}

export function getIncidentWitness({ incident_id }) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_WITNESS_REQUEST",
        "GET_INCIDENT_WITNESS_SUCCESS",
        "GET_INCIDENT_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/get-incident-witness/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addIncidentWitness({ incident_id }) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_INCIDENT_WITNESS_REQUEST",
        "ADD_INCIDENT_WITNESS_SUCCESS",
        "ADD_INCIDENT_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/add-incident-witness/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function removeIncidentWitness({ incident_id, id }) {
  return (dispatch) =>
    dispatch({
      types: [
        "REMOVE_INCIDENT_WITNESS_REQUEST",
        "REMOVE_INCIDENT_WITNESS_SUCCESS",
        "REMOVE_INCIDENT_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/remove-incident-witness/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            id,
          },
        },
      },
    });
}

export function saveVictimExplains({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_VICTIM_EXPLAIN_REQUEST",
        "SAVE_VICTIM_EXPLAIN_SUCCESS",
        "SAVE_VICTIM_EXPLAIN_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/victim-explains/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function generateVictimExplains({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: [
        "GENERATE_VICTIM_EXPLAIN_REQUEST",
        "GENERATE_VICTIM_EXPLAIN_SUCCESS",
        "GENERATE_VICTIM_EXPLAIN_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/generate-victim-explains/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
          responseType: "blob",
        },
      },
    });
}

export function updateIncidentWitness({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: [
        "UPDATE_INCIDENT_WITNESS_REQUEST",
        "UPDATE_INCIDENT_WITNESS_SUCCESS",
        "UPDATE_INCIDENT_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/update-incident-witness/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveIncidentWitness({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_INCIDENT_WITNESS_REQUEST",
        "SAVE_INCIDENT_WITNESS_SUCCESS",
        "SAVE_INCIDENT_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/save-incident-witness/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function generateIncidentWitness({ incident_id, witness_id }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/incident/generate-incident-witness/${incident_id}/${witness_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function deleteIncidentWitness({ incident_id, id }) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_INCIDENT_WITNESS_REQUEST",
        "DELETE_INCIDENT_WITNESS_SUCCESS",
        "DELETE_INCIDENT_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/delete-incident-witness/${incident_id}/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function generateProtocol({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          data,
          url: `/incident/generate-protocol/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}
export function saveStatisticCard({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          data,
          url: `/incident/save-statistic-card/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function printStatisticCard({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          data,
          url: `/incident/print/statistic-card/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function generateStatisticCard({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          data,
          url: `/incident/generate-statistic-card/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function generateAccidentCard({ incident_id, data }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          data,
          url: `/incident/generate-accident-card/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}
export function getState(incident_id) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/incident/get-stage/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function UpdateGus(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["UPDATE_GUS_REQUEST", "UPDATE_GUS_SUCCESS", "UPDATE_GUS_ERROR"],
      payload: {
        request: {
          data,
          url: `/incident/update-gus/${incident_id}`,
          method: "post",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function UpdateGusInList(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "UPDATE_GUS_IN_LIST_REQUEST",
        "UPDATE_GUS_IN_LIST_SUCCESS",
        "UPDATE_GUS_IN_LIST_ERROR",
      ],
      payload: {
        request: {
          data,
          url: `/incident/update-gus/${incident_id}`,
          method: "post",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveIncidentPerson(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "INCIDENT_HAS_PERSON_REQUEST",
        "INCIDENT_HAS_PERSON_SUCCESS",
        "INCIDENT_HAS_PERSON_ERROR",
      ],
      payload: {
        request: {
          data,
          url: `/incident/update-incident-has-person/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveIncidentPersonUser(incident_id, user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "INCIDENT_HAS_PERSON_REQUEST",
        "INCIDENT_HAS_PERSON_SUCCESS",
        "INCIDENT_HAS_PERSON_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/update-incident-has-person-user/${incident_id}/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function printListPDF(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/incident/print/${incident_id}`,
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}
export function printRegisterIncidentList(data) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/incident/print/list`,
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function getResponsibleUser(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RESPONSIBLE_USER_INCIDENT_REQUEST",
        "GET_RESPONSIBLE_USER_INCIDENT_SUCCESS",
        "GET_RESPONSIBLE_USER_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/get-responsible-user/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addTaskAttachment({ incident_id, task_id }, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_TASK_ATTACHMENT_REQUEST",
        "ADD_TASK_ATTACHMENT_SUCCESS",
        "ADD_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/${incident_id}/task/${task_id}/attachment/add`,

          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteTaskAttachment({ incident_id, task_id }, attachment_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_TASK_ATTACHMENT_REQUEST",
        "DELETE_TASK_ATTACHMENT_SUCCESS",
        "DELETE_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/incident/${incident_id}/task/${task_id}/attachment/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function clearIncidentReducer() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_INCIDENT",
      payload: {},
    });
}
