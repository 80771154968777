import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";
import Box from "components/Box";
import {
  getAuditQuestions,
  commentOpeningAction,
  downloadAuditQuestionAttachment,
  getAudit,
  saveAuditQuestionsAnswers,
  addAuditQuestionAttachment,
  addAuditQuestionAttachmentLocal,
  addAuditNewQuestionLocal,
  deleteAuditQuestionAttachment,
  deleteAuditQuestionAttachmentLocal,
  deleteAuditCustomQuestion,
  deleteAuditCustomQuestionLocal,
  resetAuditNewQuestionLocal,
} from "actions/auditActions";

import AppComponent from "components/AppComponent";
import { ButtonWrapper } from "components/ListComponents";
import { ButtonWithIcon } from "components/ListComponents";
import dataToFormData from "components/dataToFormData";
import ImageModal, {
  ImageViewModalSchema,
} from "components/Modal/ImageViewModal";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import Modal from "components/Modal/SimpleAccept";
import saveBlobFile from "components/saveBlobFile";
import PDFModal, { PDFViewModalSchema } from "components/Modal/PDFViewModal";
import QuestionLine from "./core/_questionLine";
import QuestionForm from "components/QuestionsForm/core/index.js";

import {
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInputBox,
} from "style/styleComponents/Form/input";

import Core from "./core/index.js";
class AuditQuestions extends Core {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      pdfModal: { ...PDFViewModalSchema, downloadData: {} },
      imageModal: { ...ImageViewModalSchema, downloadData: {} },
      modalDelete: {
        open: false,
        audit_question_id: null,
        attachment_id: null,
        name: null,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getInitialData = this.getInitialData.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleSubmitAttachment = this.handleSubmitAttachment.bind(this);
    this.handleSubmitAttachmentLocal = this.handleSubmitAttachmentLocal.bind(
      this
    );
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.handleDeleteAttachmentLocal = this.handleDeleteAttachmentLocal.bind(
      this
    );
    this.deleteCustomQuestion = this.deleteCustomQuestion.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.handleDeleteAttachmentRequest = this.handleDeleteAttachmentRequest.bind(
      this
    );
    this.closeModal = this.closeModal.bind(this);
    this.openPDFViewModal = this.openPDFViewModal.bind(this);
    this.handleCommentFieldOpening = this.handleCommentFieldOpening.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      audit: { singleData: auditData },
    } = this.props;
    const { audit_id } = match.params;

    if (!Object.keys(auditData).length) {
      getAudit(audit_id)(dispatch).then(this.handleServerResponse());
    }

    getAuditQuestions(audit_id)(dispatch).then(this.handleServerResponse());
  }

  openPDFViewModal(downloadData) {
    return () => {
      const { match } = this.props;
      const { audit_id } = match.params;
      const { file } = downloadData;
      const url = `/uploads/audit/${audit_id}/attachment/${file}`;

      const modal_name = (() => {
        switch (true) {
          case /\.pdf$/.test(file): {
            return "pdfModal";
          }
          case /\.(jpg|png|svg)$/.test(file): {
            return "imageModal";
          }
          default:
            return false;
        }
      })();
      if (modal_name) {
        this.setState({
          [modal_name]: {
            ...this.state.pdfModal,
            open: true,
            name: file,
            simpleUrl: url,
            file: `/api${url}`,
            downloadData,
          },
        });
      }
    };
  }

  closeViewModal(modalName) {
    this.setState({ [modalName]: { ...PDFViewModalSchema, downloadData: {} } });
  }

  handleSubmit(values) {
    const {
      dispatch,
      match,
      history,
      audit: { newQuestionsData, questionsData },
    } = this.props;
    const { audit_id } = match.params;

    let newAttachments = Object.keys(values)
      .filter((item) => {
        return /question_new_[0-9]{1,}_answer_id/.test(item);
      })
      .reduce((obj, item) => {
        let index = item.replace("question_new_", "").replace("_answer_id", "");
        let newQuestionFullData = newQuestionsData[index];
        if (newQuestionFullData.attachments) {
          newQuestionFullData.attachments.forEach((item, subindex) => {
            obj[`question_new_${index}_attachment_${subindex}`] = item.fileData;
          });
        }
        return obj;
      }, {});

    let fullData = { ...values, ...newAttachments };

    const formData = dataToFormData(fullData);

    this.props.handleSubmit(formData);
  }

  getInitialData(questionsData) {
    const that = this;
    let result = questionsData.reduce((obj, item) => {
      if (item.questions) {
        return { ...obj, ...that.getInitialData(item.questions) };
      } else {
        obj[`question_${item.id}_answer_id`] =
          item.dictionary_audit_question_answer_id;
        obj[`question_${item.id}_comment`] = item.comment;

        if (!item.audit_question_id) {
          obj[`question_${item.id}_name`] = item.name;
        }
      }
      return obj;
    }, {});
    return result;
  }

  handleDownload(file, name, attachmentIndex, index, subIndex) {
    return () => {
      const {
        dispatch,
        audit: {
          singleData: { symbol },
        },
        match,
      } = this.props;
      const { audit_id } = match.params;
      const url = `/uploads/audit/${audit_id}/attachment/${file}`;
      const ext = file.split(".").pop();
      downloadAuditQuestionAttachment(
        url,
        attachmentIndex,
        index,
        subIndex
      )(this.props.dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `${symbol}-${name}.${ext}`;
          saveBlobFile(file, fileName);
        }
      });
    };
  }

  handleSubmitAttachment(audit_question_id) {
    return (values) => {
      const { dispatch, match } = this.props;
      const { audit_id } = match.params;
      const formData = dataToFormData({ ...values, audit_question_id });
      return addAuditQuestionAttachment(
        audit_id,
        audit_question_id,
        formData
      )(dispatch).then(this.handleServerResponse());
    };
  }

  handleSubmitAttachmentLocal(index) {
    const { dispatch } = this.props;
    return (values) => {
      return addAuditQuestionAttachmentLocal({
        index,
        file: values.question_null_attachment,
      })(dispatch);
    };
  }

  handleDeleteAttachmentRequest(audit_question_id, attachment_id, name) {
    return () => {
      this.setState({
        modalDelete: {
          ...this.state.modalDelete,
          open: true,
          audit_question_id,
          attachment_id,
          name,
        },
      });
    };
  }

  handleDeleteAttachmentLocal(_, itemId, filename, index) {
    const { dispatch } = this.props;
    return (values) => {
      return deleteAuditQuestionAttachmentLocal({
        questionIndex: itemId,
        attachmentIndex: index,
      })(dispatch);
    };
  }

  handleCommentFieldOpening(id, index, subindex) {
    const { dispatch } = this.props;

    return () => {
      // commentOpeningAction
      commentOpeningAction({ custom: id ? false : true, index, subindex })(
        dispatch
      );
    };
  }

  closeModal() {
    this.setState({
      modalDelete: {
        ...this.state.modalDelete,
        open: false,
        audit_question_id: null,
        attachment_id: null,
        name: null,
      },
    });
  }

  handleDeleteAttachment() {
    const { audit_question_id, attachment_id } = this.state.modalDelete;
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    deleteAuditQuestionAttachment(
      audit_id,
      audit_question_id,
      attachment_id
    )(dispatch).then(this.handleServerResponse());
  }

  addNewQuestion() {
    const { dispatch } = this.props;
    addAuditNewQuestionLocal()(dispatch);
  }

  deleteCustomQuestion(audit_question_id) {
    return () => {
      const { dispatch, match } = this.props;
      const { audit_id } = match.params;
      deleteAuditCustomQuestion(audit_id, audit_question_id)(dispatch);
    };
  }

  deleteAuditCustomQuestionLocal(index) {
    return () => {
      const { dispatch } = this.props;
      deleteAuditCustomQuestionLocal(index)(dispatch);
    };
  }

  render() {
    const {
      singleData,
      questionsData,
      newQuestionsData,
      answerData,
      permission,
      attachmentFetching,
      SideMenu,
      match,
      // readOnlyUrl: `/audit/task/${audit_id}`
      readOnlyUrl = `/audit/task/${match.params.audit_id}`,
    } = this.props;
    const { pdfModal, imageModal } = this.state;
    const { message } = this.state;
    const initialData = this.getInitialData(questionsData);
    const readOnly = !permission[18];

    return (
      <Fragment>
        <Box title={"Pytania audytowe"}>
          <Form
            onSubmit={this.handleSubmit}
            mutators={arrayMutators}
            initialValues={initialData}
            ref={(form) => (this.form = form)}
            render={({ handleSubmit, pristine, invalid, values, ...rest }) => (
              <form onSubmit={handleSubmit}>
                {questionsData.map((field, index) => (
                  <div>
                    {field.questions && field.questions.length ? (
                      <div>
                        <StyledLabel>{field.name}</StyledLabel>
                        <div>
                          {field.questions.map((subfield, subIndex) => (
                            <div>
                              <StyledLabel>{subfield.name}</StyledLabel>
                              <QuestionLine
                                attachmentAllowed
                                permission={permission}
                                fetching={attachmentFetching}
                                disabled={readOnly}
                                readOnly={readOnly}
                                index={index}
                                subIndex={subIndex}
                                category
                                name={subfield.name}
                                answerData={answerData}
                                downloadFunc={this.handleDownload}
                                submitAttachment={this.handleSubmitAttachment}
                                deleteAttachment={
                                  this.handleDeleteAttachmentRequest
                                }
                                handleCommentFieldOpening={
                                  this.handleCommentFieldOpening
                                }
                                field={subfield}
                                openViewModal={this.openPDFViewModal}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <StyledLabel>{field.name}</StyledLabel>
                        <QuestionLine
                          attachmentAllowed
                          permission={permission}
                          fetching={attachmentFetching}
                          index={index}
                          disabled={readOnly}
                          readOnly={readOnly}
                          name={field.name}
                          answerData={answerData}
                          downloadFunc={this.handleDownload}
                          submitAttachment={this.handleSubmitAttachment}
                          deleteAttachment={this.handleDeleteAttachmentRequest}
                          deleteCustomQuestion={this.deleteCustomQuestion(
                            field.id
                          )}
                          handleCommentFieldOpening={
                            this.handleCommentFieldOpening
                          }
                          field={field}
                          openViewModal={this.openPDFViewModal}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {/* Generownie lini */}
                <div>
                  {newQuestionsData.map((field, index) => (
                    <QuestionLine
                      attachmentAllowed
                      newQuestion
                      permission={permission}
                      index={index}
                      disabled={readOnly}
                      readOnly={readOnly}
                      name={field.name}
                      answerData={answerData}
                      downloadFunc={this.handleDownload}
                      submitAttachment={this.handleSubmitAttachmentLocal}
                      deleteAttachment={this.handleDeleteAttachmentLocal}
                      field={field}
                      openViewModal={this.openPDFViewModal}
                      handleCommentFieldOpening={this.handleCommentFieldOpening}
                      deleteCustomQuestion={this.deleteAuditCustomQuestionLocal(
                        index
                      )}
                    />
                  ))}
                </div>
                <div>
                  <Button
                    type="button"
                    onClick={this.addNewQuestion}
                    disabled={readOnly}
                  >
                    Dodaj pytanie
                  </Button>
                </div>
                <ButtonWrapper>
                  {readOnly ? (
                    singleData.stage > 1 ? (
                      <LinkContainer to={readOnlyUrl || "/"}>
                        <Button bsStyle="primary">Dalej</Button>
                      </LinkContainer>
                    ) : (
                      <Button disabled={true} bsStyle="primary">
                        Dalej
                      </Button>
                    )
                  ) : (
                    <Button type="submit" bsStyle="primary">
                      Zapisz
                    </Button>
                  )}
                  <LinkContainer to={`/audit/`} exact>
                    <Button>{readOnly ? "Powrót do listy" : "Anuluj"}</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}
          />
        </Box>
        <QuestionForm />
        <PDFModal
          open={pdfModal.open}
          handleClose={this.closeViewModal.bind(this, "pdfModal")}
          title={pdfModal.name}
          handleDownload={this.handleDownload(
            pdfModal.downloadData.file,
            pdfModal.downloadData.name,
            pdfModal.downloadData.attachmentIndex,
            pdfModal.downloadData.index,
            pdfModal.downloadData.subIndex
          )}
          file={pdfModal.file}
        />
        <ImageModal
          open={imageModal.open}
          handleClose={this.closeViewModal.bind(this, "imageModal")}
          title={imageModal.name}
          handleDownload={this.handleDownload(
            imageModal.downloadData.file,
            imageModal.downloadData.name,
            imageModal.downloadData.attachmentIndex,
            imageModal.downloadData.index,
            imageModal.downloadData.subIndex
          )}
          file={imageModal.file}
        />
        <Modal
          title={`Usuń załącznik - ${this.state.modalDelete.name}`}
          description="Czy chcesz usunąć dany załącznik?"
          open={this.state.modalDelete.open}
          handleAccept={this.handleDeleteAttachment}
          handleClose={this.closeModal}
        />
      </Fragment>
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    resetAuditNewQuestionLocal()(dispatch);
  }
}

export default connect((store) => ({
  audit: store.audit,
}))(AuditQuestions);
