import React, { Fragment } from "react";
import { connect } from "react-redux";
import AppComponent from "./AppComponent";
import styled from "styled-components";
import PropTypes from "prop-types";
import Select from "../components/Select";
import DayPicker from "../lib/DayPicker/";
import { LayoutContent } from "./Layout";
import SideMenu from "./SideMenu";
import Box from "./Box";
import {
  StyledLabel,
  StyledInputBox,
} from "../style/styleComponents/Form/input";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import { getLocalizationsOnly } from "../actions/localizationActions";
import {
  addCharacteristic,
  getCharacteristic,
  pingCharacteristic,
} from "../actions/characteristicActions";
import moment from "moment";
import { getUserSections } from "../actions/sectionActions";
import LoadingScreen from "./LoadingScreen";
import validator, { composeValidators } from "./Validation";
import dataToFormData from "./dataToFormData";
import { LinkContainer } from "./LinkContainer";
import { ButtonWrapper } from "./ListComponents";
import Alert from "react-bootstrap/lib/Alert";

const FieldWrapper = styled.div`
  margin-bottom: 25px;
`;

const LocalStyledInputBox = styled(StyledInputBox)`
  margin-bottom: 25px;
`;

class CharacteristicForm extends AppComponent {
  constructor(props) {
    super(props);
    this.getSections = this.getSections.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    getLocalizationsOnly()(dispatch).then(this.handleServerResponse(() => {}));
  }

  getSections(localizaction_id) {
    const { dispatch } = this.props;
    getUserSections(localizaction_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  render() {
    let {
      message,
      handleSubmit: hs,
      localization: { data: localizationData, fetched },
      section: { data: sectionData },
      createView,
      initialData,
      permission = {},
      listUrl = "/characteristic",
    } = this.props;
    let readOnly = !createView && !permission[3];
    if (!initialData) {
      initialData = {
        date: moment().format("YYYY-MM-DD"),
      };
    } else {
      initialData = {
        ...initialData,
        pdf: null,
        date: moment(initialData.date_create).format("YYYY-MM-DD"),
      };
    }

    return (
      <LoadingScreen fetched={fetched}>
        <LayoutContent
          message={message}
          error_code={this.state.error_code}
          SideMenu={
            <SideMenu
              title="MSDS"
              data={[
                { name: "Karty charakterystyk", url: "/characteristic" },
                {
                  name: "Archiwum kart",
                  url: "/characteristic/archived",
                },
              ]}
            />
          }
        >
          <Box
            title={`${initialData ? `Edytuj` : `Dodaj`} kartę charakterystyki`}
          >
            <Form
              onSubmit={hs}
              initialValues={initialData}
              render={({ handleSubmit, pristine, invalid, form }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name={"name"}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <Fragment>
                        <LocalStyledInputBox
                          {...input}
                          label="Nazwa substancji"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      </Fragment>
                    )}
                  />
                  <FieldWrapper>
                    <StyledLabel>Data sporządzenia karty</StyledLabel>
                    <Field
                      name="date_create"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <DayPicker
                          canChangeYear
                          fullWidth
                          icon
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          selectedDay={input.value}
                          handleChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <StyledLabel>Data wymaganej aktualizacji</StyledLabel>
                    <Field
                      name="date_required_update"
                      render={({ input, meta, ...rest }) => (
                        <DayPicker
                          placeholder={""}
                          canChangeYear
                          fullWidth
                          icon
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          selectedDay={input.value}
                          handleChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="localization_id"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <Select
                          label="Lokacja"
                          title={"- wybierz -"}
                          centerOptions
                          fullWidth
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          propValue="id"
                          selected={input.value}
                          handleChange={(value) => {
                            this.getSections(value);
                            input.onChange(value);
                            form.change("section_id", undefined);
                          }}
                          data={localizationData}
                        />
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="section_id"
                      render={({ input, meta, ...rest }) => (
                        <Select
                          label="Dział"
                          // disabled={readOnly}
                          fullWidth
                          title="-- wybierz --"
                          propValue={"id"}
                          selected={input.value}
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          data={[{ id: null, name: "Brak" }].concat(
                            sectionData
                          )}
                          handleChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    />
                  </FieldWrapper>
                  <Field
                    name="pdf"
                    validate={
                      createView
                        ? composeValidators(
                            validator.required,
                            validator.attachment
                          )
                        : composeValidators(validator.attachment)
                    }
                    render={({ input, meta, ...rest }) => (
                      <LocalStyledInputBox
                        label="Dodaj plik"
                        type="file"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.files[0]);
                        }}
                      />
                    )}
                  />
                  <FieldWrapper>
                    <ButtonWrapper>
                      <Button
                        type="submit"
                        bsStyle="primary"
                        disabled={readOnly}
                      >
                        Zapisz
                      </Button>
                      <LinkContainer to={listUrl} exact>
                        <Button>Anuluj</Button>
                      </LinkContainer>
                    </ButtonWrapper>
                  </FieldWrapper>
                </form>
              )}
            />
          </Box>
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

// export default CharacteristicForm;

export default connect((store) => ({
  localization: store.localization,
  section: store.section,
  characteristic: store.characteristic,
}))(CharacteristicForm);
