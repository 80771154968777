import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const AccordionBody = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
`;
AccordionBody.displayName = "AccordionBody";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
  }

  componentDidUpdate(prevProps) {
    let { sort, data } = this.props;
    let { sort: prevSort, data: prevData } = prevProps;

    if (sort !== prevSort || data.id != prevData.id) {
      this.setState({ open: false });
    }
  }

  handleChange() {
    let { data } = this.props;
    this.setState({ open: !this.state.open });
  }

  render() {
    const { open } = this.state;
    const { data = {}, LineComponent, className, level = 0 } = this.props;
    return (
      <div className={`accordion ${className}`}>
        <LineComponent
          level={level}
          {...data}
          open={open}
          children={data.children && data.children.length ? true : false}
          handleChange={this.handleChange.bind(this)}
        />
        <AccordionBody open={open}>
          {data.children
            ? data.children.map((item, index) =>
                item.children && item.children.length ? (
                  <Accordion
                    level={level + 1}
                    key={index}
                    data={item}
                    LineComponent={LineComponent}
                  />
                ) : (
                  <LineComponent
                    level={level + 1}
                    key={index}
                    {...item}
                    open={open}
                    children={false}
                  />
                )
              )
            : null}
        </AccordionBody>
      </div>
    );
  }
}

export default Accordion;

Accordion.propTypes = {
  LineComponent: PropTypes.func.isRequired,
  data: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.object),
  }),
};
