import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import Modal from "react-bootstrap/lib/Modal";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper, TwoInputWrapper } from "components/Wrappers";
import ModalSchema from "components/Modal/./_SchemaAppComponent";
import {
  FieldInput,
  FieldTextareaBox,
  FieldDayPicker,
} from "components/Fields/index";
import validator, { composeValidators } from "components/Validation";
const LocalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
class SimpleAcceptModalDump extends ModalSchema {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {}

  accept() {
    const { accept = () => {}, closeModal } = this.props;

    accept();
    closeModal();
  }

  openAddModal() {
    let { openAddUser } = this.props;
    openAddUser();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  submit(values) {
    const { incident_id, dispatch, submit } = this.props;
    let { file, ...data } = values;

    submit(values, {
      closeModal: this.closeModal,
    });
  }

  render() {
    const {
      children,
      data = {},
      handleAccept,
      open,
      title,
      getUser,
      user,
      readOnly,
      index,
      submit,
      users: { autoCompleteUsers },
    } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Informacje świadka {index + 1}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={data}
              onSubmit={this.submit}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TwoInputWrapper>
                      <FieldInput
                        validate={validator.required}
                        name={"witness_place"}
                        label={"Miejscowość"}
                        disabled={readOnly}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldDayPicker
                        validate={validator.required}
                        name={"witness_date"}
                        label={"Data"}
                        disabled={readOnly}
                      />
                    </TwoInputWrapper>
                    <h4>Dane Świadka</h4>

                    <Field
                      name="full_name"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <Fragment>
                          <AutoComplete
                            label={"Imię i nazwisko"}
                            data={autoCompleteUsers}
                            disabled={readOnly}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            value={input.value}
                            maxListHeight={"260px"}
                            propValue={"id"}
                            propName={"full_name"}
                            fullData
                            handleChange={(value) => {
                              form.change("user_id", "");
                              input.onChange(value);
                            }}
                            handleSelect={(value) => {
                              input.onChange(value.full_name);
                              form.change("user_id", value.id);
                              getUser(value.id, form);
                            }}
                          />
                        </Fragment>
                      )}
                    />

                    <TwoInputWrapper>
                      <FieldDayPicker
                        validate={validator.required}
                        name={"birth_date"}
                        label={"Data urodzenia"}
                        disabled={readOnly}
                      />
                      <FieldInput
                        validate={validator.required}
                        name={"birth_place"}
                        label={"miejsce urodzenia"}
                        disabled={readOnly}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldInput
                        validate={validator.required}
                        name={"residence_fulladdress"}
                        label={"Adres zamieszkania"}
                        disabled={readOnly}
                      />
                      <FieldInput
                        validate={validator.required}
                        name={"pesel"}
                        label={"PESEL"}
                        disabled={readOnly}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldInput
                        validate={validator.required}
                        name={"id_document_number"}
                        label={"Nr dokumentu potwierdzającego tożsamość"}
                        disabled={readOnly}
                      />
                      <div></div>
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldInput
                        validate={validator.required}
                        name={"workplace_localization"}
                        label={"Miejsce zatrudnienia"}
                        disabled={readOnly}
                      />
                      <FieldInput
                        validate={validator.required}
                        name={"workplace_name"}
                        label={"Stanowisko służbowe"}
                        disabled={readOnly}
                      />
                    </TwoInputWrapper>

                    <h4>Informacja świadka</h4>
                    <FieldTextareaBox
                      validate={validator.required}
                      name={"witness_informations"}
                    />

                    <LocalButtonWrapper>
                      <Button
                        style={{ marginBottom: "20px" }}
                        type="button"
                        bsStyle="primary"
                        onClick={() => {
                          form.change("file", true);
                          handleSubmit();
                        }}
                      >
                        Zapisz i wygeneruj
                      </Button>
                      <ButtonWrapper>
                        <Button type="submit" bsStyle="primary">
                          Zapisz
                        </Button>
                        <Button type="button" onClick={this.closeModal}>
                          Anuluj
                        </Button>
                      </ButtonWrapper>
                    </LocalButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </StyledModal>
      </div>
    );
  }
}

export default SimpleAcceptModalDump;
