import React, { Fragment } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";

import setFieldTouched from "final-form-set-field-touched";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import {
  getDictionary,
  getDictionaryGroup,
  getDictionaryProfessionCode,
} from "actions/dictionaryActions";
import {
  getUserWorkplacesWithout,
  getSearchedWorkplace,
  getSearchedWorkplaceInitial,
} from "actions/workplaceActions";
import {
  getSearchedUsers,
  getUserByWorkplaceByUser,
} from "actions/userActions";
import {
  getIncidentToAnalysis,
  analysis,
  changeIncidentPersonalStatus,
  forwardIncident,
  acceptAnalysis,
  saveAnalysisData,
} from "actions/incidentActions";
import { getUsersNames } from "actions/userActions";

import { setMessage } from "components/AppComponent";
import IncidentDefaultComponent from "./defaultComponent";
import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import Modal from "components/Modal/Simple";
import ModalAccept from "components/Modal/SimpleAccept";
import Select from "components/Select";
import { ButtonWithIcon, ButtonWrapper } from "components/ListComponents";
import validator, { composeValidators } from "components/Validation";
import { LinkContainer } from "components/LinkContainer";
import AutoComplete from "components/AutoCompleteInput";
import ModalResponsibility, {
  modalResponsibilityState,
} from "components/Modal/ResponsibilityUser";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInputCheckBox,
  StyledInput,
  StyledInputCheckBoxWithToolTip,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";
import { StyledInputMessage } from "style/styleComponents/Form/input";

import SideMenu from "./_SideMenu";
import IncidentHasPersonSection from "./AnalysisForm/incidentHasPersonSection";
import AccidentDescription from "./AnalysisForm/AccidentDescription";
import AccidentCauseProtocol from "./AnalysisForm/AccidentCauseProtocol";
import AccidentStatisticsCard from "./AnalysisForm/AccidentStatisticsCard";
import PotentiallyAccidentalEvent from "./AnalysisForm/PotentiallyAccidentEvent";
import AccidentCard from "./AnalysisForm/AccidentCard";
import {
  OnlySxBlock,
  TwoInputWrapper,
  SelectWrapper,
  SpaceBlock,
  StyledInputCheckBoxWrapper,
} from "./AnalysisForm/AnalysisForm.style";

import WitnessComponent from "./AnalysisForm/WitnessComponent";
import ForwardIncidentComponent from "./AnalysisForm/container/ForwardIncident";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = async (values) => {
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

const defaultInitialValues = {
  so_po_outdated_regon: false,
  accident_cause: [{}],
};

let setInitialValues = (incidentData) => {
  const {
    injury_source: injurySource = [],
    protocol_work_accident_is_accident,
    protocol_work_accident_a_work_accident,
    protocol_work_accident_like_work_accident,
    protocol_accident_severity_fatal,
    protocol_accident_severity_serious,
    protocol_accident_severity_causing_unable_on_time,
    so_pt_part_second_send_to_gus,
    so_po_outdated_regon,
    so_po_part_one_send_to_gus,
    so_po_part_one_send_to_gus_date,
    protocol_number,
    profession_code,
    ...rest
  } = incidentData;

  let initialValues = {
    ...defaultInitialValues,
    ...rest,
    protocol_work_accident_is_accident: protocol_work_accident_is_accident
      ? "true"
      : "false",
    protocol_work_accident_a_work_accident: protocol_work_accident_a_work_accident
      ? "true"
      : "false",
    protocol_work_accident_like_work_accident: protocol_work_accident_like_work_accident
      ? "true"
      : "false",
    protocol_accident_severity_fatal: protocol_accident_severity_fatal
      ? "true"
      : "false",
    protocol_accident_severity_serious: protocol_accident_severity_serious
      ? "true"
      : "false",
    protocol_accident_severity_causing_unable_on_time: protocol_accident_severity_causing_unable_on_time
      ? "true"
      : "false",

    so_po_outdated_regon: so_po_outdated_regon ? "true" : "false",
    source: injurySource,
  };

  initialValues.accident_cause =
    initialValues.accident_cause && initialValues.accident_cause.length
      ? initialValues.accident_cause
      : [{}];

  return initialValues;
};

class AnalysisIncident extends IncidentDefaultComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      sex: [],
      person_status: [],
      injuries: [],
      injuries_localizations: [],
      initialValues: undefined,
      modal: {
        modalResponsibilityState,
      },
      modalPersonal: {
        open: false,
      },

      workplaceFetch: {
        prevValue: null,
      },
      searchedWorkplaces: [],
    };

    this.form = React.createRef();

    this.handleSelectWorkplace = this.handleSelectWorkplace.bind(this);
    this.handleUserChoose = this.handleUserChoose.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateIncidentPersonal = this.updateIncidentPersonal.bind(this);
    this.saveOnly = this.saveOnly.bind(this);
    this.moveToTasks = this.moveToTasks.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    Promise.all([
      getUserWorkplacesWithout()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getSearchedWorkplaceInitial()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getDictionaryGroup("analysis")(dispatch).then(
        this.handleServerResponse()
      ),
      getIncidentToAnalysis(incident_id)(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUsersNames()(dispatch).then(
        this.handleServerResponse({}, (action) => {})
      ),
    ]).then(() => {
      const {
        incident: { singleData: incidentData },
      } = this.props;
      let {
        responsible_user_id = null,
        responsible_user_name,
        incident_has_person,
        profession_code,
      } = incidentData;
      getDictionaryProfessionCode(profession_code)(dispatch).then(
        this.handleServerResponse()
      );
      let {
        responsible_user_workplace_id,
        date_event,
        so_po_date_event,
        so_po_date_event_hour,
        ...formData
      } = incidentData;

      let momentDate = moment(date_event);
      if (!so_po_date_event) so_po_date_event = momentDate.format("YYYY-MM-DD");

      let initialValues = {
        date_event,
        so_po_date_event,
        so_po_date_event_hour,
        ...formData,
      };

      this.setState({ initialValues: setInitialValues(initialValues) });
    });
  }

  openModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true } });
    };
  }

  closeModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false } });
    };
  }

  handleUserChoose(value) {
    this.setState({ modal: { ...this.state.modal, selectedUser: value } });
  }

  handleSelectWorkplace(value) {
    this.setState(
      {
        modal: {
          ...this.state.modal,
          selectedWorkplace: value,
          selectedUser: null,
        },
      },
      () => {
        getUserByWorkplaceByUser(value)(this.props.dispatch).then(
          this.handleServerResponse()
        );
      }
    );
  }

  saveOnly(rawvalues, callback) {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;

    let temp = (rawvalues) => {
      let { responsible_user_name, ...values } = rawvalues;
      values.cause_description = values.cause_description || "";
      values.similiar_incident = values.similiar_incident || "";

      let { incident_has_person } = values;

      let { user, ...incident_has_person_rest } = incident_has_person;

      let incident_has_person_edited = {
        ...incident_has_person_rest,
        full_name: user.full_name,
        user_id: user.id,
      };

      values.incident_has_person = incident_has_person_edited;

      return {
        ...values,
        source: values.source.filter((source) => source.active),
      };
    };
    let values = temp(rawvalues);
    saveAnalysisData(
      incident_id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        callback && callback();
      })
    );
  }

  moveToTasks() {
    const {
      dispatch,
      match,
      history,
      incident: {
        singleData: { stage },
      },
    } = this.props;
    const { incident_id } = match.params;
    if (stage == 2) {
      acceptAnalysis(incident_id)(dispatch).then(
        this.handleServerResponse({}, () => {
          history.push(`/incident/task/${incident_id}`);
        })
      );
    } else {
      history.push(`/incident/task/${incident_id}`);
    }
  }

  handleSubmit(rawvalues) {
    let { responsible_user_name, ...values } = rawvalues;
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    values.cause_description = values.cause_description || "";
    values.similiar_incident = values.similiar_incident || "";

    let { incident_has_person } = values;

    let { user, ...incident_has_person_rest } = incident_has_person;

    let incident_has_person_edited = {
      ...incident_has_person_rest,
      full_name: user.full_name,
      user_id: user.id,
    };

    values.incident_has_person = incident_has_person_edited;

    analysis(incident_id, {
      ...values,
      source: values.source.filter((source) => source.active),
    })(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/incident/task/" + incident_id);
      })
    );
  }

  updateIncidentPersonal() {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    const that = this;
    changeIncidentPersonalStatus(incident_id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { personal } = action.payload.data.incident;
        that.form.form.change("personal", personal);
        that.form.form.change("incident_has_person", [{}]);
      })
    );
  }

  render() {
    let { modal, modalPersonal, message, initialValues } = this.state;
    const {
      dictionary,
      incident: {
        fetching,
        singleData: {
          responsible_user_workplace_id,
          stage,
          type_id,
          ...incidentData
        },
        permission,
      },

      match,
    } = this.props;
    const { incident_id } = match.params;
    let { protocol_number } = incidentData;

    const readOnly = !permission[10];

    return (
      <LayoutContent
        mobileNotHideMenu={true}
        SideMenu={
          <SideMenu
            incident_id={incident_id}
            permission={permission}
            stage={stage}
            addNewIncident={this.addNewIncident}
          />
        }
        error404={this.state.error_code == 404}
        message={message}
      >
        <Box title={"Analiza zgłoszenia"}>
          <Form
            initialValues={initialValues}
            validate={(values) => {
              const errors = {};
              if (values) {
                if (values.incident_has_person) {
                  let item = values.incident_has_person;
                  if (item) {
                    const {
                      personal_protective_equipment,
                      personal_protective_equipment_description,
                    } = item;
                    errors.incident_has_person = {
                      personal_protective_equipment_description: validator.mustBeFilledWhenActive(
                        personal_protective_equipment,
                        personal_protective_equipment_description
                      ),
                    };
                  }
                }
                if (values.source) {
                  values.source = "Wymagany";
                }
              }
              return errors;
            }}
            mutators={{ setField, ...arrayMutators, setFieldTouched }}
            onSubmit={this.handleSubmit}
            subscription={{
              submitting: true,
              pristine: true,
            }}
            render={({
              handleSubmit,
              pristine,
              invalid,
              values = {},
              form,
            }) => {
              this.form = { form };
              let {
                mutators: { push, pop },
              } = form;
              return (
                <form onSubmit={handleSubmit}>
                  <ForwardIncidentComponent match={match} />
                  <AccidentDescription
                    form={form}
                    readOnly={readOnly}
                    personal={values.personal}
                    updateIncidentPersonal={this.updateIncidentPersonal}
                  />
                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }) => {
                      return (
                        <IncidentHasPersonSection
                          form={form}
                          incident_id={incident_id}
                          readOnly={readOnly}
                          type={type_id}
                          values={values}
                        />
                      );
                    }}
                  </FormSpy>
                  {type_id == 1 && (
                    <Fragment>
                      <WitnessComponent incident_id={incident_id} />
                      {incidentData.incident_has_person.person_status_id ==
                      1 ? (
                        <AccidentCauseProtocol
                          incident_id={incident_id}
                          dictionary={dictionary}
                          readOnly={readOnly}
                          form={form}
                          values={values}
                          protocol_number={protocol_number}
                        />
                      ) : (
                        <AccidentCard
                          personData={incidentData.incident_has_person}
                          incident_id={incident_id}
                          dictionary={dictionary}
                          readOnly={readOnly}
                          form={form}
                          values={values}
                        />
                      )}

                      <AccidentStatisticsCard
                        personData={incidentData.incident_has_person}
                        incident_id={incident_id}
                        dictionary={dictionary}
                        readOnly={readOnly}
                        form={form}
                      />
                    </Fragment>
                  )}
                  {type_id == 2 && (
                    <Fragment>
                      <PotentiallyAccidentalEvent />
                    </Fragment>
                  )}
                  <ButtonWrapper>
                    <LinkContainer to={`/incident`} exact>
                      <Button>Anuluj</Button>
                    </LinkContainer>
                    <FormSpy subscribe={{ form: true, values: true }}>
                      {({ values }) => {
                        return (
                          <>
                            <Button
                              disabled={fetching}
                              onClick={() => {
                                this.saveOnly(values);
                              }}
                              type="button"
                              bsStyle="primary"
                            >
                              Zapisz
                            </Button>
                            {readOnly ? (
                              <LinkContainer
                                // type="submit"
                                disabled={stage < 4}
                                to={`/incident/task/${incident_id}`}
                              >
                                <Button bsStyle="primary">Dalej</Button>
                              </LinkContainer>
                            ) : (
                              <Button
                                disabled={fetching}
                                onClick={() => {
                                  this.saveOnly(values, this.moveToTasks);
                                }}
                                type="button"
                                bsStyle="primary"
                              >
                                Dalej
                              </Button>
                            )}
                          </>
                        );
                      }}
                    </FormSpy>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button type="submit" bsStyle="primary">
                      Przygotuj do weryfikacji
                    </Button>
                  </ButtonWrapper>
                </form>
              );
            }}
          />
        </Box>
      </LayoutContent>
    );
  }

  componentWillMount() {}
}

//TODO Must Osoba odpowiedzialna - użytkownik nie resetuje się gdy wybiera się inne stanowisko
export default connect((store) => ({
  dictionary: store.dictionary,
  incident: store.incident,
}))(AnalysisIncident);
