import styled from "styled-components";
import { media } from "style/style-utils/index";

export const TwoInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.flexEnd ? "flex-end" : "flex-start")};
  padding-top: ${(props) => (props.noPaddingTop ? "0" : "5px")};

  ${media.xs`
    display: ${(props) => (props.noXS ? "none" : "block")}
  `}

  & > * {
    flex-basis: 48%;
  }
`;

export const SpaceBlock = styled.div`
  margin-bottom: 10px;
`;

export const StyledInputCheckBoxWrapper = styled.div`
  display: flex;
  & > div {
    margin-right: 15px;
  }
`;

export const SelectWrapper = styled.div`
  margin-bottom: 15px;
`;

export const OnlySxBlock = styled.div`
  display: none;
  ${media.xs`
    display:block;
  `}
`;

export let Width33 = styled.div`
  width: 33%;
  margin: 0 0 10px;
`;

export let LeftPadding = styled.div`
  padding-left: 20px;
`;

export let FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export let FlexLineAccidentType = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

export let FormSegment = styled.div`
  margin-bottom: 35px;
`;

export let EmptySeparator = styled.div`
  padding-bottom: 35px;
`;
