import LList from "./List";
import LForm from "./Form";
import LAnalysis from "./Analysis";
import LRegister from "./Register";
import LTaskList from "./Task/List";
import LTaskAdd from "./Task/Add";
import LTaskEdit from "./Task/Edit";
import LAttachment from "./Attachment";
import LNotification from "./Notification";
import LRedirectComponent from "./RedirectComponent";

export const List = LList;
export const Form = LForm;
export const Analysis = LAnalysis;
export const Register = LRegister;
export const TaskList = LTaskList;
export const TaskAdd = LTaskAdd;
export const TaskEdit = LTaskEdit;
export const Attachment = LAttachment;
export const Notification = LNotification;
export const RedirectComponent = LRedirectComponent;
