export function addTask(data) {
  return (dispatch) =>
    dispatch({
      types: ["ADD_TASK_REQUEST", "ADD_TASK_SUCCESS", "ADD_TASK_ERROR"],
      payload: {
        request: {
          url: "/task/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addTaskByIncident(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["ADD_TASK_REQUEST", "ADD_TASK_SUCCESS", "ADD_TASK_ERROR"],
      payload: {
        request: {
          url: `/incident/task/${incident_id}/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addTaskByAudit(audit_id, audit_question_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["ADD_TASK_REQUEST", "ADD_TASK_SUCCESS", "ADD_TASK_ERROR"],
      payload: {
        request: {
          url: `/audit/task/${audit_id}/add/${audit_question_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addTaskByRA(id, ra_ah_tme_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["ADD_TASK_REQUEST", "ADD_TASK_SUCCESS", "ADD_TASK_ERROR"],
      payload: {
        request: {
          url: `/ra/task/${id}/add/${ra_ah_tme_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getTask(id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASK_REQUEST", "GET_TASK_SUCCESS", "GET_TASK_ERROR"],
      payload: {
        request: {
          url: `/task/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentTask(incident_id, id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASK_REQUEST", "GET_TASK_SUCCESS", "GET_TASK_ERROR"],
      payload: {
        request: {
          url: `/incident/task/${incident_id}/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRATask(id, task_id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASK_REQUEST", "GET_TASK_SUCCESS", "GET_TASK_ERROR"],
      payload: {
        request: {
          url: `/ra/task/${id}/${task_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getTasks() {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASKS_REQUEST", "GET_TASKS_SUCCESS", "GET_TASKS_ERROR"],
      payload: {
        request: {
          url: "/task",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentTasks(incident_id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASKS_REQUEST", "GET_TASKS_SUCCESS", "GET_TASKS_ERROR"],
      payload: {
        request: {
          url: `/incident/task/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getTasksByFilter(filter) {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASKS_REQUEST", "GET_TASKS_SUCCESS", "GET_TASKS_ERROR"],
      payload: {
        request: {
          url: "/task",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: filter,
        },
      },
    });
}

export function editTask(task_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["EDIT_TASK_REQUEST", "EDIT_TASK_SUCCESS", "EDIT_TASK_ERROR"],
      payload: {
        request: {
          url: `/task/edit/${task_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}
export function editTaskByIncident(incident_id, task_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["EDIT_TASK_REQUEST", "EDIT_TASK_SUCCESS", "EDIT_TASK_ERROR"],
      payload: {
        request: {
          url: `/incident/task/${incident_id}/edit/${task_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editTaskByAudit(audit_id, task_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["EDIT_TASK_REQUEST", "EDIT_TASK_SUCCESS", "EDIT_TASK_ERROR"],
      payload: {
        request: {
          url: `/audit/task/${audit_id}/edit/${task_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editTaskByRA(id, ra_ah_tme_id, task_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["EDIT_TASK_REQUEST", "EDIT_TASK_SUCCESS", "EDIT_TASK_ERROR"],
      payload: {
        request: {
          url: `/ra/task/${id}/edit/${ra_ah_tme_id}/${task_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteTask(task_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_TASK_REQUEST",
        "DELETE_TASK_SUCCESS",
        "DELETE_TASK_ERROR",
      ],
      payload: {
        request: {
          url: `/task/delete/${task_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteTaskByAudit(
  audit_id,
  task_id,
  questionIndex,
  subQuestionIndex
) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_AUDIT_TASK_REQUEST",
        "DELETE_AUDIT_TASK_SUCCESS",
        "DELETE_AUDIT_TASK_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/task/${audit_id}/delete/${task_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            questionIndex,
            subQuestionIndex,
          },
        },
      },
    });
}

export function getProgresses(category) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_PROGRESS_REQUEST",
        "GET_PROGRESS_SUCCESS",
        "GET_PROGRESS_ERROR",
      ],
      payload: {
        request: {
          url: `/progress${category ? `/${category}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function acceptTask(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "REALIZATION_TASK_REQUEST",
        "REALIZATION_TASK_SUCCESS",
        "REALIZATION_TASK_ERROR",
      ],
      payload: {
        request: {
          url: `/task/accept/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function confirmTask(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "REALIZATION_TASK_REQUEST",
        "REALIZATION_TASK_SUCCESS",
        "REALIZATION_TASK_ERROR",
      ],
      payload: {
        request: {
          url: `/task/confirm/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function closeTask(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "REALIZATION_TASK_REQUEST",
        "REALIZATION_TASK_SUCCESS",
        "REALIZATION_TASK_ERROR",
      ],
      payload: {
        request: {
          url: `/task/close/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function pingTask() {
  return (dispatch) =>
    dispatch({
      types: ["PING_TASK_REQUEST", "PING_TASK_SUCCESS", "PING_TASK_ERROR"],
      payload: {
        request: {
          url: "/task/ping",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function pingIncidentTask(incident_id) {
  return (dispatch) =>
    dispatch({
      types: ["PING_TASK_REQUEST", "PING_TASK_SUCCESS", "PING_TASK_ERROR"],
      payload: {
        request: {
          url: `/incident/${incident_id}/task/ping`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAttachments(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_TASK_ATTACHMENTS_REQUEST",
        "GET_TASK_ATTACHMENTS_SUCCESS",
        "GET_TASK_ATTACHMENTS_ERROR",
      ],
      payload: {
        request: {
          url: `/task/attachment/${incident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addAttachment(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_TASK_ATTACHMENT_REQUEST",
        "ADD_TASK_ATTACHMENT_SUCCESS",
        "ADD_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/task/attachment/${incident_id}/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteAttachment(task_id, attachment_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_TASK_ATTACHMENT_REQUEST",
        "DELETE_TASK_ATTACHMENT_SUCCESS",
        "DELETE_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/task/attachment/${task_id}/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addTaskAttachmentLocal(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_TASK_ATTACHMENT_LOCAL",
      payload: data,
    });
}

export function deleteTaskAttachmentLocal(task_id, attachment_id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_TASK_ATTACHMENT_LOCAL",
      payload: {
        task_id,
        attachment_id,
      },
    });
}

export function download(url, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_TASK_ATTACHMENT_REQUEST",
        "DOWNLOAD_TASK_ATTACHMENT_SUCCESS",
        "DOWNLOAD_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            index,
          },
        },
      },
    });
}

export function clearTask() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_TASK",
      payload: {},
    });
}
