const rawValidator = {
  file: (value) => {
    return undefined;
  },
  mustBeEmail: (value) =>
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
      ? "validation_message.must_be_email"
      : undefined,
  shouldBeEmail: (value) => {
    if (!value || value.length == 0) {
      return undefined;
    }

    return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
      ? "validation_message.must_be_email"
      : undefined;
  },
  mustBeNumber: (value) => {
    let r = value
      ? isNaN(value)
        ? "validation_message.must_be_number"
        : undefined
      : undefined;
    return r;
  },
  mustBePhone: (value) =>
    !/^[^A-Za-z]+$/.test(value)
      ? "validation_message.must_be_email"
      : undefined,
  shouldBePassword: (value) =>
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value) || !value
      ? undefined
      : "validation_message.must_be_password",
  mustBePassword: (value) =>
    !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/.test(value)
      ? "validation_message.must_be_password"
      : undefined,
  mustBeRepeatedPassword: (password, repeatPassword) =>
    password != repeatPassword
      ? "validation_message.must_be_repeated_password"
      : undefined,
  cannotDeActivate: (value) =>
    !value ? "validation_message.must_be_repeated_password" : undefined,
  required: (value) => {
    switch (typeof value) {
      case "string": {
        return value.length && value != "<p><br></p>"
          ? undefined
          : "validation_message.required";
      }
      case "number": {
        return value || value === 0 ? false : "validation_message.required";
      }
      default: {
        return value ? undefined : "validation_message.required";
      }
    }
  },
  mustBeDate: (value) =>
    !/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
      ? "validation_message.must_be_date"
      : undefined,
  mustHaveAOneQuestion: (value) => {
    const errorMessage = "validation_message.must_have_one_question";
    if (!Array.isArray(value)) {
      return errorMessage;
    } else {
      let question = value.find((item) => item.category == false);
      if (question) {
        return undefined;
      }

      question = value.find((item) => item.questions && item.questions.length);
      if (question) {
        return undefined;
      }
      return errorMessage;
    }
  },
  mustBeFilledWhenActive: function (parent, child) {
    if (parent) {
      return !child ? "validation_message.required" : false;
    }

    return false;
  },
  mustBeFilledSourceWhenActive: function (data) {
    if (!data || typeof data !== "object") {
      return "validation_message.required";
    }

    const { dynamic_value, active, value } = data;

    if (dynamic_value && active) {
      return !(value && value.length)
        ? "validation_message.incident_source_other"
        : undefined;
    }

    return undefined;
  },
  mustBeInRangeEqual: function (value) {
    if (value < 0) {
      return "validation_message.greater_than_minimum";
    }
    if (value > 6) {
      return "validation_message.less_than_maximum";
    }
    return undefined;
  },
  attachment: function (value) {
    if (!value) {
      return false;
    }
    if (value && value.size && value.size < 25 * 1024 * 1024) {
      return false;
    } else {
      return "validation_message.25MB_file_require";
    }
  },
  timeHH: function (value) {
    if (!value) {
      return false;
    }
    return /^([0-1][0-9]|[2][0-3])/.test(value)
      ? false
      : "validation_message.hour_only_format";
  },
  timeHM: function (value) {
    if (!value) {
      return false;
    }
    return /^[0-9]{1,2}\:[0-9]{2}$/.test(value)
      ? /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
        ? false
        : "validation_message.hour_minutes_format2"
      : "validation_message.hour_minutes_format";
  },
  accident_cause: function (values) {
    if (!values) {
      return "validation_message.accident_cause";
    }
    if (values.length > 7) {
      return "validation_message.accident_cause_too_many";
    }
    for (let val of values) {
      if (
        !val ||
        !Object.keys(val).length ||
        !val.dictionary_accident_cause_id
      ) {
        return "validation_message.accident_cause";
      }
    }
    return false;
  },
  incident_source: function (values) {
    if (!values) {
      return "validation_message.required";
    }
    let oneActive = values.find(({ active }) => active);
    if (!oneActive) {
      return "validation_message.incident_source";
    } else {
      return false;
    }
  },
};

export default rawValidator;
