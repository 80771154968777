import React from "react";
import PropTypes from "prop-types";

export default class CollapseItem extends React.Component {
  static propTypes = {
    onSelectCollapse: PropTypes.bool,
    open: PropTypes.bool,
    parentOpen: PropTypes.bool,
    collapseOutParent: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: false,
      parentOpen: true,
      openChildIndex: null,
    };

    this.renderChildren = this.renderChildren.bind(this);
    this.collapseThis = this.collapseThis.bind(this);
    this.collapseIn = this.collapseIn.bind(this);
    this.collapseOut = this.collapseOut.bind(this);
    this.collapseOutAll = this.collapseOutAll.bind(this);
    this.updateLastOpenChild = this.updateLastOpenChild.bind(this);
  }

  collapseThis() {
    const { index, updateParentOpenChild } = this.props;
    if (!this.state.open) {
      updateParentOpenChild && updateParentOpenChild(index);
    } else {
      updateParentOpenChild && updateParentOpenChild(null);
    }
    this.setState({ open: !this.state.open });
  }

  updateLastOpenChild(index) {
    this.setState({ openChildIndex: index });
  }

  collapseIn() {
    const { index, updateParentOpenChild } = this.props;
    updateParentOpenChild && updateParentOpenChild(index);
    this.setState({ open: true });
  }

  collapseOut() {
    const { updateParentOpenChild } = this.props;
    updateParentOpenChild && updateParentOpenChild(null);
    this.setState({ open: false });
  }

  collapseOutAll() {
    this.setState({ open: false });
    this.props.collapseOutParent && this.props.collapseOutParent();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { updateParentOpenChild, open } = this.props;
    if (
      prevProps.parentOpen !== this.props.parentOpen ||
      prevProps.close !== this.props.close
    ) {
      const newOpen =
        !this.props.parentOpen || this.props.close || !this.state.open
          ? false
          : this.state.open;
      if (!newOpen && open) {
        updateParentOpenChild && updateParentOpenChild(null);
      }
      this.setState({ open: newOpen });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.open != this.state.open) {
      return true;
    }
    if (nextState.openChildIndex != this.state.openChildIndex) {
      return true;
    }
    if (nextProps.open != this.state.open) {
      return true;
    }

    if (nextProps.open !== this.props.open) {
      return true;
    }

    if (nextProps.renderItem !== this.props.renderItem) {
      return true;
    }

    if (nextProps.close !== this.props.close) {
      return true;
    }

    if (nextProps.parentOpen !== this.props.parentOpen) {
      return true;
    }
    return false;
  }

  renderItem() {
    const { open, parentOpen } = this.state;
    const {
      renderItem = ({ children }) => <div>{children}</div>,
      open: propsOpen,
      close,
    } = this.props;
    return renderItem({
      open: !close && (propsOpen || open),
      parentOpen,
      children: this.renderChildren(),
      collapse: this.collapseThis,
      collapseIn: this.collapseIn,
      collapseOut: this.collapseOut,
    });
  }

  renderChildren() {
    const { openChildIndex } = this.state;
    const { open, onSelectCollapse } = this.props;
    return React.Children.map(this.props.children, (child, index) => {
      let onSelectCollapseMutator = {};
      if (onSelectCollapse) {
        onSelectCollapseMutator = {
          close: openChildIndex == index ? false : true,
        };
      }

      return (
        child &&
        React.cloneElement(child, {
          ...child.props,
          ...onSelectCollapseMutator,
          parentOpen: open || this.state.open,
          onSelectCollapse: onSelectCollapse,
          index,
          open: openChildIndex == index ? true : false,
          collapseOutParent: this.collapseOutAll,
          updateParentOpenChild: this.updateLastOpenChild,
          onClick: (e) => {
            if (onSelectCollapse) {
              this.collapseOutAll();
            }
            child.props.onClick && child.props.onClick(e);
          },
        })
      );
    });
  }

  render() {
    const { parentOpen } = this.state;
    return parentOpen ? this.renderItem() : <div></div>;
  }
}
