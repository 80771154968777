import React from "react";
import { connect } from "react-redux";
import { TaskEdit } from "./Edit";
import { addTask } from "../../actions/taskActions";

export class TaskRepeat extends TaskEdit {
  constructor(props) {
    super(props);
    this.state = { ...this.state, repeatMode: true, permisionNumber: 1 };
  }

  handleSubmit(data) {
    const { dispatch, history } = this.props;
    data.section_id = data.section_id != "0" ? data.section_id : null;
    addTask(data)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/task");
      })
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  section: store.section,
  task: store.task,
}))(TaskRepeat);
