import React from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/lib/Button";

import {
  getAccident,
  getAttachments,
  addAttachment,
  deleteAttachment,
  setNextState,
  download,
} from "actions/accidentOWTWActions";

import AppComponet, { setMessage } from "components/AppComponent";
import AttachmentList from "components/AttachmentList";
import Box from "components/Box";
import dataToFormData from "components/dataToFormData";

import { ButtonWrapper } from "components/ListComponents";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";

import SideMenu from "./_SideMenu";

class Attachment extends AppComponet {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.setMessege = this.setMessege.bind(this);
    this.setNextStage = this.setNextStage.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      accident_owtw: { singleData },
    } = this.props;
    const { id } = match.params;

    getAttachments(id)(dispatch).then(this.handleServerResponse());
    if (!Object.keys(getAccident).length || singleData.id != id) {
      getAccident(id)(dispatch).then(this.handleServerResponse());
    }
  }
  setMessege(message) {
    this.setState({
      message,
    });
  }

  setNextStage() {
    let { match, dispatch, history } = this.props;
    const { id } = match.params;

    setNextState(
      id,
      "attachment"
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/accident-owtw/notification/${id}`);
      })
    );
  }

  render() {
    const {
      dispatch,
      accident_owtw: {
        singleData: { stage },
        attachments,
        permission,
      },
      match,
    } = this.props;
    let { message } = this.state;

    let { id: accident_id } = match.params;
    let readOnly = !permission[11];
    return (
      <>
        <LayoutContent
          mobileNotHideMenu={true}
          message={message}
          SideMenu={
            <SideMenu
              accident_id={accident_id}
              // incident_id={incident_id}
              // permission={permission}
              stage={stage}
              addNewIncident={this.addNewIncident}
            />
          }
          error404={this.state.error_code == 404}
          // message={message}
        >
          <Box title={"Analiza zgłoszenia"}>
            <AttachmentList
              dispatch={dispatch}
              permission={permission}
              attachments={attachments}
              entry_id={accident_id}
              addAttachmentAction={addAttachment}
              deleteAttachmentAction={deleteAttachment}
              downloadAction={download}
              setParentMessage={this.setMessege}
              readOnly={readOnly}
            />
            <ButtonWrapper>
              {stage == 2 ? (
                <Button onClick={this.setNextStage} type="button">
                  Dalej
                </Button>
              ) : (
                <LinkContainer
                  exact
                  to={`/accident-owtw/notification/${accident_id}`}
                >
                  <Button type="button">Dalej</Button>
                </LinkContainer>
              )}
            </ButtonWrapper>
          </Box>
        </LayoutContent>
      </>
    );
  }
}

export default connect((store) => ({
  accident_owtw: store.accident_owtw,
}))(Attachment);
