export function getConfiguration() {
  return dispatch => dispatch({
    types: ["GET_CONFIGURATIONS_REQUEST", "GET_CONFIGURATIONS_SUCCESS", "GET_CONFIGURATIONS_ERROR"],
    payload: {
      request: {
        url: "/super-admin/configuration",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function saveConfiguration(group_id,data) {
  return dispatch => dispatch({
    types: ["SAVE_CONFIGURATIONS_REQUEST", "SAVE_CONFIGURATIONS_SUCCESS", "SAVE_CONFIGURATIONS_ERROR"],
    payload: {
      request: {
        url: `/super-admin/configuration/${group_id}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}