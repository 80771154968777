import React, { Fragment } from "react";

import { FieldTextareaBox } from "./Fields";

export default class PotentiallyAccidentalEvent extends React.PureComponent {
  render() {
    let { readOnly } = this.props;
    return (
      <Fragment>
        <FieldTextareaBox
          name="potentially_accidental_event"
          label="Szczegółowy opis zdarzenia potencjalnie wypadkowego / sytuacji niebezpiecznej / nieprawidłowości"
          disabled={readOnly}
        />
      </Fragment>
    );
  }
}
