import React from "react";
import styled from "styled-components";
import DayPicker from "lib/DayPicker";
import Select from "components/Select";
import { Field } from "react-final-form";

import { StyledInputBox } from "style/styleComponents/Form/input";
import validator, { composeValidators } from "components/Validation/index";

const TimeWrapperStyle = styled.div`
  display: flex;
  margin: 0 -10px 5px;
  & > * {
    margin: 0 10px;
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 20%;
    }
  }
`;

export const TimeWrapper = (props) => {
  let { label, name, disabled, readOnly } = props;

  return (
    <TimeWrapperStyle>
      <Field
        name="date_event"
        render={({ input, meta, ...rest }) => (
          <DayPicker
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            fullWidth
            disabled={readOnly}
            selectedDay={input.value}
            handleChange={(value) => {
              input.onChange(value);
            }}
          />
        )}
      />

      <Field
        name="date_event_hour_minutes"
        validate={composeValidators(validator.required, validator.timeHM)}
        render={({ input, meta, ...rest }) => (
          <StyledInputBox
            {...input}
            placeholder={"HH:mm"}
            disabled={readOnly}
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            onChange={(event) => {
              input.onChange(event.target.value);
            }}
          />
        )}
      />
    </TimeWrapperStyle>
  );
};
