import React, { Fragment } from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  getDraftRAMethods,
  deleteDraftRAMethod,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import LoadingScreen from "components/LoadingScreen";
import {
  ButtonWrapper,
  ButtonWithIcon,
  DeleteButton,
} from "components/ListComponents";
import { LinkContainer } from "components/LinkContainer";
import PageHeader from "react-bootstrap/lib/PageHeader";
import { StyledTableList } from "components/ListComponents";
import TableSortable from "components/TableSortable";
import Modal from "components/Modal/SimpleAccept";
import Pagination from "components/Pagination";

import { setUrls } from "./_staticData";
import SideMenu from "../_SideMenu";

const StyledTableWrapper = styled.div`
  overflow-x: auto;
`;

let defaultNames = {};

class RiskAssessmentList extends AppComponent {
  constructor(props) {
    super(props);

    let { urls = {}, names = {} } = this.props;

    this.state = {
      ...this.state,
      data: [],
      modal: {
        open: false,
        entry_id: null,
      },
      urls: setUrls(props.urls),
      names: { ...defaultNames },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteRiskAssessment = this.deleteRiskAssessment.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.updateMessage();

    getDraftRAMethods()(dispatch).then(this.handleServerResponse({}, () => {}));
  }

  deleteRiskAssessment() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modal;
    deleteDraftRAMethod(entry_id)(dispatch).then(this.handleServerResponse());
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  // handleDelete() {
  //   const { dispatch } = this.props;
  //   const { audit_type_id } = this.state.modal;
  //   deleteAuditType(audit_type_id)(dispatch).then(
  //     this.handleServerResponse({})
  //   );
  // }

  openModal(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.modal, open: true, entry_id } });
    };
  }

  closeModal() {
    this.setState({
      modal: { ...this.state.modal, open: false, entry_id: null },
    });
  }

  render() {
    const { modal, message, data: riskAssessmentView, urls } = this.state;
    let { methods = [] } = this.props;
    return (
      <Fragment>
        <LoadingScreen fetched={true}>
          <LayoutContent
            message={message}
            error404={this.state.error_code == 404}
            SideMenu={<SideMenu />}
          >
            <PageHeader>Szablony metod oceny ryzyka zawodowego</PageHeader>
            <ButtonWrapper>
              <LinkContainer to={urls.ADD}>
                <ButtonWithIcon icon="fa fa-plus" name="Dodaj nowy szablon" />
              </LinkContainer>
            </ButtonWrapper>
            <StyledTableWrapper>
              <StyledTableList quickMenu quickMenuTrird nowrapTitle>
                <TableSortable
                  headers={[
                    { name: "L.p." },
                    { name: "Nazwa", prop: "title" },
                    { name: "Usuń" },
                  ]}
                  data={riskAssessmentView}
                  render={(sortedData) => {
                    return sortedData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={urls.EDIT(`${item.id}`)}>{item.name}</Link>
                        </td>
                        <td>
                          <DeleteButton onClick={this.openModal(item.id)} />
                        </td>
                      </tr>
                    ));
                  }}
                ></TableSortable>
              </StyledTableList>
            </StyledTableWrapper>
            <Pagination
              handlePageChange={this.handlePageChange.bind(this)}
              data={methods}
            />
          </LayoutContent>
        </LoadingScreen>
        <Modal
          title="Usuń metody oceny ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modal.open}
          handleAccept={this.deleteRiskAssessment}
          handleClose={this.closeModal}
        />
      </Fragment>
    );
  }
}

export default connect((store) => ({
  methods: store.riskAssessment.methods,
  message: store.message,
}))(RiskAssessmentList);
