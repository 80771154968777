import React from 'react';
import Box from "./Box";
import {Form,Field} from "react-final-form";
import validator,{composeValidators} from "./Validation";
import {StyledInputBox,StyledTextareaBox} from "../style/styleComponents/Form/input";
import Button from "react-bootstrap/lib/Button";
import {ButtonWrapper} from "./ListComponents";
import Alert from "react-bootstrap/lib/Alert";
export default class PageContactForm extends React.Component {

  constructor(props){
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values,form){
    const {handleSubmit} = this.props;
    handleSubmit(values).then(()=>{
      form.reset();
    });
  }

  render(){
    const {message} = this.props;
    return (<div>
      {message && message.text ? <Alert bsStyle={message.className}>{message.text}</Alert> : ""}
      <Box title="Formularz kontaktowy">
        <Form
          ref={form => this.form = form}
          onSubmit={this.submit}
          render={({handleSubmit, pristine, invalid,form}) => (
            <form onSubmit={event=>{handleSubmit(event,form)}} style={{"width": "100%"}}>
              <Field
                name="name"
                validate={validator.required}
                render={({input, meta, ...rest}) => (
                  <StyledInputBox
                    {...input}
                    label={"Imie i nazwisko"}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}/>
              <Field
                name="phone"
                validate={validator.required}
                render={({input, meta, ...rest}) => (
                  <StyledInputBox
                    {...input}
                    label={"Nr telefonu"}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}/>
              <Field
                name="email"
                validate={composeValidators(validator.required, validator.mustBeEmail)}
                render={({input, meta, ...rest}) => (
                  <StyledInputBox
                    {...input}
                    label={"E-mail"}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}/>
              <Field
                name="content"
                validate={validator.required}
                render={({input, meta, ...rest}) => (
                  <StyledTextareaBox
                    {...input}
                    label={"Treść zapytania"}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}/>
              <ButtonWrapper>
                <Button type="submit" bsStyle="primary">
                  Wyślij
                </Button>
              </ButtonWrapper>
            </form>)}
        />
      </Box>
    </div>);
  }
}

