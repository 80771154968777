import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";

import {
  getMEArchives,
  deleteMEReferral,
  printReferral,
  deleteMEReferralByDates,
  getMEArchivesFilter,
  getMETypeList,
  getMEArchiveWorkplaces,
} from "actions/meActions";
import { getUserWorkplacesWithout } from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import {
  ButtonWithIcon,
  ButtonWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
import Pagination from "components/Pagination";
import PageHeader from "components/PageHeader";
import { StyledTableWrapper } from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";
import { LinkContainer } from "components/LinkContainer";
import ModalDelete from "components/Modal/SimpleAccept";
import TableSortableWithPagination from "components/TableSortable/Register";

import SideMenu from "../_SideMenu";
import SummaryMedicalExaminations from "../List/summaryMedicalExaminations";
import DeleteManyModal from "./DeleteManyModal";
import { getDate } from "./_common";
import { setUrls } from "../_staticData";
import saveBlobFile from "components/saveBlobFile";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "full_name", () => null);
  changeValue(state, "workplace_name", () => null);
  changeValue(state, "medical_examinations_type_id", () => null);
  changeValue(state, "me_date", () => null);
};

class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      urls: setUrls(),
      modalUserDetails: {
        open: false,
      },
      modalDelete: this.initModalState(),
    };

    this.clearFilter = this.clearFilter.bind(this);
    this.deleteME = this.deleteME.bind(this);
    this.print = this.print.bind(this);
    this.deleteMEReferralByDates = this.deleteMEReferralByDates.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;

    getMEArchives()(dispatch).then(this.handleServerResponse());
    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());
    getMETypeList()(dispatch).then(this.handleServerResponse());
    getMEArchiveWorkplaces()(dispatch).then(this.handleServerResponse());
  }

  clearFilter(form) {
    let { dispatch } = this.props;
    this.form.mutators.clearFilterMutator();
    getMEArchives()(dispatch).then(this.handleServerResponse());
  }

  deleteME() {
    let { dispatch } = this.props;
    let { modalDelete, urls } = this.state;

    deleteMEReferral(modalDelete.entry_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  deleteMEReferralByDates(data) {
    let { dispatch } = this.props;
    deleteMEReferralByDates(data)(dispatch).then(this.handleServerResponse());
  }

  handleFilterSubmit(data) {
    let { dispatch } = this.props;
    let filterZeroId = (data) => {
      let keys = ["medical_examinations_type_id", "workplace_name"];

      for (let k of keys) {
        if (data[k] == "0" || data[k] == "wg Stanowiska") {
          data[k] = null;
        }
      }
      return data;
    };

    getMEArchivesFilter(filterZeroId(data))(dispatch).then(
      this.handleServerResponse()
    );
  }

  print(id) {
    let { dispatch } = this.props;

    printReferral(id)(dispatch).then((res) => {
      if (!res.error) {
        let file = res.payload.data;
        let fileName = `Skierowanie na badania lekarskie.pdf`;

        saveBlobFile(file, fileName);
      }
    });
  }

  render() {
    const { message, data = [], urls } = this.state;
    const { workplaces, types, permission } = this.props;

    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={<SideMenu />}
      >
        <PageHeader>Archiwum skierowań na badania</PageHeader>
        <ButtonWrapper>
          <LinkContainer to={urls.MEREFERRAL}>
            <Button disabled={!permission[53]}>Wystaw skierowanie</Button>
          </LinkContainer>
          <DeleteManyModal
            deleteMEReferralByDates={this.deleteMEReferralByDates}
            disabled={!permission[53]}
          />
        </ButtonWrapper>
        <TableSortableWithPagination
          data={this.props.archives}
          renderSearcher={
            <Form
              ref={(form) => (this.form = form)}
              initialValues={{
                full_name: undefined,
                workplace_name: "wg Stanowiska",
                medical_examinations_type_id: "0",
                me_date: undefined,
              }}
              mutators={{ clearFilterMutator }}
              onSubmit={this.handleFilterSubmit}
              render={({ handleSubmit, pristine, invalid, form }) => {
                this.form = form;
                return (
                  <>
                    <tr>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"full_name"}
                          placeholder={"wg Nazwiska"}
                        />
                      </td>

                      <td>
                        <FieldSelect
                          name={"workplace_name"}
                          propValue={"name"}
                          data={[
                            { id: "0", name: "wg Stanowiska", disabled: true },
                          ].concat(workplaces)}
                        />
                      </td>

                      <td>
                        <FieldSelect
                          name={"medical_examinations_type_id"}
                          data={[
                            {
                              id: "0",
                              name: "wg Rodzaju badania",
                              disabled: true,
                            },
                          ].concat(types)}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"me_date"} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="13" style={{ padding: "8px 40px 8px 8px" }}>
                        <ButtonWrapper nowrap>
                          <Button
                            type="submit"
                            bsStyle="primary"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Szukaj
                          </Button>
                          <Button onClick={this.clearFilter}>
                            Wyłącz filtr
                          </Button>
                        </ButtonWrapper>
                      </td>
                    </tr>
                  </>
                );
              }}
            />
          }
          headers={[
            {
              name: "Nazwisko i imię pracownika",
              prop: "full_name",
            },

            { name: "Stanowisko pracy", prop: "workplace_name" },
            { name: "Rodzaj badania", prop: "me_type_name" },
            {
              name: "Data wystawienia skierowania",
              prop: "me_date",
            },
            {
              name: "Usuń",
            },
            {
              name: "Pobierz",
            },
          ]}
          render={(sortedData) => {
            return sortedData.map((item) => {
              return (
                <tr>
                  <td>
                    <LinkContainer to={urls.MEREFERRALEDIT(item.id)}>
                      <a onClick={this.openModal("modalUserDetails")}>
                        {item.full_name}
                      </a>
                    </LinkContainer>
                  </td>
                  <td>{item.workplace_name}</td>
                  <td>{item.me_type_name}</td>
                  <td>{getDate(item.me_date)}</td>
                  <td>
                    <ButtonWithIcon
                      disabled={!permission[61]}
                      icon="fa fa-trash"
                      onClick={this.openModal("modalDelete", {
                        entry_id: item.id,
                      })}
                    ></ButtonWithIcon>
                  </td>
                  <td>
                    <ButtonWithIcon
                      disabled={!permission[56]}
                      icon="fa fa-print"
                      onClick={() => {
                        this.print(item.id);
                      }}
                    ></ButtonWithIcon>
                  </td>
                </tr>
              );
            });
          }}
        />

        {/* <SummaryMedicalExaminations
          open={this.state.modalUserDetails.open}
          closeModal={this.closeModal("modalUserDetails")}
        /> */}
        <ModalDelete
          open={this.state.modalDelete.open}
          handleClose={this.closeModal("modalDelete")}
          title={"Usuń skierowanie"}
          description="Czy chcesz usunąć dany wpis?"
          handleAccept={this.deleteME}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  permission: store.me.permission,
  archives: store.me.archives,
  types: store.me.types,
  workplaces: store.me.archive_workplaces,
}))(List);
