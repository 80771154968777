//based on
//https://codepen.io/nickjvm/pen/bERraX
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import Day, { StyledDay } from "./Day";
import DayPicker from "../../lib/DayPicker";
const styles = (
  <style type="text/css">
    {`.vertical-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin:0
}

.calendar header .arrow {
  text-align: center;
  -ms-flex-preferred-size: 15%;
      flex-basis: 15%;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: background .2s;
  transition: background .2s;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}



`}
  </style>
);
const CalendarBox = styled.section`
  display: block;
  background: #ffffff;
  width: 300px;
  border: solid 1px #cccccc;
  margin: 10px auto;
  font-size: 1.3rem;
  text-align: center;
`;

const Header = styled.header`
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  cursor: default;
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const MonthLabel = styled.span`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
`;
const MonthDisplay = styled.div`
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  background: ${(props) => props.theme.primaryColor};
`;

const Arrow = styled.i`
  &:hover {
    background: ${(props) => props.theme.primaryDarkColor};
  }
`;

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      month: moment(props.initialDate),
      selected: moment(props.initialDate).startOf("day"),
    };
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    //TODO getEvents
    const { getNewEvents = () => {} } = this.props;
    const startDate = this.state.month
      .clone()
      .startOf("month")
      .startOf("week")
      .add(0, "days")
      .format("YYYY-MM-DD");
    const endDate = this.state.month
      .clone()
      .endOf("month")
      .endOf("week")
      .format("YYYY-MM-DD");
    getNewEvents(startDate, endDate);
  }

  previous() {
    const { getNewEvents = () => {} } = this.props;

    const { month } = this.state;
    const newMonth = month.subtract(1, "month");
    const startDate = this.state.month
      .clone()
      .startOf("month")
      .startOf("week")
      .add(0, "days")
      .format("YYYY-MM-DD");
    const endDate = newMonth
      .clone()
      .endOf("month")
      .endOf("week")
      .format("YYYY-MM-DD");
    getNewEvents(startDate, endDate);

    this.setState({
      month: newMonth,
    });
  }

  next() {
    const { month } = this.state;
    //TODO getEvents
    this.setState({
      month: month.add(1, "month"),
    });
  }

  select(day) {
    this.setState({
      selected: day.date,
      month: day.date.clone(),
    });
  }

  renderWeeks() {
    let weeks = [];
    let done = false;
    let date = this.state.month
      .clone()
      .startOf("month")
      .startOf("week")
      .add(0, "days");
    let count = 0;
    let monthIndex = date.month();
    const { selected, month } = this.state;
    const { events } = this.props;

    while (!done) {
      weeks.push(
        <Week
          key={date}
          date={date.clone()}
          month={month}
          select={(day) => this.select(day)}
          selected={selected}
          events={events}
        />
      );

      date.add(1, "w");

      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }

    return weeks;
  }

  renderMonthLabel() {
    const { month } = this.state;

    return (
      <MonthLabel className="month-label">
        {month.locale("pl").format("MMMM YYYY")}
      </MonthLabel>
    );
  }

  render() {
    return (
      <div>
        {styles}
        <CalendarBox className="calendar">
          <Header className="header">
            <MonthDisplay className="month-display row">
              <Arrow
                className="arrow fa fa-angle-left"
                onClick={this.previous}
              />
              {this.renderMonthLabel()}
              <Arrow className="arrow fa fa-angle-right" onClick={this.next} />
            </MonthDisplay>
            <DayNames />
          </Header>
          {this.renderWeeks()}
        </CalendarBox>
      </div>
    );
  }
}
const DayNamesBox = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  cursor: default;
  font-size: 1.2rem;
`;

const DayNamesBoxBox = StyledDay.extend`
  cursor: default;
  &:hover {
    background: inherit;
    color: inherit;
  }
`;

class DayNames extends React.Component {
  render() {
    return (
      <DayNamesBox className="row day-names">
        <DayNamesBoxBox className="day">Pon.</DayNamesBoxBox>
        <DayNamesBoxBox className="day">Wt.</DayNamesBoxBox>
        <DayNamesBoxBox className="day">Śr.</DayNamesBoxBox>
        <DayNamesBoxBox className="day">Czw.</DayNamesBoxBox>
        <DayNamesBoxBox className="day">Pt.</DayNamesBoxBox>
        <DayNamesBoxBox className="day">Sb.</DayNamesBoxBox>
        <DayNamesBoxBox className="day">Ndz.</DayNamesBoxBox>
      </DayNamesBox>
    );
  }
}

const RowWeek = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  border-top: solid 1px #cccccc;
  &:first-child {
    border-top: none;
  }
`;

export class Week extends React.Component {
  render() {
    let days = [];
    let { date } = this.props;

    const { month, selected, select, events = {} } = this.props;

    for (var i = 0; i < 7; i++) {
      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === month.month(),
        isToday: date.isSame(new Date(), "day"),
        date: date,
      };

      const eventId = date.format("YYYY-MM-DD");

      days.push(
        <Day
          key={"week-" + i}
          day={day}
          selected={selected}
          select={select}
          events={events[eventId]}
        />
      );

      date = date.clone();
      date.add(1, "day");
    }

    return <RowWeek key={days[0]}>{days}</RowWeek>;
  }
}

export default Calendar;

Calendar.propTypes = {
  events: PropTypes.objectOf(PropTypes.array),
  getNewEvents: PropTypes.func,
};

DayPicker.propTypes = {
  dateString: PropTypes.bool,
  icon: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};
