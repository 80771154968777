import React from "react";

import { Form } from "react-final-form";

import Box from "components/Box";
import { Button } from "components/Buttons";
import { FieldInput, FieldTextareaBox } from "components/Fields";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { ButtonWrapper } from "components/Wrappers";
import validator, { composeValidators } from "components/Validation/index";

export class ModalHazardForm extends React.PureComponent {
  render() {
    let {
      data,
      submit = () => {},
      submitButtonName = "Zakończ i zapisz",
      readOnly,
    } = this.props;

    return (
      <Form
        onSubmit={submit}
        initialValues={data}
        render={({ handleSubmit, pristine, invalid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldInput
                disabled={readOnly}
                name="name"
                validate={validator.required}
                label="Zagrożenie (zdarzenie)"
              />
              <FieldTextareaBox
                disabled={readOnly}
                name="source"
                label="Źródło (przyczyny) zagrożenia"
              />
              <FieldTextareaBox
                disabled={readOnly}
                name="consequences"
                label="Możliwe skutki zagrożenia"
              />
              <FieldTextareaBox
                disabled={readOnly}
                name="prevention"
                label="Środki profilaktyczne"
              />
              <ButtonWrapper>
                <Button type="submit">{submitButtonName}</Button>
              </ButtonWrapper>
            </form>
          );
        }}
      />
    );
  }
}

export default class AddForm extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      data,
      message,
      submit = () => {},
      submitButtonName = "Zakończ i zapisz",
      SideMenu,
    } = this.props;

    return (
      <LayoutContent
        message={message}
        SideMenu={SideMenu ? <SideMenu /> : <></>}
      >
        <Box title={`${false ? "Edytuj" : "Dodaj"} wzorcowe zagrożenie`}>
          <ModalHazardForm {...this.props} />
        </Box>
      </LayoutContent>
    );
  }
}
