import React from "react";
import validator from "./Validation";
import { LayoutContent } from "./Layout";
import Box from "./Box";
import { Form, Field } from "react-final-form";
import { StyledInputBox } from "../style/styleComponents/Form/input";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import { StyledInputMessage } from "../style/styleComponents/Form/input";
import { LinkContainer } from "./LinkContainer";
import { ButtonWrapper } from "./ListComponents";

const QuestionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 -5px 10px;
  & > * {
    margin: 0 5px;
  }
`;

class AuditTypeForm extends React.Component {
  render() {
    const {
      handleSubmit,
      readOnly,
      data,
      message,
      error_code,
      SideMenu,
      permission,
      cancelLink,
      title = "Dodaj nowy rodzaj audytu",
    } = this.props;
    return (
      <LayoutContent
        error404={error_code}
        message={message}
        SideMenu={SideMenu}
      >
        <Box title={title}>
          <Form
            onSubmit={handleSubmit}
            mutators={arrayMutators}
            initialValues={data}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="title"
                  validate={validator.required}
                  render={({ input, meta, ...rest }) => (
                    <StyledInputBox
                      {...input}
                      label="Tytuł"
                      disabled={readOnly}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
                <FieldArray name="questions">
                  {({ fields, meta: { error } }) => (
                    <div>
                      {fields.map((name, index) => (
                        <div key={index}>
                          {fields.value[index].category ? (
                            <div>
                              <Field
                                name={`${name}.name`}
                                validate={validator.required}
                                render={({ input, meta, ...rest }) => (
                                  <QuestionWrapper>
                                    <StyledInputBox
                                      {...input}
                                      disabled={readOnly}
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : null
                                      }
                                      onChange={(event) => {
                                        input.onChange(event.target.value);
                                      }}
                                    />
                                    <Button
                                      disabled={readOnly}
                                      onClick={() => fields.remove(index)}
                                    >
                                      <i className="fa fa-times" />
                                    </Button>
                                  </QuestionWrapper>
                                )}
                              />
                              <FieldArray
                                name={`${name}.questions`}
                                render={({ fields: subfields }) => (
                                  <div style={{ paddingLeft: "20px" }}>
                                    {subfields.map((name, subindex) => (
                                      <div key={`${index}.${subindex}`}>
                                        <Field
                                          name={`${name}.name`}
                                          validate={validator.required}
                                          render={({
                                            input,
                                            meta,
                                            ...rest
                                          }) => (
                                            <QuestionWrapper>
                                              <StyledInputBox
                                                {...input}
                                                disabled={readOnly}
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                helperText={
                                                  meta.error && meta.touched
                                                    ? meta.error
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  input.onChange(
                                                    event.target.value
                                                  );
                                                }}
                                              />
                                              <Button
                                                disabled={readOnly}
                                                onClick={() =>
                                                  subfields.remove(subindex)
                                                }
                                              >
                                                <i className="fa fa-times" />
                                              </Button>
                                            </QuestionWrapper>
                                          )}
                                        />
                                      </div>
                                    ))}
                                    <ButtonWrapper>
                                      <Button
                                        disabled={readOnly}
                                        onClick={() =>
                                          subfields.push({ category: false })
                                        }
                                      >
                                        Dodaj pytanie
                                      </Button>
                                    </ButtonWrapper>
                                  </div>
                                )}
                              ></FieldArray>
                            </div>
                          ) : (
                            <Field
                              name={`${name}.name`}
                              validate={validator.required}
                              render={({ input, meta, ...rest }) => (
                                <QuestionWrapper>
                                  <StyledInputBox
                                    {...input}
                                    disabled={readOnly}
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : null
                                    }
                                    onChange={(event) => {
                                      input.onChange(event.target.value);
                                    }}
                                  />
                                  <Button
                                    disabled={readOnly}
                                    onClick={() => fields.remove(index)}
                                  >
                                    <i className="fa fa-times" />
                                  </Button>
                                </QuestionWrapper>
                              )}
                            />
                          )}
                        </div>
                      ))}
                      <ButtonWrapper>
                        <Button
                          disabled={readOnly}
                          onClick={() => fields.push({ category: false })}
                        >
                          Dodaj pytanie
                        </Button>
                        <Button
                          disabled={readOnly}
                          onClick={() => fields.push({ category: true })}
                        >
                          Dodaj kategorię
                        </Button>
                      </ButtonWrapper>
                    </div>
                  )}
                </FieldArray>

                <ButtonWrapper>
                  <Button disabled={readOnly} bsStyle="primary" type="submit">
                    Zakończ i zapisz
                  </Button>
                  <LinkContainer
                    to={cancelLink ? cancelLink : "/audit/type"}
                    exact
                  >
                    <Button>Anuluj</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}
          />
        </Box>
      </LayoutContent>
    );
  }
}

export default AuditTypeForm;
