export function getPages() {
  return dispatch => dispatch({
    types: ["GET_PAGES_REQUEST", "GET_PAGES_SUCCESS", "GET_PAGES_ERROR"],
    payload: {
      request: {
        url: "/super-admin/page",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function getPage(id) {
  return dispatch => dispatch({
    types: ["GET_PAGE_REQUEST", "GET_PAGE_SUCCESS", "GET_PAGE_ERROR"],
    payload: {
      request: {
        url: `/super-admin/page/${id}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function addPage(data) {
  return dispatch => dispatch({
    types: ["ADD_PAGE_REQUEST", "ADD_PAGE_SUCCESS", "ADD_PAGE_ERROR"],
    payload: {
      request: {
        url: `/super-admin/page/add`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function savePage(id,data) {
  return dispatch => dispatch({
    types: ["SAVE_PAGE_REQUEST", "SAVE_PAGE_SUCCESS", "SAVE_PAGE_ERROR"],
    payload: {
      request: {
        url: `/super-admin/page/save/${id}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function deletePage(id,data) {
  return dispatch => dispatch({
    types: ["DELETE_PAGE_REQUEST", "DELETE_PAGE_SUCCESS", "DELETE_PAGE_ERROR"],
    payload: {
      request: {
        url: `/super-admin/page/delete/${id}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function getPageByUrl(url) {
  return dispatch => dispatch({
    types: ["GET_PAGE_REQUEST", "GET_PAGE_SUCCESS", "GET_PAGE_ERROR"],
    payload: {
      request: {
        url: `/page/${url}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function getPagePotentialParent(page_id) {
  return dispatch => dispatch({
    types: ["GET_PAGES_REQUEST", "GET_PAGES_SUCCESS", "GET_PAGES_ERROR"],
    payload: {
      request: {
        url: `/super-admin/page/get-potential-parent/${page_id ? page_id : ''}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function getPublicMenuPages(){
  return dispatch => dispatch({
    types: ["GET_PAGES_MENU_REQUEST", "GET_PAGES_MENU_SUCCESS", "GET_PAGES_MENU_ERROR"],
    payload: {
      request: {
        url: `/pages`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function sendContactMessage(data){
  return dispatch => dispatch({
    types: ["SEND_PAGE_CONTACT_MESSAGE_REQUEST", "SEND_PAGE_CONTACT_MESSAGE_SUCCESS", "SEND_PAGE_CONTACT_MESSAGE_ERROR"],
    payload: {
      request: {
        url: `/page/send-contact-message`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function clearPage() {
  return dispatch => dispatch({
    type: "CLEAR_PAGE",
    payload: {}
  });
}