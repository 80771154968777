import React from "react";
import SideMenu from "components/SideMenu";
import { defaultUrls as urls } from "../_staticData";
import UpMenu from "../_SideMenu";
const LSideMenu = ({
  id,
  stage = 0,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <div>
    <UpMenu />
    <SideMenu
      title="Dodaj nową ocenę ryzyka"
      data={[
        {
          name: "Informacje ogólne",
          url: id ? urls.BASIC(id) : urls.ADD,
          notExact: true,
        },
        {
          disabled: !id || stage < 1,
          name: "Identyfikacji zagrożeń",
          url: urls.DANGER(id),
          notExact: true,
        },
        {
          disabled: !id || stage < 2,
          name: "Charakterystyka ocenianego stanowiska pracy",
          url: urls.CHARACTERISTIC(id),
          notExact: true,
        },
        {
          disabled: !id || stage < 3,
          name: "Analiza ryzyka zawodowego",
          url: urls.ANALYSIS(id),
          notExact: true,
        },
        {
          disabled: !id || stage < 4,
          notExact: true,
          name: "Działania zmniejszające ryzyko",
          url: urls.TASK(id),
        },
        {
          disabled: !id || stage < 5,
          notExact: true,
          name: "Powiadomienia",
          url: urls.NOTIFICATION(id),
        },
      ]}
    />
  </div>
);

export default LSideMenu;
