import React from "react";
import Button from "react-bootstrap/lib/Button";

import { printListPDF } from "actions/incidentActions";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldInputCheckBox } from "components/Fields";
import { TwoInputWrapper } from "components/Wrappers";
import saveBlobFile from "components/saveBlobFile";
import { StyledInputBox } from "style/styleComponents/Form/input";

let checkboxes = [
  {
    name: "title_page",
    label: "Strona tytułowa dokumentacji powypadkowej",
    prop: "title_page_pdf",
    value: "title_page.pdf",
    stage: 0,
  },
  {
    name: "protocol",
    label: "Protokół powypadkowy",
    prop: "protocol_pdf",
    value: "protocol.pdf",
    stage: 1,
    type_id: 1,
    person_status_id: 1,
  },
  {
    name: "accident_card",
    label: "Karta wypadku",
    prop: "accident_card_pdf",
    value: "accidentCard.pdf",
    stage: 1,
    type_id: 1,
    person_status_id: 2,
  },
  {
    name: "victim_explain",
    label: "Wyjaśnienia poszkodowanego",
    prop: "victim_explain_pdf",
    value: "victimexplain_id",
  },
  {
    name: "statistic_card",
    label: "Karta statystyczna wypadku Z-KW",
    prop: "statistic_card_pdf",
    value: "statistic.pdf",
    type_id: 1,
  },
  {
    name: "register",
    label: "Zgłoszenie wypadku przy pracy",
    prop: "register_pdf",
    value: "register.pdf",
    type_id: 1,
  },
  {
    name: "accident_task",
    label: "Polecenia powypadkowe",
    prop: "accident_tasks_pdf",
    value: "accident_tasks.pdf",
    type_id: 1,
  },
  {
    name: "team",
    label: "Zarządzenie o powołaniu zespołu powypadkowego",
    prop: "team_pdf",
    value: "ConvincingAnAccidentTeam.pdf",
    type_id: 1,
    person_status_id: 1,
  },
];

let dictionary = {
  register: {
    dataprop: "register_pdf",
    filename: "Zgłoszenie wypadku przy pracy",
  },
  accident_card: {
    dataprop: "accident_card_pdf",
    filename: "Karta wypadku",
  },
  statistic_card: {
    dataprop: "statistic_card_pdf",
    filename: "Karta statystyczna wypadku Z-KW",
  },
  title_page: {
    dataprop: "title_page_pdf",
    filename: "Strona tytułowa dokumentacji powypadkowej",
  },
  victim_explain: {
    dataprop: "victim_explain_pdf",
    filename: "Wyjaśnienia poszkodowanego",
  },
  accident_task: {
    dataprop: "accident_tasks_pdf",
    filename: "Polecenia powypadkowe",
  },
  protocol: {
    dataprop: "protocol_pdf",
    filename: "Protokół powypadkowy",
  },
  team: {
    dataprop: "team_pdf",
    filename: "Zarządzenie o powołaniu zespołu powypadkowego",
  },
};

export default class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
      checkboxes: [],
      dictionary,
    };

    this.downloadpdfs = this.downloadpdfs.bind(this);
  }

  componentDidMount() {
    let { data } = this.props;
    let { witnesses } = data;
    witnesses = witnesses || [];
    let witnessCheckboxes = witnesses.map((item, index) => {
      return {
        name: `witness_${item.id}`,
        label: `Informacje świadka ${index + 1}`,
        prop: "register_pdf",
        index,
      };
    });
    let modalCheckboxes = checkboxes
      .filter((item) => {
        return !!data[item.prop];
      })
      .concat(witnessCheckboxes);
    this.setState({
      checkboxes: checkboxes
        .filter((item) => {
          if (item.stage && item.stage > data.stage) {
            return false;
          }
          if (item.type_id && item.type_id !== data.type_id) {
            return false;
          }
          if (
            item.person_status_id &&
            data.incident_has_person &&
            data.incident_has_person.length &&
            data.incident_has_person[0].person_status_id &&
            data.incident_has_person[0].person_status_id !==
              item.person_status_id
          ) {
            return false;
          }
          return true;
          return !!data[item.prop];
        })
        .concat(witnessCheckboxes),
      dictionary: {
        ...dictionary,
        ...witnessCheckboxes.reduce((obj, item) => {
          obj[item.name] = {
            dataprop: "pdf",
            filename: item.label,
            index: item.index,
          };
          return obj;
        }, {}),
      },
    });
  }

  downloadpdfs(values) {
    let { data, dispatch } = this.props;
    let { dictionary } = this.state;
    let incident_id = data.id;
    let keys = Object.keys(values);
    let doc;
    for (let key of keys) {
      let { dataprop, filename, index } = dictionary[key];
      if (/^witness_/.test(key)) {
        doc = `${key}.pdf`;
      } else {
        const checkbox = checkboxes.find((item) => {
          return item.prop == dataprop;
        });
        doc = checkbox.value;
        // doc = data[dataprop];
      }

      printListPDF(incident_id, { filename: doc })(dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `${filename}.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    }
  }

  render() {
    let { permission, data } = this.props;
    return (
      <>
        <ButtonWithIcon
          disabled={!permission[19] || data.type_id != 1}
          icon="fa fa-print"
          type="button"
          onClick={() => {
            this.setState({ open: true });
          }}
        />
        <FormModal
          open={this.state.open}
          title="Drukuj dokumenty zdarzenia"
          acceptButtonName="Wygeneruj PDF"
          cancelButtonName="Anuluj"
          handleSubmit={(values) => {
            this.downloadpdfs(values);
          }}
          handleClose={() => {
            this.setState({ open: false });
          }}
        >
          {this.state.checkboxes.map((item) => {
            return <FieldInputCheckBox name={item.name} label={item.label} />;
          })}
        </FormModal>{" "}
      </>
    );
  }
}
