import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";

import {
  addME,
  getMETypeList,
  saveReferral,
  getMEDraftList,
  getMEReferral,
  printReferral,
  clearMEReducer,
} from "actions/meActions";

import { getPublicUserBasics, clearUsersReducer } from "actions/userActions";

import AppComponent from "components/AppComponent";
import saveBlobFile from "components/saveBlobFile";

import MedicalReferral from "components/MedicalReferral.Form";
import SideMenu from "./_SideMenu";

import { connect } from "react-redux";
import { setUrls } from "./_staticData";
class Referral extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };

    this.saveME = this.saveME.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    let { dispatch, match, location } = this.props;
    let query = new URLSearchParams(this.props.location.search);
    let user_id = query.get("user_id");
    let { id } = match.params;
    getMETypeList()(dispatch).then(this.handleServerResponse());
    getMEDraftList()(dispatch).then(this.handleServerResponse());
    if (id) {
      getMEReferral(id)(dispatch).then(this.handleServerResponse());
    }
    if (user_id) {
      this.getUserData(user_id);
    }
  }

  getUserData(user_id) {
    let { dispatch } = this.props;
    getPublicUserBasics(user_id)(dispatch).then(this.handleServerResponse());
  }

  print(manualId) {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    let requestId = manualId || id;
    printReferral(requestId)(dispatch).then((res) => {
      if (!res.error) {
        let file = res.payload.data;
        let fileName = `Skierowanie na badania lekarskie.pdf`;

        saveBlobFile(file, fileName);
      } else {
        console.log(res.error);
      }
    });
  }

  saveME(data) {
    let { urls } = this.state;
    let { dispatch, history, match } = this.props;
    let { id = null } = match.params;
    let { print, ...rest } = data;
    saveReferral(
      id,
      rest
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        if (print) {
          this.print(action.payload.data.data.id);
          history.push(urls.MEREFERRALEDIT(action.payload.data.data.id));
        } else {
          history.push(urls.MEREFERRALARCHIVED);
        }
      })
    );
  }

  render() {
    let { message, urls } = this.state;
    let { types, drafts, referral, user, permission, match } = this.props;
    let { id } = match.params;

    let query = new URLSearchParams(this.props.location.search);
    let user_id = query.get("user_id");
    if (user_id) {
      referral = {
        ...referral,
        sex_id: user.sex_id,
        pesel: user.pesel,
        full_name: user.full_name,
        residence_fulladdress: user.residence_fulladdress,
        workplace_name: user.workplace_name,
      };
    }

    return (
      <>
        <MedicalReferral
          id={id}
          urls={urls}
          users_id={user_id}
          SideMenu={SideMenu}
          saveME={this.saveME}
          types={types}
          drafts={drafts}
          data={referral}
          archived={true}
          print={this.print}
          message={message}
          readOnly={referral.id ? !permission[62] : !permission[53]}
        />
      </>
    );
  }

  componentWillUnmount() {
    clearUsersReducer()(this.props.dispatch);
    clearMEReducer()(this.props.dispatch);
  }
}

export default connect((store) => ({
  permission: store.me.permission,
  types: store.me.types,
  drafts: store.me.drafts,
  referral: store.me.referral,
  user: store.users.singleData,
}))(Referral);
