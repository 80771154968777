import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

// import { ButtonWrapper } from "./ListComponents";

import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
} from "components/Wrappers";
import {
  FieldInputCheckBox,
  FieldDayPicker,
  FieldSelect,
} from "components/Fields";
import { LinkContainer } from "components/LinkContainer";

import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";

import { TableSortHeader } from "components/TableSortable";

import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";

const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

class SummaryMedicalExaminationsModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.addNewQE = this.addNewQE.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  addNewQE() {
    let { addNewQE, closeModal } = this.props;
    addNewQE();
    this.closeModal();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  submit(data) {
    let { addOSH } = this.props;
    addOSH(data);
    this.closeModal();
  }

  render() {
    let { open, types = [], data, addOSH } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Dodaj nowe szkolenie BHP</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={data}
              onSubmit={this.submit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TwoInputWrapper>
                      <div>
                        <FieldSelect
                          name="osh_training_type_id"
                          label="Rodzaj szkolenia"
                          data={types}
                        />
                        <FieldDayPicker
                          name="date_completed"
                          label="Data ukończenia szkolenia (rrrr-mm-dd)"
                        />
                        <FieldDayPicker
                          name="date_next"
                          label="Data następnego szkolenia (rrrr-mm-dd)"
                        />
                      </div>
                    </TwoInputWrapper>
                    <ButtonWrapper>
                      <Button disabled={!addOSH} type="submit">
                        Zapisz
                      </Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class SummaryMedicalExaminations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: true,
    };
  }

  openModal() {}

  render() {
    let { open, closeModal, types } = this.props;

    return (
      <>
        <SummaryMedicalExaminationsModal {...this.props} />
      </>
    );
  }
}
