import React from "react";
import Button from "react-bootstrap/lib/Button";
import { OnChange } from "react-final-form-listeners";

import { Form, Field } from "react-final-form";

import AppComponent from "components/AppComponent";
import {
  FieldInput,
  FieldDayPicker,
  FieldSelect,
  FieldInput33,
  FieldTextareaBox,
} from "components/Fields";
import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import { LinkContainer } from "components/LinkContainer";
import {
  ButtonWrapper,
  LeftPadding,
  TwoInputWrapper,
} from "components/Wrappers";
import validator from "components/Validation";

let draftKeys = [
  "workplace_description",
  "wc_physical",
  "wc_powder",
  "wc_chemical",
  "wc_biological",
  "wc_other",
  "factor_counts",
];

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};
export default class Referral extends AppComponent {
  constructor(props) {
    super(props);
    // this.resetDraft = this.resetDraft.bind(this);
    this.setDraftData = this.setDraftData.bind(this);
  }

  setDraftData(value) {
    let { drafts } = this.props;

    let item = drafts.find((i) => {
      return i.id == value;
    });

    for (let k of draftKeys) {
      this.form.mutators.setField(k)(item[k]);
    }
  }

  render() {
    let {} = this.state;
    let {
      id,
      archived,
      data,
      drafts,
      print,
      saveME = () => {},
      SideMenu,
      types,
      urls,
      users_id,
      message,
      readOnly = false,
    } = this.props;

    let user_lock = users_id || readOnly;

    return (
      <>
        <LayoutContent
          mobileNotHideMenu={true}
          SideMenu={<SideMenu />}
          error404={this.state.error_code == 404}
          message={message}
        >
          <Box title={"Wystaw skierowania na badania"}>
            <Form
              onSubmit={saveME}
              initialValues={data}
              mutators={{ setField }}
              render={({
                handleSubmit,
                pristine,
                invalid,
                values = {},
                form,
              }) => {
                this.form = form;
                return (
                  <form onSubmit={handleSubmit}>
                    <TwoInputWrapper>
                      <FieldInput
                        disabled={readOnly}
                        name="me_place"
                        label={"Miejscowość"}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldDayPicker
                        readOnly={readOnly}
                        name="me_date"
                        label={"Data"}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldSelect
                        validate={validator.required}
                        disabled={readOnly}
                        name="medical_examinations_type_id"
                        label={"Rodzaj badania"}
                        data={types}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldSelect
                        name="sex_id"
                        disabled={user_lock}
                        label={"Płeć"}
                        data={[
                          { id: 1, name: "kobieta" },
                          { id: 2, name: "meżczyzna" },
                        ]}
                      />
                    </TwoInputWrapper>

                    <TwoInputWrapper>
                      <FieldInput
                        disabled={user_lock}
                        name="full_name"
                        label={"Imie i nazwisko"}
                      />
                      <FieldInput
                        disabled={user_lock}
                        name="pesel"
                        label={"PESEL"}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldInput
                        disabled={user_lock}
                        name="residence_fulladdress"
                        label={"Adres zamieszkania"}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldInput
                        disabled={user_lock}
                        name="workplace_name"
                        label={"Stanowisko pracy"}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldSelect
                        name="draft_id"
                        disabled={readOnly}
                        label="Użyć szablonu wzoru skierowania na badania?"
                        data={drafts.map((i) => ({
                          ...i,
                          name: i.workplace_name,
                        }))}
                      />
                      <OnChange name="draft_id">{this.setDraftData}</OnChange>
                    </TwoInputWrapper>

                    <FieldTextareaBox
                      name="workplace_description"
                      disabled={readOnly}
                      label={
                        "Określenie stanowiska/stanowisk pracy (opisać: rodzaj pracy, podstawowe czynności, sposób i czas ich wykonywania)"
                      }
                    />

                    <h5>Warunki pracy (nazwa i wielkość narażenia)</h5>
                    <LeftPadding>
                      <FieldTextareaBox
                        name="wc_physical"
                        label={"Czynniki fizyczne"}
                        disabled={readOnly}
                      />
                      <FieldTextareaBox
                        disabled={readOnly}
                        name="wc_powder"
                        label={"Pyły"}
                      />
                      <FieldTextareaBox
                        name="wc_chemical"
                        label={"Czynniki chemiczne"}
                        disabled={readOnly}
                      />
                      <FieldTextareaBox
                        name="wc_biological"
                        label={"Czynniki biologiczne"}
                        disabled={readOnly}
                      />
                      <FieldTextareaBox
                        name="wc_other"
                        label={"Inne czynniki, w tym niebezpieczne"}
                        disabled={readOnly}
                      />
                    </LeftPadding>
                    <FieldInput33
                      name="factor_counts"
                      type="number"
                      disabled={readOnly}
                      label={
                        " Łączna liczba czynników niebezpiecznych, szkodliwych, uciążliwych i innych"
                      }
                    />
                    {/* {draftKeys.map((k) => {
                      return <OnChange name={k}>{this.resetDraft}</OnChange>;
                    })} */}

                    <ButtonWrapper>
                      <LinkContainer exact to="/medical-examination">
                        <Button>Anuluj</Button>
                      </LinkContainer>

                      {print && (
                        <Button
                          onClick={() => {
                            if (id) {
                              print();
                            } else {
                              form.change("print", true);
                              handleSubmit();
                              form.change("print", false);
                            }
                          }}
                        >
                          Wygeneruj PDF
                        </Button>
                      )}
                      {readOnly ? (
                        <LinkContainer exact to="/medical-examination">
                          <Button disabled={readOnly} bsStyle="primary">
                            Zamknij
                            {/* {archived ? `Zapisz i zamknij` : "Zapisz"} */}
                          </Button>
                        </LinkContainer>
                      ) : (
                        <Button type="submit" bsStyle="primary">
                          Zapisz i zamknij
                        </Button>
                      )}
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Box>
        </LayoutContent>
      </>
    );
  }
}
