import React from "react";
import { connect } from "react-redux";

import {
  getVictim,
  saveVictim,
  saveVictimExplain,
  generateVictimExplain,
} from "actions/accidentOWTWActions";
import { getDictionary } from "actions/dictionaryActions";

import ModalVictimData from "components/Modal/VictimData";
import ModalVictimExplains from "components/Modal/VictimExplains";
import saveBlobFile from "components/saveBlobFile";

import AppComponent from "components/AppComponent";

class VictimData extends AppComponent {
  constructor(props) {
    super(props);

    this.updateVictimData = this.updateVictimData.bind(this);
    this.updateVictimExplain = this.updateVictimExplain.bind(this);
  }

  componentDidMount() {
    let { accident_id, dispatch } = this.props;
    Promise.all([
      getDictionary("accident-owth-insurance")(dispatch).then(
        this.handleServerResponse()
      ),
      getVictim(accident_id)(dispatch).then(this.handleServerResponse()),
    ]);
  }

  updateVictimData(values, callback) {
    let { accident_id, dispatch } = this.props;
    let { full_name, ...data } = values;
    data.victim_fullname = full_name;
    saveVictim(
      accident_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        callback();
      })
    );
  }

  updateVictimExplain(value, callback) {
    let { accident_id, dispatch } = this.props;
    let { file, data } = value;
    saveVictimExplain(
      accident_id,
      value
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        if (!file) {
          callback();
        } else {
          generateVictimExplain(accident_id)(dispatch).then((res) => {
            if (!res.error) {
              const file = res.payload.data;
              const fileName = `wyjaśnienia_poszkodowanego_${accident_id}.pdf`;

              saveBlobFile(file, fileName);
              callback();
            }
          });
        }
      })
    );
  }

  render() {
    let {
      dictionary,
      accident_owtw: { victim },
    } = this.props;

    return (
      <>
        <ModalVictimData
          dictionary={dictionary.accident_owth_insurance}
          accept={this.updateVictimData}
          data={victim}
        />
        <h5>Oświadczenie poszkodowanego</h5>
        <ModalVictimExplains
          labels={{ description: "Opis zdarzenia:" }}
          data={victim}
          accept={this.updateVictimExplain}
        />
      </>
    );
  }
}

export default connect((store) => ({
  dictionary: store.dictionary,
  accident_owtw: store.accident_owtw,
}))(VictimData);
