import React from "react";
import { connect } from "react-redux";

import { TaskDecreaseRiskContainer } from "../EntryAddEdit/TaskDecreaseRisk";

class ArchivedTask extends TaskDecreaseRiskContainer {}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(ArchivedTask);
