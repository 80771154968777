import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppComponent from "../../components/AppComponent";
import { LayoutContent } from "../../components/Layout";
import moment from "moment/moment";
import PageHeader from "../../components/PageHeader";
import LoadingScreen from "../../components/LoadingScreen";
import {
  ButtonWithIcon,
  StyledTableListWithSearch,
  StyledTableWrapper,
} from "../../components/ListComponents";
import { Form, Field } from "react-final-form";
import { StyledInput } from "../../style/styleComponents/Form/input";
import DayPicker from "../../lib/DayPicker";
import Select from "../../components/Select";
import { TableSortHeader } from "../../components/TableSortable";

import { ButtonWrapper } from "../../components/ListComponents";
import { addIncident, getIncidents } from "../../actions/incidentActions";
import Button from "react-bootstrap/lib/Button";
import {
  getAudits,
  getAuditsByFilter,
  getAuditsAuditors,
  deleteAudit,
  downloadCompleteAuditPdf,
} from "../../actions/auditActions";
import { LinkContainer } from "../../components/LinkContainer";
import { getUsersLocalizations } from "../../actions/localizationActions";
import {
  getUserSections,
  clearSections,
  getUserSectionsIndependently,
} from "../../actions/sectionActions";
import styled from "styled-components";
import Alert from "react-bootstrap/lib/Alert";
import SideMenu from "./_SideMenu";
import { StyledTooltip } from "../../style/styleComponents/ToolTip";
import Modal from "../../components/Modal/SimpleAccept";
import saveBlobFile from "../../components/saveBlobFile";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "dictionary_progress_id", () => null);
  changeValue(state, "symbol", () => null);
  changeValue(state, "title", () => null);
  changeValue(state, "date", () => null);
  changeValue(state, "localization_id", () => null);
  changeValue(state, "section_name", () => null);
  changeValue(state, "users_id", () => null);
};

const DateTd = (props) => <td>{props.children}</td>;

const getUrl = (id, stage) => {
  switch (stage) {
    case 3:
      return `/incident/attachment/${id}`;
    case 2:
      return `/incident/task/${id}`;
    case 1:
      return `/incident/analysis/${id}`;
    default:
      return `/incident/register/${id}`;
  }
};

class List extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalDeleteAudit: {
        open: false,
      },
    };
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.deleteAudit = this.deleteAudit.bind(this);
    this.handleDownloadPdf = this.handleDownloadPdf.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getAudits()(dispatch).then(this.handleServerResponse({}, () => {}));
    getUsersLocalizations()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getAuditsAuditors()(dispatch).then(this.handleServerResponse({}, () => {}));
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  handleFilterSubmit(data) {
    const { dispatch } = this.props;

    getAuditsByFilter(data)(dispatch).then(this.handleServerResponse());
  }

  localizationChange(localization_id) {
    const { dispatch } = this.props;
    getUserSections(localization_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  clearFilter(form) {
    return () => {
      const { dispatch } = this.props;
      form.mutators.clearFilterMutator();
      getAudits()(dispatch).then(this.handleServerResponse({}, () => {}));
    };
  }

  handleModalOpen(name, entry_id, rest) {
    return () => {
      this.setState({
        [name]: { ...this.state[name], open: true, entry_id, ...rest },
      });
    };
  }

  handleModalClose(name) {
    return () => {
      this.setState({
        [name]: { ...this.state[name], open: false, entry_id: null },
      });
    };
  }

  handleDownloadPdf(id, name, index) {
    return () => {
      const { match } = this.props;
      downloadCompleteAuditPdf(
        id,
        index
      )(this.props.dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `audyt-${name}.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    };
  }

  deleteAudit() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modalDeleteAudit;
    deleteAudit(entry_id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    const { message, modalDeleteAudit } = this.state;
    const {
      audit: { data: auditData, permission, fetched },
      localization: { data: localizationData },
      section: { data: sectionData },
      users: { data: usersData },
    } = this.props;

    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        SideMenu={<SideMenu permission={permission} />}
      >
        {message && message.text && (
          <Alert bsStyle={message.className}>{message.text}</Alert>
        )}
        <PageHeader>Rejestr audytów</PageHeader>
        <ButtonWrapper>
          <LinkContainer to={"/audit/register"}>
            <ButtonWithIcon
              disabled={!permission[2]}
              icon="fa fa-plus"
              name="Zaplanuj nowy audyt"
            />
          </LinkContainer>
        </ButtonWrapper>
        <div style={{ lineHeight: "34px" }}>Szukaj</div>
        <LoadingScreen fetched={fetched}>
          <StyledTableWrapper>
            <Form
              ref={(form) => (this.form = form)}
              mutators={{ clearFilterMutator }}
              onSubmit={this.handleFilterSubmit}
              render={({ handleSubmit, pristine, invalid, form }) => (
                <div onSubmit={handleSubmit}>
                  <StyledTableListWithSearch>
                    <tr>
                      <td>
                        <Field
                          name="dictionary_progress_id"
                          render={({ input, meta, ...rest }) => (
                            <Select
                              title="Typ"
                              fullWidth
                              propValue={"id"}
                              selected={input.value}
                              data={[
                                { id: null, name: "Wszystkie" },
                                { id: 7, name: "Audyt zaplanowany" },
                                {
                                  id: 3,
                                  name: "Audyt zrealizowany i wprowadzony",
                                },
                              ]}
                              handleChange={(value) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Field
                          name="symbol"
                          render={({ input, meta, ...rest }) => (
                            <StyledInput
                              {...input}
                              value={input.value}
                              placeholder={"wg Symbolu"}
                              onChange={(event) => {
                                input.onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Field
                          name="date"
                          render={({ input, meta, ...rest }) => (
                            <DayPicker
                              selectedDay={input.value}
                              fullWidth
                              placeholder={"wg Daty"}
                              handleChange={(value) => {
                                input.onChange(
                                  moment(value).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          )}
                        />
                      </td>

                      <td>
                        <Field
                          name="title"
                          render={({ input, meta, ...rest }) => (
                            <StyledInput
                              {...input}
                              placeholder={"wg Tytułu"}
                              onChange={(event) => {
                                input.onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Field
                          name="localization_id"
                          render={({ input, meta, ...rest }) => (
                            <Select
                              title="Lokacja"
                              fullWidth
                              propValue={"id"}
                              selected={input.value}
                              data={[{ id: null, name: "Wszystkie" }].concat(
                                localizationData
                              )}
                              handleChange={(value) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Field
                          name="section_name"
                          render={({ input, meta, ...rest }) => (
                            <Select
                              title="Dział"
                              fullWidth
                              selected={input.value}
                              propValue={"value"}
                              data={[{ id: null, name: "Wszystkie" }].concat(
                                sectionData
                              )}
                              handleChange={input.onChange}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Field
                          name="users_id"
                          render={({ input, meta, ...rest }) => (
                            <Select
                              title="Audytor"
                              fullWidth
                              propValue={"id"}
                              selected={input.value}
                              data={[{ name: "Wszystkie", id: null }].concat(
                                usersData
                              )}
                              handleChange={input.onChange}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="10" style={{ paddingRight: "40px" }}>
                        <ButtonWrapper nowrap>
                          <Button onClick={handleSubmit} bsStyle="primary">
                            Szukaj
                          </Button>
                          <Button onClick={this.clearFilter(form)}>
                            Wyłącz filtr
                          </Button>
                        </ButtonWrapper>
                      </td>
                    </tr>
                    <TableSortHeader
                      headers={[
                        { name: "Typ", prop: "dictionary_progress_id" },
                        { name: "Symbol", prop: "symbol", sort_type: "string" },
                        { name: "Data", prop: "date" },
                        {
                          name: "Tytuł",
                          prop: "audit_type_title",
                          sort_type: "string",
                        },
                        {
                          name: "Lokacja",
                          prop: "localization_name",
                          sort_type: "string",
                        },
                        {
                          name: "Dział firmy",
                          prop: "section_name",
                          sort_type: "string",
                        },
                        {
                          name: "Audytor",
                          prop: "user_name",
                          sort_type: "string",
                        },
                        { name: "Drukuj" },
                        { name: "Zmień typ / Usuń" },
                      ]}
                      data={auditData}
                      render={(auditSortedData) => {
                        return auditSortedData
                          .map((item) => {
                            item.date_add = moment(item.date_add).format(
                              "YYYY-MM-DD"
                            );
                            return item;
                          })
                          .map((item, index) => (
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "18px",
                                }}
                              >
                                {item.dictionary_progress_id == 3 ? (
                                  <StyledTooltip
                                    placement="bottom"
                                    overlay={
                                      <span>
                                        Audyt przeprowadzony i wprowadzony
                                      </span>
                                    }
                                  >
                                    <i className="fa fa-pencil-square-o" />
                                  </StyledTooltip>
                                ) : (
                                  <StyledTooltip
                                    placement="bottom"
                                    overlay={<span>Audyt zaplanowany</span>}
                                  >
                                    <i className="fa fa-clock-o" />
                                  </StyledTooltip>
                                )}
                              </td>
                              <td>
                                <Link to={`/audit/data/${item.id}`}>
                                  {item.symbol}
                                </Link>
                              </td>
                              <td>{item.date}</td>
                              <td>{item.audit_type_title}</td>
                              <td>{item.localization_name}</td>
                              <td>{item.section_name}</td>
                              <td>{item.user_name}</td>
                              <td>
                                <ButtonWithIcon
                                  disabled={
                                    item.dictionary_progress_id != 3 ||
                                    item.printFetching
                                  }
                                  onClick={this.handleDownloadPdf(
                                    item.id,
                                    item.symbol,
                                    index
                                  )}
                                  icon={
                                    item.printFetching
                                      ? "fa fa-spinner fa-spin"
                                      : "fa fa-print"
                                  }
                                  listButton
                                  name={"Drukuj"}
                                />
                              </td>
                              <td>
                                <ButtonWithIcon
                                  disabled={!permission[4]}
                                  onClick={this.handleModalOpen(
                                    "modalDeleteAudit",
                                    item.id
                                  )}
                                  icon="fa fa-trash"
                                />
                              </td>
                            </tr>
                          ));
                      }}
                    />
                  </StyledTableListWithSearch>
                </div>
              )}
            />
          </StyledTableWrapper>
          <Modal
            title={"Usuń audyt"}
            description="Czy chcesz usunąć dany wpis?"
            open={modalDeleteAudit.open}
            handleAccept={this.deleteAudit}
            handleClose={this.handleModalClose("modalDeleteAudit")}
          />
        </LoadingScreen>
      </LayoutContent>
    );
  }

  componentWillUnmount() {}
}

export default connect((store) => ({
  audit: store.audit,
  localization: store.localization,
  section: store.section,
  users: store.users,
}))(List);
