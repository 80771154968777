import React from "react";
import Autocomplete from "react-autocomplete";
import { StyledInput, StyledLabel } from "../style/styleComponents/Form/input";
import PropTypes from "prop-types";
import BusinessForm from "./Business.Form";
import { StyledInputMessage } from "../style/styleComponents/Form/input";

const defaultWrapperStyle = {
  display: "inline-block",
};

export default class AutoCompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tempValue: null,
      inited: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { propValue = "id", propName = "name" } = this.props;
    if (!this.state.inited) {
      const { data, value } = nextProps;
      if (this.props.isSearch) {
        const index = data.findIndex((item) => item[propValue] == value);
        if (index > -1) {
          const item = data[data.findIndex((item) => item[propValue] == value)];
          this.setState({
            value: item[propName],
            tempValue: item[propName],
            inited: true,
          });
        }
      } else {
        this.setState({ value: value, tempValue: value, inited: true });
      }
    }
  }

  componentDidMount() {
    let value = this.props.value;
    this.setState({ value: value, tempValue: value });
  }

  componentDidUpdate() {
    let value = this.props.value;
    if (value != this.state.value) {
      this.setState({ value: value, tempValue: value });
    }
  }

  onBlur() {
    if (this.props.isSearch) {
      this.setState({ tempValue: this.state.value });
    } else {
      this.setState({ tempValue: this.props.value });
    }
  }

  handleChange(e) {
    const { handleChange = () => {} } = this.props;
    const value = e.target.value;
    if (value && value.length) {
      handleChange(value);
      this.setState({ tempValue: value });
    } else {
      handleChange(value);
      this.setState({ tempValue: null, value: null });
    }
  }

  handleSelect(value) {
    const {
      handleSelect = () => {},
      data,
      propValue = "id",
      propName = "name",
    } = this.props;
    const item = data[data.findIndex((user) => user[propValue] == value)];
    handleSelect(item || { [propName]: value });
    this.setState({ value: item[propName], tempValue: item[propName] });
  }

  render() {
    const {
      data = [],
      label,
      disabled,
      wrapperStyleFullLength,
      error,
      helperText,
      propValue = "id",
      propName = "name",
      maxListHeight,
      selectOnBlur = false,
    } = this.props;

    return (
      <div>
        <StyledLabel>{label}</StyledLabel>
        <Autocomplete
          wrapperStyle={{
            display: wrapperStyleFullLength ? "block" : "inline-block",
            position: "relative",
          }}
          inputProps={{
            style: {
              border: "1px solid #ccc",
              lineHeight: "20px",
              padding: "6px 12px",
              width: "100%",
            },
            disabled: disabled,
            onBlur: this.onBlur,
          }}
          getItemValue={(item) => {
            return item[propValue].toString();
          }}
          items={data}
          renderInput={(props) => <input {...props} />}
          menuStyle={{
            borderRadius: "3px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
            background: "rgba(255, 255, 255, 0.9)",
            padding: "2px 0",
            fontSize: "90%",
            position: "static",
            overflow: "auto",

            maxHeight: maxListHeight ? maxListHeight : "1000%",
            zIndex: "998",
            position: "absolute",
            top: "100%",
            left: "0",
          }}
          renderItem={(item, isHighlighted) => (
            <div
              key={item.id}
              style={{
                background: isHighlighted ? "lightgray" : "white",
                // border:"1px solid #ccc",
                lineHeight: "20px",
                padding: "3px 12px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              {item[propName]}
            </div>
          )}
          shouldItemRender={(item, value) => {
            const newRegex = new RegExp(value, "i");
            return newRegex.test(item[propName]);
          }}
          value={this.state.tempValue}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          // selectOnBlur={selectOnBlur !== undefined ? selectOnBlur : true}
          selectOnBlur={selectOnBlur}
        />
        <div>
          <StyledInputMessage>{helperText}</StyledInputMessage>
        </div>
      </div>
    );
  }
}

BusinessForm.propTypes = {
  isSearch: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    })
  ),
  label: PropTypes.string,
  wrapperStyleFullLength: PropTypes.bool,
};
