import React from "react";
import { connect } from "react-redux";

import { CharacteristicEdit } from "./Edit";

class ArchivedCharacteristicEdit extends CharacteristicEdit {
  constructor(props) {
    super(props);
    this.state = {
      listUrl: "/characteristic/archived",
    };
  }
}

export default connect((store) => ({
  characteristic: store.characteristic,
  message: store.message,
}))(ArchivedCharacteristicEdit);
