import React from "react";
import styled from "styled-components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Button from "react-bootstrap/lib/Button";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
`;

class ReactPDF extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...this.state, numPages: null, pageNumber: 1 };

    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleNextPage() {
    if (this.state.pageNumber < this.state.numPages)
      this.setState({ pageNumber: this.state.pageNumber + 1 });
  }

  handlePrevPage() {
    if (this.state.pageNumber > 1)
      this.setState({ pageNumber: this.state.pageNumber - 1 });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    const { file } = this.props;
    return (
      <Wrapper>
        <div>
          <Document
            file={file}
            onLoadSuccess={this.onDocumentLoad}
            onLoadError={(error) => alert(error.message)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div>
            <p>
              Strona {pageNumber} z {numPages}
            </p>
            <Button disabled={pageNumber <= 1} onClick={this.handlePrevPage}>
              <i className="fa fa-angle-left" />
            </Button>
            <Button
              disabled={pageNumber >= numPages}
              onClick={this.handleNextPage}
            >
              <i className="fa fa-angle-right" />
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ReactPDF;
