import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Form, Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { connect } from "react-redux";

import { getDictionary } from "actions/dictionaryActions";
import {
  addCertificateOSHDraft,
  getCertificateOSHList,
  getCertificateOSHDraft,
  printCertificateOSH,
} from "actions/certificateOSHActions";

import AppComponent from "components/AppComponent";
import Box from "components/Box";
import { ButtonWithIcon } from "components/ListComponents";
import {
  FieldInput,
  FieldSelect,
  FieldDayPicker,
  FieldTextareaBox,
} from "components/Fields";
import FormModal from "components/Modal/FormModal";

import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import {
  ButtonWrapper,
  LeftPadding,
  TwoInputWrapper,
} from "components/Wrappers";
import saveBlobFile from "components/saveBlobFile";

import { StyledInputBox } from "style/styleComponents/Form/input";

import SideMenu from "./_SideMenu";
import { OnChange } from "react-final-form-listeners";

let SubjectTrainingLine = styled.div`
  display: flex;
  margin: 0 -10px 10px;
  & > * {
    padding: 0 10px;
    &:nth-child(1) {
      flex: 0 1 50%;
    }
    &:nth-child(2) {
      flex: 0 1 20%;
    }
    &:nth-child(3) {
      flex: 0 1 20%;
    }
    &:nth-child(4) {
      flex: 0 1 10%;
    }
  }
`;
const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};
class OSHCertificateTemplate extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, osh_type_id: null };

    this.saveDraft = this.saveDraft.bind(this);
    this.print = this.print.bind(this);
    this.getDraftData = this.getDraftData.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    getCertificateOSHList()(dispatch);
    getDictionary("osh-form")(dispatch);

    if (id) {
      this.getDraftData({ id });
    }
  }

  getDraftData(data) {
    let { draft, dispatch } = this.props;
    let { id } = data;
    getCertificateOSHDraft(id)(dispatch).then(
      this.handleServerResponse({}, () => {
        let { draft } = this.props;

        for (let k of draftKeys) {
          this.form.mutators.setField(k)(draft[k]);
        }
        this.form.mutators.setField("osh_type_id")(2);
        this.form.mutators.setField("osh_type_id")(2);
      })
    );
  }

  print(data) {
    let { dispatch } = this.props;
    const name =
      data.osh_type_id === 1
        ? "Karta szkolenia wstępnego w dziedzinie bezpieczeństwa i higieny pracy."
        : "Zaświadczenie o ukończeniu szkolenia w dziedzinie bezpieczeństwa i higieny pracy.";
    printCertificateOSH(data)(dispatch).then((res) => {
      if (!res.error) {
        const file = res.payload.data;
        const fileName = `${name}.pdf`;
        saveBlobFile(file, fileName);
      }
    });
  }

  saveDraft(data) {
    let { dispatch } = this.props;
    addCertificateOSHDraft(data)(dispatch).then(this.handleServerResponse());
  }

  render() {
    return (
      <>
        <LayoutContent SideMenu={<SideMenu />}>
          <Box title="Wystaw zaświadczenie ze szkolenia BHP">
            <Form
              onSubmit={this.print}
              mutators={{ ...arrayMutators, setField }}
              initialValues={{}}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FormSpy subscribe={{ form: true, values }}>
                      {({ form }) => {
                        this.form = form;
                        return null;
                      }}
                    </FormSpy>
                    <TwoInputWrapper>
                      <FieldSelect
                        name="osh_type_id"
                        label="Rodzaj szkolenia"
                        data={[
                          { id: "0", name: "Rodzaj szkolenia" },
                          { id: 1, name: "szkolenia wstępne BHP" },
                          { id: 2, name: "szkolenia okresowe BHP" },
                        ]}
                      />
                    </TwoInputWrapper>
                    {(() => {
                      switch (values.osh_type_id) {
                        case 1: {
                          return <OSHInitial />;
                        }
                        case 2: {
                          return (
                            <OSHPeriodic
                              saveDraft={this.saveDraft}
                              drafts={this.props.drafts}
                              osh_form={this.props.osh_form}
                              getDraftData={this.getDraftData}
                            />
                          );
                        }
                        default: {
                          return null;
                        }
                      }
                    })()}
                  </form>
                );
              }}
            />
          </Box>
        </LayoutContent>
      </>
    );
  }
}

class OSHInitial extends React.PureComponent {
  render() {
    return (
      <>
        <h4>I. Instruktaż ogólny</h4>
        <LeftPadding>
          <TwoInputWrapper>
            <div>
              <FieldInput
                name="fullname_person"
                label={"Imię i nazwisko osoby odbywającej szkolenie"}
              />
              <FieldInput
                name="business_name"
                label={"Nazwa komórki organizacyjnej"}
              />
              <FieldDayPicker
                name="date_initial"
                label="Data przeprowadzenia instruktażu ogólnego"
              />
              <FieldInput
                name="fullname_instructor"
                label={"Imię i nazwisko przeprowadzającego instruktaż"}
              />
            </div>
          </TwoInputWrapper>
        </LeftPadding>
        <h4>II. Instruktaż stanowiskowy - stanowisko 1</h4>
        <LeftPadding>
          <TwoInputWrapper>
            <div>
              <FieldInput name="workplace_1" label={"Stanowisko pracy"} />
              <FieldInput
                name="training_1"
                label={"Instruktaż przeprowadzono w dniu / dniach"}
              />
              <FieldInput
                name="training_person_1"
                label={"Imię i nazwisko przeprowadzającego instruktaż"}
              />
              <FieldInput
                name="training_target_person_1"
                label={"Imię i nazwisko osoby, której  udzielono instruktażu"}
              />
            </div>
          </TwoInputWrapper>
        </LeftPadding>
        <h4>II. Instruktaż stanowiskowy - stanowisko 2</h4>
        <LeftPadding>
          <TwoInputWrapper>
            <div>
              <FieldInput name="workplace_2" label={"Stanowisko pracy"} />
              <FieldInput
                name="training_2"
                label={"Instruktaż przeprowadzono w dniu / dniach"}
              />
              <FieldInput
                name="training_person_2"
                label={"Imię i nazwisko przeprowadzającego instruktaż"}
              />
              <FieldInput
                name="training_target_person_2"
                label={"Imię i nazwisko osoby, której  udzielono instruktażu"}
              />
            </div>
          </TwoInputWrapper>
        </LeftPadding>
        <ButtonWrapper>
          <LinkContainer exact to="/forms">
            <Button>Anuluj</Button>
          </LinkContainer>
          <Button bsStyle="primary" type="submit">
            Pobierz / drukuj
          </Button>
        </ButtonWrapper>
      </>
    );
  }
}

let draftKeys = ["name", "osh_form_id", "promoter_name", "goal", "subjects"];

class OSHPeriodic extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modalGetTemplate: {
        open: false,
        data: {},
      },
      modalSaveAsTemplate: {
        open: false,
        data: {},
      },
    };

    this.getDraftData = this.getDraftData.bind(this);
  }

  getDraftData(data) {
    let { drafts } = this.props;
    let item = drafts.find((item) => {
      return item.id == data.id;
    });

    for (let k of draftKeys) {
      this.form.mutators.setField(k)(item[k]);
    }
  }

  render() {
    let { saveDraft, drafts = [], osh_form = [], getDraftData } = this.props;

    return (
      <>
        <ButtonWrapper>
          {/* modal */}
          <Button onClick={this.openModal("modalGetTemplate")}>
            Pobierz ze wzoru
          </Button>
        </ButtonWrapper>

        <TwoInputWrapper>
          <div>
            <FieldInput label="Imię i nazwisko" name="fullname" />
            <FieldSelect
              name="sex_id"
              label="Płeć"
              data={[
                { id: 1, name: "Kobieta" },
                { id: 2, name: "Mężczyzna" },
              ]}
            />
            <FieldDayPicker name="birth_date" label="Data urodzenia" />
            <FieldInput
              label="Pełna nazwa szkolenia (podać rodzaj szkolenia i grupę osób, dla których jest przeznaczona"
              name="name"
            />
            <FieldSelect
              label="Forma szkolenia"
              name="osh_form_id"
              data={osh_form}
            />
            <FieldInput
              label="Nazwa organizatora szkolenia"
              name="promoter_name"
            />
            <FieldDayPicker
              name="date_from"
              label="Szkolenie organizowane od dnia"
            />
            <FieldDayPicker
              name="date_to"
              label="Szkolenie organizowane do dnia"
            />
            <FieldInput label="Celem szkolenia było" name="goal" />
            <FieldInput
              label="Miejscowość wystawienia zaświadczenia"
              name="certificate_place"
            />

            <FieldDayPicker
              name="certificate_date"
              label="Data wystawienia zaświadczenia"
            />
            <FieldInput
              label="Numer zaświadczenia według rejestru"
              name="certificate_number"
            />
          </div>
        </TwoInputWrapper>
        <h4>Tematyka szkolenia</h4>
        <LeftPadding>
          <SubjectTrainingLine>
            <div>Temat szkolenia</div>
            <div>Liczba godzin zajęć teoretycznych (wykładów)</div>
            <div>Liczba godzin zajęć praktycznych (ćwiczeń)</div>
            <div></div>
          </SubjectTrainingLine>

          <FieldArray name="subjects">
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <>
                    <SubjectTrainingLine>
                      <FieldTextareaBox name={`${name}.name`} />
                      <FieldInput name={`${name}.theoretical_hours`} />
                      <FieldInput name={`${name}.practical_hours`} />
                      <div>
                        <ButtonWithIcon
                          icon="fa fa-trash"
                          type="button"
                          onClick={() => {
                            fields.remove(index);
                          }}
                        />
                      </div>
                    </SubjectTrainingLine>
                  </>
                ))}
                <ButtonWrapper>
                  <Button
                    type="button"
                    onClick={() => {
                      fields.push({});
                    }}
                  >
                    Dodaj wiersz
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </FieldArray>

          <SubjectTrainingLine>
            <div style={{ textAlign: "right" }}>Suma godzin</div>
            <FieldInput disabled name="theoretical_hours_sum" />
            <FieldInput disabled name="practical_hours_sum" />
            <div></div>
          </SubjectTrainingLine>
          <FormSpy subscribe={{ values: true, form: true }}>
            {({ values, form }) => {
              let { subjects } = values;
              let theoretical_hours_sum = 0;
              let practical_hours_sum = 0;
              if (subjects) {
                for (let s of subjects) {
                  theoretical_hours_sum =
                    theoretical_hours_sum +
                    (parseInt(s.theoretical_hours) || 0);
                  practical_hours_sum =
                    practical_hours_sum + (parseInt(s.practical_hours) || 0);
                }
              }
              if (theoretical_hours_sum !== values.theoretical_hours_sum)
                form.change("theoretical_hours_sum", theoretical_hours_sum);
              if (practical_hours_sum !== values.practical_hours_sum)
                form.change("practical_hours_sum", practical_hours_sum);
              return null;
            }}
          </FormSpy>
        </LeftPadding>

        <ButtonWrapper>
          <LinkContainer exact to="/forms">
            <Button>Anuluj</Button>
          </LinkContainer>
          <FormSpy subscribe={{ values: true }}>
            {({ values }) => {
              let keys = [
                "name",
                "promoter_name",
                "osh_form_id",
                "goal",
                "subjects",
                "draft_name",
              ];
              return (
                <>
                  <Button
                    onClick={this.openModal("modalSaveAsTemplate", {
                      data: keys.reduce((obj, key) => {
                        obj[key] = values[key];
                        return obj;
                      }, {}),
                    })}
                  >
                    Zapisz jako wzór
                  </Button>
                </>
              );
            }}
          </FormSpy>
          <Button bsStyle="primary" type="submit">
            Pobierz / drukuj
          </Button>
        </ButtonWrapper>
        <FormModal
          {...this.state.modalGetTemplate}
          title="Pobierz wzór"
          description="Opis"
          acceptButtonName="Pobierz"
          cancelButtonName="Anuluj"
          handleSubmit={getDraftData}
          handleClose={this.closeModal("modalGetTemplate")}
        >
          <TwoInputWrapper>
            <FieldSelect
              name="id"
              label="Wybierz wzór"
              data={drafts.map((i) => {
                return { ...i, name: i.draft_name };
              })}
            />
          </TwoInputWrapper>
        </FormModal>
        <FormModal
          {...this.state.modalSaveAsTemplate}
          initialValues={this.state.modalSaveAsTemplate.data}
          title="Zapisz jako wzór"
          description="Opis"
          acceptButtonName="Zapisz"
          cancelButtonName="Anuluj"
          handleSubmit={saveDraft}
          handleClose={this.closeModal("modalSaveAsTemplate")}
        >
          <TwoInputWrapper>
            <FieldInput name="draft_name" label="Nazwa wzoru zaświadczenia" />
          </TwoInputWrapper>
        </FormModal>
      </>
    );
  }
}

export default connect((store) => ({
  types: store.osh.types,
  drafts: store.certificates.data,
  draft: store.certificates.singleData,
  osh_form: store.dictionary.osh_form,
}))(OSHCertificateTemplate);
