import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import AppComponent from "components/AppComponent";
export default class Schema extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      show: false,
    };

    this.accept = this.accept.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  accept() {
    const { handleAccept = () => {} } = this.props;
    handleAccept().then((res) => {
      this.handleClose();
    });
  }

  handleClose() {
    const { handleClose = () => {} } = this.props;
    handleClose();
  }

  handleShow() {
    this.setState({ show: true });
  }
}

Schema.propType = {
  handleAccept: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
