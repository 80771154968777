import React from "react";
import { connect } from "react-redux";
import { addTaskByRA } from "actions/taskActions";
import {
  pingIncidentTask,
  addTaskAttachmentLocal,
  deleteTaskAttachmentLocal,
  clearTask,
} from "actions/taskActions";

import { TaskAdd } from "container/Task/Add";
import LoadingScreen from "components/LoadingScreen/";
import TaskForm from "components/Task.Form";
import dataToFormData from "components/dataToFormData";
import SideMenu from "../../_SideMenu";
import { setUrls } from "../../_staticData";

class IncidentTaskAdd extends TaskAdd {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };
  }

  _pingTask() {
    const { dispatch } = this.props;
    pingIncidentTask()(dispatch).then(this.handleServerResponse());
  }

  handleSubmit(data) {
    const { urls } = this.state;
    const { dispatch, history, match, task } = this.props;
    const { id, ra_ah_tme_id } = match.params;
    const { attachments } = task;
    for (let attachment of attachments) {
      data[`task_new_attachment_file_${attachment.id}`] = attachment.fileData;
      data[`task_new_attachment_name_${attachment.id}`] = attachment.name;
    }
    const formData = dataToFormData(data);

    addTaskByRA(
      id,
      ra_ah_tme_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.TASK(id));
      })
    );
  }

  render() {
    let {
      match,
      incident: { permission },
      task: { attachments },
    } = this.props;
    const { incident_id, id } = match.params;
    const LSideMenu = (props) => (
      <SideMenu incident_id={incident_id} permission={permission} />
    );

    let permissionTask = {
      ...permission,
    };

    if (permission[12]) {
      permissionTask[2] = true;
      permissionTask[3] = true;
    }
    return (
      <LoadingScreen fetched={true}>
        <TaskForm
          SideMenu={LSideMenu}
          noLocalization
          mobileNotHideMenu={true}
          permission={permissionTask}
          attachments={attachments}
          data={{
            priority: 2,
          }}
          handleSubmit={this.handleSubmit}
          backUrl={this.state.urls.TASK(id)}
          createView
          addAttachment={addTaskAttachmentLocal}
          deleteAttachment={deleteTaskAttachmentLocal}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    clearTask()(this.props.dispatch);
  }
}

export default connect((store) => ({
  incident: store.incident,
  localization: store.localization,
  section: store.section,
  task: store.task,
}))(IncidentTaskAdd);
