 import React from 'react';
import SideMenu from "../../components/SideMenu";

const LSideMenu = ({permission={}}) => (
  <div>
    <SideMenu
      title="Działania"
      data={[
        { name: "Rejestr Działań", url: "/task" },
        { name: "Dodaj działanie", url: "/task/add",disabled:!permission[2]}
      ]}
    />
  </div>
);

export default LSideMenu;