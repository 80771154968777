import React from "react";
import {connect} from "react-redux";
import AppComponent from "../../components/AppComponent";
import {LayoutContent} from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import HtmlToReactParse from "../../lib/HtmlToReactParse";
import {getPageByUrl} from "../../actions/pageActions";
import LoadingScreen from "../../components/LoadingScreen/";
import PageContactForm from "../../components/PageContact.Form";
import {sendContactMessage} from "../../actions/pageActions";
// import {LinkContainer} from "../../components/LinkContainer";
import {Link} from "react-router-dom";

class Page extends AppComponent {

  constructor(props){
    super(props);
    this.sendContactMessage = this.sendContactMessage.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData(nextProps) {
    const {dispatch} = this.props;
    const match = nextProps ? nextProps.match : this.props.match;
    const {url} = match.params;
    getPageByUrl(url)(dispatch)
      .then(this.handleServerResponse());
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.match.params.url!=this.props.match.params.url) {
      this.getData(nextProps);
    }
  }

  sendContactMessage(values){
    const {dispatch} = this.props;
    return sendContactMessage(values)(dispatch)
      .then(this.handleServerResponse({},(action)=>{
        this.setState({messageForm: {text:action.payload.data.message,className:"success"}});
      }));
  }

  render() {
    const {messageForm} = this.state;
    const {page: {singleData,fetched,pageMenu}} = this.props;
    const sideMenu = pageMenu;

    return (
      <LayoutContent
        SideMenu={<SideMenu title="Pomoc" data={sideMenu}/>}
      >
        <LoadingScreen fetched={fetched}>
          <div className="ql-container ql-snow" style={{border:"none"}}>
            <div className="ql-editor">{HtmlToReactParse(singleData.content)}</div>
          </div>
        </LoadingScreen>
        {singleData.contact_form
          ? (<PageContactForm
            message={messageForm}
            handleSubmit={this.sendContactMessage}
          />)
          : ''}
        {singleData.children && singleData.children.length ? singleData.children.map(item=>(
          <h3><Link to={item.url}>{item.name}</Link></h3>)) : ''}
      </LayoutContent>
    );
  }
}

export default connect(store => ({
  page: store.page
}))(Page);