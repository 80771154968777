import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import ModalSchema from "./_Schema";
import {media} from "../../style/style-utils";
const StyledModal = styled(Modal)`
  & .modal-dialog {
    max-width:800px;
    ${media.lg`
      width:100%;

    `}
    ${media.md`
      width:1005
    `}
   
  }
  & .modal-title{
    display: flex;
    justify-content: space-between;\
  }
`;
StyledModal.displayName = "Modal";

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    margin: 0 10px
  }
`;
ButtonWrapper.displayName = "ButtonWrapper";
const StyledButton = styled(Button)`
  &:before {
    padding-right: 5px;
  }
`;
StyledButton.displayName = "Button";

const ImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

export const ImageViewModalSchema = {
  open: false,
  name: null,
  file: null,
};

class ImageViewModal extends ModalSchema {

  constructor(props){
    super(props);

    this.accept = this.accept.bind(this);
  }

  render(){
    const {open,title,handleClose,handleDownload,file} = this.props;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>{title}</span>
              <ButtonWrapper>
                <StyledButton onClick={handleDownload}>
                  <i className="fa fa-cloud-download"/> Pobierz</StyledButton>
                <StyledButton onClick={handleClose}>
                  <i className="fa fa-times"/> Zamknij</StyledButton>
              </ButtonWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ImageContainer>
              <img src={file} alt=""/>
            </ImageContainer>
          </Modal.Body>

        </StyledModal>
      </div>
    );
  }
}

export default ImageViewModal;

ImageViewModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  fileUrl: PropTypes.string,
  handleClose:PropTypes.func.isRequired
};