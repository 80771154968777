export function getNotifications(localization_id) {
  return dispatch => dispatch({
    types: ["GET_NOTIFICATIONS_REQUEST", "GET_NOTIFICATIONS_SUCCESS", "GET_NOTIFICATIONS_ERROR"],
    payload: {
      request: {
        url: `/business/notification/${localization_id}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function addNotifications(localization_id,data) {
  return dispatch => dispatch({
    types: ["ADD_NOTIFICATIONS_REQUEST", "ADD_NOTIFICATIONS_SUCCESS", "ADD_NOTIFICATIONS_ERROR"],
    payload: {
      request: {
        url: `/business/notification/${localization_id}/add-notification`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function deleteUserFromGroup(localization_id,notification_group_has_users_id){
  return dispatch => dispatch({
    types: ["DELETE_USER_FROM_NOTIFICATION_GROUP_REQUEST", "DELETE_USER_FROM_NOTIFICATION_GROUP_SUCCESS", "DELETE_USER_FROM_NOTIFICATION_GROUP_ERROR"],
    payload: {
      request: {
        url: `/business/notification/${localization_id}/delete-user-from-group`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:{
          notification_group_has_users_id
        }
      },
    }
  });
}

export function addUserToGroup(localization_id,notification_group_id,user_id){
  return dispatch => dispatch({
    types: ["ADD_USER_TO_NOTIFICATION_GROUP_REQUEST", "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS", "ADD_USER_TO_NOTIFICATION_GROUP_ERROR"],
    payload: {
      request: {
        url: `/business/notification/${localization_id}/add-user-to-group`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:{
          notification_group_id,user_id
        }
      },
    }
  });
}

export function deleteNotification(localization_id,data) {
  return dispatch => dispatch({
    types: ["DELETE_USER_NOTIFICATION_REQUEST", "DELETE_USER_NOTIFICATION_SUCCESS", "DELETE_USER_NOTIFICATION_ERROR"],
    payload: {
      request: {
        url: `/business/notification/${localization_id}/delete-notification`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function localAddNotification(payload) {
  return dispatch => dispatch({
    type:"ADD_NOTIFICATIONS_SUCCESS",
    payload: payload
  });
}

export function localRemoveNotification(payload){
  return dispatch => dispatch({
    type: "LOCAL_REMOVE_NOTIFICATION",
    payload
  });
}

export function localRemoveUser(payload) {
  return dispatch => dispatch({
    type: "LOCAL_REMOVE_USER_FROM_NOTIFICATION",
    payload
  });
}

export function localAddUser(payload) {
  return dispatch => dispatch({
    type: "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS",
    payload
  });
}

export function notificationCleaner(message){
  return dispatch=>dispatch({
    type:"CLEAN_NOTIFICATION",
    payload:{data:{message}}
  });
}