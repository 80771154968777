import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import { media } from "style/style-utils";
import { StyledInput } from "style/styleComponents/Form/input";

import Select from "components/Select";
import { Button } from "components/Buttons";
import {
  getUserSectionsIndependently,
  getUserSections,
} from "actions/sectionActions";
const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px 15px;

  ${media.xs`
    margin: 0 0 10px;
    flex-direction:column;
  `}

  & > * {
    margin: 0 10px;
    ${media.xs`
      margin: 0 0 10px
    `}
  }
`;

function ListFilter({
  localizations,
  sections,
  changeFilter,
  searchAction,
  dispatch,
  handleServerResponse,
}) {
  let [filter, setFilter] = useState({
    localization_id: null,
    section_id: null,
    section_name: null,
    name: null,
  });

  let { section_name, localization_id, name } = filter;

  function updateFilter(name) {
    return (value) => {
      let newFilter = { ...filter, [name]: value };
      // if (name == "localization_id") {
      //   filter.section_id = undefined;
      // }
      setFilter(newFilter);
      // changeFilter(newFilter);
    };
  }

  function search() {
    searchAction(filter)(dispatch).then(handleServerResponse);
  }

  return (
    <FilterWrapper>
      <FilterWrapper>
        <span style={{ lineHeight: "34px" }}>Lokalizacja</span>
        <Select
          title="Wszystkie"
          propValue={"id"}
          selected={localization_id}
          data={[{ id: null, name: "Wszystkie" }].concat(localizations)}
          handleChange={(value) => {
            // this.getSections(value);

            if (value != filter.localization_id) {
              if (value) {
                getUserSections(value)(dispatch);
              } else {
                getUserSectionsIndependently(value)(dispatch);
              }

              updateFilter("localization_id")(value);
            }
          }}
        />
      </FilterWrapper>
      <FilterWrapper>
        <span style={{ lineHeight: "34px" }}>Dział</span>
        <Select
          title="Wszystkie"
          propValue={"value"}
          selected={section_name}
          data={[{ value: null, name: "Wszystkie" }].concat(
            sections.map((i) => ({ value: i.name, name: i.name }))
          )}
          handleChange={updateFilter("section_name")}
        />
      </FilterWrapper>
      <div>
        <FilterWrapper>
          <StyledInput
            onChange={(event) => {
              updateFilter("name")(event.target.value);
            }}
            placeholder="Szukaj karty"
          />
          <Button onClick={search}>Szukaj</Button>
        </FilterWrapper>
      </div>
    </FilterWrapper>
  );
}

export default ListFilter;
