import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import QuestionSetForm from "../../components/QuestionSetForm";
import { addHazardIdentification } from "../../actions/hazardIdentificationActions";
// import SideMenu from "./_SideMenu";

class AuditAddTypes extends AppComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const { dispatch, history } = this.props;
    addHazardIdentification(values)(dispatch).then(
      this.handleServerResponse({}, () => {
        // history.push("/super-admin/draft/audit-type");
      })
    );
  }

  render() {
    const {
      audit: { permission },
    } = this.props;
    const { message } = this.state;
    return (
      <QuestionSetForm
        // SideMenu={<SideMenu />}
        title={"Dodaj szablon listy kontrolnej identyfikacji zagrożeń"}
        cancelLink={"/super-admin/draft/audit-type"}
        message={message}
        error_code={this.state.error_code == 404}
        handleSubmit={this.submit}
      />
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
}))(AuditAddTypes);
