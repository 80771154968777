import React from "react";
import AppComponent from "../../components/AppComponent";
import ClientsAccountsForm from "../../components/ClientsAccounts.Form";
class ClientsAccountsAdd extends React.Component {
  render(){
    const modules = [
      {name:"Wypadki i zdarzenia potencjalnie wypadkowe",active:true},
      {name:"Audyty" },
      {name:"Działania" },
      {name:"MSDS"}
    ];

    return (<ClientsAccountsForm
      modules={modules}
      boxTitle="Edytuj konto firmy"
    />);
  }
}

export default ClientsAccountsAdd;