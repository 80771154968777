import React from "react";
import moment from "moment";
import styled from "styled-components";

import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import { StyledTableWrapper } from "components/ListComponents";
import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
} from "components/Wrappers";
import { TableSortHeader } from "components/TableSortable";

let getDate = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format("YYYY-MM-DD");
};
export const ProgressTdStyle = styled.td`
  background: ${(props) => props.color || "inherit"};
`;

export const PseudoLink = styled.a`
  cursor: pointer;
`;

function ProgressDateTd(props) {
  let { date } = props;
  if (date === null || new Date(date) >= Date.now()) {
    return <td>{props.children}</td>;
  } else {
    return <ProgressTdStyle color={"red"}>{props.children}</ProgressTdStyle>;
  }
}

const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

export default class QEUserSegment extends React.Component {
  render() {
    let { addNewQE, editQE, data, disabled, businessView } = this.props;
    return (
      <>
        <h3>Uprawnienia kwalifikacyjne</h3>
        <ButtonWrapper>
          <Button disabled={disabled} onClick={addNewQE}>
            Dodaj nowe uprawnienia
          </Button>
        </ButtonWrapper>
        <StyledTableWrapper>
          <StyledTable striped bordered hover>
            <TableSortHeader
              headers={[
                { name: "L.p.", prop: "" },
                {
                  name: "Rodzaj uprawnień",
                  prop: "medical_examinations_type_name",
                },
                {
                  name: "Data nabycia",
                  prop: "date_get",
                },
                {
                  name: "Termin ważności",
                  prop: "date_deadline",
                },
              ]}
              data={((data && data.user_qe) || []).map((item, index) => {
                return { ...item, logicIndex: index };
              })}
              render={(sortedData) => {
                return sortedData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {editQE ? (
                          <PseudoLink onClick={editQE(item)}>
                            {item.qe_type_name}
                          </PseudoLink>
                        ) : (
                          item.qe_type_name
                        )}
                      </td>
                      <td>{getDate(item.date_get)}</td>
                      <ProgressDateTd date={item.date_deadline}>
                        {getDate(item.date_deadline)}
                      </ProgressDateTd>
                    </tr>
                  );
                });
              }}
            ></TableSortHeader>
          </StyledTable>
        </StyledTableWrapper>
      </>
    );
  }
}
