export const getFullResidencePlace = (user) => {
  let textOnly = (value, prefix = "") => (value ? `${prefix}${value}` : "");

  if (Object.keys(user).length) {
    let {
      residence_apartament_number,
      residence_building_number,
      residence_place,
      residence_postcode,
      residence_street,
    } = user;
    return `${textOnly(residence_street)}${textOnly(
      residence_building_number,
      " "
    )}${
      residence_building_number && residence_apartament_number
        ? `${textOnly(residence_apartament_number, "/")}`
        : ""
    } ${textOnly(residence_postcode, " ")} ${textOnly(residence_place, " ")}`;
  }
};
