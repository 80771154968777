import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import PropTypes from "prop-types";
import moment from "moment";

import { pingCharacteristic } from "../../actions/characteristicActions";
import dataToFormData from "../../components/dataToFormData";
import CharacteristicForm from "../../components/Characteristic.Form";
import {
  editCharacteristic,
  getCharacteristic,
} from "../../actions/characteristicActions";
import { getUserSections } from "../../actions/sectionActions";
export class CharacteristicEdit extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      listUrl: "/characteristic",
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    let { characteristic_id } = match.params;

    getCharacteristic(characteristic_id)(dispatch)
      .then(this.handleServerResponse(() => {}))
      .then(() => {
        const {
          characteristic: {
            singleData: { localization_id },
          },
        } = this.props;

        if (localization_id) {
          getUserSections(localization_id)(dispatch).then(
            this.handleServerResponse()
          );
        }
      });
    //  pingCharacteristic()(dispatch).then(this.handleServerResponse());
  }

  handleSubmit(data) {
    data.date = data.date || new Date();
    const { dispatch, match, history } = this.props;
    const { characteristic_id } = match.params;
    const formData = dataToFormData(data);
    const listUrl = this.state.listUrl;
    editCharacteristic(
      characteristic_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, function () {
        history.push(listUrl);
      })
    );
  }

  render() {
    const { message } = this.state;
    const {
      characteristic: { singleData, permission },
    } = this.props;

    const initialData = {
      ...singleData,
    };
    return (
      <CharacteristicForm
        message={message}
        initialData={initialData}
        handleSubmit={this.handleSubmit}
        permission={permission}
        listUrl={this.state.listUrl}
      />
    );
  }
}

export default connect((store) => ({
  characteristic: store.characteristic,
  message: store.message,
}))(CharacteristicEdit);
