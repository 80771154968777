export const defaultUrls = {
  ADD: `/super-admin/draft/risk-assessment/basic`,
  BASIC: (id) => `/super-admin/draft/risk-assessment/basic/${id}`,
  CHARACTERISTIC: (id) =>
    `/super-admin/draft/risk-assessment/characteristic/${id}`,
  DANGER: (id) => `/super-admin/draft/risk-assessment/danger/${id}`,
  LIST: `/super-admin/draft/risk-assessment`,
  METHODEDIT: (id) => `/super-admin/draft/ra-method/edit/${id}`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
