import { MenuLinkStyle, NavHeadTitle } from "./_styleComponents";
import { LinkContainer } from "../LinkContainer";

import React, { Fragment } from "react";

export default class MenuLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  componentWillMount() {}

  render() {
    let { hover } = this.state;
    let { url, title, className, images, active, onClick } = this.props;
    let iconMode = true;
    if (images) {
      iconMode = false;
    }
    images = images || {};
    let { base, hover: hoverIMG } = images;
    return (
      <Fragment>
        {url ? (
          <LinkContainer exact to={url}>
            <MenuLinkStyle
              onClick={onClick}
              to={url}
              title={title}
              onMouseEnter={() => {
                this.setState({ hover: true });
              }}
              onMouseLeave={() => {
                this.setState({ hover: false });
              }}
            >
              {iconMode ? (
                <i className={className} />
              ) : (
                <img
                  className="ehs-menu"
                  src={hover ? `/images/${hoverIMG}` : `/images/${base}`}
                />
              )}

              <NavHeadTitle style={{ paddingLeft: "10px", fontSize: "14px" }}>
                {title}
              </NavHeadTitle>
            </MenuLinkStyle>
          </LinkContainer>
        ) : (
          <MenuLinkStyle
            onClick={onClick}
            to={url}
            title={title}
            onMouseEnter={() => {
              this.setState({ hover: true });
            }}
            onMouseLeave={() => {
              this.setState({ hover: false });
            }}
          >
            {iconMode ? (
              <i className={className} />
            ) : (
              <img
                className="ehs-menu"
                src={hover ? `/images/${hoverIMG}` : `/images/${base}`}
              />
            )}

            <NavHeadTitle style={{ paddingLeft: "10px", fontSize: "14px" }}>
              {title}
            </NavHeadTitle>
          </MenuLinkStyle>
        )}
      </Fragment>
    );
  }
}
