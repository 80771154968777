import React from "react";
import { connect } from "react-redux";
import AppComponent, { setMessage } from "../../components/AppComponent";
import LocalizationForm from "../../components/Localization.Form";
import {
  getLocalization,
  editLocalization,
  getBusinessMainataToLocalization,
} from "../../actions/localizationActions";
import LoadingScreen from "../../components/LoadingScreen";

class EditLocalization extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getBusinessData = this.getBusinessData.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    getLocalization(localization_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  getBusinessData(callback) {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    getBusinessMainataToLocalization()(dispatch).then(
      this.handleServerResponse({}, function (action) {
        const { business } = action.payload.data;
        callback(business);
      })
    );
  }

  handleSubmit(data) {
    const { dispatch, match, history } = this.props;
    const { localization_id } = match.params;

    editLocalization(
      localization_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/localization");
      })
    );
  }

  render() {
    const { message } = this.state;
    const { localization, section, match, dispatch } = this.props;
    const { singleData: base } = localization;
    const { data: sections = [] } = section;
    return (
      <LoadingScreen fetched={localization.fetched}>
        <LocalizationForm
          dispatch={dispatch}
          localization_id={match.params.localization_id}
          title={"Edytuj lokację"}
          handleSubmit={this.handleSubmit}
          alert={message}
          base={base}
          sections={sections}
          getBusinessData={this.getBusinessData}
        />
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  section: store.section,
}))(EditLocalization);
