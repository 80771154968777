let defaultState = {
  data: [],
  attachments: [],
  singleData: {},
  victim: {},
  permission: {},
  attachmentAddFetching: false,
  fetching: false,
  fetched: true,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "REGISTER_ACCIDENT_OWTW_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "REGISTER_ACCIDENT_OWTW_SUCCESS": {
      return { ...state, fetching: false, fetched: true };
    }
    case "REGISTER_ACCIDENT_OWTW_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_ACCIDENT_OWTW_LIST_SUCCESS": {
      const { accidents, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: accidents,
        permission,
      };
    }
    case "GET_ACCIDENT_OWTW_LIST_SUCCESS": {
      const { accidents, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: accidents,
        permission,
      };
    }
    case "GET_ACCIDENT_OWTW_SUCCESS": {
      const { accident, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: accident,
        permission,
      };
    }

    case " SAVE_ANALYSIS_ACCIDENT_OWTW_SUCCESS;": {
      const { accident } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: accident,
      };
    }

    case "ADD_ACCIDENT_OWTW_SUCCESS": {
      const { accident, permission = {} } = action.payload.data;
      return {
        ...state,
        singleData: accident,
      };
    }
    case "GET_ACCIDENT_OWTW_WITNESS_SUCCESS": {
      const witnesses = action.payload.data.witnesses;

      return { ...state, witnesses };
    }
    case "GET_ACCIDENT_OWTW_VICTIM_SUCCESS": {
      const result = action.payload.data.victim;
      let { victim_fullname: full_name, ...victim } = result;

      return { ...state, victim: { ...victim, full_name } };
    }
    case "SAVE_ACCIDENT_OWTW_VICTIM_SUCCESS": {
      const result = action.payload.data.victim;
      let { victim_fullname: full_name, ...victim } = result;

      return {
        ...state,
        victim: {
          ...victim,
          full_name,
        },
      };
    }
    case "SAVE_ACCIDENT_OWTW_WITNESS_SUCCESS": {
      const result = action.payload.data.witness;
      let witnesses = state.witnesses;
      let index = state.witnesses.findIndex((item) => {
        return item.id == result.id;
      });

      if (index > -1) {
        witnesses[index] = result;
      } else {
        witnesses.push(result);
      }

      return { ...state, witnesses };
    }
    case "GET_ACCIDENT_OWTW_ATTACHMENTS_SUCCESS": {
      const { attachments, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: false,
        attachments: attachments,
        permission,
      };
    }
    case "ADD_ACCIDENT_OWTW_ATTACHMENT_REQUEST": {
      return { ...state, attachmentAddFetching: true };
    }
    case "ADD_ACCIDENT_OWTW_ATTACHMENT_ERROR": {
      return { ...state, attachmentAddFetching: false };
    }
    case "ADD_ACCIDENT_OWTW_ATTACHMENT_SUCCESS": {
      const newAttachment = [...state.attachments];
      newAttachment.push(action.payload.data.attachment);
      return {
        ...state,
        attachments: newAttachment,
        attachmentAddFetching: false,
      };
    }
    case "DELETE_ACCIDENT_OWTW_ATTACHMENT_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        attachments: state.attachments.filter(
          (item) => item.id != action.payload.data.attachment.id
        ),
      };
    }
    case "NEXT_STAGE_ACCIDENT_OWTW_SUCCESS": {
      const { stage } = action.payload.data;
      let newSingleData = { ...state.singleData };
      newSingleData.stage = stage;
      return { ...state, singleData: newSingleData };
    }
    case "DELETE_ACCIDENT_OWTW_SUCCESS": {
      const { accident_owth } = action.payload.data;
      let newData = [...state.data].filter((item) => {
        return item.id != accident_owth.id;
      });
      return { ...state, data: newData };
    }
  }
  return state;
}
