import React from 'react';
import styled from "styled-components";
import Tooltip from "rc-tooltip";

export const StyledTooltip = styled(
  ({className,children,...props})=>(
    <Tooltip overlayClassName={className} {...props}>
      {children}
    </Tooltip>
  )
)`
  max-width: 400px;
`