export const defaultUrls = {
  ADD: "/risk-assessment/control/add",
  EDIT: (id) => `/risk-assessment/control/edit/${id}`,
  LIST: `/risk-assessment/control/list`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
