import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import {
  getMEList,
  saveME,
  getMEListFilter,
  printMEReport,
} from "actions/meActions";
import { getDictionary } from "actions/dictionaryActions";
import {
  getUsersLocalizations,
  getUsersLocalizationsAndDivisions,
} from "actions/localizationActions";
import { getUserSectionsIndependently } from "actions/sectionActions";
import { getWorkplacesTreeByLocalization } from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import {
  ButtonWithIcon,
  ButtonWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
import PageHeader from "components/PageHeader";
import TableSortableWithPagination from "components/TableSortable/Register";
import SaveMedicalExaminationsFormModal from "components/MedicalExamination/SaveMedicalExaminationsFormModal";

import ModalAddUser from "container/UsersAccounts/Modal";

import SideMenu from "../_SideMenu";
import GenerateRaport from "./generateRaport";
import SummaryMedicalExaminations from "./summaryMedicalExaminations";
import { getDate } from "../_common";
import saveBlobFile from "components/saveBlobFile";

import { getLocalizationDivisionName } from "../../../util/localization";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "last_name", () => null);
  changeValue(state, "first_name", () => null);
  changeValue(state, "localization_id", () => null);
  changeValue(state, "section_name", () => "0");
  changeValue(state, "workplace_id", () => null);
  changeValue(state, "date_issue", () => null);
  changeValue(state, "date_next", () => null);
  changeValue(state, "hired_status_id", () => null);
  changeValue(state, "ability_to_work_id", () => null);
  changeValue(state, "is_me_actual", () => null);
};

export const ProgressTdStyle = styled.td`
  background: ${(props) => props.color || "inherit"};
`;

function ProgressTd(props) {
  let { days_left } = props.item;

  if (days_left === null || isNaN(days_left)) {
    return <td>{props.children}</td>;
  }

  let color = (() => {
    switch (true) {
      case days_left >= 45: {
        return "rgb(178, 255, 145)";
      }
      case days_left < 45 && days_left >= 0: {
        return "yellow";
      }
      case days_left < 0: {
        return "red";
      }
    }
  })();

  return <ProgressTdStyle color={color}>{props.children}</ProgressTdStyle>;
}

function AbilityToWorkTd(props) {
  let { ability_to_work_id: ability_to_work, ability_to_work_id } = props.item;
  if (ability_to_work_id === null || isNaN(ability_to_work_id)) {
    return <td>{props.children}</td>;
  }

  let color = (() => {
    switch (true) {
      case ability_to_work == 1: {
        return "rgb(178, 255, 145)";
      }
      case ability_to_work == 2: {
        return "red";
      }
      default: {
        return null;
      }
    }
  })();

  return <ProgressTdStyle color={color}>{props.children}</ProgressTdStyle>;
}

let AStyled = styled.a`
  cursor: pointer;
`;

class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      hired_statuses: [
        {
          id: "0",
          name: "Status zatrudnienia",
          disabled: true,
        },
        {
          id: 1,
          name: "Niezatrudniony",
        },
        {
          id: 2,
          name: "Zatrudniony",
        },
      ],

      modalUserDetails: {
        open: false,
        data: {},
      },
      modalAddUser: {
        open: false,
        user: {},
      },
      addME: { open: false, data: {} },
    };

    this.clearFilter = this.clearFilter.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.getMEList = this.getMEList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.print = this.print.bind(this);
    this.saveME = this.saveME.bind(this);
    this.saveUserCallback = this.saveUserCallback.bind(this);
  }
  componentDidMount() {
    let { dispatch } = this.props;
    this.getMEList();
    getDictionary("ability-to-work")(dispatch).then(
      this.handleServerResponse()
    );
    getUsersLocalizationsAndDivisions()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    // getUserWorkplacesParentWithout()(dispatch).then(
    //   this.handleServerResponse()
    // );
    getDictionary("hired-status")(dispatch).then(this.handleServerResponse());
    // getWorkplacesTree()(dispatch).then(this.handleServerResponse());
    getWorkplacesTreeByLocalization()(dispatch).then(
      this.handleServerResponse()
    );
  }

  clearFilter(form) {
    let {} = this.props;
    this.form.mutators.clearFilterMutator();
    this.getMEList();
  }

  getMEList(cb) {
    let { dispatch } = this.props;
    getMEList()(dispatch).then(this.handleServerResponse({}, cb));
  }

  filterSubmit(data) {
    let { dispatch } = this.props;

    let filterZeroId = (data) => {
      let keys = [
        "localization_id",
        "section_name",
        "workplace_id",
        "hired_status_id",
        "ability_to_work_id",
        "is_me_actual",
      ];

      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = null;
        }
      }
      return data;
    };

    data.ability_to_work_id =
      !data.ability_to_work_id || data.ability_to_work_id == "0"
        ? data.ability_to_work_id
        : data.ability_to_work_id == "true"
        ? 1
        : 2;

    const sendData = { ...data };

    const { localizations } = this.props;

    if (sendData.localization_id) {
      const localizationsData = localizations.find((i) => {
        return i.id == sendData.localization_id;
      });
      if (localizationsData && localizationsData.division) {
        sendData.division_id = data.localization_id;
        delete sendData.localization_id;
      }
    }

    getMEListFilter(filterZeroId(sendData))(dispatch).then(
      this.handleServerResponse()
    );
  }

  getMEActual(item) {
    let { is_me_actual } = item;
    if (is_me_actual === true) {
      return "TAK";
    }
    if (is_me_actual === false) {
      return "NIE";
    }
    return null;
  }

  getAbilityToWork(ability_to_work_id) {
    if (ability_to_work_id == 1) {
      return "TAK";
    }
    if (ability_to_work_id == 2) {
      return "NIE";
    }
    return null;
  }

  getHiredStatus(id) {
    if (!id) {
      return "Niezatrudniony";
    }

    return this.state.hired_statuses.find((i) => {
      return i.id == id;
    }).name;
  }

  handlePageChange(data) {
    this.setState({ data });
  }

  print(data) {
    let { dispatch } = this.props;
    printMEReport(data)(dispatch).then((res) => {
      if (!res.error) {
        let file;
        let fileName;

        let fileNamePrefix = `Rejestr badań lekarskich - ${moment().format(
          "YYYY-MM-DD"
        )}`;

        if (data.pdf) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.pdf`;
        }
        if (data.xml) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.xls`;
        }

        saveBlobFile(file, fileName);
      }
    });
  }

  saveME(data) {
    let { dispatch } = this.props;

    let { id } = data;
    saveME(id, { ...data })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        this.setState({
          modalUserDetails: {
            ...this.state.modalUserDetails,
            data: action.payload.data.data,
          },
        });
        this.getMEList(() => {});
      })
    );
  }

  saveUserCallback({ message }) {
    this.getMEList(() => {
      this.setSuccessMessage(message);
    });
    this.closeModal("modalAddUser");
  }
  render() {
    const { message, data } = this.state;
    const {
      ability_to_work = [],
      dictionary,
      localizations,
      sections,
      workplaces,
      permission,
    } = this.props;

    const that = this;

    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={<SideMenu />}
      >
        <PageHeader>Rejestr badań lekarskich</PageHeader>
        <ButtonWrapper>
          <LinkContainer to="/medical-examination/referral">
            <Button disabled={!permission[53]}>Wystaw skierowanie</Button>
          </LinkContainer>
          <GenerateRaport disabled={!permission[41]} print={this.print} />
          <Button
            disabled={!permission[63]}
            onClick={this.openModal("modalAddUser")}
            icon="fa fa-plus"
          >
            Dodaj pracowika
          </Button>
        </ButtonWrapper>
        <TableSortableWithPagination
          data={this.props.data}
          headers={[
            { name: "Nazwisko", prop: "last_name" },
            { name: "Imię", prop: "first_name" },
            {
              name: "Lokalizacja",
              prop: "localization_id",
            },
            { name: "Dział", prop: "section_name" },
            { name: "Stanowisko pracy", prop: "workplace_id" },
            {
              name: "Data ostatnich badań profilaktycznych",
              prop: "date_last",
            },
            { name: "Termin wykonania następnych badań", prop: "date_next" },
            { name: "Status zatrudnienia", prop: "hired_status_id" },
            {
              name: "Zdolność do pracy",
              prop: "ability_to_work_name",
            },
            {
              name: "Pozostało dni do badań",
              prop: "days_to_next",
            },
            {
              name: "Aktualność badań",
              prop: "is_me_actual",
            },
            { name: "Wystaw skierowanie" },
            { name: "Edytuj dane pracownika" },
          ]}
          renderSearcher={
            <Form
              ref={(form) => (that.form = form)}
              initialValues={{
                localization_id: "0",
                section_name: "0",
                workplace_id: "0",
                hired_status_id: "0",
                ability_to_work_id: "0",
                is_me_actual: "0",
              }}
              mutators={{ clearFilterMutator }}
              onSubmit={this.filterSubmit}
              render={({ handleSubmit, pristine, invalid, form }) => {
                that.form = form;
                return (
                  <>
                    <tr>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"last_name"}
                          placeholder={"wg Nazwiska"}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"first_name"}
                          placeholder={"wg Imienia"}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"localization_id"}
                          data={[{ id: "0", name: "Lokalizacja" }].concat(
                            localizations
                          )}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"section_name"}
                          propValue="value"
                          data={[{ value: "0", name: "Dział" }].concat(
                            sections
                          )}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"workplace_id"}
                          data={[{ id: "0", name: "Stanowisko" }].concat(
                            workplaces
                          )}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_last"} />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_next"} />
                      </td>

                      <td>
                        <FieldSelect
                          name={"hired_status_id"}
                          data={[
                            {
                              id: "0",
                              name: "Status zatrudnienia",
                              disabled: true,
                            },
                          ].concat(dictionary.hired_status)}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"ability_to_work_id"}
                          data={[
                            {
                              id: "0",
                              name: "Zdolność do pracy",
                              disabled: true,
                            },
                            {
                              id: "true",
                              name: "TAK",
                            },
                            {
                              id: "false",
                              name: "NIE",
                            },
                          ]}
                        />
                      </td>
                      <td></td>
                      <td>
                        <FieldSelect
                          name={"is_me_actual"}
                          data={[
                            {
                              id: "0",
                              name: "Aktualność badań",
                            },
                            {
                              id: "true",
                              name: "Tak",
                            },
                            {
                              id: "false",
                              name: "Nie",
                            },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="13" style={{ padding: "8px 40px 8px 8px" }}>
                        <ButtonWrapper nowrap>
                          <Button
                            type="type"
                            onClick={handleSubmit}
                            bsStyle="primary"
                          >
                            Szukaj
                          </Button>
                          <Button onClick={this.clearFilter}>
                            Wyłącz filtr
                          </Button>
                        </ButtonWrapper>
                      </td>
                    </tr>
                  </>
                );
              }}
            />
          }
          render={(sortedData) => {
            return sortedData.map((item) => {
              return (
                <tr>
                  <td>
                    <AStyled
                      onClick={this.openModal("modalUserDetails", {
                        data: item,
                      })}
                    >
                      {item.last_name}
                    </AStyled>
                  </td>
                  <td>{item.first_name}</td>
                  <td>{getLocalizationDivisionName(item)}</td>
                  <td>{item.section_name}</td>
                  <td>{item.workplace_name}</td>
                  <td>{getDate(item.date_last)}</td>
                  <td>{getDate(item.date_next)}</td>
                  <td>{this.getHiredStatus(item.hired_status_id)}</td>
                  <AbilityToWorkTd item={item}>
                    {this.getAbilityToWork(item.ability_to_work_id)}
                  </AbilityToWorkTd>

                  <ProgressTd item={item}>{item.days_left}</ProgressTd>
                  <ProgressTd item={item}>{this.getMEActual(item)}</ProgressTd>
                  <td>
                    <LinkContainer
                      to={`/medical-examination/referral?user_id=${item.users_id}`}
                    >
                      <ButtonWithIcon
                        disabled={!permission[53]}
                        icon="fa fa-print"
                      ></ButtonWithIcon>
                    </LinkContainer>
                  </td>
                  <td>
                    <ButtonWithIcon
                      disabled={!permission[65]}
                      onClick={this.openModal("modalAddUser", {
                        users_id: item.users_id,
                      })}
                      icon="fa fa-pencil-square-o"
                    ></ButtonWithIcon>
                  </td>
                </tr>
              );
            });
          }}
        />
        <SummaryMedicalExaminations
          open={this.state.modalUserDetails.open}
          data={this.state.modalUserDetails.data}
          addME={this.openModal("addME", {
            data: { users_id: this.state.modalUserDetails.data.users_id },
          })}
          editME={(item) =>
            this.openModal("addME", {
              data: item,
            })
          }
          closeModal={this.closeModal("modalUserDetails")}
          dictionary={dictionary}
          permission={permission}
        />
        <SaveMedicalExaminationsFormModal
          saveME={this.saveME}
          open={this.state.addME.open}
          data={this.state.addME.data}
          closeModal={this.closeModal("addME")}
          permission={permission}
        />
        <ModalAddUser
          {...this.state.modalAddUser}
          accept={this.saveUserCallback}
          closeModal={this.closeModal("modalAddUser", { users_id: null })}
          permission={permission}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  data: store.me.data,
  dictionary: store.dictionary,
  ability_to_work: store.dictionary.ability_to_work,
  localizations: store.localization.data,
  sections: store.section.data,
  workplaces: store.workplace.data,
  permission: store.me.permission,
}))(List);
