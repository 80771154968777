import React from "react";
import Core from "./_core";

class NotFoundSuperAdmin extends Core {
  constructor(props){
    super(props);
    this.state = {...this.state,
      mainPage:"/super-admin/dashboard"
    };
  }

}

export default NotFoundSuperAdmin;