import React from "react";
import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import App from "./app";
import reportWebVitals from "./reportWebVitals";

// import Bootstrap from "bootstrap-css-only/css/bootstrap.css";
import BootstrapTheme from "bootstrap-css-only/css/bootstrap-theme.css";
import FontAwesome from "font-awesome/css/font-awesome.min.css";
import TextEditor from "./style/sass/text-editor-styles.sass";
import BoostrapOverride from "./style/sass/custom-boostrap.sass";
import DayPickerCss from "react-day-picker/lib/style.css";
import HeaderMenu from "./style/sass/header-menu.sass";
import LoadingSpinner from "./style/sass/loading-spinner.sass";
import ReactQuill from "react-quill/dist/quill.snow.css";

import "./style/css/init.css"; //TODO:

// if (process.env.NODE_ENV !== "production" && module.hot) {
//   ReactDOM.render(
//     <AppContainer>
//       <App />
//     </AppContainer>,
//     document.getElementById("root")
//   );

//   module.hot.accept("./app", () => {
//     const NextApp = require("./app").default;
//     ReactDOM.render(
//       <AppContainer>
//         <NextApp />
//       </AppContainer>,
//       document.getElementById("root")
//     );
//   });
// } else {
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,

  document.getElementById("root")
);
reportWebVitals();
