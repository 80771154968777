import React from "react";
import { connect } from "react-redux";

import { getUsersLocalizations } from "actions/localizationActions";
import { getUserWorkplacesWithout } from "actions/workplaceActions";
import { getAudit, getAuditTypes, saveAuditData } from "actions/auditActions";

import { getUserSections } from "actions/sectionActions";
import { getUserByWorkplaceByUser } from "actions/userActions";

import AuditDataForm from "components/AuditData.Form";
import AppComponent from "components/AppComponent";

import SideMenu from "./_SideMenu";
class AuditData extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    Promise.all([
      getAudit(audit_id)(dispatch).then(this.handleServerResponse()),
      getAuditTypes()(dispatch).then(this.handleServerResponse()),
      getUsersLocalizations()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse()),
    ]).then(() => {
      const {
        audit: {
          singleData: { localization_id, workplace_id },
        },
      } = this.props;
      if (localization_id) {
        getUserSections(localization_id)(dispatch).then(
          this.handleServerResponse()
        );
      }
      if (workplace_id) {
        getUserByWorkplaceByUser(workplace_id)(dispatch).then(
          this.handleServerResponse()
        );
      }
    });
  }

  handleSubmit(values) {
    const { dispatch, history, match } = this.props;
    const { audit_id } = match.params;
    saveAuditData(
      audit_id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/audit/question/" + audit_id);
      })
    );
  }

  render() {
    const { message } = this.state;
    const {
      audit: { singleData: auditData, typeData, permission },
      localization: { data: localizationData },
      section: { data: sectionData },
      workplace: { data: workplaceData },
      users: { data: usersData },
      match,
    } = this.props;
    const { audit_id } = match.params;
    return (
      <AuditDataForm
        SideMenu={
          <SideMenu
            permission={permission}
            audit_id={auditData.id}
            stage={auditData.stage}
          />
        }
        audit_id={audit_id}
        readOnly={!permission[3]}
        error_code={this.state.error_code == 404}
        auditData={auditData}
        submit={this.handleSubmit}
        typeData={typeData}
        localizationData={localizationData}
        sectionData={sectionData}
        workplaceData={workplaceData}
        usersData={usersData}
        message={message}
      />
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  users: store.users,
}))(AuditData);
