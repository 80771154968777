import React from "react";
import {connect} from "react-redux";
import AppComponent from "../../components/AppComponent";
import styled from "styled-components";
import PropTypes from "prop-types";
import {getSuperAdminAccountData} from "../../actions/accountActions";

import QuickAccessButton,{QuickAccessButtonWrapper} from "../../components/QuickAccessButton";
import superadmin from "../../reducers/superAdminReducers";

class SuperDashboard extends AppComponent {

  componentDidMount(){
    const {dispatch} = this.props;
    getSuperAdminAccountData()(dispatch)
      .then(this.handleServerResponse({},()=>{}));
  }

  render(){
    return (  <div>
      <QuickAccessButtonWrapper>
        <QuickAccessButton to="/super-admin/businesses/add">Dodaj konto firmy</QuickAccessButton>
      </QuickAccessButtonWrapper>
    </div>);
  }
}

export default connect(store=>({
  superadmin:store.superadmin
}))(SuperDashboard);