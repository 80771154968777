import React from "react";
import {connect} from "react-redux";
import AppComponent,{setMessage} from "../../components/AppComponent";
import AccountEditComponent from "../../components/AccountEdit";
import {media} from "../../style/style-utils";
import {withCookies} from "react-cookie";
import {
  getSuperAdminAccountData,
  saveSuperAdminAvatar,
  deleteSuperAdminAvatar,
  saveSuperAdminAccount,
  saveSuperAdminNewPassword
} from "../../actions/accountActions";

class AccountEdit extends AppComponent {
  constructor(props) {
    super(props);

    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.handleAvatarAutoUpload = this.handleAvatarAutoUpload.bind(this);
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
    this.handleSubmitNewPassword = this.handleSubmitNewPassword.bind(this);
    this.handleSubmitData = this.handleSubmitData.bind(this);
    this.state = {
      ...this.state,
    };
  }

  componentDidMount(){
    const {dispatch} = this.props;
    getSuperAdminAccountData()(dispatch)
      .then(this.handleServerResponse());
  }

  deleteAvatar() {
    this.setState({modalAvatar: {...this.state.modalAvatar, open: true}});
  }

  closeModal(modalName) {
    return () => {
      this.setState({[modalName]: {...this.state[modalName], open: false}});
    };
  }

  handleAvatarAutoUpload(formData,callback){
    saveSuperAdminAvatar(formData)(this.props.dispatch)
      .then(this.handleServerResponse({},action=>{
        const {message,avatar_url} = action.payload.data;
        this.setState({message:setMessage(action.payload.data.message,"success")});
        callback(avatar_url);
      }));
  }

  handleDeleteAvatar(callback){
    deleteSuperAdminAvatar()(this.props.dispatch)
      .then(this.handleServerResponse({},action=>{
        this.setState({message:setMessage(action.payload.data.message,"success")});
        callback();
      }));

  }

  handleSubmitData(data){
    saveSuperAdminAccount(data)(this.props.dispatch)
      .then(this.handleServerResponse());
  }

  handleSubmitNewPassword(data){
    saveSuperAdminNewPassword(data)(this.props.dispatch)
      .then(this.handleServerResponse());
  }

  render() {
    const {message} = this.state;
    const {account:{singleData:accountData}} = this.props;
    const sideMenu = [
      {name:"Ustawienia konta",url:"/super-admin/account/edit"},
    ];

    return (<div>
      <AccountEditComponent
        superAdmin
        message={message}
        handleShowSettingChange={this.handleShowSettingChange}
        data={accountData}
        settings={accountData.show_settings}
        sideMenu={sideMenu}
        handleAvatarAutoUpload={this.handleAvatarAutoUpload}
        handleDeleteAvatar={this.handleDeleteAvatar}
        handleSubmitNewPassword={this.handleSubmitNewPassword}
        handleSubmitData={this.handleSubmitData}
      />

    </div>);
  }
}

export default withCookies(connect(store=>({
  account:store.account,
  users: store.users
}))(AccountEdit));