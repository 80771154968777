import React from "react";
import SideMenu from "../../components/SideMenu";

const LSideMenu = ({
  accident_id,
  stage,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <div>
    <SideMenu
      title="Wypadki w drodze"
      data={[
        { name: "Rejestr wypadków", url: "/accident-owtw" },
        {
          name: "Zgłoś nowy wypadek",
          url: "/accident-owtw/register/99",
          onClick: addNewIncident,
        },
      ]}
    />
    {accident_id && (
      <SideMenu
        title="Dodaj wypadek"
        mobileNotHide
        data={[
          {
            name: "Zgłoszenie",
            url: `/accident-owtw/register/${accident_id}`,
            disabled: stage < 0,
          },
          {
            name: "Analiza zdarzenia",
            url: `/accident-owtw/analysis/${accident_id}`,
            disabled: stage < 1,
          },
          {
            name: "Załączniki",
            url: `/accident-owtw/attachment/${accident_id}`,
            disabled: stage < 2,
          },
          {
            name: "Powiadomienia",
            url: `/accident-owtw/notification/${accident_id}`,
            disabled: stage < 3,
          },
        ]}
      />
    )}
  </div>
);

export default LSideMenu;
