import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { Link } from "react-router-dom";
import { LayoutContent } from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import {
  ButtonWrapper,
  ButtonWithIcon,
  DeleteButton,
  LpTd,
} from "../../components/ListComponents";
import { LinkContainer } from "../../components/LinkContainer";
import Pagination from "../../components/Pagination";
import { media } from "../../style/style-utils";
import { StyledInput } from "../../style/styleComponents/Form/input";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import Select from "../../components/Select";
import PageHeader from "../../components/PageHeader";
import { TableSortHeader } from "../../components/TableSortable";
import Alert from "react-bootstrap/lib/Alert";

import {
  getUsers,
  deleteUser,
  getUsersByFilter,
} from "../../actions/userActions";
import { getWorkplacesDenest } from "../../actions/workplaceActions";
import { getLocalizations } from "../../actions/localizationActions";
import Modal from "../../components/Modal/SimpleAccept";
import { getSectionsIndependently } from "../../actions/sectionActions";
import LoadingScreen from "../../components/LoadingScreen";
import {
  StyledTableListWithSearch,
  StyledTableWrapper,
} from "../../components/ListComponents";

const ContentHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;

export class UserAccountsList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      localFetched: true,
      data: [],
      filterData: [],
      userDataFetched: false,
      filter: {
        name: null,
        division_id: null,
        localization_id: null,
        section_id: null,
        workplace_id: null,
      },
      modal: {
        open: false,
        entry_id: null,
      },
    };

    this.deleteRequest = this.deleteRequest.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseDeleteRequest = this.handleCloseDeleteRequest.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.handleFilterName = this.handleFilter.bind(this)("name");
    this.handleFilterLocalization = this.handleFilter.bind(this)(
      "localization"
    );
    this.handleFilterSection = this.handleFilter.bind(this)("section");
    this.handleFilterWorkplace = this.handleFilter.bind(this)("workplace");
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.updateMessage();
    Promise.all([
      getUsers()(dispatch).then(this.handleServerResponse({}, () => {})),
      getWorkplacesDenest()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getLocalizations()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getSectionsIndependently()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
    ]).then(() => {
      this.setState({ localFetched: true });
    });
  }

  deleteRequest(entry_id) {
    const that = this;
    return () => {
      that.setState({ modal: { ...this.modal, open: true, entry_id } });
    };
  }

  handleDelete() {
    const { entry_id } = this.state.modal;
    const { dispatch } = this.props;

    deleteUser(entry_id)(dispatch).then(this.handleServerResponse());
  }

  handleCloseDeleteRequest() {
    this.setState({ modal: { ...this.modal, open: false, entry_id: null } });
  }

  handleFilter(name) {
    return (value) => {
      switch (name) {
        case "name": {
          this.setState({
            filter: { ...this.state.filter, name: value.target.value },
          });
          break;
        }
        case "localization": {
          const set = value.division ? "division_id" : "localization_id";
          const clean = value.division ? "localization_id" : "division_id";
          this.setState({
            filter: { ...this.state.filter, [set]: value.value, [clean]: null },
          });
          break;
        }
        case "section": {
          this.setState({
            filter: { ...this.state.filter, section_name: value },
          });
          break;
        }
        case "workplace": {
          this.setState({
            filter: { ...this.state.filter, workplace_id: value },
          });
          break;
        }
      }
    };
  }

  handleFilterSearch(nextProps) {
    const { dispatch } = this.props;
    const {
      name,
      division_id,
      localization_id,
      section_id,
      workplace_id,
    } = this.state.filter;
    const newRegex = new RegExp(name);

    const filter = function (item) {
      return (
        (name && name.length ? newRegex.test(item.first_name) : true) &&
        (division_id ? item.division_id == division_id : true) &&
        (localization_id ? item.localization_id == localization_id : true) &&
        (section_id ? item.section_id == section_id : true) &&
        (workplace_id ? item.workplace_id == workplace_id : true)
      );
    };
    getUsersByFilter(this.state.filter)(dispatch).then(
      this.handleServerResponse()
    );
  }

  resetFilter() {
    this.setState(
      {
        filter: {
          name: null,
          division_id: null,
          localization_id: null,
          section_id: null,
          workplace: null,
        },
      },
      () => {
        this.handleFilterSearch();
      }
    );
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  render() {
    const { modal, message, filter, localFetched } = this.state;
    const {
      users: { data: usersData },
      localization: { data: localizationData },
      workplace: { data: workplaceData },
      section: { data: sectionData },
    } = this.props;

    const selectLocalizationData = [{ id: null, name: "Wszystkie" }].concat(
      localizationData
    );
    const selectSectionData = [{ value: null, name: "Wszystkie" }].concat(
      sectionData
    );
    const selectWorkplaceData = [{ id: null, name: "Wszystkie" }].concat(
      workplaceData
    );

    return (
      <LoadingScreen fetched={localFetched}>
        <LayoutContent>
          {message && message.text && (
            <Alert bsStyle={message.className}>{message.text}</Alert>
          )}
          <PageHeader>Lista użytkowników</PageHeader>
          <ContentHeaderWrapper>
            <span>Szukaj</span>
            <LinkContainer to="/business/users-accounts/add">
              <ButtonWithIcon icon="fa fa-plus" name="Dodaj użytkownika" />
            </LinkContainer>
          </ContentHeaderWrapper>
          <StyledTableWrapper>
            <StyledTableListWithSearch>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <StyledInput
                      value={filter.name}
                      onChange={this.handleFilterName}
                      placeholder="Imię i nazwisko"
                    />
                  </td>
                  {filter.division_id ? (
                    <td>
                      <Select
                        propValue="id"
                        selected={filter.division_id}
                        handleChange={this.handleFilterLocalization}
                        extended={["division"]}
                        data={selectLocalizationData}
                        fullWidth
                        title="Dywizja / lokalizacja"
                      />
                    </td>
                  ) : (
                    ""
                  )}
                  {!filter.division_id ? (
                    <td>
                      <Select
                        propValue="id"
                        selected={filter.localization_id}
                        handleChange={this.handleFilterLocalization}
                        extended={["division"]}
                        data={selectLocalizationData}
                        fullWidth
                        title="Dywizja / lokalizacja"
                      />
                    </td>
                  ) : (
                    ""
                  )}
                  <td>
                    <Select
                      propValue="value"
                      selected={filter.section_name}
                      handleChange={this.handleFilterSection}
                      data={selectSectionData}
                      fullWidth
                      title="Wydział"
                    />
                  </td>
                  <td>
                    <Select
                      propValue="id"
                      selected={filter.workplace_id}
                      handleChange={this.handleFilterWorkplace}
                      data={selectWorkplaceData}
                      fullWidth
                      title="Stanowisko"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={6} style={{ paddingTop: "10px" }}>
                    <ButtonWrapper>
                      <Button
                        onClick={this.handleFilterSearch}
                        bsStyle="primary"
                      >
                        Szukaj
                      </Button>
                      <Button onClick={this.resetFilter}>Wyłącz filtr</Button>
                    </ButtonWrapper>
                  </td>
                </tr>
                <TableSortHeader
                  headers={[
                    { name: "L.p.", prop: "" },
                    { name: "Imię  i nazwisko", prop: "first_name" },
                    { name: "Dywizja / Lokacja", prop: "division_name" },
                    { name: "Wydział", prop: "section_name" },
                    { name: "Stanowisko", prop: "workplace_name" },
                    { name: "Usuń", prop: "" },
                  ]}
                  data={this.state.data}
                  render={(sortedData) => {
                    return sortedData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/business/users-accounts/edit/${item.id}`}>
                            {item.first_name} {item.last_name}
                          </Link>
                        </td>
                        <td>
                          {item.division_name}
                          {item.localization_division_name
                            ? `${item.localization_division_name} / `
                            : ""}
                          {item.localization_name}
                        </td>
                        <td>{item.section_name}</td>
                        <td>{item.workplace_name}</td>
                        <td>
                          <DeleteButton onClick={this.deleteRequest(item.id)} />
                        </td>
                      </tr>
                    ));
                  }}
                />
              </tbody>
            </StyledTableListWithSearch>
          </StyledTableWrapper>
          <Pagination
            handlePageChange={this.handlePageChange.bind(this)}
            data={usersData}
          />
          <Modal
            title="Usuń lokalizację/dywizję"
            description={"Czy chcesz usunąć dany wpis?"}
            open={modal.open}
            handleAccept={this.handleDelete}
            handleClose={this.handleCloseDeleteRequest}
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    this.setState({ localFetched: false });
  }
}

export default connect((store) => ({
  users: store.users,
  message: store.message,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  module: store.module,
}))(UserAccountsList);

UserAccountsList.propTypes = {
  users: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    localization: PropTypes.string,
    sector: PropTypes.string,
    workplace: PropTypes.string,
  }),
  workplace: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        children: PropTypes.array,
      })
    ),
  }),
};
