import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Field, FormSpy } from "react-final-form";
import validator from "../Validation/";
import Modal from "../Modal/SimpleAccept";

import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
} from "../../style/styleComponents/Form/input";
import ProfileImage from "../ProfileImage";
import Select from "../Select";
import { media } from "../../style/style-utils";
import dataToFormData from "../dataToFormData";
// import { getWorkplacesWithout } from "actions/workplaceActions";

import {
  getUserByWorkplaceByUser,
  getHiredStatuses,
} from "actions/userActions";
import AppComponent from "../AppComponent";

import { BoxesWrapper } from "./_stylesComponents";
import { getSections, clearSections } from "../../actions/sectionActions";

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
class UserAccountsDefaultData extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl: false,
      defaultImage: "/images/default_avatar.png",
      modalAvatarDelete: {
        open: false,
      },
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDeleteAvatarRequest = this.handleDeleteAvatarRequest.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { data = {} } = nextProps;
    if (this.state.imagePreviewUrl !== data.avatar_url) {
      this.setState({
        fetched: true,
        imagePreviewUrl: data.avatar_url ? data.avatar_url : null,
      });
    }
  }

  handleOpenModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: true } });
    };
  }

  handleImageChange(e) {
    e.preventDefault();
    const that = this;
    const { handleAvatarAutoUpload } = this.props;
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    if (handleAvatarAutoUpload) {
      const formData = dataToFormData({ avatar: file });
      handleAvatarAutoUpload(formData, function (url) {
        that.setState({ imagePreviewUrl: url });
      });
    }
  }

  handleCloseModal(modalName) {
    return (callback = () => {}) => {
      this.setState(
        { [modalName]: { ...this.state[modalName], open: false } },
        () => {
          callback();
        }
      );
    };
  }

  handleDeleteAvatarRequest() {
    this.handleCloseModal("modalAvatarDelete")(this.props.handleDeleteAvatar);
    if (this.props.createView) {
      this.setState({ imagePreviewUrl: null });
    }
  }

  handleLocalizationChange(data, mutators) {
    const { dispatch, getSections } = this.props;
    const { value, division } = data;
    mutators.setField("division_id")(value);
    if (division) {
      mutators.setField("division_id")(value);
      mutators.clearField("localization_id");
    } else {
      mutators.setField("localization_id")(value);
      mutators.clearField("division_id");
    }
    mutators.clearField("section_id");
    if (division) {
      clearSections();
    } else {
      getSections(value)(dispatch).then(this.handleServerResponse());
    }
  }

  handleWorkplaceChange(name, form) {
    const { dispatch, entry_id } = this.props;
    return (value) => {
      form.change("user_parent_id", "0");
      this.setState({ [name]: { ...this.state[name], value } }, () => {
        getUserByWorkplaceByUser(
          value,
          entry_id
        )(dispatch).then(this.handleServerResponse());
      });
    };
  }

  render() {
    const {
      create,
      boxTitle,
      workplaces,
      parent_workplaces,
      handleAvatarAutoUpload,
      localizationsData,
      parentWorkplacesData,
      usersData,
      onWorkplaceChange = () => {},
      section: { data: sectionData },
      users,
      publicView,
      readOnly,
    } = this.props;
    let { modalAvatarDelete } = this.state;
    let { hired_statuses: hiredStatusData } = users;
    const sections = [{ id: "0", name: "Brak" }].concat(sectionData);
    // const usersData = [{ id: "0", name: "Brak" }].concat(users);

    return (
      <Fragment>
        <h3>Dane ogólne</h3>
        <BoxesWrapper>
          <div>
            <Field
              name="first_name"
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  disabled={readOnly}
                  label="Imię"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                />
              )}
            />
            <Field
              name="last_name"
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  disabled={readOnly}
                  label="Nazwisko"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                />
              )}
            />
            {!publicView && (
              <Field
                name="email"
                validate={composeValidators(validator.shouldBeEmail)}
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    disabled={readOnly}
                    label="Adres e-mail"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    type="email"
                    value={input.value}
                    onChange={(e) => {
                      input.onChange(e.target.value);
                    }}
                  />
                )}
              />
            )}
            <Field
              name="phone"
              // validate={composeValidators(validator.shouldBeEmail)}
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  disabled={readOnly}
                  label="Nr telefonu"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  value={input.value}
                  onChange={(e) => {
                    input.onChange(e.target.value);
                  }}
                />
              )}
            />
            <StyledLabel>Funkcja / stanowisko</StyledLabel>
            <Field
              name="workplace_id"
              render={({ input, meta, ...rest }) => (
                <Select
                  disabled={readOnly}
                  fullWidth
                  title="-- wybierz --"
                  data={[{ id: "0", name: "Brak" }].concat(workplaces)}
                  propValue="id"
                  selected={input.value}
                  handleChange={(data) => {
                    input.onChange(data);
                    onWorkplaceChange(data);
                  }}
                />
              )}
            />
          </div>
          <div>
            <StyledLabel>Zdjęcie profilowe</StyledLabel>
            {handleAvatarAutoUpload ? (
              <ProfileImage
                disabled={readOnly}
                imageSrc={this.state.imagePreviewUrl}
                handleDelete={this.handleOpenModal("modalAvatarDelete")}
                handleChange={(event) => {
                  this.handleImageChange(event);
                }}
                defaultImage={this.state.defaultImage}
              />
            ) : (
              <Field
                name="avatar"
                render={({ input, meta, ...rest }) => (
                  <ProfileImage
                    disabled={readOnly}
                    imageSrc={this.state.imagePreviewUrl}
                    handleDelete={this.handleOpenModal("modalAvatarDelete")}
                    handleChange={(event) => {
                      this.handleImageChange(event);
                      input.onChange(event.target.files[0]);
                    }}
                    defaultImage={this.state.defaultImage}
                  />
                )}
              />
            )}
          </div>
        </BoxesWrapper>
        <FormSpy subscribe={{ values: true, form: true }}>
          {({ values, form }) => {
            return (
              <>
                {" "}
                <BoxesWrapper>
                  <div>
                    <StyledLabel>Obszar działania</StyledLabel>
                    <Field
                      name="localization_id"
                      render={({ input, meta, ...rest }) => {
                        return !values.division_id ? (
                          <Select
                            disabled={readOnly}
                            handleChange={(data) => {
                              this.handleLocalizationChange(
                                data,
                                form.mutators
                              );
                              // input.onChange(data);
                            }}
                            fullWidth
                            propValue={"id"}
                            extended={["division"]}
                            title="Bez limitu"
                            data={localizationsData}
                            selected={input.value}
                          />
                        ) : (
                          <input {...input} style={{ display: "none" }} />
                        );
                      }}
                    />
                    <Field
                      name="division_id"
                      render={({ input, meta, ...rest }) => {
                        return values.division_id ? (
                          <Select
                            disabled={readOnly}
                            handleChange={(data) => {
                              this.handleLocalizationChange(
                                data,
                                form.mutators
                              );
                              // input.onChange(data);
                            }}
                            fullWidth
                            propValue={"id"}
                            extended={["division"]}
                            title="Bez limitu"
                            data={localizationsData}
                            selected={input.value}
                          />
                        ) : (
                          <input {...input} style={{ display: "none" }} />
                        );
                      }}
                    />
                  </div>
                  {values.localization_id && (
                    <div>
                      <Field
                        name="section_id"
                        render={({ input, meta, ...rest }) => (
                          <Select
                            disabled={readOnly}
                            label="Dział"
                            fullWidth
                            title="Brak"
                            propValue={"id"}
                            data={sections}
                            selected={input.value}
                            handleChange={(data) => {
                              input.onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                </BoxesWrapper>
                <BoxesWrapper>
                  <Field
                    name="workplace_parent_id"
                    render={({ input, meta, ...rest }) => (
                      <Select
                        disabled={readOnly}
                        handleChange={(data) => {
                          this.handleWorkplaceChange(
                            "selected_parent_workplace",
                            form
                          )(data);
                          input.onChange(data);
                        }}
                        label="Przełożony"
                        fullWidth
                        title="Brak"
                        propValue={"id"}
                        data={parentWorkplacesData}
                        selected={input.value}
                      />
                    )}
                  />
                  {values.workplace_parent_id && (
                    <Field
                      name="user_parent_id"
                      render={({ input, meta, ...rest }) => (
                        <Select
                          disabled={readOnly}
                          handleChange={(data) => {
                            input.onChange(data);
                          }}
                          label="Przełożony"
                          fullWidth
                          title="Brak"
                          propValue={"id"}
                          data={users.data || []}
                          selected={input.value}
                        />
                      )}
                    />
                  )}
                </BoxesWrapper>
              </>
            );
          }}
        </FormSpy>
        <BoxesWrapper>
          <div>
            <Field
              name="hired_status_id"
              render={({ input, meta, ...rest }) => (
                <Select
                  disabled={readOnly}
                  handleChange={(data) => {
                    input.onChange(data);
                  }}
                  label="Status zatrudnienia"
                  fullWidth
                  title="Brak"
                  propValue={"id"}
                  data={hiredStatusData}
                  selected={input.value}
                />
              )}
            />
          </div>
        </BoxesWrapper>
        <Modal
          open={modalAvatarDelete.open}
          title="Usuń awatar"
          description="Czy chcesz usunąć awatar użytkownika?"
          handleClose={this.handleCloseModal("modalAvatarDelete")}
          handleAccept={this.handleDeleteAvatarRequest}
        />
      </Fragment>
    );
  }
}
export default connect((store) => ({
  section: store.section,
  users: store.users,
}))(UserAccountsDefaultData);
