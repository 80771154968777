import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { StyledLabel } from "../style/styleComponents/Form/input";
import { media } from "../style/style-utils/index";
import { isBrowser, isMobile } from "react-device-detect";
import { CSSTransition } from "react-transition-group";

import SelectHooks from "components/Select/Select.js";

export const StyledInputMessage = styled.span`
  color: #dc453c;
`;

let SelectButton = styled.button`
  &.btn.btn-default {
    background-color: #fff;
  }
`;

const SelectWrapper = styled.div`
  & > .btn-group {
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    ${(props) =>
      props.pullRight &&
      `
        posision:relative
      `}
    & button {
      width: ${(props) => (props.fullWidth ? "100%" : "auto")};
      white-space: normal;
      // ${
        isMobile
          ? `
      //   height:36px;
      //   line-height:24px;
      // `
          : ""
      }
      // ${media.sm`
      //   height:36px;
      //   line-height:24px;
      // `}
    }
    & > ul.dropdown-menu {
      width: ${(props) => (props.fullWidth ? "100%" : "auto")};
      text-align: ${(props) => (props.centerOptions ? "center" : "left")};
      max-height: 300px;
      overflow-y: auto;
      ${(props) =>
        props.pullRight &&
        `
        posision:absolute;
        right:0;
        left:auto;
        z-index:9000;
      `}
    }
  }
`;

const SelectListWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 1000;
  min-width: 100%;
`;

const UlWrapper = styled.div`
  overflow: hidden;
  max-height: 0;
  z-index: 1000;

  &.slide-enter {
    display: block;
    max-height: 0;

    &-done {
      display: block;
      overflow: hidden;
      max-height: ${(props) =>
        props.length ? `${props.length * 26 + 14}` : "0"}px;
      ${media.sm`
        max-height: ${(props) =>
          props.length ? `${props.length * 36 + 14}` : "0"}px;
      `}
      transition: max-height 0.1s linear;
    }
  }

  &.slide-exit {
    overflow: hidden;
    display: block;
    max-height: ${(props) =>
      props.length ? `${props.length * 26 + 14}` : "0"}px;
    ${media.sm`
      max-height: ${(props) =>
        props.length ? `${props.length * 36 + 14}` : "0"}px;
    `}
    &-done {
      display: block;
      overflow: hidden;
      transition: max-height 0.05s linear;
    }
  }
`;

const Ul = styled.ul`
  &.dropdown-menu {
    display: block;
    position: relative;
    top: 0;
    min-width: 100%;
  }
`;

SelectWrapper.displayName = "SelectWrapper";

const StyledMenuItem = styled(MenuItem)`
  padding-left: ${(props) => 10 * (props.level || 0)}px;
  white-space: normal;
  text-overflow: ellipsis;
  max-width: 100%;
  background-color: ${(props) => (props.selected ? "inherit" : "#e8e8e8")};
  .dropdown-menu > & > a {
    cursor: ${(props) => (props.selected ? "pointer" : "not-allowed")};
    background-color: ${(props) => (props.color ? "inherit" : props.color)};
    ${isMobile
      ? `
      line-height:30px;
    `
      : ""}
    ${media.sm`
      line-height:30px; 
    `}
  }
`;
StyledMenuItem.displayName = "StyledMenuItem";

const getSelectedEntry = (data, selected, propValue = "value", fullValue) => {
  if (!selected) {
    return null;
  }
  let index = -1;
  index = data.findIndex((element) => {
    if (fullValue) {
      return element[propValue] === selected[propValue];
    } else {
      return element[propValue] === selected;
    }
  });
  if (index > -1) {
    return data[index].name;
  }
  return null;
};

const distributeNestedArray = (array, level = 0) => {
  return array.reduce((array, item) => {
    const newItem = { ...item, level };
    delete newItem.children;
    array.push(newItem);
    if (item.children) {
      array = array.concat(distributeNestedArray(item.children, level + 1));
    }
    return array;
  }, []);
};

class Select extends React.PureComponent {
  constructor(props) {
    super(props);
    const data = distributeNestedArray(props.data || []);
    const selectedTitle = getSelectedEntry(
      data,
      props.selected,
      props.propValue,
      props.fullValue
    );
    const nextTitle = selectedTitle || props.title || "";
    this.state = {
      title: nextTitle,
      defaultTitle: nextTitle,
      selected: props.selected,
      data: data,
      show: false,
      closed: true,
    };
    this.SelectWrapperRef = React.createRef();
    this.testRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.openCloseButton = this.openCloseButton.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const data = distributeNestedArray(nextProps.data || []);
    const selectedTitle = getSelectedEntry(
      data,
      nextProps.selected,
      nextProps.propValue,
      nextProps.fullValue
    );
    this.setState({
      data,
      title: selectedTitle ? selectedTitle : this.state.defaultTitle,
    });
  }

  handleChange(eventKey) {
    const {
      handleChange = () => {},
      extended = [],
      fullValue,
      selected,
    } = this.props;

    const result = fullValue
      ? eventKey
      : extended.length
      ? eventKey
      : eventKey.value;
    this.setState({ title: eventKey.name, show: false }, () => {
      if (selected !== result) {
        handleChange(result);
      }
    });
  }

  openCloseButton() {
    if (!this.props.disabled) {
      this.setState({ show: !this.state.show });
    }
  }

  resetSelect() {
    this.handleChange(null);
  }

  render() {
    const {
      id,
      fullWidth,
      disabled = false,
      pullRight,
      centerOptions,
      label,
      propValue = "value",
      unelectedProp = "unelected",
      extended = [],
      name = "name",
      helperText,
      style,
    } = this.props;

    const { title, data, show, closed } = this.state;
    return (
      <div
        ref={this.testRef}
        style={{ ...style }}
        onBlur={(event) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            this.setState({ show: false });
          }
        }}
      >
        <SelectWrapper
          pullRight={pullRight}
          centerOptions={centerOptions}
          fullWidth={fullWidth}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          ref={this.SelectWrapperRef}
        >
          {label && <StyledLabel>{label}</StyledLabel>}
          <div className="dropdown open btn-group">
            <SelectButton
              style={{
                cursor: `${this.props.disabled ? "no-drop" : "pointer"}`,
              }}
              className="dropdown-toggle btn btn-default"
              type="button"
              onClick={this.openCloseButton}
            >
              {title}
              <span className="caret"></span>
            </SelectButton>
            <SelectListWrapper>
              <CSSTransition in={show} timeout={0} classNames="slide">
                <UlWrapper length={data.length}>
                  <Ul
                    className="dropdown-menu"
                    length={data.length}
                    closed={closed}
                  >
                    {data.map((item, index) => {
                      let mutator = {};
                      if (extended && extended.length) {
                        mutator = extended.reduce((obj, key) => {
                          obj[key] = item[key];
                          return obj;
                        }, {});
                      }
                      return (
                        <StyledMenuItem
                          className={item.className || item.classname}
                          disabled={item.disabled}
                          selected={!item[unelectedProp]}
                          level={item.level}
                          key={index}
                          eventKey={{
                            ...item,
                            value: item[propValue],
                            name: item.name,
                            ...mutator,
                          }}
                          onSelect={
                            !item[unelectedProp] ? this.handleChange : () => {}
                          }
                        >
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Ul>
                </UlWrapper>
              </CSSTransition>
            </SelectListWrapper>
          </div>
        </SelectWrapper>
        <StyledInputMessage>{helperText}</StyledInputMessage>
      </div>
    );
  }
}

export default SelectHooks;

Select.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  pullRight: PropTypes.bool,
  centerOptions: PropTypes.bool,
  propValue: PropTypes.string,
  unelectedProp: PropTypes.string,
  extended: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.node,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.node,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              value: PropTypes.node,
              children: PropTypes.arrayOf(
                PropTypes.shape({
                  name: PropTypes.string.isRequired,
                  value: PropTypes.node,
                  children: PropTypes.arrayOf(
                    PropTypes.shape({
                      name: PropTypes.string.isRequired,
                      value: PropTypes.node,
                    })
                  ),
                })
              ),
            })
          ),
        })
      ),
    })
  ),
  selected: PropTypes.node,
  handleChange: PropTypes.func,
  id: PropTypes.string,
};
