import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import {LayoutContent} from "../../components/Layout";
import Box from "../../components/Box";
import {Form,Field} from "react-final-form";
import {StyledInputBox} from "../../style/styleComponents/Form/input";
import MultiSelect from "../../components/MultiSelect";
import DivisionForm from "../../components/Localization.Division.Form";
import Button from "react-bootstrap/lib/Button";
import {getDivisionsLocalization,getDivision,clearLocationReducer,editDivision} from "../../actions/localizationActions";
import LoadingScreen from "../../components/LoadingScreen";

const LocalRow = styled.div`
  margin-bottom: 30px;
`;

const NameRow = LocalRow.extend`
  max-width: 530px;
  width: 100%;
`;

const ButtonRow = LocalRow.extend`
  display: flex;
  justify-content: flex-end;
`;

class EditDivision extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state,
      localFetched:false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    const {dispatch,match} = this.props;
    const {division_id} = match.params;
    this.updateMessage();
    Promise.all([
      getDivision(division_id)(dispatch)
        .then(this.handleServerResponse({},()=>{})),
      getDivisionsLocalization(division_id)(dispatch)
        .then(this.handleServerResponse({},()=>{}))
      ])
      .then(()=>{
        this.setState({localFetched:true});
      });
  }

  handleSubmit(data){
    const {dispatch,match,history} = this.props;
    const {division_id} = match.params;

    editDivision(division_id,data)(dispatch)
      .then(this.handleServerResponse({},()=>{
        history.push("/business/localization");
      }));
  }

  render() {
    const {message,localFetched} = this.state;
    const {localization,localization:{singleData = {}}} = this.props;
    const options = localization.data.map(item=>({
      id:item.id,value:item.name
    }));
    const selectedOptions = localization.data.filter(item=>item.selected).map(item=>item.id);
    const data={
      name: singleData.name || "",
      localizations: selectedOptions
    };
    return (
      <LoadingScreen fetched={localFetched}>
        <DivisionForm
          title={"Edytuj dywizję"}
          data={data}
          options={options}
          selectedOptions={selectedOptions}
          handleSubmit={this.handleSubmit}
          message={message}
        />
      </LoadingScreen>);
  }

  componentWillUnmount(){
    clearLocationReducer()(this.props.dispatch);
  }
}

export default connect(store=>({
  message:store.message,
  localization:store.localization
}))(EditDivision);