import React, { Fragment } from "react";
import { Field } from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import DayPicker from "../../lib/DayPicker";

import Select from "../Select";
import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
  StyledTextareaBox,
} from "../../style/styleComponents/Form/input";
import validator from "../Validation/";

import { BoxesWrapper } from "./_stylesComponents";
import ListWithModalExec from "./ListWithModalExec";
import OSHTrainingFormModal from "./OSHTrainingFormModal";
import ModalSimpleAcceptModal from "../Modal/SimpleAccept";

export default class UserAccountsDetailData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalDelete: {
        open: false,
        submitFunc: () => {},
      },
    };
    this.closeModal = this.closeModal.bind(this);
    this.saveExtraTrainings = this.saveExtraTrainings.bind(this);
  }

  closeModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: false } });
    };
  }

  saveExtraTrainings() {
    let { values, saveExtraTrainings = () => {} } = this.props;
    let { extra_osh_training } = values;
    saveExtraTrainings({ extra_osh_training });
  }

  render() {
    let {
      values,
      createView,
      readOnly = false,
      submitOSHTrainingComponent,
      osh_training_view = {},
      deleteOSHTraining,
    } = this.props;
    return (
      <Fragment>
        <h3>Szkolenie BHP</h3>
        {!createView && (
          <BoxesWrapper>
            <div>
              <StyledInputBox
                disabled
                label="Szkolenie wstępne BHP - instruktaż ogólny: data szkolenia"
                value={osh_training_view.date_initial || ""}
              />
              <StyledInputBox
                disabled
                label="Szkolenia wstępne BHP - instruktaż stanowiskowy: data ostatniego szkolenia"
                value={osh_training_view.date_workplace || ""}
              />
              <StyledInputBox
                disabled
                label="Szkolenie okresowe BHP: data ostatniego szkolenia"
                value={osh_training_view.date_periodic || ""}
              />
              <StyledInputBox
                disabled
                label="Termin następnego szkolenia BHP"
                value={osh_training_view.date_next || ""}
              />
            </div>
            <div>
              <Field
                name="extra_osh_training"
                render={({ input, meta, ...rest }) => (
                  <StyledTextareaBox
                    {...input}
                    label="Dodatkowe szkolenia / kursy"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    value={input.value}
                  />
                )}
              />
              <Button onClick={this.saveExtraTrainings} type="button">
                Zapisz
              </Button>
            </div>
          </BoxesWrapper>
        )}
        <ListWithModalExec
          tableAddButtonName={"Dodaj nowe szkolenia BHP"}
          tableFieldName={"osh_training"}
          tableLabel={"Historia szkoleń"}
          handleSubmit={submitOSHTrainingComponent}
          values={values.osh_training}
          FormModal={OSHTrainingFormModal}
          tableHeader={[
            { name: "L.p.", prop: "" },
            {
              name: "Rodzaj szkolenia",
              prop: "",
            },
            {
              name: "Data ukończenia",
              prop: "date_execution",
            },
            {
              name: "Termin następnego szkolenia",
              prop: "date_next",
            },
            { name: "Usuń", prop: "" },
          ]}
          tableContentCallback={(fields) => {
            return (item, index) => (
              <tr key={index}>
                <td>{index}</td>
                <td>{item.osh_training_type_name}</td>
                <td>{item.date_completed}</td>
                <td>{item.date_next}</td>
                <td>
                  <Button
                    onClick={() => {
                      this.setState({
                        modalDelete: {
                          open: true,
                          submitFunc: deleteOSHTraining
                            ? () => {
                                deleteOSHTraining(item.id);
                              }
                            : () => {
                                fields.remove(item.logicIndex);
                              },
                        },
                      });
                    }}
                  >
                    Usuń
                  </Button>
                </td>
              </tr>
            );
          }}
        />
        <ModalSimpleAcceptModal
          open={this.state.modalDelete.open}
          handleClose={this.closeModal("modalDelete")}
          handleAccept={this.state.modalDelete.submitFunc}
        />
      </Fragment>
    );
  }
}
