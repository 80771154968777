function getParams(data = {}) {
  let params = new URLSearchParams();

  let keys = Object.keys(data);
  for (let k of keys) {
    params.set(k, data[k]);
  }

  return params ? `?${params}` : "";
}

export function getMEList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_LIST",
      payload: {
        request: {
          url: `/me/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
export function getMEListFilter(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_LIST",
      payload: {
        request: {
          url: `/me/list`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getBusinessMESingle(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_SINGLE",
      payload: {
        request: {
          url: `/business/me/user/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getMEDraftList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_DRAFT_LIST",
      payload: {
        request: {
          url: `/me/draft/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getMEDraftListFilter(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_DRAFT_LIST",
      payload: {
        request: {
          url: `/me/draft/list`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getMETypeList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_TYPES_LIST",
      payload: {
        request: {
          url: `/me-type/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveReferral(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_REFERRAL",
      payload: {
        request: {
          url: id ? `/me/referral/${id}` : `/me/referral/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getMEArchives(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_ARCHIVES_LIST",
      payload: {
        request: {
          url: `/me/referral/list`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getMEArchivesFilter(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_ARCHIVES_LIST",
      payload: {
        request: {
          url: `/me/referral/list`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getMEReferral(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_REFERRAL",
      payload: {
        request: {
          url: `/me/referral/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteME(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_ME_ARCHIVES",
      payload: {
        request: {
          url: `/me/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteMEReferral(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_ME_ARCHIVES",
      payload: {
        request: {
          url: `/me/referral/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getMEDraft(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_DRAFT_ITEM",
      payload: {
        request: {
          url: `/me/draft/${id}${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveMEDraft(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_ME_DRAFT",
      payload: {
        request: {
          url: id ? `/me/draft/edit/${id}` : `/me/draft/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteMEDraft(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_ME_DRAFT",
      payload: {
        request: {
          url: `/me/draft/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteMEReferralByDates(data) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_REFERRAL_BY_DATES",
      payload: {
        request: {
          url: `/me/referral/delete`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveME(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_ME",
      payload: {
        request: {
          url: id ? `/me/edit/${id}` : `/me/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function printMEReport(data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/me/print/report`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}

export function printReferral(id) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/me/print/referral/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function getMEAccountData() {
  return (dispatch) =>
    dispatch({
      type: "GET_ME_SINGLE",
      payload: {
        request: {
          url: "/me/user-account",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getMEArchiveWorkplaces() {
  return (dispatch) =>
    dispatch({
      type: "GET_ARCHIVE_WORKPLACES",
      payload: {
        request: {
          url: "/me/archive-workplaces",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function clearMEReducer() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_ME_REDUCER",
      payload: {},
    });
}
