export const defaultUrls = {
  ADD: `/risk-assessment/basic`,
  DRAFTLIST: `/risk-assessment/template-doc`,
  DRAFTADD: `/risk-assessment/template-doc/basic`,
  DRAFTBASIC: (id) => `/risk-assessment/template-doc/basic/${id}`,
  DRAFTDANGER: (id) => `/risk-assessment/template-doc/danger/${id}`,
  DRAFTCHARACTERISTIC: (id) =>
    `/risk-assessment/template-doc/characteristic/${id}`,
  DRAFTANALYSIS: (id) => `/risk-assessment/template-doc/analysis/${id}`,
  DRAFTTASK: (id) => `/risk-assessment/template-doc/task-decrease-risk/${id}`,
  BASIC: (id) => `/risk-assessment/basic/${id}`,
  CHARACTERISTIC: (id) => `/risk-assessment/characteristic/${id}`,
  DANGER: (id) => `/risk-assessment/danger/${id}`,
  ANALYSIS: (id) => `/risk-assessment/analysis/${id}`,
  TASK: (id) => `/risk-assessment/task-decrease-risk/${id}`,
  TASKENTRY: (id, ra_ah_tme_id, task_id) =>
    task_id
      ? `/risk-assessment/task-decrease-risk/${id}/task/${ra_ah_tme_id}/${task_id}`
      : `/risk-assessment/task-decrease-risk/${id}/task/${ra_ah_tme_id}`,
  NOTIFICATION: (id) => `/risk-assessment/notification/${id}`,
  UPDATE: (id) => `/risk-assessment/update/${id}`,
  LIST: `/risk-assessment`,
  METHODLIST: `/risk-assessment/ra-method`,
  METHODADD: `/risk-assessment/ra-method/add`,
  METHODEDIT: (id) => `/risk-assessment/ra-method/edit/${id}`,
  ARCHIVEDLIST: `/risk-assessment/archive-orz/list`,
  ARCHIVEBASIC: (id) => `/risk-assessment/archive-orz/basic/${id}`,
  ARCHIVECHARACTERISTIC: (id) =>
    `/risk-assessment/archive-orz/characteristic/${id}`,
  ARCHIVEDANGER: (id) => `/risk-assessment/archive-orz/danger/${id}`,
  ARCHIVEANALYSIS: (id) => `/risk-assessment/archive-orz/analysis/${id}`,
  ARCHIVETASK: (id) => `/risk-assessment/archive-orz/task-decrease-risk/${id}`,
  ARCHIVENOTIFICATION: (id) =>
    `/risk-assessment/archive-orz/notification/${id}`,
  HAZARDLIST: `/risk-assessment/model-hazards/list`,
  HAZARDADD: `/risk-assessment/model-hazards/add`,
  HAZARDEDIT: (id) => `/risk-assessment/model-hazards/edit/${id}`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
