export function getModules() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_MODULES_REQUEST",
        "GET_MODULES_SUCCESS",
        "GET_MODULES_ERROR",
      ],
      payload: {
        request: {
          url: "/super-admin/modules/get",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessModulesByUser(users_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_MODULES_REQUEST",
        "GET_MODULES_SUCCESS",
        "GET_MODULES_ERROR",
      ],
      payload: {
        request: {
          url: `/business/modules/get${users_id ? `/${users_id}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessUserModules() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_MODULES_REQUEST",
        "GET_MODULES_SUCCESS",
        "GET_MODULES_ERROR",
      ],
      payload: {
        request: {
          url: "/modules/get-user-module",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
