import React from "react";
import SideMenu from "components/SideMenu";

import UpSideMenu from "../_SideMenu";
import { defaultUrls as urls } from "./_staticData";
const LSideMenu = ({
  id,
  audit_id,
  stage,
  permission = {},
  risk_assessment_id,
  hide = false,
}) => (
  <div>
    <UpSideMenu />
    {!hide && (
      <SideMenu
        title="Szablony dokumentów"
        data={[
          {
            name: "Informacje ogólne",
            url: id ? urls.BASIC(id) : urls.ADD,
            notExact: true,
          },
          {
            disabled: !id,
            name: "Identyfikacji zagrożeń",
            url: `/super-admin/draft/risk-assessment/danger/${id}`,
            notExact: true,
          },
          {
            disabled: !id,
            name: "Charakterystyka",
            url: `/super-admin/draft/risk-assessment/characteristic/${id}`,
            notExact: true,
          },
          {
            disabled: !id,
            name: "Analiza ryzyka",
            url: `/super-admin/draft/risk-assessment/analysis/${id}`,
            notExact: true,
          },
        ]}
      />
    )}
  </div>
);

export default LSideMenu;
