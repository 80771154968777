import React from "react";
import {connect} from "react-redux";
import AppComponent from "../../components/AppComponent";
import LoginView from "../../components/LoginView";
import {login} from "../../actions/superAdminActions";

export class SuperAdminLogin extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServerResponse.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
  }

  componentDidMount(){
    this.updateMessage();
  }

  handleSubmit(data){
    const {dispatch, history} = this.props;
    login(data)(dispatch)
      .then(this.handleServerResponse({}, function () {
        history.push("/super-admin/dashboard");
      }));
  }

  render() {
    const {message} = this.state;
    return (<LoginView
      handleSubmit={this.handleSubmit}
      resetPasswordLink="/super-admin/reset-password"
      alert={message}
    />);
  }
}

export default connect(store=>({
  superadmin: store.superadmin,
  message: store.message
}))(SuperAdminLogin);