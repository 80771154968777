import React from "react";
import { connect } from "react-redux";

import {
  getRA,
  getRAAnalysisHazard,
  getRAAHTMEntry,
  getRAPermission,
  addRAAHTMEntry,
  clearRiskAssessment,
  setStageRA,
  printRATaskReport,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import TaskDecreaseRisk from "components/RiskAssessment.Forms/TaskDecreaseRisk/";

import SideMenu from "../_SideMenu";
import { setUrls } from "../../_staticData";
import moment from "moment";
import saveBlobFile from "components/saveBlobFile";

export class TaskDecreaseRiskContainer extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hazards: [],
      urls: setUrls(),
    };

    this.addRAAHTMEntry = this.addRAAHTMEntry.bind(this);
    this.setNextStage = this.setNextStage.bind(this);
    this.printRegister = this.printRegister.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    getRA(id)(dispatch).then(this.handleServerResponse());
    getRAAnalysisHazard(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let { data } = action.payload.data;
        this.setState({ hazards: data.filter((i) => !i.category) });
      })
    );
    getRAPermission()(dispatch).then(this.handleServerResponse());
    getRAAHTMEntry(id)(dispatch).then(this.handleServerResponse());
  }

  addRAAHTMEntry(ra_analysis_hazard_id, cb) {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addRAAHTMEntry(
      id,
      ra_analysis_hazard_id
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        cb();
      })
    );
  }

  printRegister() {
    let { dispatch, match } = this.props;
    let data = this.state.selectedData;
    let { id } = match.params;

    printRATaskReport(
      id,
      data
    )(dispatch).then((res) => {
      if (!res.error) {
        let file;
        let fileName;

        let fileNamePrefix = `Rejestr działań naprawczych oceny ryzyka zawodowego - ${moment().format(
          "YYYY-MM-DD"
        )}`;

        if (true) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.pdf`;
        }

        saveBlobFile(file, fileName);
      }
    });
  }

  setNextStage() {
    let { urls } = this.state;
    let { dispatch, match, history } = this.props;
    let { id } = match.params;

    setStageRA(
      id,
      5
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.NOTIFICATION(id));
      })
    );
  }

  renderSideMenu() {
    const { match, permission, ra: data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }

  render() {
    let { hazards, urls = {} } = this.state;
    let { raTM, match, permission, ra } = this.props;
    let { id } = match.params;

    return (
      <>
        <TaskDecreaseRisk
          ra={ra}
          id={id}
          urls={urls}
          hazards={hazards}
          data={raTM}
          SideMenu={this.renderSideMenu()}
          addRAAHTMEntry={this.addRAAHTMEntry}
          permission={permission}
          printRegister={this.printRegister}
          setNextStage={this.setNextStage}
          stage={ra.stage}
          readOnly={!permission[28]}
        />
      </>
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  ra: store.riskAssessment.singleData,
  permission: store.riskAssessment.permission,
  raTM: store.riskAssessment.raTM,
  hazards: store.riskAssessment.raHazards,
  message: store.message,
}))(TaskDecreaseRiskContainer);
