import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { Link } from "react-router-dom";
import { LayoutContent } from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import { ButtonWrapper } from "../../components/ListComponents";
import { LinkContainer } from "../../components/LinkContainer";
import Pagination from "../../components/Pagination";
import { media } from "../../style/style-utils";

import { DeleteButton } from "../../components/ListComponents";
import TableSortable from "../../components/TableSortable";

import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Alert from "react-bootstrap/lib/Alert";
import {
  getBusinesses,
  deleteBusiness,
  loginToBusiness,
} from "../../actions/businessActions";

import { ButtonWithIcon } from "components/Buttons";
import Modal from "../../components/Modal/SimpleAccept";
import { StyledTableList } from "../../components/ListComponents";

const StyledTableWrapper = styled.div`
  overflow-x: auto;
`;

export class BusinessAccountList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      modal: {
        open: false,
        entry_id: null,
      },
    };

    this.handleServerResponse = this.handleServerResponse.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.updateMessage();
    getBusinesses()(this.props.dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  handleLoginToBusiness(business_id) {
    const { dispatch, history } = this.props;
    return () => {
      loginToBusiness(business_id)(dispatch).then(
        this.handleServerResponse({}, function () {
          history.push("/business/dashboard");
        })
      );
    };
  }

  openModal(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.modal, open: true, entry_id } });
    };
  }

  closeModal() {
    this.setState({
      modal: { ...this.state.modal, open: false, entry_id: null },
    });
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  handleDelete() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modal;
    deleteBusiness(entry_id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    const { business } = this.props;
    const { data: businessesView, message, modal } = this.state;
    return (
      <LayoutContent
        SideMenu={
          <SideMenu
            title={"Konta firm"}
            data={[
              { name: "Lista kont", url: "/super-admin/businesses" },
              { name: "Dodaj konto", url: "/super-admin/businesses/add" },
            ]}
          />
        }
      >
        {message && message.text && (
          <Alert bsStyle={message.className}>{message.text}</Alert>
        )}

        <PageHeader>Lista kont</PageHeader>
        <ButtonWrapper>
          <LinkContainer to="/super-admin/businesses/add">
            <Button>Dodaj konto</Button>
          </LinkContainer>
        </ButtonWrapper>
        {businessesView && businessesView.length ? (
          <StyledTableWrapper>
            <StyledTableList quickMenu quickMenuTrird nowrapTitle>
              <TableSortable
                headers={[
                  { name: "L.p." },
                  { name: "Nazwa firmy", prop: "name", sort_type: "string" },
                  { name: "Data utworzenia", prop: "date_add" },
                  { name: "Konto firmy aktywne", prop: "active" },
                  { name: "Konto administratora" },
                  { name: "Usuń" },
                ]}
                data={businessesView}
                render={(sortedData) => {
                  return sortedData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={`/super-admin/businesses/edit/${item.id}`}>
                          {item.demo ? "Demo-" : ""}
                          {item.name}
                        </Link>
                      </td>
                      <td>{item.date_add}</td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={item.active}
                          disabled
                        />
                      </td>
                      <td>
                        <ButtonWithIcon
                          onClick={this.handleLoginToBusiness(item.id)}
                          icon="fa fa-cog"
                        />
                      </td>
                      <td>
                        <DeleteButton onClick={this.openModal(item.id)} />
                      </td>
                    </tr>
                  ));
                }}
              />
            </StyledTableList>
          </StyledTableWrapper>
        ) : (
          ""
        )}
        <Pagination
          handlePageChange={this.handlePageChange.bind(this)}
          data={business.data}
        />
        <Modal
          title={"Usuń konto firmy"}
          description={
            "Usunięcie konta firmy spowoduje utracenie wszystkich danych danej firmy. Czy chcesz kontynuować?"
          }
          open={modal.open}
          handleAccept={this.handleDelete}
          handleClose={this.closeModal}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  business: store.business,
  message: store.message,
}))(BusinessAccountList);
