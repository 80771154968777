import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  deleteCertificateOSHDraft,
  getCertificateOSHList,
} from "actions/certificateOSHActions";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";

import { ButtonWrapper } from "components/Wrappers";
import { LayoutContent } from "components/Layout";
import PageHeader from "components/PageHeader";
import Pagination from "components/Pagination";
import { LinkContainer } from "components/LinkContainer";

import { TableListPage } from "components/Tables";

import { setUrls } from "./_staticData";
import SideMenu from "./_SideMenu";
import ModalDelete from "components/Modal/SimpleAccept";
class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      modalDelete: this.initModalState,
      urls: setUrls(),
    };

    this.deleteEntry = this.deleteEntry.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    getCertificateOSHList()(dispatch);
  }

  deleteEntry() {
    let { dispatch } = this.props;
    let { data } = this.state.modalDelete;

    deleteCertificateOSHDraft(data.id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  render() {
    let { data, urls, message } = this.state;
    let { certificates } = this.props;
    return (
      <LayoutContent message={message} SideMenu={<SideMenu />}>
        <PageHeader>Wzory zaświadczeń</PageHeader>
        <ButtonWrapper>
          <LinkContainer to={urls.CERTIFICATEADD}>
            <ButtonWithIcon icon="fa fa-plus" name="Dodaj wzór" />
          </LinkContainer>
        </ButtonWrapper>
        <TableListPage>
          <thead>
            <th>L.p</th>
            <th>Nazwa zaświadczenia</th>
            <th>Usuń</th>
          </thead>
          {data.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <Link to={urls.CERTIFICATEEDIT(item.id)}>
                    {item.draft_name}
                  </Link>
                </td>
                <td>
                  <ButtonWithIcon
                    onClick={this.openModal("modalDelete", { data: item })}
                    listButton
                    icon="fa fa-trash"
                    name="Usuń"
                  />
                </td>
              </tr>
            );
          })}
        </TableListPage>
        <Pagination
          handlePageChange={this.handlePageChange}
          data={certificates}
        />
        <ModalDelete
          open={this.state.modalDelete.open}
          title="Usuń wzór zaświadczenia"
          description={"Czy chcesz usunąć dany wpis?"}
          handleAccept={this.deleteEntry}
          handleClose={this.closeModal("modalDelete")}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  certificates: store.certificates.data,
}))(List);
