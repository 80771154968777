import { css } from "styled-components";

export const media = {
  lg: (...args) => css`
    @media (min-width : 1200px) {
      ${ css(...args) }
    }`,
  md: (...args) => css`
    @media (min-width : 992px)  and (max-width : 1199px){
      ${ css(...args) }
    }`,
  sm: (...args) => css`
    @media (max-width : 991px) {
      ${ css(...args) }
    }`,
  xs: (...args) => css`
    @media (min-width : 1px) and (max-width : 767px) {
      ${ css(...args) }
    }`,
  custom:({minWidth = 1,maxWidth=1366})=>(...args) => css`
    @media ${minWidth  ? `(min-width : ${minWidth}px)` : ''}${minWidth && maxWidth ? ` and ` : ''}${maxWidth ? `(max-width : ${maxWidth}px)` : ''} {
      ${ css(...args) }
    }`,
};