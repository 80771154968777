import React from "react";
import { connect } from "react-redux";

import {
  getDraftRA,
  saveDraftRACharacteristic,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import WorkplaceCharakteristicAdd from "components/RiskAssessment.Forms/WorkplaceCharacteristic/";
import SideMenu from "./_SideMenu";
import { setUrls } from "./_staticData";

import { RACharacteristic } from "container/RiskAssessment/EntryAddEdit/WorkplaceCharacteristic/index";
import { RAAnalysis } from "container/RiskAssessment/EntryAddEdit/Analysis/index";

let urls = {
  ANALYSIS: (id) => `/super-admin/draft/risk-assessment/analysis/${id}`,
};
class Component extends RACharacteristic {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls(), draft: true };

    this.renderSideMenu = this.renderSideMenu.bind(this);
  }

  renderSideMenu() {
    const { match, permission, data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
  workers: store.riskAssessment.raWorkers,
  permission: store.riskAssessment.permission,
  section: store.section,
  localization: store.localization,
  workplaces: store.workplace.data,
}))(Component);
