import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";
import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";

import { getUserWorkplacesWithout } from "actions/workplaceActions";
import { getIncidentNotification } from "actions/incidentActions";
import {
  addUserToGroup,
  localAddNotification,
  localRemoveNotification,
  localRemoveUser,
  localAddUser,
  notificationCleaner,
} from "actions/notificationActions";
import { registerNotifications } from "actions/incidentActions";

import Box from "components/Box";
import Modal from "components/Modal/SimpleAccept";
import { ButtonWrapper, ButtonWithIcon } from "components/ListComponents";
import { LinkContainer } from "components/LinkContainer";
import { StyledTable } from "container/Task/List";

import { UserNotificationList } from "./NotificationList";
import NotificationHistoryList from "./NotificationHistory";

import { termNeutral, termsBefore, termsAfter } from "./NotificationTerms";
const termArray = [].concat(termsBefore, termNeutral, termsAfter);

class IncidentNotification extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      dictionary_progress_open: 1,
      dictionary_progress_close: 2,
      notification_group_id: 1,
      mobileNotHideMenu: false,
      ...this.state,
      modalNotificationDelete: {
        open: false,
        title: null,
        description: null,
        entry_id: null,
      },
      modalUserDelete: {
        open: false,
        title: null,
        description: null,
        entry_id: null,
      },
      permission_to_edit: 3,
    };

    this.handleAddNotification = this.handleAddNotification.bind(this);
    this.handleAddUserToGroup = this.handleAddUserToGroup.bind(this);
    this.handleDeleteNotification = this.handleDeleteNotification.bind(this);
    this.handleDeleteUserFromGroup = this.handleDeleteUserFromGroup.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.saveNotifications = this.saveNotifications.bind(this);

    this.handleDeleteRegisteredNotification = this.handleDeleteRegisteredNotification.bind(
      this
    );
    this.handleRegisterNotification = this.handleRegisterNotification.bind(
      this
    );
    this.handleDeleteUserFromRegisterGroup = this.handleDeleteUserFromRegisterGroup.bind(
      this
    );
    this.handleAddUserToRegisterGroup = this.handleAddUserToRegisterGroup.bind(
      this
    );
    this.renderSideMenu = this.renderSideMenu.bind(this);
    this.closeInstance = this.closeInstance.bind(this);
    this.getHistory = this.getHistory.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());

    getIncidentNotification(incident_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  handleAddNotification(data) {
    const { dispatch } = this.props;
    const {
      notification: { data: notificationData },
    } = this.props;
    const { notifications } = notificationData[0];
    const { name: notificationName, ...rest } = notifications[
      notifications.findIndex((item) => item.id == data.notification_id)
    ];
    const singleTerm = termArray.find((item) => item.value == data.term);
    data.term = data.term == "null" ? null : data.term;
    const payload = {
      data: {
        notification: {
          business_has_responsibility_id: null,
          name: notificationName,
          notification_group_has_users_id: data.user_id, //???
          notification_group_id: this.state.notification_group_id, //stała
          notification_id: data.notification_id,
          term: data.term,
          term_name: singleTerm.name,
          users_id: data.user_id,
        },
      },
    };
    localAddNotification(payload)(dispatch);
  }

  handleAddUserToGroup(notification_group_id, user_id, user) {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    const newUser = {
      data: {
        user: {
          notification_group_id: this.state.notification_group_id,
          notifications: [],
          user_id: user_id,
          user_name: user.name,
          workplace_name: user.workplace_name,
        },
      },
    };

    localAddUser(newUser)(dispatch);
  }

  handleDeleteNotification() {
    const { dispatch } = this.props;
    const {
      notification_group_has_users_id,
      entry_id,
      userIndex,
      subIndex,
    } = this.state.modalNotificationDelete;
    localRemoveNotification({
      notification_group_has_users_id,
      notification_id: entry_id,
      userIndex,
      subIndex,
    })(dispatch);
  }

  saveNotifications() {}

  handleAddUserToRegisterGroup(notification_group_id, user_id, user) {
    return this.handleAddUserToGroup(notification_group_id, user_id, user);
  }

  handleDeleteUserFromRegisterGroup() {}

  handleRegisterNotification() {}

  handleDeleteRegisteredNotification() {}

  closeInstance() {}

  getHistory() {}

  handleDeleteUserFromGroup() {
    const { dispatch } = this.props;
    const {
      entry_id: notification_group_has_users_id,
    } = this.state.modalUserDelete;
    localRemoveUser({ notification_group_has_users_id })(dispatch);
  }

  prepareData() {
    const {
      notification: { data: notificationData },
    } = this.props;
    let notification_group_id = this.state.notification_group_id;
    let users = [];
    if (notificationData && notificationData.length) {
      let { notification_group_id: ln, users: lu } = notificationData[0];
      notification_group_id = ln;
      users = lu;
    }

    return [].concat.apply(
      [],
      users.map((item) => {
        const { user_id, notifications } = item;

        const newNotification = notifications.map(
          ({ notification_id, term, notification_instance_id }) => {
            return {
              registered_notification_group_has_users_id: notification_instance_id,
              notification_id,
              term,
            };
          }
        );
        return {
          notification_group_id,
          users_id: user_id,
          notifications: newNotification,
        };
      })
    );
  }

  handleModalOpen(name) {
    return ({
      id,
      title,
      notification_group_has_users_id,
      userIndex,
      subIndex,
    }) => {
      this.setState({
        [name]: {
          ...this.state[name],
          open: true,
          entry_id: id,
          userIndex: userIndex,
          title: `Usuń - ${title}`,
          notification_group_has_users_id: notification_group_has_users_id,
          subIndex: subIndex,
        },
      });
    };
  }

  handleModalClose(name) {
    return () => {
      this.setState({
        [name]: { ...this.state[name], open: false, title: null },
      });
    };
  }

  renderSideMenu() {
    return null;
  }

  render() {
    const {
      modalNotificationDelete,
      modalUserDelete,
      message,
      dictionary_progress_id,
      dictionary_progress_close,
      moduleListUrl,
    } = this.state;
    const {
      workplace: { data: workplaceData },
      notification: {
        data: notificationData,
        history,
        permission = {},
        sendFetching,
      },
      readOnly,
    } = this.props;

    let permission_to_edit = this.state.permission_to_edit;

    return (
      <LayoutContent
        mobileNotHideMenu={this.state.mobileNotHideMenu}
        message={message}
        error404={this.state.error_code == 404}
        SideMenu={this.renderSideMenu()}
      >
        <Box title="Powiadomienia">
          {notificationData.map((item) => (
            <UserNotificationList
              permission={permission[permission_to_edit]}
              workplaces={workplaceData}
              notification_group_id={this.state.notification_group_id}
              handleAddNotification={this.handleRegisterNotification}
              handleAddUserToGroup={this.handleAddUserToRegisterGroup}
              handleDelete={this.handleModalOpen("modalNotificationDelete")}
              handleDeleteUser={this.handleModalOpen("modalUserDelete")}
              notifications={item.notifications}
              name={"Ustawienia powiadomień"}
              data={item.users}
              readOnly
            />
          ))}
          <ButtonWrapper>
            {dictionary_progress_id == this.state.dictionary_progress_open ? (
              <Button
                disabled={sendFetching || !permission[permission_to_edit]}
                onClick={this.saveNotifications}
                bsStyle="primary"
                icon={sendFetching ? "fa fa-spinner fa-spin" : ""}
                listButton
                name="Prześlij"
              >
                Prześlij
                {sendFetching ? (
                  <>
                    &nbsp;
                    <i className={"fa fa-spinner fa-spin"}></i>
                  </>
                ) : (
                  ""
                )}
              </Button>
            ) : (
              ""
            )}
            <LinkContainer exact to={moduleListUrl}>
              <Button>Powrót do listy</Button>
            </LinkContainer>
          </ButtonWrapper>

          {dictionary_progress_id == this.state.dictionary_progress_close &&
          !readOnly ? (
            <ButtonWrapper>
              <Button disabled={!permission[8]} onClick={this.closeInstance}>
                Zakończ i zamknij
              </Button>
            </ButtonWrapper>
          ) : (
            ""
          )}
          <NotificationHistoryList data={history} />
        </Box>
        <Modal
          title={modalNotificationDelete.title}
          description="Czy chcesz usunąć powiadomienie?"
          open={modalNotificationDelete.open}
          handleClose={this.handleModalClose("modalNotificationDelete")}
          handleAccept={this.handleDeleteRegisteredNotification}
        />
        <Modal
          title={modalUserDelete.title}
          description="Czy chcesz usunąć użytkownika z grupy powiadomień?"
          open={modalUserDelete.open}
          handleClose={this.handleModalClose("modalUserDelete")}
          handleAccept={this.handleDeleteUserFromRegisterGroup}
        />
      </LayoutContent>
    );
  }

  componentWillUnmount() {
    notificationCleaner()(this.props.dispatch);
  }
}

export default IncidentNotification;
