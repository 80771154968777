import React from "react";
import SideMenu from "../../components/SideMenu";

const LSideMenu = ({
  incident_id,
  stage,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <div>
    <SideMenu
      title="Zdarzenia"
      data={[
        { name: "Rejestr zdarzeń", url: "/incident" },
        {
          name: "Zgłoś nowe zdarzenie",
          url: "/incident/register",
          disabled: !permission[9],
          onClick: addNewIncident,
        },
      ]}
    />
    {incident_id ? (
      <SideMenu
        title="Dodaj wypadek"
        mobileNotHide
        data={[
          { name: "Zgłoszenie", url: `/incident/register/${incident_id}` },

          {
            name: "Załączniki",
            url: `/incident/attachment/${incident_id}`,
            disabled: stage < 1,
          },
          {
            name: "Analiza zdarzenia",
            url: `/incident/analysis/${incident_id}`,
            disabled: stage < 2,
          },
          {
            name: "Działania naprawcze",
            url: `/incident/task/${incident_id}`,
            disabled: stage < 3,
            notExact: true,
          },
          {
            name: "Powiadomienia",
            url: `/incident/notification/${incident_id}`,
            disabled: stage < 4,
          },
        ]}
      />
    ) : (
      ""
    )}
  </div>
);

export default LSideMenu;
