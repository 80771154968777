import React from "react";
import styled from "styled-components";
import Box from "../../components/Box";
import { LayoutContent } from "../../components/Layout";
import {
  StyledInputBox,
  StyledInput,
  StyledLabel,
} from "../../style/styleComponents/Form/input";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, Field } from "react-final-form";

import PermissionList, { initialPermissionValues } from "./permissionsList";
import PersonnelList from "./personnelList";
import Select from "../Select/Select.js";
import { media } from "../../style/style-utils";
import { ButtonWrapper } from "../ListComponents";
import Button from "react-bootstrap/lib/Button";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import { clearSections, getSections } from "../../actions/sectionActions";
import { LinkContainer } from "../LinkContainer";
import validator, { composeValidators } from "../Validation/index";

import ProfessionCodeInput from "./professionCodeInput";

const StyledReactTabsWrapper = styled.div`
  margin-bottom: 15px;
  & .react-tabs__tab-list {
    list-style-type: none;
    border-bottom: 1px solid ${(props) => props.theme.tableBorderColor};
    margin-bottom: 0;
    ${media.sm`
    display:flex;
    padding:0;
    `}
  }

  & .react-tabs__tab {
    display: inline-block;
    position: relative;
    padding: 3px 5px;
    border: 1px solid ${(props) => props.theme.tableBorderColor};
    border-bottom: none;
    bottom: -2px;
    border-top-right-radius: 16px;
    cursor: pointer;
    ${media.xs`
      font-size:12px;
      padding:6px 4px;
      display:flex;
    `}

    &--selected {
      background-color: white;
    }
  }
`;

const TabBox = styled.div`
  border: 1px solid ${(props) => props.theme.tableBorderColor};
  border-top: none;
`;

const TabContent = styled.div`
  height: 100%;
`;

const StyledTextAreaWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

const StyledTextArea = styled.textarea`
  border: 1px solid ${(props) => props.theme.tableBorderColor};
  width: 100%;
  height: 100%;
  padding: 5px 12px;
  resize: none;
  min-height: 250px;
`;

const TopWrapper = styled.div`
  margin-bottom: 50px;
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;

  & > .btn-group {
    margin-bottom: 10px;
    & > button {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const mutatorSetTrue = ([fields], state, { changeValue }) => {
  fields.forEach((name) => {
    changeValue(state, name, () => true);
  });
};

const mutatorSetFalse = ([fields], state, { changeValue }) => {
  fields.forEach((name) => {
    changeValue(state, name, (value) => false);
  });
};

const clearField = ([name], state, { changeValue }) => {
  changeValue(state, name, () => null);
};

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

let codes = [
  {
    id: 1,
    code: "1",
    description: "BLABLABLA",
  },
  {
    id: 2,
    code: "11",
    description: "BLABLABLA 2",
  },
  {
    id: 3,
    code: "111",
    description: "BLABLABLA 3",
  },
];

class WorkplaceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      users: {
        data: [],
        localizations: [],
        localization_id: undefined,
        name: undefined,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.checkAllPermissionInGroup = this.checkAllPermissionInGroup.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.disableFilter = this.disableFilter.bind(this);
    this.handleChangeLocalization = this.handleChangeLocalization.bind(this);
  }

  handleSubmit(data) {
    const { handleSubmit } = this.props;
    const {
      name,
      workplace_parent_id,
      localization_id,
      description,
      division_id,
      profession_code_id,
      profession_code,
      ...permisions
    } = data;
    const permissionArray = Object.keys(permisions)
      .filter((item) => !!permisions[item])
      .map((item) => item.split("_")[1]);

    let result = {
      name,
      workplace_parent_id,
      localization_id,
      description,
      division_id,
      profession_code_id,
      permissions: permissionArray,
    };

    if (result.workplace_parent_id == "0") {
      delete result.workplace_parent_id;
    }

    handleSubmit(result);
  }

  checkAllPermissionInGroup(form) {
    return (fields, allChecked) => {
      return () => {
        if (allChecked) {
          form.mutators.mutatorSetFalse(fields);
        } else {
          form.mutators.mutatorSetTrue(fields);
        }
      };
    };
  }

  filterUsers() {
    const { name, localization_id } = this.state.users;
    const newRegex = new RegExp(name);
    const result = this.props.users.filter(
      (item) =>
        (name && name.length ? newRegex.test(item.name) : true) &&
        (localization_id ? item.localization_id == localization_id : true)
    );
    return result;
  }

  disableFilter() {
    this.setState(
      {
        users: {
          ...this.state.users,
          localization_id: undefined,
          name: undefined,
        },
      },
      () => {
        this.filterUsers();
      }
    );
  }

  handleFilterChange(name) {
    return (value) => {
      this.setState({ users: { ...this.state.users, [name]: value } }, () => {
        this.filterUsers();
      });
    };
  }

  handleChangeLocalization(data, form) {
    const { dispatch } = this.props;

    const { value, division } = data;
    const mutators = form.mutators;
    mutators.setField("division_id")(value);
    if (division) {
      mutators.setField("division_id")(value);
      mutators.clearField("localization_id");
    } else {
      mutators.setField("localization_id")(value);
      mutators.clearField("division_id");
    }
    mutators.clearField("section_id");
  }

  render() {
    const {
      createView,
      boxTitle,
      localizations,
      workplaces,
      data = {},
      message,
      permissions: modulePermissions = [],
      professionCodes,
      workplaceFetched,
    } = this.props;
    const { data: usersData } = this.state.users;
    const localizationsEnch = [{ id: null, name: "Bez limitu" }].concat(
      localizations
    );
    const workplacesEnch = [{ id: "0", name: "Brak" }].concat(workplaces);

    let initialPermissions = initialPermissionValues(modulePermissions);

    let personalListLocalizations = [];

    return (
      <LayoutContent>
        {message && message.text && (
          <Col lg={12}>
            <Alert bsStyle={message.className}>{message.text}</Alert>
          </Col>
        )}
        <Box title={boxTitle}>
          <Form
            onSubmit={this.handleSubmit}
            ref={(form) => (this.form = form)}
            mutators={{ mutatorSetTrue, mutatorSetFalse, clearField, setField }}
            initialValues={{ ...data, ...initialPermissions }}
            render={({ handleSubmit, pristine, invalid, values, form }) => (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <TopWrapper style={{ marginBottom: "0" }}>
                  <Field
                    name="name"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        {...input}
                        label={"Nazwa stanowiska"}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                </TopWrapper>
                <StyledLabel>
                  Pełny kod zawodu (specjalności), tj. sześciocyfrowy symbol
                  zgodny z obowiązującą klasyfikacją zawodów i specjalności na
                  potrzeby rynku pracy.
                </StyledLabel>
                <TopWrapper>
                  <ProfessionCodeInput
                    values={values}
                    codes={professionCodes}
                  />
                  <Field
                    name="workplace_parent_id"
                    render={({ input, meta, ...rest }) => (
                      <Select
                        label={"Przełożony"}
                        title={"Brak"}
                        id="superior"
                        fullWidth
                        propValue="id"
                        selected={input.value}
                        data={workplacesEnch}
                        handleChange={(data) => {
                          input.onChange(data);
                        }}
                      />
                    )}
                  />
                  <Field
                    name="localization_id"
                    render={({ input, meta, ...rest }) => {
                      return !values.division_id ? (
                        <Select
                          label={"Obszar działania"}
                          title={"Bez limitu"}
                          id="superior"
                          fullWidth
                          data={localizationsEnch}
                          selected={input.value}
                          extended={["division"]}
                          propValue="id"
                          handleChange={(data) => {
                            this.handleChangeLocalization(data, form);
                          }}
                        />
                      ) : (
                        <input {...input} style={{ display: "none" }} />
                      );
                    }}
                  />
                  <Field
                    name="division_id"
                    render={({ input, meta, ...rest }) => {
                      return values.division_id ? (
                        <Select
                          label={"Obszar działania"}
                          title={"Bez limitu"}
                          id="superior"
                          fullWidth
                          extended={["division"]}
                          data={localizationsEnch}
                          selected={input.value}
                          propValue="id"
                          handleChange={(data) => {
                            this.handleChangeLocalization(data, form);
                          }}
                        />
                      ) : (
                        <input {...input} style={{ display: "none" }} />
                      );
                    }}
                  />
                </TopWrapper>
                <StyledReactTabsWrapper>
                  <Tabs>
                    <TabList>
                      <Tab>Uprawnienia</Tab>
                      {!createView && <Tab>Aktywni pracownicy</Tab>}
                      <Tab>Opis stanowiska</Tab>
                    </TabList>
                    <TabPanel>
                      <TabBox>
                        <PermissionList
                          checkAllPermissionInGroup={this.checkAllPermissionInGroup(
                            form
                          )}
                          permissions={modulePermissions}
                          formValues={values}
                        />
                      </TabBox>
                    </TabPanel>
                    {!createView && (
                      <TabPanel>
                        <TabBox>
                          <PersonnelList
                            employees={this.props.users}
                            localizations={localizations}
                            workplaceFetched={workplaceFetched}
                            handleFilterChange={this.handleFilterChange}
                            disableFilter={this.disableFilter}
                            nameInput={this.state.users.name}
                            localization_id={this.state.users.localization_id}
                          />
                        </TabBox>
                      </TabPanel>
                    )}
                    <TabPanel>
                      <TabBox>
                        <TabContent>
                          <StyledTextAreaWrapper>
                            <Field
                              name="description"
                              render={({ input, meta, ...rest }) => (
                                <StyledTextArea
                                  value={input.value}
                                  onChange={(event) => {
                                    input.onChange(event.target.value);
                                  }}
                                />
                              )}
                            />
                          </StyledTextAreaWrapper>
                        </TabContent>
                      </TabBox>
                    </TabPanel>
                  </Tabs>
                </StyledReactTabsWrapper>
                <ButtonWrapper>
                  <Button type="submit" bsStyle="primary">
                    {createView ? "Dodaj" : "Zapisz"}
                  </Button>
                  <LinkContainer to={"/business/workplace"} exact>
                    <Button>Anuluj</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}
          />
        </Box>
      </LayoutContent>
    );
  }

  componentWillUnmount() {}
}

export default WorkplaceForm;

WorkplaceForm.propTypes = {
  workplaceFetched: PropTypes.bool,
  boxTitle: PropTypes.string,
  localizations: PropTypes.array,
  workplaces: PropTypes.array,
  content: PropTypes.string,
  employees: PropTypes.array,
  createView: PropTypes.bool,
  handleSubmit: PropTypes.func,
  message: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
};
