import React from "react";
import styled from "styled-components";

import Button from "react-bootstrap/lib/Button";
import {bootstrapUtils} from "react-bootstrap/lib/utils/index";
import {media} from "../style/style-utils";

import {LinkContainer} from "./LinkContainer";
import PropTypes from "prop-types";

bootstrapUtils.addStyle(Button, "quick-access");

const StyledQuickAccessButton = styled.div`
  flex-basis: 20%;
  flex-shrink: 1;
  margin: 0 10px;
  & a,& button {
    min-height: 60px !important;
  }
  
  ${media.sm`
    flex-basis:auto;
    margin-bottom: 10px;
  `}
  
  ${media.xs`
    flex-basis:auto;
    margin-bottom: 10px;
    width:100%;
  `}


  
`;

export const QuickAccessButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 0 -10px 50px;
  ${media.xs`
      flex-wrap: wrap;
      flex-direction:column;
      align-items:flex-start;
  `}
`;

const QuickAccessButton = props => {
  return (
    <StyledQuickAccessButton>
      {props.onClick
        ? <Button onClick={props.onClick} className="btn-default"
                  bsStyle="quick-access">{props.children}</Button>
        : <LinkContainer to={props.to}>
          <Button className="btn-default"
                  bsStyle="quick-access">{props.children}</Button>
        </LinkContainer>
      }

    </StyledQuickAccessButton>
  );
};

export default QuickAccessButton;

QuickAccessButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
};