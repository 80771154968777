import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import moment from "moment";

import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

import { TwoInputWrapper, ButtonWrapper } from "components/Wrappers";

import { LinkContainer } from "components/LinkContainer";
import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";
import { ButtonWithIcon } from "components/Buttons";

import { media } from "style/style-utils/index";
import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let getDate = (date, format = "YYYY-MM-DD") => {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
};

let getHeaders = ({ deleteOSH }) => {
  let headers = [
    { name: "L.p.", prop: "" },
    {
      name: "Rodzaj badania",
      prop: "medical_examinations_type_name",
    },
    {
      name: "Data ukończenia",
      prop: "date_execution",
    },
    {
      name: "Termin następnego szkolenia",
      prop: "date_next",
    },
  ];

  if (deleteOSH) {
    headers.push({ name: "Usuń" });
  }

  return headers;
};

export default class OSHSegment extends React.PureComponent {
  render() {
    let { data = {}, addOSH, deleteOSH, formTextBox, readOnly } = this.props;
    let history = data && data.user_osh ? data.user_osh : [];

    return (
      <>
        <h3>Szkolenie BHP</h3>
        <BoxesWrapper>
          <div>
            <StyledInputBox
              disabled
              label="Szkolenie wstępne BHP - instruktaż ogólny: data szkolenia"
              value={getDate(data.date_initial) || ""}
            />
            <StyledInputBox
              disabled
              label="Szkolenia wstępne BHP - instruktaż stanowiskowy: data ostatniego szkolenia"
              value={getDate(data.date_job) || ""}
            />
            <StyledInputBox
              disabled
              label="Szkolenie okresowe BHP: data ostatniego szkolenia"
              value={getDate(data.date_periodic) || ""}
            />
            <StyledInputBox
              disabled
              label="Termin następnego szkolenia BHP"
              value={getDate(data.date_next) || ""}
            />
          </div>
          <div>
            {formTextBox ? (
              formTextBox
            ) : (
              <StyledTextareaBox
                disabled
                label="Dodatkowe szkolenia / kursy"
                value={data.osh_training_description}
              />
            )}
          </div>
        </BoxesWrapper>
        <ButtonWrapper>
          <Button disabled={readOnly} onClick={addOSH}>
            Dodaj nowe szkolenie BHP
          </Button>
        </ButtonWrapper>
        <h5>Historia szkoleń</h5>
        <StyledTableWrapper>
          <StyledTable striped bordered hover>
            <TableSortHeader
              headers={getHeaders({ deleteOSH })}
              data={history.map((item, index) => {
                return { ...item, logicIndex: index };
              })}
              render={(sortedData) => {
                return sortedData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{getDate(item.date_completed)}</td>
                      <td>{getDate(item.date_next)}</td>
                      {deleteOSH && (
                        <td style={{ textAlign: "center" }}>
                          <ButtonWithIcon
                            onClick={() => {
                              deleteOSH(item.id);
                            }}
                            icon="fa fa-trash"
                          />
                        </td>
                      )}
                    </tr>
                  );
                });
              }}
            ></TableSortHeader>
          </StyledTable>
        </StyledTableWrapper>
      </>
    );
  }
}
