import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import { connect } from "react-redux";
import { FormSpy, Field } from "react-final-form";
import styled from "styled-components";

import { generateProtocol } from "actions/incidentActions";

import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import { ButtonWithIcon, ButtonWrapper } from "components/ListComponents";

import Select from "components/Select";
import AppComponent from "components/AppComponent";
import saveBlobFile from "components/saveBlobFile";
import validator, { composeValidators } from "components/Validation";

import DayPicker from "lib/DayPicker";
import {
  FieldSelectDitionary,
  FieldSelectDitionary33,
  FieldInput,
  FieldInput33,
  FieldTextareaBox,
  FieldInputCheckBox,
  FieldDayPicker,
  FieldDayPicker33,
} from "components/Fields";
import { TwoInputWrapper, LeftPadding, FlexLine } from "./AnalysisForm.style";
import { StyledTooltip } from "style/styleComponents/ToolTip";
import ProtocolAccidentSeverity from "./container/protocolAccidentSeverity";
import ToggleSlider from "components/ToggleSlider";

let LocalStyledInputBox = styled(StyledInputBox)`
  width: 33%;
`;

let Width33 = styled.div`
  width: 33%;
  margin: 0 0 10px;
`;
let DateRangeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
`;
let IsAccidentWrapper = styled.div`
  display: flex;
  margin: 0 -10px 10px;
  align-items: center;
  & > * {
    margin: 0 10px;
  }
`;

let TitleSection = styled.span``;

let FormPartName = "protocol_";

let protocolKeys = [
  "protocol_main_evaluator",
  "protocol_main_evaluator_function",
  "protocol_second_evaluator",
  "protocol_second_evaluator_function",
  "protocol_range_date_from",
  "protocol_range_date_to",
  "protocol_following_circumstances",
  "protocol_following_causes",
  "protocol_non_compliance_employer",
  "protocol_non_compliance_employee",
  "protocol_intoxication_employee",
  "protocol_accident_effect",
  "protocol_work_accident_is_accident",
  "protocol_work_accident_a_work_accident",
  "protocol_work_accident_like_work_accident",
  "protocol_is_accident_explanation",
  "protocol_accident_type_id",
  "protocol_accident_severity_fatal",
  "protocol_accident_severity_serious",
  "protocol_accident_severity_causing_unable_on_time",
  "protocol_conclusions_and_prevention",
  "protocol_completed_date",
  "protocol_prepare_difficulties",
  "protocol_aknowledged_to_document_person_id",
  "protocol_victim_fullname",
  "protocol_objection_to_protocol",
  "protocol_aknowledged_date",
  "protocol_accept_date",
  "protocol_accept_person",
  "protocol_send_date",
  "protocol_send_number",
  "protocols_district_court",
  "protocols_attachment_list",
];

class AccidentCauseProtocol extends AppComponent {
  constructor(props) {
    super(props);
    this.generateProtocol = this.generateProtocol.bind(this);
  }

  generateProtocol(values) {
    let { incident_id, dispatch, form, protocol_number } = this.props;

    for (let item of protocolKeys) {
      form.mutators.setFieldTouched(item, true);
    }

    let invalid = protocolKeys.find((key) => {
      let field = form.getFieldState(key);
      if (field) {
        return field.invalid;
      } else {
        return false;
      }
    });

    if (!invalid) {
      let data = protocolKeys.reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});

      generateProtocol({ incident_id, data })(dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `protokół_${protocol_number}.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    }
  }

  render() {
    let { readOnly, form, values, dictionary, generateProtocol } = this.props;

    return (
      <Fragment>
        <ToggleSlider
          title={
            <TitleSection>
              Protokół ustalenia okoliczności i przyczyn wypadku przy pracy
            </TitleSection>
          }
          duration={600}
        >
          <h4>Członkowie zespołu powypadkowego</h4>
          <TwoInputWrapper>
            <FieldInput
              validate={validator.required}
              name="protocol_main_evaluator"
              label="Imię i nazwisko"
              disabled={readOnly}
            />
            <FieldInput
              validate={validator.required}
              name="protocol_main_evaluator_function"
              label="Funkcja"
              disabled={readOnly}
            />
          </TwoInputWrapper>
          <TwoInputWrapper>
            <FieldInput
              validate={validator.required}
              name="protocol_second_evaluator"
              label="Imię i nazwisko"
              disabled={readOnly}
            />
            <FieldInput
              validate={validator.required}
              name="protocol_second_evaluator_function"
              label="Funkcja"
              disabled={readOnly}
            />
          </TwoInputWrapper>
          Dni dokonania ustaleń dotyczących okoliczności i przyczyn wypadku przy
          pracy
          <DateRangeWrapper>
            <Width33>
              <Field
                validate={validator.required}
                name="protocol_range_date_from"
                render={({ input, meta, ...rest }) => (
                  <DayPicker
                    label="Od"
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    fullWidth
                    disabled={readOnly}
                    selectedDay={input.value}
                    handleChange={(value) => {
                      input.onChange(value);
                    }}
                  />
                )}
              />
            </Width33>
            <Width33>
              <Field
                validate={validator.required}
                name="protocol_range_date_to"
                render={({ input, meta, ...rest }) => (
                  <DayPicker
                    label="Do"
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    fullWidth
                    disabled={readOnly}
                    selectedDay={input.value}
                    handleChange={(value) => {
                      input.onChange(value);
                    }}
                  />
                )}
              />
            </Width33>
          </DateRangeWrapper>
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_following_circumstances"
            label="Ustalono następujące okoliczności wypadku (pkt. 4 protokołu ustalenia okoliczności i przyczyn wypadku przy pracy)"
            disabled={readOnly}
          />
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_following_causes"
            label="Ustalono następujące przyczyny wypadku (pkt. 5 protokołu ustalenia okoliczności i przyczyn wypadku przy pracy)"
            disabled={readOnly}
          />
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_non_compliance_employer"
            label="Stwierdzono nieprzestrzeganie przez pracodawcę następujących przepisów prawa pracy, w szczególności przepisów i zasad bezpieczeństwa higieny pracy lub innych przepisów dotyczących ochrony życia i zdrowia (wskazać dowody):"
            disabled={readOnly}
          />
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_non_compliance_employee"
            label="Stwierdzono, że wyłączną przyczyną wypadku było naruszenie przez poszkodowanego pracownika przepisów dotyczących ochrony życia i zdrowia, spowodowane przez niego umyślnie lub w skutek rażącego niedbalstwa (wskazać dowody):"
            disabled={readOnly}
          />
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_intoxication_employee"
            label="Stwierdzono stan nietrzeźwości albo użycie przez poszkodowanego pracownika środków odurzających lub substancji psychotropowych przyczyniające się w znacznym stopniu do powstania wypadku przy pracy (wskazać dowody, a w przypadku odmowy przez poszkodowanego poddania się badaniom na zawartość tych substancji  w organizmie – zamieścić informację o tym fakcie):"
            disabled={readOnly}
          />
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_accident_effect"
            label="Skutki wypadku dla poszkodowanego (pkt. 6 protokołu ustalenia okoliczności przyczyn wypadku przy pracy):"
            disabled={readOnly}
          />
          <FormSpy
            subscription={{
              values: true,
            }}
          >
            {({ values }) => (
              <Fragment>
                <IsAccidentWrapper>
                  <FieldSelectDitionary
                    validate={validator.required}
                    name={"protocol_work_accident_is_accident"}
                    label={"Stwierdza się, że wypadek"}
                    disabled={readOnly}
                    dictionary={[
                      {
                        id: "true",
                        name: "jest",
                      },
                      {
                        id: "false",
                        name: "nie jest",
                      },
                    ]}
                  />

                  <Fragment>
                    <Field
                      validate={validator.required}
                      name={"protocol_work_accident_a_work_accident"}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputCheckBox
                          {...input}
                          value={input.value || "false"}
                          checked={input.value == "true"}
                          disabled={readOnly}
                          label={"wypadkiem przy pracy"}
                          onChange={(e) => {
                            if (
                              values.protocol_work_accident_is_accident ==
                                "true" &&
                              e.target.checked == true
                            ) {
                              form.change(
                                "protocol_work_accident_like_work_accident",
                                "false"
                              );
                            }
                            input.onChange(e.target.checked ? "true" : "false");
                          }}
                        />
                      )}
                    />
                    <Field
                      validate={validator.required}
                      name={"protocol_work_accident_like_work_accident"}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputCheckBox
                          {...input}
                          value={input.value || "false"}
                          disabled={readOnly}
                          checked={input.value == "true"}
                          label={"traktowany na równi z wypadkiem przy pracy"}
                          onChange={(e) => {
                            if (
                              values.protocol_work_accident_is_accident ==
                                "true" &&
                              e.target.checked == true
                            ) {
                              form.change(
                                "protocol_work_accident_a_work_accident",
                                "false"
                              );
                            }
                            input.onChange(e.target.checked ? "true" : "false");
                          }}
                        />
                      )}
                    />
                  </Fragment>
                </IsAccidentWrapper>
              </Fragment>
            )}
          </FormSpy>
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_is_accident_explanation"
            label="Co uzasadnia się następująco:"
            disabled={readOnly}
          />
          <FieldSelectDitionary33
            validate={validator.required}
            name="protocol_accident_type_id"
            label="Rodzaj wypadku"
            dictionary={dictionary.protocol_accident_type}
            disabled={readOnly}
          />
          <ProtocolAccidentSeverity readOnly={readOnly} />
          <FieldTextareaBox
            validate={validator.required}
            name="protocol_conclusions_and_prevention"
            label="Wnioski i środki profilaktyczne (pkt. 9 protokołu ustalenia okoliczności i przyczyn wypadku przy pracy):"
            disabled={readOnly}
          />
          <StyledLabel>Protokół sporządzono dnia:</StyledLabel>
          <Field
            validate={validator.required}
            name="protocol_completed_date"
            render={({ input, meta, ...rest }) => (
              <Width33>
                <DayPicker
                  onFocus={input.onFocus}
                  onBlur={input.onBlur}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  fullWidth
                  disabled={readOnly}
                  selectedDay={input.value}
                  handleChange={(value) => {
                    input.onChange(value);
                  }}
                />
              </Width33>
            )}
          />
          <FieldTextareaBox
            name="protocol_prepare_difficulties"
            label="Przeszkody lub trudności, które uniemożliwily sporządzenie protokołu w wymaganym terminie 14 dni (pkt. 12 protokołu ustalenia okoliczności i przyczyn wypadku przy pracy):"
            disabled={readOnly}
          />
          {/* <Field
            validate={validator.required}
            name="protocol_prepare_difficulties"
            render={({ input, meta, ...rest }) => (
              <StyledTextareaBox
                value={input.value}
                minHeight={`100px`}
                disabled={readOnly}
                onChange={(event) => {
                  input.onChange(event.target.value);
                }}
              />
            )}
          /> */}
          <FieldSelectDitionary33
            validate={validator.required}
            name="protocol_aknowledged_to_document_person_id"
            label="Kto zostanie zapoznany z protokołem przed jego zatwierdzeniem?"
            dictionary={dictionary.aknowledged_to_document_person}
            disabled={readOnly}
          />
          <LeftPadding>
            <FieldInput33
              name="protocol_victim_fullname"
              label={
                "Imię i nazwisko poszkodowanego pracownika lub uprawnionego członka rodziny"
              }
              disabled={readOnly}
            />
            <FieldSelectDitionary33
              name="protocol_objection_to_protocol"
              label={"Zgłasza zastrzeżenia do protokołu"}
              dictionary={[
                { id: 1, name: "TAK" },
                { id: 2, name: "NIE" },
                { id: 3, name: "nie określono" },
              ]}
              disabled={readOnly}
            />
            <FieldDayPicker33
              disabled={readOnly}
              name="protocol_aknowledged_date"
              label="Data zapoznania z protokołem"
            />
          </LeftPadding>
          <FieldDayPicker33
            disabled={readOnly}
            name="protocol_accept_date"
            label="Data zatwierdzenia protokołu"
          />
          <LeftPadding>
            <FieldInput33
              name="protocol_accept_person"
              label="Imię i nazwisko poszkodowanego pracownika lub uprawnionego członka
            rodziny potwierdzającego odbiór protokołu"
              disabled={readOnly}
            />
          </LeftPadding>
          <FieldDayPicker33
            disabled={readOnly}
            name="protocol_send_date"
            label="Data doręczenia / przesłania protokołu"
          />
          <LeftPadding>
            <FieldInput
              name="protocol_send_number"
              label="Nr przesyłki poleconej (w przypadku przesłania protokołu)"
              disabled={readOnly}
            />
          </LeftPadding>
          <FieldTextareaBox
            name="protocols_attachment_list"
            label="Wykaz załączników do protokołu"
            disabled={readOnly}
          />
          <FieldInput33
            name="protocols_district_court"
            label="Poszkodowany pracownik, a w razie wypadku śmiertelnego – uprawniony członek rodziny zmarłego pracownika, może wystąpić z powództwem o sprostowanie protokołu do sądu rejonowego  – sądu pracy w:"
            disabled={readOnly}
          />
          <ButtonWrapper>
            <FormSpy subscribe={{ values: true }}>
              {({ values }) => {
                return (
                  <Button
                    onClick={() => {
                      this.generateProtocol(values, form);
                    }}
                  >
                    Generuj protokół powypadkowy
                  </Button>
                );
              }}
            </FormSpy>
          </ButtonWrapper>
        </ToggleSlider>
      </Fragment>
    );
  }
}

export default connect((store) => ({}))(AccidentCauseProtocol);
