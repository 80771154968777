import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import ModalSchema from "components/Modal/./_SchemaAppComponent";
import {
  FieldInput,
  FieldInputCheckBox,
  FieldInputCheckBoxRadio,
  FieldTextareaBox,
} from "components/Fields";
import { TableListPage } from "components/Tables";
import {
  ButtonWrapper,
  LeftPadding,
  StyledInputCheckBoxWrapper,
  TwoInputWrapper,
} from "components/Wrappers";
import { StyledLabel } from "style/styleComponents/Form/input";
import Question from "./Question";
const StyledModal = styled(Modal)`
  width: "800px !important";
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let setInitialValues = function (questions) {
  return questions.reduce((obj, item) => {
    let { id, comment, dictionary_question_answer_id } = item;
    let newItems = {};
    newItems[`question_${id}_answer_id`] = dictionary_question_answer_id;
    newItems[`question_${id}_comment`] = comment;
    let subQuestions = {};
    if (item.questions) {
      let r = setInitialValues(item.questions);
      subQuestions = r;
    }

    return { ...obj, ...newItems, ...subQuestions };
  }, {});
};

class ControlForm extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let {
      open,
      questions,
      answers,
      dispatch,
      attachments,
      addAttachmentAction,
      deleteAttachmentAction,
      downloadAction,
      saveQuestions = () => {
        alert("ModalCOmponentForm mock");
      },
      readOnly,
    } = this.props;

    return (
      <div className="static-modal" style={{ width: "1000px" }}>
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Wypełnij listę kontrolną</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Form
            onSubmit={(v) => {
              saveQuestions(v, () => {
                this.closeModal();
              });
            }}
            initialValues={setInitialValues(questions)}
            render={({ handleSubmit, values }) => {
              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <Modal.Body>
                      <Questions questions={questions}>
                        {({ data }) => {
                          return (
                            <>
                              <Question
                                readOnly={readOnly}
                                data={data}
                                downloadAction={downloadAction}
                                dispatch={dispatch}
                                answers={answers}
                                addAttachmentAction={addAttachmentAction}
                                deleteAttachmentAction={deleteAttachmentAction}
                              />
                            </>
                          );
                        }}
                      </Questions>
                    </Modal.Body>
                    <Modal.Footer>
                      <ButtonWrapper>
                        {!readOnly ? (
                          <Button
                            disabled={readOnly}
                            bsStyle="primary"
                            type="submit"
                          >
                            Zapisz i zamknij
                          </Button>
                        ) : (
                          ""
                        )}
                      </ButtonWrapper>
                    </Modal.Footer>
                  </form>
                </>
              );
            }}
          />
        </StyledModal>
      </div>
    );
  }
}

export default connect((store) => ({
  questions: store.riskAssessment.questions,
  answers: store.riskAssessment.answers,
  attachments: store.riskAssessment.attachments,
}))(ControlForm);

function Questions(props) {
  let { questions, children } = props;
  return (
    <>
      {questions.map((q) => {
        return (
          <>
            {q.questions ? (
              <>
                <StyledLabel>{q.name}</StyledLabel>
                <LeftPadding>
                  <Questions questions={q.questions} children={children} />
                </LeftPadding>
              </>
            ) : (
              children({ data: q })
            )}
          </>
        );
      })}
    </>
  );
}
 