import React from "react";
import { connect } from "react-redux";
import {
  editBusiness,
  getBusiness,
  saveBusinessAdmin,
  saveLogo,
  deleteLogo,
} from "actions/businessActions";
import { getDraftAuditTypes, clearAudit } from "actions/auditActions";
import {
  getDraftRAMethods,
  getDraftRAs,
  getDraftControlListList,
  clearRiskAssessment,
  getDraftHazardList,
} from "actions/riskAssessmentAction";

import { getDraftQETypeList } from "actions/QEActions";

import AppComponent, { setMessage } from "components/AppComponent";
import BusinessForm from "components/Business.Form";

class BusinessEdit extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleDeleteLogo = this.handleDeleteLogo.bind(this);
    this.handleLogoAutoUpload = this.handleLogoAutoUpload.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { business_id } = match.params;
    getBusiness(business_id)(dispatch).then(this.handleServerResponse());
    getDraftAuditTypes({ business_id })(dispatch).then(
      this.handleServerResponse()
    );

    getDraftRAMethods({ business_id })(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getDraftRAs({ business_id })(dispatch).then(this.handleServerResponse());
    getDraftControlListList({ business_id })(dispatch).then(
      this.handleServerResponse()
    );
    getDraftHazardList({ business_id })(dispatch).then(
      this.handleServerResponse()
    );
    getDraftQETypeList({ business_id })(dispatch).then(
      this.handleServerResponse()
    );
  }

  handleSubmit(data) {
    const { dispatch, match } = this.props;
    const { business_id } = match.params;
    editBusiness(business_id, data)(dispatch).then(this.handleServerResponse());
  }

  handleDeleteLogo(callback) {
    const { dispatch, match } = this.props;
    const { business_id } = match.params;
    deleteLogo(business_id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { message } = action.payload.data;
        this.setState({
          message: setMessage(action.payload.data.message, "success"),
        });
        callback();
      })
    );
  }

  handleLogoAutoUpload(formData, callback) {
    const { dispatch, match } = this.props;
    const { business_id } = match.params;
    saveLogo(
      business_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { message, logo_url } = action.payload.data;
        this.setState({
          message: setMessage(action.payload.data.message, "success"),
        });
        callback(logo_url);
      })
    );
  }

  handleModalSubmit(data) {
    const { dispatch, match } = this.props;
    const { business_id } = match.params;
    saveBusinessAdmin(
      business_id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  render() {
    const { message } = this.state;
    let {
      business,
      audit,
      modules,
      users: { singleData: userData },
      ra,
      raMethods,
      controlList,
      raHazards,
      modelHazardList,
      QETypes,
    } = this.props;
    let riskAssessment = this.props.riskAssessment;

    const {
      singleData: {
        name,
        active,
        email,
        last_name,
        phone,
        full_name,
        main_address,
        nip,
        regon,
        pkd,
        logo_url,
        show_logo_in_pdf,
      },
    } = business;
    const { data: modulesData = [] } = modules;

    return (
      <BusinessForm
        handleSubmit={this.handleSubmit}
        handleModalSubmit={this.handleModalSubmit}
        handleDeleteLogo={this.handleDeleteLogo}
        handleLogoAutoUpload={this.handleLogoAutoUpload}
        data={{
          name,
          active,
          full_name,
          main_address,
          nip,
          regon,
          pkd,
          logo_url,
          show_logo_in_pdf: show_logo_in_pdf ? "true" : "false",
        }}
        admin={{
          email,
          last_name,
          phone,
        }}
        auditTypesDrafts={audit.typeData}
        ra={ra}
        raMethod={raMethods}
        controlList={controlList}
        hazards={modelHazardList}
        QETypes={QETypes}
        modules={modulesData}
        message={message}
        boxTitle="Edytuj konto firmy"
        user={userData}
      />
    );
  }
  componentWillUnmount() {
    clearAudit()(this.props.dispatch);
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  audit: store.audit,
  business: store.business,
  modules: store.modules,
  riskAssessment: store.riskAssessment,
  ra: store.riskAssessment.data,
  raMethods: store.riskAssessment.methods,
  controlList: store.riskAssessment.controlList,
  modelHazardList: store.riskAssessment.modelHazardList,
  QETypes: store.qe.types,
  users: store.users,
  message: store.message,
}))(BusinessEdit);
