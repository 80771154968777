import React from "react";
import {connect} from "react-redux";
import AppComponent from "../../components/AppComponent";
import ResetPasswordView from "../../components/ResetPasswordView";
import {sendResetRequest} from "../../actions/superAdminActions";

export class SuperAdminResetPassword extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServerResponse.bind(this);
  }

  componentDidMount(){
    this.updateMessage();
  }

  handleSubmit(data){
    const {dispatch, history} = this.props;
    sendResetRequest(data)(dispatch)
      .then(this.handleServerResponse());
  }

  render() {
    const {message} = this.state;
    return (<ResetPasswordView
      handleSubmit={this.handleSubmit}
      loginLink="/super-admin/login"
      alert={message}
    />);
  }
}

export default connect(store=>({
  message:store.message,
  superadmin: store.superadmin
}))(SuperAdminResetPassword);