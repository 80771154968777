import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import styled from "styled-components";
import PropTypes from "prop-types";

import ModalList from "components/Modal/List";

import DragAndDrop from "../../components/DragAndDrop/Dashboard";
import Bars from "../../components/Charts/Bars.js";
import PieChart from "../../components/Charts/Pie.js";
import Calendar from "../../components/Calendar/";
import TaskList, { taskWidget } from "./_TaskList";
import QuickAccessButton, {
  QuickAccessButtonWrapper,
} from "../../components/QuickAccessButton";
import {
  getTaskToDashboard,
  getDaysFromLastIncident,
  getAccountDashboardSettings,
  getIncidentStatistics,
  getIncidentStatisticsDetail,
  getPotentiallyIncidentStatistics,
  getPotentiallyIncidentStatisticsDetail,
  getCalendarEvents,
  changeWidgetOrder,
  getTasksCompleteStatistic,
  getModule7DeadlineCount,
  getModule7DeadlineCountDetail,
  dashboardCleaner,
} from "../../actions/dashboardActions";
import {
  getLocalizationsOnly,
  getUsersLocalizationsAndDivisions2,
} from "../../actions/localizationActions";
import incidentWidget from "./incidentWidget";
import { addIncident } from "../../actions/incidentActions";
import FetchingContainer from "../../components/LoadingScreen";
import Select from "components/Select";

const createPieChartData = (completedTask) => [
  { name: "Group A", value: completedTask, fill: "#00b317" },
  { name: "Group B", value: 100 - completedTask, fill: "#ff8f00" },
];

const StyledPastDays = styled.div``;

const StyledPastLine = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  line-height: 48px;
  vertical-align: bottom;
`;

const StyledMedicalLine = styled(StyledPastLine)`
  text-align: "justify";
  line-height: 20px;
  align-items: center;
`;

const StyledPastLineName = styled.div`
  width: 50%;
`;

const StyledPastLineDays = styled.div`
  width: 50%;
  font-weight: 700;
  font-size: xx-large;
  color: ${(props) => props.theme.primaryDarknessColor};
`;

export const PastDays = (props) => (
  <StyledPastDays>
    {(props.data || []).map((item, index) => (
      <StyledPastLine key={index}>
        <StyledPastLineName>{item.name}</StyledPastLineName>
        <StyledPastLineDays>{item.days}</StyledPastLineDays>
      </StyledPastLine>
    ))}
  </StyledPastDays>
);

export const MedicalStatistics = (props) => {
  let [localizationId, setLocalizationId] = useState("0");
  let { data, localizations = [], dispatch } = props;
  let { meCount, oshCount, qeCount } = data;

  useEffect(() => {
    const localization = localizations.find((i) => {
      return i.id === localizationId;
    });
    let data;
    if (localization && localization.division) {
      data = {
        division_id: localizationId,
      };
    } else {
      data = {
        localization_id: localizationId == "0" ? null : localizationId,
      };
    }

    getModule7DeadlineCountDetail(data)(dispatch);
  }, [localizationId]);

  return (
    <>
      <div
        style={{
          padding: "0 10px",
        }}
      >
        <Select
          selected={localizationId}
          propValue={"id"}
          propName={"name"}
          data={[
            {
              id: "0",
              name: "Lokalizacja",
            },
          ].concat(localizations)}
          handleChange={(value) => {
            setLocalizationId(value);
            // getModule7DeadlineCountDetail({ localization_id: value })(dispatch);
          }}
        />
      </div>
      <StyledPastDays>
        <StyledMedicalLine
          style={{
            liheHeight: "20px",
          }}
        >
          <StyledPastLineName>
            Liczba pracowników, którym kończy się ważność badań profilaktycznych
            w ciągu 45 dni.
          </StyledPastLineName>
          <StyledPastLineDays
            style={{
              marginLeft: "20px",
            }}
          >
            {meCount}
          </StyledPastLineDays>
        </StyledMedicalLine>
        <StyledMedicalLine>
          <StyledPastLineName>
            Liczba pracowników, którym kończy się ważność szkoleń BHP w ciągu 45
            dni.
          </StyledPastLineName>
          <StyledPastLineDays
            style={{
              marginLeft: "20px",
            }}
          >
            {oshCount}
          </StyledPastLineDays>
        </StyledMedicalLine>
        <StyledMedicalLine>
          <StyledPastLineName>
            Liczba pracowników, którym kończy się ważność uprawnień
            kwalifikacyjnych w ciągu 45 dni
          </StyledPastLineName>
          <StyledPastLineDays
            style={{
              marginLeft: "20px",
            }}
          >
            {qeCount}
          </StyledPastLineDays>
        </StyledMedicalLine>
      </StyledPastDays>
    </>
  );
};

export class SuperDashboard extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modal: false,
      accidentSelect: [0, 0],
      collection: this.prepareCollection([]), //przenieść do rendera
    };

    this.handleAccidentChart = this.handleAccidentChart.bind(this);
    this.handlePotentiallyIncidentChart = this.handlePotentiallyIncidentChart.bind(
      this
    );
    this.prepareCollection = this.prepareCollection.bind(this);
    this.setWidget = this.setWidget.bind(this);
    this.getCalendarEvents = this.getCalendarEvents.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      dashboard: { newTask },
    } = this.props;
    getLocalizationsOnly()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );

    getUsersLocalizationsAndDivisions2()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );

    getAccountDashboardSettings()(dispatch).then(
      this.handleServerResponse({}, () => {
        const {
          dashboard: { defaultSettings, widgetToShow },
        } = this.props;
        if (widgetToShow.find((item) => item == 2)) {
          getIncidentStatistics()(dispatch).then(
            this.handleServerResponse({}, () => {})
          );
        }

        if (widgetToShow.find((item) => item == 3)) {
          getDaysFromLastIncident()(dispatch).then(
            this.handleServerResponse({}, () => {})
          );
        }

        if (widgetToShow.find((item) => item == 5)) {
          getPotentiallyIncidentStatistics()(dispatch).then(
            this.handleServerResponse({}, () => {})
          );
        }

        if (widgetToShow.find((item) => item == 6)) {
          getTasksCompleteStatistic()(dispatch).then(
            this.handleServerResponse()
          );

          if (newTask && newTask.length) {
            this.setState({ modal: true });
          }
        }

        if (widgetToShow.find((item) => item == 8)) {
          getModule7DeadlineCount()(dispatch).then(this.handleServerResponse());
        }
      })
    );

    getTaskToDashboard()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  getCalendarEvents(startDate, endDate) {
    const { dispatch } = this.props;
    getCalendarEvents({
      date_start: startDate,
      date_end: endDate,
    })(dispatch).then(this.handleServerResponse());
  }

  prepareCollection(no) {
    let {
      task: { data: taskData },
      dashboard,
      dashboard: {
        localizationWithDays,
        incidentStatistics,
        potentiallyIncidentStatistics,
        calendarEvents,
        completedTaskPercent,
        module7Statistics,
      },
      localization: {
        data: localizationData,
        data2: localizationDataWithDivision,
      },
    } = this.props;
    const DragAndDropCollection = [
      incidentWidget({
        incidentStatistics,
        handleAccidentChart: this.handleAccidentChart,
        localizationData,
        id: 2,
        title: "Statystyki wypadków",
      }),
      {
        id: 3,
        title: "Liczba dni od ostatniego wypadku",
        content: <PastDays data={localizationWithDays} />,
      },
      {
        id: 4,
        title: "Kalendarz",
        content: (
          <Calendar
            getNewEvents={this.getCalendarEvents}
            events={calendarEvents}
          />
        ),
      },
      incidentWidget({
        incidentStatistics: potentiallyIncidentStatistics,
        handleAccidentChart: this.handlePotentiallyIncidentChart,
        localizationData,
        id: 5,
        title: "Statystyki zdarzeń potencjalnie wypadkowych",
      }),
      {
        id: 6,
        title: "Stopień wykonania zadań",
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "50px",
                fontWeight: "700",
                color: "#00b317",
              }}
            >
              {completedTaskPercent}%
            </div>
            <PieChart data={createPieChartData(completedTaskPercent)} />
          </div>
        ),
      },
      taskWidget(taskData),
      {
        id: 8,
        title:
          "Badania profilaktyczne / Szkolenia BHP / Uprawnienia kwalifikacyjne",
        content: (
          <MedicalStatistics
            data={module7Statistics}
            localizations={localizationDataWithDivision}
            dispatch={this.props.dispatch}
          />
        ),
      },
    ];

    return DragAndDropCollection.filter((collectionItemItem) => {
      return no.findIndex((item) => item == collectionItemItem.id) > -1;
    }).sort(function (a, b) {
      return no.indexOf(a.id) - no.indexOf(b.id);
    });
  }

  closeModal() {
    this.setState({ modal: false });
  }

  handleOnDragEnd() {
    this.setWidget();
  }

  handleAccidentChart(first, second) {
    const { dispatch } = this.props;
    getIncidentStatisticsDetail({
      localization_id: first,
      time_number: second,
    })(dispatch).then(this.handleServerResponse());
  }

  handlePotentiallyIncidentChart(first, second) {
    const { dispatch } = this.props;
    getPotentiallyIncidentStatisticsDetail({
      localization_id: first,
      time_number: second,
    })(dispatch).then(this.handleServerResponse());
  }

  setWidget(collection) {
    const { dispatch } = this.props;
    const no = collection.map((item) => item.id);
    changeWidgetOrder({ no })(dispatch);
    // this.setState({collection});
  }

  render() {
    const {
      dispatch,
      history,
      dashboard: { fetched, widgetToShow, newTask },
      modules: { data: modulesData },
    } = this.props;
    const { collection } = this.state;
    const permissions = modulesData.reduce((obj, item) => {
      obj[item.id] = item.active;
      return obj;
    }, {});
    return (
      <div>
        <QuickAccessButtonWrapper>
          {permissions[1] && (
            <QuickAccessButton
              onClick={() => {
                addIncident()(dispatch).then(
                  this.handleServerResponse({}, (action) => {
                    const { incident } = action.payload.data;
                    history.push(`/incident/register/${incident}`);
                  })
                );
              }}
            >
              Zgłoś wypadek lub zdarzenie potencjalnie wypadkowe
            </QuickAccessButton>
          )}
          {permissions[2] && (
            <QuickAccessButton to={"/audit/register"}>
              Zaplanuj audyt
            </QuickAccessButton>
          )}
          {permissions[2] && (
            <QuickAccessButton to={"/audit/add"}>
              Wprowadź wyniki audytu
            </QuickAccessButton>
          )}
          {permissions[3] && (
            <QuickAccessButton to={"/task/add"}>
              Dodaj działanie
            </QuickAccessButton>
          )}
          {permissions[6] && (
            <QuickAccessButton to={"/risk-assessment/basic"}>
              Dodaj nową ocenę ryzyka zawodowego
            </QuickAccessButton>
          )}
          {permissions[7] && (
            <QuickAccessButton to={"/medical-examination/referral"}>
              Wystaw skierowanie na badania profilaktyczne
            </QuickAccessButton>
          )}
        </QuickAccessButtonWrapper>
        <FetchingContainer fetched={fetched}>
          <DragAndDrop
            items={this.prepareCollection(widgetToShow)}
            handleOnDragEnd={this.setWidget}
          ></DragAndDrop>
        </FetchingContainer>
        <ModalList
          open={this.state.modal}
          handleClose={this.closeModal}
          title="Ostatnio przydzielone zadania"
          headers={[
            { name: "Nazwa", prop: "name" },
            { name: "Termin", prop: "date_end" },
          ]}
          data={newTask}
        />
      </div>
    );
  }

  componentWillUnmount() {
    dashboardCleaner()(this.props.dispatch);
  }
}

export default connect((store) => ({
  account: store.account,
  dashboard: store.dashboard,
  localization: store.localization,
  modules: store.modules,
  task: store.task,
}))(SuperDashboard);

PastDays.propType = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.node,
    })
  ),
};
