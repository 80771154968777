import React from "react";
import { connect } from "react-redux";

import SideMenu from "./_SideMenu";

import {
  clearRiskAssessment,
  getDraftRA,
  getDraftHazardList,
  getDraftRAControlListByQuestionAnswerYes,
  addDraftRAAnalysisHazard,
  getDraftRAAnalysisHazard,
  deleteRAAnalysisHazardDraft,
  getDraftMethodEntry,
  getDraftHazard,
  saveRAAnalysisHazardDraft,
  changeOrderRAAnalysisHazardDraft,
  saveDraftHazard,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import AddRiskAssessmentReview from "components/RiskAssessment.Forms/Analysis";
import RAAnalysis from "container/RiskAssessment/EntryAddEdit/Analysis";
import { setUrls } from "./_staticData";

class Component extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls() };

    this.copyRAControlList = this.copyRAControlList.bind(this);

    this.addRAAnalysisHazard = this.addRAAnalysisHazard.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.addRAAnalysisHazardByControlList = this.addRAAnalysisHazardByControlList.bind(
      this
    );
    this.deleteRAAnalysisHazard = this.deleteRAAnalysisHazard.bind(this);
    this.saveHazard = this.saveHazard.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
    this.saveModelHazard = this.saveModelHazard.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;

    getDraftRAControlListByQuestionAnswerYes(id)(dispatch).then(
      this.handleServerResponse()
    );

    getDraftRA(id)(dispatch).then(this.handleServerResponse());

    getDraftMethodEntry(id)(dispatch).then(this.handleServerResponse());
    getDraftHazardList()(dispatch).then(this.handleServerResponse());
    getDraftRAAnalysisHazard(id)(dispatch).then(this.handleServerResponse());
  }

  addRAAnalysisHazard() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addDraftRAAnalysisHazard(id)(dispatch).then(this.handleServerResponse());
  }

  addRAAnalysisHazardByControlList(ra_control_list_id) {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addDraftRAAnalysisHazard(id, { ra_control_list_id })(dispatch).then(
      this.handleServerResponse()
    );
  }

  addCategory() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addDraftRAAnalysisHazard(id, { category: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  changeOrder(data) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;
    changeOrderRAAnalysisHazardDraft(
      ra_id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  deleteRAAnalysisHazard(id) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;

    return () => {
      deleteRAAnalysisHazardDraft(
        ra_id,
        id
      )(dispatch).then(this.handleServerResponse());
    };
  }

  saveHazard(data) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;
    let { id } = data;
    saveRAAnalysisHazardDraft(
      ra_id,
      id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  saveModelHazard(data) {
    let { dispatch, history } = this.props;
    saveDraftHazard(null, data)(dispatch).then(this.handleServerResponse());
  }

  renderSideMenu() {
    const { match, permission, data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }

  render() {
    let { message } = this.state;
    let {
      controlList,
      modelHazardList,
      hazards,
      method,
      match,
      data,
    } = this.props;
    let { id } = match.params;
    return (
      <AddRiskAssessmentReview
        addCategory={this.addCategory}
        addRAAnalysisHazard={this.addRAAnalysisHazard}
        addRAAnalysisHazardByControlList={this.addRAAnalysisHazardByControlList}
        changeOrder={this.changeOrder}
        controlList={controlList}
        copyRAControlList={this.copyRAControlList}
        data={data}
        draft
        deleteRAAnalysisHazard={this.deleteRAAnalysisHazard}
        getGetHazard={getDraftHazard}
        hazards={hazards}
        id={id}
        modelHazardList={modelHazardList}
        method={method}
        message={message}
        saveHazard={this.saveHazard}
        saveModelHazard={this.saveModelHazard}
        SideMenu={this.renderSideMenu()}
        urls={this.state.urls}
      />
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  message: store.message,
  data: store.riskAssessment.singleData,
  controlList: store.riskAssessment.raControlList,
  modelHazardList: store.riskAssessment.modelHazardList,
  hazards: store.riskAssessment.raHazards,
  method: store.riskAssessment.raMethodEntry,
}))(Component);
