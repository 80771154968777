import React from "react";
import styled from "styled-components";
import {Form, Field} from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import {StyledInputBox} from "../style/styleComponents/Form/input";
import {PublicAuthView} from "../style/styleComponents/Wrappers";
import {LinkContainer} from "../components/LinkContainer";
import {media} from "../style/style-utils";
import validator from "./Validation";

const LoginBoxFooter = styled.div`
  padding-top:20px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  ${media.xs`
    align-items: stretch;
    flex-direction: column;
    & > button {
      margin-bottom:10px;
    }
  `}
`;

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export default class ResetPasswordView extends React.Component {
  render() {
    const {handleSubmit,loginLink,alert} = this.props;

    return (<PublicAuthView
      title={"Resetowanie hasła"}
      alert={alert}
    >
      <Form
        onSubmit={handleSubmit}
        render={({handleSubmit, pristine, invalid}) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              validate={composeValidators(validator.required,validator.mustBeEmail)}
              render={({input, meta, ...rest}) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="E-mail"
                  type="email"
                />
              )}
            />
            <LoginBoxFooter>
              <Button type="submit" bsStyle="primary">Wyślij nowe hasło</Button>
              <LinkContainer to={loginLink}>
                <Button>Powrót do logowania</Button>
              </LinkContainer>
            </LoginBoxFooter>
          </form>
        )}
      />
    </PublicAuthView>);
  }
}