import React from "react";
import { connect } from "react-redux";
import { getUserWorkplacesWithout } from "actions/workplaceActions";
import { getState } from "actions/accidentOWTWActions";
import AppComponent from "components/AppComponent";

const getUrl = (id, stage) => {
  switch (stage) {
    case 3:
      return `/accident-owtw/notification/${id}`;
    case 2:
      return `/accident-owtw/attachment/${id}`;
    case 1:
      return `/accident-owtw/analysis/${id}`;
    case 0:
      return `/accident-owtw/register/${id}`;
    default:
      return `/accident-owtw/register/${id}`;
  }
};

class RedirectComponent extends AppComponent {
  componentDidMount() {
    const { dispatch, match, history } = this.props;
    const { id } = match.params;
    getState(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { stage, dictionary_progress_id } = action.payload.data;
        if (dictionary_progress_id != 1) {
          history.replace(getUrl(id, 0));
        } else {
          history.replace(getUrl(id, stage));
        }
      })
    );
  }

  render() {
    return null;
  }
}

export default connect((store) => ({}))(RedirectComponent);
