import React, { Fragment } from "react";
import { Field, FormSpy } from "react-final-form";

import Box from "../Box";
import styled from "styled-components";
import validator, { composeValidators } from "../Validation";
import { FieldArray } from "react-final-form-arrays";
import Button from "react-bootstrap/lib/Button";
import Modal from "../Modal/SimpleAccept";
import { v4 as uuidv4 } from "uuid";

import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
  StyledInputMessage,
} from "../../style/styleComponents/Form/input";
import { ButtonWithIcon, ButtonWrapper } from "../ListComponents";
let VariableButtonWrapper = styled(ButtonWrapper)`
  margin: 0 -20px;
  padding: 10px 0;
`;

let DeleteButton = ({ className, ...props }) => {
  return (
    <div className={className}>
      <Button type="button" {...props}>
        <i className={"fa fa-trash"} />
      </Button>
    </div>
  );
};

let StyledDeleteButton = styled(Button)`
  height: 34px;
`;

const LineBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
  }
`;
const ParametrBox = styled(Box)`
  padding; 30px; 
`;
const VariablesTopLabel = styled.div`
  flex: 0 0 30%;
  padding: 0 10px;

  &:nth-child(1) {
    flex: 0 0 20%;
    width: 20%;
  }
  &:nth-child(3) {
    flex: 0 1 50%;
  }
  &:nth-child(4) {
    width: 37px;
  }
`;
const VariablesInput = styled(StyledInputBox)`
  flex: 0 0 30%;
  padding: 0 10px;
  box-sizing: border-box;
  &:nth-child(1) {
    flex: 0 0 20%;
    width: 20%;
  }
  &:nth-child(3) {
    flex: 0 1 50%;
  }
  &:nth-child(4) {
    width: 37px;
  }
`;
let modaDefaultState = {
  open: false,
  entry_id: null,
  entry_name: null,
  callback: () => {},
};

let VariablesWrapper = styled.div`
  padding: 0 0 40px 10px;
`;

export default class ParametrsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modal: { ...modaDefaultState },
    };
    this.deleteParametr = this.deleteParametr.bind(this);
    this.deleteModalAccept = this.deleteModalAccept.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  deleteParametr(index) {
    this.props.onRemove(index);
  }

  deleteModalAccept() {
    const { callback } = this.state.modal;
    callback();
    this.modalClose();
  }

  modalOpen(name, callback) {
    return () => {
      this.setState({
        modal: {
          ...this.state.modal,
          open: true,
          entry_id: false,
          entry_name: name,
          callback,
        },
      });
    };
  }

  modalClose() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
        entry_id: false,
        entry_name: null,
        callback: () => {},
      },
    });
  }

  render() {
    let { modal } = this.state;
    let { fields, validator, refForm, errors, readOnly } = this.props;
    let getError = validateFieldManual(errors);
    return (
      <Fragment>
        {fields.map((name, index) => {
          const field = fields.value[index];
          return (
            <FormSpy subscribe={{ values: true }}>
              {({ values }) => {
                return (
                  <ParametrBox
                    title={`Parametr${
                      values &&
                      values.parameters &&
                      values.parameters[index] &&
                      values.parameters[index].name
                        ? ` - ${values.parameters[index].name} `
                        : ""
                    }`}
                  >
                    <Field
                      name={`${name}.name`}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          label="Nazwa"
                          value={input.value}
                          disabled={readOnly}
                          // error={meta.error && meta.touched}
                          // helperText={meta.error && meta.touched ? meta.error : null}
                          {...getError(`${name}.name`, meta)}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name={`${name}.user_variable_name`}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          label="Zmienna w obliczeniach"
                          value={input.value}
                          disabled={readOnly}
                          // error={meta.error && meta.touched}
                          // helperText={meta.error && meta.touched ? meta.error : null}
                          {...getError(`${name}.name`, meta)}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <FieldArray name={`${name}.variables`}>
                      {({ fields, meta }) => (
                        <>
                          <Variables
                            readOnly={readOnly}
                            fields={fields}
                            meta={meta}
                            errors={errors}
                          />

                          <FormSpy subscribe={{ errors: true }}>
                            {({ errors }) => {
                              let error = errors[`${name}.variables`];
                              return (
                                <StyledInputMessage>
                                  {error && meta.touched ? error : null}
                                </StyledInputMessage>
                              );
                            }}
                          </FormSpy>
                        </>
                      )}
                    </FieldArray>

                    <ButtonWrapper>
                      <Button
                        disabled={readOnly}
                        onClick={() => {
                          this.modalOpen(field.name, () => {
                            fields.remove(index);
                          })();
                        }}
                      >
                        usuń
                      </Button>
                    </ButtonWrapper>
                  </ParametrBox>
                );
              }}
            </FormSpy>
          );
        })}
        <ButtonWrapper>
          <Button
            disabled={readOnly}
            type="button"
            onClick={() => {
              fields.push({});
            }}
          >
            Dodaj parametr
          </Button>
        </ButtonWrapper>
        <Modal
          open={modal.open}
          title={`Usuń parametr ${
            modal.entry_name ? ` - ${modal.entry_name}` : ""
          }.`}
          description={"Czy na pewno chcesz usunąć dany wpis? "}
          handleAccept={this.deleteModalAccept}
          handleClose={this.modalClose}
        />
      </Fragment>
    );
  }
}

let validateFieldManual = (errors) => (name, meta) => {
  let error = errors[name];

  return {
    error: error && meta.touched,
    helperText: error && meta.touched ? error : null,
  };
};

class Variables extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.state, modal: { ...modaDefaultState } };
    this.deleteModalAccept = this.deleteModalAccept.bind(this);

    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }
  deleteModalAccept() {
    const { callback } = this.state.modal;
    callback();
    this.modalClose();
  }

  modalOpen(name, callback) {
    return () => {
      this.setState({
        modal: {
          ...this.state.modal,
          open: true,
          entry_id: false,
          entry_name: name,
          callback,
        },
      });
    };
  }

  modalClose() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
        entry_id: false,
        entry_name: null,
        callback: () => {},
      },
    });
  }

  render() {
    let { modal } = this.state;
    let { fields = [], meta = {}, errors, readOnly } = this.props;
    let getError = validateFieldManual(errors);
    return (
      <Fragment>
        <VariablesWrapper>
          <LineBox>
            <VariablesTopLabel>Nazwa</VariablesTopLabel>
            <VariablesTopLabel>Wartość liczbowa</VariablesTopLabel>
            <VariablesTopLabel>Opis</VariablesTopLabel>
            <VariablesTopLabel></VariablesTopLabel>
          </LineBox>

          {fields.map((name, index) => {
            const field = fields.value[index];
            return (
              <LineBox>
                <Field
                  name={`${name}.name`}
                  render={({ input, meta, ...rest }) => (
                    <VariablesInput
                      disabled={readOnly}
                      value={input.value}
                      {...getError(`${name}.name`, meta)}
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
                <Field
                  name={`${name}.number_value`}
                  render={({ input, meta, ...rest }) => (
                    <VariablesInput
                      disabled={readOnly}
                      {...getError(`${name}.number_value`, meta)}
                      type="number"
                      value={input.value}
                      // error={meta.error && meta.touched}
                      // helperText={
                      //   meta.error && meta.touched ? meta.error : null
                      // }
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
                <Field
                  name={`${name}.description`}
                  render={({ input, meta, ...rest }) => (
                    <VariablesInput
                      disabled={readOnly}
                      {...getError(`${name}.description`, meta)}
                      value={input.value}
                      // error={meta.error && meta.touched}
                      // helperText={
                      //   meta.error && meta.touched ? meta.error : null
                      // }
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
                <DeleteButton
                  type="button"
                  disabled={readOnly}
                  onClick={() => {
                    this.modalOpen(field.name, () => {
                      fields.remove(index);
                    })();
                  }}
                >
                  <i className={"fa fa-trash"} />
                </DeleteButton>
              </LineBox>
            );
          })}
          <VariableButtonWrapper>
            <Button
              disabled={readOnly}
              type="button"
              onClick={() => {
                fields.push({ uuid: `new_${uuidv4()}` });
              }}
            >
              Dodaj wartość
            </Button>
          </VariableButtonWrapper>
        </VariablesWrapper>
        <Modal
          open={modal.open}
          title={`Usuń wartość ${
            modal.entry_name ? ` - ${modal.entry_name}` : ""
          }.`}
          description={"Czy na pewno chcesz usunąć dany wpis? "}
          handleAccept={this.deleteModalAccept}
          handleClose={this.modalClose}
        />
      </Fragment>
    );
  }
}
