export const defaultUrls = {
  MELIST: "/medical-examination",

  MEREFERRALARCHIVED: "/medical-examination/archived-referral-list",
  MEREFERRAL: "/medical-examination/referral",
  MEREFERRALEDIT: (id) => `/medical-examination/referral/${id}`,
  MEREFERRALMODELADD: "/medical-examination/referral-model",
  MEREFERRALMODELEDIT: (id) => `/medical-examination/referral-model/${id}`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
