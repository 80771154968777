import React from "react";
import { connect } from "react-redux";

import Form from "components/RiskAssessment/RiskAssessment.Form";
import { setUrls } from "./_staticData";

import { addBusinessMethod } from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";

class Add extends AppComponent {
  constructor(props) {
    super(props);

    let { urls, names = {} } = this.props;
    this.state = { ...this.state, urls: setUrls(urls) };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { dispatch, history } = this.props;
    addBusinessMethod(values)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/orz");
      })
    );
  }

  render() {
    const { message, urls } = this.state;

    return (
      <>
        <Form
          cancelUrl="/business/orz"
          handleSubmit={this.handleSubmit}
          urls={urls}
        />
      </>
    );
  }
}

export default connect((store) => ({
  // page: store.page,
}))(Add);
