import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FilteredMultiSelect from "react-filtered-multiselect";
import {media} from "../style/style-utils";

const StyledSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex-basis: 45%;
  }
  
  ${media.sm`
    flex-direction:column;
  `}
`;
const StyledTitle = styled.div`
  margin-bottom: 15px;
   ${media.sm`
    padding-top:15px
  `}
`;
const StyledFilteredMultiSelect = styled(FilteredMultiSelect)`
  display: flex;
  flex-direction: column;
`;

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    const initialSelectedOptions = props.selectedOptions || [];
    this.state = {
      selectedOptionsIds: initialSelectedOptions,
      selectedOptions: (props.options || [])
        .filter(item =>
          initialSelectedOptions
            .findIndex(el=>el==item.id) > -1)
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);

  }

  componentWillReceiveProps(nextProps){
    if(JSON.stringify(nextProps.selectedOptions)!==JSON.stringify(this.state.selectedOptionsIds)){
      const initialSelectedOptions = nextProps.selectedOptions || [];
      this.setState({
        selectedOptionsIds: initialSelectedOptions,
        selectedOptions: (nextProps.options || [])
          .filter(item =>
            initialSelectedOptions
              .findIndex(el=>el==item.id) > -1)
      });
    }
  }

  handleChange(data){
    this.props.handleChange(data);
  }

  handleDeselect(deselectedOptions){
    const selectedOptions = this.state.selectedOptions.slice();
    deselectedOptions.forEach(option => {
      selectedOptions.splice(selectedOptions.indexOf(option), 1);
    });
    this.setState({selectedOptions},function () {
      this.handleChange(selectedOptions);
    });
  }

  handleSelect(selectedOptions){
    selectedOptions.sort((a, b) => a.id - b.id);
    this.setState({selectedOptions},function () {
      this.handleChange(selectedOptions);
    });
  }

  render() {
    const {selectedOptions} = this.state;
    const {options = [],leftSelectTitle = "", rightSelectTitle=""} = this.props;
    return (
      <div>
        <StyledSelectWrapper>
          <div>
            <StyledTitle data-multiselect-title>
              {leftSelectTitle}
            </StyledTitle>
            <StyledFilteredMultiSelect
              buttonText="Dodaj"
              placeholder="szukaj..."
              onChange={this.handleSelect}
              options={options}
              selectedOptions={selectedOptions}
              classNames={{
                filter: "form-control",
                select: "form-control",
                button: "btn btn-primary btn-MultiSelect",
                buttonActive: "btn btn-primary add-button"
              }}
              textProp="value"
              valueProp="id"
            />
          </div>
          <div>
            <StyledTitle data-multiselect-title>
              {rightSelectTitle}
            </StyledTitle>

            <StyledFilteredMultiSelect
              buttonText="Usuń"
              placeholder="szukaj..."
              classNames={{
                filter: "form-control",
                select: "form-control",
                button: "btn btn-danger btn-MultiSelect",
                buttonActive: "btn btn-danger add-button"
              }}
              onChange={this.handleDeselect}
              options={selectedOptions}
              textProp="value"
              valueProp="id"
            />
          </div>
        </StyledSelectWrapper>
      </div>);
  }
}

export default MultiSelect;

MultiSelect.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id:PropTypes.number.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  handleChange:PropTypes.func,
  leftSelectTitle:PropTypes.string,
  rightSelectTitle:PropTypes.string
};