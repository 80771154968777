export default function reducer(state = {
  data: [],
  fetching: false,
  fetched: true
}, action) {
  switch (action.type) {
    case "GET_MAILS_REQUEST" : {
      return {...state,fetching:true,fetched:false};
    }
    case "GET_MAILS_SUCCESS" : {
      return {...state,fetching:false,fetched:true,data: action.payload.data.mails};
    }
    case "GET_MAILS_ERROR" : {
      return {...state,fetching:false,fetched:false};
    }
  }
  return state;
}