import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import {
  saveDraftControlList,
  getDraftControlList,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import Box from "components/Box";
import { ButtonWithIcon } from "components/Buttons";

import { FieldInput, FieldTextareaBox } from "components/Fields";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { ButtonWrapper } from "components/Wrappers";
import SideMenu from "../_SideMenu";
import AuditTypeForm from "components/AuditTypes.Form";
import { defaultUrls as urls } from "./_staticData";
let names = {
  TITLE: "Edytuj szablon listy kontrolnej identyfikacji zagrożeń",
};

class Add extends AppComponent {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    const { id } = match.params;

    getDraftControlList(id, { readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  submit(data) {
    let { match, dispatch, history } = this.props;
    let { id } = match.params;
    data.name = data.title;
    saveDraftControlList(
      id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.LIST);
      })
    );
  }

  render() {
    let { message } = this.state;
    const { singleData } = this.props;

    return (
      <AuditTypeForm
        data={{ ...singleData, title: singleData.name }}
        title={names.TITLE}
        SideMenu={<SideMenu />}
        cancelLink={urls.LIST}
        message={message}
        error_code={this.state.error_code == 404}
        handleSubmit={this.submit}
      />
    );
  }
}

export default connect((store) => ({
  singleData: store.riskAssessment.singleData,
}))(Add);
