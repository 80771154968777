import React, { Fragment } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import PropTypes from "prop-types";
import moment from "moment";

import SimpleModal from "../components/Modal/SimpleAccept";
import FormModal from "../components/Modal/FormModal";
import SideMenu from "../components/SideMenu";
import { media } from "../style/style-utils";
import { Field } from "react-final-form";
import { StyledInputBox } from "../style/styleComponents/Form/input";
import { LayoutContent } from "../components/Layout";
import ProfileImage from "./ProfileImage";
import Box from "../components/Box";
import dataToFormData from "./dataToFormData";
import Alert from "react-bootstrap/lib/Alert";
import { StyledInputCheckBox } from "../style/styleComponents/Form/input";
import validator, { composeValidators } from "./Validation/index";
import { TableDefault as Table } from "components/Tables";
import TableSortable from "components/TableSortable";
import { StyledTableList } from "components/ListComponents";

const Block = (props) => <Box {...props} half />;

const CustomButton = styled(Button)`
  ${media.sm`
    width:100%;
    line-height:36px
    `}
`;

const InfoLineBox = styled.div`
  margin-bottom: 10px;
`;
const InfoLineLabel = styled.div`
  margin-bottom: 5px;
`;
const InfoLineValue = styled.div`
  border: 1px solid #ccc;
  line-height: 20px;
  min-height: 36px;
  padding: 6px 12px;
`;

const InfoLine = (props) => (
  <InfoLineBox>
    <InfoLineLabel>{props.label}</InfoLineLabel>
    <InfoLineValue>{props.value || props.children}</InfoLineValue>
  </InfoLineBox>
);

const EmployeesListLabel = styled.div`
  margin-bottom: 5px;
`;

const EmployeesListBox = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 15px;
  ${(props) =>
    props.length &&
    props.length > 6 &&
    `
    overflow-y: scroll;
    max-height: 150px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const EmployeesList = (props) => (
  <div>
    <EmployeesListLabel id="EmployeesListTitle">
      {props.label}
    </EmployeesListLabel>
    <EmployeesListBox length={props.length} id="EmployeesListBody">
      {(props.value || []).map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </EmployeesListBox>
  </div>
);

const ContactDataBox = styled.div`
  margin-bottom: 25px;
`;

const ContactData = (props) => (
  <ContactDataBox>
    <InfoLine label="E-mail" value={props.email} />
    {!props.superAdmin ? (
      <InfoLine label="Nr telefonu" value={props.phone} />
    ) : (
      ""
    )}
  </ContactDataBox>
);

const CheckBoxLineStyle = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
`;
const CheckBoxLineText = styled.span`
  padding-left: 15px;
`;

export const CheckBoxLine = (props) => (
  <CheckBoxLineStyle onClick={props.handleCheckbox}>
    <label
      style={{
        display: "block",
        maxWidth: "auto",
        marginBottom: "0",
        fontWeight: "normal",
        cursor: "pointer",
      }}
    >
      <input
        value={props.value}
        type="checkbox"
        defaultChecked={props.checked}
      />
      <CheckBoxLineText>{props.name}</CheckBoxLineText>
    </label>
  </CheckBoxLineStyle>
);

const ContentBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  margin: 0 -15px;
  & > * {
    padding: 0 15px;
  }
`;

let getDate = (timestamp, nullPlaceholder) => {
  if (!timestamp) {
    return nullPlaceholder ? "bezterminowo" : null;
  }

  return moment(timestamp).format("DD.MM.YYYY");
};

let mockData = [
  {
    type: "Elektryczne dozorowe",
    date: "01.01.2021",
    date_last: "01.01.2022",
  },
  {
    type: "Wózki widlowe",
    date: "01.01.2021",
    date_last: "01.01.2022",
  },
  {
    type: "Prawo jazdy kat B.",
    date: "01.01.2021",
    date_last: "bezterminowo",
  },
  {
    type: "Elektryczne dozorowe",
    date: "01.01.2021",
    date_last: "01.01.2022",
  },
  {
    type: "Elektryczne dozorowe",
    date: "01.01.2021",
    date_last: "01.01.2022",
  },
];

export default class AccountEdit extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.state = {
      ...this.state,
      modalAvatar: {
        open: false,
        handleOpen: this.handleOpenModal.bind(this)("modalAvatar"),
        handleClose: this.handleCloseModal.bind(this)("modalAvatar"),
      },
      modalEdit: {
        open: false,
        handleOpen: this.handleOpenModal.bind(this)("modalEdit"),
        handleClose: this.handleCloseModal.bind(this)("modalEdit"),
      },
      modalPassword: {
        open: false,
        handleOpen: this.handleOpenModal.bind(this)("modalPassword"),
        handleClose: this.handleCloseModal.bind(this)("modalPassword"),
      },
      fetched: false,
      imagePreviewUrl: false,
      defaultImage: "/images/default_avatar.png",
      settings: this.props.settings,
    };

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.fetched &&
      nextProps &&
      nextProps.data &&
      Object.keys(nextProps.data).length
    ) {
      const { data } = nextProps;
      this.setState({
        selected_localization: data.localization_id,
        selected_parent_workplace: data.workplace_parent_id,
        fetched: true,
        imagePreviewUrl: data.avatar_url ? data.avatar_url : null,
      });
    }
  }

  deleteAvatar() {
    this.setState({ modalAvatar: { ...this.state.modalAvatar, open: true } });
  }

  handleOpenModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: true } });
    };
  }

  handleCloseModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: false } });
    };
  }

  handleCheckbox(id, index) {
    return (e) => {
      if (e.target.checked !== undefined) {
        this.props.handleShowSettingChange(id, e.target.checked);
      }
    };
  }

  handleDeleteAvatar() {
    const that = this;
    this.props.handleDeleteAvatar(function () {
      that.setState({ imagePreviewUrl: null });
    });
  }

  handleImageChange(e) {
    e.preventDefault();
    const that = this;
    const { handleAvatarAutoUpload } = this.props;
    let file = e.target.files[0];

    if (handleAvatarAutoUpload) {
      const formData = dataToFormData({ avatar: file });
      handleAvatarAutoUpload(formData, function (url) {
        that.setState({ imagePreviewUrl: url });
      });
    }
  }

  render() {
    const {
      sideMenu = [],
      data = {},
      settings = [],
      superAdmin = false,
      message,
      me = {},
      osh = {},
      qe = [],
    } = this.props;

    const contactFormInitial = superAdmin
      ? {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
        }
      : { email: data.email, phone: data.phone };

    return (
      <div>
        <LayoutContent
          SideMenu={<SideMenu title={"Edycja konta"} data={sideMenu} />}
        >
          {message && message.text && (
            <Alert bsStyle={message.className}>{message.text}</Alert>
          )}
          <ContentBox>
            <Block title="Informacje podstawowe">
              <InfoLine
                label="Imię i nazwisko"
                value={`${data.first_name} ${data.last_name}`}
              />
              {superAdmin ? (
                <ButtonWrapper>
                  <CustomButton
                    style={{ marginBottom: "10px" }}
                    onClick={this.handleOpenModal("modalEdit")}
                  >
                    Zmień dane
                  </CustomButton>
                </ButtonWrapper>
              ) : (
                ""
              )}
              {!superAdmin ? (
                <InfoLine label="Stanowisko" value={data.workplace_name} />
              ) : (
                ""
              )}
              {!superAdmin ? (
                <InfoLine label="Lokacja / dział">
                  {(() => {
                    const {
                      localization_division_name,
                      localization_name,
                      division_name,
                      section_name,
                    } = data;
                    if (localization_name) {
                      return ` ${
                        localization_division_name
                          ? `${localization_division_name} / `
                          : ""
                      }${localization_name}${
                        section_name ? ` / ${section_name}` : ""
                      }`;
                    } else if (division_name) {
                      return division_name;
                    }
                    return "";
                  })()}
                </InfoLine>
              ) : (
                ""
              )}
              {!superAdmin ? (
                <>
                  <InfoLine label="Przełożony">
                    {data.boss_first_name} {data.boss_last_name}
                    {data.boss_workplace_name && data.boss_workplace_name.length
                      ? ` / ${data.boss_workplace_name}`
                      : ""}
                  </InfoLine>

                  <EmployeesList
                    label="Podlegli pracownicy"
                    length={(data.users || []).length}
                    value={data.users || []}
                  />
                </>
              ) : (
                ""
              )}
            </Block>
            <Block title="Edycja konta">
              <ProfileImage
                imageSrc={this.state.imagePreviewUrl}
                handleDelete={this.handleOpenModal("modalAvatar")}
                handleChange={(event) => {
                  this.handleImageChange(event);
                }}
                defaultImage={this.state.defaultImage}
              />
              <ContactData {...data} superAdmin={superAdmin} />
              <ButtonWrapper>
                <CustomButton
                  style={{ marginBottom: "10px" }}
                  onClick={this.handleOpenModal("modalEdit")}
                >
                  Zmień dane kontaktowe
                </CustomButton>
                <CustomButton onClick={this.handleOpenModal("modalPassword")}>
                  Zmień hasło
                </CustomButton>
              </ButtonWrapper>
            </Block>
          </ContentBox>
          {!superAdmin ? (
            <ContentBox>
              <Block title="Ustawienia wyświetlania">
                {settings.map((category = { title: "", value: [] }, index) => {
                  return (
                    <div key={index}>
                      <h4>{category.title}</h4>
                      {category.settings.map((item, subindex) => (
                        <StyledInputCheckBox
                          key={subindex}
                          label={item.name}
                          // value={item.id}
                          onChange={this.handleCheckbox(item.id, index)}
                          defaultChecked={item.value}
                        />
                      ))}
                      {false && index == 1 && (
                        <StyledInputCheckBox
                          label={`Pokaż statystyki Badań profilaktyczne / Szkoleń BHP / Uprawnień kwalifikacyjne`}
                          // value={item.id}
                          onChange={() => {
                            alert("");
                          }}
                          defaultChecked={true}
                        />
                      )}
                    </div>
                  );
                })}
              </Block>
              {true ? (
                <Block title="Badania profilaktyczne / Szkolenia BHP / Uprawnienia kwalifilacyjne">
                  <InfoLine
                    label="Termin następnych badań profilaktycznych"
                    value={getDate(me.date_next)}
                  />
                  <InfoLine
                    label="Termin następnego szkolenia BHP"
                    value={getDate(osh.date_next)}
                  />
                  <h5>Posiadane uprawnienia kwalifikacyjne</h5>
                  <StyledTableList quickMenu quickMenuTrird nowrapTitle>
                    <TableSortable
                      data={qe.filter((i) => i.id)}
                      headers={[
                        { name: "L.p." },
                        {
                          name: "Rodzaj uprawnień",
                          prop: "qe_type_name",
                          sort_type: "string",
                        },
                        { name: "Data nabycia", prop: "date_get" },
                        { name: "Termin Ważności", prop: "date_deadline" },
                      ]}
                      render={(sortData) => {
                        return sortData.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.qe_type_name}</td>
                              <td>{getDate(item.date_get, true)}</td>
                              <td>{getDate(item.date_deadline, true)}</td>
                            </tr>
                          );
                        });
                      }}
                    />
                  </StyledTableList>
                </Block>
              ) : (
                <div style={{ width: "50%" }}></div>
              )}
            </ContentBox>
          ) : (
            ""
          )}
        </LayoutContent>
        <SimpleModal
          {...this.state.modalAvatar}
          title="Usuń awatar"
          description="Czy chcesz usunąć dany wpis?"
          handleAccept={this.handleDeleteAvatar}
        />
        <FormModal
          {...this.state.modalEdit}
          initialValues={contactFormInitial}
          title="Zmień dane kontaktowe"
          description="Opis"
          handleSubmit={this.props.handleSubmitData}
          acceptButtonName="Zapisz"
          cancelButtonName="Anuluj"
        >
          {superAdmin ? (
            <Fragment>
              <Field
                name="first_name"
                validate={composeValidators(validator.required)}
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    label="Imię"
                  />
                )}
              />
              <Field
                name="last_name"
                validate={composeValidators(validator.required)}
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    label="Nazwisko"
                  />
                )}
              />
            </Fragment>
          ) : (
            ""
          )}
          <Field
            name="email"
            validate={composeValidators(
              validator.required,
              validator.mustBeEmail
            )}
            render={({ input, meta, ...rest }) => (
              <StyledInputBox
                {...input}
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                label="E-mail"
              />
            )}
          />
          {!superAdmin ? (
            <Field
              name="phone"
              render={({ input, meta, ...rest }) => (
                <StyledInputBox {...input} label="Nr telefonu" />
              )}
            />
          ) : (
            ""
          )}
        </FormModal>
        <FormModal
          {...this.state.modalPassword}
          title="Zmień hasło"
          description="Opis"
          acceptButtonName="Zapisz"
          cancelButtonName="Anuluj"
          handleSubmit={this.props.handleSubmitNewPassword}
          validate={(values) => {
            const errors = {};
            if (values) {
              const repeatPassword = validator.mustBeRepeatedPassword(
                values.password,
                values.repeatPassword
              );
              if (repeatPassword) {
                errors.repeatPassword = repeatPassword;
              }
            }

            return Object.keys(errors).length ? errors : undefined;
          }}
        >
          <Field
            name="oldPassword"
            validate={composeValidators(
              validator.required,
              validator.mustBePassword
            )}
            render={({ input, meta, ...rest }) => (
              <StyledInputBox
                {...input}
                label="Stare hasło"
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                type="password"
              />
            )}
          />
          <Field
            name="password"
            validate={composeValidators(
              validator.required,
              validator.mustBePassword
            )}
            render={({ input, meta, ...rest }) => (
              <StyledInputBox
                {...input}
                label="Nowe hasło"
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                type="password"
              />
            )}
          />
          <Field
            name="repeatPassword"
            validate={composeValidators(validator.required)}
            render={({ input, meta, ...rest }) => (
              <StyledInputBox
                {...input}
                label="Powtórz nowe hasło"
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                type="password"
              />
            )}
          />
        </FormModal>
      </div>
    );
  }
}

CheckBoxLine.propType = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

ContactData.propType = {
  email: PropTypes.string,
  phone: PropTypes.string,
};

InfoLine.propType = {
  label: PropTypes.string,
  value: PropTypes.string,
};
//
ProfileImage.propTypes = {
  imageSrc: PropTypes.string,
  handleDeleteButton: PropTypes.func,
};
//
EmployeesList.propType = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

AccountEdit.propType = {
  sideMenu: PropTypes.array,
  data: PropTypes.object,
  handleDeleteAvatar: PropTypes.func.isRequired,
  handleChangeAvatar: PropTypes.func.isRequired,
  handleSubmitData: PropTypes.func.isRequired,
  handleSubmitNewPassword: PropTypes.func.isRequired,
};
