import React from "react";

import { connect } from "react-redux";

import {
  addDraftRAControlList,
  getDraftControlListList,
  getDraftRAControlList,
  deleteDraftRAControlList,
  getDraftRAControlListQuestions,
  saveDraftRAControlListQuestions,
  addSARACLQuestionAttachment,
  deleteSARACLQuestionAttachment,
  downloadRAQuestionAttachment,
  download,
} from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";

import Danger from "components/Danger";

import SideMenu from "../_SideMenu";
import saveBlobFile from "components/saveBlobFile";

let urls = {
  CHARACTERISTIC: (id) =>
    `/super-admin/draft/risk-assessment/characteristic/${id}`,
};

class DangerList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalControlForm: {
        open: false,
      },
    };

    this.addNewDanger = this.addNewDanger.bind(this);
    this.deleteDanger = this.deleteDanger.bind(this);
    this.getRaControlListQuestion = this.getRaControlListQuestion.bind(this);
    this.saveQuestions = this.saveQuestions.bind(this);
    // this.download = this.download.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;

    let { id } = match.params;

    getDraftControlListList()(dispatch);
    getDraftRAControlList(id)(dispatch);
  }

  addNewDanger(id) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;
    addDraftRAControlList(ra_id, { id })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        getDraftRAControlList(ra_id)(dispatch);
        this.setSuccessMessage(action.payload.data.message);
      })
    );
  }

  getRaControlListQuestion(id) {
    let { dispatch } = this.props;
    getDraftRAControlListQuestions(id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  saveQuestions(data, callback = () => {}) {
    let { match, dispatch } = this.props;
    let { id } = match.params;

    let segregateInObj = Object.keys(data)
      .filter((key) => /^question\_[0-9]{1,}\_[A-Za-z]+/.test(key))
      .reduce((obj, key) => {
        let keySplited = key.split("_");

        let id = keySplited[1];
        let prop = keySplited[2];
        if (!obj[id]) obj[id] = {};
        obj[id][prop] = data[key];
        return obj;
      }, {});

    let sendData = Object.keys(segregateInObj).map((key) => {
      let newData = { ...segregateInObj[key], id: parseInt(key) };

      return newData;
    });

    saveDraftRAControlListQuestions(id, { questions: sendData })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        callback();
      })
    );
  }

  deleteDanger(id) {
    const { dispatch } = this.props;
    deleteDraftRAControlList(id)(dispatch).then(this.handleServerResponse());
  }

  // download(file, name, attachmentIndex, index, subIndex) {
  //   return () => {
  //     const {
  //       dispatch,
  //       audit: {
  //         singleData: { symbol },
  //       },
  //       match,
  //     } = this.props;
  //     const { audit_id } = match.params;
  //     const url = `/uploads/ra/${audit_id}/attachment/${file}`;
  //     const ext = file.split(".").pop();
  //     downloadRAQuestionAttachment(
  //       url,
  //       attachmentIndex,
  //       index,
  //       subIndex
  //     )(this.props.dispatch).then((res) => {
  //       if (!res.error) {
  //         const file = res.payload.data;
  //         const fileName = `${symbol}-${name}.${ext}`;
  //         saveBlobFile(file, fileName);
  //       }
  //     });
  //   };
  // }
  render() {
    let { message } = this.state;
    let { controlList, match, data, questions } = this.props;
    let { id } = match.params;

    return (
      <Danger
        draft
        addAttachmentAction={addSARACLQuestionAttachment}
        downloadAction={download}
        addNewDanger={this.addNewDanger}
        controlList={controlList}
        data={data}
        deleteAttachmentAction={deleteSARACLQuestionAttachment}
        deleteDanger={this.deleteDanger}
        getQuestions={this.getRaControlListQuestion}
        id={match.params.id}
        message={message}
        questions={questions}
        saveQuestions={this.saveQuestions}
        SideMenu={<SideMenu id={id} />}
        urls={urls}
      />
    );
  }
}

export default connect((store) => ({
  data: store.riskAssessment.raControlList,
  controlList: store.riskAssessment.controlList,
  questions: store.riskAssessment.questions,
  answers: store.riskAssessment.answers,
}))(DangerList);
