export function sendResetRequest(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "RESET_PASSWORD_USER_REQUEST",
        "RESET_PASSWORD_USER_SUCCESS",
        "RESET_PASSWORD_USER_ERROR",
      ],
      payload: {
        request: {
          url: "/reset-password",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function login(data) {
  return (dispatch) =>
    dispatch({
      types: ["LOGIN_USER_REQUEST", "LOGIN_USER_SUCCESS", "LOGIN_USER_ERROR"],
      payload: {
        request: {
          url: "/login",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function logout(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "LOGOUT_USER_REQUEST",
        "LOGOUT_USER_SUCCESS",
        "LOGOUT_USER_ERROR",
      ],
      payload: {
        request: {
          url: "/logout",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function sendNewPassword(token, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "RESET_PASSWORD_CONFIRM_USER_REQUEST",
        "RESET_PASSWORD_CONFIRM_USER_SUCCESS",
        "RESET_PASSWORD_CONFIRM_USER_ERROR",
      ],
      payload: {
        request: {
          url: "/reset-password/" + token,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addUser(data) {
  return (dispatch) =>
    dispatch({
      types: ["ADD_USER_REQUEST", "ADD_USER_SUCCESS", "ADD_USER_ERROR"],
      payload: {
        request: {
          url: "/business/users-accounts/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function publicAddUser(data) {
  return (dispatch) =>
    dispatch({
      types: ["ADD_USER_REQUEST", "ADD_PUBLIC_USER_SUCCESS", "ADD_USER_ERROR"],
      payload: {
        request: {
          url: "/users/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function publicEditUser(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_USER_REQUEST",
        "EDIT_PUBLIC_USER_SUCCESS",
        "EDIT_USER_ERROR",
      ],
      payload: {
        request: {
          url: `/users/edit/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function editUser(user_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["EDIT_USER_REQUEST", "EDIT_USER_SUCCESS", "EDIT_USER_ERROR"],
      payload: {
        request: {
          url: `/business/users-accounts/edit/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editUserPublic(user_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["EDIT_USER_REQUEST", "EDIT_USER_SUCCESS", "EDIT_USER_ERROR"],
      payload: {
        request: {
          url: `/users-accounts/edit/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getUsers() {
  return (dispatch) =>
    dispatch({
      types: ["GET_USERS_REQUEST", "GET_USERS_SUCCESS", "GET_USERS_ERROR"],
      payload: {
        request: {
          url: "/business/users-accounts",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getPublicUserBasics(user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_PUBLIC_USER_BASIC_REQUEST",
        "GET_PUBLIC_USER_BASIC_SUCCESS",
        "GET_PUBLIC_USER_BASIC_ERROR",
      ],
      payload: {
        request: {
          url: `/users/get-user/${user_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getPublicUserToIncident(user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_PUBLIC_USER_BASIC_REQUEST",
        "GET_PUBLIC_USER_BASIC_SUCCESS",
        "GET_PUBLIC_USER_BASIC_ERROR",
      ],
      payload: {
        request: {
          url: `/users/get-user/incident-analysis/${user_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUsersNames() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_USERS_NAMES_REQUEST",
        "GET_USERS_NAMES_SUCCESS",
        "GET_USERS_NAMES_ERROR",
      ],
      payload: {
        request: {
          url: "/users/get-names-only",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserByWorkplace(workplace_id, not_user_id, not_user_ids) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_USERS_NOFETCH_REQUEST",
        "GET_USERS_NOFETCH_SUCCESS",
        "GET_USERS_NOFETCH_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/get-by-workplace/${workplace_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            not_user_id,
            not_user_ids,
          },
        },
      },
    });
}

export function getUserByWorkplaceByUser(
  workplace_id,
  not_user_id,
  not_user_ids
) {
  return (dispatch) =>
    dispatch({
      types: ["GET_USERS_REQUEST", "GET_USERS_SUCCESS", "GET_USERS_ERROR"],
      payload: {
        request: {
          url: `/users-accounts/get-by-workplace/${workplace_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            not_user_id,
            not_user_ids,
          },
        },
      },
    });
}

export function getUserByWorkplaceByUserOnParentField(
  workplace_id,
  not_user_id,
  not_user_ids
) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_PARENT_USERS_REQUEST",
        "GET_PARENT_USERS_SUCCESS",
        "GET_PARENT_USERS_ERROR",
      ],
      payload: {
        request: {
          url: `/users-accounts/get-by-workplace/${workplace_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            not_user_id,
            not_user_ids,
          },
        },
      },
    });
}

export function getUser(user_id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_USER_REQUEST", "GET_USER_SUCCESS", "GET_USER_ERROR"],
      payload: {
        request: {
          url: `/business/users-accounts/${user_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserPublic(user_id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_USER_REQUEST", "GET_USER_SUCCESS", "GET_USER_ERROR"],
      payload: {
        request: {
          url: `/users-accounts/${user_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteUser(user_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_REQUEST",
        "DELETE_USER_SUCCESS",
        "DELETE_USER_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/delete/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getUsersByFilter(data) {
  return (dispatch) =>
    dispatch({
      types: ["GET_USERS_REQUEST", "GET_USERS_SUCCESS", "GET_USERS_ERROR"],
      payload: {
        request: {
          url: `/business/users-accounts`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function saveAvatar(user_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_USERS_AVATAR_REQUEST",
        "SAVE_USERS_AVATAR_SUCCESS",
        "SAVE_USERS_AVATAR_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/save-avatar/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function saveAvatarPublic(user_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_USERS_AVATAR_REQUEST",
        "SAVE_USERS_AVATAR_SUCCESS",
        "SAVE_USERS_AVATAR_ERROR",
      ],
      payload: {
        request: {
          url: `/users-accounts/save-avatar/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteAvatar(user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USERS_AVATAR_REQUEST",
        "DELETE_USERS_AVATAR_SUCCESS",
        "DELETE_USERS_AVATAR_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/delete-avatar/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteAvatarPublic(user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USERS_AVATAR_REQUEST",
        "DELETE_USERS_AVATAR_SUCCESS",
        "DELETE_USERS_AVATAR_ERROR",
      ],
      payload: {
        request: {
          url: `/users-accounts/delete-avatar/${user_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getSearchedUsers(name) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SEARCHED_USERS_REQUEST",
        "GET_SEARCHED_USERS_SUCCESS",
        "GET_SEARCHED_USERS_ERROR",
      ],
      payload: {
        request: {
          url: `/users-accounts/get-by-name`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            name: name,
          },
        },
      },
    });
}

export function getUsersToTaskFilter(name) {
  return (dispatch) =>
    dispatch({
      types: ["GET_USERS_REQUEST", "GET_USERS_SUCCESS", "GET_USERS_ERROR"],
      payload: {
        request: {
          url: "/users-accounts/get-all-responsibility-users",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            name: name,
          },
        },
      },
    });
}

export function checkResetPasswordToken(token) {
  return (dispatch) =>
    dispatch({
      types: [
        "RESET_PASSWORD_TOKEN_CHECK_REQUEST",
        "RESET_PASSWORD_TOKEN_CHECK_SUCCESS",
        "RESET_PASSWORD_TOKEN_CHECK_ERROR",
      ],
      payload: {
        request: {
          url: "/reset-password/" + token,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getHiredStatuses(token) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_HIRED_STATUSES_REQUEST",
        "GET_HIRED_STATUSES_SUCCESS",
        "GET_HIRED_STATUSES_ERROR",
      ],
      payload: {
        request: {
          url: "/hired-status/get",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function savePreventiveMedicalExaminations(user_id, data, id) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_PREVENTIVE_MEDICAL_EXAMINATIONS_REQUEST",
        "SAVE_PREVENTIVE_MEDICAL_EXAMINATIONS_SUCCESS",
        "SAVE_PREVENTIVE_MEDICAL_EXAMINATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/save-preventive-medical-examinations/${user_id}`,
          method: "post",
          data: {
            id,
            data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveOSHTraining(user_id, data, id) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_OSH_TRAINING_REQUEST",
        "SAVE_OSH_TRAINING_SUCCESS",
        "SAVE_OSH_TRAINING_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/save-osh-training/${user_id}`,
          method: "post",
          data: {
            id,
            data,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deletePreventiveMedicalExaminations(user_id, id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_PREVENTIVE_MEDICAL_EXAMINATIONS_REQUEST",
        "DELETE_PREVENTIVE_MEDICAL_EXAMINATIONS_SUCCESS",
        "DELETE_PREVENTIVE_MEDICAL_EXAMINATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/delete-preventive-medical-examinations/${user_id}`,
          method: "post",
          data: {
            id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteOSHTraining(user_id, id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_OSH_TRAINING_REQUEST",
        "DELETE_OSH_TRAINING_SUCCESS",
        "DELETE_OSH_TRAINING_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/delete-osh-training/${user_id}`,
          method: "post",
          data: {
            id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveExtraTrainings(user_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_EXTRA_OSH_TRAINING_REQUEST",
        "SAVE_EXTRA_OSH_TRAINING_SUCCESS",
        "SAVE_EXTRA_OSH_TRAINING_ERROR",
      ],
      payload: {
        request: {
          url: `/business/users-accounts/save-extra-osh-training/${user_id}`,
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserPermissions() {
  return (dispatch) =>
    dispatch({
      types: ["GET_USER_REQUEST", "GET_USER_SUCCESS", "GET_USER_ERROR"],
      payload: {
        request: {
          url: "/business/users-accounts/permissions",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacePermissions({ workplace_id }) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACE_PERMISSION_REQUEST",
        "GET_WORKPLACE_PERMISSION_SUCCESS",
        "GET_WORKPLACE_PERMISSION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/get-permission/${workplace_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function clearUsersReducer() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_USERS_REDUCER",
      payload: {},
    });
}

export function clearUsersSingleData() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_USERS_SINGLEDATA",
      payload: {},
    });
}
