import React from "react";
import styled from "styled-components";
import { FormSpy } from "react-final-form";

import { Button } from "components/Buttons";
import { LeftPadding } from "components/Wrappers";
import { TableListPage } from "components/Tables";
import { FieldInputCheckBox } from "components/Fields";
import { Center } from "components/Wrappers";
import PermissionList, {
  initialPermissionValues,
} from "components/Workplace.form/permissionsList";
let FlexLine = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
  }
`;

export default class Permissions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.checkAllPermissionInGroup = this.checkAllPermissionInGroup.bind(this);
  }

  checkAllPermissionInGroup(fields, allChecked) {
    let { form } = this.props;
    return () => {
      if (allChecked) {
        form.mutators.mutatorSetFalse(fields);
      } else {
        form.mutators.mutatorSetTrue(fields);
      }
    };
  }

  render() {
    let { modulePermissions = [] } = this.props;

    return (
      <LeftPadding>
        <h3>Uprawnienia użytkownika</h3>
        <FormSpy subscribe={{ values: true }}>
          {({ values: formValues }) => {
            return (
              <PermissionList
                formValues={formValues}
                permissions={modulePermissions}
                checkAllPermissionInGroup={this.checkAllPermissionInGroup}
              />
            );
          }}
        </FormSpy>

        <h3>Dostęp do danych osobowych</h3>
        <FlexLine style={{ display: "flex" }}>
          <FieldInputCheckBox
            name="permission_to_personal_data"
            label="Dane ogólne"
          />
          <FieldInputCheckBox
            name="permission_to_sensitive_data"
            label="Dane szczegółowe"
          />
        </FlexLine>
      </LeftPadding>
    );
  }
}
