export function getAccidentList() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_LIST_REQUEST",
        "GET_ACCIDENT_OWTW_LIST_SUCCESS",
        "GET_ACCIDENT_OWTW_LIST_ERROR",
      ],
      payload: {
        request: {
          url: "/accident-owtw/list",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAccidentListByFilter(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_LIST_REQUEST",
        "GET_ACCIDENT_OWTW_LIST_SUCCESS",
        "GET_ACCIDENT_OWTW_LIST_ERROR",
      ],
      payload: {
        request: {
          url: "/accident-owtw/list",
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAccident(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_REQUEST",
        "GET_ACCIDENT_OWTW_SUCCESS",
        "GET_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAccidentToRegister(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_REQUEST",
        "GET_ACCIDENT_OWTW_SUCCESS",
        "GET_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/get-register-data/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addDraft() {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_ACCIDENT_OWTW_REQUEST",
        "ADD_ACCIDENT_OWTW_SUCCESS",
        "ADD_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: "/accident-owtw/add-draft",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteDraft(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_ACCIDENT_OWTW_REQUEST",
        "DELETE_ACCIDENT_OWTW_SUCCESS",
        "DELETE_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/delete-draft/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function registerAccident(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_ACCIDENT_OWTW_REQUEST",
        "REGISTER_ACCIDENT_OWTW_SUCCESS",
        "REGISTER_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/register/${accident_id}`,
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function forwardAccident(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "FORWARD_ACCIDENT_OWTW_REQUEST",
        "FORWARD_ACCIDENT_OWTW_SUCCESS",
        "FORWARD_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/forward/${accident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function saveAnalysis(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_ANALYSIS_ACCIDENT_OWTW_REQUEST",
        "SAVE_ANALYSIS_ACCIDENT_OWTW_SUCCESS",
        "SAVE_ANALYSIS_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/save-analysis/${accident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveAnalysisData(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_ANALYSIS_DATA_ACCIDENT_OWTW_REQUEST",
        "SAVE_ANALYSIS_DATA_ACCIDENT_OWTW_SUCCESS",
        "SAVE_ANALYSIS_DATA_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/analysis/${accident_id}/save-only`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getIncidentToAnalysis(accident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_REQUEST",
        "GET_ACCIDENT_OWTW_SUCCESS",
        "GET_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/get-to-analysis/${accident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveAccidentWitness({ id, data }) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_ACCIDENT_OWTW_WITNESS_REQUEST",
        "SAVE_ACCIDENT_OWTW_WITNESS_SUCCESS",
        "SAVE_ACCIDENT_OWTW_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/save-witness/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function generateWitness({ id, witness_id }) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/accident-owtw/generate-witness/${id}/${witness_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function getWitnesses(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_WITNESS_REQUEST",
        "GET_ACCIDENT_OWTW_WITNESS_SUCCESS",
        "GET_ACCIDENT_OWTW_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/get-witness/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteWitness({ accident_id, id }) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_ACCIDENT_OWTW_WITNESS_REQUEST",
        "DELETE_ACCIDENT_OWTW_WITNESS_SUCCESS",
        "DELETE_ACCIDENT_OWTW_WITNESS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/delete-witness/${accident_id}/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveVictim(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_ACCIDENT_OWTW_VICTIM_REQUEST",
        "SAVE_ACCIDENT_OWTW_VICTIM_SUCCESS",
        "SAVE_ACCIDENT_OWTW_VICTIM_ERROR",
      ],
      payload: {
        request: {
          data,
          url: `/accident-owtw/update-victim/${accident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveVictimExplain(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_ACCIDENT_OWTW_VICTIM_REQUEST",
        "SAVE_ACCIDENT_OWTW_VICTIM_SUCCESS",
        "SAVE_ACCIDENT_OWTW_VICTIM_ERROR",
      ],
      payload: {
        request: {
          data,
          url: `/accident-owtw/update-victim-explain/${accident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function generateVictimExplain(id) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/accident-owtw/generate-victim-explain/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function getVictim(accident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_VICTIM_REQUEST",
        "GET_ACCIDENT_OWTW_VICTIM_SUCCESS",
        "GET_ACCIDENT_OWTW_VICTIM_ERROR",
      ],
      payload: {
        request: {
          url: `/accidents-owtw/get-victim/${accident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function generateAccidentCard(id) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/accident-owtw/generate-accident-card/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

//załączniki

export function getAttachments(accident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_ATTACHMENTS_REQUEST",
        "GET_ACCIDENT_OWTW_ATTACHMENTS_SUCCESS",
        "GET_ACCIDENT_OWTW_ATTACHMENTS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/attachment/${accident_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRegisterAttachments(accident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_ACCIDENT_OWTW_ATTACHMENTS_REQUEST",
        "GET_ACCIDENT_OWTW_ATTACHMENTS_SUCCESS",
        "GET_ACCIDENT_OWTW_ATTACHMENTS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/attachment/${accident_id}/register`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addAttachment(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_ACCIDENT_OWTW_ATTACHMENT_REQUEST",
        "ADD_ACCIDENT_OWTW_ATTACHMENT_SUCCESS",
        "ADD_ACCIDENT_OWTW_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/attachment/${accident_id}/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function addAttachmentRegister(accident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_ACCIDENT_OWTW_ATTACHMENT_REQUEST",
        "ADD_ACCIDENT_OWTW_ATTACHMENT_SUCCESS",
        "ADD_ACCIDENT_OWTW_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/attachment/${accident_id}/register/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteAttachment(accident_id, attachment_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_ACCIDENT_OWTW_ATTACHMENT_REQUEST",
        "DELETE_ACCIDENT_OWTW_ATTACHMENT_SUCCESS",
        "DELETE_ACCIDENT_OWTW_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/attachment/${accident_id}/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

//powiadomienia
export function getNotification(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATIONS_REQUEST",
        "GET_NOTIFICATIONS_SUCCESS",
        "GET_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
export function getHistory(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATION_HISTORY_REQUEST",
        "GET_NOTIFICATION_HISTORY_SUCCESS",
        "GET_NOTIFICATION_HISTORY_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${id}/history`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addUserToRegisterGroup(id, user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_USER_TO_NOTIFICATION_GROUP_REQUEST",
        "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS",
        "ADD_USER_TO_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${id}/add-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: { user_id },
        },
      },
    });
}

export function deleteUserFromRegisterGroup(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_FROM_NOTIFICATION_GROUP_REQUEST",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_SUCCESS",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${id}/delete-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerNotification(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_NOTIFICATIONS_REQUEST",
        "ADD_NOTIFICATIONS_SUCCESS",
        "ADD_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${id}/add-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerNotifications(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_NOTIFICATIONS_REQUEST",
        "REGISTER_NOTIFICATIONS_SUCCESS",
        "REGISTER_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteRegisteredNotification(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_NOTIFICATION_REQUEST",
        "DELETE_USER_NOTIFICATION_SUCCESS",
        "DELETE_USER_NOTIFICATION_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/notification/${incident_id}/delete-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}
//end powiadomienia
export function printListPDF(incident_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/accident-owtw/print/${incident_id}`,
          method: "post",
          data,
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        },
      },
    });
}

export function download(url, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_ACCIDENT_OWTH_REQUEST",
        "DOWNLOAD_ACCIDENT_OWTH_SUCCESS",
        "DOWNLOAD_ACCIDENT_OWTH_ERROR",
      ],
      payload: {
        request: {
          url: url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            index,
          },
        },
      },
    });
}

export function closeAccident(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "REALIZATION_ACCIDENT_OWTW_REQUEST",
        "REALIZATION_ACCIDENT_OWTW_REQUEST",
        "REALIZATION_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/close/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

//rest
export function setNextState(id, current_stage_name) {
  return (dispatch) =>
    dispatch({
      types: [
        "NEXT_STAGE_ACCIDENT_OWTW_REQUEST",
        "NEXT_STAGE_ACCIDENT_OWTW_SUCCESS",
        "NEXT_STAGE_ACCIDENT_OWTW_ERROR",
      ],
      payload: {
        request: {
          url: `/accident-owtw/set-next-stage/${id}/${current_stage_name}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getState(id) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/accident-owtw/get-stage/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
