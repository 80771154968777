import React from "react";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { TableListPage } from "components/Tables";
import { FieldInputCheckBox } from "components/Fields";
import { Center } from "components/Wrappers";

const StyledTable = styled(Table)`
  th,
  td {
    text-align: center;
    vertical-align: center;
    &:first-child,
    &:last-child {
      padding: 8px;
      text-align: left;
    }
    &:nth-child(6) {
      width: 70px;
      text-align: center;
    }
  }
  &.table > thead > tr > th {
    vertical-align: top;
    font-size: 12px;
    font-weight: 700;
    &:last-child {
      text-align: center;
    }
  }
`;

const StyledTableWrapper = styled.div`
  overflow-x: auto;
`;

const localHandleCheckAll = (fn) => (index) => () => {
  fn(index);
};

const permissionsBuilder = (permission) => {
  const defaultP = {
    title: "",
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  return { ...defaultP, ...permission };
};

export let initialPermissionValues = (modulePermissions) => {
  if (!modulePermissions || !modulePermissions.length) {
    return {};
  }
  return modulePermissions.reduce((obj, item) => {
    const newPermissions = item.permissions.reduce((obj, permission) => {
      if (permission.id) {
        obj[`permission_${permission.id}`] = permission.active || undefined;
      }
      return obj;
    }, {});

    return { ...obj, ...newPermissions };
  }, {});
};

class PemissionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      permissions: (props.data || []).map(permissionsBuilder),
    };
  }

  componentWillReceiveProps() {}

  checkAllPermissionInGroup(index) {
    return () => {
      const permissions = [...this.state.permissions];
      if (
        permissions[index].create &&
        permissions[index].read &&
        permissions[index].update &&
        permissions[index].delete
      ) {
        permissions[index] = {
          ...permissions[index],
          create: false,
          read: false,
          update: false,
          delete: false,
        };
      } else {
        permissions[index] = {
          ...permissions[index],
          create: true,
          read: true,
          update: true,
          delete: true,
        };
      }
      this.setState({ permissions: permissions }, () => {
        this.handleChange();
      });
    };
  }

  handleCheckbox(index, key) {
    return (event) => {
      const permissions = [...this.state.permissions];
      const permission = { ...permissions[index] };
      permission[key] = !permission[key];
      permissions[index] = permission;

      this.setState({ permissions: permissions }, () => {
        this.handleChange();
      });
    };
  }

  handleChange() {
    const { handleChange } = this.props;
    handleChange(this.state.permissions);
  }

  render() {
    const props = this.props;
    const {
      data = [],
      handleChange = () => {},
      handleCheckAll = () => {},
      permissions: modulePermissions = [],
      formValues,
    } = props;
    return (
      <>
        <div style={{ padding: "20px" }}>
          <TableListPage>
            <thead>
              <th>Nazwa modułu</th>
              <th>Czynność</th>
              <th>Wszystko</th>
            </thead>
            <tbody>
              {modulePermissions.map((item) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>
                      {item.permissions.map((permission) => (
                        <FieldInputCheckBox
                          name={`permission_${permission.id}`}
                          label={permission.name}
                          value={item.active}
                        />
                      ))}
                    </td>
                    <td>
                      <Center>
                        <Button
                          style={{ minWidth: "140px" }}
                          onClick={this.props.checkAllPermissionInGroup(
                            item.permissions
                              .filter((permission) => permission.id)
                              .map(
                                (permission) => `permission_${permission.id}`
                              ),
                            item.permissions
                              .filter((permission) => permission.id)
                              .map(
                                (permission) =>
                                  formValues[`permission_${permission.id}`]
                              )
                              .reduce((bool, next) => bool && next, true)
                          )}
                        >
                          {item.permissions
                            .filter((permission) => permission.id)
                            .map(
                              (permission) =>
                                formValues[`permission_${permission.id}`]
                            )
                            .reduce((bool, next) => bool && next, true)
                            ? "Odznacz wszystko"
                            : "Zaznacz wszystko"}
                        </Button>
                      </Center>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableListPage>
        </div>
      </>
    );
  }
}

export default PemissionList;

PemissionList.propTypes = {
  handleChange: PropTypes.func,
};
