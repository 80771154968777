import React from "react";
import { connect } from "react-redux";

import {
  saveRA,
  updateRA,
  getRAMethods,
  getRA,
  getRAShadow,
  getRAPermission,
  getNextSymbol,
  getRADrafts,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import BasicForm from "components/RiskAssessment.Forms/Basic/index.js";

import SideMenu from "./_SideMenu";

import { setUrls } from "../_staticData";

export class Add extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      urls: setUrls(),
      update: false,
      draft: false,
    };

    this.submit = this.submit.bind(this);
    this.getRAData = this.getRAData.bind(this);
    this.renderSideMenu = this.renderSideMenu.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    getRAMethods({ readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
    getRADrafts({ readOnly: true })(dispatch).then(this.handleServerResponse());

    if (id) {
      getRA(id)(dispatch).then(this.handleServerResponse());
    } else {
      getRAPermission()(dispatch).then(this.handleServerResponse());
      getNextSymbol({ update: this.state.update })(dispatch).then(
        this.handleServerResponse({}, (action) => {
          this.setState({ nextSymbol: action.payload.data.data });
        })
      );
    }
  }

  getRAData(id, cb = () => {}) {
    let { dispatch } = this.props;
    getRAShadow(id, { readOnly: true })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        cb(action.payload.data.data);
      })
    );
  }

  submit(values) {
    let { urls, draft } = this.state;
    let { history, dispatch, match } = this.props;
    let { id = null } = match.params;
    values = { ...values, draft };
    saveRA(
      id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        if (action.payload.data) {
          let { data } = action.payload.data;
          history.push(urls.DANGER(data.id));
        }
      })
    );
  }

  renderSideMenu() {
    let {
      riskAssessment: { singleData },
      match,
    } = this.props;
    let { id } = match.params;
    return <SideMenu id={id} stage={singleData.stage} />;
  }

  render() {
    let { urls, update, readOnlyState, message } = this.state;
    let {
      riskAssessment: { methods = [], singleData, permission, raDraftList },
      match,
    } = this.props;
    let { id } = match.params;

    return (
      <BasicForm
        message={message}
        getRAData={this.getRAData}
        raDraftList={raDraftList}
        update={update}
        urls={urls}
        permission={permission}
        id={match.params.id}
        data={
          id ? singleData : { ...singleData, symbol: this.state.nextSymbol }
        }
        methods={methods}
        SideMenu={this.renderSideMenu()}
        submit={this.submit}
        readOnly={readOnlyState || !permission[21]}
        readOnlyState={readOnlyState}
      />
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

class UpdateC extends Add {
  constructor(props) {
    super(props);
    this.state = { ...this.state, update: true };
  }

  submit(values) {
    let { urls } = this.state;
    let { history, dispatch, match } = this.props;
    let { id = null } = match.params;
    updateRA(
      id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        if (action.payload.data) {
          let { data } = action.payload.data;
          history.push(urls.DANGER(data.id));
        }
      })
    );
  }
}

export let Basic = connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(Add);

export let Update = connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(UpdateC);
