import React from "react";

import { ModelComponent } from "components/AppComponent";
import { AddButton, DeleteButton } from "components/Buttons";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { ButtonWrapper } from "components/Wrappers";
import PageHeader from "components/PageHeader";
import Pagination from "components/Pagination";
import Link from "react-router-dom/Link";
import LoadingScreen from "components/LoadingScreen";

import { TableListPage } from "components/Tables";
import { TableSortableWithPagination } from "components/TableSortableWithPagination";
import Modal from "components/Modal/SimpleAccept";

let defaultNames = {
  TITLE: "Listy kontrolne identyfikacji zagrożeń",
  ADD_BUTTON: "Dodaj nowy szablon listy kontrolnej",
  DELETE_MODAL_TITLE: "Usuń listę kontrolną identyfikacji zagrożeń",
  DELETE_MODAL_DESCRIPTION: "Czy chcesz usunąć dany wpis?",
};

let defaultUrls = {
  ADD: "/",
  EDIT: () => "",
};

export default class List extends ModelComponent {
  constructor(props) {
    super(props);
    let { urls = {}, names = {} } = this.props;
    this.state = {
      ...this.state,
      urls: { ...defaultUrls, ...urls },
      names: { ...defaultNames, ...names },
      modal: this.initModalState(),
    };

    this.deleteEntry = this.deleteEntry.bind(this);
  }

  deleteEntry() {
    let { deleteEntry } = this.props;
    let { entry_id } = this.state.modal;
    deleteEntry(entry_id);
  }

  render() {
    let { urls, names, modal } = this.state;
    let {
      SideMenu,
      message,
      data = [],
      permission = {},
      id,
      business_id,
      superAdmin,
    } = this.props;

    return (
      <LoadingScreen fetched={true}>
        <LayoutContent
          error404={this.state.error_code == 404}
          message={message}
          SideMenu={<SideMenu id={id} stage={data.stage} />}
        >
          <PageHeader>{names.TITLE}</PageHeader>
          <ButtonWrapper>
            <LinkContainer to={urls.ADD}>
              <AddButton
                disabled={!superAdmin && !permission[32]}
                name={names.ADD_BUTTON}
              />
            </LinkContainer>
          </ButtonWrapper>
          <TableSortableWithPagination
            data={data}
            headers={[
              { name: "L.p" },
              { name: "Nazwa", prop: "name", sort_type: "string" },
              { name: "Usuń" },
            ]}
          >
            {(data) => {
              return data.map((item, index) => {
                const readOnly =
                  !superAdmin && (item.business_id === 0 || !permission[34]);

                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={urls.EDIT(item.id)}>{item.name}</Link>
                    </td>
                    <td>
                      <DeleteButton
                        disabled={readOnly}
                        onClick={this.openModal("modal", { entry_id: item.id })}
                        name="Usuń"
                      />
                    </td>
                  </tr>
                );
              });
            }}
          </TableSortableWithPagination>
        </LayoutContent>
        <Modal
          title={defaultNames.DELETE_MODAL_TITLE}
          description={defaultNames.DELETE_MODAL_DESCRIPTION}
          open={modal.open}
          handleAccept={this.deleteEntry}
          handleClose={this.closeModal("modal", this.initModalState)}
        />
      </LoadingScreen>
    );
  }
}
