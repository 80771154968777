import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

// import { ButtonWrapper } from "./ListComponents";

import {
  FieldInputCheckBox,
  FieldDayPicker,
  FieldSelect,
} from "components/Fields";
import { LinkContainer } from "components/LinkContainer";

import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";
import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
} from "components/Wrappers";
import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";

import { getDate } from "../_common";
import MESegment from "components/MedicalExamination/MEUserSegment";
const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export default class SummaryMedicalExaminationsModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let {
      open,
      data,
      addME = () => {},
      editME,
      dictionary,
      permission,
    } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Podsumowanie badań profilaktycznych pracownika</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h4>Dane ogólne</h4>
            <StyledInputBox
              label="Imię"
              value={data.first_name}
              disabled={true}
            />
            <StyledInputBox
              label="Nazwisko"
              value={data.last_name}
              disabled={true}
            />
            <StyledInputBox
              label="Funkcja / stanowisko"
              value={data.workplace_name}
              disabled={true}
            />
            <MESegment
              data={data}
              dictionary={dictionary}
              addME={addME}
              editME={editME}
              permission={permission}
              referralLink
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}
