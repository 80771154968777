import React from "react";
import { connect } from "react-redux";

import { saveQEType } from "actions/QEActions";

import AppComponent from "components/AppComponent";
import QualificationEntitlementsTypeForm from "components/QE/FormType";
import SideMenu from "./_SideMenu";
import { setUrls } from "./_staticData";
let names = {
  TITLE: "Dodaj rodzaj uprawnień",
};
class Add extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls() };

    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { urls } = this.state;
    const { dispatch, history } = this.props;
    saveQEType(
      null,
      data
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.LISTTYPE);
      })
    );
  }

  render() {
    let { message, urls } = this.state;
    let { permission } = this.props;

    return (
      <QualificationEntitlementsTypeForm
        message={message}
        SideMenu={SideMenu}
        title="Dodaj rodzaj uprawnień"
        names={names}
        submit={this.submit}
        urls={urls}
        permission={permission}
      />
    );
  }
}

export default connect((store) => ({
  message: store.message,
  permission: store.qe.permission,
}))(Add);
