import React from "react";
import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import { Link } from "react-router-dom";

import { ButtonWrapper } from "components/Wrappers";
import { LayoutContent } from "components/Layout";
import PageHeader from "components/PageHeader";
import Pagination from "components/Pagination";
import { LinkContainer } from "components/LinkContainer";

import { TableListPage } from "components/Tables";
import Modal from "components/Modal/SimpleAccept";

export default class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modalDelete: this.initModalState(),
      data: [],
    };

    this.deleteEntry = this.deleteEntry.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  deleteEntry() {
    let { id } = this.state.modalDelete;
    let { deleteQEType } = this.props;

    deleteQEType(id, () => {
      this.closeModal();
    });
  }

  handlePageChange(data) {
    this.setState({ data });
  }

  render() {
    let { data, modalDelete } = this.state;
    let {
      SideMenu,
      urls,
      message,
      permission = {},
      superAdminView,
    } = this.props;

    return (
      <LayoutContent message={message} SideMenu={<SideMenu />}>
        <PageHeader>Rodzaje uprawnień kwalifikacyjnych</PageHeader>
        <ButtonWrapper>
          <LinkContainer to={urls.ADDTYPE}>
            <ButtonWithIcon
              disabled={!(superAdminView || permission[50])}
              icon="fa fa-plus"
              name="Dodaj uprawnienia"
            />
          </LinkContainer>
        </ButtonWrapper>
        <TableListPage>
          <thead>
            <th>L.p</th>
            <th>Rodzaj uprawnień</th>
            <th>Usuń</th>
          </thead>
          {data.map((item, index) => {
            const disabled = !(
              superAdminView ||
              (item.business_id !== 0 && permission[52])
            );
            return (
              <tr>
                <td>
                  <span>{index + 1}</span>
                </td>
                <td>
                  <Link to={urls.EDITTYPE(item.id)}>{item.name}</Link>
                </td>
                <td>
                  <ButtonWithIcon
                    disabled={disabled}
                    onClick={this.openModal("modalDelete", item)}
                    listButton
                    icon="fa fa-trash"
                    name="Usuń"
                  />
                </td>
              </tr>
            );
          })}
        </TableListPage>
        <Pagination
          handlePageChange={this.handlePageChange}
          data={this.props.data}
        />
        <Modal
          title="Usuń rodzaj uprawnień kwalifikacyjnych"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modalDelete.open}
          handleAccept={this.deleteEntry}
          handleClose={this.closeModal("modalDelete")}
        />
      </LayoutContent>
    );
  }
}
