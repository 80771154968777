import React from "react";
import { connect } from "react-redux";

import {
  saveDraftRA,
  getDraftRAMethods,
  getDraftRA,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import BasicForm from "components/RiskAssessment.Forms/Basic/index.js";

import SideMenu from "./_SideMenu";

import { setUrls } from "./_staticData";

let url = {
  DANGER: (id) => `/super-admin/draft/risk-assessment/danger/${id}`,
};
class Add extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    getDraftRAMethods()(dispatch).then(this.handleServerResponse());
    if (id) {
      getDraftRA(id)(dispatch).then(this.handleServerResponse());
    }
  }

  submit(values) {
    let { urls } = this.state;
    let { history, dispatch, match } = this.props;
    let { id = null } = match.params;
    saveDraftRA(
      id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        if (action.payload.data) {
          let { data } = action.payload.data;
          history.push(url.DANGER(data.id));
        }
      })
    );
  }

  render() {
    let {
      riskAssessment: { methods = [], singleData },
      match,
    } = this.props;
    let { id } = match.params;

    return (
      <BasicForm
        draft
        urls={this.state.urls}
        id={match.params.id}
        data={singleData}
        methods={methods}
        SideMenu={<SideMenu id={id} />}
        submit={this.submit}
      />
    );
  }
}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(Add);
