import React from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import { Form, Field } from "react-final-form";
import PropTypes from "prop-types";

import { ButtonWrapper } from "components/Wrappers";

import ModalSchema from "./_Schema";

export default class FormModal extends ModalSchema {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const { handleClose, handleSubmit } = this.props;
    handleSubmit(data);
    handleClose();
  }

  render() {
    const {
      open,
      title,
      description,
      initialValues = {},
      handleClose,
      children,
      acceptButtonName = "Tak",
      cancelButtonName = "Nie",
      handleSubmit,
      validate,
      acceptDisabled = false,
    } = this.props;

    const { accept } = this.state;
    return (
      <div className="static-modal">
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={{ ...initialValues }}
            validate={validate}
            render={({ handleSubmit, reset, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>{children}</Modal.Body>

                <Modal.Footer>
                  <Button
                    disabled={acceptDisabled}
                    type="submit"
                    bsStyle="primary"
                  >
                    {acceptButtonName}
                  </Button>
                  <Button onClick={handleClose}>{cancelButtonName}</Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal>
      </div>
    );
  }
}

FormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  acceptButtonName: PropTypes.string,
  cancelButtonName: PropTypes.string,
};
