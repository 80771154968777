import React from "react";
import SideMenu from "components/SideMenu";

import UpSideMenu from "../_SideMenu";
import { defaultUrls as urls } from "../_staticData";
const LSideMenu = ({
  id,
  audit_id,
  stage,
  permission = {},
  risk_assessment_id,
  hide = false,
}) => (
  <div>
    <UpSideMenu />
    {!hide && (
      <SideMenu
        title="Szablony dokumentów"
        data={[
          {
            name: "Informacje ogólne",
            url: id ? urls.DRAFTBASIC(id) : urls.DRAFTADD,
            notExact: true,
          },
          {
            disabled: !id,
            name: "Identyfikacji zagrożeń",
            url: urls.DRAFTDANGER(id),
            notExact: true,
          },
          {
            disabled: !id,
            name: "Charakterystyka",
            url: urls.DRAFTCHARACTERISTIC(id),
            notExact: true,
          },
          {
            disabled: !id,
            name: "Analiza ryzyka",
            url: urls.DRAFTANALYSIS(id),
            notExact: true,
          },
          // {
          //   disabled: !id,
          //   name: "Działania zmniejszające ryzyko",
          //   url: urls.DRAFTTASK(id),
          //   notExact: true,
          // },
        ]}
      />
    )}
  </div>
);

export default LSideMenu;
