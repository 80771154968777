function getParams(data = {}) {
  let params = new URLSearchParams();

  let keys = Object.keys(data);
  for (let k of keys) {
    params.set(k, data[k]);
  }

  return params ? `?${params}` : "";
}
// type
export function getDraftQETypeList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_TYPE_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/qe-type/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getQETypeList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_TYPE_LIST",
      payload: {
        request: {
          url: `/qe-type/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessQETypeList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_TYPE_LIST",
      payload: {
        request: {
          url: `/qe-type/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftQEType(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_TYPE_ITEM",
      payload: {
        request: {
          url: `/super-admin/draft/qe-type/${id}${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getQEType(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_TYPE_ITEM",
      payload: {
        request: {
          url: `/qe-type/${id}${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveDraftQEType(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_QE_TYPE_ITEM",
      payload: {
        request: {
          url: id
            ? `/super-admin/draft/qe-type/edit/${id}`
            : `/super-admin/draft/qe-type/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveQEType(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_QE_TYPE_ITEM",
      payload: {
        request: {
          url: id ? `/qe-type/edit/${id}` : `/qe-type/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteDraftQEType(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_QE_TYPE_ITEM",
      payload: {
        request: {
          url: `/super-admin/draft/qe-type/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteQEType(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_QE_TYPE_ITEM",
      payload: {
        request: {
          url: `/qe-type/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

//list

export function getQEList() {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_LIST",
      payload: {
        request: {
          url: `/qe`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getQEListFilter(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_LIST",
      payload: {
        request: {
          url: `/qe`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getBusinessQESingle(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_SINGLE",
      payload: {
        request: {
          url: `/business/qe/user/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addQE(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_QE",
      payload: {
        request: {
          url: `/qe/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addBusinessQE(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_QE",
      payload: {
        request: {
          url: `/business/qe/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}


export function saveBusinessQE(id, data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_QE",
      payload: {
        request: {
          url: id ? `/business/qe/edit/${id}` : `/business/qe/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function printQEReport(data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/qe/print/report`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}

export function getQEAccountData() {
  return (dispatch) =>
    dispatch({
      type: "GET_QE_LIST",
      payload: {
        request: {
          url: `/qe/users-account`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
