const defaultState = {
  data: [],
  parentUsers: [],
  autoCompleteUsers: [],
  singleData: {},
  fetching: false,
  fetched: true,
  hired_statuses: [],
  preventive_medical_examinations_view: {},
  osh_training_view: {},
  publicUserFetching: false,
  publicUserFetched: false,

  editFetching: false,
  editFetched: false,

  getUserFetching: false,
  getUserFetched: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "ADD_PUBLIC_USER_SUCCESS": {
      let users = action.payload.data.users;
      return {
        ...state,
        autoCompleteUsers: users,
      };
    }
    case "GET_USER_REQUEST": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        getUserFetching: true,
        getUserFetched: false,
      };
    }
    case "GET_USER_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        getUserFetching: false,
        getUserFetched: true,
        singleData: action.payload.data.user,
        preventive_medical_examinations_view:
          action.payload.data.preventive_medical_examinations_view,
        osh_training_view: action.payload.data.osh_training_view,
      };
    }
    case "GET_USER_ERROR": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        getUserFetching: false,
        getUserFetched: true,
      };
    }
    case "GET_USERS_REQUEST": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_USERS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.users,
      };
    }
    case "GET_USERS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_USERS_NAMES_SUCCESS": {
      let usersToGet = action.payload.data.users;

      return { ...state, autoCompleteUsers: usersToGet };
    }
    case "GET_PARENT_USERS_REQUEST": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_PARENT_USERS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        parentUsers: action.payload.data.users,
      };
    }
    case "GET_PARENT_USERS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_USERS_NOFETCH_REQUEST": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_USERS_NOFETCH_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.users,
      };
    }
    case "GET_USERS_NOFETCH_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "DELETE_USER_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data.filter(
          (item) => item.id != action.payload.data.user.id
        ),
      };
    }
    case "GET_BUSINESS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.user,
      };
    }
    case "SAVE_BUSINESSES_ADMIN_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.user,
      };
    }
    case "DELETE_USERS_AVATAR_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: { ...state.singleData, avatar: null, avatar_url: null },
      };
    }
    case "GET_SEARCHED_USERS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.users,
      };
    }
    case "GET_HIRED_STATUSES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        hired_statuses: action.payload.data.hired_statuses,
      };
    }
    case "SAVE_PREVENTIVE_MEDICAL_EXAMINATIONS_SUCCESS": {
      return {
        ...state,
        singleData: {
          ...state.singleData,
          preventive_medical_examinations:
            action.payload.data.preventive_medical_examinations,
        },
        preventive_medical_examinations_view:
          action.payload.data.preventive_medical_examinations_view,
      };
    }
    case "DELETE_PREVENTIVE_MEDICAL_EXAMINATIONS_SUCCESS": {
      return {
        ...state,
        singleData: {
          ...state.singleData,
          preventive_medical_examinations:
            action.payload.data.preventive_medical_examinations,
        },
        preventive_medical_examinations_view:
          action.payload.data.preventive_medical_examinations_view,
      };
    }
    case "SAVE_OSH_TRAINING_SUCCESS": {
      return {
        ...state,
        singleData: {
          ...state.singleData,
          osh_training: action.payload.data.osh_training,
        },
        osh_training_view: action.payload.data.osh_training_view,
      };
    }
    case "DELETE_OSH_TRAINING_SUCCESS": {
      return {
        ...state,
        singleData: {
          ...state.singleData,
          osh_training: action.payload.data.osh_training,
        },
        osh_training_view: action.payload.data.osh_training_view,
      };
    }
    case "GET_PUBLIC_USER_BASIC_REQUEST": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        publicUserFetching: true,
        publicUserFetched: false,
      };
    }
    case "GET_PUBLIC_USER_BASIC_SUCCESS": {
      let { user } = action.payload.data;

      return {
        ...state,
        singleData: user,
        fetching: false,
        fetched: true,
        publicUserFetching: false,
        publicUserFetched: true,
      };
    }
    case "GET_PUBLIC_USER_BASIC_ERROR": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        publicUserFetching: false,
        publicUserFetched: true,
      };
    }
    case "CLEAR_USERS_SINGLEDATA_REDUCER": {
      return { ...state, singleData: {} };
    }
    case "ADD_PUBLIC_USER_SUCCESS": {
    }
    case "CLEAR_USERS_REDUCER": {
      return defaultState;
    }
    case "CLEAR_USERS_SINGLEDATA": {
      return { ...state, singleData: {} };
    }
    case "LOGOUT_USER_SUCCESS": {
      return defaultState;
    }
  }

  return state;
}
