import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Field, FormSpy } from "react-final-form";

import { getDictionary } from "actions/dictionaryActions";
import {
  getUsersNames,
  getPublicUserToIncident as getPublicUserBasics,
} from "actions/userActions";

import styled from "styled-components";
import { saveIncidentPersonUser } from "actions/incidentActions";
import AppComponent from "../../../components/AppComponent";
import Box from "../../../components/Box";
import Button from "react-bootstrap/lib/Button";
import Select from "../../../components/Select";
import {
  StyledInput,
  StyledInputBox,
  StyledInputCheckBox,
  StyledTextareaBox,
} from "../../../style/styleComponents/Form/input";
import validator, { composeValidators } from "../../../components/Validation";

import { OnlySxBlock, TwoInputWrapper, SpaceBlock } from "./AnalysisForm.style";
import ModalGetUser from "./Modal/getUser";
import ModalAddUser from "./Modal/addUser";
import ModalSetSubcontractor from "./Modal/setSubcontractor";
import ModalVictimExplains from "./Modal/victimExplains";
const changeInjuryValue = ({ personIndex, value, fields, person }) => {
  fields.remove(personIndex);
  fields.insert("incident_has_person", {
    ...person,
    serious_accident: true,
    injury_id: value,
  });
};

class IncidentHasPersonSection extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalSetSubcontractor: {
        open: false,
      },
      modalGetUser: {
        open: false,
        readOnly: true,
        user: {},
      },
      modalAddUser: {
        open: false,
        user: {},
      },
      modalVictimExplains: {
        data: {},
      },
    };

    this.getUser = this.getUser.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setAddedUser = this.setAddedUser.bind(this);
    this.setSubcontractor = this.setSubcontractor.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    Promise.all([
      getUsersNames()(dispatch).then(
        this.handleServerResponse({}, (action) => {
          let usersToGet = action.payload.data.users;
          usersToGet = usersToGet.map((item) => {
            return { ...item, name: item.full_name };
          });
        })
      ),
    ]).then(() => {});
  }

  closeModal(name, rest = {}) {
    return () => {
      let { values } = this.props;
      const result = {
        ...this.state[name],
        open: false,
        ...rest,
        user: values.incident_has_person,
      };
      this.setState({
        [name]: result,
      });
    };
  }

  componentDidUpdate() {
    let { modalGetUser } = this.state;
    let { values } = this.props;
    if (!Object.keys(modalGetUser.user).length) {
      if (values.incident_has_person) {
        let fullname =
          typeof values.incident_has_person.full_name == "string"
            ? values.incident_has_person.full_name.split(" ")
            : "";

        this.setState({
          modalGetUser: {
            ...this.state.modalGetUser,
            user: {
              ...values.incident_has_person,
              first_name: fullname[0],
              lastname: fullname[1],
            },
          },
        });
      }
    }
  }

  openModal(name, rest = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true, ...rest } });
    };
  }

  getUser(id) {
                const {
                  dispatch,
                  users: { fetching },
                } = this.props;

                if (!fetching) {
                  getPublicUserBasics(id)(dispatch).then(
                    this.handleServerResponse({}, (action) => {
                      let user = action.payload.data.user;
                      user.date_birth = user.date_birth
                        ? moment(user.date_birth).format("YYYY-MM-DD")
                        : null;
                      user.id = id;
                      this.setState({
                        modalGetUser: { ...this.state.modalGetUser, user },
                      });
                    })
                  );
                }
              }

  setUser() {
    let { form, dispatch, incident_id } = this.props;
    let { user = {} } = this.state.modalGetUser;
    let { id } = user;

    saveIncidentPersonUser(
      incident_id,
      id
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const incidentPerson = action.payload.data.incidentPerson;
        form.change("incident_has_person", {
          ...incidentPerson,
          birth_date: incidentPerson.birth_date,
          user: {
            id: incidentPerson.user_id,
            full_name: incidentPerson.full_name,
          },
        });
        this.getUser(incidentPerson.user_id);
      })
    );
  }

  setSubcontractor(values) {
    let { form } = this.props;
    let keys = Object.keys(values);
    for (let key of keys) {
      form.change(`incident_has_person.user.${key}`, values[key]);
    }
    this.setState({
      modalGetUser: { ...this.state.modalGetUser, user: values },
    });
  }

  setAddedUser(user) {
    let { form, values, dispatch } = this.props;
    let { id, full_name } = user;
    let incident_has_person = values.incident_has_person;

    incident_has_person.user = {
      id,
      full_name,
    };
    form.change("incident_has_person", incident_has_person);

    getPublicUserBasics(user.id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let user = action.payload.data.user;
        user.date_birth = user.date_birth
          ? moment(user.date_birth).format("YYYY-MM-DD")
          : null;
        user.id = id;
        this.setState({
          modalGetUser: { ...this.state.modalGetUser, user },
        });
      })
    );
  }

  render() {
    let {
      dictionary,
      form,
      incident_id,
      readOnly = true,
      users: { autoCompleteUsers },
      values,
      type_id,
      incident: { singleData: incidentData },
      account,
    } = this.props;
    let person = incidentData.incident_has_person || {};
    let { person_status_id } = person;
    const {
      workplace: { searchedWorkplaces },
    } = this.props;
    let {
      explain_place,
      explain_date,
      explain_description,
    } = values.incident_has_person ? values.incident_has_person : {};
    let explainData = {
      explain_place,
      explain_date,
      explain_description,
    };

    let name = "incident_has_person";
    return (
      <Fragment>
        <Fragment>
          {values && values.personal ? (
            <div>
              <Box title={`Poszkodowany`}>
                <TwoInputWrapper>
                  <Field
                    name={`${name}.user.full_name`}
                    disabled={true}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledInput
                        style={{
                          cursor: "pointer",
                          pointerEvents: "true",
                          background: "rgba(239, 239, 239, 0.3)",
                          caretColor: "transparent",
                          outline: "none",
                        }}
                        label="imię i nazwisko"
                        value={input.value}
                        // disabled
                        // readonly
                        onClick={this.openModal(
                          person_status_id == 1
                            ? "modalGetUser"
                            : "modalSetSubcontractor",
                          { readOnly: true }
                        )}
                      />
                    )}
                  />
                  <Button
                    disabled={readOnly}
                    onClick={this.openModal(
                      person_status_id == 1
                        ? "modalGetUser"
                        : "modalSetSubcontractor",
                      { readOnly: false }
                    )}
                  >
                    {person_status_id == 1
                      ? "Wybierz pracownika z bazy danych"
                      : "Dane poszkodowanego"}
                  </Button>
                </TwoInputWrapper>
                <TwoInputWrapper>
                  <Button onClick={this.openModal("modalVictimExplains")}>
                    Dodaj wyjaśnienia poszkodowanego
                  </Button>
                </TwoInputWrapper>

                {type_id && (
                  <Field
                    name={`${name}.person_status_id`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <SpaceBlock>
                        <Select
                          handleChange={(data) => {
                            input.onChange(data);
                          }}
                          validate={validator.required}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          label="Status"
                          fullWidth
                          title="Brak"
                          propValue={"id"}
                          disabled={readOnly}
                          data={dictionary.person_status}
                          selected={input.value}
                        />
                      </SpaceBlock>
                    )}
                  />
                )}
                <Field
                  name={`${name}.sex_id`}
                  validate={validator.required}
                  render={({ input, meta, ...rest }) => (
                    <SpaceBlock>
                      <Select
                        handleChange={(data) => {
                          input.onChange(data);
                        }}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        label="Płeć"
                        fullWidth
                        title="Brak"
                        propValue={"id"}
                        disabled={readOnly}
                        data={dictionary.sex}
                        selected={input.value}
                      />
                    </SpaceBlock>
                  )}
                />

                <TwoInputWrapper noPaddingTop noXS>
                  <Field
                    name={`${name}.age`}
                    validate={composeValidators(
                      validator.required,
                      validator.mustBeNumber
                    )}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        label="Wiek"
                        value={input.value}
                        error={meta.error && meta.touched}
                        disabled={readOnly}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name={`${name}.job_seniority`}
                    validate={composeValidators(
                      validator.required,
                      validator.mustBeNumber
                    )}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        label="Staż pracy poszkodowanego na zajmowanym stanowisku pracy"
                        value={input.value}
                        error={meta.error && meta.touched}
                        disabled={readOnly}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                </TwoInputWrapper>
                <OnlySxBlock>
                  <Field
                    name={`${name}.age`}
                    validate={composeValidators(
                      validator.required,
                      validator.mustBeNumber
                    )}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        value={input.value}
                        label={"Wiek"}
                        error={meta.error && meta.touched}
                        disabled={readOnly}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name={`${name}.job_seniority`}
                    validate={composeValidators(
                      validator.required,
                      validator.mustBeNumber
                    )}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        value={input.value}
                        label={
                          "Staż pracy poszkodowanego na zajmowanym stanowisku pracy"
                        }
                        error={meta.error && meta.touched}
                        disabled={readOnly}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                </OnlySxBlock>
                <TwoInputWrapper>
                  <Field
                    name={`${name}.injury_id`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <SpaceBlock>
                        <Select
                          handleChange={(value) => {
                            if (value == 15) {
                              form.change(`${name}.serious_accident`, true);
                            }
                            input.onChange(value);
                          }}
                          label="Rodzaj urazu"
                          fullWidth
                          title="Brak"
                          propValue={"id"}
                          disabled={readOnly}
                          data={dictionary.injury}
                          selected={input.value}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      </SpaceBlock>
                    )}
                  />
                  <Field
                    name={`${name}.localization_injury_id`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <SpaceBlock>
                        <Select
                          handleChange={(data) => {
                            input.onChange(data);
                          }}
                          label="Umiejscowienie urazu"
                          fullWidth
                          title="Brak"
                          propValue={"id"}
                          disabled={readOnly}
                          data={dictionary.localization_injury}
                          selected={input.value}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                        />
                      </SpaceBlock>
                    )}
                  />
                </TwoInputWrapper>
                <SpaceBlock>
                  <Field
                    name={`${name}.activity_id`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <Select
                        style={{ marginBottom: "5px" }}
                        handleChange={(data) => {
                          input.onChange(data);
                        }}
                        label="Czynność wykonywana w chwili wypadku"
                        fullWidth
                        title="Brak"
                        propValue={"id"}
                        disabled={readOnly}
                        data={dictionary.activity}
                        selected={input.value}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    )}
                  />
                </SpaceBlock>
                <Field
                  name={`${name}.personal_protective_equipment`}
                  render={({ input, meta, ...rest }) => (
                    <StyledInputCheckBox
                      label="Poszkodowany posiadał środki ochrony indywidualnej"
                      checked={input.value}
                      disabled={readOnly}
                      onChange={(event) => {
                        input.onChange(event.target.checked);
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                />
                <Field
                  name={`${name}.personal_protective_equipment_description`}
                  render={({ input, meta, ...rest }) => (
                    <StyledTextareaBox
                      label="Jakie?"
                      disabled={readOnly}
                      value={input.value}
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                />
                <Field
                  name={`${name}.risk_assessment`}
                  render={({ input, meta, ...rest }) => (
                    <StyledInputCheckBox
                      label="Czy dla stanowiska opracowano ocenę ryzyka zawodowego?"
                      disabled={readOnly}
                      checked={input.value}
                      onChange={(event) => {
                        input.onChange(event.target.checked);
                      }}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                />
              </Box>
            </div>
          ) : (
            ""
          )}
          {person_status_id == 1 ? (
            <ModalGetUser
              account={account.singleData}
              accept={this.setUser}
              data={autoCompleteUsers}
              getUser={this.getUser}
              closeModal={this.closeModal("modalGetUser")}
              openAddUser={this.openModal("modalAddUser")}
              user={
                values.incident_has_person && values.incident_has_person.user
              }
              title={
                this.state.modalGetUser.readOnly
                  ? "Dane pracownika"
                  : "Wybierz pracownika"
              }
              // defaultUser={values.incident_has_person.user}
              {...this.state.modalGetUser}
            />
          ) : (
            <ModalSetSubcontractor
              accept={this.setSubcontractor}
              dictionary={dictionary.subcontractor_insurance}
              readOnly={readOnly}
              {...this.state.modalSetSubcontractor}
              incident_id={incident_id}
              user={
                values.incident_has_person && values.incident_has_person.user
              }
              closeModal={this.closeModal("modalSetSubcontractor")}
            />
          )}
        </Fragment>

        <ModalAddUser
          {...this.state.modalAddUser}
          accept={this.setAddedUser}
          closeModal={this.closeModal("modalAddUser")}
        />
        <ModalVictimExplains
          readOnly={readOnly}
          incident_id={incident_id}
          data={explainData}
          closeModal={this.closeModal("modalVictimExplains")}
          {...this.state.modalVictimExplains}
        />
      </Fragment>
    );
  }
}

export default connect((store) => ({
  account: store.account,
  dictionary: store.dictionary,
  incident: store.incident,
  workplace: store.workplace,
  users: store.users,
}))(IncidentHasPersonSection);
