import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {LayoutContent} from "./Layout";
import SideMenu from "./SideMenu";
import Box from "./Box";
import {StyledInputBox,StyledInputCheckBox} from "../style/styleComponents/Form/input";
import {Field} from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import {media} from "../style/style-utils";
import FormModal from "./Modal/FormModal";
const MainWrapper = styled.div`
  margin-bottom: 40px;
  ${media.sm`
    margin-botton:10px
  `}
`;
const ModulesCheckboxWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top:10px;
`;

class ClientsAccountForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      modal:{
        open: false
      }
    };
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose(){
    this.setState({modal:{...this.state.modalNotificationDelete,open:false}});
  }

  handleModalOpen(){
    this.setState({modal:{...this.state.modalNotificationDelete,open:true}});
  }

  render(){
    const {modal} = this.state;
    const {modules = [],createView=false,boxTitle} = this.props;

    return (<LayoutContent
      SideMenu={<SideMenu title={"Konta firm"} data={[
        {name: "Lista kont", url: "/a"},
        {name: "Dodaj konto", url: "/a"}
      ]}/>}
    >
      <Box title={boxTitle}>
        <MainWrapper>
          <StyledInputBox
            label="Nazwa"
          />
          <StyledInputCheckBox
            label="Konto firmy aktywne"
          />
        </MainWrapper>
        <ModulesCheckboxWrapper>
          <h4>Dostępne moduły</h4>

          {modules.map((item,index)=>(
            <StyledInputCheckBox key={index} label={item.name} checked={item.active || false} />
          ))}
        </ModulesCheckboxWrapper>
        {!createView && <Button onClick={this.handleModalOpen}>Dodaj konto administratora</Button>}
        <ButtonWrapper>
          <Button>
            {createView ? "Dodaj" : "Zapisz"}
          </Button>
        </ButtonWrapper>
        <FormModal
          title={"Konto administratora"}
          open={modal.open}
          onSubmit={()=>{}}
          handleClose={this.handleModalClose}
          acceptButtonName="Dodaj"
          cancelButtonName="Anuluj"
        >
          <Field
            name="name"
            render={({input, meta, ...rest}) => (
              <StyledInputBox
                label="Imię i nazwisko"
              />
            )}
          />
          <Field
            name="email"
            render={({input, meta, ...rest}) => (
              <StyledInputBox
                label="E-mail"
              />
            )}
          />
          <Field
            name="phone"
            render={({input, meta, ...rest}) => (
              <StyledInputBox
                label="Nr telefonu"
              />
            )}
          />
        </FormModal>
      </Box>
    </LayoutContent>);
  }
}

export default ClientsAccountForm;

ClientsAccountForm.propTypes = {
  modules:PropTypes.arrayOf(
    PropTypes.shape({
      name:PropTypes.string,
      value:PropTypes.node,
      active:PropTypes.bool
    })
  ),
  createView: PropTypes.bool
};