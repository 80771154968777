import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { saveDraftControlList } from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import Box from "components/Box";
import { ButtonWithIcon } from "components/Buttons";

import { FieldInput, FieldTextareaBox } from "components/Fields";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { ButtonWrapper } from "components/Wrappers";
import SideMenu from "../_SideMenu";
import AuditTypeForm from "components/AuditTypes.Form";
import { setUrls } from "./_staticData";

let defaultNames = {
  TITLE: "Dodaj szablon listy kontrolnej identyfikacji zagrożeń",
};

class Add extends AppComponent {
  constructor(props) {
    super(props);

    let { urls = {}, names = {} } = props;

    this.state = {
      ...this.state,
      urls: setUrls(urls),
      names: { ...defaultNames, ...names },
    };

    this.submit = this.submit.bind(this);
  }

  submit(data) {
    let { urls } = this.state;
    let { dispatch, history } = this.props;

    saveDraftControlList(
      null,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.LIST);
      })
    );
  }

  render() {
    let { urls, names } = this.state;
    let { message } = this.state;
    return (
      <AuditTypeForm
        title={names.TITLE}
        SideMenu={<SideMenu />}
        cancelLink={urls.LIST}
        message={message}
        error_code={this.state.error_code == 404}
        handleSubmit={this.submit}
      />
    );
  }
}

export default connect((store) => ({}))(Add);
