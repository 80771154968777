import React from "react";
import Bars from "../../components/Charts/Bars.js";

const barChartMainColumnName = "Liczba zdarzeń potencjalnie wypadkowych";
const barChartMainColumnName2 = "Liczba wypadków";

const createSelects = ({localizationData})=>{
  return [
    {
      title: "Lokalizacja", data:
        [{id:0,name:"Wszystkie"}].concat(localizationData.map(item=>({value:item.id,name:item.name})))
    },
    {
      title: "Okres", data: [
        {value: null, name: "Rok bieżący"},
        {divider:true},
        {value: 1, name: "Styczeń"},
        {value: 2, name: "Luty"},
        {value: 3, name: "Marzec"},
        {value: 4, name: "Kwiecień"},
        {value: 5, name: "Maj"},
        {value: 6, name: "Czerwiec"},
        {value: 7, name: "Lipiec"},
        {value: 8, name: "Sierpień"},
        {value: 9, name: "Wrzesień"},
        {value: 10, name: "Październik"},
        {value: 11, name: "Listopad"},
        {value: 12, name: "Grudzień"},
        {divider:true},
        {value: 13, name: "Ostatnie 10 lat"}]
    }
  ];
}

const getStatisticName = (time_number,main_value)=> {
  const month = ["I","II","III","IV","V","VI","VII","VIII","IX","X","XI","XII"];
  if(time_number == null){
    return month[main_value];
  }
  return main_value;
}

const createDataMonth = (data,barChartColumnName)=>{
  const {result,time_number} = data;
  return result.map(item=>{
    return {name:getStatisticName(time_number,item.main_value),[barChartColumnName]:item.value};
  });
}

const asd = function ({id,title,incidentStatistics,handleAccidentChart,localizationData}) {
  const barChartColumnName = id==2 ? barChartMainColumnName2 : barChartMainColumnName;

  return {
    id,
    title: title,
    content: (<Bars
      data={createDataMonth(incidentStatistics,barChartColumnName)}
      bars={{
        [barChartColumnName]: "#5f7bc0"
      }}
      selects={createSelects({localizationData})}
      selectFunction={handleAccidentChart}
    />),
  };
}

export default asd