import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LayoutContent } from "./Layout";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import Box from "./Box";
import {
  StyledInput,
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
} from "../style/styleComponents/Form/input";
import Select from "./Select";
import Modal from "./Modal/SimpleAccept";
import Button from "react-bootstrap/lib/Button";
import DayPicker from "../lib/DayPicker/";
import { Form, Field, FormSpy } from "react-final-form";
import moment from "moment";
import AutoComplete from "./AutoCompleteInput";
import AppComponent from "./AppComponent";
import { getSearchedUsers } from "actions/userActions";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import validator from "./Validation/";
import { acceptTask, closeTask, confirmTask } from "actions/taskActions";
import { getUserSections } from "actions/sectionActions";
import { LinkContainer } from "./LinkContainer";
import { ButtonWrapper } from "./ListComponents";
import { media } from "style/style-utils/index";
import AttachmentList from "./AttachmentList";
const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

const StyledInputCheckBoxWrapper = styled.div`
  display: flex;
  & > div {
    margin-right: 15px;
  }
`;

const SelectWrapper = styled.div`
  margin: 0 0 10px;
`;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  ${media.custom({ maxWidth: 599 })`
    flex-wrap: wrap;
  `}
`;

const SubmitBox = (props) => <SubmitWrapper>{props.children}</SubmitWrapper>;

const AcceptBox = (props) => <SubmitWrapper>{props.children}</SubmitWrapper>;

const ProgressArrowWrapper = styled((props) => (
  <div className={props.className}>
    <div className="fa fa-arrow-right" />
  </div>
))`
  display: flex;
  align-items: center;
  padding: 0 5px;
  ${media.custom({ maxWidth: 599 })`
    justify-content:center;
    width:100%;
    & .fa.fa-arrow-right {
      transform: rotate(90deg);
      padding: 5px;
    }
  `}
`;

const SubmitButton = styled(Button)`
  ${media.custom({ maxWidth: 599 })`
    width:100%;
  `}
`;

const ParentTask = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const modalStateFactory = () => {
  return {
    open: false,
  };
};

class TaskForm extends AppComponent {
  constructor(props) {
    super(props);
    const { data = {} } = this.props;
    this.state = {
      ...this.state,
      progress_id: data.progress_id,
      modalAccept: {
        open: false,
      },
      modalConfirm: modalStateFactory(),
      modalClose: modalStateFactory(),
      userFetch: {
        prevValue: null,
      },
    };
    this.handleAcceptModal = this.handleAcceptModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserInputChange = this.handleUserInputChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadSections = this.loadSections.bind(this);
  }

  openModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true } });
    };
  }

  closeModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false } });
    };
  }

  handleAcceptModal() {
    this.setState({ progress_id: this.state.progress_id + 1 });
  }

  handleSubmit(data) {
    const newData = {
      ...data,
      section_id: parseInt(data.section_id || 0) || null,
      date_end: data.date_end
        ? moment(data.date_end).format("YYYY-MM-DD")
        : undefined,
    };

    this.props.handleSubmit(newData);
  }

  handleUserInputChange(value) {
    if (
      value &&
      value.length &&
      value.length == 3 &&
      value != this.state.userFetch.prevValue
    ) {
      const { dispatch } = this.props;
      getSearchedUsers(value)(dispatch).then(this.handleServerResponse());
      this.setState({
        userFetch: { ...this.state.userFetch, prevValue: value },
      });
    }
  }

  loadSections(localization_id) {
    const { dispatch } = this.props;
    getUserSections(localization_id)(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  getTitle({ addView, repeatView, editView }) {
    switch (true) {
      case addView: {
        return "Dodaj działanie";
      }
      case editView: {
        return "Edytuj / Pokaż działanie";
      }
      case repeatView: {
        return "Powtórz działanie";
      }
      default: {
        return "Dodaj działanie";
      }
    }
  }

  render() {
    const {
      dispatch,
      createView,
      readOnly,
      editView,
      repeatView,
      data = {},
      localizations,
      sections,
      loadSections,
      users: { data: userData },
      message,
      noLocalization,
      permission,
      attachments = [],
      task_id,
      incident_id,
      audit_id,
      addAttachment,
      setMessage,
      deleteAttachment,
      attachmentAddFetching,
      downloadAction,
    } = this.props;
    const attachmentsEntryId = incident_id || audit_id ? { incident_id,audit_id, task_id } : task_id;

    const { symbol } = data;
    const { modal } = this.state;
    const { acceptTask, confirmTask, closeTask, repeatMode } = this.props;
    const { modalAccept, modalConfirm, modalClose } = this.state;
    const LSideMenu = this.props.SideMenu;
    const { date_end, date_done, dictionary_progress_id, ...dataRaw } = data;
    const date_end2 = moment(
      createView ? Date.now() : date_end ? date_end : Date.now()
    ).format("YYYY-MM-DD");
    const initialValues = {
      ...{ priority: 2, date_end: repeatView ? null : date_end2 },
      date_done,
      ...dataRaw,
    };
    const BoxTitle = this.getTitle({ createView, editView, repeatView });
    return (
      <LayoutContent
        mobileNotHideMenu={this.props.mobileNotHideMenu}
        error404={this.props.error_code == 404}
        message={message}
        SideMenu={
          LSideMenu ? (
            <LSideMenu permission={permission} />
          ) : (
            <SideMenu
              title="Działania"
              data={[
                { name: "Rejestr działań", url: "/task" },
                {
                  name: "Dodaj działanie",
                  url: "/task/add",
                  disabled: !permission[2],
                },
              ]}
            />
          )
        }
      >
        <Box title={BoxTitle}>
          {!createView && editView && (
            <StyledInputBox label="Symbol" value={symbol} disabled />
          )}
          <Form
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="title"
                  validate={validator.required}
                  render={({ input, meta, ...rest }) => (
                    <StyledInputBox
                      {...input}
                      label="Tytuł działania"
                      disabled={readOnly}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
                {!noLocalization ? (
                  <div>
                    <Field
                      name="localization_id"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <SelectWrapper>
                          <Select
                            label="Lokacja"
                            title={"- wybierz -"}
                            id="superior"
                            disabled={readOnly}
                            fullWidth
                            centerOptions
                            propValue={"id"}
                            selected={input.value}
                            data={localizations}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            handleChange={(value) => {
                              this.loadSections(value);
                              input.onChange(value);
                            }}
                          />
                        </SelectWrapper>
                      )}
                    />
                    <Field
                      name="section_id"
                      render={({ input, meta, ...rest }) => (
                        <SelectWrapper>
                          <Select
                            label={"Dział"}
                            title={"- wybierz -"}
                            id="superior"
                            disabled={readOnly}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            fullWidth
                            centerOptions
                            propValue={"id"}
                            selected={input.value}
                            data={[{ id: "0", name: "Brak" }].concat(sections)}
                            handleChange={(value) => {
                              input.onChange(value);
                            }}
                          />
                        </SelectWrapper>
                      )}
                    />
                  </div>
                ) : (
                  ""
                )}
                <Field
                  name="content"
                  render={({ input, meta, ...rest }) => (
                    <StyledTextareaBox
                      {...input}
                      label="Informacje szczegółowe"
                      value={input.value}
                      disabled={readOnly}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
                <FormSpy subscribe={{ form: true }}>
                  {({ form }) => {
                    return (
                      <>
                        <Field
                          name="users_name"
                          render={({ input, meta, ...rest }) => (
                            <AutoComplete
                              isSearch={true}
                              label="Osoba odpowiedzialna"
                              error={meta.error && meta.touched}
                              disabled={readOnly}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                              data={userData}
                              value={input.value}
                              propValue={"id"}
                              propName={"name"}
                              handleChange={this.handleUserInputChange}
                              handleSelect={(value) => {
                                form.change("users_id", value.id);
                                form.change("users_name", value.name);
                              }}
                            />
                          )}
                        />
                      </>
                    );
                  }}
                </FormSpy>

                <Field
                  name="date_end"
                  validate={composeValidators(
                    validator.required,
                    validator.mustBeDate
                  )}
                  render={({ input, meta, ...rest }) => (
                    <div style={{ margin: "0 0 5px " }}>
                      <div style={{ marginBottom: "5px" }}>
                        Termin wykonania
                      </div>
                      <DayPicker
                        selectedDay={input.value}
                        disabled={readOnly}
                        handleChange={input.onChange}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                      />
                    </div>
                  )}
                />
                {!createView && (
                  <div style={{ margin: "0 0 5px " }}>
                    <div style={{ marginBottom: "5px" }}>Data Wykonania</div>
                    {dictionary_progress_id == 3 ? (
                      <Field
                        name="date_done"
                        validate={composeValidators(
                          validator.required,
                          validator.mustBeDate
                        )}
                        render={({ input, meta, ...rest }) => (
                          <div style={{ margin: "0 0 5px " }}>
                            <DayPicker
                              selectedDay={input.value}
                              disabled={readOnly}
                              handleChange={input.onChange}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                            />
                          </div>
                        )}
                      />
                    ) : (
                      <StyledInput disabled width={"auto"} value={date_done} />
                    )}
                  </div>
                )}

                <div style={{ marginBottom: "5px" }}>Priorytet</div>
                <Field
                  name="priority"
                  render={({ input, meta, ...rest }) => (
                    <StyledInputCheckBoxWrapper
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    >
                      <StyledInputCheckBoxRadio
                        type="radio"
                        title="Wysoki priorytet"
                        checked={input.value == 1}
                        disabled={readOnly}
                        value={1}
                        name="priority"
                        label="1"
                        onChange={(event) => {
                          input.onChange(1);
                        }}
                      />
                      <StyledInputCheckBoxRadio
                        type="radio"
                        title="Normalny priorytet"
                        value={2}
                        checked={input.value == 2}
                        disabled={readOnly}
                        name="priority"
                        label="2"
                        onChange={(event) => {
                          input.onChange(2);
                        }}
                      />
                      <StyledInputCheckBoxRadio
                        type="radio"
                        title="Niski priorytet"
                        value={3}
                        checked={input.value == 3}
                        disabled={readOnly}
                        name="priority"
                        label="3"
                        onChange={(event) => {
                          input.onChange(3);
                        }}
                      />
                    </StyledInputCheckBoxWrapper>
                  )}
                />
                <ButtonWrapper>
                  {!readOnly ? (
                    <Button type="submit" bsStyle="primary">
                      {createView ? "Akceptuj" : "Zapisz"}
                    </Button>
                  ) : (
                    ""
                  )}
                  <LinkContainer to={this.props.backUrl || "/task"} exact>
                    <Button>Anuluj</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}
          />
          <AttachmentList
            createView={createView}
            dispatch={dispatch}
            entry_id={attachmentsEntryId}
            attachments={attachments}
            permission={permission}
            addAttachmentAction={addAttachment}
            deleteAttachmentAction={deleteAttachment}
            attachmentAddFetching={attachmentAddFetching}
            downloadAction={downloadAction}
            setParentMessage={setMessage}
            readOnly={readOnly}
          />
          {editView && (
            <AcceptBox>
              <SubmitButton
                disabled={!(dictionary_progress_id == 5 && permission[5])}
                onClick={this.openModal("modalAccept")}
              >
                Zaakceptuj realizację
              </SubmitButton>
              <ProgressArrowWrapper />
              <SubmitButton
                disabled={!(dictionary_progress_id == 1 && permission[7])}
                onClick={this.openModal("modalConfirm")}
              >
                Potwierdź wykonanie
              </SubmitButton>
              <ProgressArrowWrapper />
              <SubmitButton
                disabled={!(dictionary_progress_id == 6 && permission[8])}
                onClick={this.openModal("modalClose")}
              >
                Zatwierdź i zamknij
              </SubmitButton>
            </AcceptBox>
          )}
          {data.parent_task && (
            <ParentTask>
              Działanie wynika z&nbsp;
              <Link to={data.parent_task.url}>{data.parent_task.symbol}</Link>
            </ParentTask>
          )}
        </Box>
        <Modal
          title="Czy chcesz zaakceptować realizację?"
          open={modalAccept.open}
          handleClose={this.closeModal("modalAccept")}
          handleAccept={acceptTask}
        />
        <Modal
          title="Czy chcesz potwierdzić wykonanie?"
          open={modalConfirm.open}
          handleClose={this.closeModal("modalConfirm")}
          handleAccept={confirmTask}
        />
        <Modal
          title="Czy chcesz zatwierdzić i zamknąć działanie?"
          open={modalClose.open}
          handleClose={this.closeModal("modalClose")}
          handleAccept={closeTask}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  section: store.section,
  users: store.users,
}))(TaskForm);

TaskForm.propTypes = {
  createView: PropTypes.bool,
  editView: PropTypes.bool,
  permission: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  localization: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  section: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  data: PropTypes.shape({
    symbol: PropTypes.string,
    title: PropTypes.string,
    responsible_person: PropTypes.string,
    selected_location: PropTypes.node,
    selected_section: PropTypes.node,
    content: PropTypes.string,
  }),
  loadSections: PropTypes.func,
};
