import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "components/Buttons";
import { FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import { StyledLabel } from "style/styleComponents/Form/input";
import {
  FieldInput,
  FieldInputCheckBox,
  FieldInputCheckBoxRadio,
  FieldTextareaBox,
} from "components/Fields";
import {
  ButtonWrapper,
  LeftPadding,
  StyledInputCheckBoxWrapper,
  TwoInputWrapper,
} from "components/Wrappers";

import {
  addTask,
  addTaskAttachmentLocal,
  deleteTaskAttachmentLocal,
} from "actions/taskActions";

import {
  addAttachmentLocal,
  deleteAttachmentLocal,
} from "actions/riskAssessmentAction";
import AttachmentList from "components/AttachmentList";
export default function QuestionLine(props) {
  let {
    data,
    dispatch,
    answers = [],
    readOnly = false,
    attachments = [],
    setMessege = () => {},
    addAttachmentAction,
    deleteAttachmentAction,
    downloadAction,
  } = props;

  let [commentOpen, setCommentOpen] = useState(false);
  let [inames, setInames] = useState(setINamesFn(null, data));
  let { name } = props;

  useEffect(() => {
    setInames(setINamesFn(null, data));
    return () => {
      setInames(null);
    };
  }, [data]);

  useEffect(() => {
    if (data && data.comment) {
      setCommentOpen(true);
    }
  }, [data && data.comment]);
  return (
    <>
      <StyledLabel>{data.name}</StyledLabel>
      <div>
        <TwoInputWrapper>
          <FieldInputCheckBoxRadio
            disabled={readOnly}
            name={inames.CHECKBOX}
            inputs={answers.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />

          <ButtonWrapper>
            <FormSpy subscribe={{ values: true, form: true }}>
              {({ values, form }) => {
                return (
                  <Button
                    disabled={readOnly}
                    onClick={() => {
                      setCommentOpen(!commentOpen);
                      if (values[inames.COMMENT]) {
                        form.change(inames.COMMENT, null);
                      }
                    }}
                  >
                    {commentOpen ? "Usuń" : "Dodaj"} komentarz
                  </Button>
                );
              }}
            </FormSpy>
          </ButtonWrapper>
        </TwoInputWrapper>
      </div>
      {commentOpen ? (
        <FieldTextareaBox
          name={inames.COMMENT}
          placeholder="Wpisz komentarz do pytania"
        />
      ) : (
        ""
      )}
      <AttachmentList
        attachmentAddFetching={false}
        permission={{}}
        dispatch={dispatch}
        entry_id={data.id}
        attachments={data.attachments || []}
        addAttachmentAction={addAttachmentAction}
        deleteAttachmentAction={deleteAttachmentAction}
        downloadAction={downloadAction}
        setParentMessage={setMessege}
        readOnly={readOnly}
        additionalData={{ id: data.id }}
      />
    </>
  );
}

function setINamesFn(setInames, data = {}) {
  let defaultNames = {
    CHECKBOX: "foo",
    COMMENT: "foo",
  };

  let CHECKBOX = `question_${data.id}_answer_id`;
  let COMMENT = `question_${data.id}_comment`;
  let result = { ...defaultNames, CHECKBOX, COMMENT };
  if (setInames) {
    setInames(result);
  } else {
    return result;
  }
}
