import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

// import { ButtonWrapper } from "./ListComponents";

import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
} from "components/Wrappers";
import {
  FieldInputCheckBox,
  FieldDayPicker,
  FieldSelect,
} from "components/Fields";
import ModalSchema from "components/Modal/./_Schema";

import { StyledLabel } from "style/styleComponents/Form/input";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;

let exportCheckboxes = [
  [
    { name: "last_name", label: "Nazwisko" },
    { name: "first_name", label: "Imię" },
  ],
  [
    { name: "localization", label: "Lokacja" },
    { name: "section", label: "Dział" },
  ],
  [
    { name: "workplace", label: "Stanowisko pracy" },
    { name: "date_last", label: "Data ostatnich badań profilaktycznych" },
  ],
  [
    { name: "date_next", label: "Termin wykonania następnych badań" },
    { name: "hired_status", label: "Status zatrudnienia" },
  ],
  [
    { name: "ability_to_work", label: "Zdolność do pracy" },
    { name: "days_left", label: "Pozostało dni do badań" },
  ],
  [{ name: "is_me_actual", label: "Aktualność badań" }],
];

const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let initialValues = {
  specification: {
    last_name: true,
    first_name: true,
    localization: true,
    section: true,
    workplace: true,
    date_last: true,
    date_next: true,
    hired_status: true,
    ability_to_work: true,
    days_left: true,
    is_me_actual: true,
  },
  filter: {
    date_last_from: undefined,
    date_last_to: undefined,
    date_next_from: undefined,
    date_next_to: undefined,
    hired_status_id: undefined,
    is_me_actual: undefined,
  },
};

class GenerateRaportModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  accept(data) {
    let { print = () => {} } = this.props;

    let filterZeroId = (data) => {
      let keys = ["hired_status_id", "is_me_actual"];

      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = undefined;
        }
      }
      return data;
    };
    let stringBoolean = (data) => {
      let keys = ["is_me_actual"];
      for (let k of keys) {
        if (data[k] == "true") {
          data[k] = true;
        } else if (data[k] == "false") {
          data[k] = false;
        } else {
          data[k] = undefined;
        }
      }
      return data;
    };

    data.fitler = filterZeroId(data.filter);

    print(data);
    this.closeModal();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <ModalTitleWrapper>
              <span> Podsumowanie badań profilaktycznych pracownika</span>
              <Button
                bsStyle="danger"
                id="ModalListClose"
                onClick={this.closeModal}
              >
                X
              </Button>
            </ModalTitleWrapper>

            <Modal.Title>Generuj raport</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={initialValues}
              onSubmit={this.accept}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <>
                      <LeftPadding>
                        {exportCheckboxes.map((item) => {
                          return (
                            <TwoInputWrapper>
                              {item.map((subitem) => {
                                return (
                                  <FieldInputCheckBox
                                    name={`specification.${subitem.name}`}
                                    label={subitem.label}
                                  />
                                );
                              })}
                            </TwoInputWrapper>
                          );
                        })}
                      </LeftPadding>
                    </>
                    <h5>Filtruj dane</h5>
                    <LeftPadding>
                      <h5>Data ostatnich badań profilaktycznych</h5>

                      <TwoInputWrapper>
                        <FieldDayPicker
                          name={"filter.date_last_from"}
                          label={"Od"}
                        />
                        <FieldDayPicker
                          name={"filter.date_last_to"}
                          label={"Do"}
                        />
                      </TwoInputWrapper>
                    </LeftPadding>
                    <LeftPadding>
                      <h5>Termin wykonania następnych badań</h5>

                      <TwoInputWrapper>
                        <FieldDayPicker
                          name={"filter.date_next_from"}
                          label={"Od"}
                        />
                        <FieldDayPicker
                          name={"filter.date_next_to"}
                          label={"Do"}
                        />
                      </TwoInputWrapper>
                    </LeftPadding>
                    <LeftPadding>
                      <TwoInputWrapper>
                        <FieldSelect
                          name={"filter.hired_status_id"}
                          label={"Status zatrudnienia"}
                          data={[
                            {
                              id: "0",
                              name: "Wszyscy",
                            },
                            {
                              id: 2,
                              name: "Zatrudnieni",
                            },
                            {
                              id: 1,
                              name: "Niezatrudnieni",
                            },
                          ]}
                        />
                      </TwoInputWrapper>
                    </LeftPadding>
                    <LeftPadding>
                      <TwoInputWrapper>
                        <FieldSelect
                          name={"filter.is_me_actual"}
                          label={"Aktualność badań"}
                          data={[
                            {
                              id: "0",
                              name: "Wszyscy",
                            },
                            {
                              id: "true",
                              name: "Tak",
                            },
                            {
                              id: "false",
                              name: "Nie",
                            },
                          ]}
                        />
                      </TwoInputWrapper>
                    </LeftPadding>
                    <ButtonWrapper style={{ justifyContent: "flex-start" }}>
                      <Button
                        onClick={() => {
                          form.change("pdf", true);
                          form.change("xml", undefined);
                          handleSubmit();
                        }}
                      >
                        Wygeneruj PDF
                      </Button>
                      <Button
                        onClick={() => {
                          form.change("pdf", undefined);
                          form.change("xml", true);
                          handleSubmit();
                        }}
                      >
                        Wygeneruj XLS
                      </Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class GenerateRaport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: false,
    };
  }

  render() {
    let { open } = this.state;
    let { disabled } = this.props;
    return (
      <>
        <Button
          disabled={disabled}
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Generuj raport
        </Button>
        <GenerateRaportModal
          print={this.props.print}
          open={open}
          closeModal={() => {
            this.setState({ open: false });
          }}
        />
      </>
    );
  }
}
