import { defaultUrls as moduleUrls } from "../_staticData";

export const defaultUrls = {
  LIST: moduleUrls.METHODLIST,
  ADD: moduleUrls.METHODADD,
  EDIT: moduleUrls.METHODEDIT,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
