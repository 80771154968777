import axios from "axios";

export const client = axios.create({
  baseURL:"/api",
  withCredentials: true,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "User-token": "token"

  },
  proxy: {
    host: "127.0.0.1",
    port: 80,
    "changeOrigin": true
  }
});

