import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LinkContainer } from "../../components/LinkContainer";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { LayoutContent } from "../../components/Layout";
import Pagination from "../../components/Pagination";
import { DeleteButton } from "../../components/ListComponents";
import Button from "react-bootstrap/lib/Button";
import { bootstrapUtils } from "react-bootstrap/lib/utils";
import AccordionList, {
  AccordionButton,
  AccordionLineNameColumn,
  AccordionLineOptionColumn,
  AccordionLineBody,
} from "components/AccordionList";

import PageHeader from "../../components/PageHeader";
import { getLocalizations } from "../../actions/localizationActions";
import Modal from "../../components/Modal/SimpleAccept";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import {
  deleteLocalization,
  deleteDivision,
} from "../../actions/localizationActions";
import LoadingScreen from "../../components/LoadingScreen";
bootstrapUtils.addStyle(Button, "list-delete-button");

let FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > * {
    flex: 0 1 100%;
    &:last-child {
      flex: 0 0 20px;
      padding: 0 10px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -10px 20px;

  & > a {
    margin: 0 10px;
  }
`;
let sortData = (data, sortCallback) => {
  for (let item of data) {
    if (item.children && item.children.length) {
      item.children = sortData(item.children, sortCallback);
    }
  }
  return data.sort(sortCallback);
};

let decorateWithUrl = (item) => {
  return {
    ...item,
    url: item.division
      ? `/business/localization/division/edit/${item.id}`
      : `/business/localization/edit/${item.id}`,
    children: item.children
      ? item.children.map((subitem) => ({
          ...subitem,
          url: `/business/localization/edit/${subitem.id}`,
        }))
      : item.children,
  };
};

class LocalizationList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modal: {
        open: false,
      },
      localizations: [],
      sort: 0,
      sortData: [],
    };
    this.changeSort = this.changeSort.bind(this);
    this.deleteRequest = this.deleteRequest.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseDeleteRequest = this.handleCloseDeleteRequest.bind(this);
  }

  changeSort() {
    let { sort } = this.state;
    let { localization } = this.props;
    let { data } = localization;

    let newData = [...data];

    let newState = {};
    switch (sort) {
      case 0: {
        newState = {
          sort: 1,
          sortData: sortData(newData, (a, b) =>
            a.name.localeCompare(b.name, "pl", {
              caseFirst: "lower",
              numeric: true,
              sensitivity: "base",
            })
          ),
        };
        break;
      }
      case 1: {
        newState = {
          sort: 2,
          sortData: sortData(newData, (b, a) =>
            a.name.localeCompare(b.name, "pl", {
              caseFirst: "lower",
              numeric: true,
              sensitivity: "base",
            })
          ),
        };
        break;
      }
      case 2: {
        newState = { sort: 0, sortData: newData };
        break;
      }
    }

    this.setState(newState);
  }

  componentWillMount() {
    this.updateMessage();
    getLocalizations()(this.props.dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  componentDidMount() {
    let { localization } = this.props;

    // this.setState({
    //   sortData: { ...localization.data },
    // });
  }

  componentDidUpdate() {
    let { localization } = this.props;

    if (this.state.sortData.length !== localization.data.length) {
      this.setState({
        sortData: localization.data.map(decorateWithUrl),
      });
    }
  }

  deleteRequest({ division, entry_id }) {
    const that = this;
    return () => {
      that.setState({
        modal: { ...this.modal, open: true, division, entry_id },
      });
    };
  }

  handleDelete() {
    const { division, entry_id } = this.state.modal;
    const { dispatch } = this.props;

    const deleteFunction = division ? deleteDivision : deleteLocalization;
    deleteFunction(entry_id)(dispatch).then(this.handleServerResponse());
  }

  handleCloseDeleteRequest() {
    this.setState({
      modal: { ...this.modal, open: false, division: false, entry_id: null },
    });
  }

  handlePageChange(data) {
    let localizations = data.map(decorateWithUrl);
    this.setState({ data: localizations });
  }

  render() {
    const { localization } = this.props;
    const { modal, message, localizations, sort, sortData, data } = this.state;
    const AccordionLine = ({
      id,
      open,
      url,
      name,
      option_name,
      lineHeight,
      optionColumnWidth,
      optionColumn,
      level,
      handleChange,
      children,
      className,
      header,
      division,
    }) => (
      <AccordionLineBody
        lineHeight={"45px"}
        className={"accordion-item"}
        level={level}
        optionColumnWidth={"160px"}
        header={header}
      >
        <AccordionButton
          handleChange={handleChange}
          children={children}
          open={open}
        />
        <AccordionLineNameColumn
          onClick={
            header
              ? () => {
                  this.changeSort();
                }
              : undefined
          }
        >
          <FlexLine>
            <div>{url ? <Link to={url}>{name}</Link> : name} </div>
            <div>
              {header && sort > 0 ? (
                <i
                  className={`fa fa-long-arrow-${sort == 2 ? "down" : "up"}`}
                />
              ) : (
                ""
              )}
            </div>
          </FlexLine>
        </AccordionLineNameColumn>
        <AccordionLineOptionColumn>
          {!header ? (
            <DeleteButton
              onClick={this.deleteRequest({ division, entry_id: id })}
              handleClose={this.handleCloseDeleteRequest}
              name="Usuń"
            />
          ) : (
            <div>{header && option_name}</div>
          )}
        </AccordionLineOptionColumn>
      </AccordionLineBody>
    );

    return (
      <LoadingScreen fetched={localization.fetched}>
        <LayoutContent>
          {message && message.text && (
            <Col>
              <Alert bsStyle={message.className}>{message.text}</Alert>
            </Col>
          )}
          <PageHeader>Lista lokacji</PageHeader>
          <ButtonWrapper>
            <LinkContainer to="/business/localization/add">
              <Button>Dodaj lokację</Button>
            </LinkContainer>
            <LinkContainer to="/business/localization/add-division">
              <Button>Dodaj dywizję</Button>
            </LinkContainer>
          </ButtonWrapper>
          <div>
            <AccordionList
              data={data}
              header={["Nazwa", "Usuń lokację/dywizję"]}
              LineComponent={AccordionLine}
            />
          </div>
          <div>
            <Pagination
              handlePageChange={this.handlePageChange.bind(this)}
              data={sortData}
            />
          </div>
          <Modal
            title="Usuń lokalizację/dywizję"
            description={"Czy chcesz usunąć dany wpis?"}
            open={modal.open}
            handleAccept={this.handleDelete}
            handleClose={this.handleCloseDeleteRequest}
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  message: store.message,
}))(LocalizationList);

LocalizationList.propTypes = {
  localization: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      })
    ),
  }),
};
