import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {LayoutContent} from "./Layout";
import Box from "./Box";
import {Form, Field} from "react-final-form";
import {StyledInputBox} from "../style/styleComponents/Form/input";
import MultiSelect from "./MultiSelect";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import validator,{composeValidators}  from "./Validation";
import {ButtonWrapper} from "./ListComponents";
import {LinkContainer} from "./LinkContainer";

const LocalRow = styled.div`
  margin-bottom: 30px;
`;

const NameRow = LocalRow.extend`
  max-width: 530px;
  width: 100%;
`;

const ButtonRow = LocalRow.extend`
  display: flex;
  justify-content: flex-end;
`;

class DivisionForm extends React.Component {

  submit(data){
    const {handleSubmit} = this.props;
    const {name,selectedOptions = []} = data;
    const newData = {
      name,
      localizations : selectedOptions.map(item=>item.id)
    };

    handleSubmit(newData);
  }

  render() {
    const {title,options,data = {},message} = this.props;
    const selectedOptions = (data || {}).localizations || [];
    const initialValues = {name:data.name,selectedOptions:data.localizations.map(item=>({id:item}))};

    return (
      <LayoutContent>
        {message && message.text && <Col lg={12}><Alert bsStyle={message.className}>{message.text}</Alert></Col>}
        <Box title={title}>
          <Form
            onSubmit={this.submit.bind(this)}
            initialValues={{...initialValues}}
            render={({handleSubmit, pristine, invalid}) => (
              <form onSubmit={handleSubmit} style={{"width": "100%"}}>
                <NameRow>
                  <Field
                    name="name"
                    validate={validator.required}
                    render={({input, meta, ...rest}) => (
                      <StyledInputBox
                        {...input}
                        value={input.value}
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched ? meta.error : null}
                        label={"Nazwa dywizji"}
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}/></NameRow>
                <LocalRow>
                  <Field
                    name="selectedOptions"
                    render={({input, meta, ...rest}) => (
                      <MultiSelect
                        options={options}
                        selectedOptions={selectedOptions}
                        leftSelectTitle="Dostępne lokacje"
                        rightSelectTitle="Przypisane lokacje"
                        handleChange={(data)=>{
                          input.onChange(data);
                        }}
                      />
                    )}

                  />
                </LocalRow>
                <ButtonWrapper>
                  <Button type="submit" bsStyle="primary">
                    Zapisz
                  </Button>
                  <LinkContainer to="/business/localization" exact>
                    <Button>
                      Anuluj
                    </Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}/>
        </Box>
      </LayoutContent>);
  }
}

export default DivisionForm;

DivisionForm.propTypes = {
  title:PropTypes.string,
  options:PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string
    })
  ),
  data:PropTypes.shape({
    name:PropTypes.string,
    localizations:PropTypes.arrayOf(PropTypes.number)
  }),
  selectedOptions:PropTypes.arrayOf(PropTypes.number)
}