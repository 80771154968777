import React, { useState, useEffect } from "react";
import Pagination from "components/Pagination";
import { TableSortHeader } from "components/TableSortable";
import { StyledTableWrapper } from "components/ListComponents";
import {
  ButtonWithIcon,
  ButtonWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
let sortSwitch = (value) => {
  switch (value) {
    case 0:
      return 1;
    case 1:
      return -1;
    default:
      return 0;
  }
};

let sortData = ({ sortProp, stateData, sort_direction, sortType }) => {
  return stateData.sort(function (a, b) {
    if (sortType == "string") {
      let ta = typeof a[sortProp] !== "string" ? a.toString() : a[sortProp];
      let tb = typeof b[sortProp] !== "string" ? b.toString() : b[sortProp];
      return (
        ta.localeCompare(tb, "pl", {
          caseFirst: "lower",
          numeric: true,
          sensitivity: "base",
        }) * sort_direction
      );
    } else {
      return a[sortProp] > b[sortProp] ? sort_direction : sort_direction * -1;
    }
  });
};

function Header(props) {
  let [headers, setHeaders] = useState(
    (props.headers || []).map((item) => ({ ...item, sort: 0 }))
  );
  let [sortObj, setSortObj] = useState({
    direction: 0,
    prop: "",
  });
  let [data, setData] = useState([...props.data]);

  useEffect(() => {
    setData(() => [...props.data]);
  }, [props.data]);

  useEffect(() => {
    let result = sortObj.prop
      ? sortData({
          sortProp: sortObj.prop,
          stateData: data,
          sort_direction: sortObj.direction,
          sortType: sortObj.type,
        })
      : props.data;

    props.sortedData(result);
  }, [sortObj, data]);

  let sort = (index) => () => {
    let newData = [...headers];
    let sort_direction = 0;
    let sort_prop;
    let sort_type;
    newData = newData.map((item, subindex) => {
      if (subindex == index) {
        sort_direction = sortSwitch(item.sort);
        sort_prop =
          sort_direction == 1 || sort_direction == -1 ? item.prop : undefined;
        sort_type =
          sort_direction == 1 || sort_direction == -1
            ? item.sort_type
            : undefined;
        return {
          ...item,
          sort: sort_direction,
        };
      } else {
        return {
          ...item,
          sort: 0,
        };
      }
    });

    setHeaders(newData);
    setSortObj({
      ...sortObj,
      direction: sort_direction,
      prop: sort_prop,
      type: sort_type,
    });
  };

  return (
    <tr>
      {headers.map((item, index) => {
        return (
          <td
            style={{
              cursor: item.prop && sort(index) ? "pointer" : "default",
            }}
            onClick={item.prop && sort(index)}
          >
            {item.name}{" "}
            {item.sort ? (
              <i
                className={`fa fa-long-arrow-${item.sort > 0 ? "up" : "down"}`}
              />
            ) : item.prop ? (
              <>
                <span style={{ whiteSpace: "nowrap" }}>
                  <i className={`fa fa-long-arrow-up`} />
                  <i className={`fa fa-long-arrow-down`} />
                </span>
              </>
            ) : (
              ""
            )}
          </td>
        );
      })}
    </tr>
  );
}

export default function Component(props) {
  let {
    data = [],
    headers,
    renderSearcher = () => null,
    render = () => null,
    getSortedData,
  } = props;

  let [sortedData, setSortedData] = useState(data);
  let [paginationData, setPaginationData] = useState(data);

  useEffect(() => {
    if (getSortedData) {
      getSortedData(sortedData);
    }
  }, [sortedData]);

  return (
    <>
      <StyledTableWrapper style={{ overflowX: "auto" }}>
        <StyledTable cellspacing={0}>
          {renderSearcher}
          <Header
            data={data}
            headers={headers}
            sortedData={(data) => {
              setSortedData([...data]);
            }}
          />
          {render(paginationData)}
        </StyledTable>
      </StyledTableWrapper>
      <Pagination
        handlePageChange={(data) => {
          setPaginationData(data);
        }}
        data={sortedData}
      />
    </>
  );
}
