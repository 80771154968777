import LList from "./List";
import LAdd from "./Add";
import LRepeat from "./Repeat";
import LEdit from "./Edit";
import LShow from "./Show";

export const List = LList;
export const Add = LAdd;
export const Edit = LEdit;
export const Show = LShow;
export const Repeat = LRepeat;
