import LList from "./List";
import LRegister from "./Register";
import LAdd from "./Add";
import LData from "./Data";
import LQuestion from "./Question";
import LNotification from "./Notification";
import LTaskList from "./Task/List";
import LTaskAdd from "./Task/Add";
import LTaskEdit from "./Task/Edit";
import LListTypes from "./List.Types";
import LAddTypes from "./Add.Types";
import LEditTypes from "./Edit.Type";

export const List = LList;
export const Register = LRegister;
export const Add = LAdd;
export const Data = LData;
export const Question = LQuestion;
export const TaskList = LTaskList;
export const TaskAdd = LTaskAdd;
export const TaskEdit = LTaskEdit;
export const ListTypes = LListTypes;
export const AddTypes = LAddTypes;
export const EditTypes = LEditTypes;
export const Notification = LNotification;
