import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import LoadingScreen from "../../components/LoadingScreen";
import PageHeader from "react-bootstrap/lib/PageHeader";
import { LayoutContent } from "../../components/Layout";
import { ButtonWrapper, ButtonWithIcon } from "../../components/ListComponents";
import { LinkContainer } from "../../components/LinkContainer";
import styled from "styled-components";
import { StyledTableList } from "../../components/ListComponents";
import TableSortable from "../../components/TableSortable";
import { Link } from "react-router-dom";
import { DeleteButton } from "../../components/ListComponents";
import SideMenu from "./_SideMenu";
import { getDraftHazardIdentifications } from "../../actions/hazardIdentificationActions";

const StyledTableWrapper = styled.div`
  overflow-x: auto;
`;

class AuditTypeList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: [],
      modal: {
        open: false,
        entry_id: null,
      },
    };

    // this.handleDelete = this.handleDelete.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    getDraftHazardIdentifications()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    this.updateMessage();
  }

  openModal(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.modal, open: true, entry_id } });
    };
  }

  closeModal() {
    this.setState({
      modal: { ...this.state.modal, open: false, entry_id: null },
    });
  }

  render() {
    const {
      audit: { typeData = [], permission },
    } = this.props;
    const { modal, message } = this.state;

    return (
      <LoadingScreen fetched={true}>
        <LayoutContent
          error404={this.state.error_code == 404}
          SideMenu={<SideMenu permission={permission} />}
        >
          <PageHeader>
            Szablony listy kontrolnej identyfikacji zagrożeń
          </PageHeader>
          <ButtonWrapper>
            <LinkContainer to="/super-admin/draft/audit-type/add">
              <ButtonWithIcon icon="fa fa-plus" name="Dodaj nowy szablon" />
            </LinkContainer>
          </ButtonWrapper>
          <StyledTableWrapper>
            <StyledTableList quickMenu quickMenuTrird nowrapTitle>
              <TableSortable
                headers={[
                  { name: "L.p." },
                  { name: "Nazwa", prop: "title" },
                  { name: "Usuń" },
                ]}
                data={typeData}
                render={(sortedData) => {
                  return sortedData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/super-admin/draft/audit-type/edit/${item.id}`}
                        >
                          {item.title}
                        </Link>
                      </td>
                      <td>
                        <DeleteButton onClick={this.openModal(item.id)} />
                      </td>
                    </tr>
                  ));
                }}
              ></TableSortable>
            </StyledTableList>
          </StyledTableWrapper>
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
  message: store.message,
}))(AuditTypeList);
