import React from "react";

import Pagination from "components/Pagination";
import { StyledTable } from "components/ListComponents";
import { TableSortHeader, sortData } from "components/TableSortable";

export class TableSortableWithPagination extends TableSortHeader {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      paginatedData: [],
    };
  }

  handlePageChange(data) {
    this.setState({ paginatedData: data });
  }

  render() {
    let { children } = this.props;
    let { paginatedData } = this.state;
    let sort_direction = this.state.sort.direction;
    let sortProp = this.state.sort.prop;
    let sortType = this.state.sort.type;

    let sortedData = sortProp
      ? [
          ...sortData({
            sortProp,
            stateData: this.state.data,
            sort_direction,
            sortType,
            propsData: this.props.data,
          }),
        ]
      : this.props.data;

    return (
      <>
        <StyledTable>
          <thead>
            <tr>
              {this.state.headers.map((item, index) => {
                return (
                  <th
                    style={{
                      cursor:
                        item.prop && this.sort(index) ? "pointer" : "default",
                    }}
                    onClick={item.prop && this.sort(index)}
                  >
                    {item.name}{" "}
                    {item.sort ? (
                      <i
                        className={`fa fa-long-arrow-${
                          item.sort > 0 ? "up" : "down"
                        }`}
                      />
                    ) : item.prop ? (
                      <>
                        <i className={`fa fa-long-arrow-up`} />
                        <i className={`fa fa-long-arrow-down`} />
                      </>
                    ) : (
                      ""
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{children && children(paginatedData)}</tbody>
        </StyledTable>
        <Pagination
          handlePageChange={this.handlePageChange.bind(this)}
          sort_direction={sort_direction}
          data={sortedData}
        />
      </>
    );
  }
}
