import styled from "styled-components";
import { media } from "style/style-utils";

export const ButtonWrapperRaw = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -10px 20px;

  ${media.sm`
    flex-wrap:wrap;
  `}

  & > * {
    margin: 0 10px;
    ${media.xs`
    ${(props) =>
      props.nowrap
        ? ""
        : `width:100%; margin-bottom:10px !important;&:last-child {margin-bottom:0px;}`}
      
    `}
  }
`;

ButtonWrapperRaw.displayName = "ButtonWrapper";

export const ButtonWrapper = ButtonWrapperRaw;

export const TwoInputWrapper = styled.div`
  display: flex;

  justify-content: space-between;
  /* align-items: ${(props) => (props.flexEnd ? "flex-end" : "flex-start")}; */
  align-items: stretch; 
  padding-top: ${(props) => (props.noPaddingTop ? "0" : "5px")};

  ${media.xs`
    display: ${(props) => (props.noXS ? "none" : "block")}
  `}

  & > * {
    flex-basis: 48%;
  }
`;

export let LeftPadding = styled.div`
  padding-left: 20px;
`;

export let Section = styled.div`
  margin-bottom: 20px;
`;

export const StyledInputCheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  ${media.sm`
    flex-wrap:wrap;
    justify-content:space-around;
  `}
  & > div {
    &:first-child {
      ${(props) => (props.category ? "padding-left:20px;" : "")};
    }
    margin-right: 15px;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;
