"use strict";

import { applyMiddleware, createStore } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import { routerMiddleware } from "react-router-redux";

import thunk from "redux-thunk";
import logger from "redux-logger";

import { client } from "./axios";

import { createBrowserHistory as createHistory } from "history";
import reducer from "./reducers";

export const history = createHistory();

const middleware = (() => {
  if (process.env.NODE_ENV !== "production") {
    return applyMiddleware(
      thunk,
      axiosMiddleware(client),
      routerMiddleware(history),
      logger
    );
  }
  return applyMiddleware(
    thunk,
    axiosMiddleware(client),
    routerMiddleware(history)
  );
})();

export const store = createStore(reducer, {}, middleware);
