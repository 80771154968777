import React, { Fragment } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import Modal from "../Modal/SimpleAccept";
import validator, { composeValidators } from "../Validation";
import countMaxForFormula from "./countMaxForFormula";
import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
  StyledInputMessage,
} from "../../style/styleComponents/Form/input";
import { ButtonWrapper } from "../ListComponents";
import ColorPicker from "../ColorPicker";
let modaDefaultState = {
  open: false,
  entry_id: null,
  entry_name: null,
  callback: () => {},
};

let LocalDeleteButton = ({ className, ...props }) => {
  return (
    <div className={className}>
      <Button type="button" {...props}>
        <i className={"fa fa-trash"} />
      </Button>
    </div>
  );
};

let StyledLocalDeleteButton = styled(LocalDeleteButton)`
  height: 34px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const MethodButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #eee;
`;

let RiskLevelDescriptionHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;

  &:nth-child(4) {
    flex: 0 0 46px;
  }
  &:nth-child(5) {
    flex: 0 0 57px;
  }
`;
let RiskLevelDescriptionListWrapper = styled.div`
  margin-bottom: 20px;
`;

const RiskLevelDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
    box-sizing: border-box;

    &:nth-child(4) {
      flex: 0 0 66px;
    }
    &:nth-child(5) {
      flex: 0 0 57px;
    }
  }
`;
const MatrixPairWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  & > div {
    width: 100%;
    padding: 0 10px;
    &:nth-child(4) {
      flex: 0 0 66px;
    }
  }
`;

let customValidationMax = (values) => (value) => {
  let min = 0;
  if (!value) {
    return "Dane wymagane.";
  }
  try {
    let max = countMaxForFormula(values);
    if (value < min) {
      return `Wartość nie może być mniejsza od ${min}`;
    }
    if (value > max) {
      return `Wartość nie może być większa niż ${max}`;
    }
  } catch (err) {
    return "Wzór na wartość ryzyka nie jest zbierzny z parametrami";
  }
};

export default class RiskCalculationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dictionary_ra_method_calc_id: undefined,
      fetched: false,
    };
  }

  componentDidUpdate() {
    let { values } = this.props;
    if (
      this.state.fetched == false &&
      values &&
      values.dictionary_ra_method_calc_id
    ) {
      this.setState({
        dictionary_ra_method_calc_id: values.dictionary_ra_method_calc_id,
        fetched: true,
      });
    }
  }

  changeMethod(mode_value) {
    return () => {
      let newState = {
        dictionary_ra_method_calc_id: mode_value,
      };
      this.setState({ ...newState });
    };
  }

  render() {
    let { dictionary_ra_method_calc_id } = this.state;
    let { fields, values, disabled } = this.props;
    return (
      <div>
        <div className="page-header">
          <h2>Kalkulacja ryzyka</h2>
        </div>
        <Field
          name={`dictionary_ra_method_calc_id`}
          validate={validator.required}
          render={({ input, meta, ...rest }) => (
            <Fragment>
              <MethodButtonWrapper>
                <Button
                  disabled={disabled}
                  type="button"
                  onClick={() => {
                    this.changeMethod(1)();
                    input.onChange(1);
                  }}
                >
                  Metoda liczbowa
                </Button>

                <Button
                  type="button"
                  onClick={() => {
                    this.changeMethod(2)();
                    input.onChange(2);
                  }}
                  disabled={
                    disabled ||
                    !values.parameters ||
                    (values.parameters && values.parameters.length != 2)
                  }
                >
                  Metoda matrycowa
                </Button>
              </MethodButtonWrapper>

              <div>
                <StyledInputMessage>
                  {meta.error && meta.touched ? meta.error : null}
                </StyledInputMessage>
              </div>
            </Fragment>
          )}
        />
        <FormSpy subscribe={{ values: true }}>
          {({ values }) => {
            return (
              <>
                <div>
                  {dictionary_ra_method_calc_id == 1 && (
                    <NumericalMethod
                      disabled={disabled}
                      parameters={values.parameters}
                      mathematical_formula={values.mathematical_formula}
                      values={values}
                    ></NumericalMethod>
                  )}
                </div>
                <div>
                  {dictionary_ra_method_calc_id == 2 && (
                    <MatrixMethod
                      disabled={disabled}
                      fields={fields}
                      values={values}
                    />
                  )}
                </div>
              </>
            );
          }}
        </FormSpy>
      </div>
    );
  }
}

class NumericalMethod extends React.Component {
  render() {
    let { mathematical_formula, parameters, values, disabled } = this.props;

    return (
      <div>
        <Field
          disabled={disabled}
          name={`risk_assessment_mode`}
          render={({ input, meta, ...rest }) => (
            <input type="hidden" value="1" />
          )}
        />
        <Field
          name={`mathematical_formula`}
          // validate={customValidationMax({ mathematical_formula, parameters })}
          render={({ input, meta, ...rest }) => (
            <StyledInputBox
              label="Wzór na wartość ryzyka"
              value={input.value}
              disabled={disabled}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              onChange={(event) => {
                input.onChange(event.target.value);
              }}
            />
          )}
        />
        <StyledLabel>Opis poziomów ryzyka</StyledLabel>
        <FieldArray
          name={`risk_level_descriptions`}
          validate={validator.required}
          disabled={disabled}
        >
          {({ fields, meta }) => (
            <RiskLevelDescription
              values={values}
              fields={fields}
              meta={meta}
              disabled={disabled}
            />
          )}
        </FieldArray>
      </div>
    );
  }
}
class RiskLevelDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.state, modal: { ...modaDefaultState } };
    this.deleteModalAccept = this.deleteModalAccept.bind(this);

    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  deleteModalAccept() {
    const { callback } = this.state.modal;
    callback();
    this.modalClose();
  }

  modalOpen(name, callback) {
    return () => {
      this.setState({
        modal: {
          ...this.state.modal,
          open: true,
          entry_id: false,
          entry_name: name,
          callback,
        },
      });
    };
  }

  modalClose() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
        entry_id: false,
        entry_name: null,
        callback: () => {},
      },
    });
  }

  render() {
    let { modal } = this.state;

    let { fields, values, meta, disabled } = this.props;

    return (
      <Fragment>
        <Field
          name={`risk_assessment_mode`}
          render={({ input, meta, ...rest }) => (
            <input type="hidden" value="2" />
          )}
        />
        <RiskLevelDescriptionWrapper>
          <RiskLevelDescriptionHeader>Od</RiskLevelDescriptionHeader>
          <RiskLevelDescriptionHeader>Do</RiskLevelDescriptionHeader>
          <RiskLevelDescriptionHeader>Opis</RiskLevelDescriptionHeader>
          <RiskLevelDescriptionHeader>Kolor</RiskLevelDescriptionHeader>
          <RiskLevelDescriptionHeader></RiskLevelDescriptionHeader>
        </RiskLevelDescriptionWrapper>
        <RiskLevelDescriptionListWrapper>
          {fields.map((name, index) => {
            const field = fields.value[index];

            return (
              <Fragment>
                <RiskLevelDescriptionWrapper>
                  <Field
                    name={`${name}.from`}
                    validate={index != 0 && validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        type="number"
                        disabled={disabled || index == 0}
                        value={index == 0 ? 0 : input.value}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name={`${name}.to`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        type="number"
                        value={input.value}
                        disabled={disabled}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name={`${name}.description`}
                    validate={
                      fields.length && index + 1
                        ? composeValidators(validator.required)
                        : validator.required
                    }
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        value={input.value}
                        error={meta.error && meta.touched}
                        disabled={disabled}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name={`${name}.color`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <ColorPicker
                        disabled={disabled}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                        value={input.value}
                      />
                    )}
                  />
                  <StyledLocalDeleteButton
                    disabled={disabled}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  />
                </RiskLevelDescriptionWrapper>
              </Fragment>
            );
          })}
        </RiskLevelDescriptionListWrapper>
        <ButtonWrapper>
          <Button
            type="button"
            disabled={disabled}
            onClick={() => {
              if (fields.length < 1) {
                fields.push({ from: "0", color: "#000000" });
              } else {
                fields.push({ color: "#000000" });
              }
            }}
          >
            Dodaj wartość
          </Button>
        </ButtonWrapper>
        {/* <div>{meta && meta.error}</div> */}

        <Modal
          open={modal.open}
          title={`Usuń wartość ${
            modal.entry_name ? ` - ${modal.entry_name}` : ""
          }.`}
          description={"Czy na pewno chcesz usunąć dany wpis? "}
          handleAccept={this.deleteModalAccept}
          handleClose={this.modalClose}
        />
      </Fragment>
    );
  }
}

class MatrixMethod extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(props) {}

  render() {
    let { fields, values } = this.props;
    let parametr1 = values.parameters[0].variables || [];
    let parametr2 = values.parameters[1].variables || [];

    return (
      <Fragment>
        <MatrixPairWrapper>
          <div>Skutki</div>
          <div>Prawdopodobieństwo</div>
          <div>Opis</div>
          <div>Kolor</div>
        </MatrixPairWrapper>
        {parametr1.map((mainParametr, index) => {
          return parametr2.map((secondParametr, subindex) => {
            return (
              <MatrixPairWrapper>
                <div>
                  <StyledInputBox disabled={true} value={mainParametr.name} />
                </div>
                <div>
                  <StyledInputBox disabled={true} value={secondParametr.name} />
                </div>
                <div>
                  <Field
                    name={`ra_method_matrix_method_descriptions.${mainParametr.uuid}.${secondParametr.uuid}.description`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        value={input.value}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    name={`ra_method_matrix_method_descriptions.${mainParametr.uuid}.${secondParametr.uuid}.color`}
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <ColorPicker
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                        value={input.value}
                      />
                    )}
                  />
                </div>
              </MatrixPairWrapper>
            );
          });
        })}
      </Fragment>
    );
  }
}
