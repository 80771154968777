import React from "react";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Button from "react-bootstrap/lib/Button";

import {
  getAuditQuestions,
  commentOpeningAction,
  downloadAuditQuestionAttachment,
  getAudit,
  saveAuditQuestionsAnswers,
  addAuditQuestionAttachment,
  addAuditQuestionAttachmentLocal,
  addAuditNewQuestionLocal,
  deleteAuditQuestionAttachment,
  deleteAuditCustomQuestion,
  deleteAuditCustomQuestionLocal,
  resetAuditNewQuestionLocal,
} from "actions/auditActions";

import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import dataToFormData from "components/dataToFormData";
import { ButtonWrapper } from "components/ListComponents";
import { LinkContainer } from "components/LinkContainer";
import LoadingScreen from "components/LoadingScreen";
import saveBlobFile from "components/saveBlobFile";
import { ButtonWithIcon } from "components/ListComponents";
import Modal from "components/Modal/SimpleAccept";
import PDFModal, { PDFViewModalSchema } from "components/Modal/PDFViewModal";
import ImageModal, {
  ImageViewModalSchema,
} from "components/Modal/ImageViewModal";
import QuestionForm from "components/QuestionsForm/AuditQuestions";

import styled from "styled-components";
import {
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInputBox,
} from "style/styleComponents/Form/input";

import QuestionLine from "./_questionLine";
import SideMenu from "./_SideMenu";

class AuditQuestions extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      pdfModal: { ...PDFViewModalSchema, downloadData: {} },
      imageModal: { ...ImageViewModalSchema, downloadData: {} },
      modalDelete: {
        open: false,
        audit_question_id: null,
        attachment_id: null,
        name: null,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getInitialData = this.getInitialData.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleSubmitAttachment = this.handleSubmitAttachment.bind(this);
    this.handleSubmitAttachmentLocal = this.handleSubmitAttachmentLocal.bind(
      this
    );
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.deleteCustomQuestion = this.deleteCustomQuestion.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.handleDeleteAttachmentRequest = this.handleDeleteAttachmentRequest.bind(
      this
    );
    this.closeModal = this.closeModal.bind(this);
    this.openPDFViewModal = this.openPDFViewModal.bind(this);
    this.handleCommentFieldOpening = this.handleCommentFieldOpening.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      audit: { singleData: auditData },
    } = this.props;
    const { audit_id } = match.params;

    if (!Object.keys(auditData).length) {
      getAudit(audit_id)(dispatch).then(this.handleServerResponse());
    }

    getAuditQuestions(audit_id)(dispatch).then(this.handleServerResponse());
  }

  openPDFViewModal(downloadData) {
    return () => {
      const { match } = this.props;
      const { audit_id } = match.params;
      const { file } = downloadData;
      const url = `/uploads/audit/${audit_id}/attachment/${file}`;

      const modal_name = (() => {
        switch (true) {
          case /\.pdf$/.test(file): {
            return "pdfModal";
          }
          case /\.(jpg|png|svg)$/.test(file): {
            return "imageModal";
          }
          default:
            return false;
        }
      })();
      if (modal_name) {
        this.setState({
          [modal_name]: {
            ...this.state.pdfModal,
            open: true,
            name: file,
            simpleUrl: url,
            file: `/api${url}`,
            downloadData,
          },
        });
      }
    };
  }

  closeViewModal(modalName) {
    this.setState({ [modalName]: { ...PDFViewModalSchema, downloadData: {} } });
  }

  handleSubmit(formData) {
    const {
      dispatch,
      match,
      history,
      audit: { newQuestionsData, questionsData },
    } = this.props;
    const { audit_id } = match.params;

    saveAuditQuestionsAnswers(
      audit_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/audit/task/${audit_id}`);
      })
    );
  }

  getInitialData(questionsData) {
    const that = this;
    let result = questionsData.reduce((obj, item) => {
      if (item.questions) {
        return { ...obj, ...that.getInitialData(item.questions) };
      } else {
        obj[`question_${item.id}_answer_id`] =
          item.dictionary_audit_question_answer_id;
        obj[`question_${item.id}_comment`] = item.comment;

        if (!item.audit_question_id) {
          obj[`question_${item.id}_name`] = item.name;
        }
      }

      return obj;
    }, {});
    return result;
  }

  handleDownload(file, name, attachmentIndex, index, subIndex) {
    return () => {
      const {
        dispatch,
        audit: {
          singleData: { symbol },
        },
        match,
      } = this.props;
      const { audit_id } = match.params;
      const url = `/uploads/audit/${audit_id}/attachment/${file}`;
      const ext = file.split(".").pop();
      downloadAuditQuestionAttachment(
        url,
        attachmentIndex,
        index,
        subIndex
      )(this.props.dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `${symbol}-${name}.${ext}`;
          saveBlobFile(file, fileName);
        }
      });
    };
  }

  handleSubmitAttachment(audit_question_id) {
    return (values) => {
      const { dispatch, match } = this.props;
      const { audit_id } = match.params;
      const formData = dataToFormData({ ...values, audit_question_id });
      return addAuditQuestionAttachment(
        audit_id,
        audit_question_id,
        formData
      )(dispatch).then(this.handleServerResponse());
    };
  }

  handleSubmitAttachmentLocal(index) {
    const { dispatch } = this.props;
    return (values) => {
      return addAuditQuestionAttachmentLocal({
        index,
        file: values.question_null_attachment,
      })(dispatch);
    };
  }

  handleDeleteAttachmentRequest(audit_question_id, attachment_id, name) {
    return () => {
      this.setState({
        modalDelete: {
          ...this.state.modalDelete,
          open: true,
          audit_question_id,
          attachment_id,
          name,
        },
      });
    };
  }

  handleCommentFieldOpening(id, index, subindex) {
    const { dispatch } = this.props;

    return () => {
      // commentOpeningAction
      commentOpeningAction({ custom: id ? false : true, index, subindex })(
        dispatch
      );
    };
  }

  closeModal() {
    this.setState({
      modalDelete: {
        ...this.state.modalDelete,
        open: false,
        audit_question_id: null,
        attachment_id: null,
        name: null,
      },
    });
  }

  handleDeleteAttachment() {
    const { audit_question_id, attachment_id } = this.state.modalDelete;
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    deleteAuditQuestionAttachment(
      audit_id,
      audit_question_id,
      attachment_id
    )(dispatch).then(this.handleServerResponse());
  }

  addNewQuestion() {
    const { dispatch } = this.props;
    addAuditNewQuestionLocal()(dispatch);
  }

  deleteCustomQuestion(audit_question_id) {
    return () => {
      const { dispatch, match } = this.props;
      const { audit_id } = match.params;
      deleteAuditCustomQuestion(audit_id, audit_question_id)(dispatch);
    };
  }

  deleteAuditCustomQuestionLocal(index) {
    return () => {
      const { dispatch } = this.props;
      deleteAuditCustomQuestionLocal(index)(dispatch);
    };
  }

  render() {
    const {
      audit: {
        singleData,
        questionsData,
        newQuestionsData,
        answerData,
        permission,
        attachmentFetching,
      },
      match,
    } = this.props;
    const { pdfModal, imageModal } = this.state;
    const { audit_id } = match.params;
    const { message } = this.state;
    const initialData = this.getInitialData(questionsData);
    const readOnly = !permission[3];
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={
          <SideMenu
            permission={permission}
            audit_id={audit_id}
            stage={singleData.stage}
          />
        }
      >
        <QuestionForm
          singleData={singleData}
          questionsData={questionsData}
          newQuestionsData={newQuestionsData}
          answerData={answerData}
          permission={permission}
          attachmentFetching={attachmentFetching}
          SideMenu={SideMenu}
          match={match}
          handleSubmit={this.handleSubmit}
          SideMenu={
            <SideMenu
              permission={permission}
              audit_id={audit_id}
              stage={singleData.stage}
            />
          }
        />
        <PDFModal
          open={pdfModal.open}
          handleClose={this.closeViewModal.bind(this, "pdfModal")}
          title={pdfModal.name}
          handleDownload={this.handleDownload(
            pdfModal.downloadData.file,
            pdfModal.downloadData.name,
            pdfModal.downloadData.attachmentIndex,
            pdfModal.downloadData.index,
            pdfModal.downloadData.subIndex
          )}
          file={pdfModal.file}
        />
        <ImageModal
          open={imageModal.open}
          handleClose={this.closeViewModal.bind(this, "imageModal")}
          title={imageModal.name}
          handleDownload={this.handleDownload(
            imageModal.downloadData.file,
            imageModal.downloadData.name,
            imageModal.downloadData.attachmentIndex,
            imageModal.downloadData.index,
            imageModal.downloadData.subIndex
          )}
          file={imageModal.file}
        />
        <Modal
          title={`Usuń załącznik - ${this.state.modalDelete.name}`}
          description="Czy chcesz usunąć dany załącznik?"
          open={this.state.modalDelete.open}
          handleAccept={this.handleDeleteAttachment}
          handleClose={this.closeModal}
        />
      </LayoutContent>
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    resetAuditNewQuestionLocal()(dispatch);
  }
}

export default connect((store) => ({
  audit: store.audit,
}))(AuditQuestions);
