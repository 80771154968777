import React from "react";
import Table from "react-bootstrap/lib/Table";
import styled from "styled-components";

export const TableDefault = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & td,
  & th {
    border-left: 2px solid #ddd;
    padding-left: 5px;
  }
  & thead {
    background-color: #e6e6e6;
  }
  & tr {
    &:nth-child(2n + 1) {
      background-color: #e6e6e6;
    }
  }
`;

export const TableListPage = styled(Table)`
  font-size: 12px;

  border-bottom: 1px solid ${(props) => props.theme.tableBorderColor};
  border-right: 1px solid ${(props) => props.theme.tableBorderColor};
  &:nth-child(2n) {
    background: #f9f9f9;
  }
  & tr,
  & > tbody > tr,
  & thead {
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};
    border-left: 1px solid ${(props) => props.theme.tableBorderColor};

    & > td,
    & > th {
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
      padding: 8px;
      vertical-align: middle !important;
    }
  }

  & tr {
    &:nth-child(2n -1) {
      background: #fff;
    }
  }
`;

export const StyledTableListWithSearch = styled(Table)`
  font-size: 12px;
  &.table {
  }
  & th {
    border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    padding: 5px;
    box-sizing: border-box;
  }
  & tr {
    &:nth-child(2n) {
      background: #f9f9f9;
    }
    &:nth-child(-n + 2) {
      border: none;
      background: inherit;
      &:hover {
        background: inherit;
      }
      & > td {
        border: none;
      }
    }
    & > td {
      & > * {
        // width: 100%;
      }
      &:last-child {
        width: 70px !important;
        text-align: center;
      }
    }
    &:nth-child(1) {
      td {
        padding: 8px;
        box-sizing: border-box;
        &:fist-child {
          & * {
            max-width: 48px;
          }
        }
      }
    }
    &:nth-child(3) {
      & > td {
        font-weight: 700;
        vertical-align: top;
      }
    }
    &:nth-child(n + 3) {
      border-top: 1px solid ${(props) => props.theme.tableBorderColor};
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
      & > td {
        height: 33px;
        padding: 8px;
        box-sizing: border-box;
        &:nth-child(1) {
          max-width: 30px;
          ${(props) =>
            props.firstColumnWidth ? `width:${props.firstColumnWidth}` : ""}
          text-align:center;
        }
        &:nth-child(n + 2) {
          border-left: 1px solid ${(props) => props.theme.tableBorderColor};
        }
        &:last-child {
          border-right: 1px solid ${(props) => props.theme.tableBorderColor};
        }
      }
    }

    &:nth-child(n + 4) {
      vertical-align: center;
    }
    &:nth-child(4) {
      & > td {
        border-top: 2px solid ${(props) => props.theme.tableBorderColor};
      }
    }
    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
  &.table > tbody > tr {
    &:nth-child(n + 4) {
      & > td {
        vertical-align: middle;
      }
    }
  }
`;
