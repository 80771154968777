import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Link } from "react-router-dom";
import moment from "moment";

import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";

import {
  deleteRA,
  getRAsArchive,
  getRAsArchiveFilter,
  getRAMethods,
  printRAReport,
  printRADocument,
} from "actions/riskAssessmentAction";
import { getUsersLocalizations } from "actions/localizationActions";
import { getUserSectionsIndependently } from "actions/sectionActions";
import { getUserWorkplacesWithout } from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import PageHeader from "components/PageHeader";
import { ButtonWrapper } from "components/Wrappers";
import Pagination from "components/Pagination";
import {
  ButtonWithIcon,
  StyledTableWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";
import TableSortableWithPagination from "components/TableSortable/Register";
import saveBlobFile from "components/saveBlobFile";
import Modal from "components/Modal/SimpleAccept";

import PrintModal from "../List/PrintModal";
import SaveAsModal from "./SaveAsModal";
import SideMenu from "../_SideMenu";
import { setUrls } from "./_staticData";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "name", () => null);
  changeValue(state, "date_execution", () => null);
  changeValue(state, "date_update", () => null);
  changeValue(state, "symbol", () => null);
  changeValue(state, "ra_method_id", () => "0");
  changeValue(state, "localization_id", () => "0");
  changeValue(state, "section_name", () => "0");
  changeValue(state, "workplace_id", () => "0");
};

class List extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      urls: setUrls(),
      readOnlyState: true,
      modal: {
        open: false,
      },
      modalPrint: {
        open: false,
      },
    };

    this.filterSubmit = this.filterSubmit.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.printRADocuments = this.printRADocuments.bind(this);
    this.printRegister = this.printRegister.bind(this);
    this.deleteRA = this.deleteRA.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;
    this.updateMessage();

    getRAsArchive()(dispatch).then(this.handleServerResponse({}, () => {}));

    getUsersLocalizations()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getRAMethods()(dispatch).then(this.handleServerResponse({}, () => {}));
    getUserWorkplacesWithout()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  clearFilter(form) {
    let { dispatch } = this.props;
    return () => {
      form.mutators.clearFilterMutator();
      getRAsArchive()(dispatch).then(this.handleServerResponse());
    };
  }

  deleteRA() {
    let { modal } = this.state;
    let { dispatch } = this.props;

    let { entry_id } = modal;

    deleteRA(entry_id)(dispatch).then(this.handleServerResponse());
  }

  filterSubmit(data) {
    let { dispatch } = this.props;
    let filterZeroId = (data) => {
      let keys = [
        "ra_method_id",
        "localization_id",
        "section_name",
        "workplace_id",
      ];

      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = null;
        }
      }
      return data;
    };

    getRAsArchiveFilter(filterZeroId(data))(dispatch).then(
      this.handleServerResponse()
    );
  }

  printRegister() {
    let { dispatch } = this.props;
    let data = this.state.selectedData;
    if (!(data && data.length)) {
      return null;
    }
    printRAReport(data)(dispatch).then((res) => {
      if (!res.error) {
        let file;
        let fileName;

        let fileNamePrefix = `Rejestr kont oceny ryzyka zawodowego - ${moment().format(
          "YYYY-MM-DD"
        )}`;

        if (true) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.pdf`;
        }
        if (data.xls) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.xls`;
        }

        saveBlobFile(file, fileName);
      }
    });
  }

  printRADocuments(data) {
    let { modalPrint } = this.state;
    let { id } = modalPrint;
    let { dispatch } = this.props;

    printRADocument(
      id,
      data
    )(dispatch).then((res) => {
      if (!res.error) {
        let file = res.payload.data;
        let fileName = `Archiwalna ocena ryzyka zawodowego.pdf`;
        saveBlobFile(file, fileName);
      }
    });
  }

  render() {
    let { message, urls } = this.state;
    let {
      data,
      localizationData,
      sectionData,
      methods,
      workplaceData,
    } = this.props;

    return (
      <>
        <LayoutContent
          error404={this.state.error_code == 404}
          message={message}
          SideMenu={<SideMenu />}
        >
          <PageHeader>Archiwum ORZ</PageHeader>
          <ButtonWrapper>
            <Button onClick={this.printRegister}>Drukuj rejestr</Button>
          </ButtonWrapper>
          <TableSortableWithPagination
            headers={[
              { name: "Nazwa", prop: "name" },
              { name: "Data wykonania", prop: "date_execution" },
              { name: "Data aktualności", prop: "date_update" },
              { name: "Symbol", prop: "symbol" },
              { name: "Metoda oceny", prop: "method_id" },

              {
                name: "Lokalizacja",
                prop: "localization_id",
              },
              { name: "Dział", prop: "section_name" },
              { name: "Stanowisko", prop: "workplace_id" },
              { name: "Pobierz" },
              { name: "Duplikuj" },
              { name: "Usuń" },
              { name: "Zapisz jako" },
            ]}
            getSortedData={(data) => {
              this.setState({ selectedData: data.map((i) => i.id) });
            }}
            data={data}
            renderSearcher={
              <Form
                ref={(form) => (this.form = form)}
                initialValues={{
                  ra_method_id: "0",
                  localization_id: "0",
                  section_name: "0",
                  workplace_id: "0",
                }}
                mutators={{ clearFilterMutator }}
                onSubmit={this.filterSubmit}
                render={({ handleSubmit, pristine, invalid, form }) => {
                  return (
                    <>
                      <tr>
                        <td style={{ minWidth: "120px" }}>
                          <FieldInput name={"name"} placeholder={"wg Nazwy"} />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldDayPicker name={"date_execution"} />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldDayPicker name={"date_update"} />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldInput
                            name={"symbol"}
                            placeholder={"wg Symbolu"}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"ra_method_id"}
                            data={[{ id: "0", name: "Metoda" }].concat(methods)}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"localization_id"}
                            data={[{ id: "0", name: "Lokalizacja" }].concat(
                              localizationData
                            )}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"section_name"}
                            propValue="value"
                            data={[{ value: "0", name: "Dział" }].concat(
                              sectionData
                            )}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"workplace_id"}
                            data={[{ id: "0", name: "Stanowisko" }].concat(
                              workplaceData
                            )}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="13"
                          style={{ padding: "8px 40px 8px 8px" }}
                        >
                          <ButtonWrapper>
                            <Button
                              type="submit"
                              bsStyle="primary"
                              onClick={handleSubmit}
                            >
                              Szukaj
                            </Button>
                            <FormSpy subscribe={{ form: true }}>
                              {({ form }) => (
                                <Button onClick={this.clearFilter(form)}>
                                  Wyłącz filtr
                                </Button>
                              )}
                            </FormSpy>
                          </ButtonWrapper>
                        </td>
                      </tr>
                    </>
                  );
                }}
              />
            }
            render={(sortedData) => {
              return sortedData.map((item) => {
                return (
                  <tr>
                    <td>
                      <Link to={urls.BASIC(item.id)}>{item.name}</Link>
                    </td>
                    <td>{item.date_execution}</td>
                    <td>{item.date_actual}</td>
                    <td>{item.symbol}</td>
                    <td>{item.method_name}</td>
                    <td>{item.localization_name}</td>
                    <td>{item.section_name}</td>
                    <td>{item.workplace_name}</td>

                    <td>
                      <ButtonWithIcon
                        onClick={this.openModal("modalPrint", { ...item })}
                        icon="fa fa-print"
                      ></ButtonWithIcon>
                    </td>
                    <td>
                      <ButtonWithIcon icon="fa fa-files-o"></ButtonWithIcon>
                    </td>
                    <td>
                      <ButtonWithIcon
                        icon="fa fa-trash"
                        onClick={this.openModal("modal", {
                          entry_id: item.id,
                        })}
                      ></ButtonWithIcon>
                    </td>

                    <td>
                      <SaveAsModal />
                    </td>
                  </tr>
                );
              });
            }}
          />
        </LayoutContent>
        <Modal
          title="Usuń ocenę ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={this.state.modal.open}
          handleAccept={this.deleteRA}
          handleClose={this.closeModal("modal")}
        />
        <PrintModal
          open={this.state.modalPrint.open}
          handleClose={this.closeModal("modalPrint", {})}
          handleAccept={this.printRADocuments}
        />
      </>
    );
  }
}

export default connect((store) => ({
  data: store.riskAssessment.data,
  message: store.message,
  methods: store.riskAssessment.methods,
  localizationData: store.localization.data,
  sectionData: store.section.data,
  workplaceData: store.workplace.data,
}))(List);
