export function getDictionarySex() {
  return (dispatch) =>
    dispatch({
      types: [
        "DICTIONARY_SEX_REQUEST",
        "DICTIONARY_SEX_SUCCESS",
        "DICTIONARY_SEX_ERROR",
      ],
      payload: {
        request: {
          url: "/dictionary/sex",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDictionaryPersonStatus() {
  return (dispatch) =>
    dispatch({
      types: [
        "DICTIONARY_SEX_REQUEST",
        "DICTIONARY_PERSON_STATUS",
        "DICTIONARY_SEX_ERROR",
      ],
      payload: {
        request: {
          url: "/dictionary/person-status",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDictionary(name) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_DICTIONARY_REQUEST",
        "GET_DICTIONARY_SUCCESS",
        "GET_DICTIONARY_ERROR",
      ],
      payload: {
        request: {
          url: `/dictionary/${name}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDictionaryGroup(name) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_DICTIONARY_REQUEST",
        "GET_DICTIONARY_SUCCESS",
        "GET_DICTIONARY_ERROR",
      ],
      payload: {
        request: {
          url: `/dictionary/group/${name}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDictionaryProfessionCode(codes, filter) {
  let params = new URLSearchParams();

  params.set("codes", codes);
  params.set("filter", filter);

  return (dispatch) =>
    dispatch({
      types: [
        "GET_DICTIONARY_REQUEST",
        "GET_DICTIONARY_SUCCESS",
        "GET_DICTIONARY_ERROR",
      ],
      payload: {
        request: {
          url: `/dictionary/profession-code${params ? `?${params}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
