export const defaultUrls = {
  ADDTYPE: "/qualification-entitlements/type/add",
  EDITTYPE: (id) => `/qualification-entitlements/type/edit/${id}`,
  LISTTYPE: `/qualification-entitlements/type`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
