import React from "react";
import {connect} from "react-redux"
import AppComponent from "../../components/AppComponent";
import LoadingScreen from "../../components/LoadingScreen";
import {getPage, savePage,getPagePotentialParent,clearPage} from "../../actions/pageActions";
import PageForm from "../../components/Page.Form";
export class Page extends AppComponent {

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const {dispatch, match} = this.props;
    const {page_id} = match.params;
    getPage(page_id)(dispatch)
      .then(this.handleServerResponse());
    getPagePotentialParent(page_id)(dispatch)
      .then(this.handleServerResponse());
  }

  submit(values) {
    const {dispatch, match,history} = this.props;
    const {page_id} = match.params;
    savePage(page_id, values)(dispatch)
      .then(this.handleServerResponse({},()=>{
        history.push("/super-admin/page");
      }));
  }

  render() {
    const {message} = this.state;
    const {page: {singleData:pageData,data:parentPages}} = this.props;

    return (<LoadingScreen fetched={true}>
      <PageForm
        title={"Edytuj stronę"}
        submitTitle={"Zapisz"}
        data={pageData}
        parentPages={parentPages}
        handleSubmit={this.submit}
        message={message}
      />
    </LoadingScreen>);
  }

  componentWillUnmount(){
    clearPage()(this.props.dispatch);
  }
}

export default connect(store => ({
  page: store.page
}))(Page);