import React from "react";
import styled from "styled-components";
import { Form, Field, FormSpy } from "react-final-form";
import validator, { composeValidators } from "components/Validation";

import { Link } from "react-router-dom";
import { HoverNav } from "../components/Header";
import Button from "react-bootstrap/lib/Button";
const Fake = (props) => (
  <div onClick={props.onClick}>
    {props.children}
    {props.lol}
  </div>
);

let Input = styled.input``;

const onSubmit = (values) => {
  alert(JSON.stringify(values, 0, 2));
};

class Sandbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  render() {
    const renderItem = ({ open, children, collapse }) => (
      <div>
        <button onClick={collapse}>{open ? "close" : "open"}</button>
        <div className={`collapse ${open ? "in" : ""}`}> {children} </div>
      </div>
    );

    return (
      <div>
        <Form
          onSubmit={onSubmit}
          // subscription={{ submitting: true, pristine: true, valid: true }}
          render={({ handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="test"
                  validation={validator.required}
                  render={({ input, meta }) => {
                    return (
                      <Input
                        type="text"
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    );
                  }}
                ></Field>
                <button type="submit">test</button>
                <pre>{JSON.stringify(values, 0, 2)}</pre>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

export default Sandbox;
