import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { StyledLabel } from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";
import { isBrowser, isMobile } from "react-device-detect";
import { CSSTransition } from "react-transition-group";

import {
  StyledInputMessage,
  SelectButton,
  SelectWrapper,
  SelectListWrapper,
  UlWrapper,
  Ul,
  StyledMenuItem,
} from "./Select.style";

SelectWrapper.displayName = "SelectWrapper";
StyledMenuItem.displayName = "StyledMenuItem";

const getSelectedEntry = (data, selected, propValue = "value", fullValue) => {
  if (!selected) {
    return null;
  }
  let index = -1;
  index = data.findIndex((element) => {
    if (fullValue) {
      return element[propValue] === selected[propValue];
    } else {
      return element[propValue] === selected;
    }
  });
  if (index > -1) {
    return data[index].name;
  }
  return null;
};

let minListHeight = 150;
let maxListHeight = 400;

const distributeNestedArray = (array, level = 0) => {
  return array.reduce((array, item) => {
    const newItem = { ...item, level };
    delete newItem.children;
    array.push(newItem);
    if (item.children) {
      array = array.concat(distributeNestedArray(item.children, level + 1));
    }
    return array;
  }, []);
};

function SelectHooks({
  centerOptions,
  oneThirdButton,
  data,
  disabled,
  extended = [],
  fullValue,
  fullWidth,
  handleChange,
  helperText,
  label,
  onBlur,
  onFocus,
  propValue,
  pullRight,
  selected,
  style,
  title,
  unelectedProp,
}) {
  let SelectRef = useRef();
  let [ptitle, setTitle] = useState();
  let [slideUp, setSlideUp] = useState(false);
  let [defaultTitle, setDefaultTitle] = useState(
    getSelectedEntry(data, selected, propValue, fullValue) || title || ""
  );
  let [pdata, setData] = useState(data);
  let [show, setShow] = useState(false);
  let [closed, setClosed] = useState(true);
  let [listMaxHeight, setListMaxHeight] = useState(0);
  let [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);
  let [currentOffsetTop, setCurrentOffsetTop] = useState(window.innerHeight);

  useEffect(() => {
    let predata = distributeNestedArray(data || []);
    let title =
      getSelectedEntry(predata, selected, propValue, fullValue) || defaultTitle;
    setData(predata);
    setTitle(title);
  }, [data, selected]);

  let openCloseButton = () => {
    if (!disabled) {
      setShow(!show);
    }
  };

  let onChange = (eventKey) => {
    const result = fullValue
      ? eventKey
      : extended.length
      ? eventKey
      : eventKey.value;

    setTitle(eventKey.name);
    setShow(false);
    if (selected !== result) {
      handleChange(result);
    }
  };

  useEffect(() => {
    if (SelectRef && SelectRef.current.offsetTop) {
      let existSpaceBelow = windowInnerHeight - currentOffsetTop;
      /// mieści się podspodem
      let isSpaceBelow = existSpaceBelow > listMaxHeight;

      let isSpaceAbove = currentOffsetTop > listMaxHeight;

      if (!isSpaceBelow && isSpaceAbove) {
        setSlideUp(true);
      } else {
        setSlideUp(false);
      }
    }
  }, [windowInnerHeight, currentOffsetTop]);

  function resized() {
    setWindowInnerHeight(window.innerHeight);
    let offsetTop;
    // if (SelectRef && SelectRef.current.offsetTop) {
    //   offsetTop = SelectRef.current.offsetTop;
    //   setCurrentOffsetTop(offsetTop);
    // }
    if (
      SelectRef &&
      SelectRef.current &&
      SelectRef.current.getBoundingClientRect
    ) {
      setCurrentOffsetTop(SelectRef.current.getBoundingClientRect().y);
    }
  }

  useEffect(() => {
    resized();
  }, [SelectRef]);
  let timeout;
  function reportWindowSize() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      resized();
    }, 100);
  }

  window.onresize = reportWindowSize;
  window.onscroll = reportWindowSize;
  useEffect(() => {
    let maxHeight = data.length * 34;
    maxHeight = maxHeight > 400 ? 400 : maxHeight;
    setListMaxHeight(maxHeight);
  }, [data.length]);

  return (
    <>
      {/* {SelectRef.current.offsetTop} */}
      <div
        ref={SelectRef}
        style={{ ...style }}
        onBlur={(event) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setShow(false);
          }
        }}
      >
        <SelectWrapper
          pullRight={pullRight}
          centerOptions={centerOptions}
          fullWidth={fullWidth}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {label && <StyledLabel>{label}</StyledLabel>}
          <div className="dropdown open btn-group">
            <div style={{ width: oneThirdButton ? "33%" : "auto" }}>
              <SelectButton
                style={{
                  cursor: `${disabled ? "no-drop" : "pointer"}`,
                }}
                className="dropdown-toggle btn btn-default"
                type="button"
                onClick={openCloseButton}
              >
                {ptitle && ptitle.trim ? ptitle.trim() : ptitle}
                <span className="caret"></span>
              </SelectButton>
            </div>
            <SelectListWrapper slideUp={slideUp}>
              <CSSTransition in={show} timeout={0} classNames="slide">
                <UlWrapper
                  length={data.length + 2}
                  listMaxHeight={listMaxHeight}
                >
                  <Ul
                    className="dropdown-menu"
                    length={data.length}
                    closed={closed}
                  >
                    {data.map((item, index) => {
                      let mutator = {};
                      if (extended && extended.length) {
                        mutator = extended.reduce((obj, key) => {
                          obj[key] = item[key];
                          return obj;
                        }, {});
                      }
                      return (
                        <StyledMenuItem
                          title={item.name}
                          className={item.className || item.classname}
                          disabled={item.disabled}
                          selected={!item[unelectedProp]}
                          level={item.level}
                          key={index}
                          eventKey={{
                            ...item,
                            value: item[propValue],
                            name: item.name,
                            ...mutator,
                          }}
                          onSelect={!item[unelectedProp] ? onChange : () => {}}
                        >
                          {item.name}
                        </StyledMenuItem>
                      );
                    })}
                  </Ul>
                </UlWrapper>
              </CSSTransition>
            </SelectListWrapper>
          </div>
        </SelectWrapper>
        <StyledInputMessage>{helperText}</StyledInputMessage>
      </div>
    </>
  );
}

export default SelectHooks;
