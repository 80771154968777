import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import { Link } from "react-router-dom";

import validator, { composeValidators } from "components/Validation/index";
import {
  ButtonWithIcon,
  DownloadButton,
  DeleteButton,
} from "components/ListComponents";
import { LinkContainer } from "components/LinkContainer";

import {
  StyledInputCheckBoxRadio,
  StyledInputBox,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";

import { StyledInputMessage } from "style/styleComponents/Form/input";

const QuestionName = styled.div`
  width: 40%;
  margin-right: 10px;
  line-height: 32px;
  ${media.sm`
    width:100%;
  `}
`;

const QuestionTaskList = styled.div`
  width: 60%;
  ${media.sm`
    width:100%;
  `}
`;

const StyledTaskLine = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${media.sm`
    flex-wrap:wrap;
  `}
  & > * {
    &:first-child {
      ${(props) => (props.category ? "padding-left: 20px;" : "")}
    }
  }
`;

const StyledTaskSingleLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AddButton = styled(Button)`
  ${media.sm`
    width:100%;
  `}
`;

const ButtonWithIcon2 = styled(ButtonWithIcon)`
  flex-grow: 0;
`;

const TaskItemName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const TaskLine = (props) => {
  const {
    item,
    addTask,
    audit_id,
    category,
    permission,
    taskPermission,
    tasks = [],
    deleteEvent,
    questionIndex,
    subQuestionIndex,
  } = props;

  return (
    <div>
      <StyledTaskLine category={category} style={{ marginBottom: "15px" }}>
        <QuestionName>{item.name}</QuestionName>
        <QuestionTaskList>
          {item.dictionary_audit_question_answer_id == 2 ? (
            <div>
              <AddButtonWrapper>
                <LinkContainer to={`/audit/task/${audit_id}/add/${item.id}`}>
                  <AddButton disabled={!permission[12]}>
                    Dodaj zadanie
                  </AddButton>
                </LinkContainer>
              </AddButtonWrapper>
              {tasks && tasks.length ? (
                <div>
                  {item.tasks.map((task) => {
                    return (
                      <StyledTaskSingleLine>
                        {!(taskPermission[1] || taskPermission[12]) ? (
                          <TaskItemName>{task.title}</TaskItemName>
                        ) : (
                          <TaskItemName>
                            <Link
                              to={`/audit/task/${audit_id}/edit/${item.id}/${task.id}`}
                            >
                              {task.title}
                            </Link>
                          </TaskItemName>
                        )}
                        <ButtonWithIcon2
                          onClick={deleteEvent(
                            task.id,
                            task.title,
                            questionIndex,
                            subQuestionIndex
                          )}
                          icon={"fa fa-trash"}
                          disabled={!taskPermission[4]}
                        />
                      </StyledTaskSingleLine>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </QuestionTaskList>
      </StyledTaskLine>
      <div></div>
    </div>
  );
};

export default TaskLine;
