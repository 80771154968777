export const defaultUrls = {
  ADD: `/business/orz/edit/add`,
  EDIT: (id) => `/business/orz/edit/${id}`,
  LIST: `/business/orz`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
