import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { LayoutContent } from "../../components/Layout";
import Pagination from "../../components/Pagination";
import { LinkContainer } from "../../components/LinkContainer";
import { ButtonWithIcon } from "../../components/ListComponents";
import AccordionList, {
  AccordionButton,
  AccordionLineNameColumn,
  AccordionLineOptionColumn,
  AccordionLineBody,
} from "../../components/AccordionList";
import PageHeader from "../../components/PageHeader";
import { getLocalizations } from "../../actions/localizationActions";
import LoadingScreen from "../../components/LoadingScreen/";
import Media from "react-media";

const LocalAccordionLineOptionColumn = ({
  header,
  id,
  option_name,
  division,
  ...props
}) => (
  <AccordionLineOptionColumn {...props}>
    {!header && !division ? (
      <Media query="(max-width: 767px)">
        {(matches) =>
          matches ? (
            <LinkContainer to={`/business/responsibility/${id}`}>
              <ButtonWithIcon icon="fa fa-cog" />
            </LinkContainer>
          ) : (
            <LinkContainer to={`/business/responsibility/${id}`}>
              <ButtonWithIcon
                icon="fa fa-cog"
                name="Przypisz odpowiedzialność"
              />
            </LinkContainer>
          )
        }
      </Media>
    ) : (
      <div>{header && option_name}</div>
    )}
  </AccordionLineOptionColumn>
);

let sortData = (data, sortCallback) => {
  for (let item of data) {
    if (item.children && item.children.length) {
      item.children = sortData(item.children, sortCallback);
    }
  }
  return data.sort(sortCallback);
};

let FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > * {
    flex: 0 1 100%;
    &:last-child {
      flex: 0 0 20px;
      padding: 0 10px;
    }
  }
`;

class ResponsibilityList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, data: [], sort: 0, sortData: [] };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  changeSort() {
    let { sort } = this.state;
    let { localization } = this.props;
    let { data } = localization;
    let newData = [...data];

    let newState = {};
    switch (sort) {
      case 0: {
        newState = {
          sort: 1,
          sortData: sortData(newData, (a, b) =>
            a.name.localeCompare(b.name, "pl", {
              caseFirst: "lower",
              numeric: true,
              sensitivity: "base",
            })
          ),
        };
        break;
      }
      case 1: {
        newState = {
          sort: 2,
          sortData: sortData(newData, (b, a) =>
            a.name.localeCompare(b.name, "pl", {
              caseFirst: "lower",
              numeric: true,
              sensitivity: "base",
            })
          ),
        };
        break;
      }
      case 2: {
        newState = { sort: 0, sortData: newData };
        break;
      }
    }

    this.setState(newState);
  }

  componentDidMount() {
    getLocalizations()(this.props.dispatch).then(this.handleServerResponse());
  }

  componentDidUpdate() {
    let { localization } = this.props;

    if (this.state.sortData.length !== localization.data.length) {
      this.setState({
        sortData: localization.data,
      });
    }
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  render() {
    const { sortData, sort } = this.state;
    const {
      localization: { data, fetched },
    } = this.props;

    const AccordionLine = ({
      open,
      url,
      name,
      option_name,
      lineHeight,
      optionColumnWidth,
      optionColumn,
      level,
      handleChange,
      children,
      className,
      header,
      division,
      id,
    }) => (
      <AccordionLineBody
        lineHeight={"45px"}
        className={"accordion-item"}
        level={level}
        optionColumnWidth={"245px"}
        header={header}
      >
        <AccordionButton
          handleChange={handleChange}
          children={children}
          open={open}
        />
        <AccordionLineNameColumn
          onClick={
            header
              ? () => {
                  this.changeSort();
                }
              : undefined
          }
        >
          {url ? (
            <Link to={url}>{name}</Link>
          ) : (
            <FlexLine>
              {name}{" "}
              {header && sort > 0 ? (
                <i
                  className={`fa fa-long-arrow-${sort == 2 ? "down" : "up"}`}
                />
              ) : (
                ""
              )}
            </FlexLine>
          )}
        </AccordionLineNameColumn>
        <Media query="(max-width: 560px)">
          {(matches) =>
            matches ? (
              <LocalAccordionLineOptionColumn
                header={header}
                id={id}
                option_name={option_name}
                division={division}
                style={{ flexBasis: "120px" }}
              />
            ) : (
              <LocalAccordionLineOptionColumn
                header={header}
                id={id}
                option_name={option_name}
                division={division}
              />
            )
          }
        </Media>
      </AccordionLineBody>
    );

    return (
      <LoadingScreen fetched={fetched}>
        <LayoutContent>
          <PageHeader>Lista lokacji - przypisz odpowiedzialność</PageHeader>
          <AccordionList
            header={["Lokacje", "Przypisz odpowiedzialność"]}
            data={this.state.data}
            LineComponent={AccordionLine}
            optionColumn={(item) => {
              return !item.children ? (
                <LinkContainer to={`/business/responsibility/${item.id}`}>
                  <ButtonWithIcon
                    icon="fa fa-cog"
                    name="Przypisz odpowiedzialność"
                  />
                </LinkContainer>
              ) : (
                <div></div>
              );
            }}
            optionColumnWidth={"27%"}
          />
          <Pagination
            handlePageChange={this.handlePageChange}
            data={sortData}
            data={sortData}
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
}))(ResponsibilityList);
