export const defaultUrls = {
  ADD: `/super-admin/draft/ra-method/add`,
  EDIT: (id) => `/super-admin/draft/ra-method/edit/${id}`,
  LIST: `/super-admin/draft/ra-method`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
