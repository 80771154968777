import React from "react";
import { connect } from "react-redux";

import {
  getAuditNotification,
  getAuditTypes,
  getAuditTypesWithDrafts,
  pingAudit,
  registerAudit,
} from "actions/auditActions";

import { getUsersLocalizations } from "actions/localizationActions";
import {
  getUserWorkplacesWithout,
  getUserWorkplacesWithoutOnParentField,
} from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import AuditRegisterForm from "components/AuditRegister.Form";

import SideMenu from "./_SideMenu";

export class AuditRegister extends AppComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    Promise.all([
      pingAudit()(dispatch).then(this.handleServerResponse({}, () => {})),
      getAuditTypesWithDrafts()(dispatch).then(this.handleServerResponse({}, () => {})),
      getUsersLocalizations()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse()),
    ]).then(() => {});
  }

  handleSubmit(values) {
    const { dispatch, history } = this.props;
    registerAudit({ ...values, planned: "false" })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { audit_id } = action.payload.data;
        history.push("/audit/question/" + audit_id);
      })
    );
  }

  render() {
    const { message } = this.state;
    const {
      audit: { typeData, permission },
      localization: { data: localizationData },
      section: { data: sectionData },
      workplace: { data: workplaceData },
      users: { parentUsers: usersData },
      match,
    } = this.props;
    return (
      <div>
        <AuditRegisterForm
          random
          match={match}
          permission={permission}
          SideMenu={<SideMenu permission={permission} />}
          register
          message={message}
          error_code={this.state.error_code == 404}
          submit={this.handleSubmit}
          typeData={typeData}
          localizationData={localizationData}
          sectionData={sectionData}
          workplaceData={workplaceData}
          usersData={usersData}
        />
      </div>
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  users: store.users,
}))(AuditRegister);
