import React from "react";
import styled from "styled-components";
import Alert from "react-bootstrap/lib/Alert";
import PropTypes from "prop-types";
import Box from "../../../components/Box";
import {media} from "../../style-utils";

const PublicAuthViewContainer = styled.div` 
  position: relative;
  display: flex; 
  min-height: calc(100vh - 84px);
  justify-content: center;
`;

const ContentBox = styled.div`
  padding: 30px 25px;
  width:100%;
  max-width: 600px;
  
`;

const CenteringWrapper = styled.div`
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 550px;
  width: 100%;
  max-height: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CenteredWrapper = styled.div`
  flex-basis: 75%;
  ${media.xs`
    flex-basis: 100%;
  `}
`;

export const PublicAuthView = ({title,children,alert}) => (
  <PublicAuthViewContainer>
    <CenteringWrapper>
      <CenteredWrapper>
        {alert && alert.text && <Alert style={{width:"100%"}} bsStyle={alert.className}>{alert.text}</Alert>}
        <Box
          title={title}
          style={{padding:"0 0 0"}}

        >
          <ContentBox>
            {children}
          </ContentBox>
        </Box>
      </CenteredWrapper>
    </CenteringWrapper>
  </PublicAuthViewContainer>
);

PublicAuthView.propTypes ={
  title:PropTypes.string,
  children: PropTypes.node,
  alert: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string
  })
};