import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import {
  generateVictimExplains,
  saveVictimExplains,
} from "actions/incidentActions";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import ModalSchema from "components/Modal/./_SchemaAppComponent";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import DayPicker from "lib/DayPicker";

import { FieldDayPicker, FieldInput, FieldTextareaBox } from "../Fields";

import saveBlobFile from "components/saveBlobFile";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;

const LocalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

StyledModal.displayName = "Modal";

let defaultLabels = {
  place: "Miejscowość",
  date: "Data",
  description: "Prawdopodobna przyczyna zdarzenia",
};

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      labels: {
        ...defaultLabels,
        ...(props.labels || {}),
      },
    };

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {}

  openAddModal() {
    let { openAddUser } = this.props;
    openAddUser();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  submit(values) {
    const { incident_id, dispatch, accept = () => {}, closeModal } = this.props;
    let { file, ...data } = values;
    accept(values, () => {
      closeModal();
    });
  }

  render() {
    let { labels } = this.state;
    let { open, title, user, readOnly, data } = this.props;
    data = data || {};
    let { explain_place, explain_date, explain_description } = data;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={{
                explain_place,
                explain_date,
                explain_description,
              }}
              onSubmit={this.submit}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FieldInput
                      disabled={readOnly}
                      name={`explain_place`}
                      label={labels.place}
                    />
                    <FieldDayPicker name="explain_date" label={labels.date} />
                    <FieldTextareaBox
                      name="explain_description"
                      label={labels.description}
                    />

                    <LocalButtonWrapper>
                      <Button
                        style={{ marginBottom: "20px" }}
                        type="button"
                        bsStyle="primary"
                        onClick={() => {
                          form.change("file", true);
                          handleSubmit();
                        }}
                      >
                        Zapisz i wygeneruj
                      </Button>
                      <ButtonWrapper>
                        <Button
                          type="submit"
                          bsStyle="primary"
                          onClick={() => {
                            form.change("file", false);
                            handleSubmit();
                          }}
                        >
                          Zapisz
                        </Button>
                        <Button type="button" onClick={this.closeModal}>
                          Anuluj
                        </Button>
                      </ButtonWrapper>
                    </LocalButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </StyledModal>
      </div>
    );
  }
}

export default class victimExplain extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    const { title, user, readOnly, data, accept, labels } = this.props;
    let modalProps = { open: this.state.open, title, user, readOnly, data };

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Dodaj oświadczenie poszkodowanego
        </Button>
        <SimpleAcceptModal
          accept={accept}
          labels={labels}
          {...modalProps}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
