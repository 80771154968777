import React from "react";
import { connect } from "react-redux";

import {
  getDraftRA,
  getDraftHazardList,
  getDraftRAControlListByQuestionAnswerYes,
  addDraftRAAnalysisHazard,
  getDraftRAAnalysisHazard,
  deleteRAAnalysisHazard,
  getDraftMethodEntry,
  getDraftHazard,
  saveRAAnalysisHazard,
  changeOrderRAAnalysisHazard,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import AddRiskAssessmentReview from "components/RiskAssessment.Forms/Analysis";

import { setUrls } from "../_staticData";
import SideMenu from "./_SideMenu";

import { RAAnalysis } from "container/RiskAssessment/EntryAddEdit/Analysis/index";

class RAAnalysisDraft extends RAAnalysis {
  constructor(props) {
    super(props);
    this.renderSideMenu = this.renderSideMenu.bind(this);
    this.state = { ...this.state, draft: true };
  }

  renderSideMenu() {
    const { match, permission, data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
  permission: store.riskAssessment.permission,
  controlList: store.riskAssessment.raControlList,
  modelHazardList: store.riskAssessment.modelHazardList,
  hazards: store.riskAssessment.raHazards,
  method: store.riskAssessment.raMethodEntry,
}))(RAAnalysisDraft);
