export const WEEKDAYS_SHORT = ["Ndz", "Pn", "Wt", "Śr", "Czw", "Pt", "Sob"];

export const WEEKDAYS_LONG = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela",
];

export const MONTHS = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

export const LABELS = {
  ru: { nextMonth: "следующий месяц", previousMonth: "предыдущий месяц" },
  it: { nextMonth: "Prossimo mese", previousMonth: "Mese precedente" },
};
