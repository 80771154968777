import React from "react";
import { connect } from "react-redux";

import { getDraftQETypeList, deleteDraftQEType } from "actions/QEActions";

import AppComponent from "components/AppComponent";
import List from "components/QE/ListType";
import SideMenu from "../_SideMenu";
import { setUrls } from "./_staticData";

let mockData = [
  {
    id: 1,
    name: "Elektryczne dozorowe",
  },
  {
    id: 1,
    name: "Elektryczne eksploatacyjne",
  },
  {
    id: 1,
    name: "Cieplne dozorowe",
  },
  {
    id: 1,
    name: "Cieplne eksploatacyjne",
  },
  {
    id: 1,
    name: "Operator suwnic",
  },
];

class QETypeList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };

    this.deleteQEType = this.deleteQEType.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;
    this.updateMessage();
    getDraftQETypeList()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  deleteQEType(id) {
    let { dispatch } = this.props;

    deleteDraftQEType(id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { urls, message } = this.state;
    let { data } = this.props;

    return (
      <List
        urls={urls}
        message={message}
        SideMenu={SideMenu}
        data={data}
        deleteQEType={this.deleteQEType}
        superAdminView
      />
    );
  }
}
export default connect((store) => ({
  data: store.qe.types,
  message: store.message,
}))(QETypeList);
