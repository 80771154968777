import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import ModalSchema from "./_Schema";
import {ButtonWrapper} from "../ListComponents";
import {media} from "../../style/style-utils";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  & .modal-title{
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";
export default class SimpleAcceptModal extends ModalSchema {

  constructor(props){
    super(props);

    this.accept = this.accept.bind(this);
  }

  accept(){
    const {handleAccept = ()=>{},handleClose,autoAcceptClose = true} = this.props;
    handleAccept();
    autoAcceptClose && handleClose();
  }

  render(){
    const {open,title,children,handleAccept} = this.props;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              {title}
              {/*<ButtonWrapper>*/}
                {/*<Button onClick={this.handleClose}>*/}
                  {/*<i className="fa fa-times" />*/}
                {/*</Button>*/}
              {/*</ButtonWrapper>*/}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{children}</Modal.Body>
          {handleAccept && <Modal.Footer>
            <Button onClick={this.accept}  bsStyle="primary">Zapisz</Button>
            <Button onClick={this.handleClose}>Anuluj</Button>
          </Modal.Footer>}
        </StyledModal>
      </div>
    );
  }
}

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose:PropTypes.func.isRequired
};