import React, { Fragment } from "react";
import styled from "styled-components";
import { Field } from "react-final-form";
import Select from "components/Select";
import { Width33, SpaceBlock } from "../AnalysisForm.style";
import validator, { composeValidators } from "components/Validation";
import DayPicker from "lib/DayPicker";

import {
  StyledInputCheckBox,
  StyledInputBox,
  StyledLabel,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";

import FieldArrayL from "./FieldArray";
export const FieldArrayComponent = FieldArrayL;

export const FieldInput = (props) => {
  let { label, name, disabled, validate } = props;

  return (
    <Field
      name={name}
      validate={validate}
      subscription={{
        touched: true,
        error: true,
        value: true,
      }}
      render={({ input, meta, ...rest }) => (
        <Fragment>
          <StyledInputBox
            {...input}
            label={label}
            disabled={disabled}
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            onChange={(event) => {
              input.onChange(event.target.value);
            }}
          />
        </Fragment>
      )}
    />
  );
};
let LocalStyledInputBox = styled(StyledInputBox)`
  width: 33%;
`;

export const FieldInput33 = (props) => {
  let { label, name, disabled, validate } = props;
  return (
    <Fragment>
      <div>
        <StyledLabel>{label}</StyledLabel>
        <Field
          validate={validate}
          name={name}
          subscription={{
            touched: true,
            error: true,
            value: true,
          }}
          render={({ input, meta, ...rest }) => (
            <LocalStyledInputBox
              {...input}
              disabled={disabled}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              onChange={(event) => {
                input.onChange(event.target.value);
              }}
            />
          )}
        />
      </div>
    </Fragment>
  );
};

export const FieldSelectDitionary = (props) => {
  let {
    dictionary,
    disabled,
    label,
    name,
    fullValue = false,
    validate,
  } = props;
  return (
    <Field
      name={name}
      validate={validate}
      subscription={{
        touched: true,
        error: true,
        value: true,
      }}
      render={({ input, meta, ...rest }) => (
        <SpaceBlock>
          <Select
            disabled={disabled}
            label={label}
            fullWidth
            propValue={"id"}
            helperText={meta.error && meta.touched ? meta.error : null}
            selected={input.value}
            data={dictionary}
            handleChange={(value) => {
              input.onChange(value);
            }}
          />
        </SpaceBlock>
      )}
    />
  );
};

export const FieldSelectDitionary33 = (props) => {
  let { dictionary, disabled, label, name, validate, handleChange } = props;
  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>

      <Field
        name={name}
        validate={validate}
        subscription={{
          touched: true,
          error: true,
          value: true,
        }}
        render={({ input, meta, ...rest }) => (
          <SpaceBlock>
            <Select
              disabled={disabled}
              fullWidth
              helperText={meta.error && meta.touched ? meta.error : null}
              propValue={"id"}
              selected={input.value}
              data={dictionary}
              handleChange={
                handleChange
                  ? handleChange(input)
                  : (value) => {
                      input.onChange(value);
                    }
              }
            />
          </SpaceBlock>
        )}
      />
    </Fragment>
  );
};

export const FieldTextareaBox = (props) => {
  let { label, name, disabled, validate } = props;
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta, ...rest }) => (
        <StyledTextareaBox
          label={label}
          value={input.value}
          error={meta.error && meta.touched}
          helperText={meta.error && meta.touched ? meta.error : null}
          minHeight={`100px`}
          disabled={disabled}
          onChange={(event) => {
            input.onChange(event.target.value);
          }}
        />
      )}
    />
  );
};

export const FieldInputCheckBox = (props) => {
  let { label, name, disabled } = props;
  return (
    <Field
      name={name}
      render={({ input, meta, ...rest }) => (
        <StyledInputCheckBox
          {...input}
          value={input.value || "false"}
          checked={input.value == "true"}
          disabled={disabled}
          label={label}
          onChange={(e) => {
            input.onChange(e.target.checked ? "true" : "false");
          }}
        />
      )}
    />
  );
};

export const FieldDayPicker = (props) => {
  let { label, name, disabled, readOnly } = props;

  return (
    <Fragment>
      <Field
        name={name}
        render={({ input, meta, ...rest }) => (
          <div style={{ marginBottom: "10px" }}>
            <DayPicker
              canChangeYear={true}
              label={label}
              onFocus={input.onFocus}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              fullWidth
              disabled={readOnly || disabled}
              selectedDay={input.value}
              handleChange={(value) => {
                input.onChange(value);
              }}
            />
          </div>
        )}
      />
    </Fragment>
  );
};

export const FieldDayPicker33 = (props) => {
  let { label, name, disabled, readOnly } = props;

  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>
      <Field
        name={name}
        render={({ input, meta, ...rest }) => (
          <Width33>
            <div style={{ marginBottom: "10px" }}>
              <DayPicker
                canChangeYear={true}
                onFocus={input.onFocus}
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                fullWidth
                disabled={readOnly}
                selectedDay={input.value}
                handleChange={(value) => {
                  input.onChange(value);
                }}
              />
            </div>
          </Width33>
        )}
      />
    </Fragment>
  );
};
