import LList from "./List";
import LArchivedCharacteristicList from "./ArchivedCharacteristicList";
import LAdd from "./Add";
import LEdit from "./Edit";
import LArchivedEdit from "./ArchivedCharacteristicEdit";

export const List = LList;
export const ArchivedCharacteristicList = LArchivedCharacteristicList;
export const Add = LAdd;
export const Edit = LEdit;
export const ArchivedEdit = LArchivedEdit;
