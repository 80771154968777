import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";
import QEUserSegment from "components/QE/QEUserSegment";
import moment from "moment";
// import { ButtonWrapper } from "./ListComponents";

import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
} from "components/Wrappers";
import {
  FieldInputCheckBox,
  FieldDayPicker,
  FieldSelect,
} from "components/Fields";
import { LinkContainer } from "components/LinkContainer";

import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";

import { TableSortHeader } from "components/TableSortable";

import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";

let getDate = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format("YYYY-MM-DD");
};

const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const ProgressTdStyle = styled.td`
  background: ${(props) => props.color || "inherit"};
`;

function ProgressDateTd(props) {
  let { date } = props;
  if (date === null || new Date(date) >= Date.now()) {
    return <td>{props.children}</td>;
  } else {
    return <ProgressTdStyle color={"red"}>{props.children}</ProgressTdStyle>;
  }
}

class SummaryMedicalExaminationsModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.addNewQE = this.addNewQE.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  addNewQE() {
    let { addNewQE, closeModal } = this.props;
    this.closeModal();

    addNewQE();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open, data = {}, permission } = this.props;

    let {
      date_initial,
      date_execution,
      ability_to_work_name,
      date_next,
      additiona_description,
    } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Podsumowanie uprawnień pracownika</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h4>Dane ogólne</h4>
            <TwoInputWrapper>
              <div>
                <StyledInputBox
                  label="Imię"
                  value={data.first_name}
                  disabled={true}
                />
                <StyledInputBox
                  label="Nazwisko"
                  value={data.last_name}
                  disabled={true}
                />
                <StyledInputBox
                  label="Funkcja / Stanowisko"
                  value={data.workplace_name}
                  disabled={true}
                />
              </div>
            </TwoInputWrapper>
            <QEUserSegment
              data={data}
              addNewQE={this.addNewQE}
              disabled={!permission[46]}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class SummaryMedicalExaminations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: true,
    };
  }

  openModal() {}

  render() {
    let { open, closeModal, addNewQE, data } = this.props;

    return (
      <>
        <SummaryMedicalExaminationsModal
          {...this.props}
          data={data}
          open={open}
          closeModal={closeModal}
          addNewQE={addNewQE}
        />
      </>
    );
  }
}
