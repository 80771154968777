import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Button from "react-bootstrap/lib/Button";

import { ButtonWrapper } from "components/Wrappers";
import { LinkContainer } from "components/LinkContainer";
import ImageModal, {
  ImageViewModalSchema,
} from "components/Modal/ImageViewModal";
import PDFModal, { PDFViewModalSchema } from "components/Modal/PDFViewModal";

import QuestionLine from "./core/_questionLine";
import {
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInputBox,
} from "style/styleComponents/Form/input";
export class AuditQuestionsDump extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      pdfModal: { ...PDFViewModalSchema, downloadData: {} },
      imageModal: { ...ImageViewModalSchema, downloadData: {} },
      modalDelete: {
        open: false,
        audit_question_id: null,
        attachment_id: null,
        name: null,
      },
    };
    this.deleteCustomQuestion = this.deleteCustomQuestion.bind(this);
    this.submit = this.submit.bind(this);
  }

  deleteCustomQuestion(audit_question_id) {
    return () => {
      // const { dispatch, match } = this.props;
      // const { audit_id } = match.params;
      // deleteAuditCustomQuestion(audit_id, audit_question_id)(dispatch);
    };
  }

  submit() {}

  render() {
    const {
      singleData,
      questionsData = [],
      newQuestionsData = [],
      answerData = [],
      permission = {},
      attachmentFetching = false,
      SideMenu,
      match,
      data,
      readOnlyUrl = `/audit/task`,
      readOnly = false,
    } = this.props;
    const { pdfModal, imageModal } = this.state;
    const { message } = this.state;

    return (
      <Form
        onSubmit={this.submit}
        mutators={arrayMutators}
        initialValues={data}
        ref={(form) => (this.form = form)}
        render={({ handleSubmit, pristine, invalid, values, ...rest }) => (
          <form onSubmit={handleSubmit}>
            {questionsData.map((field, index) => (
              <div>
                {field.questions && field.questions.length ? (
                  <div>
                    <StyledLabel>{field.name}</StyledLabel>
                    <div>
                      {field.questions.map((subfield, subIndex) => (
                        <div>
                          <StyledLabel>{subfield.name}</StyledLabel>
                          <QuestionLine
                            permission={permission}
                            fetching={attachmentFetching}
                            disabled={readOnly}
                            readOnly={readOnly}
                            index={index}
                            subIndex={subIndex}
                            category
                            name={subfield.name}
                            answerData={answerData}
                            downloadFunc={this.handleDownload}
                            submitAttachment={this.handleSubmitAttachment}
                            deleteAttachment={
                              this.handleDeleteAttachmentRequest
                            }
                            handleCommentFieldOpening={
                              this.handleCommentFieldOpening
                            }
                            field={subfield}
                            openViewModal={this.openPDFViewModal}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <StyledLabel>{field.name}</StyledLabel>
                    <QuestionLine
                      permission={permission}
                      fetching={attachmentFetching}
                      index={index}
                      disabled={readOnly}
                      readOnly={readOnly}
                      name={field.name}
                      answerData={answerData}
                      downloadFunc={this.handleDownload}
                      submitAttachment={this.handleSubmitAttachment}
                      deleteAttachment={this.handleDeleteAttachmentRequest}
                      deleteCustomQuestion={this.deleteCustomQuestion(field.id)}
                      handleCommentFieldOpening={this.handleCommentFieldOpening}
                      field={field}
                      openViewModal={this.openPDFViewModal}
                    />
                  </div>
                )}
              </div>
            ))}
            {/* Generownie lini */}
            <div>
              {newQuestionsData.map((field, index) => (
                <QuestionLine
                  // permission={permission}
                  // fetching={attachmentFetching}
                  index={index}
                  disabled={readOnly}
                  readOnly={readOnly}
                  name={field.name}
                  answerData={answerData}
                  downloadFunc={this.handleDownload}
                  submitAttachment={this.handleSubmitAttachmentLocal}
                  deleteAttachment={this.handleDeleteAttachmentRequest}
                  field={field}
                  openViewModal={this.openPDFViewModal}
                  handleCommentFieldOpening={this.handleCommentFieldOpening}
                  deleteCustomQuestion={this.deleteAuditCustomQuestionLocal(
                    index
                  )}
                />
              ))}
            </div>
            <div>
              <Button type="button" onClick={this.addNewQuestion}>
                Dodaj pytanie
              </Button>
            </div>
            <ButtonWrapper>
              {readOnly ? (
                <LinkContainer to={readOnlyUrl}>
                  <Button bsStyle="primary">Dalej</Button>
                </LinkContainer>
              ) : (
                <Button type="submit" bsStyle="primary">
                  Zapisz
                </Button>
              )}
              <LinkContainer to={`/audit/`} exact>
                <Button>Anuluj</Button>
              </LinkContainer>
            </ButtonWrapper>
          </form>
        )}
      />
    );
  }
}
