import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";
import { ButtonWrapper } from "components/Wrappers";
import { StyledInputBox, StyledLabel } from "style/styleComponents/Form/input";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let GetLine = styled.div`
  display: flex;
  margin: 0 0 0 -10px;
  & > * {
    padding: 0 10px;
    &:nth-child(1) {
      flex: 1 1 70%;
    }
    &:nth-child(2) {
      flex: 1 1 30%;
    }
  }
`;

export class List extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      visibleData: [],
      filter: "",
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setState({ visibleData: this.props.data });
  }

  componentDidUpdate(prevProps, prevState) {
    let { visibleData, filter } = this.state;
    let { data } = this.props;
    if (data && !prevProps.data.length) {
      this.setState({ visibleData: this.props.data });
    }

    // if (varsDiff()) {
    // }

    if (filter !== prevState.filter) {
      let regexp = new RegExp(filter, "i");
      let filteredData = this.props.data.filter((i) => {
        return regexp.test(i.name);
      });

      this.setState({
        visibleData: filteredData,
      });
    }
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
    this.setState({ visibleData: this.props.data });
  }

  render() {
    let { visibleData, modalName } = this.state;
    let {
      open,
      onSelect = () => {
        alert("[PH]");
      },
      disabled,
    } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>{this.props.modalTitle}</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <StyledInputBox
              name="filter"
              placeholder="Szukaj..."
              onChange={(event) => {
                this.setState({ filter: event.target.value });
              }}
            />
            {this.props.renderList ? (
              this.props.renderList(visibleData)
            ) : (
              <TableListPage>
                <thead>
                  <th>Nazwa</th>
                </thead>
                <tbody>
                  {visibleData.map((item) => (
                    <tr>
                      <td
                        onClick={() => {
                          onSelect(item);
                          this.closeModal();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableListPage>
            )}
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class ModalListWithFilter extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: false,
      workplace: { id: null, name: null },
    };
  }

  render() {
    let { open } = this.state;
    let { buttonName, disabled } = this.props;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ open: true });
          }}
          disabled={disabled}
        >
          {buttonName}
        </Button>
        <List
          {...this.props}
          disabled={disabled}
          open={open}
          closeModal={() => {
            this.setState({ open: false });
          }}
        />
      </>
    );
  }
}
