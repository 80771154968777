import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import { Link } from "react-router-dom";
import ModalSchema from "./_Schema";
import TableSortable from "../TableSortable";
const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

class ModalList extends ModalSchema {
  render() {
    const {
      title,
      data = [],
      headers = [],
      open,
      handleClose,
      disabled,
    } = this.props;

    return (
      <div className="static-modal">
        <Modal id="ModalList" show={open} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title id="ModalListTitle">
              <ModalTitleWrapper>
                <span>{title}</span>
                <Button
                  disabled={disabled}
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={handleClose}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="ModalListBody">
            <Table striped bordered condensed hover>
              <TableSortable
                headers={[{ name: "L.p" }, ...headers]}
                data={data}
                render={(sortedData) => {
                  return sortedData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={item.url}>{item.name}</Link>
                      </td>
                      <td>{item.date_end}</td>
                    </tr>
                  ));
                }}
              />
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModalList;

ModalList.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      open: PropTypes.bool,
      handleClose: PropTypes.func,
    })
  ),
};
