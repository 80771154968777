const defaultState = {
  no: [1, 2, 3, 4, 5, 6],
  calendarEvents: {},
  defaultSettings: {},
  incidentStatistics: {
    time_number: null,
    result: [],
  },
  completedTaskPercent: 0,
  potentiallyIncidentStatistics: {
    time_number: null,
    result: [],
  },
  newTask: [],
  widgetToShow: [],
  localizationWithDays: [],
  module7Statistics: {},
  fetching: false,
  fetched: true,
};

export default function reducer(state = { ...defaultState }, action) {
  switch (action.type) {
    case "GET_DASHBOARD_TASK_SUCCESS": {
      const index = state.data.findIndex((item) => item.id == 6);
      if (index > -1) {
        const newData = [...state.data];
        newData[index] = action.payload.data.tasks;
        return { ...state, data: newData };
      }
    }
    case "GET_DASHBOARD_SETTINGS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_DASHBOARD_SETTINGS_SUCCESS": {
      const { defaultSettings, widgetToShow } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        defaultSettings,
        widgetToShow,
      };
    }
    case "GET_DASHBOARD_SETTINGS_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_INCIDENT_LAST_DAYS_SUCCESS": {
      return {
        ...state,
        localizationWithDays: action.payload.data.localizationWithDays,
      };
    }
    case "GET_INCIDENT_DASHBOARD_SUCCESS": {
      return {
        ...state,
        incidentStatistics: action.payload.data.incidentStatistics,
      };
    }
    case "GET_POTENTIALLY_INCIDENT_DASHBOARD_SUCCESS": {
      return {
        ...state,
        potentiallyIncidentStatistics: action.payload.data.incidentStatistics,
      };
    }

    case "GET_CALENDAR_DASHBOARD_SUCCESS": {
      return { ...state, calendarEvents: action.payload.data.events };
    }

    case "SET_CALENDAR_ORDERING_DASHBOARD_REQUEST": {
      return { ...state, widgetToShow: action.payload.request.data.no };
    }
    case "GET_TASK_STATISTIC_DASHBOARD_SUCCESS": {
      const completedTaskPercent = action.payload.data.completedTaskPercent;
      return { ...state, completedTaskPercent };
    }
    case "GET_MODULE7_DEADLINE_COUNT_SUCCESS": {
      let { data } = action.payload;

      return { ...state, module7Statistics: data };
    }
    case "LOGIN_SUCCESS": {
      return { ...state, newTask: action.payload.data.newTasks };
    }
    case "CLEAN_DASHBOARD": {
      return { ...defaultState };
    }
  }
  return state;
}
