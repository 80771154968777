import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Button from "react-bootstrap/lib/Button";
import Image from "react-bootstrap/lib/Image";

import { media } from "../style/style-utils";

const ProfileImageRow = styled(Row)`
  margin-bottom: 20px;
`;
ProfileImageRow.displayName = ProfileImageRow;
const ProfileImageImgBox = styled(Col)`
  ${media.sm`
    display:flex;
    justify-content: center;
    margin-bottom:15px;
  `}
`;
ProfileImageImgBox.displayName = ProfileImageImgBox;
const ProfileImageButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > button {
    width: 50%;
    margin-bottom: 10px;
    ${media.sm`
      width:100%;
      line-height:36px
    `}
  }
`;
ProfileImageButtonBox.displayName = ProfileImageButtonBox;

class ProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.triggerInputFile = this.triggerInputFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  triggerInputFile() {
    this.fileInput.click();
  }

  handleChange(event) {
    this.props.handleChange(event);
    this.fileInput.value = null;
  }

  render() {
    const props = this.props;

    return (
      <ProfileImageRow>
        <ProfileImageImgBox lg={5} md={6} sm={5} xs={12}>
          <Image
            style={{ width: "171px", height: "180px" }}
            src={props.imageSrc || props.defaultImage}
          />
        </ProfileImageImgBox>
        <Col lg={7} md={6} sm={7} xs={12}>
          <ProfileImageButtonBox>
            {props.handleDelete && props.imageSrc && (
              <Button disabled={props.disabled} onClick={props.handleDelete}>
                Usuń
              </Button>
            )}
            <Button
              disabled={props.disabled}
              onClick={this.triggerInputFile.bind(this)}
            >
              {props.imageSrc ? "Zmień" : "Dodaj"}
            </Button>

            <input
              style={{ display: "none" }}
              type="file"
              ref={(fileInput) => (this.fileInput = fileInput)}
              onChange={this.handleChange}
            />
          </ProfileImageButtonBox>
        </Col>
      </ProfileImageRow>
    );
  }
}

export default ProfileImage;

ProfileImage.propTypes = {
  imageSrc: PropTypes.node,
  defaultImage: PropTypes.string,
  handleDelete: PropTypes.func,
  handleClick: PropTypes.func,
  handleChange: PropTypes.func,
};
