import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

// import { ButtonWrapper } from "./ListComponents";

import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
} from "components/Wrappers";
import {
  FieldInputCheckBox,
  FieldDayPicker,
  FieldSelect,
} from "components/Fields";
import { LinkContainer } from "components/LinkContainer";

import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";

import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";

const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let setInitialValues = (data) => {
  let keys = ["id", "qe_type_id", "date_get", "date_deadline", "users_id"];

  return keys.reduce((obj, k) => {
    obj[k] = data[k];
    return obj;
  }, {});
};

class SummaryMedicalExaminationsModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.addNewQE = this.addNewQE.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  addNewQE(data) {
    let { addQE, closeModal } = this.props;
    this.closeModal();
    addQE(data);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open, addQE = () => {}, businessView } = this.props;
    let { data = {}, types, isAdd } = this.props;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Dodaj nowe uprawnienie</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {businessView ? (
              ""
            ) : (
              <>
                <h4>Dane ogólne</h4>
                <TwoInputWrapper>
                  <div>
                    <StyledInputBox value={data.first_name} disabled={true} />
                    <StyledInputBox value={data.last_name} disabled={true} />
                    <StyledInputBox
                      value={data.workplace_name}
                      disabled={true}
                    />
                  </div>
                </TwoInputWrapper>
                <h4>Dodaj uprawnienia kwalifikacyjne</h4>
              </>
            )}
            <Form
              initialValues={setInitialValues(data)}
              onSubmit={this.addNewQE}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TwoInputWrapper>
                      <div>
                        <FieldSelect
                          disabled={!isAdd}
                          name="qe_type_id"
                          label="Rodzaj uprawnień"
                          data={types}
                        />
                        <FieldDayPicker name="date_get" label="Data nabycia" />
                        <FieldDayPicker
                          name="date_deadline"
                          label="Termin ważności"
                        />
                      </div>
                    </TwoInputWrapper>
                    <ButtonWrapper>
                      <Button type="submit">Zapisz i zamknij</Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class SummaryMedicalExaminations extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  openModal() {}

  render() {
    return (
      <>
        <SummaryMedicalExaminationsModal {...this.props} />
      </>
    );
  }
}
