export const defaultUrls = {
  ADDTYPE: "/super-admin/draft/qe-type/add",
  EDITTYPE: (id) => `/super-admin/draft/qe-type/edit/${id}`,
  LISTTYPE: `/super-admin/draft/qe-type`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
