import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import PropTypes from "prop-types";
import moment from "moment";

import { pingCharacteristic } from "../../actions/characteristicActions";
import dataToFormData from "../../components/dataToFormData";
import CharacteristicForm from "../../components/Characteristic.Form";
import { addCharacteristic } from "../../actions/characteristicActions";
class CharacteristicAdd extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // componentDidMount() {
  //  pingCharacteristic()(dispatch).then(this.handleServerResponse());
  // }

  handleSubmit(data) {
    data.date = data.date || new Date();
    const { dispatch, history } = this.props;
    const formData = dataToFormData(data);
    addCharacteristic(formData)(dispatch).then(
      this.handleServerResponse({}, function () {
        history.push("/characteristic");
      })
    );
  }

  render() {
    const { message } = this.state;

    const initialData = {
      date: moment().format("YYYY-MM-DD"),
    };
    return (
      <CharacteristicForm
        message={message}
        createView={true}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect((store) => ({
  message: store.message,
}))(CharacteristicAdd);
