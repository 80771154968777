import HtmlToReact from "html-to-react";
const HtmlToReactParser = HtmlToReact.Parser;

function HtmlToReactParse(htmlInput){
  const htmlToReactParser = new HtmlToReactParser();
  return htmlToReactParser.parse(htmlInput);
}

export default HtmlToReactParse;

