export function getCertificateOSHList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_CERTIFICATE_LIST",
      payload: {
        request: {
          url: `/certificate/osh`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getCertificateOSHDraft(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_CERTIFICATE_ITEM",
      payload: {
        request: {
          url: `/certificate/osh/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addCertificateOSHDraft(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_CERTIFICATE_ITEM",
      payload: {
        request: {
          url: `/certificate/osh/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteCertificateOSHDraft(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_CERTIFICATE_ITEM",
      payload: {
        request: {
          url: `/certificate/osh/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function printCertificateOSH(data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/certificate/osh/print`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}
