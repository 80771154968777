import React from "react";
import { connect } from "react-redux";

import {
  clearUsersReducer,
  deleteAvatar,
  deleteOSHTraining,
  deletePreventiveMedicalExaminations,
  getHiredStatuses,
  editUser,
  getUser,
  getUserByWorkplace,
  saveAvatar,
  savePreventiveMedicalExaminations,
  saveOSHTraining,
  saveExtraTrainings,
} from "actions/userActions";
import { getDictionary } from "actions/dictionaryActions";
import { getLocalizations } from "actions/localizationActions";
import {
  getWorkplacesWithout,
  getParentWorkplacesWithout,
} from "actions/workplaceActions";

import { getSections } from "actions/sectionActions";
import { getBusinessModulesByUser } from "actions/moduleActions";
import { getBusinessMESingle, saveME, deleteME } from "actions/meActions";
import {
  getBusinessOSHSingle,
  deleteOSH,
  getBusinessOSHTypeList,
  addBusinessOSH,
} from "actions/OSHTrainingActions";

import {
  getBusinessQESingle,
  addBusinessQE,
  saveBusinessQE,
  getBusinessQETypeList,
} from "actions/QEActions";

import AppComponent, { setMessage } from "components/AppComponent";
import UserAccountsForm from "components/UsersAccounts.Form/index";
import LoadingScreen from "components/LoadingScreen/";
class UserAccountsEdit extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, localFetched: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
    this.handleAvatarAutoUpload = this.handleAvatarAutoUpload.bind(this);
    this.submitPreventiveMedicalExaminationsComponent = this.submitPreventiveMedicalExaminationsComponent.bind(
      this
    );
    this.submitOSHTrainingComponent = this.submitOSHTrainingComponent.bind(
      this
    );
    this.deletePreventiveMedicalExaminations = this.deletePreventiveMedicalExaminations.bind(
      this
    );
    this.deleteOSHTraining = this.deleteOSHTraining.bind(this);
    this.saveExtraTrainings = this.saveExtraTrainings.bind(this);
    this.saveME = this.saveME.bind(this);
    this.deleteME = this.deleteME.bind(this);
    this.deleteOSH = this.deleteOSH.bind(this);
    this.addOSH = this.addOSH.bind(this);
    this.addQE = this.addQE.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;
    const that = this;
    this.updateMessage();
    Promise.all([
      getUser(user_id)(dispatch).then(this.handleServerResponse()),
      getBusinessModulesByUser(user_id)(dispatch).then(
        this.handleServerResponse()
      ),
      getLocalizations()(dispatch).then(this.handleServerResponse()),
      getWorkplacesWithout(null)(dispatch).then(this.handleServerResponse()),
      getHiredStatuses()(dispatch).then(this.handleServerResponse()),
      getBusinessMESingle(user_id)(dispatch).then(this.handleServerResponse()),
      getBusinessOSHSingle(user_id)(dispatch).then(this.handleServerResponse()),
      getBusinessQESingle(user_id)(dispatch).then(this.handleServerResponse()),
      getDictionary("ability-to-work")(dispatch).then(
        this.handleServerResponse()
      ),
      getBusinessOSHTypeList()(dispatch).then(this.handleServerResponse()),
      getBusinessQETypeList({ readOnly: true })(dispatch).then(
        this.handleServerResponse()
      ),
    ]).then(() => {
      const {
        singleData: { localization_id, workplace_parent_id },
      } = this.props.users;
      const promise = [];
      promise.push(
        getParentWorkplacesWithout()(dispatch).then(this.handleServerResponse())
      );

      if (localization_id) {
        promise.push(
          getSections(localization_id)(dispatch).then(
            this.handleServerResponse()
          )
        );
      }

      if (workplace_parent_id) {
        promise.push(
          getUserByWorkplace(
            workplace_parent_id,
            user_id
          )(dispatch).then(this.handleServerResponse())
        );
      }

      Promise.all(promise).then(() => {
        that.setState({ localFetched: true });
      });
    });
  }

  handleSubmit(data) {
    const { dispatch, match, history } = this.props;
    const { user_id } = match.params;
    editUser(
      user_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/users-accounts");
      })
    );
  }

  submitPreventiveMedicalExaminationsComponent(data) {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;
    let { id, ...value } = data;

    savePreventiveMedicalExaminations(
      user_id,
      data,
      id
    )(dispatch).then(this.handleServerResponse());
  }

  submitOSHTrainingComponent(data) {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;
    let { id, ...value } = data;

    saveOSHTraining(
      user_id,
      data,
      id
    )(dispatch).then(this.handleServerResponse());
  }

  deletePreventiveMedicalExaminations(id) {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;

    deletePreventiveMedicalExaminations(
      user_id,
      id
    )(dispatch).then(this.handleServerResponse());
  }

  deleteOSHTraining(id) {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;

    deleteOSHTraining(user_id, id)(dispatch).then(this.handleServerResponse());
  }

  handleAvatarAutoUpload(formData, callback) {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;
    saveAvatar(
      user_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { message, avatar_url } = action.payload.data;
        this.setState({
          message: setMessage(action.payload.data.message, "success"),
        });
        callback(avatar_url);
      })
    );
  }

  handleDeleteAvatar() {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;
    deleteAvatar(user_id)(dispatch).then(this.handleServerResponse({}));
  }

  saveExtraTrainings(data) {
    const { dispatch, match } = this.props;
    const { user_id } = match.params;
    saveExtraTrainings(
      user_id,
      data
    )(dispatch).then(this.handleServerResponse({}));
  }

  saveME(data) {
    let { dispatch, match } = this.props;
    const { user_id } = match.params;

    let { id } = data;
    saveME(id, { ...data, users_id: user_id })(dispatch).then(
      this.handleServerResponse({}, () => {
        getBusinessMESingle(user_id)(dispatch).then(
          this.handleServerResponse()
        );
      })
    );
  }

  deleteME(id) {
    let { dispatch, match } = this.props;
    let { user_id } = match.params;
    deleteME(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);

        getBusinessMESingle(user_id)(dispatch).then(
          this.handleServerResponse({}, () => {})
        );
      })
    );
  }

  deleteOSH(id) {
    let { dispatch, match } = this.props;
    let { user_id } = match.params;
    deleteOSH(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);

        getBusinessOSHSingle(user_id)(dispatch).then(
          this.handleServerResponse({}, () => {})
        );
      })
    );
  }

  addOSH(data) {
    let { dispatch, match } = this.props;
    let { user_id } = match.params;
    addBusinessOSH({ ...data, user_id })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        this.setState({
          modalUserDetails: {
            ...this.state.modalUserDetails,
            data: {},
          },
        });
        getBusinessOSHSingle(user_id)(dispatch).then(
          this.handleServerResponse({}, () => {})
        );
      })
    );
  }
  addQE(data) {
    let { dispatch, match } = this.props;
    let { user_id } = match.params;
    let { id } = data;

    saveBusinessQE(id, { ...data, users_id: user_id })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        getBusinessQESingle(user_id)(dispatch).then(
          this.handleServerResponse()
        );
      })
    );
  }

  render() {
    const {
      users: {
        singleData,
        data: userParent,
        fetched: userFetched,
        preventive_medical_examinations_view,
        osh_training_view,
      },
      localization: {
        data: localizationData = [],
        fetched: localizationFetched,
      },
      workplace: { data: workplaceData = [], parentWorkplaces },
      modules: { data: moduleData },
      dictionary,
      me,
      osh,
      qe,
      types,
      qeTypes,
    } = this.props;

    const { message, localFetched } = this.state;
    const { user_id } = this.props.match.params;
    const { permissions, ...data } = singleData;
    return (
      <LoadingScreen fetched={true}>
        <UserAccountsForm
          admin
          boxTitle="Edytuj użytkownika"
          data={{ ...data, workplace_id: data.workplace_id || "0" }}
          deletePreventiveMedicalExaminations={
            this.deletePreventiveMedicalExaminations
          }
          businessView
          deleteOSHTraining={this.deleteOSHTraining}
          entry_id={user_id}
          handleAvatarAutoUpload={this.handleAvatarAutoUpload}
          handleDeleteAvatar={this.handleDeleteAvatar}
          handleSubmit={this.handleSubmit}
          localizations={localizationData}
          message={message}
          modules={moduleData}
          osh_training_view={osh_training_view}
          parent_workplaces={workplaceData}
          permissions={permissions}
          preventiveMedicalExaminationsView={
            preventive_medical_examinations_view
          }
          saveExtraTrainings={this.saveExtraTrainings}
          submitPreventiveMedicalExaminationsComponent={
            this.submitPreventiveMedicalExaminationsComponent
          }
          submitOSHTrainingComponent={this.submitOSHTrainingComponent}
          users={userParent}
          workplaces={workplaceData}
          dictionary={dictionary}
          me={me}
          osh={osh}
          qe={qe}
          saveME={this.saveME}
          addOSH={this.addOSH}
          addQE={this.addQE}
          deleteME={this.deleteME}
          deleteOSH={this.deleteOSH}
          types={types}
          qeTypes={qeTypes}
          getSections={getSections}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    this.setState({ localFetched: true });
    clearUsersReducer()(this.props.dispatch);
  }
}

export default connect((store) => ({
  users: store.users,
  localization: store.localization,
  workplace: store.workplace,
  message: store.message,
  modules: store.modules,
  dictionary: store.dictionary,
  me: store.me.singleData,
  osh: store.osh.singleData,
  qe: store.qe.singleData,
  types: store.osh.types,
  qeTypes: store.qe.types,
}))(UserAccountsEdit);
