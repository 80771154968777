import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import styled from "styled-components";

import { StyledInput } from "../../style/styleComponents/Form/input";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import {
  archiveCharacteristic,
  getCharacteristics,
  getCharacteristicsByFilter,
  deleteCharacteristic,
  downloadCharacteristic,
  printCharacteristicRegister,
} from "actions/characteristicActions";
import { getLocalizationsOnly } from "actions/localizationActions";
import {
  getUserSectionsIndependently,
  getUserSections,
} from "actions/sectionActions";

import { LinkContainer } from "../../components/LinkContainer";
import AppComponent from "../../components/AppComponent";
import { LayoutContent } from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import Pagination from "../../components/Pagination";
import Select from "../../components/Select";
import Modal, { PDFViewModalSchema } from "../../components/Modal/PDFViewModal";
import ModalSimpleAccept from "../../components/Modal/SimpleAccept";
import { TableSortable } from "../../components/TableSortable";
import {
  StyledTableWrapper,
  DeleteButton,
  ButtonWithIcon,
  LpTd,
  SpanAsLink,
  DownloadButton,
  StyledTableList,
} from "../../components/ListComponents";
import { media } from "../../style/style-utils";
import PageHeader from "../../components/PageHeader";

import saveBlobFile from "../../components/saveBlobFile";

import LoadingScreen from "../../components/LoadingScreen";
import Media from "react-media";
import StandardFilter from "./_standardFilter";

import { ButtonWrapper } from "components/Wrappers";
const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px 15px;

  ${media.xs`
    margin: 0 0 10px;
    flex-direction:column;
  `}

  & > * {
    margin: 0 10px;
    ${media.xs`
      margin: 0 0 10px
    `}
  }
`;

export const LetterComponent = (props) => {
  const Letters = "abcdefghijklmnopqrstuvwxyz".split("");

  const carryingFunction = (letter) => () => props.handleClick(letter);

  return (
    <div className={props.className}>
      <span onClick={carryingFunction("[0-9]")}>123</span>
      {Letters.map((letter, index) => (
        <span key={index} onClick={carryingFunction(letter)}>
          {letter}
        </span>
      ))}
      <Media query="(max-width: 767px)">
        {(matches) => (matches ? <br /> : "")}
      </Media>
      <span onClick={carryingFunction(null)}>Wszystkie</span>
    </div>
  );
};

const StyledLetterComponent = styled(LetterComponent)`
  text-align: center;
  & span {
    margin: 0 3px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
    &:last-child {
      margin-left: 20px;
      ${media.xs`
        margin-left:auto;
      `}
    }
  }
`;

const modalSchema = { ...PDFViewModalSchema };

export class CharacteristicList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: [],
      filteredData: [],
      modal: { ...modalSchema },
      localFetched: false,
      simpleAnswerModal: {
        open: false,
        entry_id: null,
      },
      archiveModal: {
        open: false,
        entry_id: null,
      },

      alphabeticalFilter: null,
      filter: {
        localization_id: null,
        section_name: null,
        name: null,
        adad: [1],
      },

      archived: false,
      editUrlPrefix: "/characteristic/edit/",
    };
    if (/\/archived/.test(props.match.path)) {
      this.state.archived = true;
    } else {
      this.state.archived = false;
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePdfModalOpen = this.handlePdfModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleLetterClick = this.handleLetterClick.bind(this);
    this.handleFilterData = this.handleFilterData.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handlePrintCharacteristicRegister = this.handlePrintCharacteristicRegister.bind(
      this
    );
    this.handleArchive = this.handleArchive.bind(this);
    this.editUrlPrefix = "/characteristic/edit/";
  }

  componentDidMount() {
    let { filter, archived } = this.state;
    const { dispatch } = this.props;
    this.updateMessage();

    Promise.all([
      getCharacteristicsByFilter({ ...filter, archived })(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getLocalizationsOnly()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      // getUserSectionsIndependently()(dispatch),
      getUserSectionsIndependently()(dispatch),
    ]).then(() => {
      const {
        characteristic: { data },
      } = this.props;
      this.setState({ localFetched: true, filteredData: data });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.characteristic.data) !=
      JSON.stringify(this.props.characteristic.data)
    ) {
      this.handleFilterData();
    }
  }

  deleteRequest(entry_id) {
    const that = this;
    return () => {
      that.setState({
        simpleAnswerModal: { ...this.simpleAnswerModal, open: true, entry_id },
      });
    };
  }

  handleModalOpen(modalName, entry_id) {
    const that = this;
    return () => {
      that.setState({
        [modalName]: { ...this.state[modalName], open: true, entry_id },
      });
    };
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  handleLetterClick(letter) {
    this.setState({ filterLetter: letter }, () => {
      this.handleFilterData();
    });
  }

  handleFilterData() {
    const {
      characteristic: { data },
    } = this.props;
    const { filterLetter: letter } = this.state;
    let filteredData = data;
    if (letter) {
      const alphabeticalFilterRegex = letter
        ? new RegExp(`^${letter}`, "i")
        : null;
      filteredData = data.filter((item) =>
        alphabeticalFilterRegex.test(item.name)
      );
    }

    this.setState({ filteredData });
  }

  handleDelete() {
    const { entry_id } = this.state.simpleAnswerModal;
    const { dispatch } = this.props;

    deleteCharacteristic(entry_id)(dispatch).then(this.handleServerResponse());
  }

  handleArchive() {
    let { dispatch, history } = this.props;
    let {
      archiveModal: { entry_id },
    } = this.state;
    archiveCharacteristic({ characteristic_id: entry_id })(dispatch).then(
      () => {
        history.push("/characteristic/archived");
      }
    );
  }

  handleDownload(url, name, index) {
    return () => {
      downloadCharacteristic(
        url,
        index
      )(this.props.dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `karta-charakterystyk-${name}.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    };
  }

  handlePdfModalOpen(index) {
    const data = this.props.characteristic.data;
    const { name, pdf } = data[index];
    this.setState({
      modal: {
        ...this.state.modal,
        open: true,
        name: name,
        index,
        simpleUrl: pdf,
        file: `/api${pdf}`,
      },
    });
  }

  handleFilterChange(name) {
    return (value) => {
      let filter = { ...this.state.filter, [name]: value };
      if (name == "localization_id") {
        filter.section_id = undefined;
      }
      this.setState({ filter });
    };
  }

  handlePrintCharacteristicRegister() {
    const { filteredData } = this.state;
    let characteristic_ids = filteredData.map((item) => {
      return item.id;
    });

    printCharacteristicRegister({ characteristic_ids })(
      this.props.dispatch
    ).then((res) => {
      if (!res.error) {
        const file = res.payload.data;
        const fileName = `Lista-kart-charakterystyk-.pdf`;
        saveBlobFile(file, fileName);
      }
    });
  }

  handleModalClose(modalName) {
    this.setState({ [modalName]: { ...modalSchema } });
  }

  render() {
    const {
      data,
      modal,
      simpleAnswerModal,
      message,
      data: stateData,
      localFetched,
      filteredData,
      filter: { localization_id, section_name },
      archived,
      archiveModal,
    } = this.state;
    const {
      characteristic: { fetched, permission, printFetching },
      localization: { data: localizations },
      section: { data: sections },
      dispatch,
    } = this.props;

    return (
      <LoadingScreen fetched={fetched || localFetched}>
        <LayoutContent
          error404={this.state.error_code == 404}
          error403={this.state.error_code == 403}
          error_code={this.state.error_code}
          SideMenu={
            <SideMenu
              title="MSDS"
              data={[
                { name: "Karty charakterystyk", url: "/characteristic" },
                {
                  name: "Archiwum kart",
                  url: "/characteristic/archived",
                },
              ]}
            />
          }
        >
          {message && message.text && (
            <Col>
              <Alert bsStyle={message.className}>{message.text}</Alert>
            </Col>
          )}
          <PageHeader>Lista kart charakterystyk</PageHeader>

          <StandardFilter
            dispatch={dispatch}
            localizations={localizations}
            sections={sections}
            changeFilter={() => {}}
            searchAction={getCharacteristicsByFilter}
            handleServerResponse={() => {
              this.handleServerResponse({}, () => {});
            }}
          />
          <FilterWrapper style={{ justifyContent: "space-around" }}>
            <StyledLetterComponent handleClick={this.handleLetterClick} />
          </FilterWrapper>
          <ButtonWrapper>
            <LinkContainer to="/characteristic/add">
              <ButtonWithIcon
                disabled={!permission[2]}
                icon="fa fa-plus"
                name="Dodaj kartę"
              />
            </LinkContainer>
            <DownloadButton
              fetching={printFetching}
              disabled={!permission[19]}
              icon="fa fa-download"
              name="Drukuj rejestr"
              onClick={this.handlePrintCharacteristicRegister}
            />
          </ButtonWrapper>
          <LoadingScreen fetched={fetched}>
            <StyledTableWrapper>
              <StyledTableList quickMenu striped bordered condensed hover>
                <TableSortable
                  headers={
                    archived
                      ? [
                          { name: "L.p." },
                          { name: "Nazwa", prop: "name", sort_type: "string" },
                          { name: "Data sporządzenia", prop: "date_create" },
                          { name: "Data archiwizacji", prop: "date_archived" },
                          { name: "Pobierz kartę", prop: "" },
                          { name: "Edytuj", prop: "" },
                          { name: "Usuń", prop: "" },
                        ]
                      : [
                          { name: "L.p." },
                          { name: "Nazwa", prop: "name", sort_type: "string" },
                          { name: "Data sporządzenia", prop: "date_create" },
                          { name: "Pobierz kartę", prop: "" },
                          { name: "Edytuj", prop: "" },
                          { name: "Przenieś do archiwum", prop: "" },
                          { name: "Usuń", prop: "" },
                        ]
                  }
                  quickMenu
                  striped
                  bordered
                  condensed
                  hover
                  data={data}
                  render={(sortedData) => {
                    return sortedData.map((item, index) => (
                      <tr key={index}>
                        <LpTd>{index + 1}</LpTd>
                        <td>
                          <SpanAsLink
                            onClick={this.handlePdfModalOpen.bind(this, index)}
                          >
                            {item.name}
                          </SpanAsLink>
                        </td>
                        <td>{item.date_create}</td>
                        {archived ? <td>{item.date_archived}</td> : ""}
                        <td>
                          <DownloadButton
                            fetching={item.fetching}
                            disabled={item.fetching}
                            listButton
                            icon="fa fa-download"
                            onClick={this.handleDownload(
                              item.pdf,
                              item.name,
                              index
                            )}
                            name="Pobierz"
                          />
                        </td>
                        <td>
                          <LinkContainer to={`${this.editUrlPrefix}${item.id}`}>
                            <Button>
                              {permission[3] ? "Edytuj" : "Podgląd"}
                            </Button>
                          </LinkContainer>
                        </td>
                        {!archived && (
                          <td>
                            <ButtonWithIcon
                              name={"Przenieś"}
                              disabled={!permission[20]}
                              onClick={this.handleModalOpen(
                                "archiveModal",
                                item.id
                              )}
                            />
                          </td>
                        )}

                        <td>
                          <DeleteButton
                            disabled={!permission[4]}
                            onClick={this.handleModalOpen(
                              "simpleAnswerModal",
                              item.id
                            )}
                          />
                        </td>
                      </tr>
                    ));
                  }}
                />
              </StyledTableList>
            </StyledTableWrapper>
          </LoadingScreen>
          <Pagination
            handlePageChange={this.handlePageChange.bind(this)}
            data={filteredData}
          />
          <Modal
            open={modal.open}
            handleClose={this.handleModalClose.bind(this, "modal")}
            title={modal.name}
            handleDownload={this.handleDownload(
              modal.simpleUrl,
              modal.name,
              modal.index
            )}
            file={modal.file}
          />
          <ModalSimpleAccept
            open={simpleAnswerModal.open}
            handleClose={this.handleModalClose.bind(this, "simpleAnswerModal")}
            handleAccept={this.handleDelete}
            title="Usuń kartę charakterystyki"
            description="Czy chcesz usunąć kartę charakterystyki?"
          />
          <ModalSimpleAccept
            open={archiveModal.open}
            handleClose={this.handleModalClose.bind(this, "archiveModal")}
            handleAccept={this.handleArchive}
            title="Archiwizuj kartę"
            description="Czy chcesz zarchiwizować kartę charakterystyki?"
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  characteristic: store.characteristic,
  localization: store.localization,
  section: store.section,
  message: store.message,
}))(CharacteristicList);

LetterComponent.propTypes = {
  handleClick: PropTypes.func,
};
