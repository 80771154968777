const defaultState = {
  data: [],
  singleData: {},
  fetching: false,
  fetched: true,
  types: [],
  type: {},
  draft: {},
  drafts: [],
  archives: [],
  referral: {},
  permission: {},
  archive_workplaces: [],
};
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "GET_ME_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, data, permission };
    }

    case "GET_ME_TYPES_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;
      return { ...state, types: data, permission };
    }

    case "GET_ME_ARCHIVES_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, archives: data, permission };
    }
    case "GET_ME_DRAFT_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, drafts: data, permission };
    }
    case "GET_ME_DRAFT_ITEM_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, draft: data, permission };
    }
    case "GET_ME_REFERRAL_SUCCESS": {
      const { data, permission } = action.payload.data;
      return { ...state, referral: data, permission };
    }
    case "DELETE_ME_ARCHIVES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        archives: state.archives.filter(
          (item) => item.id != action.payload.data.data.id
        ),
      };
      return state;
    }

    case "DELETE_ME_DRAFT_SUCCESS": {
      const { data } = action.payload.data;
      let newData = [...state.drafts].filter((i) => i.id != data.id);
      return { ...state, drafts: newData };
    }

    case "DELETE_REFERRAL_BY_DATES_SUCCESS": {
      const { data } = action.payload.data;

      let dataIds = data.map((i) => i.id);

      let newData = [...state.archives].filter((i) => {
        let index = dataIds.indexOf(i.id);

        return index == -1;
      });
      return { ...state, archives: newData };
    }

    case "GET_ME_SINGLE_SUCCESS": {
      const { data } = action.payload.data;

      return { ...state, singleData: data };
    }
    case "GET_ARCHIVE_WORKPLACES_SUCCESS": {
      let archive_workplaces = action.payload.data.data || [];
      return { ...state, archive_workplaces };
    }
    case "CLEAR_ME_REDUCER": {
      return defaultState;
    }
  }

  return state;
}
