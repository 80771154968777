import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Alert from "react-bootstrap/lib/Alert";

import { getWorkplacesWithout } from "actions/workplaceActions";
import {
  getNotifications,
  addUserToGroup,
  addNotifications,
  deleteNotification,
  deleteUserFromGroup,
} from "actions/notificationActions";

import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import NotificationList from "components/NotificationComponent/NotificationList";
import Modal from "components/Modal/SimpleAccept";
import PageHeader from "components/PageHeader";

class SingleLocationNotificationList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalNotificationDelete: {
        open: false,
        title: null,
        description: null,
        entry_id: null,
      },
      modalUserDelete: {
        open: false,
        title: null,
        description: null,
        entry_id: null,
      },
    };
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleAddUserToGroup = this.handleAddUserToGroup.bind(this);
    this.handleAddNotification = this.handleAddNotification.bind(this);
    this.handleDeleteNotification = this.handleDeleteNotification.bind(this);
    this.handleDeleteUserFromGroup = this.handleDeleteUserFromGroup.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    getWorkplacesWithout(null)(dispatch).then(this.handleServerResponse());
    getNotifications(localization_id)(dispatch).then(
      this.handleServerResponse({}, function () {})
    );
  }

  handleModalOpen(name) {
    return ({ id, title }) => {
      this.setState({
        [name]: {
          ...this.state[name],
          open: true,
          entry_id: id,
          title: `Usuń - ${title}`,
        },
      });
    };
  }

  handleModalClose(name) {
    return () => {
      this.setState({
        [name]: { ...this.state[name], open: false, title: null },
      });
    };
  }

  handleAddNotification(data) {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    data.term = data.term == "null" ? null : data.term;
    addNotifications(
      localization_id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  handleAddUserToGroup(notification_group_id, user_id) {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    addUserToGroup(
      localization_id,
      notification_group_id,
      user_id
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteUserFromGroup() {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    deleteUserFromGroup(
      localization_id,
      this.state.modalUserDelete.entry_id
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteNotification() {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    const {
      modalNotificationDelete: { entry_id },
    } = this.state;
    deleteNotification(localization_id, { notification_id: entry_id })(
      dispatch
    ).then(this.handleServerResponse());
  }

  render() {
    const {
      workplace: { data: workplaceData },
      notification: { data: notification_groups },
    } = this.props;

    const { modalNotificationDelete, modalUserDelete, message } = this.state;

    return (
      <LayoutContent>
        {message && message.text && (
          <Alert bsStyle={message.className}>{message.text}</Alert>
        )}
        <PageHeader>Rodzaje powiadomień automatycznych</PageHeader>
        {notification_groups.map((item) => (
          <NotificationList
            permission={true}
            workplaces={workplaceData}
            notification_group_id={item.notification_group_id}
            handleAddNotification={this.handleAddNotification}
            handleAddUserToGroup={this.handleAddUserToGroup}
            handleDelete={this.handleModalOpen("modalNotificationDelete")}
            handleDeleteUser={this.handleModalOpen("modalUserDelete")}
            notifications={item.notifications}
            name={item.notification_group_name}
            data={item.users}
          />
        ))}
        <Modal
          title={modalNotificationDelete.title}
          description="Czy chcesz usunąć powiadomienie?"
          open={modalNotificationDelete.open}
          handleClose={this.handleModalClose("modalNotificationDelete")}
          handleAccept={this.handleDeleteNotification}
        />
        <Modal
          title={modalUserDelete.title}
          description="Czy chcesz usunąć użytkownika z grupy powiadomień?"
          open={modalUserDelete.open}
          handleClose={this.handleModalClose("modalUserDelete")}
          handleAccept={this.handleDeleteUserFromGroup}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  workplace: store.workplace,
  notification: store.notification,
}))(SingleLocationNotificationList);
