import React from "react";
import Button from "react-bootstrap/lib/Button";

import { printRegisterIncidentList } from "actions/incidentActions";
import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldDayPicker, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";
import saveBlobFile from "components/saveBlobFile";
let checkboxes = [
  {
    name: "personal_true",
    label: "wypadki przy pracy",
  },
  {
    name: "personal_false",
    label: "zdarzenia potencjalnie wypadkowe",
  },
];

export default class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.printRegisterIncidentList = this.printRegisterIncidentList.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  openModal() {
    this.setState({ open: true });
  }

  printRegisterIncidentList(data) {
    let { dispatch } = this.props;
    let filename = "Rejestr wypadków przy pracy";
    printRegisterIncidentList(data)(dispatch).then((res) => {
      if (!res.error) {
        const file = res.payload.data;
        const fileName = `${filename}.pdf`;
        saveBlobFile(file, fileName);
      }
    });
  }

  render() {
    return (
      <>
        <Button onClick={this.openModal}>Drukuj rejestr</Button>
        <FormModal
          open={this.state.open}
          title="Drukuj rejetr"
          acceptButtonName="Wygeneruj PDF"
          cancelButtonName="Anuluj"
          handleSubmit={(values) => {
            this.printRegisterIncidentList(values);
          }}
          handleClose={this.closeModal}
        >
          <h5>Drukuj rejestr</h5>
          <LeftPadding>
            {checkboxes.map((item) => {
              return <FieldInputCheckBox name={item.name} label={item.label} />;
            })}
          </LeftPadding>
          <TwoInputWrapper>
            <FieldDayPicker name="from" label="Zdarzenia od" />
            <FieldDayPicker name="to" label="Zdarzenia do" />
          </TwoInputWrapper>
        </FormModal>{" "}
      </>
    );
  }
}
