import React from "react";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import {Link} from "react-router-dom";
import {LayoutContent} from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import {ButtonWrapper} from "../../components/ListComponents";
import {LinkContainer} from "../../components/LinkContainer";
import Pagination from "../../components/Pagination";
import {media} from "../../style/style-utils";

import {DeleteButton} from "../../components/ListComponents";

import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import PageHeader from "../../components/PageHeader";

const StyledTableWrapper = styled.div`
  overflow-x: auto;
`;
const StyledTable = styled(Table)`
  ${media.xs`
    width:100%;
  `} 
  & th {
    border-left: 1px solid ${props=>props.theme.tableBorderColor};
    padding: 5px;
    box-sizing: border-box;
  }
  & td {
    vertical-align: center;
    text-align: center;
    &:nth-child(n+2):nth-child(-n+3){
      text-align: left;
    }
  }
`;

class ClientsAccountList extends AppComponent {
  render() {

    const accountsList = [
      {name:"Pierwsza firma",date_add:"2018-02-02",active:true,admin_account_url:"/admin",url:"/adas"},
      {name:"Druga firma",date_add:"2018-02-02",active:true,admin_account_url:"/admin",url:"/adas"},
      {name:"Trzecia firma",date_add:"2018-02-02",active:true,admin_account_url:"/admin",url:"/adas"},
      {name:"Czwarta firma",date_add:"2018-02-02",active:false,admin_account_url:"/admin",url:"/adas"}
    ];

    return (<LayoutContent
      SideMenu={<SideMenu title={"Konta firm"} data={[
        {name: "Lista kont", url: "/a"},
        {name: "Dodaj konto", url: "/a"}
      ]}/>}
    >
      <PageHeader>Lista kont</PageHeader>
      <ButtonWrapper>
        <LinkContainer to="/asdadsasd"><Button>Dodaj konto</Button></LinkContainer>
      </ButtonWrapper>
      <StyledTableWrapper>
        <StyledTable striped bordered condensed hover>
          <thead>
            <tr>
              <td>L.p.</td>
              <td>Nazwa firmy</td>
              <td>Data utworzenia</td>
              <td>Konto firmy aktywne</td>
              <td>Konto administratora</td>
              <td>Usuń</td>
            </tr>
          </thead>
          <tbody>
            {accountsList.map((item,index)=>(
              <tr key={index}>
                <td>{index+1}</td>
                <td>
                  <Link to={item.url}>{item.name}</Link>
                </td>
                <td>{item.date_add}</td>
                <td>
                  <input type="checkbox" defaultChecked={item.active} disabled/>
                </td>
                <td>
                  <Button class="fa fa-cog"/>
                </td>
                <td>
                  <DeleteButton

                  />
                </td>
              </tr>
            ))}

          </tbody>
        </StyledTable>
      </StyledTableWrapper>
      <Pagination />
    </LayoutContent>);
  }
}

export default ClientsAccountList;