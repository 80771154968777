import React from "react";
import Button from "react-bootstrap/lib/Button";
import Link from "react-router-dom/Link";

import AppComponent, { ModelComponent } from "components/AppComponent";
import { AddButton, DeleteButton } from "components/Buttons";
import { LayoutContent } from "components/Layout";
import { ButtonWrapper } from "components/Wrappers";
import { LinkContainer } from "components/LinkContainer";
import PageHeader from "components/PageHeader";
import Pagination from "components/Pagination";
import LoadingScreen from "components/LoadingScreen";
import Modal from "components/Modal/SimpleAccept";

import { TableListPage } from "components/Tables";

let defaultUrls = {
  ADD: "/",
  EDIT: (id) => `/`,
};

let defaultNames = {
  TITLE: "Wzorcowe zagrożenia",
};

export default class TemplateList extends ModelComponent {
  constructor(props) {
    super(props);

    let { urls = {}, names = {} } = this.props;
    this.state = {
      ...this.state,
      data: [],
      urls: { ...defaultUrls, ...urls },
      names: { ...defaultNames, ...names },
      modal: this.initModalState(),
    };

    this.deleteEntry = this.deleteEntry.bind(this);
  }

  deleteEntry() {
    let { deleteEntry } = this.props;
    let { entry_id } = this.state.modal;
    deleteEntry(entry_id);
  }

  render() {
    let { urls, names, modal } = this.state;
    let {
      message,
      data = [],
      SideMenu,
      permission = {},
      superAdmin,
    } = this.props;

    return (
      <LoadingScreen fetched={true}>
        <LayoutContent
          error404={this.state.error_code == 404}
          message={message}
          SideMenu={<SideMenu />}
        >
          <PageHeader>{names.TITLE}</PageHeader>
          <ButtonWrapper>
            <LinkContainer to={urls.HAZARDADD}>
              <AddButton
                disabled={!superAdmin && !permission[29]}
                name="Dodaj nowe zagrożenie"
              />
            </LinkContainer>
          </ButtonWrapper>
          <TableListPage>
            <thead>
              <th>L.p.</th>
              <th>Nazwa</th>
              <th>Usuń</th>
            </thead>
            <tbody>
              {this.state.data.map((item, index) => {
                const readOnly =
                  !superAdmin && (item.business_id === 0 || !permission[31]);
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={urls.HAZARDEDIT(item.id)}>{item.name}</Link>
                    </td>
                    <td>
                      <DeleteButton
                        disabled={readOnly}
                        onClick={this.openModal("modal", { entry_id: item.id })}
                        name="Usuń"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableListPage>
          <Pagination handlePageChange={this.changePage} data={data} />
        </LayoutContent>
        <Modal
          title="Usuń metody oceny ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modal.open}
          handleAccept={this.deleteEntry}
          handleClose={this.closeModal("modal", this.initModalState)}
        />
      </LoadingScreen>
    );
  }
}
