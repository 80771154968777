import React from "react";
import { connect } from "react-redux";
import { addAuditType, pingAudit } from "actions/auditActions";

import AppComponent from "components/AppComponent";
import AuditTypeForm from "components/AuditTypes.Form";

import SideMenu from "./_SideMenu";

class AuditAddTypes extends AppComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    pingAudit()(dispatch).then(this.handleServerResponse({}, () => {}));
  }

  submit(values) {
    const { dispatch, history } = this.props;
    addAuditType(values)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/audit/type");
      })
    );
  }

  render() {
    const {
      readOnly,
      audit: { permission },
    } = this.props;
    const { message } = this.state;
    return (
      <AuditTypeForm
        SideMenu={<SideMenu permission={permission} />}
        message={message}
        readOnly={readOnly}
        error_code={this.state.error_code == 404}
        handleSubmit={this.submit}
      />
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
}))(AuditAddTypes);
