import LList from "./List";
import LRegister from "./Register";
import LAnalysis from "./Analysis";
import LAttachment from "./Attachment";
import LNotification from "./Notification";
import LRedirectComponent from "./RedirectComponent";

export const List = LList;
export const Register = LRegister;
export const Analysis = LAnalysis;
export const Attachment = LAttachment;
export const Notification = LNotification;
export const RedirectComponent = LRedirectComponent;
