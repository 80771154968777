import React from "react";
import { connect } from "react-redux";
import { LinkContainer } from "../../../components/LinkContainer";
import { Link } from "react-router-dom";
import {
  TaskList,
  StyledTableWrapper,
  DateTd,
  ProgressTd,
} from "../../Task/List";
import { addNewIncident } from "../defaultComponent";
import { getProgresses, getIncidentTasks } from "../../../actions/taskActions";
import { getUsersToTaskFilter } from "../../../actions/userActions";
import { getUsersLocalizations } from "../../../actions/localizationActions";
import moment from "moment/moment";
import LoadingScreen from "../../../components/LoadingScreen";
import { LayoutComponent, LayoutContent } from "../../../components/Layout";
import SideMenu from "../_SideMenu";
import PageHeader from "../../../components/PageHeader";
import { ButtonWrapper } from "../../../components/ListComponents";
import Button from "react-bootstrap/lib/Button";
import Modal from "../../../components/Modal/SimpleAccept";
import {
  acceptTask,
  getIncidentToAnalysis,
} from "../../../actions/incidentActions";
import { StyledTableList } from "../../../components/ListComponents";
import { ButtonWithIcon } from "../../../components/ListComponents";
import styled from "styled-components";
import TableSortable from "components/TableSortable";
const CustomStyledTableList = styled(StyledTableList)``;

class IncidentTaskList extends TaskList {
  constructor(props) {
    super(props);
    this.setNextStage = this.setNextStage.bind(this);
    this.addNewIncident = addNewIncident.bind(this);
  }

  getData() {
    const {
      dispatch,
      match,
      incident: { singleData: incidentData },
    } = this.props;
    const { incident_id } = match.params;

    const getData = [
      getIncidentTasks(incident_id)(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUsersLocalizations()(dispatch).then(this.handleServerResponse()),
      getProgresses()(dispatch).then(this.handleServerResponse()),
      getUsersToTaskFilter()(dispatch).then(this.handleServerResponse()),
    ];

    if (!Object.keys(incidentData).length || incidentData.id != incident_id) {
      getData.push(
        getIncidentToAnalysis(incident_id)(dispatch).then(
          this.handleServerResponse({}, () => {})
        )
      );
    }

    return getData;
  }

  setNextStage() {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    acceptTask(incident_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/incident/notification/${incident_id}`);
      })
    );
  }

  render() {
    const { match } = this.props;
    const { incident_id } = match.params;
    const { message, modal, localFetched } = this.state;
    const {
      task: {
        data,
        progresses,
        fetched: taskFetched,
        permission: taskPermission,
      },
      localization: { data: localizationData },
      section: { data: sectionData },
      users: { data: userData },
      incident: {
        singleData: { stage },
        permission,
      },
    } = this.props;
    const readOnly = !permission[12];
    return (
      <LoadingScreen fetched={localFetched}>
        <LayoutContent
          mobileNotHideMenu={true}
          message={message}
          SideMenu={
            <SideMenu
              incident_id={incident_id}
              permission={permission}
              stage={stage}
              addNewIncident={this.addNewIncident}
            />
          }
        >
          <PageHeader>Rejestr działań</PageHeader>
          <ButtonWrapper>
            <LinkContainer to={`/incident/task/${incident_id}/add`}>
              <Button disabled={readOnly}>Dodaj zadanie</Button>
            </LinkContainer>
          </ButtonWrapper>
          <LoadingScreen fetched={taskFetched}>
            <StyledTableWrapper>
              <StyledTableList cellspacing={0} bordered hover>
                <TableSortable
                  headers={[
                    { name: "" },
                    { name: "Symbol", prop: "symbol", sort_type: "string" },
                    { name: "Tytuł", prop: "title", sort_type: "string" },
                    { name: "Data rozpoczęcia", prop: "date_add" },
                    {
                      name: "Lokalizacja",
                      prop: "localization_name",
                      sort_type: "string",
                    },
                    {
                      name: "Dział firmy",
                      prop: "section_name",
                      sort_type: "string",
                    },
                    { name: "Termin zakończenia", prop: "date_end" },
                    {
                      name: "Osoba odpowiedzialna",
                      prop: "users_name",
                      sort_type: "string",
                    },
                    {
                      name: "Postęp",
                      prop: "progress_name",
                      sort_type: "string",
                    },
                    { name: "Usuń" },
                  ]}
                  data={data}
                  render={(data) => {
                    return data
                      .map((item) => ({
                        ...item,
                        date_add: moment(item.date_add).format("YYYY-MM-DD"),
                        date_end: item.date_end
                          ? moment(item.date_end).format("YYYY-MM-DD")
                          : "",
                      }))
                      .map((item, index) => (
                        <tr color={item.progress_color} key={index}>
                          {item.priority == 1 ? (
                            <td
                              style={{ color: "red" }}
                              title="Wysoki priorytet"
                              className=""
                            >
                              <i className="fa fa-warning" />
                            </td>
                          ) : (
                            <td></td>
                          )}

                          <td>{item.symbol}</td>
                          <td>
                            <Link
                              to={`/incident/task/${item.incident_id}/edit/${item.id}`}
                            >
                              {item.title}
                            </Link>
                          </td>
                          <DateTd>{item.date_add}</DateTd>
                          <td>{item.localization_name}</td>
                          <td>{item.section_name}</td>
                          <DateTd>{item.date_end}</DateTd>
                          <td>{item.users_name}</td>
                          <ProgressTd color={item.progress_color}>
                            {item.progress_name}
                          </ProgressTd>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "streatch",
                              }}
                            >
                              <ButtonWithIcon
                                disabled={!taskPermission[4]}
                                icon="fa fa-trash"
                                onClick={this.handleDeleteRequest(item.id)}
                                style={{ width: "100%" }}
                              >
                                <i className="fa fa-trash" />
                              </ButtonWithIcon>
                            </div>
                          </td>
                        </tr>
                      ));
                  }}
                />
              </StyledTableList>
            </StyledTableWrapper>
          </LoadingScreen>
          <ButtonWrapper>
            {permission[12] && stage == 3 ? (
              <Button onClick={this.setNextStage} bsStyle="primary">
                Dalej
              </Button>
            ) : (
              <LinkContainer to={`/incident/notification/${incident_id}`}>
                <Button disabled={stage < 4} bsStyle="primary">
                  Dalej
                </Button>
              </LinkContainer>
            )}
            <LinkContainer to={`/incident`} exact>
              <Button>Powrót do listy</Button>
            </LinkContainer>
          </ButtonWrapper>
          {/*<Pagination handlePageChange={this.handlePageChange.bind(this)} data={localization.data}/>*/}
          <Modal
            title="Usuń zadanie"
            description={"Czy chcesz usunąć dany wpis?"}
            open={modal.open}
            handleAccept={this.handleDelete}
            handleClose={this.handleCloseDeleteRequest}
            autoAcceptClose
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  task: store.task,
  localization: store.localization,
  incident: store.incident,
  section: store.section,
  users: store.users,
  message: store.message,
}))(IncidentTaskList);
