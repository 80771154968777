import React from 'react';
import SideMenu from "../../components/SideMenu";

const LSideMenu = ({audit_id,stage,permission={}}) => (
  <div>
    <SideMenu
      title="Audyty"
      data={[
        {name: "Rejestr audytów", url: "/audit",disabled:!permission[1]},
        {name: "Zaplanuj audyt", url: "/audit/register",disabled:!permission[2]},
        {name: "Wprowadź nowy audyt", url: "/audit/add",disabled:!permission[2]},
        {name: "Zarządzanie audytami",notExact:true, url: "/audit/type",disabled:!permission[1]}
      ]}
    />
    {audit_id ?
      <SideMenu
        title="Wprowadź nowy audyt"
        data={[
          {name: "Dane o audycie", url: `/audit/data/${audit_id}`},
          {name: "Pytania audytowe", url: `/audit/question/${audit_id}`,disabled:(stage < 1)},
          {name: "Działania naprawcze", url: `/audit/task/${audit_id}`,disabled:(stage < 2)},
          {name: "Powiadomienia", url: `/audit/notification/${audit_id}`,disabled:(stage < 3)}
        ]}/>
      : ""}

  </div>
);

export default LSideMenu;