import AppComponent from "../components/AppComponent";
import {getPublicMenuPages} from "../actions/pageActions";

class AppSchema extends AppComponent {
  componentDidMount(){

  }

  loadingPages(){
    getPublicMenuPages()(this.props.dispatch)
      .then(this.handleServerResponse());
  }
}

export default AppSchema;