import React from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import {
  StyledInputMessage,
  StyledLabel,
} from "../../style/styleComponents/Form/input";
const StyledTextEditor = styled((props) => <ReactQuill {...props} />)`
  & .ql-container.ql-snow {
    margin-bottom: 10px;
  }
  & .ql-editor {
    min-height: 250px;
    ${(props) =>
      props.readOnly
        ? `
      background-color: #FAFAFA;
    `
        : null}
  }
`;

export default class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: "" }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const { label, value, helperText, disabled } = this.props;
    return (
      <div>
        <StyledLabel>{label}</StyledLabel>
        <StyledTextEditor
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          readOnly={disabled}
        />
        <StyledInputMessage>{helperText}</StyledInputMessage>
      </div>
    );
  }
}
