import { MONTHS } from "lib/DayPicker/locale_pl.js";

export const termsAfter = [
  { value: "null", name: "Natychmiast" },
  { value: "0", name: "W dniu realizacji" },
  { value: 1, name: "Po 1 dniu" },
].concat(
  Array.apply(null, { length: 181 })
    .map(Number.call, Number)
    .slice(2)
    .map((item) => ({ name: `Po ${item} dniach`, value: item }))
);

export const termNeutral = [
  { value: "null", name: "Natychmiast" },
  { value: "0", name: "W dniu realizacji" },
];

export const termsBefore = [
  { value: "null", name: "Natychmiast" },
  { value: "0", name: "W dniu realizacji" },
  { value: -1, name: "1 dzień przed" },
].concat(
  Array.apply(null, { length: 31 })
    .map(Number.call, Number)
    .slice(2)
    .map((item) => ({ name: `${item} dni przed`, value: -item }))
);

export const termsInNextMonth = Array.apply(null, { length: 32 })
  .map(Number.call, Number)
  .slice(1)
  .map((item) => ({ name: `${item} dzień w miesiącu`, value: item }));

export const termDate = (() => {
  let term1;
  let term2;

  term1 = Array.apply(null, { length: 32 })
    .map(Number.call, Number)
    .slice(1)
    .map((item) => ({ name: `${item}`, value: item }));
  term2 = Array.apply(null, { length: 12 })
    .map(Number.call, Number)
    .map((item) => ({ name: MONTHS[item], value: item + 1 }));
  return [term1, term2];
})();
