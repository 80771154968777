import React, { Fragment } from "react";
import AppComponent, { setMessage } from "./AppComponent";
import { ButtonWrapper } from "./ListComponents";
import { ButtonWithIcon, DeleteButton, DownloadButton } from "./ListComponents";
import DeleteModal from "./Modal/SimpleAccept";
import AddModal from "./Modal/FormModal";
import { Field } from "react-final-form";
import { StyledInputBox } from "../style/styleComponents/Form/input";

import dataToFormData from "./dataToFormData";
import saveBlobFile from "./saveBlobFile";
import { StyledTableList, StyledTableWrapper } from "./ListComponents";
import validator from "./Validation";
import PDFModal, { PDFViewModalSchema } from "./Modal/PDFViewModal";
import ImageModal, { ImageViewModalSchema } from "./Modal/ImageViewModal";
import { SpanAsLink } from "./ListComponents";
import { downloadInsert } from "actions/incidentActions";
export default class AttachmentList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      addModal: {
        open: false,
      },
      pdfModal: { ...PDFViewModalSchema },
      imageModal: { ...ImageViewModalSchema },
      deleteModal: {
        open: false,
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.closeViewModal = this.closeViewModal.bind(this);
  }

  openModal(name, rest = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true, ...rest } });
    };
  }

  openViewModal(modal_name) {
    return (index) => {
      const data = this.props.attachments;
      const { name, fileName, url } = data[index];
      if (/\.(pdf|jpg|png|svg)$/.test(url)) {
        this.setState({
          [modal_name]: {
            ...this.state.pdfModal,
            open: true,
            name: name,
            fileName,
            index,
            simpleUrl: url,
            file: `/api${url}`,
          },
        });
      }
    };
  }

  closeViewModal(modalName) {
    return () => {
      const schema =
        modalName == "pdfModal" ? PDFViewModalSchema : ImageViewModalSchema;
      this.setState({ [modalName]: { ...schema } });
    };
  }

  closeModal(name, rest = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false, ...rest } });
    };
  }

  addAttachment(data) {
    const that = this;
    const {
      dispatch,
      match,
      addAttachmentAction,
      entry_id,
      setParentMessage,
      createView,
      additionalData = {},
    } = this.props;
    if (createView) {
      addAttachmentAction({ ...data, ...additionalData })(dispatch);
    } else {
      const formData = dataToFormData(data);

      addAttachmentAction(
        entry_id,
        formData
      )(dispatch).then(
        this.handleServerResponse({}, (action) => {
          that.closeModal("addModal");
          setParentMessage(setMessage(action.payload.data.message, "success"));
        })
      );
    }
  }

  handleModalClose(modalName) {
    this.setState({ [modalName]: { ...PDFViewModalSchema } });
  }

  download(url, name, index) {
    const { downloadAction } = this.props;
    let downloadA = downloadAction ? downloadAction : () => {};

    return () => {
      if (downloadA)
        downloadA(
          url,
          index
        )(this.props.dispatch).then((res) => {
          if (!res.error) {
            const file = res.payload.data;
            const fileName = `${name}`;
            saveBlobFile(file, fileName);
          }
        });
    };
  }

  deleteAttachment() {
    const { id } = this.state.deleteModal;
    const {
      dispatch,
      match,
      deleteAttachmentAction,
      entry_id,
      createView,
      additionalData,
    } = this.props;
    if (createView) {
      deleteAttachmentAction(entry_id, id, additionalData)(dispatch);
    } else {
      deleteAttachmentAction(
        entry_id,
        id
      )(dispatch).then(this.handleServerResponse());
    }
  }

  render() {
    let { addModal, deleteModal, message, pdfModal, imageModal } = this.state;
    const {
      attachments,
      permission,
      downloadAction,
      attachmentAddFetching,
      readOnly,
    } = this.props;
    return (
      <Fragment>
        <ButtonWrapper>
          <ButtonWithIcon
            disabled={readOnly || attachmentAddFetching}
            icon={
              attachmentAddFetching ? "fa fa-spinner fa-spin" : "fa fa-plus"
            }
            onClick={this.openModal("addModal")}
            name="Dodaj załącznik"
          />
        </ButtonWrapper>
        {attachments.length ? (
          <StyledTableWrapper>
            <StyledTableList quickMenu>
              <thead>
                <tr>
                  <th>L.p.</th>
                  <th>Nazwa</th>
                  <th>Pobierz</th>
                  <th>Usuń</th>
                </tr>
              </thead>
              <tbody>
                {attachments.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {(() => {
                        switch (true) {
                          case /\.pdf$/.test(item.url):
                            return (
                              <SpanAsLink
                                onClick={this.openViewModal("pdfModal").bind(
                                  this,
                                  index
                                )}
                              >
                                {item.name}
                              </SpanAsLink>
                            );
                          case /\.(jpg|png|svg)$/.test(item.url):
                            return (
                              <SpanAsLink
                                onClick={this.openViewModal("imageModal").bind(
                                  this,
                                  index
                                )}
                              >
                                {item.name}
                              </SpanAsLink>
                            );
                          default:
                            return item.name;
                        }
                      })()}
                    </td>
                    <td>
                      <DownloadButton
                        listButton
                        disabled={!downloadAction}
                        fetching={item.fetching}
                        disabled={item.fetching}
                        onClick={this.download(item.url, item.fileName, index)}
                        icon="fa fa-cloud-download"
                        name="pobierz"
                      />
                    </td>
                    <td>
                      <DeleteButton
                        disabled={readOnly}
                        name="Usuń"
                        onClick={this.openModal("deleteModal", {
                          id: item.id,
                        })}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTableList>
          </StyledTableWrapper>
        ) : (
          ""
        )}
        <AddModal
          title="Dodaj załącznik"
          open={addModal.open}
          handleSubmit={this.addAttachment}
          handleClose={this.closeModal("addModal")}
          acceptButtonName="Dodaj"
          cancelButtonName="Anuluj"
          validate={(values) => {
            return {
              name: validator.required(values.name),
              attachment:
                validator.required(values.attachment) ||
                validator.attachment(values.attachment),
            };
          }}
        >
          <Field
            name="name"
            render={({ input, meta, ...rest }) => (
              <StyledInputBox
                label="Nazwa"
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                onChange={(event) => {
                  input.onChange(event.target.value);
                }}
              />
            )}
          />
          <Field
            name="attachment"
            render={({ input, meta, ...rest }) => (
              <StyledInputBox
                label="Dodaj plik"
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                type="file"
                onChange={(event) => {
                  input.onChange(event.target.files[0]);
                }}
              />
            )}
          />
        </AddModal>
        <PDFModal
          open={pdfModal.open}
          handleClose={this.closeViewModal("pdfModal")}
          title={pdfModal.name}
          handleDownload={this.download(
            pdfModal.simpleUrl,
            pdfModal.fileName,
            pdfModal.index
          )}
          file={pdfModal.file}
        />
        <ImageModal
          open={imageModal.open}
          handleClose={this.closeViewModal("imageModal")}
          title={imageModal.name}
          handleDownload={this.download(
            imageModal.simpleUrl,
            imageModal.fileName,
            imageModal.index
          )}
          file={imageModal.file}
        />
        <DeleteModal
          title={"Usuń załącznik"}
          description={"Czy na pewno chcesz usunąć załącznik?"}
          open={deleteModal.open}
          handleAccept={this.deleteAttachment}
          handleClose={this.closeModal("deleteModal", { id: null })}
        />
      </Fragment>
    );
  }
}
