import React from "react";
import Button from "react-bootstrap/lib/Button";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldDayPicker, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";

let checkboxes = [
  {
    name: "so_po_send_to_gus",
    label: "I część karty Z-KW zostało wysłana do GUS",
  },
  {
    name: "so_pt_send_to_gus",
    label: "II część karty Z-KW zostało wysłana do GUS",
  },
];

export default class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  openModal() {
    this.setState({ open: true });
  }

  render() {
    let { data, updateGus, readOnly } = this.props;
    return (
      <>
        <Button disabled={readOnly} onClick={this.openModal}>
          GUS
        </Button>
        <FormModal
          initialValues={data}
          open={this.state.open}
          title="Wysłane karty do GUS"
          acceptButtonName="Potwierdź"
          cancelButtonName="Anuluj"
          handleSubmit={(values) => {
            updateGus(values);
          }}
          handleClose={this.closeModal}
        >
          {checkboxes.map((item) => {
            return <FieldInputCheckBox name={item.name} label={item.label} />;
          })}
        </FormModal>
      </>
    );
  }
}
