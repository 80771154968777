import React from "react";
import { connect } from "react-redux";

import { getQETypeList, deleteQEType } from "actions/QEActions";

import AppComponent from "components/AppComponent";
import List from "components/QE/ListType";

import SideMenu from "./_SideMenu";
import { setUrls } from "./_staticData";

class ListType extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };

    this.deleteQEType = this.deleteQEType.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;
    getQETypeList({ readOnly: true })(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    this.updateMessage();
  }

  deleteQEType(id) {
    let { dispatch } = this.props;

    deleteQEType(id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { urls, message } = this.state;
    let { data, permission } = this.props;

    return (
      <List
        permission={permission}
        message={message}
        urls={urls}
        SideMenu={SideMenu}
        data={data}
        deleteQEType={this.deleteQEType}
      />
    );
  }
}

export default connect((store) => ({
  message: store.message,
  data: store.qe.types,
  permission: store.qe.permission,
}))(ListType);
