import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import AppComponent from "components/AppComponent";
import {
  FieldInput,
  FieldDayPicker,
  FieldSelect,
  FieldInput33,
  FieldTextareaBox,
} from "components/Fields";
import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import { LinkContainer } from "components/LinkContainer";
import {
  ButtonWrapper,
  LeftPadding,
  TwoInputWrapper,
} from "components/Wrappers";

export default class Referral extends AppComponent {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(data) {
    let { save } = this.props;
    save(data);
  }

  render() {
    let {
      SideMenu,
      print,
      archived,
      data,
      workplaces = [],
      localizations = [],
      sections = [],
      getSections = () => {},
      message,
      readOnly = false,
    } = this.props;

    return (
      <>
        <LayoutContent
          mobileNotHideMenu={true}
          SideMenu={<SideMenu />}
          error404={this.state.error_code == 404}
          message={message}
        >
          <Box title={"Dodaj wzór skierowania na badania"}>
            <Form
              onSubmit={this.submit}
              initialValues={data}
              render={({
                handleSubmit,
                pristine,
                invalid,
                values = {},
                form,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TwoInputWrapper>
                      <FieldSelect
                        disabled={readOnly}
                        name="workplace_id"
                        label={"Stanowisko pracy"}
                        data={workplaces}
                      />
                    </TwoInputWrapper>
                    <TwoInputWrapper>
                      <FieldSelect
                        disabled={readOnly}
                        name="localization_id"
                        label={"Lokacja"}
                        data={localizations}
                      />
                    </TwoInputWrapper>
                    <OnChange name="localization_id">
                      {(value) => {
                        getSections(value);
                      }}
                    </OnChange>
                    <TwoInputWrapper>
                      <FieldSelect
                        disabled={readOnly}
                        name="section_id"
                        label={"Dział"}
                        data={sections}
                      />
                    </TwoInputWrapper>
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="workplace_description"
                      label={
                        "Określenie stanowiska/stanowisk pracy (opisać: rodzaj pracy, podstawowe czynności, sposób i czas ich wykonywania)"
                      }
                    />

                    <h5>Warunki pracy (nazwa i wielkośc narażenia)</h5>
                    <LeftPadding>
                      <FieldTextareaBox
                        disabled={readOnly}
                        name="wc_physical"
                        label={"Czynniki fizyczne"}
                      />
                      <FieldTextareaBox
                        name="wc_powder"
                        disabled={readOnly}
                        label={"Pyły"}
                      />
                      <FieldTextareaBox
                        disabled={readOnly}
                        name="wc_chemical"
                        label={"Czynniki chemiczne"}
                      />
                      <FieldTextareaBox
                        disabled={readOnly}
                        name="wc_biological"
                        label={"Czynniki biologiczne"}
                      />
                      <FieldTextareaBox
                        disabled={readOnly}
                        name="wc_other"
                        label={"Inne czynniki, w tym niebezpieczne"}
                      />
                    </LeftPadding>
                    <FieldInput33
                      disabled={readOnly}
                      name="factor_counts"
                      label={
                        " Łączna liczba czynników niebezpiecznych, szkodliwych, uciążliwych i innych"
                      }
                    />
                    <ButtonWrapper>
                      <LinkContainer
                        exact
                        to="/medical-examination/referral-list"
                      >
                        <Button>Anuluj</Button>
                      </LinkContainer>

                      {print && (
                        <LinkContainer
                          exact
                          to="/medical-examination/referral-list"
                        >
                          <Button onClick={print}>Wygeneruj PDF</Button>{" "}
                        </LinkContainer>
                      )}
                      <Button type="submit" disabled={readOnly}>
                        Zapisz
                      </Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Box>
        </LayoutContent>
      </>
    );
  }
}
