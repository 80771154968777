import React, { Fragment } from "react";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Button from "react-bootstrap/lib/Button";

import styled from "styled-components";

import { ButtonWrapper } from "components/ListComponents";
import validator, { composeValidators } from "components/Validation/";
import { StyledLabel, StyledInputBox } from "style/styleComponents/Form/input";

let InputWrapper = styled.div`
  display: flex;
  margin: 0 -10px 10px;

  & > * {
    margin: 0 10px;
  }
`;

export default class WitnessComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.addWitness = this.addWitness.bind(this);
    this.removeIncidentWitness = this.removeIncidentWitness.bind(this);
  }

  addWitness(fields) {
    fields.push({});
  }

  removeIncidentWitness(fields, index) {
    fields.remove(index);
  }

  render() {
    let { readOnly = false, incident_id, incident, permission } = this.props;
    return (
      <Fragment>
        <StyledLabel>Świadkowe</StyledLabel>
        <Form
          onSubmit={() => {}}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, pristine, invalid, form, values }) => {
            return (
              <form>
                <FieldArray name="incident_witnesses">
                  {({ fields }) => (
                    <div>
                      {fields.map((name, index) => (
                        <InputWrapper>
                          <Field
                            name={`${name}.full_name`}
                            validate={validator.required}
                            type="text"
                            render={({ input, meta, ...rest }) => (
                              <StyledInputBox
                                disabled={readOnly}
                                placeholder={"Imię i nazwisko świadka"}
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : null
                                }
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                value={input.value}
                                onChange={(event) => {
                                  input.onChange(event);
                                }}
                              />
                            )}
                          />
                          <Button
                            onClick={this.removeIncidentWitness(fields, index)}
                          >
                            <i className={"fa fa-trash"} />
                          </Button>
                        </InputWrapper>
                      ))}

                      <div>
                        <ButtonWrapper>
                          <Button
                            type="button"
                            onClick={this.addWitness(fields)}
                          >
                            Dodaj świadka
                          </Button>
                        </ButtonWrapper>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </form>
            );
          }}
        />
      </Fragment>
    );
  }
}
