import React from "react";
import { PieChart, Pie, Sector, Cell,Legend,Bar} from "recharts";
// const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
//   {name: 'Group C', value: 300}, {name: 'Group D', value: 200}];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} 	dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class SimplePieChart extends React.Component{
  render () {
    const {data =[]} = this.props;
    return (
      <PieChart width={300} height={300} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          startAngle={90}
          endAngle={-270}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={index} fill={entry.fill}/>)
          }
          <Legend>
            <Bar dataKey={"aaaa"} fill={"red"}></Bar>
          </Legend>

        </Pie>
      </PieChart>
    );
  }
}

export default SimplePieChart;

