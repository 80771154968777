const defaultState = {
  sortMode: 0,
  data: [],
  typeData: [],
  singleData: {},
  typeSingleData: {},
  questionsData: [],
  newQuestionsData: [],
  answerData: [],
  permission: {},
  fetching: false,
  fetched: true,
  attachmentFetching: false,
  printFetching: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "COMMENT_OPENNING": {
      let { custom, index, subindex } = action.payload;
      let key = custom ? "newQuestionsData" : "questionsData";
      let { questionsData, newQuestionsData } = { ...state };
      if (custom) {
        newQuestionsData[index].comment_open = !newQuestionsData[index]
          .comment_open;
      } else {
        if (Number.isInteger(subindex)) {
          let newFlag = !questionsData[index].questions[subindex].comment_open;
          questionsData[index].questions[subindex].comment_open = newFlag;
          if (!newFlag) {
            // questionsData[index].questions[subindex].comment = "null";
          }
        } else {
          questionsData[index].comment_open = !questionsData[index]
            .comment_open;
        }
      }
      return { ...state, newQuestionsData, questionsData };
    }
    case "GET_AUDIT_TYPES_REQUEST": {
      return { ...state, fetching: true };
    }
    case "GET_AUDIT_TYPES_SUCCESS": {
      const { auditTypes } = action.payload.data;
      let typeData = auditTypes.map((item, i) => {
        return { ...item, index: i + 1 };
      });

      return { ...state, fetching: false, fetched: true, typeData };
    }
    case "GET_AUDIT_TYPES_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_AUDIT_TYPE_REQUEST": {
      return { ...state, fetching: true };
    }
    case "GET_AUDIT_TYPE_SUCCESS": {
      const { auditType, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        typeSingleData: auditType,
        permission,
      };
    }
    case "GET_AUDIT_TYPE_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "DELETE_AUDIT_TYPE_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        typeData: state.typeData.filter(
          (item) => item.id != action.payload.data.auditType.id
        ),
      };
    }
    case "GET_AUDITS_REQUEST": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_AUDITS_SUCCESS": {
      const { audits, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: audits,
        permission,
      };
    }
    case "GET_AUDITS_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_AUDIT_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_AUDIT_SUCCESS": {
      const { audit, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: audit,
        permission: permission,
      };
    }
    case "GET_AUDIT_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_AUDIT_QUESTIONS_REQUEST": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_AUDIT_QUESTIONS_SUCCESS": {
      const {
        questions = [],
        answers = [],
        permission = {},
      } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        questionsData: questions.map((item) => {
          return {
            ...item,
            custom_open: false,
            questions:
              item.questions &&
              item.questions.map((subitem) => {
                return {
                  ...subitem,
                  comment_open:
                    subitem.comment && subitem.comment.length > 0
                      ? true
                      : false,
                };
              }),
            comment_open:
              item.comment && item.comment.length > 0 ? true : false,
          };
        }),
        answerData: answers,
        permission,
      };
    }
    case "GET_AUDIT_QUESTIONS_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_AUDIT_QUESTIONS_TASK_REQUEST": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_AUDIT_QUESTIONS_TASK_SUCCESS": {
      const {
        questions,
        answers = [],
        auditPermission = {},
      } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        questionsData: questions,
        answerData: answers,
        permission: auditPermission,
      };
    }
    case "GET_AUDIT_QUESTIONS_TASK_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }

    case "ADD_INCIDENT_REQUEST": {
      return { ...state };
    }
    case "ADD_INCIDENT_SUCCESS": {
      return { ...state };
    }
    case "ADD_INCIDENT_ERROR": {
      return { ...state };
    }
    case "PING_AUDIT_SUCCESS": {
      return { ...state, permission: action.payload.data.permission };
    }
    case "DELETE_AUDIT_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data.filter(
          (item) => item.id != action.payload.data.audit.id
        ),
      };
    }
    case "ADD_AUDIT_NEW_QUESTION_LOCAL": {
      let newNewQuestionsData = [...state.newQuestionsData];
      newNewQuestionsData.push({
        attachments: [],
        dictionary_audit_question_answer_id: null,
        id: null,
        name: null,
        tasks: null,
        comment_open: false,
        comment: null,
      });
      return { ...state, newQuestionsData: newNewQuestionsData };
    }
    case "ADD_AUDIT_QUESTION_ATTACHMENT_REQUEST": {
      const formData = action.payload.request.data;
      const audit_question_id = formData.get("audit_question_id");

      const questions = state.questionsData;
      const findQuestion = (item) => item.id == audit_question_id;
      const index = questions.findIndex(findQuestion);
      let newQuestions = [...questions];
      if (index > -1) {
        newQuestions[index].fetching = true;
      } else {
        newQuestions = questions.map((item) => {
          if (item.questions) {
            const index2 = item.questions.findIndex(findQuestion);
            if (index2 > -1) {
              item.questions[index2].fetching = true;
            }
          }
          return item;
        });
      }
      return {
        ...state,
        questionsData: newQuestions,
        attachmentFetching: true,
      };
    }
    case "ADD_AUDIT_QUESTION_ATTACHMENT_SUCCESS": {
      const { attachment } = action.payload.data;
      const { id, audit_question_id } = attachment;
      const questions = state.questionsData;
      const findQuestion = (item) => item.id == audit_question_id;
      const index = questions.findIndex(findQuestion);
      let newQuestions = [...questions];

      if (index > -1) {
        newQuestions[index].attachments = newQuestions[index].attachments || [];
        newQuestions[index].attachments.push(attachment);
        newQuestions[index].fetching = false;
      } else {
        newQuestions = questions.map((item) => {
          if (item.questions) {
            const index2 = item.questions.findIndex(findQuestion);
            if (index2 > -1) {
              item.questions[index2].attachments =
                item.questions[index2].attachments || [];
              item.questions[index2].attachments.push(attachment);
              item.questions[index2].fetching = false;
            }
          }
          return item;
        });
      }
      return {
        ...state,
        questionsData: newQuestions,
        attachmentFetching: false,
      };
    }
    case "ADD_AUDIT_QUESTION_ATTACHMENT_ERROR": {
      return { ...state, attachmentFetching: false };
    }
    case "ADD_AUDIT_QUESTION_ATTACHMENT_LOCAL": {
      const { index, file } = action.payload;

      let fileFulldata = {
        id: index,
        file: file.name,
        fileData: file,
      };

      state.newQuestionsData[index].attachments.push(fileFulldata);
      return { ...state };
    }
    case "DELETE_AUDIT_QUESTION_ATTACHMENT_LOCAL": {
      const { questionIndex, attachmentIndex } = action.payload;
      state.newQuestionsData[questionIndex].attachments.splice(
        attachmentIndex,
        1
      );
      return { ...state };
    }
    case "DELETE_AUDIT_QUESTION_ATTACHMENT_SUCCESS": {
      const { attachment } = action.payload.data;
      const { id, audit_question_id } = attachment;
      const questions = state.questionsData;
      const findQuestion = (item) => item.id == audit_question_id;
      const index = questions.findIndex(findQuestion);
      let newQuestions = [...questions];
      if (index > -1) {
        newQuestions[index].attachments = newQuestions[index].attachments || [];
        newQuestions[index].attachments = newQuestions[
          index
        ].attachments.filter((attachment) => attachment.id != id);
      } else {
        newQuestions = questions.map((item) => {
          if (item.questions) {
            const index2 = item.questions.findIndex(findQuestion);
            if (index2 > -1) {
              item.questions[index2].attachments =
                item.questions[index2].attachments || [];
              item.questions[index2].attachments = item.questions[
                index2
              ].attachments.filter((attachment) => attachment.id != id);
            }
          }
          return item;
        });
      }
      return {
        ...state,
        questionsData: newQuestions,
        attachmentFetching: false,
      };
    }
    case "DELETE_AUDIT_CUSTOM_QUESTION_LOCAL": {
      let index = action.payload.index;
      let newQuestionsData = state.newQuestionsData;
      let newData = newQuestionsData.splice(index, 1);
      return { ...state, newQuestionsData };
    }
    case "DOWNLOAD_AUDIT_QUESTION_ATTACHMENT_REQUEST": {
      const { index, subIndex, attachmentIndex } = action.payload.request.data;
      const newQuestionsData = [...state.questionsData];
      if (!Number.isInteger(subIndex)) {
        newQuestionsData[index].attachments[attachmentIndex].fetching = true;
      } else {
        newQuestionsData[index].questions[subIndex].attachments[
          attachmentIndex
        ].fetching = true;
      }

      return { ...state, questionsData: newQuestionsData };
    }

    case "DOWNLOAD_AUDIT_QUESTION_ATTACHMENT_SUCCESS": {
      const {
        index,
        subIndex,
        attachmentIndex,
      } = action.meta.previousAction.payload.request.data;
      const newQuestionsData = [...state.questionsData];
      if (!Number.isInteger(subIndex)) {
        newQuestionsData[index].attachments[attachmentIndex].fetching = false;
      } else {
        newQuestionsData[index].questions[subIndex].attachments[
          attachmentIndex
        ].fetching = false;
      }

      return { ...state, questionsData: newQuestionsData };
    }

    case "DOWNLOAD_AUDIT_QUESTION_ATTACHMENT_ERROR": {
      const {
        index,
        subIndex,
        attachmentIndex,
      } = action.meta.previousAction.payload.request.data;
      const newQuestionsData = [...state.questionsData];
      if (!subIndex) {
        newQuestionsData[index].attachments[attachmentIndex].fetching = false;
      } else {
        newQuestionsData[index].questionsData[subIndex].attachments[
          attachmentIndex
        ].fetching = false;
      }

      return { ...state, questionsData: newQuestionsData };
    }

    case "DOWNLOAD_COMPLETE_AUDIT_REQUEST": {
      const { index } = action.payload.request.data;
      const newData = [...state.data];
      newData[index].printFetching = true;
      return { ...state, data: newData };
    }
    case "DOWNLOAD_COMPLETE_AUDIT_SUCCESS": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newData = [...state.data];
      newData[index].printFetching = false;
      return { ...state, data: newData };
    }
    case "DOWNLOAD_COMPLETE_AUDIT_ERROR": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newData = [...state.data];
      newData[index].printFetching = false;
      return { ...state, data: newData };
    }

    case "DOWNLOAD_AUDIT_TYPE_REQUEST": {
      const { index } = action.payload.request.data;
      const newTypeData = [...state.typeData];
      newTypeData[index].printFetching = true;
      return { ...state, typeData: newTypeData };
    }
    case "DOWNLOAD_AUDIT_TYPE_SUCCESS": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newTypeData = [...state.typeData];
      newTypeData[index].printFetching = false;
      return { ...state, typeData: newTypeData };
    }
    case "DOWNLOAD_AUDIT_TYPE_ERROR": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newTypeData = [...state.typeData];
      newTypeData[index].printFetching = false;
      return { ...state, typeData: newTypeData };
    }
    case "DELETE_AUDIT_TASK_SUCCESS": {
      const {
        questionIndex,
        subQuestionIndex,
      } = action.meta.previousAction.payload.request.data;
      const { id: task_id } = action.payload.data.task;
      const newQuestionsData = state.questionsData;
      if (subQuestionIndex != null) {
        newQuestionsData[questionIndex].questions[
          subQuestionIndex
        ].tasks = newQuestionsData[questionIndex].questions[
          subQuestionIndex
        ].tasks.filter((item) => {
          return item.id != task_id;
        });
      } else {
        newQuestionsData[questionIndex].tasks = newQuestionsData[
          questionIndex
        ].tasks.filter((item) => {
          return item.id != task_id;
        });
      }

      return { ...state, questionsData: newQuestionsData };
    }
    case "SAVE_AUDIT_QUESTIONS_SUCCESS": {
      const { stage } = action.payload.data;

      const newSingleData = { ...stage.singleData };
      newSingleData.stage = stage;

      return { ...state, singleData: newSingleData };
    }
    case "REGISTER_AUDIT_SUCCESS": {
      const { audit } = action.payload.data;
      return { ...state, singleData: audit };
    }
    case "RESET_NEW_QUESTION_LOCAL": {
      return { ...state, newQuestionsData: [] };
    }
    case "GET_AUDIT_DEFAULT_PERMISSION_SUCCESS": {
      const { permission = {} } = action.payload.data;
      return { ...state, permission };
    }
    case "CLEAR_AUDIT": {
      return defaultState;
    }
  }

  return state;
}
