import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import AuditTypeForm from "../../components/AuditTypes.Form";
import { getAuditType, editAuditType } from "../../actions/auditActions";
import SideMenu from "./_SideMenu";
import Model from "../../components/Modal/SimpleAccept";

class EditAuditTypes extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      values: {},
      modal: {
        open: false,
      },
    };
    this.submit = this.submit.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.editAudit = this.editAudit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { audit_type_id } = match.params;
    getAuditType(audit_type_id, undefined, { readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  submit(values) {
    const { dispatch, match, history } = this.props;
    const { audit_type_id } = match.params;
    const handleOpenModal = this.handleOpenModal;
    this.setState({ values: values }, () => {
      handleOpenModal();
    });
  }

  editAudit() {
    const { dispatch, match, history } = this.props;
    const { audit_type_id } = match.params;
    const values = this.state.values;

    editAuditType(
      audit_type_id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/audit/type");
      })
    );
  }

  handleOpenModal() {
    this.setState({ modal: { ...this.state.modal, open: true } });
  }

  handleCloseModal() {
    this.setState({ modal: { ...this.state.modal, open: false } });
  }

  render() {
    const {
      audit: { typeSingleData, permission },
    } = this.props;
    const { message, modal } = this.state;
    const readOnly =
      Boolean(typeSingleData.business_id === 0) || !permission[15];
    
    return (
      <div>
        <AuditTypeForm
          edit
          error404={this.state.error_code == 404}
          SideMenu={<SideMenu permission={permission} />}
          permission={permission}
          message={message}
          data={typeSingleData}
          readOnly={readOnly}
          handleSubmit={this.submit}
        />
        <Model
          title="Czy zmienić treść pytań audytowych?"
          open={modal.open}
          handleAccept={this.editAudit}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
}))(EditAuditTypes);
