import React from "react";
import Link from "react-router-dom/Link";
import moment from "moment";
import AppComponent from "components/AppComponent";
import Button from "react-bootstrap/lib/Button";
import Box from "components/Box";
import { ButtonWrapper } from "components/Wrappers";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { TableListPage } from "components/Tables";

import AddTaskModal from "./AddTaskModal";

let getDate = (date) => {
  if (!date) {
    return null;
  }
  return moment(date).format("YYYY-MM-DD");
};
export default class Add extends AppComponent {
  render() {
    let { message } = this.state;
    let {
      id,
      draft,
      SideMenu,
      hazards,
      addRAAHTMEntry,
      data = [],
      stage,
      urls = {},
      permission,
      readOnly,
      setNextStage,
      printRegister,
    } = this.props;

    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={SideMenu}
      >
        <Box title={"Dodaj nową ocenę ryzyka zawodowego - Działania naprawcze"}>
          <ButtonWrapper>
            <AddTaskModal
              readOnly={readOnly}
              hazards={hazards}
              data={data}
              addRAAHTMEntry={addRAAHTMEntry}
            />
            <Button onClick={printRegister}>
              Drukuj plan działań ograniczjących ryzyka
            </Button>
          </ButtonWrapper>

          <TableListPage>
            <thead>
              <th>Zagrożenia</th>
              <th>Symbol rejestrowy działań</th>
              <th>Informacje szczegółowe</th>
              <th>Termin zakończenia</th>
              <th>Osoba odpowiedzialne</th>
            </thead>
            <tbody>
              {data.map((item, index) => {
                let { name, tasks, id: ra_ah_tme_id } = item;
                if (tasks && tasks.length) {
                  return tasks.map((subitem, subIndex) => {
                    return (
                      <tr
                        style={{
                          background: index % 2 != 0 ? "#f9f9f9" : "#fff",
                        }}
                      >
                        {subIndex == 0 && (
                          <td
                            style={{ height: "100px" }}
                            rowspan={tasks.length}
                          >
                            <div>{name}</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <LinkContainer
                                to={urls.TASKENTRY(id, ra_ah_tme_id)}
                              >
                                <Button>Dodaj działanie</Button>
                              </LinkContainer>{" "}
                            </div>
                          </td>
                        )}
                        <td>
                          <Link
                            to={urls.TASKENTRY(id, ra_ah_tme_id, subitem.id)}
                          >
                            {subitem.symbol}
                          </Link>
                        </td>
                        <td>{subitem.title}</td>
                        <td>{getDate(subitem.date_end)}</td>
                        <td>{subitem.user_name}</td>
                      </tr>
                    );
                  });
                } else {
                  return (
                    <tr
                      style={{
                        background: index % 2 != 0 ? "#f9f9f9" : "#fff",
                      }}
                    >
                      <td style={{ height: "100px" }}>
                        <div>{name}</div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <LinkContainer to={urls.TASKENTRY(id, ra_ah_tme_id)}>
                            <Button>Dodaj działanie</Button>
                          </LinkContainer>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </TableListPage>
          <ButtonWrapper>
            {!draft && stage <= 4 ? (
              <Button onClick={setNextStage}>Dalej</Button>
            ) : (
              <LinkContainer to={urls.NOTIFICATION(id)}>
                <Button>Dalej</Button>
              </LinkContainer>
            )}
          </ButtonWrapper>
        </Box>
      </LayoutContent>
    );
  }
}
