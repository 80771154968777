import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

// import { ButtonWrapper } from "./ListComponents";

import { printListPDF } from "actions/accidentOWTWActions";
import saveBlobFile from "components/saveBlobFile";

import {
  LeftPadding,
  TwoInputWrapper,
  ButtonWrapper,
  Center,
} from "components/Wrappers";

import {
  FieldInputCheckBox,
  FieldDayPicker,
  FieldSelect,
} from "components/Fields";

import { ButtonWithIcon } from "components/Buttons";
import { LinkContainer } from "components/LinkContainer";
import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";
import { TableListPage } from "components/Tables";

import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";

import { media } from "style/style-utils/index";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let tdCenter = styled.td`
  text-align: center;
  vertical-align: middle;
`;

let checkboxes = [
  {
    name: "register",
    prop: "register_pdf",
    label: "Zawiadomienie o wypadku w drodze",
    value: "register.pdf",
  },
  {
    name: "accident_card",
    prop: "accident_card_pdf",
    label: "Karta wypadku w drodze",
    value: "accident_card.pdf",
  },
  {
    name: "victim_explain",
    prop: "victim_explain_pdf",
    label: "Oświadczenie poszkodowanego",
    value: "victim_explain.pdf",
  },
];

let dictionary = {
  register: {
    dataprop: "register_pdf",
    filename: "Zawiadomienie o wypadku w drodze",
  },
  accident_card: {
    dataprop: "accident_card_pdf",
    filename: "Karta wypadku w drodze",
  },
  victim_explain: {
    dataprop: "victim_explain_pdf",
    filename: "Oświadczenie poszkodowanego",
  },
};
class PrintModalComponent extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
      checkboxes: [],
      dictionary,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let { data = {} } = this.props;
    let { witnesses } = data;
    witnesses = witnesses || [];
    let witnessCheckboxes = witnesses.map((item, index) => {
      return {
        name: `witness_${item.id}`,
        label: `Informacje świadka ${index + 1}`,
        prop: "register_pdf",
        index,
      };
    });

    this.setState({
      checkboxes: checkboxes
        .filter((item) => {
          return true;
        })
        .concat(witnessCheckboxes),
      dictionary: {
        ...dictionary,
        ...witnessCheckboxes.reduce((obj, item) => {
          obj[item.name] = {
            dataprop: "pdf",
            filename: item.label,
            index: item.index,
          };
          return obj;
        }, {}),
      },
    });
  }

  downloadpdfs(values) {
    let { data, dispatch } = this.props;
    let { dictionary } = this.state;
    let incident_id = data.id;
    let keys = Object.keys(values);
    let doc;
    for (let key of keys) {
      let { dataprop, filename, index } = dictionary[key];
      if (/^witness_/.test(key)) {
        doc = `${key}.pdf`;
      } else {
        const checkbox = checkboxes.find((item) => {
          return item.prop == dataprop;
        });
        doc = checkbox.value;
      }

      printListPDF(incident_id, { filename: doc })(dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `${filename}.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    }
  }

  componentDidUpdate() {
    let { data = {} } = this.props;
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Drukuj</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={(values) => {
                this.downloadpdfs(values);
              }}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TableListPage>
                      <thead>
                        <th>Dokument</th>
                        <th>Drukuj</th>
                      </thead>
                      <tbody>
                        {this.state.checkboxes.map((item) => {
                          return (
                            <tr>
                              <td>{item.label}</td>

                              <td style={{ textAlign: "center" }}>
                                <FieldInputCheckBox name={item.name} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </TableListPage>
                    <ButtonWrapper>
                      <Button type="submit">Pobierz pliki</Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class PrintModalContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  openModal() {
    this.setState({ open: true });
  }

  render() {
    let { open, closeModal, dispatch, data, permission } = this.props;

    return (
      <>
        <ButtonWithIcon
          disabled={!permission[19]}
          icon="fa fa-file-pdf-o"
          onClick={this.openModal}
        />
        <PrintModalComponent
          dispatch={dispatch}
          data={data}
          open={this.state.open}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}
