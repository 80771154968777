const defaultState = {
  data: [],
  pageMenu:[
    {name: "", url: ""},
  ],
  singleData:{},
  fetching: false,
  fetched: true
};

export default function reducer(state = defaultState , action) {

  switch (action.type) {
    case "GET_PAGES_REQUEST":{
      return {...state,fetching:true,fetched:false};
    }
    case "GET_PAGES_SUCCESS":{
      return {...state,fetching:false,fetched:true,data:action.payload.data.pages};
    }
    case "GET_PAGES_ERROR":{
      return {...state,fetching:false,fetched:true};
    }
    case "GET_PAGE_REQUEST":{
      return {...state,fetching:true,fetched:false};
    }
    case "GET_MENU_PAGES_SUCCESS":{
      return {...state,fetching:false,fetched:true,pageMenu:action.payload.data.pages};
    }
    case "GET_MENU_PAGES_ERROR":{
      return {...state,fetching:false,fetched:true};
    }
    case "GET_MENU_PAGE_REQUEST":{
      return {...state,fetching:true,fetched:false};
    }
    case "GET_PAGE_SUCCESS":{
      return {...state,fetching:false,fetched:true,singleData:action.payload.data.page};
    }
    case "GET_PAGE_ERROR":{
      return {...state,fetching:false,fetched:true};
    }
    case "DELETE_PAGE_SUCCESS":{
      return {...state,data:action.payload.data.pages};
    }
    case "CLEAR_PAGE":{
      return defaultState;
    }
    case "GET_PAGES_MENU_REQUEST":{
      return {...state,menuFetching:true,menuFetched:false};
    }
    case "GET_PAGES_MENU_SUCCESS":{
      const {pages} = action.payload.data;
      return {...state,menuFetching:false,menuFetched:true,pageMenu:pages};
    }
    case "GET_PAGES_MENU_ERROR":{
      return {...state,menuFetching:false,menuFetched:false};
    }
  }

  return state;
}