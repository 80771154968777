export function login(data){
  return dispatch => dispatch({
    types: ["LOGIN_REQUEST","LOGIN_SUCCESS","LOGIN_ERROR"],
    payload:{
      request: {
        url: "/login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function businessLogin(data){
  return dispatch => dispatch({
    types: ["LOGIN_REQUEST","LOGIN_SUCCESS","LOGIN_ERROR"],
    payload:{
      request: {
        url: "/business/login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function logout(data){
  return dispatch => dispatch({
    types: ["LOGOUT_REQUEST","LOGOUT_SUCCESS","LOGOUT_ERROR"],
    payload:{
      request: {
        url: "/business/logout",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function sendPanelResetRequest(data){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_USER_REQUEST","RESET_PASSWORD_USER_SUCCESS","RESET_PASSWORD_USER_ERROR"],
    payload:{
      request: {
        url: "/business/reset-password",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function sendPanelNewPassword(token,data){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_CONFIRM_USER_REQUEST","RESET_PASSWORD_CONFIRM_USER_SUCCESS","RESET_PASSWORD_CONFIRM_USER_ERROR"],
    payload:{
      request: {
        url: "/reset-password/"+token,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function checkResetPasswordToken(token){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_REQUEST","RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_SUCCESS","RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/business/reset-password/"+token,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function sendNewPassword(token,data){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_CONFIRM_USER_REQUEST","RESET_PASSWORD_CONFIRM_USER_SUCCESS","RESET_PASSWORD_CONFIRM_USER_ERROR"],
    payload:{
      request: {
        url: "/business/reset-password/"+token,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}