import React from "react";
import SideMenu from "../../components/SideMenu";

const LSideMenu = ({
  accident_id,
  stage,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <div>
    <SideMenu
      title="B / S / U"
      data={[
        { name: "Badania profilaktyczne", url: "/medical-examination" },
        { name: "Szkolenia BHP", url: "/osh" },
        { name: "Uprawnienia", url: "/qualification-entitlements" },
        { name: "Formularze", url: "/forms" },
      ]}
    />
  </div>
);

export default LSideMenu;
