import React, { Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Button from "react-bootstrap/lib/Button";
import { getIncidentWitness } from "actions/incidentActions";

import ModalWitnessForm from "./Modal/witnessForm";
import ModalWitnessList from "./Modal/witnessList";

let Title = styled.h4`
  margin-bottom: 10px;
`;

class WitnessComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalWitness: {
        open: false,
      },
      modalWitnessList: {
        open: false,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let { incident_id, dispatch } = this.props;
    getIncidentWitness({ incident_id })(dispatch);
  }

  openModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true } });
    };
  }

  closeModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false } });
    };
  }

  render() {
    let {
      incident_id,
      incident: { incident_witnesses },
    } = this.props;
    return (
      <Fragment>
        <Title>Informacje uzyskane od świadka wypadku</Title>
        <div style={{ marginBottom: "30px" }}>
          <div>
            <Button onClick={this.openModal("modalWitnessList")}>
              Dodaj informacje uzyskane od świadka wypadku
            </Button>
          </div>
          <ModalWitnessList
            data={incident_witnesses}
            {...this.state.modalWitnessList}
            incident_id={incident_id}
            switchModal={(index) => {
              this.setState({
                modalWitness: {
                  ...this.state.modalWitness,
                  open: true,
                  data: incident_witnesses[index],
                  index: index,
                },
              });
              this.openModal("modalWitness");
            }}
            closeModal={this.closeModal("modalWitnessList")}
          />
          <ModalWitnessForm
            incident_id={incident_id}
            {...this.state.modalWitness}
            closeModal={() => {
              this.setState({
                modalWitnessList: {
                  ...this.state.modalWitnessList,
                  open: true,
                },
                modalWitness: { ...this.state.modalWitness, open: false },
              });
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
}))(WitnessComponent);
