const defaultState = {
  data: [],
  singleData: {},
  fetching: false,
  fetched: true,
  types: [],
  type: {},
  permission: {},
};
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "GET_QE_TYPE_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, types: data, permission };
    }
    case "GET_QE_TYPE_ITEM_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, type: data, permission };
    }

    case "DELETE_QE_TYPE_ITEM_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        types: state.types.filter(
          (item) => item.id != action.payload.data.data.id
        ),
      };
    }
    case "DELETE_QE_TYPE_ITEM_SUCCESS": {
      const { data } = action.payload.data;
      let newData = [...state.types].filter((i) => i.id != data.id);

      return { ...state, types: newData };
    }

    case "GET_QE_LIST_SUCCESS": {
      const { data } = action.payload.data;
      return { ...state, data: data || [] };
    }
    case "GET_QE_SINGLE_SUCCESS": {
      const { data } = action.payload.data;
      return { ...state, singleData: data };
    }
  }

  return state;
}
