import React, { Fragment } from "react";
import { connect } from "react-redux";
import AppComponent from "components/AppComponent";
import RiskAssessmentForm from "components/RiskAssessment/RiskAssessment.Form";

import {
  addDraftRAMethod,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";
import { v4 as uuidv4 } from "uuid";
import { setUrls } from "./_staticData";
import SideMenu from "../_SideMenu";

class AuditAddTypes extends AppComponent {
  constructor(props) {
    super(props);

    let { urls, names = {} } = this.props;

    this.state = { ...this.state, urls: setUrls(urls) };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    let { urls } = this.state;
    let { dispatch, history } = this.props;
    addDraftRAMethod(values)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.LIST);
      })
    );
  }

  render() {
    const { message, urls } = this.state;
    return (
      <Fragment>
        <RiskAssessmentForm
          fetched={true}
          SideMenu={<SideMenu />}
          // data={initialData}
          message={message}
          urls={urls}
          handleSubmit={this.handleSubmit}
        />
      </Fragment>
    );
  }
  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(AuditAddTypes);
