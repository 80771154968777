import React from "react";
import Button from "react-bootstrap/lib/Button";

import { deleteMEReferralByDates } from "actions/meActions";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldDayPicker, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";

let checkboxes = [
  {
    name: "a",
    label: "wypadki przy pracy",
  },
  {
    name: "b",
    label: "zdarzenia potencjalnie wypadkowe",
  },
];

export default class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.submit = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  submit(data) {
    alert("yo");
    let { deleteMEReferralByDates } = this.props;
    deleteMEReferralByDates(data);
  }

  openModal() {
    this.setState({ open: true });
  }

  render() {
    let { deleteMEReferralByDates, disabled } = this.props;
    return (
      <>
        <Button disabled={disabled} onClick={this.openModal}>
          Usuń masowo
        </Button>
        <FormModal
          open={this.state.open}
          title="Usuń masowo"
          acceptButtonName="Usuń"
          cancelButtonName="Anuluj"
          handleSubmit={(data) => {
            deleteMEReferralByDates(data);
          }}
          handleClose={this.closeModal}
        >
          <TwoInputWrapper>
            <FieldDayPicker
              name="me_date_from"
              label="Skierowanie wystawienia od"
            />
            <FieldDayPicker
              name="me_date_to"
              label="Skierowaie wystawienia do"
            />
          </TwoInputWrapper>
        </FormModal>{" "}
      </>
    );
  }
}
