import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import Link from "react-router-dom/Link";
import { LinkContainer } from "../../components/LinkContainer";
import Modal from "../../components/Modal/SimpleAccept";

import { LayoutContent } from "../../components/Layout";
import Pagination from "../../components/Pagination";
import Button from "react-bootstrap/lib/Button";
import AccordionList, {
  AccordionButton,
  AccordionLineNameColumn,
  AccordionLineOptionColumn,
  AccordionLineBody,
} from "components/AccordionList";
import { DeleteButton } from "components/ListComponents";
import PageHeader from "components/PageHeader";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import LoadingScreen from "components/LoadingScreen";

import { getWorkplaces, deleteWorkplace } from "actions/workplaceActions";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -10px 20px;

  & > a {
    margin: 0 10px;
  }
`;

let FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > * {
    flex: 0 1 100%;
    &:last-child {
      flex: 0 0 20px;
      padding: 0 10px;
    }
  }
`;

let sortData = (data, sortCallback) => {
  for (let item of data) {
    if (item.children && item.children.length) {
      item.children = sortData(item.children, sortCallback);
    }
  }
  return data.sort(sortCallback);
};

class WorkplaceList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: [],
      modal: {
        open: false,
        entry_id: null,
      },
      sort: 0,
      sortData: [],
    };

    this.changeSort = this.changeSort.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRequestDelete = this.handleRequestDelete.bind(this);
    this.handleCloseDeleteRequest = this.handleCloseDeleteRequest.bind(this);
  }
  changeSort() {
    let { sort } = this.state;
    let { workplace } = this.props;
    let { data } = workplace;

    let newData = [...data];

    let newState = {};
    switch (sort) {
      case 0: {
        newState = {
          sort: 1,
          sortData: sortData(newData, (a, b) =>
            a.name.localeCompare(b.name, "pl", {
              caseFirst: "lower",
              numeric: true,
              sensitivity: "base",
            })
          ),
        };
        break;
      }
      case 1: {
        newState = {
          sort: 2,
          sortData: sortData(newData, (b, a) =>
            a.name.localeCompare(b.name, "pl", {
              caseFirst: "lower",
              numeric: true,
              sensitivity: "base",
            })
          ),
        };
        break;
      }
      case 2: {
        newState = { sort: 0, sortData: newData };
        break;
      }
    }

    this.setState(newState);
  }

  componentDidMount() {
    this.updateMessage();
    let that = this;
    getWorkplaces()(this.props.dispatch).then(
      this.handleServerResponse({}, function (actions) {
        let workplace = actions.payload.data.workplaces;
        let sortData = workplace.map((item, index) => {
          return { ...item, no: index };
        });
        that.setState({
          data: workplace,
          sortData,
        });
      })
    );
  }

  componentDidUpdate() {
    let { workplace } = this.props;

    if (this.state.sortData.length !== workplace.data.length) {
      let sortData = workplace.data.map((item, index) => {
        return { ...item, no: index };
      });
      this.setState({
        data: workplace.data,
        sortData,
      });
    }
  }

  handleRequestDelete(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.model, open: true, entry_id } });
    };
  }

  handleDelete() {
    const {
      modal: { entry_id },
    } = this.state;
    const { dispatch } = this.props;
    deleteWorkplace(entry_id)(dispatch).then(this.handleServerResponse());
  }

  handleCloseDeleteRequest() {
    this.setState({
      modal: { ...this.state.model, open: false, entry_id: null },
    });
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  render() {
    const { message, modal, data, sort, sortData } = this.state;
    const { workplace } = this.props;

    const AccordionLine = ({
      id,
      open,
      url,
      name,
      option_name,
      lineHeight,
      optionColumnWidth,
      optionColumn,
      level,
      handleChange,
      children,
      className,
      header,
    }) => (
      <AccordionLineBody
        lineHeight={"45px"}
        className={"accordion-item"}
        level={level}
        optionColumnWidth={"90px"}
        header={header}
      >
        <AccordionButton
          handleChange={handleChange}
          children={children}
          open={open}
        />
        <AccordionLineNameColumn
          onClick={
            header
              ? () => {
                  this.changeSort();
                }
              : undefined
          }
        >
          <FlexLine>
            <div>{url ? <Link to={url}>{name}</Link> : name}</div>
            <div>
              {header && sort > 0 ? (
                <i
                  className={`fa fa-long-arrow-${sort == 2 ? "down" : "up"}`}
                />
              ) : (
                ""
              )}
            </div>
          </FlexLine>
        </AccordionLineNameColumn>
        <AccordionLineOptionColumn>
          {!header ? (
            <DeleteButton onClick={this.handleRequestDelete(id)} name="Usuń" />
          ) : (
            <div>{header && option_name}</div>
          )}
        </AccordionLineOptionColumn>
      </AccordionLineBody>
    );

    return (
      <LoadingScreen fetched={workplace.fetched}>
        <LayoutContent>
          {message && message.text && (
            <Alert bsStyle={message.className}>{message.text}</Alert>
          )}
          <PageHeader>Lista stanowisk</PageHeader>
          <ButtonWrapper>
            <LinkContainer to="/business/workplace/add">
              <Button>Dodaj stanowisko</Button>
            </LinkContainer>
          </ButtonWrapper>

          <AccordionList
            data={data}
            header={["Nazwa", "Usuń"]}
            LineComponent={AccordionLine}
          />
          <Pagination
            handlePageChange={this.handlePageChange.bind(this)}
            data={sortData}
          />
          <Modal
            title="Usuń stanowisko"
            description={"Czy chcesz usunąć dany wpis?"}
            open={modal.open}
            handleAccept={this.handleDelete}
            handleClose={this.handleCloseDeleteRequest}
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  message: store.message,
  workplace: store.workplace,
}))(WorkplaceList);
