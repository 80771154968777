import React from "react";
import { connect } from "react-redux";
import { getUserWorkplacesWithout } from "actions/workplaceActions";
// import {
//   registerNotifications,
//   deleteRegisteredNotification,
//   registerNotification,
//   getIncidentToRegister,
//   closeIncident,
// } from "actions/incidentActions";

import {
  getAccident,
  deleteUserFromRegisterGroup,
  getNotification,
  addUserToRegisterGroup,
  getHistory,
  registerNotification,
  registerNotifications,
  deleteRegisteredNotification,
  closeAccident,
} from "actions/accidentOWTWActions";

import NotificationComponent from "components/NotificationComponent";
import SideMenu from "./_SideMenu";

class Notification extends NotificationComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      dictionary_progress_open: null,
      dictionary_progress_close: 1,
      mobileNotHideMenu: true,
      notification_group_id: 5,
      moduleListUrl: "/accident-owtw",
      permission_to_edit: 13,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      accident_owtw: { singleData },
    } = this.props;
    const { id } = match.params;

    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());
    getNotification(id)(dispatch).then(this.handleServerResponse());
    this.getHistory();
    if (!Object.keys(getAccident).length || singleData.id != id) {
      getAccident(id)(dispatch).then(
        this.handleServerResponse({}, (action) => {
          const { accident } = action.payload.data;
          // let { dictionary_progress_id } = singleData;
          this.setState({
            dictionary_progress_id: accident.dictionary_progress_id,
          });
        })
      );
    }
  }

  saveNotifications() {
    const { dispatch, match, history } = this.props;
    const { id } = match.params;
    const result = this.prepareData();
    registerNotifications(
      id,
      result
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/incident");
      })
    );
  }

  handleAddUserToRegisterGroup(notification_group_id, user_id, user) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    addUserToRegisterGroup(
      id,
      user_id
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteUserFromRegisterGroup() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    deleteUserFromRegisterGroup(id, {
      register_notification_group_has_users_id: this.state.modalUserDelete
        .entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  handleRegisterNotification(data) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    data.term = data.term == "null" ? null : data.term;
    registerNotification(id, data)(dispatch).then(this.handleServerResponse());
  }

  handleDeleteRegisteredNotification() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    const {
      modalNotificationDelete: { entry_id },
    } = this.state;
    deleteRegisteredNotification(id, {
      register_notification_id: entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  renderSideMenu() {
    const { match } = this.props;
    const { id } = match.params;

    return <SideMenu accident_id={id} />;
  }

  closeInstance() {
    const { dispatch, history, match } = this.props;
    const { id } = match.params;
    closeAccident(id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/accident-owtw");
      })
    );
  }

  getHistory() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    getHistory(id)(dispatch).then(this.handleServerResponse());
  }
}

export default connect((store) => ({
  workplace: store.workplace,
  notification: store.notification,
  accident_owtw: store.accident_owtw,
}))(Notification);
