import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import AppSchema from "apps/AppSchema";
import Header from "components/Header/";
import Layout from "components/Layout";
import DashboardTemplate from "container/ClientDashboard";
import AccountEdit from "container/Account/Edit";
import * as Characteristic from "container/Characteristic";
import * as Incident from "container/Incident";
import * as Task from "container/Task";
import * as Audit from "container/Audit";
import * as AccidentOWTW from "container/AccidentOWTW";
import * as MedicalExamination from "container/MedicalExamination";
import * as QualificationEntitlements from "container/QualificationEntitlements";
import * as Forms from "container/Forms";
import * as OSH from "container/OSH";
import * as RiskAssessment from "container/RiskAssessment";

import {
  incidentTheme,
  auditTheme,
  taskTheme,
  msbsTheme,
  BADTheme,
  ORZTheme,
} from "style/Theme";

import { withCookies } from "react-cookie";
import NotFound from "container/NotFound/404Private";
import * as Page from "container/Page/";
import PrivateAppSchema from "./PrivateAppSchema.js";

class IncidentAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={incidentTheme}>
        <div className={"incident"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route exact path="/incident" component={Incident.List} />
                <Route
                  exact
                  path="/incident/register/:incident_id"
                  component={Incident.Register}
                />
                <Route
                  exact
                  path="/incident/analysis/:incident_id"
                  component={Incident.Analysis}
                />
                <Route
                  exact
                  path="/incident/task/:incident_id"
                  component={Incident.TaskList}
                />
                <Route
                  exact
                  path="/incident/task/:incident_id/add"
                  component={Incident.TaskAdd}
                />
                <Route
                  exact
                  path="/incident/task/:incident_id/edit/:task_id"
                  component={Incident.TaskEdit}
                />
                <Route
                  exact
                  path="/incident/attachment/:incident_id"
                  component={Incident.Attachment}
                />
                <Route
                  exact
                  path="/incident/notification/:incident_id"
                  component={Incident.Notification}
                />
                <Route
                  exact
                  path="/incident/:incident_id"
                  component={Incident.RedirectComponent}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const IncidentApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(IncidentAppRaw));

class TaskAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={taskTheme}>
        <div className={"task"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route exact path="/task" component={Task.List} />
                <Route exact path="/task/add" component={Task.Add} />
                <Route
                  exact
                  path="/task/repeat/:task_id"
                  component={Task.Repeat}
                />
                <Route exact path="/task/:task_id" component={Task.Edit} />
                <Route exact path="/task/show/:id" component={Task.Show} />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const TaskApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(TaskAppRaw));

class AuditAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={auditTheme}>
        <div className={"audit"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route exact path="/audit" component={Audit.List} />
                <Route
                  exact
                  path="/audit/data/:audit_id"
                  component={Audit.Data}
                />
                <Route
                  exact
                  path="/audit/question/:audit_id"
                  component={Audit.Question}
                />
                <Route
                  exact
                  path="/audit/notification/:audit_id"
                  component={Audit.Notification}
                />
                <Route
                  exact
                  path="/audit/task/:audit_id"
                  component={Audit.TaskList}
                />
                <Route
                  exact
                  path="/audit/task/:audit_id/add/:audit_question_id"
                  component={Audit.TaskAdd}
                />
                <Route
                  exact
                  path="/audit/task/:audit_id/edit/:audit_question_id/:task_id"
                  component={Audit.TaskEdit}
                />
                <Route
                  exact
                  path="/audit/register"
                  component={Audit.Register}
                />
                <Route exact path="/audit/add" component={Audit.Add} />
                <Route exact path="/audit/type" component={Audit.ListTypes} />
                <Route
                  exact
                  path="/audit/type/add"
                  component={Audit.AddTypes}
                />
                <Route
                  exact
                  path="/audit/type/edit/:audit_type_id"
                  component={Audit.EditTypes}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const AuditApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(AuditAppRaw));

class CharacteristicAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={msbsTheme}>
        <div className={"msbs"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/characteristic"
                  component={Characteristic.List}
                />
                <Route
                  exact
                  path="/characteristic/archived"
                  component={Characteristic.ArchivedCharacteristicList}
                />
                <Route
                  exact
                  path="/characteristic/add"
                  component={Characteristic.Add}
                />
                <Route
                  exact
                  path="/characteristic/edit/:characteristic_id"
                  component={Characteristic.Edit}
                />
                <Route
                  exact
                  path="/characteristic/archived/edit/:characteristic_id"
                  component={Characteristic.ArchivedEdit}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const CharacteristicApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(CharacteristicAppRaw));

let AccidentOWTWRoutes = [];
class AccidentOWTWAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={incidentTheme}>
        <div className={"incident"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/accident-owtw"
                  component={AccidentOWTW.List}
                />
                <Route
                  exact
                  path="/accident-owtw/register/:id"
                  component={AccidentOWTW.Register}
                />
                <Route
                  exact
                  path="/accident-owtw/analysis/:id"
                  component={AccidentOWTW.Analysis}
                />
                <Route
                  exact
                  path="/accident-owtw/attachment/:id"
                  component={AccidentOWTW.Attachment}
                />
                <Route
                  exact
                  path="/accident-owtw/notification/:id"
                  component={AccidentOWTW.Notification}
                />
                <Route
                  path="/accident-owtw/:id"
                  component={AccidentOWTW.RedirectComponent}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const AccidentOWTWApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(AccidentOWTWAppRaw));

class RiskAssessmentAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={ORZTheme}>
        <div className={"orz"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/risk-assessment"
                  component={RiskAssessment.List}
                />
                <Route
                  exact
                  path="/risk-assessment/basic/:id?"
                  component={RiskAssessment.Basic}
                />{" "}
                <Route
                  exact
                  path="/risk-assessment/update/:id?"
                  component={RiskAssessment.Update}
                />
                <Route
                  exact
                  path="/risk-assessment/characteristic/:id"
                  component={RiskAssessment.WorkplaceCharacteristic}
                />
                <Route
                  exact
                  path="/risk-assessment/analysis/:id"
                  component={RiskAssessment.Analysis}
                />
                <Route
                  exact
                  path="/risk-assessment/task-decrease-risk/:id"
                  component={RiskAssessment.AddTaskDecreaseRisk}
                />
                <Route
                  exact
                  path="/risk-assessment/archive-orz/list"
                  component={RiskAssessment.ArchivedORZList}
                />
                <Route
                  exact
                  path="/risk-assessment/archive-orz/basic/:id?"
                  component={RiskAssessment.ArchivedORZBasic}
                />
                />
                <Route
                  exact
                  path="/risk-assessment/archive-orz/characteristic/:id"
                  component={RiskAssessment.ArchivedORZCharacteristic}
                />
                <Route
                  exact
                  path="/risk-assessment/archive-orz/analysis/:id"
                  component={RiskAssessment.ArchivedORZAnalysis}
                />
                <Route
                  exact
                  path="/risk-assessment/archive-orz/task-decrease-risk/:id"
                  component={RiskAssessment.ArchivedORZTaskDecreaseRisk}
                />
                <Route
                  exact
                  path="/risk-assessment/archive-orz/notification/:id"
                  component={RiskAssessment.ArchivedORZNotification}
                />
                <Route
                  exact
                  path="/risk-assessment/danger/:id"
                  component={RiskAssessment.Danger}
                />
                <Route
                  exact
                  path="/risk-assessment/template-doc"
                  component={RiskAssessment.TemplateList}
                />
                <Route
                  exact
                  path="/risk-assessment/template-doc/basic/:id?"
                  component={RiskAssessment.TemplateAdd}
                />
                <Route
                  exact
                  path="/risk-assessment/template-doc/danger/:id"
                  component={RiskAssessment.TemplateDanger}
                />
                <Route
                  exact
                  path="/risk-assessment/template-doc/characteristic/:id"
                  component={RiskAssessment.TemplateCharacteristic}
                />
                <Route
                  exact
                  path="/risk-assessment/template-doc/analysis/:id"
                  component={RiskAssessment.TemplateAnalysis}
                />
                <Route
                  exact
                  path="/risk-assessment/template-doc/task-decrease-risk/:id"
                  component={RiskAssessment.TemplateTask}
                />
                {/* <Route
                  exact
                  path="/risk-assessment/template-danger/list"
                  component={RiskAssessment.TemplateDangerList}
                />
                <Route
                  exact
                  path="/risk-assessment/template-danger/add"
                  component={RiskAssessment.TemplateDangerAdd}
                /> */}
                <Route
                  exact
                  path="/risk-assessment/model-hazards/list"
                  component={RiskAssessment.ModelHazardsList}
                />
                <Route
                  exact
                  path="/risk-assessment/model-hazards/add"
                  component={RiskAssessment.ModelHazardsAdd}
                />
                <Route
                  exact
                  path="/risk-assessment/model-hazards/edit/:id"
                  component={RiskAssessment.ModelHazardsEdit}
                />
                <Route
                  exact
                  path="/risk-assessment/control/list"
                  component={RiskAssessment.ControlList}
                />
                <Route
                  exact
                  path="/risk-assessment/control/add"
                  component={RiskAssessment.ControlAdd}
                />
                <Route
                  exact
                  path="/risk-assessment/control/edit/:id"
                  component={RiskAssessment.ControlEdit}
                />
                <Route
                  exact
                  path="/risk-assessment/notification/:id"
                  component={RiskAssessment.Notification}
                />
                <Route
                  exact
                  path="/risk-assessment/task-decrease-risk/:id/task/:ra_ah_tme_id"
                  component={RiskAssessment.TaskAdd}
                />
                <Route
                  exact
                  path="/risk-assessment/task-decrease-risk/:id/task/:ra_ah_tme_id/:task_id"
                  component={RiskAssessment.TaskEdit}
                />
                <Route
                  exact
                  path="/risk-assessment/ra-method"
                  component={RiskAssessment.MethodList}
                />
                <Route
                  exact
                  path="/risk-assessment/ra-method/add"
                  component={RiskAssessment.MethodAdd}
                />
                <Route
                  exact
                  path="/risk-assessment/ra-method/edit/:method_id"
                  component={RiskAssessment.MethodEdit}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const RiskAssessmentApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(RiskAssessmentAppRaw));

class MedicalExaminationAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={BADTheme}>
        <div className={"bad"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/medical-examination"
                  component={MedicalExamination.List}
                />
                <Route
                  exact
                  path="/medical-examination/referral-model/:id?"
                  component={MedicalExamination.ReferralModel}
                />
                <Route
                  exact
                  path="/medical-examination/referral/:id?"
                  component={MedicalExamination.Referral}
                />
                <Route
                  exact
                  path="/medical-examination/referral-list"
                  component={MedicalExamination.ReferralList}
                />
                <Route
                  exact
                  path="/medical-examination/archived-referral-list"
                  component={MedicalExamination.ArchivedReferralList}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const MedicalExaminationApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(MedicalExaminationAppRaw));

class OSHAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={BADTheme}>
        <div className={"bad"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route exact path="/osh" component={OSH.List} />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const OSHApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(OSHAppRaw));

class QualificationEntitlementsAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={BADTheme}>
        <div className={"bad"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/qualification-entitlements"
                  component={QualificationEntitlements.List}
                />
                <Route
                  exact
                  path="/qualification-entitlements/type"
                  component={QualificationEntitlements.ListType}
                />
                <Route
                  exact
                  path="/qualification-entitlements/type/add"
                  component={QualificationEntitlements.AddType}
                />
                <Route
                  exact
                  path="/qualification-entitlements/type/edit/:id"
                  component={QualificationEntitlements.EditType}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const QualificationEntitlementsApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(QualificationEntitlementsAppRaw));

class FormsAppRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <ThemeProvider theme={BADTheme}>
        <div className={"bad"}>
          {logged && (
            <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
          )}
          {logged && (
            <Layout>
              <Switch>
                <Route exact path="/forms" component={Forms.Forms} />
                <Route
                  exact
                  path="/forms/certificate-templates/osh/:id?"
                  component={Forms.OSHCertificateTemplate}
                />
                <Route
                  exact
                  path="/forms/certificate-templates"
                  component={Forms.CertificateTemplatesList}
                />
              </Switch>
            </Layout>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const FormsApp = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(FormsAppRaw));

class RestRaw extends PrivateAppSchema {
  render() {
    const logged = !!Object.keys(this.props.account.singleData).length > 0;
    return (
      <div>
        {logged && (
          <Header mainPageUrl={"/dashboard"} menu={this.createMenu()} />
        )}
        {logged && (
          <Layout>
            <Switch>
              <Route exact path="/account/edit" component={AccountEdit} />
              <Route exact path="/dashboard" component={DashboardTemplate} />
              <Route exact path="/page/:url" component={Page.Show} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        )}
      </div>
    );
  }
}

const Rest = connect((store) => ({
  account: store.account,
  modules: store.modules,
  page: store.page,
}))(withCookies(RestRaw));

export default class PrivateApp extends AppSchema {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/task*" component={TaskApp} />
          <Route exact path="/characteristic*" component={CharacteristicApp} />
          <Route exact path="/accident-owtw*" component={AccidentOWTWApp} />
          <Route
            exact
            path="/medical-examination*"
            component={MedicalExaminationApp}
          />
          <Route exact path="/risk-assessment*" component={RiskAssessmentApp} />
          <Route exact path="/osh*" component={OSHApp} />
          <Route
            exact
            path="/qualification-entitlements*"
            component={QualificationEntitlementsApp}
          />
          <Route exact path="/forms*" component={FormsApp} />
          <Route exact path="/incident*" component={IncidentApp} />
          <Route exact path="/audit*" component={AuditApp} />
          <Route exact path="*" component={Rest} />
        </Switch>
      </div>
    );
  }
}
