import React, { Fragment } from "react";
import Modal from "components/Modal/SimpleAccept";
import { FieldInputCheckBox } from "../Fields";
import { StyledInputCheckBox } from "style/styleComponents/Form/input";
import { updateGus } from "actions/incidentActions";

export default class GusCheckboxModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: false,
    };
    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  accept() {
    let { accept, value } = this.props;
    let valueToSend = !value;
    accept(!value);
  }

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    let { name, label, readOnly, value, disabled } = this.props;

    return (
      <Fragment>
        <StyledInputCheckBox
          checked={value}
          disabled={true}
          label={label}
          onClick={() => {
            if (!disabled) {
              this.setState({ open: true });
            }
          }}
        />
        <Modal
          title={
            value
              ? "Anulowanie oznaczenia, że karta została wysłana do GUS "
              : "Czy na pewno karta została wysłana do GUS?"
          }
          open={this.state.open}
          handleClose={this.closeModal}
          handleAccept={this.accept}
        />
      </Fragment>
    );
  }
}
