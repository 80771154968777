import React from "react";
import { connect } from "react-redux";
import AppComponent, { setMessage } from "../../components/AppComponent";
import AccountEditComponent from "../../components/AccountEdit";
import {
  getFullAccountData,
  saveAvatar,
  saveNewPassword,
  deleteAvatar,
  saveAccount,
  loginToBusinessAccount,
  changeShowSettings,
} from "actions/accountActions";

import { getMEAccountData } from "actions/meActions";
import { getOSHAccountData } from "actions/OSHTrainingActions";
import { getQEAccountData } from "actions/QEActions";
import { media } from "../../style/style-utils";
import { withCookies } from "react-cookie";

class AccountEdit extends AppComponent {
  constructor(props) {
    super(props);

    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.handleAvatarAutoUpload = this.handleAvatarAutoUpload.bind(this);
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
    this.handleSubmitNewPassword = this.handleSubmitNewPassword.bind(this);
    this.handleSubmitData = this.handleSubmitData.bind(this);
    this.handleLoginToAdmin = this.handleLoginToAdmin.bind(this);
    this.handleShowSettingChange = this.handleShowSettingChange.bind(this);
    this.state = {
      ...this.state,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    getFullAccountData()(dispatch).then(this.handleServerResponse());
    getQEAccountData()(dispatch).then(this.handleServerResponse());
    getMEAccountData()(dispatch).then(this.handleServerResponse());
    getOSHAccountData()(dispatch).then(this.handleServerResponse());
  }

  deleteAvatar() {
    this.setState({ modalAvatar: { ...this.state.modalAvatar, open: true } });
  }

  closeModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: false } });
    };
  }

  handleAvatarAutoUpload(formData, callback) {
    saveAvatar(formData)(this.props.dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { message, avatar_url } = action.payload.data;
        this.setState({
          message: setMessage(action.payload.data.message, "success"),
        });
        callback(avatar_url);
      })
    );
  }

  handleDeleteAvatar(callback) {
    deleteAvatar()(this.props.dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setState({ message: action.payload.data.message });
        callback();
      })
    );
  }

  handleSubmitData(data) {
    saveAccount(data)(this.props.dispatch).then(this.handleServerResponse());
  }

  handleSubmitNewPassword(data) {
    saveNewPassword(data)(this.props.dispatch).then(
      this.handleServerResponse()
    );
  }

  handleLoginToAdmin() {
    const { dispatch, history } = this.props;
    loginToBusinessAccount()(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/dashboard");
      })
    );
  }

  handleShowSettingChange(id, value) {
    const { dispatch } = this.props;
    changeShowSettings({ id, value })(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  render() {
    const { message } = this.state;
    const {
      account: { singleData: accountData },
      me,
      osh,
      qe,
    } = this.props;
    const sideMenu = [{ name: "Ustawienia konta", url: "/account/edit" }];

    if (accountData.admin) {
      sideMenu.push({
        name: "Kliencki panel administratora",
        onClick: this.handleLoginToAdmin,
      });
    }

    return (
      <div>
        <AccountEditComponent
          message={message}
          handleShowSettingChange={this.handleShowSettingChange}
          data={accountData}
          settings={accountData.show_settings}
          sideMenu={sideMenu}
          handleAvatarAutoUpload={this.handleAvatarAutoUpload}
          handleDeleteAvatar={this.handleDeleteAvatar}
          handleSubmitNewPassword={this.handleSubmitNewPassword}
          handleSubmitData={this.handleSubmitData}
          me={me}
          osh={osh}
          qe={qe}
        />
      </div>
    );
  }
}

export default withCookies(
  connect((store) => ({
    account: store.account,
    users: store.users,
    me: store.me.singleData,
    osh: store.osh.singleData,
    qe: store.qe.data,
  }))(AccountEdit)
);
