export function getLocalizations() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATIONS_REQUEST",
        "GET_LOCALIZATIONS_SUCCESS",
        "GET_LOCALIZATIONS_ERROR",
      ],
      payload: {
        request: {
          url: "/business/localization",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessMainataToLocalization() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_BUSINESS_MAIN_DATA_TO_LOCALIZATION_REQUEST",
        "GET_BUSINESS_MAIN_DATA_TO_LOCALIZATION_SUCCESS",
        "GET_BUSINESS_MAIN_DATA_TO_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: "/business/business/get-main-address",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUsersLocalizations() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATIONS_REQUEST",
        "GET_LOCALIZATIONS_SUCCESS",
        "GET_LOCALIZATIONS_ERROR",
      ],
      payload: {
        request: {
          url: "/localization",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUsersLocalizationsAndDivisions() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATIONS_REQUEST",
        "GET_LOCALIZATIONS_SUCCESS",
        "GET_LOCALIZATIONS_ERROR",
      ],
      payload: {
        request: {
          url: "/localization/withDivisions",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUsersLocalizationsAndDivisions2() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATIONS_2_REQUEST",
        "GET_LOCALIZATIONS_2_SUCCESS",
        "GET_LOCALIZATIONS_2_ERROR",
      ],
      payload: {
        request: {
          url: "/localization/withDivisions",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}


export function getLocalization(localization_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATION_REQUEST",
        "GET_LOCALIZATION_SUCCESS",
        "GET_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/edit/${localization_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addLocalization(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_LOCALIZATION_REQUEST",
        "ADD_LOCALIZATION_SUCCESS",
        "ADD_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: "/business/localization/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editLocalization(localization_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_LOCALIZATION_REQUEST",
        "EDIT_LOCALIZATION_SUCCESS",
        "EDIT_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/edit/${localization_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteLocalization(localization_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_LOCALIZATION_REQUEST",
        "DELETE_LOCALIZATION_SUCCESS",
        "DELETE_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/delete/${localization_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDivisionsLocalization(division_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_DIVISIONS_LOCALIZATION_REQUEST",
        "GET_DIVISIONS_LOCALIZATION_SUCCESS",
        "GET_DIVISIONS_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/getByDivision${
            division_id ? `/${division_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addDivision(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_DIVISION_REQUEST",
        "ADD_DIVISION_SUCCESS",
        "ADD_DIVISION_ERROR",
      ],
      payload: {
        request: {
          url: "/business/localization/add-division",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getDivision(division_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_DIVISION_REQUEST",
        "GET_DIVISION_SUCCESS",
        "GET_DIVISION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/division/${division_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteDivision(division_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_DIVISION_REQUEST",
        "DELETE_DIVISION_SUCCESS",
        "DELETE_DIVISION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/division/delete/${division_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function editDivision(division_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_DIVISION_REQUEST",
        "EDIT_DIVISION_SUCCESS",
        "EDIT_DIVISION_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/division/edit/${division_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getLocalizationsOnly() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATIONS_REQUEST",
        "GET_LOCALIZATIONS_SUCCESS",
        "GET_LOCALIZATIONS_ERROR",
      ],
      payload: {
        request: {
          url: "/localization/only",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getSpeculateSymbol(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_LOCALIZATION_SPECULATE_SYMBOL_REQUEST",
        "GET_LOCALIZATION_SPECULATE_SYMBOL_SUCCESS",
        "GET_LOCALIZATION_SPECULATE_SYMBOL_ERROR",
      ],
      payload: {
        request: {
          url: "/business/localization/get-speculate-symbol",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function checkSlugAvailable(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "CHECK_SLUG_LOCALIZATION_REQUEST",
        "CHECK_SLUG_LOCALIZATION_SUCCESS",
        "CHECK_SLUG_LOCALIZATION_ERROR",
      ],
      payload: {
        request: {
          url: "/business/localization/check-slug",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function clearLocationReducer() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_LOCALIZATION_REDUCER",
      payload: {},
    });
}
