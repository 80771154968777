import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/lib/Modal";
import Alert from "react-bootstrap/lib/Alert";

import { getUsersLocalizations } from "actions/localizationActions";
import { getSearchedWorkplaceInitial } from "actions/workplaceActions";
import {
  publicAddUser,
  publicEditUser,
  getHiredStatuses,
  getUserPublic,
  editUserPublic,
  getUserByWorkplaceByUser as getUserByWorkplacePublic,
  clearUsersSingleData,
  saveAvatarPublic,
  deleteAvatarPublic,
} from "actions/userActions";
import { getUserWorkplacesWithout as getParentWorkplacesWithoutPublic } from "actions/workplaceActions";
import { getUserSections as getSectionsPublic } from "actions/sectionActions";
import LoadingScreen from "components/LoadingScreen";

import ModalSchema from "components/Modal/./_SchemaAppComponent";
import validator, { composeValidators } from "components/Validation";
import { UserForm } from "components/UsersAccounts.Form/index";
import AppComponent, { setMessage } from "components/AppComponent";

const StyledModal = styled(Modal)`
  & .modal-dialog {
    width: 90%;
    max-width: 900px;
  }
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";

function getFullResidencePlace(user, prefix = "") {
  let textOnly = (value) => (value ? `${prefix}${value}` : "");

  if (Object.keys(user).length) {
    let {
      residence_apartament_number,
      residence_building_number,
      residence_place,
      residence_postcode,
      residence_street,
    } = user;
    return `${textOnly(residence_street)}${textOnly(
      residence_building_number,
      " "
    )}${
      residence_building_number && residence_apartament_number
        ? `${textOnly(residence_apartament_number, "/")}`
        : ""
    } ${textOnly(residence_postcode, " ")} ${textOnly(residence_place, " ")}`;
  }
}

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUsersDataToProcess = this.getUsersDataToProcess.bind(this);

    this.handleAvatarAutoUpload = this.handleAvatarAutoUpload.bind(this);
    this.handleDeleteAvatar = this.handleDeleteAvatar.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getUsersLocalizations()(dispatch).then(this.handleServerResponse());
    getHiredStatuses()(dispatch).then(this.handleServerResponse());
    getSearchedWorkplaceInitial()(dispatch).then(this.handleServerResponse());
  }

  componentDidUpdate(prevProps) {
    const { dispatch, user_id, users_id, users } = this.props;
    let that = this;
    if (users_id && users_id !== prevProps.users_id) {
      // this.getUsersDataToProcess();
      getUserPublic(users_id)(dispatch).then(
        this.handleServerResponse({}, (action) => {
          const {
            singleData: { localization_id, workplace_parent_id },
          } = this.props.users;
          const promise = [];
          promise.push(
            getParentWorkplacesWithoutPublic()(dispatch).then(
              this.handleServerResponse()
            )
          );

          if (localization_id) {
            promise.push(
              getSectionsPublic(localization_id)(dispatch).then(
                this.handleServerResponse()
              )
            );
          }

          if (workplace_parent_id) {
            promise.push(
              getUserByWorkplacePublic(
                workplace_parent_id,
                user_id
              )(dispatch).then(this.handleServerResponse())
            );
          }

          Promise.all(promise).then(() => {
            that.setState({ localFetched: true });
          });
        })
      );
    }
  }

  getUsersDataToProcess() {
    const { dispatch, users_id } = this.props;

    getUserPublic(users_id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        // let user = action.payload.data.user;
        // user.date_birth = user.date_birth
        //   ? moment(user.date_birth).format("YYYY-MM-DD")
        //   : null;
        // user.id = id;
        // this.setState({
        //   modalGetUser: { ...this.state.modalGetUser, user },
        // });
      })
    );
  }

  accept(user, message) {
    const { accept = () => {}, closeModal } = this.props;
    accept({ user, message });
    this.closeModal();
  }

  closeModal() {
    let { closeModal, dispatch } = this.props;
    clearUsersSingleData()(dispatch);
    closeModal();
  }

  handleSubmit(values) {
    let { dispatch, users_id } = this.props;

    if (users_id) {
      publicEditUser(
        users_id,
        values
      )(dispatch).then(
        this.handleServerResponse({}, (action) => {
          let user = action.payload.data.user;
          let message = action.payload.data.message;
          this.accept(user, message);
        })
      );
    } else {
      publicAddUser(values)(dispatch).then(
        this.handleServerResponse({}, (action) => {
          let user = action.payload.data.user;
          let message = action.payload.data.message;

          this.accept(user, message);
        })
      );
    }
  }

  handleAvatarAutoUpload(formData, callback) {
    const { dispatch, match, users_id } = this.props;

    saveAvatarPublic(
      users_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { message, avatar_url } = action.payload.data;
        this.setState({
          message: setMessage(action.payload.data.message, "success"),
        });
        callback(avatar_url);
      })
    );
  }

  handleDeleteAvatar() {
    const { dispatch, match, users_id } = this.props;

    deleteAvatarPublic(users_id)(dispatch).then(this.handleServerResponse({}));
  }

  render() {
    const {
      children,
      data,
      handleAccept,
      open,
      title,
      user,
      users: { data: userParent, singleData, fetched },
      localization: { data: localizationData = [] },
      workplace: { data: workplaceData = [] },
      modules: { data: modules },
      permission = {},
    } = this.props;

    let { message } = this.state;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {message && message.text && (
              <Alert bsStyle={message.className}>{message.text}</Alert>
            )}
            <UserForm
              acceptButtonName="Zapisz i zamknij"
              boxTitle="Dodaj użytkownika"
              cancelFunction={() => {
                this.closeModal();
              }}
              create
              createView
              handleAvatarAutoUpload={this.handleAvatarAutoUpload}
              handleDeleteAvatar={this.handleDeleteAvatar}
              data={singleData}
              handleSubmit={this.handleSubmit}
              message={message}
              modules={modules}
              localizations={localizationData}
              parent_workplaces={workplaceData}
              users={userParent}
              getSections={getSectionsPublic}
              workplaces={workplaceData}
              publicView
              readOnly={singleData.id ? !permission[64] : !permission[63]}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  workplace: store.workplace,
  users: store.users,
  modules: store.modules,
}))(SimpleAcceptModal);

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
