import React from "react";
import ButtonBoostrap from "react-bootstrap/lib/Button";

export const Button = ButtonBoostrap;

export const ButtonWithIcon = (props) => {
  return (
    <ButtonBoostrap
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      className="btn-default"
      bsStyle={
        props.listButton
          ? "list-delete-button"
          : props.bsStyle
          ? props.bsStyle
          : null
      }
    >
      <i className={props.icon}></i>
      {props.name && props.name.length ? (
        <span style={{ margin: "0 5px" }}>{props.name}</span>
      ) : (
        ""
      )}
    </ButtonBoostrap>
  );
};

export const ButtonWithIconLoading = (props) => {
  return (
    <ButtonWithIcon
      {...props}
      disabled={props.disabled || props.fetching}
      name={props.children}
      icon={props.fetching ? "fa fa-spinner fa-spin" : props.icon || ""}
    />
  );
};

export const AddButton = (props) => {
  return (
    <ButtonWithIcon {...props} disabled={props.disabled} icon="fa fa-plus" />
  );
};

export const DeleteButton = (props) => {
  return (
    <ButtonWithIcon {...props} disabled={props.disabled} icon="fa fa-trash" />
  );
};

export const DownloadButton = (props) => {
  return (
    <ButtonWithIcon
      {...props}
      icon={
        props.fetching
          ? "fa fa-spinner fa-spin"
          : props.icon || "fa fa-cloud-download"
      }
    />
  );
};
