import React from "react";
import {connect} from "react-redux";
import Header from "../components/Header/";
import Layout from "../components/Layout";
import {Route, Switch} from "react-router-dom";
import {withCookies} from "react-cookie";
import {getBusinessAccountData, logout} from "../actions/accountActions";

import DashboardContainer from "../container/Dashboard/";
import * as Localization from "../container/Localization/";
import * as Responsibility from "../container/Responsibility/";
import * as Notification from "../container/Notification/";
import * as Workplace from "../container/Workplace/";
import * as UsersAccounts from "../container/UsersAccounts/";
import * as ORZ from "container/BusinessORZ/";
import NotFound from "container/NotFound/404Business";
import {getPublicMenuPages} from "../actions/pageActions";

import AppComponent from "components/AppComponent";
import {WhiteBorder} from "components/Header/_styleComponents";

class ClientAppRaw extends AppComponent {

  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const { account: { singleData: accountData }, dispatch } = this.props;
    getPublicMenuPages()(dispatch);
    if (!Object.keys(accountData).length || !accountData.admin) {
      getBusinessAccountData()(dispatch)
        .then(this.handleServerResponse());
    }
  }

  handleLogout() {
    const { dispatch, history } = this.props;
    logout()(dispatch)
      .then(this.handleServerResponse({},action=>{
        history.push("/business/login");
      }));
  }

  render() {
    const { account: { singleData: accountData }, page: {pageMenu}} = this.props;
 
    const Menu = { 
      left: [
        { url: "/business/dashboard", title: "Strona główna", class: "ehs-menu aaaaaaa ehs-menu-dashboard", images:{
          base:"dashboard.svg",
          hover:"dashboard_1.svg"
        }}, 
        { url: "/business/localization", title: "Lokacje", class: "ehs-menu ehs-menu-localizations", images:{
          base:"locations.svg",
          hover:"locations_1.svg"
        } },
        { url: "/business/workplace", title: "Stanowiska", class: "ehs-menu ehs-menu-roles", images:{
          base:"roles.svg",
          hover:"roles_1.svg"
        } },
        { url: "/business/users-accounts", title: "Konta użytkowników", class: "ehs-menu ehs-menu-users",images:{
          base:"users.svg",
          hover:"users_1.svg"
        } },
        { url: "/business/responsibility", title: "Ustawienia odpowiedzialności", class: "ehs-menu ehs-menu-responsibilities",images:{
          base:"responsibilities.svg",
          hover:"responsibilities_1.svg"
        } },
        {
          url: "/business/notification",
          title: "Ustawienia powiadomień",
          class: "ehs-menu ehs-menu-notices",images:{
            base:"notices.svg",
            hover:"notices_1.svg"
          }
        },
        {
          url: "/business/orz",
          title: "Ocena ryzyka",
          class: "ehs-menu ehs-menu-notices",images:{
             base:"ORZ.svg",
            hover:"ORZ_1.svg"
          }
        },
        {render: props => (<WhiteBorder />)},
        {
          class: "", title: `${accountData.first_name} ${accountData.last_name}`,account:true,image:true,imageUrl:accountData.avatar_url,
          children: [
            {name:"Ustawienia konta",url:"/account/edit"},
            {name:"Powrót do widoku użytkownika", url:"/dashboard"}
          ]
        },
        {
          class: "ehs-menu ehs-menu-help", title: "Pomoc", children: pageMenu
        },
        {url: "/dashboard", title: "Wyloguj", class: "ehs-menu ehs-menu-logout",onClick:this.handleLogout,images:{
          base:"logout.svg",
          hover:"logout.svg"
        }}
      ],
      right: [

      ]
    };


    const logged = !!Object.keys(this.props.account.singleData).length && (this.props.account.singleData.admin || this.props.account.singleData.superadmin);
    return (<div style={{ minHeight: "100vh" }}>
      {logged && <Header  mainPageUrl={"/dashboard"} menu={Menu} />}
      {logged && <Layout>
        <Switch>
          <Route exact path="/business" component={DashboardContainer} />
          <Route exact path="/business/dashboard" component={DashboardContainer} />
          <Route exact path="/business/localization" component={Localization.List} />
          <Route exact path="/business/localization/add-division" component={Localization.AddDivision} />
          <Route exact path="/business/localization/division/edit/:division_id" component={Localization.EditDivision} />
          <Route exact path="/business/localization/add" component={Localization.Add} />
          <Route exact path="/business/localization/edit/:localization_id" component={Localization.Edit} />
          <Route exact path="/business/responsibility" component={Responsibility.LocalizationList} />
          <Route exact path="/business/notification" component={Notification.LocalizationList} />
          <Route exact path="/business/notification/:localization_id" component={Notification.List} />
          <Route exact path="/business/responsibility/:localization_id" component={Responsibility.List} />
          <Route exact path="/business/workplace" component={Workplace.List} />
          <Route exact path="/business/workplace/add" component={Workplace.Add} />
          <Route exact path="/business/workplace/edit/:workplace_id" component={Workplace.Edit} />
          <Route exact path="/business/users-accounts" component={UsersAccounts.List} />
          <Route exact path="/business/users-accounts/add" component={UsersAccounts.Add} />
          <Route exact path="/business/users-accounts/edit/:user_id" component={UsersAccounts.Edit} />

          
          <Route exact path="/business/orz" component={ORZ.List} />
          <Route exact path="/business/orz/add" component={ORZ.Add} />
          <Route exact path="/business/orz/edit/:method_id" component={ORZ.Edit} />


          <Route component={NotFound} />
        </Switch>
      </Layout>}
    </div>);
  }
}

const ClientApp = connect(store => ({
  account: store.account,
  page: store.page
}))(withCookies(ClientAppRaw));

export default ClientApp;