import React from "react";
import { connect } from "react-redux";

import {
  saveAccidentWitness,
  generateWitness,
  getWitnesses,
  deleteWitness,
} from "actions/accidentOWTWActions";

import AppComponent from "components/AppComponent";
import saveBlobFile from "components/saveBlobFile";
import WitnessComponentDump from "components/Modal/WitnessesForm/WitnessComponent";
class WitnessAnalysis extends AppComponent {
  constructor(props) {
    super(props);

    this.deleteWitness = this.deleteWitness.bind(this);
    this.saveWitness = this.saveWitness.bind(this);
  }

  componentDidMount() {
    let { accident_id, dispatch } = this.props;
    getWitnesses(accident_id)(dispatch);
  }

  saveWitness(values, { closeModal }) {
    const { accident_id, dispatch } = this.props;
    let { file, ...data } = values;
    saveAccidentWitness({
      id: accident_id,
      data,
    })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let { id } = action.payload.data.witness;

        if (file) {
          generateWitness({ id: accident_id, witness_id: id })(dispatch).then(
            (res) => {
              if (!res.error) {
                const file = res.payload.data;
                const fileName = `świadek_${id}.pdf`;
                saveBlobFile(file, fileName);
                closeModal();
              }
            }
          );
        } else {
          closeModal();
        }
      })
    );
  }

  deleteWitness(id, callback) {
    let { accident_id, dispatch } = this.props;
    if (id) {
      deleteWitness({
        accident_id,
        id,
      })(dispatch).then(
        this.handleServerResponse({}, (action) => {
          callback();
        })
      );
    } else {
      callback();
    }
  }

  render() {
    const {
      accident_id,
      dispatch,
      accident_owtw: { witnesses },
      users: { autoCompleteUsers },
    } = this.props;

    return (
      <>
        <WitnessComponentDump
          deleteWitness={this.deleteWitness}
          saveWitness={this.saveWitness}
          incident_witnesses={witnesses}
          users={{ autoCompleteUsers }}
        />
      </>
    );
  }
}

export default connect((store) => ({
  accident_owtw: store.accident_owtw,
  users: store.users,
}))(WitnessAnalysis);
