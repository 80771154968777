import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { Link } from "react-router-dom";
import { LayoutComponent, LayoutContent } from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import Pagination from "../../components/Pagination";
import DayPicker from "../../lib/DayPicker";
import Select from "../../components/Select";
import { StyledInput } from "../../style/styleComponents/Form/input";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import { media } from "../../style/style-utils";
import PageHeader from "../../components/PageHeader";
import Modal from "../../components/Modal/SimpleAccept";
import moment from "moment";
import { getTasks, deleteTask, getTasksByFilter, getProgresses } from "../../actions/taskActions";
import { getUsersLocalizations } from "../../actions/localizationActions";
import { getUserSections } from "../../actions/sectionActions";
import { getUsersToTaskFilter } from "../../actions/userActions";
import Alert from "react-bootstrap/lib/Alert";
import { Form, Field } from "react-final-form";
import LoadingScreen from "../../components/LoadingScreen";

class IncidentForm extends AppComponent {
  render() {
    return (
      <div>
        Incident form
      </div>
    );
  }
}

export default connect(store => ({

}))(IncidentForm);
