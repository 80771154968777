import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/lib/Table";
import Alert from "react-bootstrap/lib/Alert";

import {
  deleteAuditType,
  getAuditTypes,
  getAuditDefaultPermission,
} from "actions/auditActions";
import { downloadAuditType } from "actions/auditActions";

import AppComponent from "components/AppComponent";
import LoadingScreen from "components/LoadingScreen/";
import { LayoutContent } from "components/Layout";
import PageHeader from "components/PageHeader";
import {
  ButtonWrapper,
  ButtonWithIcon,
  DeleteButton,
  StyledTableList,
} from "components/ListComponents";
import { LinkContainer } from "components/LinkContainer";
import TableSortable from "components/TableSortable";
import Modal from "components/Modal/SimpleAccept";
import saveBlobFile from "components/saveBlobFile";

import SideMenu from "./_SideMenu";

class ListOfTypes extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modal: {
        audit_type_id: null,
      },
    };
    this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.updateMessage();
    getAuditDefaultPermission()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getAuditTypes({ readOnly: true })(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    this.updateMessage();
  }

  handleCloseModal() {
    this.setState({
      modal: { ...this.state.modal, open: false, audit_type_id: null },
    });
  }

  handleDeleteRequest(audit_type_id) {
    return () => {
      this.setState({
        modal: { ...this.state.modal, open: true, audit_type_id },
      });
    };
  }

  handleDelete() {
    const { dispatch } = this.props;
    const { audit_type_id } = this.state.modal;
    deleteAuditType(audit_type_id)(dispatch).then(
      this.handleServerResponse({})
    );
  }

  handleDownload(id, name, index) {
    return () => {
      const { match } = this.props;
      downloadAuditType(
        id,
        index
      )(this.props.dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `audyt-${name}.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    };
  }

  render() {
    const {
      audit: { typeData = [], permission },
    } = this.props;
    const { modal, message } = this.state;
    const readOnly = Boolean(typeData.business_id === 0);
    return (
      <LoadingScreen fetched={true}>
        <LayoutContent
          error404={this.state.error_code == 404}
          SideMenu={<SideMenu permission={permission} />}
        >
          {message && message.text && (
            <Alert bsStyle={message.className}>{message.text}</Alert>
          )}
          <PageHeader>Rodzaje audytów</PageHeader>
          <ButtonWrapper>
            <LinkContainer to="/audit/type/add">
              <ButtonWithIcon
                disabled={!permission[14]}
                icon="fa fa-plus"
                name="Dodaj nowy rodzaj audytu"
              />
            </LinkContainer>
          </ButtonWrapper>
          <StyledTableList>
            <TableSortable
              quickMenu
              headers={[
                { name: "L.p." },
                { name: "Nazwa", prop: "title", sort_type: "string" },
                { name: "Drukuj", prop: "date_create" },
                { name: "Usuń", prop: "" },
              ]}
              data={typeData}
              render={(sortedTypeData) => {
                return typeData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Link to={`/audit/type/edit/${item.id}`}>
                        {item.title}
                      </Link>
                    </td>
                    <td>
                      <ButtonWithIcon
                        disabled={item.printFetching}
                        onClick={this.handleDownload(
                          item.id,
                          item.title,
                          index
                        )}
                        icon={
                          item.printFetching
                            ? "fa fa-spinner fa-spin"
                            : "fa fa-print"
                        }
                        listButton
                        name={"Drukuj"}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onClick={this.handleDeleteRequest(item.id)}
                        disabled={
                          Boolean(item.business_id === 0) || !permission[16]
                        }
                      />
                    </td>
                  </tr>
                ));
              }}
            />
          </StyledTableList>
          <Modal
            open={modal.open}
            title={"Czy chcesz usunąć rodzaj audytu?"}
            handleAccept={this.handleDelete}
            handleClose={this.handleCloseModal}
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  audit: store.audit,
  message: store.message,
}))(ListOfTypes);
