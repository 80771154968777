import LAuditTypeAdd from "./AuditType.Add";
import LRiskAssessmentAdd from "./RAMethod/Add";
import LRiskAssessmentList from "./RAMethod/List";
import LRiskAssessmentEdit from "./RAMethod/Edit";
import LAuditTypeList from "./AuditTypeList";
import LAuditTypeEdit from "./AuditTypeEdit";
import LHazardIdentificationAdd from "./HazardIdentification.Add";
import LHazardIdentificationList from "./HazardIdentification.List";

import LRiskAssessmentTemplateList from "./RiskAssessmentTemplete/List.js";
import LRiskAssessmentTemplateBasic from "./RiskAssessmentTemplete/Basic.js";
import LRiskAssessmentTemplateListDanger from "./RiskAssessmentTemplete/Danger";
import LRiskAssessmentTemplateAddCharacteristic from "./RiskAssessmentTemplete/CharakteristicAdd.js";
import LRiskAssessmentTemplateAnalysis from "./RiskAssessmentTemplete/Analysis.js";

import LRiskAssessmentTemplateControlList from "./ControlList/ControlList.js";
import LRiskAssessmentTemplateControlAdd from "./ControlList/ControlAdd.js";
import LRiskAssessmentTemplateControlEdit from "./ControlList/ControlEdit.js";

import LModelHazardAdd from "./ModelHazards/Add";
import LModelHazardEdit from "./ModelHazards/Edit";
import LModelHazardList from "./ModelHazards/List";

import LQETypeList from "./QEType/ListType";
import LQETypeAdd from "./QEType/AddType";
import LQETypeEdit from "./QEType/EditType";

export const AuditTypeList = LAuditTypeList;
export const AuditTypeEdit = LAuditTypeEdit;
export const AuditTypeAdd = LAuditTypeAdd;
export const RiskAssessmentAdd = LRiskAssessmentAdd;
export const RiskAssessmentList = LRiskAssessmentList;
export const RiskAssessmentEdit = LRiskAssessmentEdit;
export const HazardIdentificationAdd = LHazardIdentificationAdd;
export const HazardIdentificationList = LHazardIdentificationList;

export const RiskAssessmentTemplateList = LRiskAssessmentTemplateList;
export const RiskAssessmentTemplateBasic = LRiskAssessmentTemplateBasic;
export const RiskAssessmentTemplateListDanger = LRiskAssessmentTemplateListDanger;
export const RiskAssessmentTemplateAddCharacteristic = LRiskAssessmentTemplateAddCharacteristic;
export const RiskAssessmentTemplateAnalysis = LRiskAssessmentTemplateAnalysis;
export const RiskAssessmentTemplateControlList = LRiskAssessmentTemplateControlList;
export const RiskAssessmentTemplateControlAdd = LRiskAssessmentTemplateControlAdd;
export const RiskAssessmentTemplateControlEdit = LRiskAssessmentTemplateControlEdit;

export const QETypeList = LQETypeList;
export const QETypeAdd = LQETypeAdd;
export const QETypeEdit = LQETypeEdit;

export const ModelHazardAdd = LModelHazardAdd;
export const ModelHazardEdit = LModelHazardEdit;
export const ModelHazardList = LModelHazardList;
