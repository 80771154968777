import React, { Fragment } from "react";
import {
  TwoInputWrapper,
  LeftPadding,
  Width33,
  FlexLine,
} from "../AnalysisForm.style";
import { FieldInputCheckBox } from "../Fields";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import { StyledTooltip } from "style/styleComponents/ToolTip";

export default class ProtocolAccidentSeverity extends React.PureComponent {
  render() {
    let { readOnly } = this.props;

    return (
      <Fragment>
        <StyledLabel>Ciężkość wypadku</StyledLabel>
        <FlexLine style={{ maxWidth: "600px" }}>
          <StyledTooltip
            placement="bottom"
            overlay={
              <span>
                Za śmiertelny wypadek przy pracy uważa się wypadek, w wyniku
                którego nastąpiła śmierć w okresie nieprzekraczającym 6 miesięcy
                od dnia wypadku.
              </span>
            }
          >
            <span>
              <FieldInputCheckBox
                name="protocol_accident_severity_fatal"
                label={"śmiertelny"}
                disabled={readOnly}
              />
            </span>
          </StyledTooltip>
          <StyledTooltip
            placement="bottom"
            overlay={
              <span>
                Za ciężki wypadek przy pracy uważa się wypadek, w wyniku którego
                nastąpiło ciężkie uszkodzenie ciała, takie jak: utrata wzroku,
                słuchu, mowy, zdolności rozrodczej lub inne uszkodzenie ciała
                albo rozstrój zdrowia, naruszające podstawowe funkcje organizmu,
                a także choroba nieuleczalna lub zagrażająca życiu, trwała
                choroba psychiczna, całkowita lub częściowa niezdolność do pracy
                w zawodzie albo trwałe, istotne zeszpecenie lub zniekształcenie
                ciała.
              </span>
            }
          >
            <span>
              <FieldInputCheckBox
                name="protocol_accident_severity_serious"
                label={"ciężki"}
                disabled={readOnly}
              />
            </span>
          </StyledTooltip>

          <FieldInputCheckBox
            name="protocol_accident_severity_causing_unable_on_time"
            label={"powodujący czasową niezdolność do pracy"}
            disabled={readOnly}
          />
        </FlexLine>
      </Fragment>
    );
  }
}
