import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/lib/Table";
import { StyledTableList } from "../../components/ListComponents";
import TableSortable from "../../components/TableSortable";
const StyledTableList2 = styled(StyledTableList)`
  &.table > thead > tr > th {
    text-align: left !important;
  }
`;

export const countDiffrence = (date, options = {}) => {
  return moment(date).diff(moment(options.initialDate), "days");
};

const trBackgroundColor = (date, options) => {
  if (!date) {
    return null;
  }
  const leftDays = countDiffrence(date, options);
  switch (true) {
    case leftDays < 0:
      return "danger";
    case leftDays < 7:
      return "warning";
    default:
      return null;
  }
};

const Tr = styled.tr`
  background-color: ${(props) =>
    props.theme[props.barColor + "Color"]} !important;
`;

const TaskList = (props) => (
  <StyledTableList2>
    <TableSortable
      headers={[
        { name: "L.p." },
        { name: "Tytuł", prop: "title" },
        { name: "Termin", prop: "date_end" },
      ]}
      data={props.data || []}
      render={(sortedData) => {
        return sortedData.map((item, index) => (
          <Tr
            key={index}
            barColor={trBackgroundColor(item.date_end, {
              initialDate: props.initialDate,
            })}
          >
            <td>{index + 1}</td>
            <td>
              <Link to={item.url ? item.url : "/404"}>{item.title}</Link>
            </td>
            <td>
              {item.date_end
                ? moment(item.date_end).format("DD.MM.YYYY")
                : null}
            </td>
          </Tr>
        ));
      }}
    />
  </StyledTableList2>
);

export const taskWidget = function (taskData) {
  return {
    id: 7,
    title: "Zadania",
    content: <TaskList data={taskData} />,
  };
};

export default TaskList;
