import React from "react";
import styled from "styled-components";
import {
  AlphaPicker,
  BlockPicker,
  ChromePicker,
  CirclePicker,
  CompactPicker,
  GithubPicker,
  HuePicker,
  MaterialPicker,
  PhotoshopPicker,
  SketchPicker,
  SliderPicker,
  SwatchesPicker,
  TwitterPicker,
} from "react-color";

import { StyledInputMessage } from "style/styleComponents/Form/input";

let StyledSwatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgb(204, 204, 204);
  display: inline-block;
  cursor: pointer;
`;

let StyledColor = styled.div`
  width: 36px;
  height: 24px;
  border-radius: 2px;
  background: #000;
`;

let StyledPopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

export default class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: "#000000",
  };

  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: "#000000",
    };
  }

  componentDidUpdate() {
    if (this.props.value != this.state.color) {
      this.setState({ color: this.props.value });
    }
  }

  handleClick = () => {
    if (this.props.disabled) return;
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.props.onChange(color.hex);
    this.setState({ color: color.hex, displayColorPicker: false });
  };

  render() {
    let { helperText, disabled } = this.props;

    return (
      <div>
        <StyledSwatch onClick={this.handleClick}>
          <StyledColor style={{ background: this.state.color }} />
        </StyledSwatch>
        {this.state.displayColorPicker ? (
          <StyledPopOver>
            <div onClick={this.handleClose} />
            <GithubPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </StyledPopOver>
        ) : null}
        <StyledInputMessage>{helperText}</StyledInputMessage>
      </div>
    );
  }
}
