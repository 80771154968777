import React from "react";
import { connect } from "react-redux";

import Form from "components/RiskAssessment/RiskAssessment.Form";

import {
  addMethod,
  getBusinessMethod,
  saveMethod,
  saveBusinessMethod,
} from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import { setUrls } from "./_staticData";

class Edit extends AppComponent {
  constructor(props) {
    super(props);

    let { urls, names = {} } = this.props;
    this.state = { ...this.state, urls: setUrls(urls) };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    let { method_id } = match.params;
    getBusinessMethod(method_id, { readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  handleSubmit(values) {
    const { dispatch, history, match } = this.props;
    let { method_id } = match.params;

    saveBusinessMethod(
      method_id,
      values
    )(dispatch).then(this.handleServerResponse());
  }

  render() {
    const { message, urls } = this.state;

    let {
      riskAssessment: { raMethod },
    } = this.props;

    const readOnly = Boolean(raMethod.business_id === 0);

    return (
      <>
        <Form
          message={message}
          cancelUrl="/business/orz"
          data={raMethod}
          handleSubmit={this.handleSubmit}
          urls={urls}
          readOnly={readOnly}
        />
      </>
    );
  }
}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(Edit);
