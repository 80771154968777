import styled from "styled-components";
import { media } from "style/style-utils/index";
import { isBrowser, isMobile } from "react-device-detect";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { Button } from "components/Buttons";
export const StyledInputMessage = styled.span`
  color: #dc453c;
`;

export let SelectButton = styled(Button)``;

export const SelectWrapper = styled.div`
  & > .btn-group {
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    ${(props) =>
      props.pullRight &&
      `
        posision:relative
      `}
    & button {
      width: ${(props) => (props.fullWidth ? "100%" : "auto")};
      white-space: normal;
      // ${
        isMobile
          ? `
      //   height:36px;
      //   line-height:24px;
      // `
          : ""
      }
      // ${media.sm`
      //   height:36px;
      //   line-height:24px;
      // `}
    }
    & > ul.dropdown-menu {
      width: ${(props) => (props.fullWidth ? "100%" : "auto")};
      text-align: ${(props) => (props.centerOptions ? "center" : "left")};
      max-height: 300px;
      overflow-y: auto;

      //scroll
      &::scrollbar {
        width:10px
      };
      &::scrollbar-track {
        background: #f1f1f1; 
      }
      &::scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }





      ${(props) =>
        props.pullRight &&
        `
        posision:absolute;
        right:0;
        left:auto;
        z-index:9000;
      `}
    }
  }
`;

//TODO: powoduje zwężenie
export const SelectListWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  min-width: 320px;

  ${(props) => (props.slideUp ? `bottom: 100%;` : `top: 100%;`)}
`;

export const UlWrapper = styled.div`
  overflow: hidden;
  max-height: 0;
  z-index: 1000;

  &.slide-enter {
    display: block;
    max-height: 0;

    &-done {
      display: block;

      ${(props) =>
        props.listMaxHeight > 400
          ? `overflow-y: scroll;`
          : "overflow-y: visible;"}
      
      /* max-height: ${(props) =>
        props.length ? `${props.length * 26 + 14}` : "0"}px; */
      max-height: ${(props) =>
        props.length ? `${props.listMaxHeight + 14}` : "0"}px;
      ${media.sm`
        max-height: ${(props) =>
          props.length ? `${props.length * 36 + 14}` : "0"}px;
      `}
      transition: max-height 0.1s linear;
    }
  }

  &.slide-exit {
    overflow: hidden;
    display: block;
    max-height: ${(props) =>
      props.length ? `${props.length * 26 + 14}` : "0"}px;
    ${media.sm`
      max-height: ${(props) =>
        props.length ? `${props.length * 36 + 14}` : "0"}px;
    `}
    &-done {
      display: block;
      overflow: hidden;
      transition: max-height 0.05s linear;
      z-index: 999;
    }
  }
`;

export const Ul = styled.ul`
  &.dropdown-menu {
    display: block;
    position: relative;
    min-width: 100%;
    top: 0;
    /* height: 100px; */
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding-left: ${(props) => 10 * (props.level || 0)}px;
  white-space: normal;
  text-overflow: ellipsis;
  // max-width: 100%;
  background-color: ${(props) => (props.selected ? "inherit" : "#e8e8e8")};
  .dropdown-menu > & > a {
    cursor: ${(props) => (props.selected ? "pointer" : "not-allowed")};
    background-color: ${(props) => (props.color ? "inherit" : props.color)};
    ${isMobile
      ? `
      line-height:30px;
    `
      : ""}
    ${media.sm`
      line-height:30px; 
    `}
  }
`;
StyledMenuItem.displayName = "StyledMenuItem";
