export function getAccountDashboardSettings() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_DASHBOARD_SETTINGS_REQUEST",
        "GET_DASHBOARD_SETTINGS_SUCCESS",
        "GET_DASHBOARD_SETTINGS_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-users-settings",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDaysFromLastIncident() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_LAST_DAYS_REQUEST",
        "GET_INCIDENT_LAST_DAYS_SUCCESS",
        "GET_INCIDENT_LAST_DAYS_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-days-count",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getTaskToDashboard() {
  return (dispatch) =>
    dispatch({
      types: ["GET_TASKS_REQUEST", "GET_TASKS_SUCCESS", "GET_TASKS_ERROR"],
      payload: {
        request: {
          url: "/dashboard/get-tasks",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentStatistics() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_DASHBOARD_REQUEST",
        "GET_INCIDENT_DASHBOARD_SUCCESS",
        "GET_INCIDENT_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-incident-statistic/1",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getIncidentStatisticsDetail(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INCIDENT_DASHBOARD_REQUEST",
        "GET_INCIDENT_DASHBOARD_SUCCESS",
        "GET_INCIDENT_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-incident-statistic/1",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getPotentiallyIncidentStatistics() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_POTENTIALLY_INCIDENT_DASHBOARD_REQUEST",
        "GET_POTENTIALLY_INCIDENT_DASHBOARD_SUCCESS",
        "GET_POTENTIALLY_INCIDENT_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-incident-statistic/2",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getPotentiallyIncidentStatisticsDetail(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_POTENTIALLY_INCIDENT_DASHBOARD_REQUEST",
        "GET_POTENTIALLY_INCIDENT_DASHBOARD_SUCCESS",
        "GET_POTENTIALLY_INCIDENT_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-incident-statistic/2",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getCalendarEvents(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_CALENDAR_DASHBOARD_REQUEST",
        "GET_CALENDAR_DASHBOARD_SUCCESS",
        "GET_CALENDAR_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-calendar-events",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function changeWidgetOrder(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SET_CALENDAR_ORDERING_DASHBOARD_REQUEST",
        "SET_CALENDAR_ORDERING_DASHBOARD_SUCCESS",
        "SET_CALENDAR_ORDERING_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/set-widget-ordering",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getTasksCompleteStatistic() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_TASK_STATISTIC_DASHBOARD_REQUEST",
        "GET_TASK_STATISTIC_DASHBOARD_SUCCESS",
        "GET_TASK_STATISTIC_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-task-statistic",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getNewTask() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NEW_TASK_DASHBOARD_REQUEST",
        "GET_NEW_TASK_DASHBOARD_SUCCESS",
        "GET_NEW_TASK_DASHBOARD_ERROR",
      ],
      payload: {
        request: {
          url: "/dashboard/get-new-task",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getModule7DeadlineCount() {
  return (dispatch) =>
    dispatch({
      type: "GET_MODULE7_DEADLINE_COUNT",
      payload: {
        request: {
          url: "/dashboard/get-module7-deadline-count",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getModule7DeadlineCountDetail(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_MODULE7_DEADLINE_COUNT",
      payload: {
        request: {
          url: "/dashboard/get-module7-deadline-count",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function dashboardCleaner(message) {
  return (dispatch) =>
    dispatch({
      type: "CLEAN_DASHBOARD",
      payload: { data: { message } },
    });
}
