import React, { Fragment } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import ModalSchema from "components/Modal/./_SchemaAppComponent";
import { FieldArrayComponent } from "components/Fields/index";
import ModalSimpleAccept from "components/Modal/SimpleAccept";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";

const FlexLine = styled.div`
  display: flex;
`;

const LineButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px !important;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;

export default class SimpleAcceptModalDump extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modalWitnessListDelete: {
        open: false,
      },
    };

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {}

  accept() {
    const { accept = () => {}, closeModal } = this.props;

    accept();
    closeModal();
  }

  openAddModal() {
    let { openAddUser } = this.props;
    openAddUser();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  submit(values) {}

  render() {
    const {
      children,
      data = [],
      handleAccept,
      open,
      title,
      getUser,
      user,
      readOnly,
      index,
      incident_id,
      switchModal = () => {},
      deleteWitness,
    } = this.props;

    let initialValues = { witnesses: data };
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>Informacje od świadków wypadku</span>
              <Button
                bsStyle="danger"
                id="ModalListClose"
                onClick={this.closeModal}
              >
                X
              </Button>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {deleteWitness}
            <Form
              initialValues={initialValues}
              onSubmit={this.submit}
              mutators={{ ...arrayMutators }}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <h4>Lista świadków</h4>

                    <FieldArrayComponent
                      name={`witnesses`}
                      removeProp={(fields, index) => {
                        let callback = () => {
                          let aaa = fields[index];
                          let { id } = fields.value[index];
                          deleteWitness(id, () => {
                            //odświeżenie w reducer wystarczy
                            // fields.remove(index);
                          });
                        };
                        this.setState({
                          ...this.state,
                          modalWitnessListDelete: {
                            ...this.state.modalWitnessListDelete,
                            open: true,
                            title: `Czy chcesz usunąć informację świadka ${
                              index + 1
                            }?`,
                            callback,
                          },
                        });
                      }}
                      render={({ name, index }) => {
                        return (
                          <div>
                            <LineButton
                              onClick={() => {
                                switchModal(index);
                                this.closeModal();
                              }}
                            >
                              Informacja świadka {index + 1}
                            </LineButton>
                          </div>
                        );
                      }}
                      renderButton={(addField) => {
                        return (
                          <AddButtonWrapper onClick={addField}>
                            <Button>Dodaj świadka </Button>
                          </AddButtonWrapper>
                        );
                      }}
                    />
                  </form>
                );
              }}
            />
            <ModalSimpleAccept
              open={this.state.modalWitnessListDelete.open}
              title={this.state.modalWitnessListDelete.title}
              handleAccept={() => {
                this.state.modalWitnessListDelete.callback();
                this.setState({
                  ...this.state,
                  modalWitnessListDelete: {
                    ...this.state.modalWitnessListDelete,
                    open: false,
                    callback: undefined,
                  },
                });
              }}
              autoAcceptClose={false}
              handleClose={() => {
                this.setState({
                  ...this.state,
                  modalWitnessListDelete: {
                    ...this.state.modalWitnessListDelete,
                    open: false,
                    callback: undefined,
                  },
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </StyledModal>
      </div>
    );
  }
}
