import React from "react";
import { connect } from "react-redux";

import { Add } from "../EntryAddEdit/Basic";
import { setUrls } from "./_staticData";
import SideMenu from "./_SideMenu";

class BasicR extends Add {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls(), readOnlyState: true };
  }

  renderSideMenu() {
    let {
      riskAssessment: { data },
      match,
    } = this.props;
    let { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }
}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(BasicR);
