import React from "react";
import { connect } from "react-redux";

import {
  addRAControlList,
  getControlListList,
  getRAControlList,
  deleteRAControlList,
  getRAControlListQuestions,
  saveRAControlListQuestions,
  addRACLQuestionAttachment,
  deleteRACLQuestionAttachment,
  download,
  setStageRA,
  getRA,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import Danger from "components/Danger";
import SideMenu from "../_SideMenu";
import { setUrls } from "../../_staticData";

export class DangerList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalControlForm: {
        open: false,
      },
      urls: setUrls(),
    };

    this.addNewDanger = this.addNewDanger.bind(this);
    this.deleteDanger = this.deleteDanger.bind(this);
    this.getRaControlListQuestion = this.getRaControlListQuestion.bind(this);
    this.saveQuestions = this.saveQuestions.bind(this);
    this.setNextStage = this.setNextStage.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;

    let { id } = match.params;
    getControlListList({ readOnly: true })(dispatch);
    getRAControlList(id, { readOnly: true })(dispatch);
    getRA(id, { readOnly: true })(dispatch).then(this.handleServerResponse());
  }

  addNewDanger(id) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;
    addRAControlList(ra_id, { id })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        getRAControlList(ra_id)(dispatch);
        this.setSuccessMessage(action.payload.data.message);
      })
    );
  }

  getRaControlListQuestion(id) {
    let { dispatch } = this.props;
    getRAControlListQuestions(id, { readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  saveQuestions(data, callback = () => {}) {
    let { match, dispatch } = this.props;
    let { id } = match.params;

    let segregateInObj = Object.keys(data)
      .filter((key) => /^question\_[0-9]{1,}\_[A-Za-z]+/.test(key))
      .reduce((obj, key) => {
        let keySplited = key.split("_");
        let id = keySplited[1];
        let prop = keySplited[2];
        if (!obj[id]) obj[id] = {};
        obj[id][prop] = data[key];
        return obj;
      }, {});

    let sendData = Object.keys(segregateInObj).map((key) => {
      return { ...segregateInObj[key], id: parseInt(key) };
    });

    saveRAControlListQuestions(id, { questions: sendData })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        callback();
      })
    );
  }

  deleteDanger(id) {
    const { dispatch } = this.props;
    deleteRAControlList(id)(dispatch).then(this.handleServerResponse());
  }

  setNextStage() {
    let { urls } = this.state;
    let { dispatch, match, history } = this.props;
    let { id } = match.params;

    setStageRA(
      id,
      2
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.CHARACTERISTIC(id));
      })
    );
  }

  renderSideMenu() {
    const { match, permission, ra: data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }

  render() {
    let { message, urls } = this.state;
    let { controlList, match, data, questions, permission, ra } = this.props;
    const readOnly = Boolean(ra.business_id === 0);

    return (
      <Danger
        addAttachmentAction={addRACLQuestionAttachment}
        addNewDanger={this.addNewDanger}
        downloadAction={download}
        controlList={controlList}
        data={data}
        deleteAttachmentAction={deleteRACLQuestionAttachment}
        deleteDanger={this.deleteDanger}
        getQuestions={this.getRaControlListQuestion}
        permission={permission}
        id={match.params.id}
        message={message}
        questions={questions}
        readOnly={readOnly}
        saveQuestions={this.saveQuestions}
        setNextStage={this.setNextStage}
        SideMenu={this.renderSideMenu()}
        stage={ra.stage}
        urls={urls}
      />
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  ra: store.riskAssessment.singleData,
  answers: store.riskAssessment.answers,
  controlList: store.riskAssessment.controlList,
  data: store.riskAssessment.raControlList,
  permission: store.riskAssessment.permission,
  questions: store.riskAssessment.questions,
}))(DangerList);
