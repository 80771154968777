import React from "react";
import { connect } from "react-redux";

import { RAAnalysis } from "../EntryAddEdit/Analysis";
import { setUrls } from "./_staticData";

class Analysis extends RAAnalysis {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls(), readOnlyState: true };
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
  permission: store.riskAssessment.permission,
  controlList: store.riskAssessment.raControlList,
  modelHazardList: store.riskAssessment.modelHazardList,
  hazards: store.riskAssessment.raHazards,
  method: store.riskAssessment.raMethod,
}))(Analysis);
