import React, { PureComponent, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { Form, Field, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import { Button } from "components/Buttons";
import { FieldInput, FieldTextareaBox, FieldSelect } from "components/Fields";
import {
  ButtonWrapper,
  LeftPadding,
  TwoInputWrapper,
} from "components/Wrappers";
import { StyledInputBox } from "style/styleComponents/Form/input";
import AppComponent from "components/AppComponent";
import GetDangerous from "./GetDangerous";
import SaveAsTemplateModal from "./SaveAsModal";

import RegisterMethodCalc from "./RegisterMethodCalc";
import AutoSave from "./AutoSave";

let GetLine = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
    &:nth-child(1) {
      flex: 1 1 70%;
    }
    &:nth-child(2) {
      flex: 1 1 30%;
    }
  }
`;

let ButtonWrapperPadding = styled.div`
  padding-top: 20px;
`;

let CorrectionLineWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
    &:nth-child(1) {
      flex: 1 1 20%;
    }
    &:nth-child(2) {
      flex: 1 1 80%;
    }
  }
`;

let setInitialValues = (data) => {
  let { parameters1, parameters2, ...rest } = data;
  function formParameters(parameters = [], name) {
    return parameters.reduce((obj, i) => {
      obj[`${name}_${i.parameter_id}`] = {
        id: i.id,
        variable_id: i.variable_id,
        description: i.description,
      };

      return obj;
    }, {});
  }

  let result = {
    ...rest,
    ...formParameters(parameters1, "parameters1"),
    ...formParameters(parameters2, "parameters2"),
  };
  return result;
};

const RegisterComponent = (props) => {
  // const [variableValue1, setVariableValue1] = useState({});
  // const [variableValue2, setVariableValue2] = useState({});
  const [data, setData] = useState({});

  const setHazard = useCallback(
    (item, form) => {
      return () => {
        let { getGetHazard, handleServerResponse, dispatch } = props;
        let { id } = item;

        getGetHazard(id, {
          readOnly: true,
        })(dispatch).then((action) => {
          const hazard = action.payload.data.data;
          //Note: jeśli consequences jest poza for nie działa powód: nie wiem
          form.change("consequences", hazard["consequences"]);
          for (let i of ["consequences", "name", "prevention", "source"]) {
            form.change(i, hazard[i]);
          }
        });
      };
    },
    [
      props.getGetHazard,
      props.dispatch,
      props.handleServerResponse,
      props.hazard,
    ]
  );

  const handleSaveHazard = useCallback(
    (data) => {
      let { saveHazard } = props;
      let newData = Object.keys(data).reduce((obj, key) => {
        if (/^parameters[0-9]{1,}\_[0-9]{1,}/.test(key)) {
          let parts = key.split("_");
          if (!obj[parts[0]]) {
            obj[parts[0]] = [];
          }
          //
          obj[parts[0]].push({
            ...data[key],
          });
        } else {
          obj[key] = data[key];
        }
        return obj;
      }, {});
      saveHazard(newData);
    },
    [props.saveHazard]
  );

  let {
    modelHazardList,
    data: propData,
    deleteRAAnalysisHazard,
    method,
    saveModelHazard,
  } = props;

  let { parameters = [], numberMethod = {} } = method;
  let { mathematical_formula, risk_level_description } = numberMethod;

  parameters = parameters || [];
  return (
    <>
      <Form
        onSubmit={handleSaveHazard}
        // mutators={{ ...arrayMutators }}
        subscribtion={{ pristine: true }}
        initialValues={setInitialValues(data.id ? data : propData)}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <AutoSave
                debounce={1000}
                // setFieldData={form.mutators.setFieldData}
                // values={values}
                save={handleSaveHazard}
              />
              <TwoInputWrapper>
                <FieldInput name="lp" label="L.p." disabled />
              </TwoInputWrapper>
              <GetLine>
                <FieldInput name={"name"} />
                <FormSpy
                  subscribe={{
                    form: true,
                  }}
                >
                  {({ form }) => (
                    <GetDangerous
                      setHazard={setHazard}
                      modelHazardList={modelHazardList}
                      form={form}
                    />
                  )}
                </FormSpy>
              </GetLine>
              <FieldTextareaBox
                name="source"
                label="Źródło (przyczyny) zagrożenia"
              />
              <FieldTextareaBox
                name="consequences"
                label="Możliwe skutki zagrożenia"
              />
              <h4>Przed korektą</h4>
              <LeftPadding>
                <RegisterMethodCalc
                  name={"parameters1"}
                  parameters={parameters}
                  method={method}
                  mathematical_formula={mathematical_formula}
                  risk_level_description={risk_level_description}
                />
              </LeftPadding>
              <FieldTextareaBox
                name="prevention"
                label="Środki profilaktyczne"
              />
              <h4>Po korekcie</h4>
              <LeftPadding>
                <RegisterMethodCalc
                  name={"parameters2"}
                  parameters={parameters}
                  method={method}
                  mathematical_formula={mathematical_formula}
                  risk_level_description={risk_level_description}
                />
              </LeftPadding>

              <FormSpy
                subscribe={{
                  form: true,
                  values: true,
                }}
              >
                {({ values }) => {
                  return (
                    <ButtonWrapperPadding>
                      <ButtonWrapper>
                        <SaveAsTemplateModal
                          saveModelHazard={saveModelHazard}
                          data={values}
                        />
                        <Button
                          type="button"
                          onClick={deleteRAAnalysisHazard(propData.id)}
                        >
                          Usuń zagrożenie
                        </Button>
                      </ButtonWrapper>
                    </ButtonWrapperPadding>
                  );
                }}
              </FormSpy>
            </form>
          );
        }}
      />
    </>
  );
};

class Register extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      variableValue1: {},
      variableValue2: {},
      data: undefined,
    };

    this.handleServerResponse = this.handleServerResponse.bind(this);
    // this.setHazard = this.setHazard.bind(this);
    // this.saveHazard = this.saveHazard.bind(this);
    // this.updateVariable = this.updateVariable.bind(this);
  }

  render() {
    return (
      <RegisterComponent
        handleServerResponse={this.handleServerResponse}
        {...this.props}
      />
    );
  }
}

export default connect((store) => ({
  hazard: store.riskAssessment.raHazard,
}))(Register);
