import React from "react";
import { connect } from "react-redux";

import {
  saveRAAsDraft,
  getRADrafts as getRAs,
  clearRiskAssessment,
  deleteRADraft as deleteRA,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import LoadingScreen from "components/LoadingScreen";
import PageHeader from "components/PageHeader";
import { LayoutContent } from "components/Layout";
import { ButtonWrapper, ButtonWithIcon } from "components/ListComponents";
import { LinkContainer } from "components/LinkContainer";
import styled from "styled-components";
import { StyledTableList } from "components/ListComponents";
import TableSortable from "components/TableSortable";

import { Link } from "react-router-dom";
import { DeleteButton } from "components/ListComponents";
import SideMenu from "../_SideMenu";
import Pagination from "components/Pagination";
import Modal from "components/Modal/SimpleAccept";
import { setUrls } from "../_staticData";
const StyledTableWrapper = styled.div`
  overflow-x: auto;
`;

class RADraftList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: [],
      modal: {
        open: false,
        entry_id: null,
      },
      urls: setUrls(),
    };

    // this.handleDelete = this.handleDelete.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changePage = this.changePage.bind(this);
    this.deleteRA = this.deleteRA.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;
    getRAs({ readOnly: true })(dispatch).then(this.handleServerResponse());
  }

  deleteRA() {
    const { dispatch } = this.props;
    const {
      modal: { entry_id },
    } = this.state;
    deleteRA(entry_id)(dispatch).then(this.handleServerResponse());
  }

  openModal(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.modal, open: true, entry_id } });
    };
  }

  closeModal() {
    this.setState({
      modal: { ...this.state.modal, open: false, entry_id: null },
    });
  }

  changePage(data) {
    this.setState({ data });
  }

  saveDraft(data) {
    let { dispatch } = this.props;
    // saveRAAsDraft()(dispatch).then(this.handleServerResponse());
  }

  render() {
    const {
      // audit: { typeData = [], permission },
      riskAssessment: { permission },
      data = [],
    } = this.props;
    const { modal, message, urls } = this.state;
    return (
      <LoadingScreen fetched={true}>
        <LayoutContent
          message={message}
          error404={this.state.error_code == 404}
          SideMenu={<SideMenu permission={permission} hide />}
        >
          <PageHeader>Szablony wzorcowych ocen ryzyka zawodowego</PageHeader>
          <ButtonWrapper>
            <LinkContainer to={urls.DRAFTADD}>
              <ButtonWithIcon
                disabled={!permission[26]}
                icon="fa fa-plus"
                name="Nowa ocena ryzyka"
              />
            </LinkContainer>
          </ButtonWrapper>
          <StyledTableWrapper>
            <StyledTableList>
              <TableSortable
                headers={[
                  { name: "L.p." },
                  { name: "Nazwa", prop: "title" },
                  { name: "Usuń" },
                ]}
                data={data}
                render={(sortedData) => {
                  return sortedData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={urls.DRAFTBASIC(item.id)}>{item.name}</Link>
                      </td>
                      <td>
                        <DeleteButton
                          onClick={this.openModal(item.id)}
                          disabled={!permission[28] || item.business_id === 0}
                        />
                      </td>
                    </tr>
                  ));
                }}
              ></TableSortable>
            </StyledTableList>
          </StyledTableWrapper>
          <Pagination handlePageChange={this.changePage} data={data} />
        </LayoutContent>
        <Modal
          title="Usuń metody oceny ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modal.open}
          handleAccept={this.deleteRA}
          handleClose={this.closeModal}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  incident: store.incident,
  riskAssessment: store.riskAssessment,
  data: store.riskAssessment.raDraftList,
}))(RADraftList);
