import React from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import setFieldData from "final-form-set-field-data";
import Button from "react-bootstrap/lib/Button";

import { getLocalizationsOnly } from "actions/localizationActions";
import { getUserSections } from "actions/sectionActions";
import { getSearchedWorkplaceInitial } from "actions/workplaceActions";
import { getDictionary } from "actions/dictionaryActions";
import { getUsersNames } from "actions/userActions";

import validator, { composeValidators } from "components/Validation/";
import AutoSendInput from "components/AutoSendInput";
import {
  addAttachmentRegister,
  clearIncidentReducer,
  deleteAttachmentRegister,
  getRegisterAttachments,
  getIncidentToRegister,
  updateField,
  register,
  registerSave,
} from "actions/incidentActions";

import AttachmentList from "components/AttachmentList";
import AutoSave from "lib/React-Final-Form/AutoSave";
import Box from "components/Box";
import { ButtonWrapper } from "components/ListComponents";
import dataToFormData from "components/dataToFormData";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { setMessage } from "components/AppComponent";
import Select from "components/Select";
import styled from "styled-components";
import {
  StyledInputBox,
  StyledInputCheckBoxRadio,
  StyledTextareaBox,
  StyledLabel,
  StyledInputMessage,
} from "style/styleComponents/Form/input";
import { StyledTooltip } from "style/styleComponents/ToolTip";

import VictimSection from "./RegisterForm/victimSection";
import WitnessesSection from "./RegisterForm/witnessesSection";
import { TimeWrapper } from "./DefaultForm";
import IncidentDefaultComponent from "./defaultComponent";

import SideMenu from "./_SideMenu";
import { OnChange } from "react-final-form-listeners";

const SavingIndicator = ({ name }) => (
  <Field
    name={name}
    subscription={{ data: true }}
    render={({
      meta: {
        data: { saving },
      },
    }) => (saving ? <div className="saving">Saving</div> : null)}
  />
);
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const save = async (values) => {
  await sleep(1000);
};

const StyledInputCheckBoxWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  & > div {
    margin-right: 15px;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 5px;
`;

const StyledSavingBox = styled((props) => (
  <div className={props.className}>
    <i className="fa fa-spinner fa-spin" />
  </div>
))`
  position: absolute;
  background-color: rgba(213, 213, 213, 0.5);
  font-size: 36px;
  text-align: center;
  margin: -15px -15px 0;
  width: 100%;
  & > i {
    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: rotating 3s linear infinite;
  }
`;

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

class Register extends IncidentDefaultComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      accident: true,
      emptyRush: true,
      person_status: [],
      potential_protocol_number: null,
    };
    this.getSections = this.getSections.bind(this);
    this.register = this.register.bind(this);
    this.updateChanges = this.updateChanges.bind(this);
    this.setAccident = this.setAccident.bind(this);
    this.customInput = this.customInput.bind(this);

    this.setMessege = this.setMessege.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    const that = this;

    Promise.all([
      getIncidentToRegister(incident_id)(dispatch).then(
        this.handleServerResponse()
      ),
      getRegisterAttachments(incident_id)(dispatch).then(
        this.handleServerResponse()
      ),
      getLocalizationsOnly()(dispatch).then(this.handleServerResponse()),
      getSearchedWorkplaceInitial()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getDictionary("person-status")(dispatch).then(
        this.handleServerResponse({}, (action) => {
          this.setState({ person_status: action.payload.data.person_status });
        })
      ),
      getUsersNames()(dispatch).then(
        this.handleServerResponse({}, (action) => {})
      ),
    ]).then(() => {
      const {
        incident: {
          singleData: { localization_id, type_id },
        },
      } = this.props;
      if (localization_id) {
        getUserSections(localization_id)(dispatch).then(
          this.handleServerResponse()
        );
      }
      that.setState({ accident: type_id == 1 ? true : false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userID !== prevProps.userID) {
      this.fetchData(this.props.userID);
    }

    if (
      this.props.incident &&
      this.props.incident.singleData &&
      this.props.incident.singleData.potential_protocol_number &&
      this.props.incident.singleData.potential_protocol_number !==
        this.state.potential_protocol_number
    ) {
      this.setState({
        ...this.state,
        potential_protocol_number: this.props.incident.singleData
          .potential_protocol_number,
      });
    }
  }

  setAccident(bool) {
    this.setState({ accident: bool });
  }

  getSections(localizaction_id) {
    const { dispatch } = this.props;
    getUserSections(localizaction_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  updateChanges(form) {
    return (value, prev) => {
      const { dispatch, match } = this.props;
      const { incident_id } = match.params;
      const that = this;
      const Form = that.form;

      value = Object.keys(value).reduce(
        (obj, key) => {
          if (obj[key] === null) {
            obj[key] = "";
          }
          return obj;
        },
        { ...value }
      );

      if (value.type_id) {
        value.type_id = parseInt(value.type_id);
      }
      if (value && Object.keys(value).length) {
        if (!this.state.emptyRush) {
          updateField(
            incident_id,
            value
          )(dispatch).then(
            this.handleServerResponse({}, (action) => {
              const { formDataChange, message, error } = action.payload.data;
              if (error) {
                Object.keys(prev).forEach((key) => {
                  Form.form.mutators.setFieldData(key, formDataChange[key]);
                });
                Form.form.mutators.setFieldData("protocol_number");
                this.setState({
                  message: setMessage(action.payload.data.message, "danger"),
                });
              } else {
                Object.keys(formDataChange).forEach((key) => {
                  form.change(key, formDataChange[key]);
                });
                this.setState({ message: setMessage(null, "success") });
              }
            })
          );
        } else {
          this.state.emptyRush = false;
        }
      }
    };
  }

  register(values) {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    let { incident_has_person, ...data } = values;
    let {
      incident: { singleData },
    } = this.props;
    let action = singleData && singleData.stage ? registerSave : register;

    action(
      incident_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/incident");
      })
    );
  }

  setMessege(message) {}

  customInput({
    input: { onChange, ...inputRest },
    onChangeCallback = () => {},
    label,
    type,
    setAccident,
    meta: { touched, error },
    disabled,
  }) {
    return (
      <span>
        <input
          type={type}
          disabled={disabled}
          {...inputRest}
          onChange={(event) => {
            let value = event.target.value == 1;

            this.setAccident(event.target.value == 1);
            onChangeCallback();
            onChange(event);
          }}
        />
      </span>
    );
  }

  updateProtocolNumberOnSwitch(values, form) {
    return (value) => {
      if (
        !values.protocol_number &&
        values.type_id == 1 &&
        values.person &&
        values.person.person_status_id === 1
      ) {
        form.change("protocol_number", this.state.potential_protocol_number);
      }
    };
  }

  //TODO Must brak komunikatu validacji checkboxów
  render() {
    const { message } = this.state;
    const {
      dispatch,
      localization: { data: localizationData },
      section: { data: sectionData },
      incident: {
        singleData: incidentData,
        permission,
        fetchingInput,
        fetchingSave,
        attachments,
        attachmentAddFetching,
        incident_witnesses,
      },
      users: { autoCompleteUsers },
      match,
      workplace: { searchedWorkplaces },
    } = this.props;
    const { incident_id } = match.params;
    const readOnly = !permission[9];
    const { stage } = incidentData;
    if (Object.keys(incidentData).length) {
      if (incidentData.type_id) {
        incidentData.type_id = incidentData.type_id.toString();
      }
    }
    const { id, ...initialData } = incidentData;
    return (
      <LayoutContent
        mobileNotHideMenu={true}
        SideMenu={
          <SideMenu
            incident_id={incident_id}
            stage={incidentData.stage}
            permission={permission}
            addNewIncident={this.addNewIncident}
          />
        }
        message={message}
        error404={this.state.error_code == 404}
      >
        <Box title={"Zgłoszenie"}>
          <Form
            ref={(form) => (this.form = form)}
            onSubmit={this.register}
            initialValues={
              stage
                ? { ...initialData, incident_witnesses }
                : { ...initialData }
            }
            mutators={{ setFieldData, setField, ...arrayMutators }}
            // subscription={{} /* No need to subscribe to anything */}
            render={({
              handleSubmit,
              pristine,
              invalid,
              form,
              values,
              errors,
              touched,
            }) => {
              this.form = { form };
              return (
                <form onSubmit={handleSubmit}>
                  {incidentData.dictionary_progress_id == 4 && (
                    <AutoSave
                      debounce={500}
                      setFieldData={form.mutators.setFieldData}
                      values={values}
                      save={this.updateChanges(form)}
                    />
                  )}
                  {fetchingInput && <StyledSavingBox />}
                  <div>
                    <Field
                      name="reporting_person"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          {...input}
                          label={"Imię i nazwisko osoby zgłaszającej zdarzenie"}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          disabled={readOnly}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ marginBottom: "5px" }}>Typ zdarzenia</div>
                  <div>
                    <StyledInputCheckBoxWrapper>
                      <label
                        style={{
                          display: "block",
                          maxWidth: "auto",
                          marginBottom: "0",
                          fontWeight: "normal",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        <StyledTooltip
                          placement="bottom"
                          overlay={
                            <span>
                              Wypadek przy pracy - nagłe zdarzenie, wywołane
                              przyczyną zewnetrzną powodujące uraz lub śmierć,
                              które nastąpiło związku z pracą:
                              <ol>
                                <li>
                                  podczas lub w związku z wykonywaniem przez
                                  pracownika zwykłych czynności lub poleceń
                                  przełożonych
                                </li>
                                <li>
                                  podczas lub w związku z wykonywaniem przez
                                  pracownika czynności na rzecz pracodawcy,
                                  nawet bez polecenia
                                </li>
                                <li>
                                  w czasie pozostawania pracownika w dyspozycji
                                  pracodawcy w drodze między siedzibą pracodawcy
                                  a miejscem wykonywania obowiązku wynikającego
                                  ze sosunku pracy
                                </li>
                              </ol>
                            </span>
                          }
                        >
                          <span>
                            <Field
                              name="type_id"
                              validate={validator.required}
                              disabled={readOnly}
                              component={this.customInput}
                              type="radio"
                              value="1"
                              setAccident={this.setAccident}
                              onChangeCallback={() => {
                                form.change("personal", "true");
                              }}
                            />{" "}
                            Wypadek przy pracy
                          </span>
                        </StyledTooltip>
                      </label>

                      <label
                        style={{
                          display: "block",
                          maxWidth: "auto",
                          marginBottom: "0",
                          fontWeight: "normal",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        <StyledTooltip
                          placement="bottom"
                          overlay={
                            <span>
                              Zdarzenie potencjalnie wypadkowe - Niebezpieczne
                              zdarzenie związane z wykonywaną pracą, podczas
                              którego nie dochodzi do urazów lub pogorszenia
                              stanu zdrowia
                            </span>
                          }
                        >
                          <span>
                            <Field
                              name="type_id"
                              validate={validator.required}
                              disabled={readOnly}
                              component={this.customInput}
                              type="radio"
                              value="2"
                            />{" "}
                            Zdarzenie potencjalnie wypadkowe
                          </span>
                        </StyledTooltip>
                      </label>
                    </StyledInputCheckBoxWrapper>
                    {touched.type_id && errors.type_id && (
                      <StyledInputMessage>{errors.type_id}</StyledInputMessage>
                    )}
                    <SavingIndicator name="type_id" />
                  </div>
                  {this.state.accident ? (
                    <div>
                      <Field
                        name="protocol_number"
                        render={({ input, meta, ...rest }) => (
                          <>
                            <StyledInputBox
                              label="Kolejny numer protokołu powypadkowego w ciągu roku kalendarzowego"
                              disabled={readOnly}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              value={input.value}
                              onChange={(event) => {
                                input.onChange(event.target.value || null);
                              }}
                            />
                          </>
                        )}
                        type="text"
                      />
                      <SavingIndicator name="title" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <Field
                      name="title"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          label={"Tytuł zdarzenia"}
                          disabled={readOnly}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          value={input.value}
                          onChange={(event) => {
                            input.onChange(event);
                          }}
                        />
                      )}
                      type="text"
                    />
                    <SavingIndicator name="title" />
                  </div>
                  <div>
                    <Field
                      name="localization_id"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <InputWrapper>
                          <Select
                            label="Lokacja"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            disabled={readOnly}
                            fullWidth
                            title="-- wybierz --"
                            data={localizationData}
                            propValue={"id"}
                            selected={input.value}
                            onBlur={input.onBlur}
                            onFocus={input.onFocus}
                            handleChange={(value) => {
                              this.getSections(value);
                              input.onChange(value);
                            }}
                          />
                        </InputWrapper>
                      )}
                    />
                    <SavingIndicator name="title" />
                  </div>
                  <div>
                    <Field
                      name="section_id"
                      render={({ input, meta, ...rest }) => (
                        <InputWrapper>
                          <Select
                            label="Dział"
                            disabled={readOnly}
                            fullWidth
                            title="-- wybierz --"
                            propValue={"id"}
                            selected={input.value}
                            onBlur={input.onBlur}
                            onFocus={input.onFocus}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            data={[{ id: null, name: "Brak" }].concat(
                              sectionData
                            )}
                            handleChange={(value) => {
                              input.onChange(value);
                            }}
                          />
                        </InputWrapper>
                      )}
                    />
                    <SavingIndicator name="title" />
                  </div>
                  <StyledLabel>Data i godzina zdarzenia</StyledLabel>
                  <TimeWrapper readOnly={readOnly}></TimeWrapper>
                  <div>
                    <Field
                      name="description"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledTextareaBox
                          label="Opis zdarzenia, przyczyny, skutki"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          disabled={readOnly}
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                          value={input.value}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <StyledLabel>Czy w zdarzeniu brały udział osoby?</StyledLabel>
                  <div>
                    <Field
                      name="personal"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <div>
                          <StyledInputCheckBoxWrapper>
                            <StyledInputCheckBoxRadio
                              label="Tak"
                              disabled={readOnly || values.type_id == "1"}
                              value={"true"}
                              checked={input.value == "true"}
                              name="type"
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onChange={(event) => {
                                input.onChange("true");
                              }}
                            />
                            <StyledInputCheckBoxRadio
                              label="Nie"
                              disabled={readOnly || values.type_id == "1"}
                              value={"false"}
                              checked={input.value == "false"}
                              name="type"
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onChange={(event) => {
                                input.onChange("false");
                              }}
                            />
                          </StyledInputCheckBoxWrapper>
                          <StyledInputMessage>
                            {meta.error && meta.touched ? meta.error : null}
                          </StyledInputMessage>
                        </div>
                      )}
                    />
                  </div>
                  <VictimSection
                    readOnly={readOnly}
                    form={form}
                    values={values}
                    autoCompleteUsers={autoCompleteUsers}
                    searchedWorkplaces={searchedWorkplaces}
                    person_status={this.state.person_status}
                  />
                  <WitnessesSection
                    incident_id={incident_id}
                    readOnly={readOnly}
                    handleServerResponse={this.handleServerResponse.bind(this)}
                  />
                  {permission[9] && (
                    <AttachmentList
                      attachmentAddFetching={attachmentAddFetching}
                      dispatch={dispatch}
                      entry_id={incident_id}
                      attachments={attachments}
                      permission={permission}
                      addAttachmentAction={addAttachmentRegister}
                      deleteAttachmentAction={deleteAttachmentRegister}
                      setParentMessage={this.setMessege}
                      readOnly={!permission[9]}
                    />
                  )}
                  <FormSpy subscribe={{ form: true, values: true }}>
                    {({ form }) => {
                      return (
                        <>
                          <OnChange name="type_id">
                            {this.updateProtocolNumberOnSwitch(values, form)}
                          </OnChange>
                          <OnChange name="person.person_status_id">
                            {this.updateProtocolNumberOnSwitch(values, form)}
                          </OnChange>
                        </>
                      );
                    }}
                  </FormSpy>
                  <ButtonWrapper>
                    {readOnly ? (
                      <LinkContainer to={`/incident/analysis/${incident_id}`}>
                        <Button bsStyle="primary">Dalej</Button>
                      </LinkContainer>
                    ) : (
                      <Button
                        // disabled={pristine || invalid || fetchingSave}
                        type="submit"
                        bsStyle="primary"
                      >
                        {incidentData.dictionary_progress_id == 4
                          ? "Wyślij zgłoszenie"
                          : "Zapisz"}
                      </Button>
                    )}
                    <LinkContainer to={`/incident`} exact>
                      <Button>Powrót do listy</Button>
                    </LinkContainer>
                  </ButtonWrapper>
                </form>
              );
            }}
          />
        </Box>
      </LayoutContent>
    );
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    clearIncidentReducer()(dispatch);
  }
}

export default connect((store) => ({
  incident: store.incident,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  users: store.users,
}))(Register);
