import React from "react";
import validator from "./Validation";
import {LayoutContent} from "./Layout";
import PageHeader from "./PageHeader";
import {savePage} from "../actions/pageActions";
import {ButtonWrapper} from "./ListComponents";
import {StyledInputBox, StyledTextareaBox, StyledInputCheckBox} from "../style/styleComponents/Form/input";
import {Form, Field} from "react-final-form";
import LoadingScreen from "./LoadingScreen";
import SideMenu from "./SideMenu";
import {ButtonWithIcon} from "./ListComponents";
import Button from "react-bootstrap/lib/Button";
import Box from "./Box";
import Select from "./Select";
import TextEditor from "../lib/ReactQuill/index.js";
import {LinkContainer} from "./LinkContainer";

class PageForm extends React.Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const {handleSubmit} = this.props;

    const data = {...values,
      parent_page_id: values.parent_page_id == "0" ? null : values.parent_page_id
    };
    handleSubmit(data);
  }

  render() {
    const {title = "Dodaj stronę",submitTitle= "Dodaj",data, message,parentPages} = this.props;
    return (
      <LayoutContent
        SideMenu={(
          <SideMenu
            title="Strony statyczne"
            data={[
              {name: "Lista stron", url: "/super-admin/page"},
              {name: "Dodaj stronę", url: "/super-admin/page/add"}
            ]}
          />
        )}
        message={message}
      >
        <Box title={title}>
          <Form
            ref={form => this.form = form}
            initialValues={data}
            onSubmit={this.submit}
            render={({handleSubmit, pristine, invalid}) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="name"
                  validate={validator.required}
                  render={({input, meta, ...rest}) => (
                    <StyledInputBox
                      label="Tytuł"
                      value={input.value}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : null}
                      onChange={event => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}/>
                <Field
                  name="url"
                  validate={validator.required}
                  render={({input, meta, ...rest}) => (
                    <StyledInputBox
                      label="Url"
                      value={input.value}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : null}
                      onChange={event => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}/>
                <Field
                  name="content"
                  render={({input, meta, ...rest}) => (
                    <TextEditor
                      label="Treść"
                      value={input.value}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : null}
                      onChange={value => {
                        input.onChange(value);
                      }} />
                  )}/>
                <Field
                  name="parent_page_id"
                  render={({input, meta, ...rest}) => (
                    <Select
                      label="Strona nadrzędna"
                      title={"- wybierz -"}
                      id="superior"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : null}
                      propValue={"id"}
                      selected={input.value}
                      data={[{id: "0", name: "Brak"}].concat(parentPages)}
                      handleChange={value => {
                        input.onChange(value);
                      }}
                    />
                  )}/>
                <Field
                  name="no"
                  validate={validator.required}
                  render={({input, meta, ...rest}) => (
                    <StyledInputBox
                      label="Kolejność"
                      value={input.value}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : null}
                      onChange={event => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}/>
                <Field
                  name="contact_form"
                  render={({input, meta, ...rest}) => (
                    <StyledInputCheckBox
                      {...input}
                      label="Formularz kontaktowy"
                      value={input.value}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : null}
                      onChange={event => {
                        input.onChange(event.target.checked);
                      }}
                      checked={input.value}
                    />
                  )}/>
                <ButtonWrapper>
                  <Button type="submit" bsStyle="primary">{submitTitle}</Button>
                  <LinkContainer to="/super-admin/page" exact>
                    <Button >Anuluj</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}/>
        </Box>
      </LayoutContent>
    );
  }
}

export default PageForm