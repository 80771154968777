import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Image from "react-bootstrap/lib/Image";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Nav from "react-bootstrap/lib/Nav";
import Navbar from "react-bootstrap/lib/Navbar";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import { media } from "../../style/style-utils";
import { Link } from "react-router-dom";
import { bootstrapUtils } from "react-bootstrap/lib/utils";
import { withTheme } from "styled-components";
import logo from "../../style/image/HSQ_net_logo.png";
import { isBrowser, isMobile } from "react-device-detect";
import { LinkContainer } from "../LinkContainer";
bootstrapUtils.addStyle(Image, "avatar");

export class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <span onClick={this.handleClick} style={{ textAlign: "center" }}>
        {this.props.children}
      </span>
    );
  }
}

export const CustomMenuItem = styled(MenuItem)`
  ${media.xs`
    text-align:center;
  `}
`;

export const MobileDropDownToggle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  width: 64px;
  display: ${isMobile ? "flex" : "none"} !important;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.primaryTextColor};
  border-radius: 4px;
  color: ${(props) => props.theme.primaryTextColor};

  ${media.xs`
      display: flex !important;
  `}
`;

export const CustomStyledNavItem = styled.li`
  ${(props) =>
    props.pullRight
      ? `
    align-self: flex-end;
    margin-left:auto;
  `
      : ""}
  cursor: pointer;
  text-align: ${(props) => (props.pullRight && !isMobile ? "right" : "left")};
  padding: 8px 15px;
  ${(props) => (props.account ? "display:none;" : false)}
  ${(props) => (props.hoverItem ? "" : "height:66px;")}
  ${isMobile ? "width:100%;" : ""}
  ${media.sm`
    text-align:left;
  `}
  ${media.xs`
    text-align:left;
  `}
  color: ${(props) => props.theme.primaryTextColor};
  &:hover {
    color: ${(props) => props.theme.primaryLighterColor};
  }
  i {
    ${isMobile
      ? `
      text-align:center;
      width:50px;
    `
      : ""}
    ${media.xs`
      text-align:center;
      width:50px;
    `}
  }
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 80px;
`;

export const CustomStyledNavItem2 = styled.div`
  ${(props) =>
    props.pullRight
      ? `
    align-self: flex-end;
    margin-left:auto;
  `
      : ""}
  cursor: pointer;
  text-align: ${(props) => (props.pullRight && !isMobile ? "right" : "left")};
  ${(props) =>
    props.account && !isMobile ? "padding: 0px 15px;" : "padding: 8px 15px;"}
  ${media.xs`
    padding: 8px 15px;
  `}
  ${(props) => (props.hoverItem ? "" : "height:66px;")}
  ${isMobile ? "width:100%;" : ""}
  ${media.sm`
    text-align:left;
  `}
  ${media.xs`
    text-align:left;
  `}
  color: ${(props) => props.theme.primaryTextColor};
  &:hover {
    color: ${(props) => props.theme.primaryLighterColor};
  }
  i {
    ${isMobile
      ? `
      text-align:center;
      width:50px;
    `
      : ""}
    ${media.xs`
      text-align:center;
      width:50px;
    `}
  }
  width: 100%;
`;

export const NavItemHoverNavWrapperMobileStyle = `
  width:100%;
  & > a {
    width:100%;
  }
  & .dropdown {    
    position: relative;
    top: auto;
  }
`;
export const NavItemHoverNavWrapper = styled.li`
  ${(props) =>
    props.pullRight
      ? `
    align-self: flex-end;
    margin-left:auto;
  `
      : ""}
  position: relative;
  flex-wrap: wrap;
  & .dropdown {
    position: absolute;
    top: calc(100% - 3px);
    ${(props) => (props.pullRight ? "right:15px;" : "")}
  }
  ${isMobile ? NavItemHoverNavWrapperMobileStyle : ""}
  ${media.xs`${NavItemHoverNavWrapperMobileStyle}`}
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 80px;
`;

export const NavHead = styled.div`
  width: 100%;
  position: relative;
  ${isMobile ? "width:100%;" : ""}
  ${media.sm`
    width:100%;
  `}
  ${media.xs`
      pointer-events: auto;
  `}
`;

export const NavDropboxContainer = styled.ul`
  padding: 0;
  list-style: none;
  ${media.xs`
    padding: 0 15px;
    width:100%;
  `}
  ${isMobile
    ? `
    padding: 0 15px;
    width:100%;
  `
    : ""}
  & > li {
    left: 16px;
    ${isMobile
      ? `
      left: 0;
    `
      : ""}
    ${media.sm`
      left: 0;
    `}
  }
`;

export const NavHeadTitle = styled.div`
  display: ${(props) => (isMobile || props.account ? "block" : "none")};
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${isMobile
    ? `
    text-align:top;
    padding-left: 10px;
    display: block;
  `
    : ""}
  ${media.xs`
    text-align:top;
    padding-left: 10px;
    display: block;
  `}
`;

export const Logo = styled((props) => (
         <img className={props.className} src={logo} alt="EHS_Soluction" />
       ))`
         height: 46px;
         display: block;
       `;

const HoverMenuMobileStyles = `
  border-bottom: 1px #fff solid;
  &:last-child{
    border-bottom: none;
  }

  .navbar-nav .open .dropdown-menu > & > a {
    height:48px;
    line-height:38px;
  }
`;

export const HoverLi = styled.li`
  ${isMobile
    ? `
    ${HoverMenuMobileStyles}; 
  `
    : ""}
  ${media.xs`
    ${HoverMenuMobileStyles};
  `}
`;

export const MenuLinkStyle = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  color: ${(props) => props.theme.primaryTextColor};
  &:hover {
    color: ${(props) => props.theme.primaryLighterColor};
  }
  ${isMobile
    ? `
    display:flex !important;
    align-items:center;
  `
    : ""}
  ${media.xs`
    display:flex !important;
    align-items: center;
  `}
`;

export const WhiteBorder = styled.div`
  align-self: flex-end;
  margin-left: auto;
  ${isMobile
    ? `
    border-top: 1px solid #fff;
    padding-bottom:10px;
    width:100%;
    height:0px;
  `
    : ""}
  ${media.xs`
    border-top: 1px solid #fff;
    padding-bottom:10px;
    width:100%;
    height:0px;
  `}
`;
