import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Form, Field } from "react-final-form";
import Button from "react-bootstrap/lib/Button";

import {
  getUserSections,
  getUserSectionsIndependently,
} from "actions/sectionActions";
import { getUsersLocalizations } from "actions/localizationActions";
import {
  getIncidentByFilter,
  deleteIncidentDraft,
  changeIncidentType,
  deleteIncident,
  UpdateGusInList,
} from "actions/incidentActions";
import { getIncidents, getProgresses } from "actions/incidentActions";

import { ButtonWrapper } from "components/ListComponents";
import { LayoutContent } from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import LoadingScreen from "../../components/LoadingScreen";
import {
  ButtonWithIcon,
  StyledTableWrapper,
  StyledTableListWithSearch,
} from "components/ListComponents";
import Select from "../../components/Select";
import { TableSortHeader } from "components/TableSortable";
import Modal from "../../components/Modal/SimpleAccept";

import { StyledInput } from "../../style/styleComponents/Form/input";
import DayPicker from "../../lib/DayPicker";

import SideMenu from "./_SideMenu";
import IncidentDefaultComponent from "./defaultComponent";
import PrintModal from "./List/PrintModal";
import PrintListModal from "./List/PrintListModal";
import SetGUSModal from "./List/SetGUSModal";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "type_id", () => null);
  changeValue(state, "symbol", () => null);
  changeValue(state, "protocol_number", () => null);
  changeValue(state, "date_event", () => null);
  changeValue(state, "title", () => null);
  changeValue(state, "localization_id", () => null);
  changeValue(state, "section_name", () => null);
  changeValue(state, "progress_id", () => null);
};

const DateTd = (props) => <td>{props.children}</td>;

const getUrl = (id, stage) => {
  switch (stage) {
    case 5:
      return `/incident/analysis/${id}`;
    case 4:
      return `/incident/notification/${id}`;
    case 1:
      return `/incident/attachment/${id}`;
    case 3:
      return `/incident/task/${id}`;
    case 2:
      return `/incident/analysis/${id}`;
    case 0:
      return `/incident/register/${id}`;
    default:
      return `/incident/register/${id}`;
  }
};

export const ProgressTd = styled.td`
  background: ${(props) => props.color || "inherit"};
`;

let StyledTable = styled(StyledTableListWithSearch)`
  & tr {
    & > td {
    }
  }
`;

const TwoButtons = styled.div`
  display: flex;
  justify-content: space-around;

  & button {
    margin: 0 4px;
  }
`;

class List extends IncidentDefaultComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalDelete: {
        open: false,
      },
      modalChangeType: {
        open: false,
      },
      modalDeleteIncident: {
        open: false,
      },
      localFetched: true,
    };
    this.addNewIncident = this.addNewIncident.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.deleteIncidentDraft = this.deleteIncidentDraft.bind(this);
    this.changeIncidentType = this.changeIncidentType.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.deleteIncident = this.deleteIncident.bind(this);
    this.updateGus = this.updateGus.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.updateMessage();
    Promise.all([
      getIncidents()(dispatch).then(this.handleServerResponse({}, () => {})),
      getUsersLocalizations()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getProgresses("incident")(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUserSectionsIndependently()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
    ]).then(() => {
      this.setState({ localFetched: true });
    });
  }

  clearFilter() {
    const { dispatch } = this.props;
    this.form.form.mutators.clearFilterMutator();
    getIncidents()(dispatch).then(this.handleServerResponse({}, () => {}));
  }

  handleModalOpen(name, entry_id, rest) {
    return () => {
      this.setState({
        [name]: { ...this.state[name], open: true, entry_id, ...rest },
      });
    };
  }

  handleModalClose(name) {
    return () => {
      this.setState({
        [name]: { ...this.state[name], open: false, entry_id: null },
      });
    };
  }

  localizationChange(localization_id) {
    const { dispatch } = this.props;
    getUserSections(localization_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  handleFilterSubmit(data) {
    const { dispatch } = this.props;
    getIncidentByFilter(data)(dispatch).then(this.handleServerResponse());
  }

  changeIncidentType() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modalChangeType;
    changeIncidentType(entry_id)(dispatch).then(this.handleServerResponse());
  }

  deleteIncidentDraft() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modalDelete;
    deleteIncidentDraft(entry_id)(dispatch).then(this.handleServerResponse());
  }

  deleteIncident() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modalDeleteIncident;
    deleteIncident(entry_id)(dispatch).then(this.handleServerResponse());
  }
  updateGus(data) {
    let { dispatch } = this.props;
    let { incident_id, ...rest } = data;

    UpdateGusInList(incident_id, rest, { list: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  render() {
    const {
      modalDelete,
      modalChangeType,
      modalDeleteIncident,
      message,
    } = this.state;
    const {
      incident: {
        data: incidentData,
        progresses: progressData,
        permission,
        fetched,
      },
      localization: { data: localizationData },
      section: { data: sectionData },
      account: {
        singleData: { id: localUserId },
      },
      dispatch,
    } = this.props;
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={
          <SideMenu
            permission={permission}
            addNewIncident={this.addNewIncident}
          />
        }
      >
        <PageHeader>Rejestr zdarzeń</PageHeader>
        <ButtonWrapper>
          <PrintListModal dispatch={dispatch} />
          <ButtonWithIcon
            disabled={!permission[9]}
            onClick={this.addNewIncident}
            icon="fa fa-plus"
            name="Zgłoś nowe zdarzenie"
          />
        </ButtonWrapper>
        <div style={{ lineHeight: "34px" }}>Szukaj</div>
        <LoadingScreen fetched={this.state.localFetched}>
          <StyledTableWrapper>
            <Form
              mutators={{ clearFilterMutator }}
              onSubmit={this.handleFilterSubmit}
              render={({ handleSubmit, pristine, invalid, form, values }) => {
                this.form = { form };
                return (
                  <form onSubmit={handleSubmit}>
                    <StyledTable cellspacing={0}>
                      <tr>
                        <td>
                          <Field
                            name="type_id"
                            render={({ input, meta, ...rest }) => (
                              <Select
                                title="Typ"
                                fullWidth
                                propValue={"id"}
                                selected={input.value}
                                data={[
                                  { id: null, name: "Wszystkie" },
                                  { id: 1, name: "Wypadek" },
                                  {
                                    id: 2,
                                    name: "Zdarzenie potencjalnie wypadkowe",
                                  },
                                ]}
                                handleChange={(value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Field
                            name="symbol"
                            render={({ input, meta, ...rest }) => (
                              <StyledInput
                                {...input}
                                value={input.value}
                                placeholder={"wg Symbolu"}
                                onChange={(event) => {
                                  input.onChange(event.target.value);
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Field
                            name="protocol_number"
                            render={({ input, meta, ...rest }) => (
                              <StyledInput
                                {...input}
                                value={input.value}
                                placeholder={"wg Nr protokołu"}
                                onChange={(event) => {
                                  input.onChange(event.target.value);
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Field
                            name="date_event"
                            render={({ input, meta, ...rest }) => (
                              <DayPicker
                                selectedDay={input.value}
                                fullWidth
                                placeholder={"wg Daty"}
                                handleChange={(value) => {
                                  input.onChange(
                                    moment(value).format("YYYY-MM-DD")
                                  );
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Field
                            name="title"
                            render={({ input, meta, ...rest }) => (
                              <StyledInput
                                {...input}
                                placeholder={"wg Tytułu"}
                                onChange={(event) => {
                                  input.onChange(event.target.value);
                                }}
                              />
                            )}
                          />
                        </td>

                        <td>
                          <Field
                            name="localization_id"
                            render={({ input, meta, ...rest }) => (
                              <Select
                                title="Lokacja"
                                fullWidth
                                propValue={"id"}
                                selected={input.value}
                                data={[{ id: null, name: "Wszystkie" }].concat(
                                  localizationData
                                )}
                                handleChange={(value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Field
                            name="section_name"
                            render={({ input, meta, ...rest }) => (
                              <Select
                                title="Dział"
                                fullWidth
                                propValue={"value"}
                                selected={input.value}
                                data={[{ id: null, name: "Wszystkie" }].concat(
                                  sectionData
                                )}
                                handleChange={input.onChange}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Field
                            name="progress_id"
                            render={({ input, meta, ...rest }) => (
                              <Select
                                title="Postęp"
                                fullWidth
                                pullRight
                                propValue={"id"}
                                selected={input.value}
                                data={[{ name: "Wszystkie", id: null }].concat(
                                  progressData
                                )}
                                handleChange={input.onChange}
                              />
                            )}
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          colSpan="10"
                          style={{ padding: "8px 40px 8px 8px" }}
                        >
                          <ButtonWrapper nowrap>
                            <Button type="submit" bsStyle="primary">
                              Szukaj
                            </Button>
                            <Button onClick={this.clearFilter}>
                              Wyłącz filtr
                            </Button>
                          </ButtonWrapper>
                        </td>
                      </tr>
                      <TableSortHeader
                        headers={[
                          { name: "Typ", prop: "type_id" },
                          {
                            name: "Symbol",
                            prop: "symbol",
                            sort_type: "string",
                          },
                          {
                            name: "Numer protokołu powypadkowego",
                            prop: "protocol_number",
                            sort_type: "string",
                          },
                          { name: "Data", prop: "date_event" },
                          { name: "Tytuł", prop: "title", sort_type: "string" },
                          {
                            name: "Lokacja",
                            prop: "localization_name",
                            sort_type: "string",
                          },
                          {
                            name: "Dział",
                            prop: "section_name",
                            sort_type: "string",
                          },
                          {
                            name: "Postęp",
                            prop: "dictionary_progress_name",
                            sort_type: "string",
                          },
                          { name: "Zmień typ / Usuń" },
                          { name: "Druk" },
                          { name: "GUS" },
                        ]}
                        data={incidentData}
                        render={(incidentSortedData) => {
                          return incidentSortedData
                            .map((item) => {
                              item.date_add = moment(item.date_add).format(
                                "YYYY-MM-DD"
                              );
                              return item;
                            })
                            .map((item) => (
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  {item.type_id == 1 && (
                                    <i
                                      style={{ color: "#bb3434" }}
                                      title="Wypadek przy pracy"
                                      className="fa fa-exclamation"
                                    ></i>
                                  )}
                                  {item.type_id == 2 && (
                                    <i
                                      style={{ color: "#d4d48e" }}
                                      title="Zdarzenie potencjalnie wypadkowe"
                                      className="fa fa-warning"
                                    ></i>
                                  )}
                                </td>
                                <td>{item.symbol}</td>
                                <td>{item.protocol_number}</td>
                                <td>{item.date_event}</td>
                                <td>
                                  {permission[1] ||
                                  (item.users_id == localUserId &&
                                    item.dictionary_progress_id == 4) ||
                                  (item.responsible_user_id == localUserId &&
                                    item.dictionary_progress_id == 1) ? (
                                    <Link
                                      to={getUrl(
                                        item.id,
                                        item.dictionary_progress_id == 1
                                          ? item.stage
                                          : null
                                      )}
                                    >
                                      {item.title
                                        ? item.title
                                        : `szkic ${item.date_add}`}
                                    </Link>
                                  ) : (
                                    <span>
                                      {item.title
                                        ? item.title
                                        : `szkic ${item.date_add}`}
                                    </span>
                                  )}
                                </td>
                                <td>{item.localization_name}</td>
                                <td>{item.section_name}</td>
                                <ProgressTd
                                  color={item.dictionary_progress_color}
                                >
                                  {item.dictionary_progress_name}
                                </ProgressTd>
                                <td>
                                  {item.dictionary_progress_id == 4 ? (
                                    <TwoButtons>
                                      <ButtonWithIcon
                                        onClick={this.handleModalOpen(
                                          "modalDelete",
                                          item.id
                                        )}
                                        icon="fa fa-trash"
                                      />
                                    </TwoButtons>
                                  ) : (
                                    <TwoButtons>
                                      <ButtonWithIcon
                                        disabled={
                                          !(
                                            permission[9] ||
                                            (item.assigned &&
                                              item.dictionary_progress_id ==
                                                1) ||
                                            item.responsive_person_permission
                                          )
                                        }
                                        onClick={this.handleModalOpen(
                                          "modalChangeType",
                                          item.id
                                        )}
                                        icon="fa fa-refresh"
                                      />
                                      <ButtonWithIcon
                                        disabled={!permission[4]}
                                        onClick={this.handleModalOpen(
                                          "modalDeleteIncident",
                                          item.id
                                        )}
                                        icon="fa fa-trash"
                                      />
                                    </TwoButtons>
                                  )}
                                </td>
                                <td>
                                  <PrintModal
                                    data={item}
                                    dispatch={dispatch}
                                    permission={permission}
                                  />
                                </td>
                                <td>
                                  <SetGUSModal
                                    readOnly={
                                      !permission[10] || item.type_id != 1
                                    }
                                    updateGus={this.updateGus}
                                    data={{
                                      incident_id: item.id,
                                      so_po_send_to_gus: item.so_po_send_to_gus,
                                      so_pt_send_to_gus: item.so_pt_send_to_gus,
                                    }}
                                  />
                                </td>
                              </tr>
                            ));
                        }}
                      />
                    </StyledTable>
                  </form>
                );
              }}
            />
          </StyledTableWrapper>
        </LoadingScreen>
        <Modal
          title={"Usuń szkic zdarzenia"}
          description="Czy chcesz usunąć dany wpis?"
          open={modalDelete.open}
          handleAccept={this.deleteIncidentDraft}
          handleClose={this.handleModalClose("modalDelete")}
        />
        <Modal
          title={"Usuń zdarzenie"}
          description="Czy chcesz usunąć dany wpis?"
          open={modalDeleteIncident.open}
          handleAccept={this.deleteIncident}
          handleClose={this.handleModalClose("modalDeleteIncident")}
        />
        <Modal
          title={"Zmień typ zdarzenia"}
          description="Czy chcesz zmienić typ zdarzenia danego wpisu?"
          open={modalChangeType.open}
          handleAccept={this.changeIncidentType}
          handleClose={this.handleModalClose("modalChangeType")}
        />
      </LayoutContent>
    );
  }
}
export default connect((store) => ({
  incident: store.incident,
  localization: store.localization,
  section: store.section,
  message: store.message,
  account: store.account,
}))(List);
