import React from "react";
import { connect } from "react-redux";
import { getUserWorkplacesWithout } from "actions/workplaceActions";
import {
  getIncidentNotification,
  registerNotifications,
  deleteRegisteredNotification,
  deleteUserFromRegisterGroup,
  registerNotification,
  addUserToRegisterGroup,
  getIncidentToRegister,
  closeIncident,
  getHistory,
} from "../../actions/incidentActions";
import NotificationComponent from "components/NotificationComponent";
import SideMenu from "./_SideMenu";
import { addNewIncident } from "./defaultComponent";

class IncidentNotification extends NotificationComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      mobileNotHideMenu: true,
      notification_group_id: 1,
      moduleListUrl: "/incident",
      permission_to_edit: 13,
    };
    this.addNewIncident = addNewIncident.bind(this);
  }

  componentDidMount() {
    const { dispatch, match, incident } = this.props;
    const { incident_id } = match.params;

    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());

    getIncidentNotification(incident_id)(dispatch).then(
      this.handleServerResponse()
    );
    this.getHistory();
    if (!incident || incident.id != incident_id) {
      getIncidentToRegister(incident_id)(dispatch).then(
        this.handleServerResponse({}, (action) => {
          const {
            incident: { dictionary_progress_id },
          } = action.payload.data;
          this.setState({ dictionary_progress_id });
        })
      );
    }
  }

  saveNotifications() {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    const result = this.prepareData();
    registerNotifications(
      incident_id,
      result
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/incident");
      })
    );
  }

  handleAddUserToRegisterGroup(notification_group_id, user_id, user) {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    addUserToRegisterGroup(
      incident_id,
      user_id
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteUserFromRegisterGroup() {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    deleteUserFromRegisterGroup(incident_id, {
      register_notification_group_has_users_id: this.state.modalUserDelete
        .entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  handleRegisterNotification(data) {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    data.term = data.term == "null" ? null : data.term;
    registerNotification(
      incident_id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteRegisteredNotification() {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    const {
      modalNotificationDelete: { entry_id },
    } = this.state;
    deleteRegisteredNotification(incident_id, {
      register_notification_id: entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  renderSideMenu() {
    const { match } = this.props;
    const { incident_id } = match.params;
    return (
      <SideMenu
        incident_id={incident_id}
        permission={this.props.incident.permission}
        addNewIncident={this.addNewIncident}
      />
    );
  }

  closeInstance() {
    const { dispatch, history, match } = this.props;
    const { incident_id } = match.params;
    closeIncident(incident_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/incident");
      })
    );
  }

  getHistory() {
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    getHistory(incident_id)(dispatch).then(this.handleServerResponse());
  }
}

export default connect((store) => ({
  workplace: store.workplace,
  notification: store.notification,
  incident: store.incident,
}))(IncidentNotification);
