import React from "react";
import { connect } from "react-redux";
import { TaskEdit } from "../../Task/Edit";
import LoadingScreen from "components/LoadingScreen/";
import TaskForm from "components/Task.Form";
import {
  acceptTask,
  closeTask,
  confirmTask,
  getIncidentTask,
  editTaskByIncident,
  download,
} from "actions/taskActions";
import SideMenu from "../_SideMenu";
import { addNewIncident } from "../defaultComponent";

// import {
//   addTask,
//   editTask,
//   getTask,
//   addAttachment,
//   deleteAttachment,
//   getAttachments,
//   download,
// } from "actions/taskActions";

import {
  addTaskAttachment,
  deleteTaskAttachment,
} from "actions/incidentActions";
class IncidentTaskEdit extends TaskEdit {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { incident_id } = match.params;
    this.state = { ...this.state, parentUrl: `/incident/task/${incident_id}` };
    this.addNewIncident = addNewIncident.bind(this);
    this.setMessege = this.setMessage.bind(this);
  }

  _getTask() {
    const { dispatch, match } = this.props;
    const { incident_id, task_id } = match.params;
    return getIncidentTask(
      incident_id,
      task_id
    )(dispatch).then(this.handleServerResponse({}, () => {}));
  }

  setMessage(message) {
    this.setState({
      message,
    });
  }
  handleSubmit(data) {
    const { dispatch, history, match } = this.props;
    const { incident_id, task_id } = match.params;

    editTaskByIncident(
      incident_id,
      task_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/incident/task/${incident_id}`);
      })
    );
  }

  render() {
    const {
      account: { singleData: user },
      task: {
        singleData,
        permission: taskPermission,
        attachmentAddFetching,
        attachments,
      },
      incident: { permission },
      localization: { data: localizationData },
      section: { data: sectionData },
      match,
    } = this.props;
    const { incident_id, task_id } = match.params;
    const { message } = this.state;
    const LSideMenu = (props) => (
      <SideMenu
        incident_id={incident_id}
        permission={permission}
        addNewIncident={this.addNewIncident}
      />
    );
    if (permission[12]) {
      taskPermission[2] = true;
      taskPermission[3] = true;
      taskPermission[7] = true;
    }

    if (singleData.users_id == user.id) {
      taskPermission[7] = true;
    }

    return (
      <LoadingScreen fetched={true}>
        <TaskForm
          mobileNotHideMenu={true}
          SideMenu={LSideMenu}
          noLocalization
          handleSubmit={this.handleSubmit}
          localizations={localizationData}
          sections={sectionData}
          data={singleData}
          message={message}
          permission={taskPermission}
          readOnly={!taskPermission[3]}
          acceptTask={this.acceptTask}
          confirmTask={this.confirmTask}
          closeTask={this.closeTask}
          incident_id={incident_id}
          task_id={task_id}
          editView
          backUrl={`/incident/task/${incident_id}`}
          parent={{
            url: `/incident/analysis/:${incident_id}`,
            name: "name",
          }}
          attachments={attachments}
          setMessage={this.setMessege}
          addAttachment={addTaskAttachment}
          deleteAttachment={deleteTaskAttachment}
          attachmentAddFetching={attachmentAddFetching}
          downloadAction={download}
        />
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  account: store.account,
  task: store.task,
  incident: store.incident,
  localization: store.localization,
  section: store.section,
}))(IncidentTaskEdit);
