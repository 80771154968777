import React, { Fragment } from "react";
import { Field } from "react-final-form";
import FormModal from "../Modal/FormModal";
import DayPicker from "../../lib/DayPicker";

import Select from "../../components/Select";
import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
  StyledTextareaBox,
} from "../../style/styleComponents/Form/input";
import validator from "../Validation/";

export default class OSHTrainingFormModal extends React.PureComponent {
  render() {
    let { modalSettings, handleSubmit, readOnly = false } = this.props;
    let title = "Dodaj nowe szkolenie";
    return (
      <FormModal
        {...modalSettings}
        // initialValues={contactFormInitial}
        title={title}
        description="Opis"
        handleSubmit={handleSubmit}
        acceptButtonName="Zapisz"
        cancelButtonName="Anuluj"
      >
        <Field
          name="osh_training_type_id"
          validate={validator.required}
          render={({ input, meta, ...rest }) => (
            <Select
              label="Rodzaj szkolenia"
              fullWidth
              title="Brak"
              propValue={"id"}
              data={[
                { id: 1, name: "Szkolenie wstępne BHP - instruktaż ogólny" },
                {
                  id: 2,
                  name: "Szkolenie wstępne BHP - instruktaż stanowiskowy",
                },
                { id: 3, name: "Szkolenie okresowe BHP" },
              ]}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              selected={input.value}
              handleChange={(data) => {
                input.onChange(data);
              }}
            />
          )}
        />
        <Field
          name="date_completed"
          validate={validator.required}
          render={({ input, meta, ...rest }) => (
            <DayPicker
              label="Data ukończenia szkolenia"
              fullWidth
              disabled={readOnly}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              selectedDay={input.value}
              value={input.value}
              handleChange={(value) => {
                input.onChange(value);
              }}
            />
          )}
        />
        <Field
          name="date_next"
          validate={validator.required}
          render={({ input, meta, ...rest }) => (
            <DayPicker
              label="Data następnego szkolenia"
              fullWidth
              disabled={readOnly}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              selectedDay={input.value}
              value={input.value}
              handleChange={(value) => {
                input.onChange(value);
              }}
            />
          )}
        />
      </FormModal>
    );
  }
}
