import React from "react";
import { connect } from "react-redux";
import AppComponent from "../components/AppComponent";
import { LayoutContent } from "../components/Layout";
import PageHeader from "../components/PageHeader";
import {
  StyledInputBox,
  StyledInputCheckBox,
} from "../style/styleComponents/Form/input";
import {
  getConfiguration,
  saveConfiguration,
} from "../actions/configurationActions";
import { Form, Field } from "react-final-form";
import { ButtonWrapper } from "../components/ListComponents";
import Button from "react-bootstrap/lib/Button";
import Alert from "react-bootstrap/lib/Alert";

class Configuration extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      message_id: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getConfiguration()(dispatch).then(this.handleServerResponse());
  }

  submit(group_id) {
    return (values) => {
      const { dispatch } = this.props;
      this.setState({ message_id: group_id }, () => {
        saveConfiguration(
          group_id,
          values
        )(dispatch).then(this.handleServerResponse());
      });
    };
  }

  render() {
    const { message_id, message } = this.state;
    const {
      configuration: { data },
    } = this.props;
    return (
      <LayoutContent>
        <PageHeader>Ustawienia systemu</PageHeader>
        {data.map((item) => (
          <div>
            <PageHeader>{item.title}</PageHeader>

            <Form
              onSubmit={this.submit(item.id)}
              initialValues={item.config.reduce((obj, item) => {
                obj[item.key] = item.value;
                return obj;
              }, {})}
              render={({ handleSubmit, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  {message_id &&
                  message_id == item.id &&
                  message &&
                  message.text ? (
                    <Alert bsStyle={message.className}>{message.text}</Alert>
                  ) : (
                    ""
                  )}
                  {item.config.map((subitem) => (
                    <Field
                      name={subitem.key}
                      render={({ input, meta, ...rest }) => (
                        <div>
                          {subitem.type == 1 ? (
                            <StyledInputCheckBox
                              {...input}
                              value={input.value || "false"}
                              checked={input.value == "true"}
                              label={subitem.title}
                              onChange={(e) => {
                                input.onChange(
                                  e.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          ) : (
                            <StyledInputBox
                              value={input.value}
                              label={subitem.title}
                              onChange={(e) => {
                                input.onChange(e.target.value);
                              }}
                            />
                          )}
                        </div>
                      )}
                    />
                  ))}
                  <ButtonWrapper>
                    <Button type="submit" bsStyle="primary">
                      Zapisz
                    </Button>
                  </ButtonWrapper>
                </form>
              )}
            />
          </div>
        ))}
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  configuration: store.configuration,
}))(Configuration);
