import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";

import AppComponent from "components/AppComponent";
import { FieldInput33 } from "components/Fields";
import Box from "components/Box";
import { Button } from "components/Buttons";
import { ButtonWrapper } from "components/Wrappers";

import AutoSave from "./AutoSave";

let setInitialValues = (data) => {
  let { parameters1, parameters2, ...rest } = data;

  function formParameters(parameters = [], name) {
    return parameters.reduce((obj, i) => {
      obj[`${name}_${i.parameter_id}`] = {
        id: i.id,
        variable_id: i.variable_id,
        description: i.description,
      };

      return obj;
    }, {});
  }

  let result = {
    ...rest,
    ...formParameters(parameters1, "parameters1"),
    ...formParameters(parameters2, "parameters2"),
  };
  return result;
};

export default class Category extends AppComponent {
  constructor(props) {
    super(props);

    this.saveHazard = this.saveHazard.bind(this);
  }

  saveHazard(data) {
    let { saveHazard } = this.props;

    saveHazard(data);
  }

  render() {
    let { deleteRAAnalysisHazard, data } = this.props;

    return (
      <Form
        onSubmit={() => {}}
        // mutators={{ ...arrayMutators }}
        initialValues={data}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <AutoSave
                debounce={1000}
                // setFieldData={form.mutators.setFieldData}
                // values={values}
                save={this.saveHazard}
              />
              <FieldInput33 name="name" label="Nazwa kategorii" />
              <ButtonWrapper>
                <Button type="button" onClick={deleteRAAnalysisHazard(data.id)}>
                  Usuń kategorię
                </Button>
              </ButtonWrapper>
            </form>
          );
        }}
      />
    );
  }
}
