import React from "react";
import styled from "styled-components";

import Modal from "components/Modal/Simple";
import Select from "components/Select";

export const SelectWrapper = styled.div`
  margin-bottom: 15px;
`;

export const modalResponsibilityState = {
  selectedWorkplace: null,
  selectedUser: null,
  selectedUserName: null,
  open: false,
};

export default class ModalResponsibility extends React.PureComponent {
  render() {
    let {
      accept,
      closeModal,
      modal,
      usersData,
      workplaceData,
      setWorkplace,
      setUser,
    } = this.props;
    let { open, selectedWorkplace, selectedUser } = modal;
    return (
      <Modal
        handleAccept={accept}
        handleClose={closeModal}
        title="Osoba odpowiedzialna"
        open={modal.open}
      >
        <SelectWrapper>
          <Select
            fullWidth
            title="Stanowisko"
            selected={selectedWorkplace}
            data={workplaceData}
            propValue={"id"}
            handleChange={setWorkplace}
          />
        </SelectWrapper>
        {selectedWorkplace != null && (
          <Select
            fullWidth
            selected={selectedUser}
            propValue={"id"}
            handleChange={setUser}
            title="Osoba"
            data={usersData}
          />
        )}
      </Modal>
    );
  }
}
