export default function reducer(
  state = {
    data: [],
    data2: [],
    dataWithDivision: [],
    dataWithoutDivision: [],
    singleData: {},
    fetching: false,
    fetched: true,
  },
  action
) {
  switch (action.type) {
    case "GET_LOCALIZATIONS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_LOCALIZATIONS_SUCCESS": {
      let denest = (values) => {
        let newValues = [];
        for (let item of values) {
          let { children, ...rest } = item;
          newValues.push(rest);
          if (children) {
            let orphanedChildren = children.map((orphan) => {
              orphan.name = "\u00A0\u00A0" + orphan.name;
              return orphan;
            });
            newValues = newValues.concat(orphanedChildren);
          }
        }

        return newValues;
      };

      return {
        ...state,
        fetching: false,
        fetched: true,
        data: denest(action.payload.data.localizations),
      };
    }
    case "GET_LOCALIZATIONS_2_SUCCESS": {
      let denest = (values) => {
        let newValues = [];
        for (let item of values) {
          let { children, ...rest } = item;
          newValues.push(rest);
          if (children) {
            let orphanedChildren = children.map((orphan) => {
              orphan.name = "\u00A0\u00A0" + orphan.name;
              return orphan;
            });
            newValues = newValues.concat(orphanedChildren);
          }
        }

        return newValues;
      };

      return {
        ...state,
        fetching: false,
        fetched: true,
        data2: denest(action.payload.data.localizations),
      };
    }
    case "GET_LOCALIZATIONS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }

    case "GET_LOCALIZATION_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_LOCALIZATION_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.localization,
      };
    }
    case "GET_BUSINESS_MAIN_DATA_TO_LOCALIZATION_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {
          ...state.singleData,
          ...action.payload.data.business,
        },
      };
    }
    case "GET_LOCALIZATION_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }

    case "ADD_LOCALIZATION_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "ADD_LOCALIZATION_SUCCESS": {
      return { ...state, fetching: false, fetched: true };
    }
    case "ADD_LOCALIZATION_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "EDIT_LOCALIZATION_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "EDIT_LOCALIZATION_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.localization,
      };
    }
    case "EDIT_LOCALIZATION_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "SAVE_LOCALIZATIONS_LOGO_SUCCESS": {
      const { logo_url } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {
          ...state.singleData,
          logo: null,
          logo_url: logo_url,
        },
      };
    }
    case "DELETE_LOCALIZATIONS_LOGO_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {
          ...state.singleData,
          logo: null,
          logo_url: null,
        },
      };
    }
    case "DELETE_LOCALIZATION_SUCCESS": {
      const { id: deletedLocalizationId } = action.payload.data.localization;
      const newData = state.data
        .filter((item) => item.id != deletedLocalizationId || item.division)
        .map((item) => {
          if (item.division) {
            if (item.children) {
              item.children = item.children.filter(
                (item) => item.id != deletedLocalizationId
              );
            }
            return item;
          } else {
            return item;
          }
        });
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: newData,
      };
    }

    case "GET_DIVISIONS_LOCALIZATION_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_DIVISIONS_LOCALIZATION_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.localizations,
      };
    }
    case "GET_DIVISIONS_LOCALIZATION_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }

    case "DELETE_DIVISION_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.localizations,
      };
    }
    case "GET_DIVISION_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.division,
      };
    }

    case "CLEAR_LOCALIZATION_REDUCER": {
      return {
        data: [],
        sectors: [],
        singleData: {},
        fetching: false,
        fetched: true,
      };
    }
  }

  return state;
}
