import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import styled from "styled-components";

import AppComponent from "../AppComponent";
import validator, { composeValidators } from "../Validation";
import { LinkContainer } from "../LinkContainer";

import Box from "../Box";
import Button from "react-bootstrap/lib/Button";
import { ButtonWrapper } from "../ListComponents";
import { LayoutContent } from "../Layout";
import LoadingScreen from "../LoadingScreen";
import TextEditor from "../../lib/ReactQuill/index.js";
import TextEditor2 from "../TextEditor";
import countMaxForFormula from "./countMaxForFormula";

import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox as TextareaBox,
  StyledInputMessage,
} from "style/styleComponents/Form/input.js";

import Parameters from "./ParametrsComponent";
import RiskCalculationComponent from "./RiskCalculationComponent";

const clearField = ([name], state, { changeValue }) => {
  changeValue(state, name, () => null);
};

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

let StyledTextareaBox = styled(TextareaBox)`
  min-height: 100px;
`;

let StyledBox = styled(Box)`
  padding: 0 0 0 0 !important;
`;

function formValidate(values) {
  const errors = {};
  if (values) {
    let min = 0;
    if (!(values.parameters && values.parameters.length)) {
      errors["parameters"] = "Uzupełniony parametr wymagany";
    } else {
      for (let [i, p] of values.parameters.entries()) {
        errors[`parameters[${i}].name`] = validator.required(p.name);
        errors[`parameters[${i}].user_variable_name`] = validator.required(
          p.user_variable_name
        );
        if (!(p.variables && p.variables.length)) {
          errors[`parameters[${i}].variables`] = "Uzupełniona wartość wymagana";
        } else {
          p.variables.forEach((v, iv) => {
            errors[
              `parameters[${i}].variables[${iv}].name`
            ] = validator.required(v.name);
            errors[
              `parameters[${i}].variables[${iv}].number_value`
            ] = validator.required(v.number_value);
            errors[
              `parameters[${i}].variables[${iv}].description`
            ] = validator.required(v.description);
          });

          // for (let [iv, v] of p.variables) {
          //
          // }
        }
      }
    }

    if (!values.dictionary_ra_method_calc_id) {
      errors["dictionary_ra_method_calc_id"] = "brak";
    }
    if (values.dictionary_ra_method_calc_id == 1) {
      if (values.mathematical_formula) {
        if (
          values &&
          values.risk_level_descriptions &&
          values.risk_level_descriptions.length
        ) {
          let value =
            values.risk_level_descriptions[
              values.risk_level_descriptions.length - 1
            ].to;
          try {
            let max = countMaxForFormula(values);

            if (value < min) {
              if (!errors.risk_level_descriptions)
                errors.risk_level_descriptions[
                  errors.risk_level_descriptions.length - 1
                ].from = `Wartość nie może być mniejsza od ${min}`;
            }
            if (value != max) {
              if (!errors.risk_level_descriptions) {
                errors.risk_level_descriptions = values.risk_level_descriptions.map(
                  (i) => ({})
                );
              }
              errors.risk_level_descriptions[
                values.risk_level_descriptions.length - 1
              ].to = `Wartość nie może być inna niż ${max}`;
            }
          } catch (err) {
            errors["mathematical_formula"] =
              "Wzór na wartość ryzyka nie jest zbierzny z parametrami";
          }
        }
      } else {
        errors["mathematical_formula"] = validator.required(
          values.mathematical_formula
        );
      }
    }
  }

  return Object.keys(errors).length ? errors : undefined;
}

class RAMethodForm extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.props.handleSubmit(data);
  }

  render() {
    let {
      message,
      fetched,
      handleSubmit,
      data,
      SideMenu,
      urls,
      readOnly = false,
    } = this.props;
    let fieldsLength = 0;
    let that = this;

    return (
      <>
        <LoadingScreen
          fetched={
            true
            // fetched
          }
        >
          <LayoutContent
            message={message}
            error_code={this.state.error_code}
            SideMenu={SideMenu}
          >
            <StyledBox
              title={`${
                data && data.id ? `Edytuj` : `Dodaj`
              } metodę oceny ryzyka zawodowego`}
              noPadding
            >
              <Form
                onSubmit={this.handleSubmit}
                initialValues={data}
                ref={(form) => (this.form = form)}
                mutators={{
                  ...arrayMutators,
                  clearField,
                  setField,
                }}
                validate={formValidate}
                render={({
                  handleSubmit,
                  pristine,
                  invalid,
                  form,
                  values = {},
                  errors,
                  ...rest
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name={"name"}
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <Fragment>
                          <StyledInputBox
                            {...input}
                            disabled={readOnly}
                            label="Nazwa metody"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        </Fragment>
                      )}
                    />
                    <Field
                      name="goal_scope"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledTextareaBox
                          label="Cei i zakres oceny ryzyka zawodowego"
                          disabled={readOnly}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                          value={input.value}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="legal_basis"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledTextareaBox
                          label="Podstawy prawne"
                          disabled={readOnly}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                          value={input.value}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="definitions"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledTextareaBox
                          label="Definicje"
                          disabled={readOnly}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                          value={input.value}
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="content"
                      render={({ input, meta, ...rest }) => (
                        <>
                          <StyledLabel>
                            Charakterystyka wykorzystanej metody oceny ryzyka
                            zawodowego
                          </StyledLabel>
                          <TextEditor2
                            label="Treść"
                            disabled={readOnly}
                            data={input.value}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            onChange={(value) => {
                              input.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />

                    <div className="page-header">
                      <h2>Ustawienia metody</h2>
                    </div>
                    <FieldArray name="parameters">
                      {({ fields, meta }) => {
                        return (
                          <Fragment>
                            <Parameters
                              fields={fields}
                              refForm={that.form}
                              onRemove={(index) => {
                                fields.remove(index);
                              }}
                              errors={errors}
                              readOnly={readOnly}
                            />

                            <StyledInputMessage>
                              {meta.error && meta.touched ? meta.error : null}
                            </StyledInputMessage>
                          </Fragment>
                        );
                      }}
                    </FieldArray>
                    {values.parameters && (
                      <RiskCalculationComponent
                        values={values}
                        disabled={readOnly}
                      />
                    )}

                    <ButtonWrapper>
                      <Button
                        disabled={readOnly}
                        type="submit"
                        bsStyle="primary"
                      >
                        Zapisz
                      </Button>
                      <LinkContainer to={urls.LIST} exact>
                        <Button>Anuluj</Button>
                      </LinkContainer>
                    </ButtonWrapper>
                  </form>
                )}
              />
            </StyledBox>
          </LayoutContent>
        </LoadingScreen>
      </>
    );
  }
}

export default RAMethodForm;
