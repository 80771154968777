import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import { media } from "style/style-utils";
import { connect } from "react-redux";
import moment from "moment";
import {
  getUserByWorkplace,
  getUserByWorkplaceByUser,
} from "actions/userActions";

import AppComponent from "components/AppComponent";
import Select from "components/Select";

import { StyledTableWrapper } from "components/ListComponents";
import { ButtonWithIcon, DeleteButton } from "components/ListComponents";

import {
  termNeutral,
  termsAfter,
  termsBefore,
  termsInNextMonth,
  termDate,
} from "./NotificationTerms";
const distributeNestedArray = (parray, level = 0, parent, userIndex = null) => {
  let lparent = parent || 1;
  return parray.reduce((array, item, index) => {
    const newItem = {
      ...item,
      userIndex: userIndex,
      level,
      notification_length: item.notifications ? item.notifications.length : 0,
      parent: lparent,
      index,
    };
    delete newItem.notifications;
    array.push(newItem);
    if (item.notifications) {
      array = array.concat(
        distributeNestedArray(item.notifications, level + 1, lparent, index)
      );
    }
    !parent && lparent++;
    return array;
  }, []);
};

const getTermName = function (term, term_type, day, month) {
  switch (true) {
    case term === 0: {
      return "W dniu realizacji";
    }
    case term && term_type == 1: {
      return `po ${term} dniach`;
    }
    case term && term_type == 2: {
      return `${term} dzień w miesiącu`;
    }
    case term && term_type == 4: {
      return `${term} dzień w miesiącu`;
    }
    case term && term_type == -1: {
      return `${term} dni przed`;
    }
    case term_type == 3: {
      return `${day} ${termDate[1][month - 1].name}`;
    }
    default: {
      return "Natychmiast";
    }
  }
};

const StyledTable = styled(Table)`
  &.table > thead > tr {
    & > th {
      vertical-align: top;
      &:nth-child(3) {
        width: 430px;
        white-space: normal;
      }
      &:nth-child(4) {
        width: 170px;
        white-space: normal;
      }
      &:last-child {
        text-align: center;
      }
    }
  }

  & > tbody > tr > td {
    &:last-child {
      text-align: center;
    }
  }
`;

const AddPersonWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  align-items: flex-start;
  width: 100%;
  max-width: 550px;
  margin-bottom: 80px;
  ${media.sm`
    flex-direction:column;
   `}
  & > * {
    width: 33%;
    ${media.sm`
      width:100%; 
    `}
  }
`;

const AddPersonColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  ${media.sm`
      width:100%;
      margin-bottom:10px; 
  `}
`;

const AddPersonColumnHeader = styled.div`
  height: 20px;
  margin-bottom: 5px;
`;

const AddPersonAddButton = styled((props) => (
  <div onClick={props.onClick} className={props.className}>
    <i className="fa fa-plus" />
  </div>
))`
  font-size: 28px;
  cursor: pointer;
`;

const defaultUsers = [];

const UserNameSpan = styled.span`
  line-height: 38px;
  padding-bottom: 12px;
`;

const UserNameBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const DoubleSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

function SelectTermType3(props) {
  let { user, item, handleChange } = props;

  let [day, setDay] = useState(null);
  let [month, setMonth] = useState(null);
  let [term1, setTerm1] = useState(termDate[0]);
  let [term2, setTerm2] = useState(termDate[1]);

  let onChange = (mode) => (value) => {
    if (mode) {
      setMonth(value);
      let lastDayOfMonthString = moment()
        .set("month", value - 1)
        .endOf("month")
        .format("DD");
      let lastDayOfMonth = parseInt(lastDayOfMonthString);
      if (lastDayOfMonth < day) {
        setDay(lastDayOfMonth);
      }
      let t = term1.map((i) => {
        let disabled = false;
        if (i.value > lastDayOfMonth) disabled = true;
        return { ...i, disabled };
      });
      setTerm1(t);
    } else {
      setDay(value);
    }
  };

  useEffect(() => {
    if (day && month) {
      handleChange([day, month]);
    }
  }, [day, month]);

  return (
    <DoubleSelectWrapper>
      <Select
        title={"wybierz"}
        fullWidth
        selected={day}
        data={term1}
        propValue={"value"}
        handleChange={onChange(0)}
      />
      <Select
        title={"wybierz"}
        fullWidth
        selected={month}
        data={term2}
        propValue={"value"}
        handleChange={onChange(1)}
      />
    </DoubleSelectWrapper>
  );
}

class NotificationList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      users: [],
      workplace_id: null,
      user_id: null,
      user_index: null,
      notificationStore: {},
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleWorkplaceChange = this.handleWorkplaceChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleAddNotification = this.handleAddNotification.bind(this);
  }

  handleAddNotification(user_id) {
    const { handleAddNotification = () => {} } = this.props;
    return () => {
      const {
        notification_id,
        term,
        day,
        month,
      } = this.state.notificationStore[user_id];
      this.setState(
        {
          notificationStore: {
            ...this.state.notificationStore,
            [user_id]: null,
            term: null,
            notification_id: null,
            term_type: 0,
          },
        },
        () => {
          handleAddNotification({
            user_id,
            notification_id,
            term,
            day,
            month,
          });
        }
      );
    };
  }

  handleAddUser() {
    const { notification_group_id } = this.props;
    const { user_id, users } = this.state;
    if (user_id) {
      const index = users.findIndex((item) => item.id == user_id);
      const user = users[index];
      this.props.handleAddUserToGroup(notification_group_id, user_id, user);
      this.setState({ user_id: null, workplace_id: null, users: [] });
    }
  }

  handleDelete(item, index, subIndex) {
    const { handleDelete = () => {} } = this.props;
    return () => {
      handleDelete({
        id: item.notification_instance_id,
        title: item.name,
        notification_group_has_users_id: item.notification_group_has_users_id,
        userIndex: item.userIndex,
        subIndex,
      });
    };
  }

  handleDeleteUser({ id, title, index }) {
    const { handleDeleteUser = () => {} } = this.props;
    return () => {
      handleDeleteUser({
        id,
        title,
        userIndex: index,
      });
    };
  }

  handleNotificationChange(user_id) {
    return (value) => {
      const term_type = this.props.notifications[
        this.props.notifications.findIndex((item) => item.id == value)
      ].term_type;
      this.setState({
        notificationStore: {
          ...this.state.notificationStore,
          [user_id]: {
            ...this.state.notificationStore[user_id],
            notification_id: value,
            term: 0,
            term_type,
          },
        },
      });
    };
  }

  handleNotificationTermChange(user_id) {
    return (value) => {
      const { notifications } = this.props;
      let term = null;
      let day = null;
      let month = null;
      if (Array.isArray(value)) {
        month = value[1];
        day = value[0];
      } else {
        term = value;
      }
      this.setState({
        notificationStore: {
          ...this.state.notificationStore,
          [user_id]: {
            ...this.state.notificationStore[user_id],
            term,
            day,
            month,
          },
        },
      });
    };
  }

  handleWorkplaceChange(value) {
    const that = this;
    const { data } = this.props;
    const users_ids = data.map((item) => item.user_id);

    getUserByWorkplace(
      parseInt(value),
      null,
      users_ids
    )(this.props.dispatch).then(
      this.handleServerResponse({}, function (action) {
        that.setState({
          workplace_id: value,
          users: defaultUsers.concat(action.payload.data.users),
        });
      })
    );
  }

  handleUserChange(value) {
    this.setState({ user_id: value });
  }

  render() {
    const {
      name,
      data = [],
      notifications = [],
      workplaces = [],
      permission,
    } = this.props;
    const newData = distributeNestedArray(data).map((item) => {
      return {
        ...item,
        term_name: getTermName(item.term, item.term_type, item.day, item.month),
      };
    });

    return (
      <div>
        <div>
          <h4>{name}</h4>
        </div>
        {newData.length ? (
          <StyledTableWrapper>
            <StyledTable bsStyle={"notification"} bordered>
              <thead>
                <tr>
                  <th>Imię i nazwisko</th>
                  <th>Stanowisko</th>
                  <th>Sytuacja, w której osoba otrzymuje powiadomienie</th>
                  <th>Termin</th>
                  <th>Operacje</th>
                </tr>
              </thead>
              <tbody>
                {newData.map((item, index) => {
                  if (!item.level) {
                    const term = (() => {
                      if (
                        !(
                          this.state.notificationStore[item.user_id] &&
                          this.state.notificationStore[item.user_id].term_type
                        )
                      ) {
                        return termNeutral;
                      }
                      switch (
                        this.state.notificationStore[item.user_id].term_type
                      ) {
                        case 1:
                          return termsAfter;
                        case -1:
                          return termsBefore;
                        case 2:
                          return termsInNextMonth;
                        case 3:
                          return termDate;
                        case 4:
                          return termsInNextMonth;
                        default:
                          return termNeutral;
                      }
                    })();

                    let { [item.user_id]: user } = this.state.notificationStore;
                    user = user || { notification_id: null };
                    return (
                      <tr
                        key={index}
                        className={"first"}
                        style={{
                          backgroundColor:
                            item.parent % 2 != 0 ? "#f9f9f9" : "inherit",
                        }}
                      >
                        {!item.level && (
                          <td rowSpan={item.notification_length + 1}>
                            <UserNameBlock>
                              {permission ? (
                                <UserNameSpan>{item.user_name}</UserNameSpan>
                              ) : (
                                <span>{item.user_name}</span>
                              )}
                              {permission ? (
                                <ButtonWithIcon
                                  icon="fa fa-trash"
                                  onClick={this.handleDeleteUser({
                                    id: item.notification_group_has_users_id,
                                    title: item.user_name,
                                    index: item.index,
                                  })}
                                />
                              ) : (
                                ""
                              )}
                            </UserNameBlock>
                          </td>
                        )}
                        {!item.level && (
                          <td rowSpan={item.notification_length + 1}>
                            <UserNameSpan>{item.workplace_name}</UserNameSpan>
                          </td>
                        )}
                        <td>
                          {permission ? (
                            <Select
                              title={"- wybierz -"}
                              fullWidth
                              data={notifications}
                              selected={user.notification_id}
                              propValue={"id"}
                              handleChange={this.handleNotificationChange(
                                item.user_id
                              )}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {this.state.notificationStore[item.user_id] &&
                          this.state.notificationStore[item.user_id]
                            .term_type == 3 ? (
                            <>
                              <SelectTermType3
                                user={user}
                                handleChange={this.handleNotificationTermChange(
                                  item.user_id
                                )}
                              />
                            </>
                          ) : (
                            <>
                              {permission ? (
                                <Select
                                  title={"- wybierz -"}
                                  fullWidth
                                  selected={user.term}
                                  data={term}
                                  propValue={"value"}
                                  handleChange={this.handleNotificationTermChange(
                                    item.user_id
                                  )}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {permission ? (
                            <Button
                              onClick={this.handleAddNotification(item.user_id)}
                            >
                              Dodaj
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr
                        key={index}
                        style={{
                          backgroundColor:
                            item.parent % 2 != 0 ? "#f9f9f9" : "inherit",
                        }}
                      >
                        <td>{item.name}</td>
                        <td>{item.term_name}</td>
                        <td>
                          <Button
                            disabled={!permission}
                            className={"fa fa-trash"}
                            onClick={this.handleDelete(
                              item,
                              item.userIndex,
                              item.index
                            )}
                          ></Button>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </StyledTable>
          </StyledTableWrapper>
        ) : (
          ""
        )}
        <div>
          {permission ? (
            <div>
              <h4>Dodaj osobę</h4>
              <AddPersonWrapper>
                <AddPersonColumn>
                  <AddPersonColumnHeader>Stanowisko</AddPersonColumnHeader>
                  <div>
                    <Select
                      fullWidth
                      title="- Wybierz -"
                      data={[{ id: null, name: "Bez limitu" }].concat(
                        workplaces
                      )}
                      propValue={"id"}
                      handleChange={this.handleWorkplaceChange}
                      selected={this.state.workplace_id}
                    />
                  </div>
                </AddPersonColumn>
                <AddPersonColumn>
                  <AddPersonColumnHeader>Imię i nazwisko</AddPersonColumnHeader>
                  <div>
                    <Select
                      fullWidth
                      title="Brak"
                      data={[{ id: null, name: "Brak" }].concat(
                        this.state.users
                      )}
                      handleChange={this.handleUserChange}
                      propValue={"id"}
                      selected={this.state.user_id}
                    />
                  </div>
                </AddPersonColumn>
                <AddPersonColumn>
                  <AddPersonColumnHeader></AddPersonColumnHeader>
                  <AddPersonAddButton onClick={this.handleAddUser} />
                </AddPersonColumn>
              </AddPersonWrapper>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

class UserNotificationListaRaw extends NotificationList {
  handleWorkplaceChange(value) {
    const that = this;
    const { data } = this.props;
    const users_ids = data.map((item) => item.user_id);

    getUserByWorkplaceByUser(
      value,
      null,
      users_ids
    )(this.props.dispatch).then(
      this.handleServerResponse({}, function (action) {
        that.setState({
          workplace_id: value,
          users: defaultUsers.concat(action.payload.data.users),
        });
      })
    );
  }
}

export const UserNotificationList = connect((store) => ({
  notification: store.notification,
}))(UserNotificationListaRaw);

export default connect((store) => ({
  notification: store.notification,
}))(NotificationList);

NotificationList.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number.isRequired,
      user_name: PropTypes.string,
      workplace_name: PropTypes.string,
      notification: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
          term_name: PropTypes.string,
        })
      ),
    })
  ),
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      name: PropTypes.string,
    })
  ),
  handleDelete: PropTypes.func,
  handleCreate: PropTypes.func,
};
