import React from "react";
import { connect } from "react-redux";
import SideMenu from "../_SideMenu";

import {
  getRA,
  saveRACharacteristic,
  getRAWorkers,
  addRAWorker,
  deleteRAWorker,
  addRAWorkersByWorkplace,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";
import { getUserWorkplacesWithoutTrim } from "actions/workplaceActions";
import { getLocalizationsOnly } from "actions/localizationActions";
import { getUserSections } from "actions/sectionActions";

import AppComponent from "components/AppComponent";
import WorkplaceCharakteristicAdd from "components/RiskAssessment.Forms/WorkplaceCharacteristic/";
import { setUrls } from "../../_staticData";

export class RACharacteristic extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      urls: setUrls(),
      SideMenu,
      draft: false,
    };

    this.submit = this.submit.bind(this);
    this.getSections = this.getSections.bind(this);
    this.addWorker = this.addWorker.bind(this);
    this.deleteWorker = this.deleteWorker.bind(this);
    this.addRAWorkersByWorkplace = this.addRAWorkersByWorkplace.bind(this);
    this.renderSideMenu = this.renderSideMenu.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    Promise.all([
      getRA(id, { readOnly: true })(dispatch).then(this.handleServerResponse()),
      getLocalizationsOnly()(dispatch).then(this.handleServerResponse()),
      getUserWorkplacesWithoutTrim()(dispatch).then(
        this.handleServerResponse()
      ),
      getRAWorkers(id)(dispatch).then(this.handleServerResponse()),
    ]).then(() => {
      const { data } = this.props;
      let { localization_id } = data;
      if (localization_id) {
        getUserSections(localization_id)(dispatch).then(
          this.handleServerResponse()
        );
      }
    });
  }

  getSections(localization_id) {
    const { dispatch } = this.props;
    if (localization_id) {
      getUserSections(localization_id)(dispatch).then(
        this.handleServerResponse()
      );
    }
  }

  addWorker(
    data,
    cb = () => {
      alert("mock cb");
    }
  ) {
    const { dispatch, match } = this.props;
    let { id } = match.params;
    addRAWorker(
      id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        cb();
      })
    );
  }

  addRAWorkersByWorkplace(workplace_id) {
    const { dispatch, match } = this.props;
    let { id } = match.params;

    addRAWorkersByWorkplace(id, { workplace_id })(dispatch);
  }

  deleteWorker(id) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;

    deleteRAWorker(ra_id, id)(dispatch).then(this.handleServerResponse());
  }

  submit(data) {
    let { urls, draft: sd } = this.state;
    let { dispatch, match, history, draft: pd } = this.props;
    let { id } = match.params;
    let draft = sd || pd || false;
    saveRACharacteristic(
      id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.ANALYSIS(id));
      })
    );
  }

  renderSideMenu() {
    const { match, permission, data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }

  render() {
    let { urls, draft } = this.state;
    let {
      match,
      data,
      localization: { data: localizationData },
      permission,
      section: { data: sectionData },
      workplaces,
      workers,
    } = this.props;
    let { id } = match.params;
    const readOnly = Boolean(data.business_id === 0) || !permission[23];

    return (
      <WorkplaceCharakteristicAdd
        draft={draft}
        permission={permission}
        data={data}
        readonly={false}
        urls={urls}
        id={id}
        SideMenu={this.renderSideMenu()}
        submit={this.submit}
        addRAWorkersByWorkplace={this.addRAWorkersByWorkplace}
        localizations={localizationData}
        sections={sectionData}
        workplaces={workplaces}
        getSections={this.getSections}
        workers={workers}
        addWorker={this.addWorker}
        deleteWorker={this.deleteWorker}
        nextUrl={urls.ANALYSIS(id)}
        readOnly={readOnly}
        title={
          "Dodaj nową ocenę ryzyka zawodowego - Charakterystyka stanowiska pracy"
        }
      />
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
  workers: store.riskAssessment.raWorkers,
  permission: store.riskAssessment.permission,
  section: store.section,
  localization: store.localization,
  workplaces: store.workplace.data,
}))(RACharacteristic);
