import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import WorkplaceForm from "../../components/Workplace.form";
import LoadingScreen from "../../components/LoadingScreen";

import { getLocalizations } from "../../actions/localizationActions";
import {
  addWorkplace,
  getWorkplacesParentWithout,
  getWorkplacePermissions,
  clearWorkplaceReducer,
} from "actions/workplaceActions";
class WorkplaceAdd extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, localFetched: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    Promise.all([
      getLocalizations()(dispatch).then(this.handleServerResponse()),
      getWorkplacesParentWithout(null)(dispatch).then(
        this.handleServerResponse()
      ),
      getWorkplacePermissions()(dispatch).then(this.handleServerResponse()),
    ]).then(() => {
      this.setState({ localFetched: true });
    });
  }

  handleSubmit(data) {
    const { dispatch, history } = this.props;

    addWorkplace(data)(dispatch, history).then(
      this.handleServerResponse({}, function () {
        history.push("/business/workplace");
      })
    );
  }

  render() {
    const { message } = this.state;
    const {
      dictionary: { profession_code = [] },
      localization: { data: localizationData = [] },
      workplace: { singleData, data: workplaceData = [], professionCodes },
    } = this.props;
    const { permissions } = singleData;
    return (
      <LoadingScreen fetched={this.state.localFetched}>
        <WorkplaceForm
          createView
          boxTitle={"Dodaj stanowisko"}
          handleSubmit={this.handleSubmit}
          message={message}
          localizations={localizationData}
          permissions={permissions}
          professionCodes={profession_code}
          workplaces={workplaceData}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    clearWorkplaceReducer()(dispatch);
  }
}

export default connect((store) => ({
  dictionary: store.dictionary,
  message: store.message,
  localization: store.localization,
  workplace: store.workplace,
  module: store.module,
}))(WorkplaceAdd);
