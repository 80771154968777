import React from 'react';
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import {Link} from "react-router-dom";

const SpanLink = styled.span`
  color: #337ab7;
  text-decoration: none;
  
  &:hover{
    color: #23527c;
    text-decoration: underline;
  }
`;

class NotFound extends AppComponent {

  constructor(props){
    super(props);
    this.back = this.back.bind(this);
  }

  back(){
    const {history} = this.props;
    history.goBack();
  }

  render(){
    return (
      <div>
        <h3>404 Strona nie istnieje</h3>
        <p>
          <ul>
            <li><SpanLink onClick={this.back}>Powrót do poprzedniej strony</SpanLink></li>
            <li><Link to="/dashboard">Powrót na stronę główną</Link></li>
          </ul>
        </p>
      </div>
    );
  }
}

export default NotFound;