export function getMails() {
  return dispatch => dispatch({
    types: ["GET_MAILS_REQUEST", "GET_MAILS_SUCCESS", "GET_MAILS_ERROR"],
    payload: {
      request: {
        url: "/super-admin/mailing",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    },
  });
}

export function getMailsGroup(group_id) {
  return dispatch => dispatch({
    types: ["GET_MAILS_REQUEST", "GET_MAILS_SUCCESS", "GET_MAILS_ERROR"],
    payload: {
      request: {
        url: `/super-admin/mailing/${group_id}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    },
  });
}

export function setMail(id,data) {
  return dispatch => dispatch({
    types: ["SET_MAIL_REQUEST", "SET_MAIL_SUCCESS", "SET_MAILS_ERROR"],
    payload: {
      request: {
        url: `/super-admin/mailing/${id}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    },
  });
}