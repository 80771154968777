import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import {
  addOSH,
  getOSHList,
  getOSHTypeList,
  getOSHListFilter,
  printOSHReport,
} from "actions/OSHTrainingActions";
import { getUsersLocalizationsAndDivisions } from "actions/localizationActions";
import { getUserSectionsIndependently } from "actions/sectionActions";
import { getWorkplacesTreeByLocalization } from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import {
  ButtonWithIcon,
  ButtonWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
import PageHeader from "components/PageHeader";
import TableSortableWithPagination from "components/TableSortable/Register";
import ModalAddUser from "container/UsersAccounts/Modal";

import SideMenu from "../_SideMenu";
import GenerateRaport from "./generateRaport";
import SummaryOSH from "./summaryOSH";
import AddOSH from "components/OSH/AddOSH";
import UserModal from "./UserModal";
import { getDate } from "./_common";
import saveBlobFile from "components/saveBlobFile";
import { getLocalizationDivisionName } from "../../../util/localization";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "last_name", () => null);
  changeValue(state, "first_name", () => null);
  changeValue(state, "localization_id", () => "0");
  changeValue(state, "section_name", () => "0");
  changeValue(state, "workplace_id", () => "0");
  changeValue(state, "qe_type_id", () => "0");
  changeValue(state, "hired_status_id", () => "0");
  changeValue(state, "date_employment", () => null);
  changeValue(state, "date_initial", () => null);
  changeValue(state, "date_job", () => null);
  changeValue(state, "date_periodic", () => null);
  changeValue(state, "date_next", () => null);
  changeValue(state, "is_osh_actual", () => null);
};

export const ProgressTdStyle = styled.td`
  background: ${(props) => props.color || "inherit"};
`;

let AStyled = styled.a`
  cursor: pointer;
`;

function ProgressTd(props) {
  let { days_left } = props.item;

  if (days_left === null || isNaN(days_left)) {
    return <td>{props.children}</td>;
  }

  let color = (() => {
    switch (true) {
      case days_left >= 45: {
        return "green";
      }
      case days_left < 45 && days_left >= 0: {
        return "yellow";
      }
      case days_left < 0: {
        return "red";
      }
    }
  })();

  return <ProgressTdStyle color={color}>{props.children}</ProgressTdStyle>;
}

const InitialJobTrainingDate = (props) => {
  // klient chce aby: gdy nie ma szkolenia - napis brak i na czerwonoe
  let { children: date } = props;
  return (
    <ProgressTdStyle color={date ? undefined : "red"}>
      {date ? getDate(date) : "brak"}
    </ProgressTdStyle>
  );
};

class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      hired_statuses: [
        {
          id: "0",
          name: "Status zatrudnienia",
          disabled: true,
        },
        {
          id: 1,
          name: "Niezatrudniony",
        },
        {
          id: 2,
          name: "Zatrudniony",
        },
      ],
      modalUserDetails: {
        open: false,
        data: {},
      },
      modalAddOSH: {
        open: false,
        data: {},
      },
      modalUser: {
        open: false,
      },
      modalAddUser: {
        open: false,
        user: {},
      },
    };

    this.addOSH = this.addOSH.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.getOSHList = this.getOSHList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.print = this.print.bind(this);
    this.saveUserCallback = this.saveUserCallback.bind(this);
  }

  addOSH(data) {
    let { dispatch } = this.props;
    addOSH(data)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        this.setState({
          modalUserDetails: {
            ...this.state.modalUserDetails,
            data: action.payload.data.data,
          },
        });
        this.getOSHList(() => {});
      })
    );
  }

  clearFilter(form) {
    let { dispatch } = this.props;
    this.form.form.mutators.clearFilterMutator();
    getOSHList()(dispatch).then(this.handleServerResponse());
  }

  filterSubmit(data) {
    let { dispatch } = this.props;

    let filterZeroId = (data) => {
      let keys = [
        "qe_type_id",
        "localization_id",
        "section_name",
        "workplace_id",
        "hired_status_id",
        "is_osh_actual",
      ];

      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = null;
        }
      }
      return data;
    };

    const sendData = { ...data };

    const {
      localization: { data: localizations },
    } = this.props;

    if (sendData.localization_id) {
      const localizationsData = localizations.find((i) => {
        return i.id == sendData.localization_id;
      });
      if (localizationsData && localizationsData.division) {
        sendData.division_id = data.localization_id;
        delete sendData.localization_id;
      }
    }

    getOSHListFilter(filterZeroId(sendData))(dispatch).then(
      this.handleServerResponse()
    );
  }

  componentDidMount() {
    let { dispatch } = this.props;
    this.getOSHList();
    getOSHTypeList()(dispatch).then(this.handleServerResponse());

    getUsersLocalizationsAndDivisions()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    // getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());
    getWorkplacesTreeByLocalization()(dispatch).then(
      this.handleServerResponse()
    );
  }

  getOSHList(cb) {
    let { dispatch } = this.props;

    getOSHList()(dispatch).then(this.handleServerResponse({}, cb));
  }

  getOSHActual(item) {
    let { is_osh_actual } = item;
    if (is_osh_actual === true) {
      return "TAK";
    }
    if (is_osh_actual === false) {
      return "NIE";
    }
    return null;
  }

  getHiredStatus(id) {
    if (!id) {
      return "Niezatrudniony";
    }

    return this.state.hired_statuses.find((i) => {
      return i.id == id;
    }).name;
  }

  handlePageChange(data) {
    this.setState({ data });
  }

  print(data) {
    let { dispatch } = this.props;
    printOSHReport(data)(dispatch).then((res) => {
      if (!res.error) {
        let file;
        let fileName;

        let fileNamePrefix = `Rejestr szkoleń BHP - ${moment().format(
          "YYYY-MM-DD"
        )}`;

        if (data.pdf) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.pdf`;
        }
        if (data.xml) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.xls`;
        }

        saveBlobFile(file, fileName);
      }
    });
  }

  saveUserCallback({ message }) {
    this.getOSHList(() => {
      this.setSuccessMessage(message);
    });
    this.closeModal("modalAddUser");
  }

  render() {
    const { message, data, hired_statuses } = this.state;
    let {
      localization: { data: localizationData },
      section: { data: sectionData },
      workplace: { data: workplaceData },
      permission,
    } = this.props;

    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={<SideMenu />}
      >
        <PageHeader>Rejestr szkoleń BHP</PageHeader>
        <ButtonWrapper>
          <GenerateRaport disabled={!permission[45]} print={this.print} />
          <ButtonWithIcon
            onClick={this.openModal("modalAddUser")}
            icon="fa fa-plus"
            name="Dodaj pracownika"
            disabled={!permission[63]}
          />
        </ButtonWrapper>
        <TableSortableWithPagination
          data={this.props.data}
          renderSearcher={
            <Form
              ref={(form) => (this.form = form)}
              initialValues={{
                localization_id: "0",
                section_name: "0",
                workplace_id: "0",
                hired_status_id: "0",
                is_osh_actual: "0",
              }}
              mutators={{ clearFilterMutator }}
              onSubmit={this.filterSubmit}
              render={({ handleSubmit, pristine, invalid, form }) => {
                this.form = { form };

                return (
                  <>
                    <tr>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"last_name"}
                          placeholder={"wg Nazwiska"}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"first_name"}
                          placeholder={"wg Imienia"}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"localization_id"}
                          data={[
                            { id: "0", name: "Lokalizacja", disabled: true },
                          ].concat(localizationData)}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"section_name"}
                          propValue="value"
                          data={[
                            { value: "0", name: "Dział", disabled: true },
                          ].concat(sectionData)}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"workplace_id"}
                          data={[
                            { id: "0", name: "Stanowisko", disabled: true },
                          ].concat(workplaceData)}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_employment"} />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_initial"} />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_job"} />
                      </td>

                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_periodic"} />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_next"} />
                      </td>
                      <td>
                        <FieldSelect
                          name={"hired_status_id"}
                          data={this.state.hired_statuses}
                        />
                      </td>
                      <td></td>
                      <td>
                        <FieldSelect
                          name={"is_osh_actual"}
                          data={[
                            {
                              id: "0",
                              name: "Aktualność szkoleń",
                            },
                            {
                              id: "true",
                              name: "Tak",
                            },
                            {
                              id: "false",
                              name: "Nie",
                            },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="13" style={{ padding: "8px 40px 8px 8px" }}>
                        <ButtonWrapper nowrap>
                          <Button
                            type="button"
                            onClick={() => {
                              handleSubmit();
                            }}
                            bsStyle="primary"
                          >
                            Szukaj
                          </Button>
                          <Button onClick={this.clearFilter}>
                            Wyłącz filtr
                          </Button>
                        </ButtonWrapper>
                      </td>
                    </tr>
                  </>
                );
              }}
            />
          }
          headers={[
            { name: "Nazwisko", prop: "last_name" },
            { name: "Imię", prop: "first_name" },
            {
              name: "Lokalizacja",
              prop: "localization_id",
            },
            { name: "Dział", prop: "section_name" },
            { name: "Stanowisko", prop: "workplace_id" },
            { name: "Data zatrudnienia", prop: "date_employment" },
            {
              name: "Szkolenie wstępne BHP instruktaż ogólny",
              prop: "date_initial",
            },
            {
              name: "Szkolenie wstępne BHP instruktaż stanowiskowy",
              prop: "date_job",
            },
            {
              name: "Szkolenie okresowe BHP",
              prop: "date_periodic",
            },
            {
              name: "Termin następnego szkolenia BHP",
              prop: "date_next",
            },
            {
              name: "Status zatrudnienia",
              prop: "hired_status_name",
            },
            {
              name: "Liczba dni do kolejnego szkolenia",
              prop: "days_left",
            },
            {
              name: "Aktualność szkoleń",
              prop: "is_osh_actual",
            },
            { name: "Edytuj dane" },
          ]}
          render={(sortedData) => {
            return sortedData.map((item) => {
              return (
                <tr>
                  <td>
                    <AStyled
                      onClick={this.openModal("modalUserDetails", {
                        data: item,
                      })}
                    >
                      {item.last_name}
                    </AStyled>
                  </td>
                  <td>{item.first_name}</td>
                  <td>{getLocalizationDivisionName(item)}</td>
                  <td>{item.section_name}</td>
                  <td>{item.workplace_name}</td>
                  <td>{getDate(item.date_employment)}</td>
                  <InitialJobTrainingDate>
                    {item.date_initial}
                  </InitialJobTrainingDate>
                  <InitialJobTrainingDate>
                    {item.date_job}
                  </InitialJobTrainingDate>

                  <td>{getDate(item.date_periodic)}</td>
                  <td>{getDate(item.date_next)}</td>
                  <td item={item}>
                    {this.getHiredStatus(item.hired_status_id)}
                  </td>
                  <ProgressTd item={item}>{item.days_left}</ProgressTd>
                  <ProgressTd item={item}>{this.getOSHActual(item)}</ProgressTd>

                  <td>
                    <ButtonWithIcon
                      disabled={!permission[65]}
                      onClick={this.openModal("modalAddUser", {
                        users_id: item.users_id,
                      })}
                      icon="fa fa-pencil-square-o"
                    />
                  </td>
                </tr>
              );
            });
          }}
        />

        <AddOSH
          addOSH={this.addOSH}
          open={this.state.modalAddOSH.open}
          data={this.state.modalAddOSH.data}
          types={this.props.types}
          closeModal={this.closeModal("modalAddOSH")}
          permission={permission}
        />
        <SummaryOSH
          addOSH={this.openModal("modalAddOSH", {
            data: (() => {
              return { user_id: this.state.modalUserDetails.data.users_id };
            })(),
          })}
          data={this.state.modalUserDetails.data}
          open={this.state.modalUserDetails.open}
          closeModal={this.closeModal("modalUserDetails")}
          permission={permission}
        />
        <UserModal
          open={this.state.modalUser.open}
          closeModal={this.closeModal("modalUser")}
          permission={permission}
        />
        <ModalAddUser
          {...this.state.modalAddUser}
          accept={this.saveUserCallback}
          closeModal={this.closeModal("modalAddUser")}
          permission={permission}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  permission: store.osh.permission,
  data: store.osh.data,
  types: store.osh.types,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
}))(List);
