import React from "react";
import PropTypes from "prop-types";
import { messageCleaner } from "../actions/messageActions";

export const setMessage = (message = "", className = "", rest = {}) => {
  return {
    text: message,
    className: className,
    ...rest,
  };
};

export class ModelComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      message: setMessage(),
      error_code: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.setUrls = this.setUrls.bind(this);
  }

  initModalState() {
    return {
      open: false,
      entry_id: null,
    };
  }

  setUrls(defaultUrls) {
    let { urls } = this.props;
    let u = typeof urls === "object" && urls !== null ? urls : {};
    return { ...defaultUrls, ...u };
  }

  openModal(name, data = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true, ...data } });
    };
  }

  closeModal(name, data = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false, ...data } });
    };
  }
  changePage(data) {
    this.setState({ data });
  }

  setSuccessMessage(messageR, className, rest) {
    let message = { text: "", className: "" };

    message = setMessage(messageR, "success", rest);
    this.setState({ message });
  }
}

export default class AppComponent extends ModelComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      message: setMessage(),
      error_code: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.filterZeroId = this.filterZeroId.bind(this);
  }

  handlePageChange(data) {
    this.setState({ data });
  }

  filterZeroId(keys) {
    return (data) => {
      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = null;
        }
      }
      return data;
    };
  }

  handleServerResponse(rest = {}, callback) {
    return (action) => {
      const thisState = this.state;
      const thisProps = this.props;
      const { history } = this.props;
      let message = { text: "", className: "" };

      if (!action.error) {
        if (callback) {
          callback(action, thisState, thisProps);
          return false;
        } else {
          message = setMessage(action.payload.data.message, "success");
        }

        this.setState({ message, ...rest });
      } else {
        let error_code = this.state.error_code;
        const err = action.error.response;
        switch (err.status) {
          case 301: {
            if (err.data.data && err.data.data.url) {
              history.push(err.data.data.url);
            } else {
              history.push("/");
            }
            return false;
          }

          case 401: {
            const pathName = this.props.location.pathname;
            const routeGroup = pathName.split("/")[1] || "";
            const url = (() => {
              switch (routeGroup) {
                case "super-admin":
                  return "/super-admin/login";
                case "business": {
                  return "/business/login";
                }
                default:
                  return "/login";
              }
            })();
            history.push(url);
            return false;
          }
          case 403: {
            error_code = error_code || 403;
            message = setMessage(
              "Brak uprawnień do wykonania czynności",
              "danger"
            );
            this.setState({ message });

            break;
          }
          case 404: {
            history.replace("/404");
            return false;
          }
          case 502: {
            history.push("/502");
            return false;
          }
          default: {
          }
        }
        message = setMessage(err.data.message, "danger");
        this.setState({ message, ...rest, error_code });
      }
    };
  }

  updateMessage(entryName) {
    const { dispatch, message: entry } = this.props;
    const { error: reducerError = "", message: reducerMessage = "" } = entry;
    const { text } = this.state.message;
    if (reducerError.length) {
      messageCleaner(reducerError)(dispatch);
      if (reducerError !== text) {
        this.setState({ message: { text: reducerError, className: "danger" } });
      }
    } else if (reducerMessage.length) {
      messageCleaner(reducerMessage)(dispatch);
      if (reducerMessage !== text) {
        this.setState({
          message: { text: reducerMessage, className: "success" },
        });
      }
    }
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

AppComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
