import React from "react";
import { connect } from "react-redux";
import { addTaskByIncident } from "actions/taskActions";
import {
  pingIncidentTask,
  addTaskAttachmentLocal,
  deleteTaskAttachmentLocal,
} from "actions/taskActions";

import { TaskAdd } from "container/Task/Add";
import LoadingScreen from "components/LoadingScreen/";
import TaskForm from "components/Task.Form";
import dataToFormData from "components/dataToFormData";
import SideMenu from "../_SideMenu";
import { addNewIncident } from "../defaultComponent";

class IncidentTaskAdd extends TaskAdd {
  constructor(props) {
    super(props);
    this.addNewIncident = addNewIncident.bind(this);
  }

  _pingTask() {
    const { dispatch } = this.props;
    pingIncidentTask()(dispatch).then(this.handleServerResponse());
  }

  handleSubmit(data) {
    const { dispatch, history, match, task } = this.props;
    const { incident_id } = match.params;
    const { attachments } = task;
    for (let attachment of attachments) {
      data[`task_new_attachment_file_${attachment.id}`] = attachment.fileData;
      data[`task_new_attachment_name_${attachment.id}`] = attachment.name;
    }
    const formData = dataToFormData(data);

    addTaskByIncident(
      incident_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/incident/task/${incident_id}`);
      })
    );
  }

  render() {
    let {
      match,
      incident: { permission },
      task: { attachments },
    } = this.props;
    const { incident_id } = match.params;
    const LSideMenu = (props) => (
      <SideMenu
        incident_id={incident_id}
        permission={permission}
        addNewIncident={this.addNewIncident}
      />
    );

    let permissionTask = {
      ...permission,
    };

    if (permission[12]) {
      permissionTask[2] = true;
      permissionTask[3] = true;
    }

    return (
      <LoadingScreen fetched={true}>
        <TaskForm
          SideMenu={LSideMenu}
          noLocalization
          mobileNotHideMenu={true}
          permission={permissionTask}
          attachments={attachments}
          data={{
            priority: 2,
          }}
          handleSubmit={this.handleSubmit}
          backUrl={`/incident/task/${incident_id}`}
          createView
          addAttachment={addTaskAttachmentLocal}
          deleteAttachment={deleteTaskAttachmentLocal}
        />
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
  localization: store.localization,
  section: store.section,
  task: store.task,
}))(IncidentTaskAdd);
