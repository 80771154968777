export function login(data) {
  return dispatch => dispatch({
    types: ["LOGIN_REQUEST", "LOGIN_SUCCESS", "LOGIN_ERROR"],
    payload: {
      request: {
        url: "/login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}

export function logout(data) {
  return dispatch => dispatch({
    types: ["LOGOUT_BUSINESS_ADMIN_REQUEST", "LOGOUT_BUSINESS_ADMIN_SUCCESS", "LOGOUT_BUSINESS_ADMIN_ERROR"],
    payload: {
      request: {
        url: "/business/logout",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}

export function logoutUser(data) {
  return dispatch => dispatch({
    types: ["LOGOUT_USER_REQUEST", "LOGOUT_USER_SUCCESS", "LOGOUT_USER_ERROR"],
    payload: {
      request: {
        url: "/logout",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}

export function getAccountData() {
  return dispatch => dispatch({
    types: ["GET_ACCOUNT_DATA_REQUEST", "GET_ACCOUNT_DATA_SUCCESS", "GET_ACCOUNT_DATA_ERROR"],
    payload: {
      request: {
        url: "/get-account-data",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function getSuperAdminAccountData(){
  return dispatch => dispatch({
    types: ["GET_ACCOUNT_DATA_REQUEST", "GET_ACCOUNT_DATA_SUCCESS", "GET_ACCOUNT_DATA_ERROR"],
    payload:{
      request: {
        url: "/super-admin/get-account-data",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function getBusinessAccountData() {
  return dispatch => dispatch({
    types: ["GET_ACCOUNT_DATA_REQUEST", "GET_ACCOUNT_DATA_SUCCESS", "GET_ACCOUNT_DATA_ERROR"],
    payload: {
      request: {
        url: "/business/get-account-data",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function getFullAccountData() {
  return dispatch => dispatch({
    types: ["GET_FULL_ACCOUNT_DATA_REQUEST", "GET_FULL_ACCOUNT_DATA_SUCCESS", "GET_FULL_ACCOUNT_DATA_ERROR"],
    payload: {
      request: {
        url: "/get-account-edit-data",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function getBusinessFullAccountData() {
  return dispatch => dispatch({
    types: ["GET_FULL_ACCOUNT_DATA_REQUEST", "GET_FULL_ACCOUNT_DATA_SUCCESS", "GET_FULL_ACCOUNT_DATA_ERROR"],
    payload: {
      request: {
        url: "/business/get-account-edit-data",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function saveAvatar(data) {
  return dispatch => dispatch({
    types: ["SAVE_AVATAR_REQUEST", "SAVE_AVATAR_SUCCESS", "SAVE_AVATAR_ERROR"],
    payload: {
      request: {
        url: "/account/save-avatar",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data
      },
    }
  });
}

export function saveSuperAdminAvatar(data) {
  return dispatch => dispatch({
    types: ["SAVE_AVATAR_REQUEST", "SAVE_AVATAR_SUCCESS", "SAVE_AVATAR_ERROR"],
    payload: {
      request: {
        url: "/super-admin/account/save-avatar",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data
      },
    }
  });
}

export function deleteAvatar() {
  return dispatch => dispatch({
    types: ["DELETE_AVATAR_REQUEST", "DELETE_AVATAR_SUCCESS", "DELETE_AVATAR_ERROR"],
    payload: {
      request: {
        url: "/account/delete-avatar",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function deleteSuperAdminAvatar() {
  return dispatch => dispatch({
    types: ["DELETE_AVATAR_REQUEST", "DELETE_AVATAR_SUCCESS", "DELETE_AVATAR_ERROR"],
    payload: {
      request: {
        url: "/super-admin/account/delete-avatar",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function saveNewPassword(data) {
  return dispatch => dispatch({
    types: ["SAVE_PASSWORD_REQUEST", "SAVE_PASSWORD_SUCCESS", "SAVE_PASSWORD_ERROR"],
    payload: {
      request: {
        url: "/account/save-new-password",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}
export function saveSuperAdminNewPassword(data) {
  return dispatch => dispatch({
    types: ["SAVE_PASSWORD_REQUEST", "SAVE_PASSWORD_SUCCESS", "SAVE_PASSWORD_ERROR"],
    payload: {
      request: {
        url: "/super-admin/account/save-new-password",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}

export function saveAccount(data) {
  return dispatch => dispatch({
    types: ["SAVE_ACCOUNT_REQUEST", "SAVE_ACCOUNT_SUCCESS", "SAVE_ACCOUNT_ERROR"],
    payload: {
      request: {
        url: "/account/save-account",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}

export function saveSuperAdminAccount(data) {
  return dispatch => dispatch({
    types: ["SAVE_ACCOUNT_REQUEST", "SAVE_ACCOUNT_SUCCESS", "SAVE_ACCOUNT_ERROR"],
    payload: {
      request: {
        url: "/super-admin/account/save-account",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      },
    }
  });
}

export function loginToBusinessAccount() {
  return dispatch => dispatch({
    types: ["LOGIN_TO_BUSINESS_ACCOUNT_REQUEST", "LOGIN_TO_BUSINESS_ACCOUNT_SUCCESS", "LOGIN_TO_BUSINESS_ACCOUNT_ERROR"],
    payload: {
      request: {
        url: "/account/business-login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function changeShowSettings(data) {
  return dispatch => dispatch({
    types: ["CHANGE_SHOW_SETTINGS_ACCOUNT_REQUEST", "CHANGE_SHOW_SETTINGS_ACCOUNT_SUCCESS", "CHANGE_SHOW_SETTINGS_ACCOUNT_ERROR"],
    payload: {
      request: {
        url: "/account/change-show-settings",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}
