import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import Modal from "components/Modal/SimpleAccept";

import validator from "components/Validation";
import { StyledInputBox } from "style/styleComponents/Form/input";
import { StyledLabel } from "style/styleComponents/Form/input";
const InputWrapper = styled.div`
  display: flex;
  /* margin: 0 -10px; */
  align-items: flex-start;
  & > * {
    &:nth-child(1) {
      flex-basis: 100%;
    }
    &:nth-child(2) {
      margin: 0 0 0 10px;
    }
  }
`;

let AddButton = styled(Button)`
  margin-top: 10px;
`;

export default class FieldArrayComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        open: false,
      },
    };
  }

  acceptModal() {
    const { callback } = this.state.modal;
    callback();
    this.modalClose();
  }

  openModal() {
    this.setState({
      modal: { ...this.state.modal, open: true },
    });
  }

  closeModal() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
        entry_id: false,
        entry_name: null,
        callback: () => {},
      },
    });
  }

  render() {
    let { modal } = this.state;
    let {
      defaultValue = {},
      name,
      render: propsRender,
      label,
      renderButton,
    } = this.props;

    return (
      <Fragment>
        <StyledLabel>{label}</StyledLabel>
        <FieldArray name={name}>
          {({ fields }) => (
            <Fragment>
              {fields.map((name, index) => {
                const field = fields.value[index];
                return (
                  <InputWrapper>
                    {propsRender({ name, index })}
                    <Button
                      onClick={() => {
                        if (this.props.removeProp) {
                          this.props.removeProp(fields, index);
                        } else {
                          fields.remove(index);
                        }
                      }}
                    >
                      <i className={"fa fa-trash"} />
                    </Button>
                  </InputWrapper>
                );
              })}

              <>
                {renderButton ? (
                  renderButton(() => fields.push(defaultValue))
                ) : (
                  <AddButton
                    style={{ marginTop: "10px" }}
                    onClick={() => fields.push(defaultValue)}
                  >
                    <i className={"fa fa-plus"} />
                  </AddButton>
                )}
              </>
            </Fragment>
          )}
        </FieldArray>
        <Modal
          open={modal.open}
          title={`Usuń wpis`}
          description={"Czy na pewno chcesz usunąć dany wpis? "}
          handleAccept={this.acceptModal}
          handleClose={this.closeModal}
        />{" "}
      </Fragment>
    );
  }
}
