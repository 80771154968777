import React from "react";
import { connect } from "react-redux";
import Header from "../components/Header/";
import Layout from "../components/Layout";
import * as BusinessAccounts from "../container/BusinessAccounts";
import * as ClientsAccounts from "../container/ClientsAccounts";
import { Route, Switch } from "react-router-dom";
import SuperDashboardContainer from "../container/SuperDashboard";
import { logout } from "../actions/superAdminActions";
import Mails from "../container/Mails";
import * as Page from "../container/Page";
import * as SuperAdminDrafts from "../container/SuperAdminDrafts";
import SuperAdminAccountEdit from "../container/SuperAdmin/Edit";
import { getSuperAdminAccountData } from "../actions/accountActions";
import AppComponent from "../components/AppComponent";
import Configuration from "../container/Configuration";
import NotFound from "../container/NotFound/404SuperAdmin";
import { getPublicMenuPages } from "../actions/pageActions";
import { WhiteBorder } from "../components/Header/_styleComponents";

export class SuperAdminApp extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state };
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const {
      account: { singleData: accountData },
      dispatch,
    } = this.props;
    getPublicMenuPages()(dispatch);
    if (!Object.keys(accountData).length) {
      getSuperAdminAccountData()(dispatch).then(this.handleServerResponse());
    }
  }

  handleLogout() {
    const { dispatch, history } = this.props;

    logout()(dispatch).then(() => {
      history.push("/super-admin/login");
    });
  }

  render() {
    const {
      account: {
        singleData: { first_name, last_name, avatar_url },
      },
    } = this.props;
    //TODO MUST podmienić domyślny avatar
    const Menu = {
      left: [
        {
          url: "/super-admin/dashboard",
          title: "Strona główna",
          class: "fa fa-home",
        },
        {
          url: "/super-admin/businesses",
          title: "Konta firm",
          class: "fa fa-users",
        },
        {
          url: "/super-admin/mails",
          title: "Ustawienia wiadomości e-mail",
          class: "fa fa-envelope",
        },
        {
          url: "/super-admin/page",
          title: "Strony statyczne",
          class: "fa fa-file",
        },
        {
          title: "Strony statyczne",
          class: "fa fa-files-o",
          children: [
            {
              name: "Szablony audytowych list kontrolnych",
              url: "/super-admin/draft/audit-type",
            },
            {
              name: "Szablony metod oceny ryzyka zawodowego",
              url: "/super-admin/draft/ra-method",
            },
            {
              name: "Szablony wzorcowych ocen ryzyka zawodowego",
              url: "/super-admin/draft/risk-assessment",
            },
            {
              name: "Szablony list kontrolnych identyfikacji zagrożeń",
              url: "/super-admin/draft/risk-assessment-control-list-template",
            },
            {
              name: "Szablony wzorcowych zagrożeń",
              url: "/super-admin/draft/model-hazards",
            },
            {
              name: "Szablony rodzajów uprawnień kwalifikacyjnych",
              url: "/super-admin/draft/qe-type",
            },
          ],
        },
        {
          url: "/super-admin/configuration",
          title: "Ustawienia systemu",
          class: "fa fa-cog",
        },
        { render: (props) => <WhiteBorder /> },
        {
          class: "",
          title: `${first_name} ${last_name}`,
          account: true,
          image: true,
          imageUrl: avatar_url,
          children: [
            { name: "Ustawienia konta", url: "/super-admin/account/edit" },
          ],
        },
        {
          url: "/dashboard",
          title: "Wyloguj",
          class: "ehs-menu ehs-menu-logout",
          onClick: this.handleLogout,
        },
      ],
      right: [],
    };
    const logged = !!Object.keys(this.props.account.singleData).length;
    return (
      <div style={{ minHeight: "100vh" }}>
        {logged && (
          <Header mainPageUrl={"/super-admin/dashboard"} menu={Menu} />
        )}
        {logged && (
          <Layout>
            <Switch>
              <Route
                exact
                path="/super-admin/dashboard"
                component={SuperDashboardContainer}
              />
              <Route
                exact
                path="/super-admin/clients-accounts"
                component={ClientsAccounts.List}
              />
              <Route
                exact
                path="/super-admin/clients-accounts/add"
                component={ClientsAccounts.Add}
              />
              <Route
                exact
                path="/super-admin/clients-accounts/edit/:id"
                component={ClientsAccounts.Edit}
              />
              <Route
                exact
                path="/super-admin/businesses"
                component={BusinessAccounts.List}
              />
              <Route
                exact
                path="/super-admin/businesses/add"
                component={BusinessAccounts.Add}
              />
              <Route
                exact
                path="/super-admin/businesses/edit/:business_id"
                component={BusinessAccounts.Edit}
              />
              <Route exact path="/super-admin/mails" component={Mails} />
              <Route
                exact
                path="/super-admin/mails/:mailing_group_id"
                component={Mails}
              />
              <Route
                exact
                path="/super-admin/account/edit"
                component={SuperAdminAccountEdit}
              />
              <Route exact path="/super-admin/page" component={Page.List} />
              <Route exact path="/super-admin/page/add" component={Page.Add} />
              <Route
                exact
                path="/super-admin/page/:page_id"
                component={Page.Edit}
              />
              <Route
                exact
                path="/super-admin/draft/audit-type"
                component={SuperAdminDrafts.AuditTypeList}
              />
              <Route
                exact
                path="/super-admin/draft/audit-type/add"
                component={SuperAdminDrafts.AuditTypeAdd}
              />
              <Route
                exact
                path="/super-admin/draft/audit-type/edit/:audit_type_id"
                component={SuperAdminDrafts.AuditTypeEdit}
              />
              <Route
                exact
                path="/super-admin/draft/ra-method"
                component={SuperAdminDrafts.RiskAssessmentList}
              />
              <Route
                exact
                path="/super-admin/draft/ra-method/add"
                component={SuperAdminDrafts.RiskAssessmentAdd}
              />
              <Route
                exact
                path="/super-admin/draft/ra-method/edit/:risk_assessment_id"
                component={SuperAdminDrafts.RiskAssessmentEdit}
              />
              <Route
                exact
                path="/super-admin/draft/hazard-identification/add"
                component={SuperAdminDrafts.HazardIdentificationAdd}
              />
              <Route
                exact
                path="/super-admin/draft/hazard-identification/list"
                component={SuperAdminDrafts.HazardIdentificationList}
              />

              <Route
                exact
                path="/super-admin/draft/risk-assessment"
                component={SuperAdminDrafts.RiskAssessmentTemplateList}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment/basic"
                component={SuperAdminDrafts.RiskAssessmentTemplateBasic}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment/basic/:id"
                component={SuperAdminDrafts.RiskAssessmentTemplateBasic}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment/danger/:id"
                component={SuperAdminDrafts.RiskAssessmentTemplateListDanger}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment/characteristic/:id"
                component={
                  SuperAdminDrafts.RiskAssessmentTemplateAddCharacteristic
                }
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment/analysis/:id"
                component={SuperAdminDrafts.RiskAssessmentTemplateAnalysis}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment-control-list-template"
                component={SuperAdminDrafts.RiskAssessmentTemplateControlList}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment-control-list-template/add"
                component={SuperAdminDrafts.RiskAssessmentTemplateControlAdd}
              />
              <Route
                exact
                path="/super-admin/draft/risk-assessment-control-list-template/edit/:id"
                component={SuperAdminDrafts.RiskAssessmentTemplateControlEdit}
              />
              <Route
                exact
                path="/super-admin/draft/model-hazards"
                component={SuperAdminDrafts.ModelHazardList}
              />
              <Route
                exact
                path="/super-admin/draft/model-hazards/add"
                component={SuperAdminDrafts.ModelHazardAdd}
              />
              <Route
                exact
                path="/super-admin/draft/model-hazards/edit/:id"
                component={SuperAdminDrafts.ModelHazardEdit}
              />
              {/* <Route exact path="/super-admin/draft/risk-assessment/edit/:risk_assessment_id" component={SuperAdminDrafts.RiskAssessmentEdit}/> */}

              {/* QE_TYPE */}
              <Route
                exact
                path="/super-admin/draft/qe-type"
                component={SuperAdminDrafts.QETypeList}
              />
              <Route
                exact
                path="/super-admin/draft/qe-type/add"
                component={SuperAdminDrafts.QETypeAdd}
              />
              <Route
                exact
                path="/super-admin/draft/qe-type/edit/:id"
                component={SuperAdminDrafts.QETypeEdit}
              />
              <Route
                exact
                path="/super-admin/configuration"
                component={Configuration}
              />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  account: store.account,
  superadmin: store.superadmin,
}))(SuperAdminApp);
