import React, { Fragment } from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/lib/Button";
import { getIncidentWitness } from "actions/incidentActions";
import { getPublicUserBasics } from "actions/userActions";
import ModalWitnessForm from "./Form";
import ModalWitnessList from "./List";
import AppComponent from "components/AppComponent";
import { getFullResidencePlace } from "container/Incident/AnalysisForm/common";

class WitnessComponent extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalWitness: {
        open: false,
      },
      modalWitnessList: {
        open: false,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {}

  openModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true } });
    };
  }

  closeModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false } });
    };
  }

  getUser(id, form) {
    const { dispatch } = this.props;

    getPublicUserBasics(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let user = action.payload.data.user;
        let keys = Object.keys(user);
        let { date_birth, birth_place, id_document_number } = user;
        let workplace_localization = user.division_name
          ? user.division_name
          : user.localization_name
          ? `${user.localization_name}${
              user.section_name ? `/${user.section_name}` : ""
            }`
          : null;

        form.change("birth_date", date_birth);
        form.change("birth_place", birth_place);
        form.change("residence_fulladdress", getFullResidencePlace(user));
        form.change("id_document_number", id_document_number);
        form.change("workplace_localization", workplace_localization);

        form.change("pesel", user.pesel);
        form.change("workplace_name", user.workplace_name);
      })
    );
  }

  render() {
    let {
      incident_id,
      incident_witnesses,
      users,
      saveWitness,
      deleteWitness,
    } = this.props;
    return (
      <Fragment>
        <div>
          <Button onClick={this.openModal("modalWitnessList")}>
            Dodaj informację od świadków
          </Button>
        </div>
        <ModalWitnessList
          data={incident_witnesses}
          {...this.state.modalWitnessList}
          incident_id={incident_id}
          deleteWitness={deleteWitness}
          switchModal={(index) => {
            this.setState({
              modalWitness: {
                ...this.state.modalWitness,
                open: true,
                data: incident_witnesses[index],
                index: index,
              },
            });
            this.openModal("modalWitness");
          }}
          closeModal={this.closeModal("modalWitnessList")}
        />
        <ModalWitnessForm
          incident_id={incident_id}
          {...this.state.modalWitness}
          submit={saveWitness}
          users={users}
          getUser={this.getUser}
          closeModal={() => {
            this.setState({
              modalWitnessList: { ...this.state.modalWitnessList, open: true },
              modalWitness: { ...this.state.modalWitness, open: false },
            });
          }}
        />
      </Fragment>
    );
  }
}

export default connect(() => {})(WitnessComponent);
