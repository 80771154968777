import React, { Fragment } from "react";
import { connect } from "react-redux";
import { LinkContainer } from "../../components/LinkContainer";
import AppComponent, { setMessage } from "../../components/AppComponent";
import { LayoutContent } from "../../components/Layout";
import { ButtonWrapper } from "../../components/ListComponents";
import Box from "../../components/Box";

import Table from "react-bootstrap/lib/Table";
import DeleteModal from "../../components/Modal/SimpleAccept";
import AddModal from "../../components/Modal/FormModal";
import { media } from "../../style/style-utils";
import styled from "styled-components";
import { Field } from "react-final-form";
import { StyledInputBox } from "../../style/styleComponents/Form/input";
import {
  clearIncidentReducer,
  getAttachments,
  addAttachment,
  deleteAttachment,
  getIncidentToRegister,
  downloadInsert,
  acceptAttachments,
} from "../../actions/incidentActions";
import dataToFormData from "../../components/dataToFormData";

import SideMenu from "./_SideMenu";
import Button from "react-bootstrap/lib/Button";
import IncidentDefaultComponent from "./defaultComponent";

import validator, { composeValidators } from "../../components/Validation";
import PDFModal, {
  PDFViewModalSchema,
} from "../../components/Modal/PDFViewModal";
import ImageModal, {
  ImageViewModalSchema,
} from "../../components/Modal/ImageViewModal";

import { SpanAsLink } from "../../components/ListComponents";
import AttachmentList from "components/AttachmentList";

class IncidentAttachment extends IncidentDefaultComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      addModal: {
        open: false,
      },
      pdfModal: { ...PDFViewModalSchema },
      imageModal: { ...ImageViewModalSchema },
      deleteModal: {
        open: false,
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.setNextStage = this.setNextStage.bind(this);
    this.closeViewModal = this.closeViewModal.bind(this);
    this.setMessege = this.setMessege.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      incident: { singleData: incidentData },
    } = this.props;
    const { incident_id } = match.params;

    getAttachments(incident_id)(dispatch).then(this.handleServerResponse());
    if (!Object.keys(incidentData).length || incidentData.id != incident_id) {
      getIncidentToRegister(incident_id)(dispatch).then(
        this.handleServerResponse()
      );
    }
  }

  openModal(name, rest = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true, ...rest } });
    };
  }

  openViewModal(modal_name) {
    return (index) => {
      const data = this.props.incident.attachments;
      const { name, fileName, url } = data[index];
      if (/\.(pdf|jpg|png|svg)$/.test(url)) {
        this.setState({
          [modal_name]: {
            ...this.state.pdfModal,
            open: true,
            name: name,
            fileName,
            index,
            simpleUrl: url,
            file: `/api${url}`,
          },
        });
      }
    };
  }

  setMessege(message) {
    this.setState({
      message,
    });
  }

  closeViewModal(modalName) {
    return () => {
      const schema =
        modalName == "pdfModal" ? PDFViewModalSchema : ImageViewModalSchema;
      this.setState({ [modalName]: { ...schema } });
    };
  }

  closeModal(name, rest = {}) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false, ...rest } });
    };
  }

  addAttachment(data) {
    const that = this;
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    const formData = dataToFormData(data);
    addAttachment(
      incident_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        that.closeModal("addModal");
        this.setState({
          message: setMessage(action.payload.data.message, "success"),
        });
      })
    );
  }

  handleModalClose(modalName) {
    this.setState({ [modalName]: { ...PDFViewModalSchema } });
  }

  deleteAttachment() {
    const { id } = this.state.deleteModal;
    const { dispatch, match } = this.props;
    const { incident_id } = match.params;
    deleteAttachment(
      incident_id,
      id
    )(dispatch).then(this.handleServerResponse());
  }

  setNextStage() {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    acceptAttachments(incident_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/incident/analysis/${incident_id}`);
      })
    );
  }

  render() {
    let { addModal, deleteModal, message, pdfModal, imageModal } = this.state;
    const {
      dispatch,
      incident: {
        attachments,
        permission,
        singleData: incidentData,
        attachmentAddFetching,
      },
      match,
    } = this.props;
    const { incident_id } = match.params;
    const readOnly = !permission[11];
    return (
      <Fragment>
        <LayoutContent
          mobileNotHideMenu={true}
          SideMenu={
            <SideMenu
              permission={permission}
              stage={incidentData.stage}
              incident_id={incident_id}
            />
          }
          error404={this.state.error_code == 404}
          message={message}
        >
          <Box title={"Załączniki"}>
            <AttachmentList
              attachmentAddFetching={attachmentAddFetching}
              permission={permission}
              dispatch={dispatch}
              entry_id={incident_id}
              attachments={attachments}
              addAttachmentAction={addAttachment}
              deleteAttachmentAction={deleteAttachment}
              downloadAction={downloadInsert}
              setParentMessage={this.setMessege}
              readOnly={readOnly}
            />
            <ButtonWrapper>
              {readOnly ? (
                <LinkContainer to={`/incident/analysis/${incident_id}`} exact>
                  <Button disabled={incidentData.stage < 2} bsStyle="primary">
                    Dalej
                  </Button>
                </LinkContainer>
              ) : (
                <Button onClick={this.setNextStage} bsStyle="primary">
                  Dalej
                </Button>
              )}
              <LinkContainer to={`/incident`}>
                <Button>Anuluj</Button>
              </LinkContainer>
            </ButtonWrapper>
          </Box>
        </LayoutContent>
      </Fragment>
    );
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    clearIncidentReducer()(dispatch);
  }
}

export default connect((store) => ({
  incident: store.incident,
}))(IncidentAttachment);
