const defaultData = {
  dangerColor: "#f2dede",
  warningColor: "#fcf8e3",
  primaryColor: "#5f7bc0",
  primaryLighterColor: "#B4B9D0",
  primaryLightColor: "#57b4cc",
  primaryDarkColor: "#49509d",
  primaryDarknessColor: "#43468a",
  primaryTextColor: "#fff",
  tableBorderColor: "#ddd",
  tableSecondaryColor: "#f9f9f9",

  navBar: {
    background: "#5f7bc0",
    backgroundDarkColor: "49509d",
  },
  panelHeading: {
    background: "#5f7bc0",
    backgroundDarkColor: "49509d",
  },
};

export const defaultTheme = { ...defaultData };

export const localizationTheme = {
  ...defaultData,
};

export const incidentTheme = {
  ...defaultData,
  primaryColor: "#ab0000",
  primaryLighterColor: "#ffc1b2",
  primaryDarkColor: "#4e0000",
  navBar: {
    backgroundColor: "#ab0000",
    backgroundDarkColor: "#4e0000",
    backgroundDarknessColor: "#000000",
  },
};

export const auditTheme = {
  ...defaultData,
  primaryColor: "#aaabab",
  primaryLighterColor: "#f2f3f3",
  primaryDarkColor: "#5a5a5a",
  navBar: {
    backgroundColor: "#aaabab",
    backgroundDarkColor: "#5a5a5a",
    backgroundDarknessColor: "#000000",
  },
};

export const taskTheme = {
  ...defaultData,
  primaryColor: "#fcc100",
  primaryLighterColor: "#fff4d5",
  primaryDarkColor: "#816200",
  navBar: {
    backgroundColor: "#fcc100",
    backgroundDarkColor: "#816200",
    backgroundDarknessColor: "#000000",
  },
};

export const msbsTheme = {
  ...defaultData,
  primaryColor: "#ab0099",
  primaryLighterColor: "#ffdfd8",
  primaryDarkColor: "#490041",
  navBar: {
    backgroundColor: "#ab0099",
    backgroundDarkColor: "#490041",
    backgroundDarknessColor: "#000000",
  },
};

export const BADTheme = {
  ...defaultData,
  primaryColor: "##30D630",
  primaryLighterColor: "#addaad",
  primaryDarkColor: "#176717",
  navBar: {
    backgroundColor: "#30D630",
    backgroundDarkColor: "#176717",
    backgroundDarknessColor: "#000000",
  },
};

export const ORZTheme = {
  ...defaultData,
  primaryColor: "#57C1DC",
  primaryLighterColor: "#97cfdd",
  primaryDarkColor: "#2C616E",
  navBar: {
    backgroundColor: "#57C1DC",
    backgroundDarkColor: "#2C616E",
    backgroundDarknessColor: "#000000",
  },
};
