export function getResponsibilities(localization_id) {
  return dispatch => dispatch({
    types: ["GET_RESPONSIBILITIES_REQUEST", "GET_RESPONSIBILITIES_SUCCESS", "GET_RESPONSIBILITIES_ERROR"],
    payload: {
      request: {
        url: `/business/responsibility/${localization_id}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        }
      },
    }
  });
}

export function assignUser(localization_id,responsibility_id,user_id){
  return dispatch => dispatch({
    types: ["ASSIGN_USER_TO_RESPONSIBILITY_REQUEST", "ASSIGN_USER_TO_RESPONSIBILITY_SUCCESS", "ASSIGN_USER_TO_RESPONSIBILITY_ERROR"],
    payload: {
      request: {
        url: `/business/responsibility/${localization_id}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:{
          responsibility_id,user_id
        }
      },
    }
  });
}