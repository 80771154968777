import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Image from "react-bootstrap/lib/Image";
import {media} from "../../style/style-utils";
import {Link} from "react-router-dom";
import {bootstrapUtils} from "react-bootstrap/lib/utils";
import {withTheme} from "styled-components";
import Media from "react-media";
import {
  isBrowser,
  isMobile
} from "react-device-detect";
import AnimateHeight from 'react-animate-height';
import {LinkContainer} from "../LinkContainer";
import CollapseItem from "./CollapseItem";
import MenuLink from "./_MenuLink";
import HoverNav from "./HoverNav";

import {
  CustomStyledNavItem,
  NavHeadTitle,
  Logo,
  MenuLinkStyle,HoverLi
} from "./_styleComponents";
bootstrapUtils.addStyle(Image, "avatar");

export const HeaderButton = ({className, title, onClick, index}) => (
  <CustomStyledNavItem onClick={onClick} key={index} eventKey={index + 1} href="#" style={{fontSize: "32px"}}>
    {className} :O
    <div>
      <i className={className}/>
      <NavHeadTitle style={{paddingLeft: "10px", fontSize: "14px"}}>{title}???</NavHeadTitle>
    </div>
  </CustomStyledNavItem>
);

const NavBar = styled(({className,children})=>(
  <ul className={`nav navbar-nav ${className}`}>
    {children}
  </ul>
))`
  ${isBrowser ? `
    display:flex;
  ` : ''}
  ${media.xs`
    display:block;
  `}
`


const StyledA = styled.a`
  cursor: pointer;
`

const RenderMenu = props => {

  const {menu,menuRight,parentOpen,onSelectCollapse,collapseOutParent,updateParentOpenChild} = props;
  const renderMenuItems = ({pullRight = false})=>(item, index) => {
    
    if(!item){
      return undefined
    }
    if (item.render) {
      return (<item.render {...{key: index}} pullRight/>);
    }

    else if (item.children && item.children.length) {
      return (
        <HoverNav pullRight={pullRight} key={index} title={item.title} url={item.url} icon={item.class} index={index} parentOpen={parentOpen} {...item}>
         {item.children.map((subItem, subIndex) => (
            <HoverLi key={subIndex}>
              {subItem.onClick
                ? subItem.disabled ? '' : <StyledA onClick={e => {
                  e.preventDefault();
                  subItem.onClick();
                }}>
                  {subItem.name}
                </StyledA> 
                : subItem.disabled ? '' : <Link to={subItem.url}>
                  {subItem.name}
                </Link>}

            </HoverLi>
          ))}
        </HoverNav>);
    }

    else {
      return (
        <CustomStyledNavItem pullRight={pullRight} key={index} onClick={item.onClick} eventKey={index + 1} href="#" style={{fontSize: "32px"}}>
          <LinkContainer to={item.url} title={item.title}>
            <MenuLink url={item.url} title={item.title} className={item.class} images={item.images}/> 
          </LinkContainer>
        </CustomStyledNavItem>);
    }
  };
  return (
    <Media
      query={{maxWidth: 767}}
    >
      {matches=> matches || isMobile ?
        (<CollapseItem
          open={parentOpen}
          close={false}
          parentOpen={parentOpen}
          onSelectCollapse={onSelectCollapse}
          collapseOutParent={collapseOutParent}
          updateParentOpenChild={updateParentOpenChild}
          renderItem={({children})=>(
            <NavBar className={`nav navbar-nav ${props.pullRight ? 'navbar-right' : ''}`}>
              {children}
            </NavBar>
          )}
        >
          {([...menu]).map(renderMenuItems({}))}
        </CollapseItem>)
        :
        <div className={`navbar-collapse collapse in`}>
          <NavBar className={`nav navbar-nav ${props.pullRight ? 'navbar-right' : ''}`}>
            {([...menu]).map(renderMenuItems({}))}
          </NavBar>
        </div>
      }
    </Media>);
};

const createNavbarStyle= props=>({
  backgroundColor: props.theme.navBar.backgroundColor,
  backgroundImage: `linear-gradient(to bottom, ${props.theme.navBar.backgroundColor} 0%, ${props.theme.navBar.backgroundDarknessColor ? `${props.theme.navBar.backgroundDarkColor} 60%, ${props.theme.navBar.backgroundDarknessColor} 100%)` : `${props.theme.navBar.backgroundDarkColor} 100%)`}`,
})

const Hamburger = styled(({className,children,...props})=>{
  return (<button className={`navbar-toggle collapsed ${className}`} {...props}>
    <span className="sr-only"></span>
    <span className="icon-bar"></span>
    <span className="icon-bar"></span>
    <span className="icon-bar"></span>
  </button>);
})`
  &.navbar-toggle{
    margin-top:16px;
    margin-:16px;
  }
`;

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      open:false
    };

    this.openMenu = this.openMenu.bind(this);
  }

  openMenu(){
    this.setState({open:!this.state.open});
  }

  render() {
    const {menu,mainPageUrl = "/"} = this.props;
    const menuLeft = menu && menu.left || [];
    const menuRight = menu && menu.right || [];
    return (
      <CollapseItem
        onSelectCollapse
        renderItem={({open,children,collapse})=>{
          return (<nav style={createNavbarStyle(this.props)} className={`${isMobile ? "mobile" : ''} navbar navbar-default`}>
            <div className="container">
              <div className="navbar-header">
                <div className="navbar-brand">
                  <Link to={mainPageUrl}>
                    <Logo />
                  </Link>
                </div>
                {menuLeft.length ? <Hamburger className="navbar-toggle collapsed" onClick={collapse} /> : ''}
              </div>
              <Media
                query={{maxWidth: 767}}
              >
                {matches=> matches || isMobile ?
                  (<AnimateHeight duration={500} height={open ? "auto" : 0}>
                    <div className={`navbar-collapse collapse in`}>
                      {children}
                    </div>
                  </AnimateHeight>)
                :
                  <div className={`navbar-collapse collapse in`}>
                    {children}
                  </div>
                }
              </Media>
            </div>
          </nav>);
        }}
      >
        <RenderMenu menu={menuLeft} menuRight={menuRight}/>
      </CollapseItem>
    );
  }
}

export default withTheme(Header);

Header.propTypes = {
  menu: PropTypes.shape({
    left: PropTypes.array,
    right: PropTypes.array
  })
};

HeaderButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
};
