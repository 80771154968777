import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import Alert from "react-bootstrap/lib/Alert";

import { getUsersLocalizations } from "actions/localizationActions";
import { getSearchedWorkplaceInitial } from "actions/workplaceActions";
import { getBusinessModulesByUser } from "actions/moduleActions";
import {
  publicAddUser,
  getHiredStatuses,
  getPublicUserBasics,
} from "actions/userActions";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import ModalSchema from "components/Modal/./_SchemaAppComponent";
import validator, { composeValidators } from "components/Validation";
import { UserForm } from "components/UsersAccounts.Form/index";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";

import { SpaceBlock } from "../AnalysisForm.style";
const StyledModal = styled(Modal)`
  & .modal-dialog {
    width: 90%;
    max-width: 900px;
  }
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";

function getFullResidencePlace(user, prefix = "") {
  let textOnly = (value) => (value ? `${prefix}${value}` : "");

  if (Object.keys(user).length) {
    let {
      residence_apartament_number,
      residence_building_number,
      residence_place,
      residence_postcode,
      residence_street,
    } = user;
    return `${textOnly(residence_street)}${textOnly(
      residence_building_number,
      " "
    )}${
      residence_building_number && residence_apartament_number
        ? `${textOnly(residence_apartament_number, "/")}`
        : ""
    } ${textOnly(residence_postcode, " ")} ${textOnly(residence_place, " ")}`;
  }
}

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getUsersLocalizations()(dispatch).then(this.handleServerResponse());
    getHiredStatuses()(dispatch).then(this.handleServerResponse());
    getSearchedWorkplaceInitial()(dispatch).then(this.handleServerResponse());
  }

  componentDidUpdate() {
    const { dispatch, user_id } = this.props;
    // getPublicUserBasics(user_id)(dispatch).then(this.handleServerResponse());
  }

  accept(user) {
    const { accept = () => {}, closeModal } = this.props;
    accept(user);
    closeModal();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  handleSubmit(values) {
    let { dispatch } = this.props;

    publicAddUser(values)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let user = action.payload.data.user;
        this.accept(user);
      })
    );
  }

  render() {
    const {
      children,
      data,
      handleAccept,
      open,
      title,
      user,
      users: { data: userParent },
      localization: { data: localizationData = [] },
      workplace: { data: workplaceData = [] },
      modules: { data: modules },
    } = this.props;

    let { message } = this.state;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {message && message.text && (
              <Alert bsStyle={message.className}>{message.text}</Alert>
            )}
            <UserForm
              acceptButtonName="Zapisz i zamnij"
              boxTitle="Dodaj użytkownika"
              cancelFunction={() => {
                this.closeModal();
              }}
              create
              createView
              message={message}
              handleSubmit={this.handleSubmit}
              localizations={localizationData}
              modules={modules}
              parent_workplaces={workplaceData}
              users={userParent}
              workplaces={workplaceData}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  workplace: store.workplace,
  users: store.users,
  modules: store.modules,
}))(SimpleAcceptModal);

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
