export default function reducer(state = {
  data: [],
  singleData:{},
  fetching: false,
  fetched: false
}, action) {

  switch (action.type) {
    case "GET_LOCALIZATION_REQUEST":{
      return {...state,fetching:true,fetched:false};
    }
    case "GET_LOCALIZATION_SUCCESS":{
      return {...state,fetching:false,fetched:true,data:action.payload.data.sections};
    }
    case "GET_LOCALIZATION_ERROR":{
      return {...state,fetching:false,fetched:false};
    }
    case "EDIT_LOCALIZATION_SUCCESS": {
      return {...state,fetching:false,fetched:true,data:action.payload.data.sections};
    }
    case "GET_SECTIONS_REQUEST": {
      return {...state,fetching:true,fetched:false};
    }
    case "GET_SECTIONS_SUCCESS": {
      return {...state,fetching:false,fetched:true,data:action.payload.data.sections};
    }
    case "GET_SECTIONS_ERROR": {
      return {...state,fetching:false,fetched:false};
    }
    case "CLEAR_SECTIONS": {
      return {...state, data: [], singleData: {}, fetching: false, fetched: false};
    }
  }

  return state;
}