import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { Link } from "react-router-dom";
import { LayoutComponent, LayoutContent } from "../../components/Layout";
import SideMenu from "./_SideMenu";
import Pagination from "../../components/Pagination";
import DayPicker from "../../lib/DayPicker";
import Select from "../../components/Select";
import { StyledInput } from "../../style/styleComponents/Form/input";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import { media } from "../../style/style-utils";
import PageHeader from "../../components/PageHeader";
import Modal from "../../components/Modal/SimpleAccept";
import moment from "moment";
import {
  getTasks,
  deleteTask,
  getTasksByFilter,
  getProgresses,
} from "../../actions/taskActions";
import { getUsersLocalizations } from "../../actions/localizationActions";
import {
  getUserSections,
  getUserSectionsIndependently,
} from "../../actions/sectionActions";
import {
  getUsersToTaskFilter,
  clearUsersReducer,
} from "../../actions/userActions";
import Alert from "react-bootstrap/lib/Alert";
import { Form, Field } from "react-final-form";
import LoadingScreen from "../../components/LoadingScreen";
import { StyledTableListWithSearch } from "../../components/ListComponents";
import { LinkContainer } from "../../components/LinkContainer";
import { ButtonWithIcon } from "../../components/ListComponents";
import { TableSortHeader } from "../../components/TableSortable";
import { ButtonWrapper } from "components/ListComponents";
// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   margin: 0 -5px;
//   margin-bottom: 15px;
//   & > * {
//     margin: 5px 5px;
//   }
// `;
export const StyledTableWrapper = styled.div`
  font-size: 12px;
  padding 0 1px;
  ${media.sm`
    overflow-x: auto;
  `}
`;

export const StyledTable = styled(StyledTableListWithSearch)`
  & tr {
    & > td {
      &:nth-child(1) {
        width: 25px;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }
      &:nth-child(2) {
        width: 130px;
      }
      &:nth-child(3) {
        min-width: 110px;
      }
      &:nth-child(4) {
        width: 100px;
      }
      &:nth-child(7) {
        width: 100px;
      }
      &:nth-child(8) {
        width: 100px;
      }
      &:nth-child(9) {
        width: 125px;
      }
    }
  }
`;
export const ProgressTd = styled.td`
  background: ${(props) => props.color || "inherit"};
`;
const DateHeadTd = (props) => (
  <th style={{ width: "100px", padding: "0 5px" }}>{props.children}</th>
);

export const DateTd = (props) => <td>{props.children}</td>;

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "symbol", () => null);
  changeValue(state, "title", () => null);
  changeValue(state, "date_add", () => null);
  changeValue(state, "localization_id", () => null);
  changeValue(state, "section_name", () => null);
  changeValue(state, "date_end", () => null);
  changeValue(state, "user_id", () => null);
  changeValue(state, "dictionary_progress_id", () => null);
};

export class TaskList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      localFetched: false,
      modal: {
        open: false,
        entry_id: false,
      },
      modalRepeat: {
        open: false,
        entry_id: false,
      },
    };

    this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
    this.handleCloseDeleteRequest = this.handleCloseDeleteRequest.bind(this);
    this.handleCloseRepeatRequest = this.handleCloseRepeatRequest.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData() {
    const { dispatch } = this.props;
    return [
      getTasks()(dispatch),
      getUserSectionsIndependently()(dispatch),
      getUsersLocalizations()(dispatch),
      getProgresses("task")(dispatch),
      getUsersToTaskFilter()(dispatch),
    ];
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.updateMessage();
    Promise.all(this.getData())
      .then(() => {
        this.setState({ localFetched: true });
      })
      .then(() => {});
  }

  handleDeleteRequest(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.modal, entry_id, open: true } });
    };
  }

  handleRepeatRequest(entry_id) {
    return () => {
      this.setState({
        modalRepeat: { ...this.state.modalRepeat, entry_id, open: true },
      });
    };
  }

  handleCloseDeleteRequest() {
    this.setState({
      modal: { ...this.state.modal, entry_id: null, open: false },
    });
  }

  handleCloseRepeatRequest() {
    this.setState({
      modalRepeat: { ...this.state.modalRepeat, entry_id: null, open: false },
    });
  }

  handleDelete() {
    const { entry_id } = this.state.modal;
    const { dispatch } = this.props;
    deleteTask(entry_id)(dispatch).then(this.handleServerResponse());
  }

  handleFilterSubmit(data) {
    const { dispatch } = this.props;

    getTasksByFilter(data)(dispatch).then(this.handleServerResponse());
  }

  clearFilter() {
    this.form.form.mutators.clearFilterMutator();
    getTasks()(this.props.dispatch).then(this.handleServerResponse());
  }

  render() {
    const { message, modal, localFetched, modalRepeat } = this.state;
    const {
      task: { data, progresses, fetched: taskFetched, permission },
      localization: { data: localizationData },
      section: { data: sectionData },
      users: { data: userData },
    } = this.props;
    return (
      <LoadingScreen fetched={localFetched}>
        <LayoutContent
          message={message}
          error404={this.state.error_code == 404}
          SideMenu={<SideMenu permission={permission} />}
        >
          <PageHeader>Rejestr działań</PageHeader>
          <ButtonWrapper>
            <LinkContainer to={"/task/add"}>
              <ButtonWithIcon
                disabled={!permission[2]}
                icon="fa fa-plus"
                name="Dodaj działanie"
              />
            </LinkContainer>
          </ButtonWrapper>
          <div style={{ lineHeight: "34px" }}>Szukaj</div>
          <StyledTableWrapper>
            <Form
              ref={(form) => (this.form = form)}
              mutators={{ clearFilterMutator }}
              onSubmit={this.handleFilterSubmit}
              render={({ handleSubmit, pristine, invalid, form }) => {
                this.form = { form };
                return (
                  <form onSubmit={handleSubmit}>
                    <StyledTable>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <Field
                              name="symbol"
                              render={({ input, meta, ...rest }) => (
                                <StyledInput
                                  {...input}
                                  value={input.value}
                                  placeholder={"wg Symbolu"}
                                  onChange={(event) => {
                                    input.onChange(event.target.value);
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              name="title"
                              render={({ input, meta, ...rest }) => (
                                <StyledInput
                                  {...input}
                                  placeholder={"wg Tytułu"}
                                  onChange={(event) => {
                                    input.onChange(event.target.value);
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              name="date_add"
                              render={({ input, meta, ...rest }) => (
                                <DayPicker
                                  fullWidth
                                  handleChange={(value) => {
                                    input.onChange(
                                      moment(value).format("YYYY-MM-DD")
                                    );
                                  }}
                                />
                              )}
                            />
                          </td>

                          <td>
                            <Field
                              name="localization_id"
                              render={({ input, meta, ...rest }) => (
                                <Select
                                  title="Lokacja"
                                  fullWidth
                                  propValue={"id"}
                                  selected={input.value}
                                  data={[
                                    { id: null, name: "Wszystkie" },
                                  ].concat(localizationData)}
                                  handleChange={(value) => {
                                    input.onChange(value);
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              name="section_name"
                              render={({ input, meta, ...rest }) => (
                                <Select
                                  title="Dział"
                                  fullWidth
                                  selected={input.value}
                                  propValue={"value"}
                                  data={[
                                    { value: null, name: "Wszystkie" },
                                  ].concat(sectionData)}
                                  handleChange={input.onChange}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              name="date_end"
                              render={({ input, meta, ...rest }) => (
                                <DayPicker
                                  fullWidth
                                  handleChange={(value) => {
                                    input.onChange(
                                      moment(value).format("YYYY-MM-DD")
                                    );
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              name="user_id"
                              render={({ input, meta, ...rest }) => (
                                <Select
                                  title="Osoba odp."
                                  fullWidth
                                  propValue={"id"}
                                  selected={input.value}
                                  handleChange={input.onChange}
                                  data={[
                                    { name: "Wszystkie", id: null },
                                  ].concat(userData)}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              name="dictionary_progress_id"
                              render={({ input, meta, ...rest }) => (
                                <Select
                                  title="Postęp"
                                  fullWidth
                                  pullRight
                                  propValue={"id"}
                                  selected={input.value}
                                  data={[
                                    { name: "Wszystkie", id: null },
                                  ].concat(progresses)}
                                  handleChange={input.onChange}
                                />
                              )}
                            />
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="10" style={{ paddingRight: "40px" }}>
                            <ButtonWrapper nowrap>
                              <Button type="submit" bsStyle="primary">
                                Szukaj
                              </Button>
                              <Button onClick={this.clearFilter}>
                                Wyłącz filtr
                              </Button>
                            </ButtonWrapper>
                          </td>
                        </tr>
                        <TableSortHeader
                          headers={[
                            { name: "" },
                            {
                              name: "Symbol",
                              prop: "symbol",
                              sort_type: "string",
                            },
                            {
                              name: "Tytuł",
                              prop: "title",
                              sort_type: "string",
                            },
                            { name: "Data rozpoczęcia", prop: "date_add" },
                            {
                              name: "Lokacja",
                              prop: "localization_name",
                              sort_type: "string",
                            },
                            {
                              name: "Dział firmy",
                              prop: "section_name",
                              sort_type: "string",
                            },
                            { name: "Termin zakończenia", prop: "date_end" },
                            {
                              name: "Osoba odpowiedzialna",
                              prop: "users_name",
                              sort_type: "string",
                            },
                            {
                              name: "Postęp",
                              prop: "progress_name",
                              sort_type: "string",
                            },
                            { name: "Usuń" },
                            { name: "Powtórz działanie" },
                          ]}
                          data={data}
                          render={(sortedData) => {
                            return sortedData
                              .map((item) => ({
                                ...item,
                                date_add: moment(item.date_add).format(
                                  "YYYY-MM-DD"
                                ),
                                date_end: item.date_end
                                  ? moment(item.date_end).format("YYYY-MM-DD")
                                  : "",
                              }))
                              .map((item, index) => (
                                <tr color={item.progress_color} key={index}>
                                  {item.priority == 1 ? (
                                    <td
                                      style={{ color: "red" }}
                                      title="Wysoki priorytet"
                                      className=""
                                    >
                                      <i className="fa fa-warning" />
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}

                                  <td>{item.symbol}</td>
                                  <td>
                                    <Link to={`/task/${item.id}`}>
                                      {item.title}
                                    </Link>
                                  </td>
                                  <DateTd>{item.date_add}</DateTd>
                                  <td>{item.localization_name}</td>
                                  <td>{item.section_name}</td>
                                  <DateTd>{item.date_end}</DateTd>
                                  <td>{item.users_name}</td>
                                  <ProgressTd color={item.progress_color}>
                                    {item.progress_name}
                                  </ProgressTd>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "streatch",
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        disabled={!permission[4]}
                                        onClick={this.handleDeleteRequest(
                                          item.id
                                        )}
                                        style={{ width: "100%" }}
                                      >
                                        <i className="fa fa-trash" />
                                      </Button>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "streatch",
                                      }}
                                    >
                                      <LinkContainer
                                        to={`/task/repeat/${item.id}`}
                                      >
                                        <Button
                                          type="button"
                                          disabled={!permission[2]}
                                          style={{ width: "100%" }}
                                          onClick={this.handleRepeatRequest(
                                            item.id
                                          )}
                                        >
                                          <i className="fa fa-refresh" />
                                        </Button>
                                      </LinkContainer>
                                    </div>
                                  </td>
                                </tr>
                              ));
                          }}
                        />
                      </tbody>
                    </StyledTable>
                  </form>
                );
              }}
            />
          </StyledTableWrapper>
          {/*<Pagination handlePageChange={this.handlePageChange.bind(this)} data={localization.data}/>*/}
          <Modal
            title="Usuń zadanie"
            description={"Czy chcesz usunąć dany wpis?"}
            open={modal.open}
            handleAccept={this.handleDelete}
            handleClose={this.handleCloseDeleteRequest}
            autoAcceptClose
          />
          <Modal
            title="Powtórz zadanie"
            description={"Czy chcesz powtórz dane zadanie?"}
            open={modalRepeat.open}
            handleAccept={this.handleRepeat}
            handleClose={this.handleCloseRepeatRequest}
            autoAcceptClose
          />
        </LayoutContent>
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    clearUsersReducer()(this.props.dispatch);
  }
}

export default connect((store) => ({
  task: store.task,
  localization: store.localization,
  section: store.section,
  users: store.users,
  message: store.message,
}))(TaskList);
