import React, { Fragment } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { FieldArray } from "react-final-form-arrays";
import ModalAccept from "components/Modal/SimpleAccept";

import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInputCheckBox,
  StyledInput,
  StyledInputCheckBoxWithToolTip,
  StyledInputMessage,
} from "style/styleComponents/Form/input";

import {
  OnlySxBlock,
  TwoInputWrapper,
  SelectWrapper,
  SpaceBlock,
  StyledInputCheckBoxWrapper,
  EmptySeparator,
} from "../AnalysisForm/AnalysisForm.style";

import validator, { composeValidators } from "components/Validation";
const SourceField = ({ input, readOnly, fieldName, meta, value: main }) => {
  const { dynamic_value, active, value } = main;
  return (
    <div>
      <StyledInputCheckBox
        disabled={readOnly}
        label={fieldName}
        // value={input.active}
        onChange={(event) => {
          const newValue = event.target.checked;
          input.onChange({ ...main, active: newValue });
        }}
        checked={active}
      />
      {dynamic_value ? (
        <StyledInput
          value={value}
          disabled={readOnly}
          onChange={(event) => {
            const newValue = event.target.value;
            input.onChange({ ...main, value: newValue });
          }}
        />
      ) : (
        ""
      )}
      {/* <StyledInputMessage>
        {meta.error && meta.touched ? meta.error : null}
      </StyledInputMessage> */}
    </div>
  );
};

export default class AccidentDescription extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalPersonal: {
        open: false,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true } });
    };
  }

  closeModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false } });
    };
  }

  render() {
    let { modalPersonal } = this.state;
    let { readOnly, updateIncidentPersonal } = this.props;
    return (
      <Fragment>
        <Row style={{ paddingBottom: "10px" }}>
          <Col lg={12}>
            <StyledLabel>W zdarzeniu brał udział</StyledLabel>
          </Col>
          {/* <FieldArray validate={validator.incident_source} name="source"> */}
          <FieldArray name="source">
            {({ fields, meta }) => {
              return (
                <div>
                  {fields.map((name, index) => {
                    const { name: fieldName, dynamic_value } = fields.value[
                      index
                    ];
                    return (
                      <Col
                        lg={dynamic_value ? 12 : 6}
                        md={dynamic_value ? 12 : 6}
                        sm={12}
                        xs={12}
                      >
                        <Field
                          name={name}
                          validate={validator.mustBeFilledSourceWhenActive}
                          render={({ input, meta, ...rest }) => (
                            <>
                              <SourceField
                                input={input}
                                readOnly={readOnly}
                                fieldName={fieldName}
                                value={input.value}
                                // meta={meta}
                              />
                            </>
                          )}
                          c
                        />
                      </Col>
                    );
                  })}
                  <Col lg={12}>
                    <StyledInputMessage>
                      {meta.error && meta.touched ? meta.error : null}
                    </StyledInputMessage>
                  </Col>
                </div>
              );
            }}
          </FieldArray>
        </Row>
        <EmptySeparator />

        <Field
          name="repeated_incident"
          render={({ input, meta, ...rest }) => (
            <StyledInputCheckBox
              label="W przeszłości zgłaszano podobne zdarzenia"
              checked={input.value}
              disabled={readOnly}
              onChange={(event) => {
                input.onChange(event.target.checked);
              }}
            />
          )}
        />
        <Field
          name="similiar_incident"
          render={({ input, meta, ...rest }) => (
            <StyledTextareaBox
              label="Czy podobne zagrożenia występują w innych miejscach w firmie?"
              style={{ minHeight: "83px" }}
              value={input.value}
              disabled={readOnly}
              onChange={(event) => {
                input.onChange(event.target.value);
              }}
            />
          )}
        />
        <EmptySeparator />
        <SpaceBlock>
          <StyledLabel>
            Czy zdarzenie spowodowało uraz u osoby biorącej w nim udział?
          </StyledLabel>
          <FormSpy subscribe={{ values: true }}>
            {({ values }) => {
              return (
                <Field
                  name={`personal`}
                  render={({ input, meta, ...rest }) => (
                    <StyledInputCheckBoxWrapper>
                      <StyledInputCheckBoxRadio
                        label="Tak"
                        disabled={readOnly}
                        value={true}
                        name="type"
                        checked={input.value == true}
                        onChange={(event) => {
                          if (
                            values.personal == false ||
                            values.personal == null
                          ) {
                            this.openModal("modalPersonal")();
                          }
                        }}
                      />
                      <StyledInputCheckBoxRadio
                        label="Nie"
                        disabled={readOnly}
                        value={2}
                        name="type"
                        checked={input.value == false}
                        onChange={(event) => {
                          if (values.personal) {
                            this.openModal("modalPersonal")();
                          }
                        }}
                      />
                    </StyledInputCheckBoxWrapper>
                  )}
                />
              );
            }}
          </FormSpy>
        </SpaceBlock>
        <ModalAccept
          handleAccept={updateIncidentPersonal}
          handleClose={this.closeModal("modalPersonal")}
          title="Czy zdarzenie spowodowało uraz u osoby biorącej w niej udział?"
          description={
            "Wybrana opcja jest sprzeczna z informacjami z zgłoszenia. Czy zaktualizowań dane informacje?"
          }
          open={modalPersonal.open}
        >
          <div>a</div>
        </ModalAccept>
      </Fragment>
    );
  }
}
