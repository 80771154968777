import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { OnChange } from "react-final-form-listeners";

import { getMEDraft, saveMEDraft } from "actions/meActions";
import { getUsersLocalizations } from "actions/localizationActions";
import { getUserSections } from "actions/sectionActions";
import { getUserWorkplacesWithout } from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import {
  FieldInput,
  FieldDayPicker,
  FieldSelect,
  FieldInput33,
} from "components/Fields";
import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import { LinkContainer } from "components/LinkContainer";
import {
  ButtonWrapper,
  LeftPadding,
  TwoInputWrapper,
} from "components/Wrappers";

import MedicalReferral from "components/MedicalReferral.Form";
import MedicalReferralModel from "components/MedicalReferralModel.Form";
import SideMenu from "./_SideMenu";

class Referral extends AppComponent {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.getSections = this.getSections.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;

    Promise.all([
      getUsersLocalizations()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse()),
      id
        ? getMEDraft(id)(dispatch).then(this.handleServerResponse())
        : undefined,
    ]).then(() => {
      const {
        draft: { localization_id },
      } = this.props;
      if (localization_id) {
        getUserSections(localization_id)(dispatch).then(
          this.handleServerResponse()
        );
      }
    });
  }

  save(data) {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    saveMEDraft(id, data)(dispatch).then(this.handleServerResponse());
  }

  getSections(localizaction_id) {
    const { dispatch } = this.props;
    getUserSections(localizaction_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  render() {
    let { message } = this.state;
    let { localizations, workplaces, sections, draft, permission } = this.props;
    return (
      <>
        <MedicalReferralModel
          permission={permission}
          data={draft}
          message={message}
          SideMenu={SideMenu}
          localizations={localizations}
          workplaces={workplaces}
          sections={sections}
          save={this.save}
          getSections={this.getSections}
          readOnly={draft.id ? !permission[60] : !permission[54]}
        />
      </>
    );
  }
}

export default connect((store) => ({
  permission: store.me.permission,
  draft: store.me.draft,
  localizations: store.localization.data,
  sections: store.section.data,
  workplaces: store.workplace.data,
}))(Referral);
