import React, { useState, useEffect } from "react";
import { FormSpy } from "react-final-form";
// import diff from "object-diff";
import {
  diff,
  addedDiff,
  deletedDiff,
  updatedDiff,
  detailedDiff,
} from "deep-object-diff";

import { save } from "react-cookies";

function arrayDiff(oldData, newData) {
  return newData.reduce((array, newItem) => {
    let oldItem = oldData.find((item) => {
      return item.id == newItem.id;
    });

    if (oldItem) {
      const difference = diff(oldItem, newItem);
      if (Object.keys(difference).length) {
        array.push(newItem);
      }
    } else {
      return newItem;
    }

    return array;
  }, []);
}

class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = { values: props.values || {}, submitting: false };
    this.save = this.save.bind(this);
    this.init = false;
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { save, customDiff, values } = this.props;
    // This diff step is totally optional
    const prev = { ...this.state.values };
    const difference = diff(this.state.values, values);
    if (this.state.init) {
      if (Object.keys(difference).length) {
        //   // values have changed
        this.setState({ submitting: true, values });
        this.promise = save(values);
        await this.promise;
        delete this.promise;
        this.setState({ submitting: false });
      }
    } else {
      this.setState({ values, init: true });
    }
  };

  render() {
    return (
      this.state.submitting && <div className="submitting">Submitting...</div>
    );
  }
}

export default (props) => {
  return (
    <FormSpy
      {...props}
      setFieldData={props.setFieldData}
      data={props.values}
      save={props.save}
      subscription={{ active: true, values: true, form: true }}
      component={AutoSave}
    />
  );
};
