import React from "react";
import { connect } from "react-redux";

import { getHazardList, deleteHazard } from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import List from "components/ModelHazard/List";

import SideMenu from "../_SideMenu";
import { setUrls } from "../_staticData.js";

let names = {
  TITLE: "Szablony wzorcowych zagrożeń",
};

class ModelHazardList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };

    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount() {
    this.updateMessage();
    getHazardList({ readOnly: true })(this.props.dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  deleteItem(id) {
    let { dispatch } = this.props;
    deleteHazard(id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { message, urls } = this.state;
    let { modelHazardList, permission } = this.props;
    return (
      <List
        permission={permission}
        message={message}
        data={modelHazardList}
        SideMenu={SideMenu}
        urls={urls}
        names={names}
        deleteEntry={this.deleteItem}
      />
    );
  }
}

export default connect((store) => ({
  modelHazardList: store.riskAssessment.modelHazardList,
  message: store.message,
  permission: store.riskAssessment.permission,
}))(ModelHazardList);
