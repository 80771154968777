import React from "react";
import { connect } from "react-redux";

import { CharacteristicList } from "./List";

class ArchivedCharacteristicList extends CharacteristicList {
  constructor(props) {
    super(props);
    this.editUrlPrefix = "/characteristic/archived/edit/";
  }
}

export default connect((store) => ({
  characteristic: store.characteristic,
  localization: store.localization,
  section: store.section,
  message: store.message,
}))(ArchivedCharacteristicList);
