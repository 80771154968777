import React from "react";
import styled from "styled-components";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { media } from "../../style/style-utils";

import { ButtonWithIcon } from "components/Buttons";

const StyledDragAndDropTitle = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${(props) => props.theme.primaryColor};
  margin-bottom: 20px;
`;

export const DragAndDropMove = SortableHandle(
  ({ children, isSorting, readOnly }) => <i className="fa fa-arrows"></i>
);

export const DragAndDropTitle = ({ children, isSorting }) => (
  <StyledDragAndDropTitle>{children}</StyledDragAndDropTitle>
);
//

const StyledDragAndDropItem = styled.div`
  width: 100%;
  background-color: inherit;
  user-select: ${(props) => (props.isSorting ? "none" : "text")};
`;
export const DragAndDropItem = SortableElement(
  ({ title, content, children, isSorting, render, item }) => (
    <StyledDragAndDropItem isSorting={isSorting}>
      {render(item, DragAndDropMove)}
    </StyledDragAndDropItem>
  )
);

export const DragAndDropList = SortableContainer(
  ({ items, isSorting, render }) => {
    return (
      <div id="SortableList" style={{ display: "flex", flexWrap: "wrap" }}>
        {items.map((value, index) => (
          <DragAndDropItem
            key={`item-${index}`}
            title={value.title}
            index={index}
            item={value}
            content={value.content}
            isSorting={isSorting}
            render={render}
          />
        ))}
      </div>
    );
  }
);

class DragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSorting: true,
    };
    this.onSortStart = this.onSortStart.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortMove() {}

  onSortStart() {
    this.setState({ isSorting: true });
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { handleOnDragEnd, items } = this.props;
    const result = arrayMove(items, oldIndex, newIndex);
    handleOnDragEnd(result);
    this.setState({
      isSorting: false,
    });
  }

  render() {
    const { items } = this.props;

    return (
      <>
        <DragAndDropList
          items={items}
          axis="xy"
          useDragHandle={true}
          onSortStart={this.onSortStart}
          onSortEnd={this.onSortEnd}
          isSorting={this.state.isSorting}
          render={this.props.render}
        />
      </>
    );
  }
}

export default DragAndDrop;
