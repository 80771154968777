import stringMath from "string-math";

let getMaxParametrs = (parameters) => {
  return parameters.reduce((obj, parametr) => {
    let key = parametr.user_variable_name;
    let numbers = parametr.variables.map((item) => item.number_value);
    let max = Math.max.apply(Math, numbers);
    obj[key] = max;
    return obj;
  }, {});
};

export let matchVariables = (mathematical_formula) => {
  const regex = /[A-Z]/gi;
  return mathematical_formula.match(regex);
};

let run = (values) => {
  let maxParametrs = getMaxParametrs(values.parameters);
  let variablesSymbols = matchVariables(values.mathematical_formula);
  let mathematicalFormulaFromSymbolsToVariables = values.mathematical_formula;

  for (let symbol of variablesSymbols) {
    mathematicalFormulaFromSymbolsToVariables = mathematicalFormulaFromSymbolsToVariables.replace(
      symbol,
      maxParametrs[symbol]
    );
  }

  return stringMath(mathematicalFormulaFromSymbolsToVariables);
};

export let count = ({ mathematical_formula, variableValue = {} }) => {
  if (mathematical_formula) {
    let maxParametrs = variableValue;
    let variablesSymbols = matchVariables(mathematical_formula);
    let mathematicalFormulaFromSymbolsToVariables = mathematical_formula;

    for (let symbol of variablesSymbols) {
      let value = maxParametrs[symbol];
      value = value || "0";

      mathematicalFormulaFromSymbolsToVariables = mathematicalFormulaFromSymbolsToVariables.replace(
        symbol,
        value
      );
    }

    return stringMath(mathematicalFormulaFromSymbolsToVariables);
  }
};

export default run;
