import React from "react";
import PropTypes from "prop-types";
import { media } from "../style/style-utils";
import styled from "styled-components";
import Panel from "react-bootstrap/lib/Panel";
import { withTheme } from "styled-components";

export const BlockBox = styled.div`
  flex: 1 1 ${(props) => (props.half ? "48%" : "100%")};
  display: flex;
  box-sizing: border-box;
  ${media.sm`
    flex-basis:100%;
  `}
  & > div {
    flex-basis: 100%;
  }
`;

export const createPanelHeadingStyle = (props) => ({
  backgroundColor: props.theme.navBar.backgroundColor,
  backgroundImage: `linear-gradient(to bottom, ${
    props.theme.navBar.backgroundColor
  } 0%, ${
    props.theme.navBar.backgroundDarknessColor
      ? `${props.theme.navBar.backgroundDarkColor} 60%, ${props.theme.navBar.backgroundDarknessColor} 100%)`
      : `${props.theme.navBar.backgroundDarkColor} 100%)`
  }`,
  color: props.theme.primaryTextColor,
});

const Box = (props) => (
  <BlockBox
    lg={6}
    md={6}
    sm={12}
    xs={12}
    style={{ ...props.style }}
    half={props.half}
  >
    <Panel>
      <Panel.Heading style={createPanelHeadingStyle(props)}>
        {props.title}
      </Panel.Heading>
      {props.children ? <Panel.Body>{props.children}</Panel.Body> : ""}
    </Panel>
  </BlockBox>
);

export default withTheme(Box);

Box.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};
