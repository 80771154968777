export default function reducer(state = {
  data: [],
  fetching: false,
  fetched: true
}, action) {

  switch (action.type) {
    case "GET_CONFIGURATIONS_REQUEST": {
      return {...state,fetching:true,fetched:false};
    }
    case "GET_CONFIGURATIONS_SUCCESS": {
      return {...state,fetching:false,fetched:true,data:action.payload.data.configuration};
    }
    case "GET_CONFIGURATIONS_ERROR": {
      return {...state,fetching:false};
    }
  }

  return state;
}