import React from "react";
import { connect } from "react-redux";

import {
  getDraftHazardList,
  deleteDraftHazard,
} from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import List from "components/ModelHazard/List";

import SideMenu from "../_SideMenu";

let urls = {
  HAZARDADD: "/super-admin/draft/model-hazards/add",
  HAZARDEDIT: (id) => `/super-admin/draft/model-hazards/edit/${id}`,
};

let names = {
  TITLE: "Szablony wzorcowych zagrożeń",
};

class ModelHazardList extends AppComponent {
  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount() {
    getDraftHazardList()(this.props.dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    this.updateMessage();
  }

  deleteItem(id) {
    let { dispatch } = this.props;
    deleteDraftHazard(id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { message } = this.state;
    let { modelHazardList } = this.props;

    return (
      <List
        superAdmin
        message={message}
        data={modelHazardList}
        SideMenu={SideMenu}
        urls={urls}
        names={names}
        deleteEntry={this.deleteItem}
      />
    );
  }
}

export default connect((store) => ({
  modelHazardList: store.riskAssessment.modelHazardList,
  message: store.message,
}))(ModelHazardList);
