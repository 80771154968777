export default function reducer(state = {
  message: "",
  error: "",
  data: {},
  singleData:{},
  loginFetching: false,
  loginFetched: false,
  resetPasswordTokenCheckFetching:false,
  resetPasswordTokenCheckFetched:false,
}, action) {
  switch (action.type) {
    case "LOGIN_SUPER_ADMIN_REQUEST": {
      return {...state, loginFetching: true, loginFetched: false};
    }
    case "LOGIN_SUPER_ADMIN_SUCCESS": {
      return {...state, loginFetching: false, loginFetched: true, data: action.payload.data.superadmin, error: false};
    }
    case "LOGIN_SUPER_ADMIN_ERROR": {
      return {...state, loginFetching: false, loginFetched: false, error: action.error.response.data.message};
    }
    case "RESET_PASSWORD_SUPER_ADMIN_REQUEST": {
      return {...state};
    }
    case "RESET_PASSWORD_SUPER_ADMIN_SUCCESS": {
      return {...state};
    }
    case "RESET_PASSWORD_SUPER_ADMIN_ERROR": {
      return {...state};
    }
    case "RESET_PASSWORD_CONFIRM_SUPER_ADMIN_REQUEST": {
      return {...state, loginFetching: true, loginFetched: false};
    }
    case "RESET_PASSWORD_CONFIRM_SUPER_ADMIN_SUCCESS": {
      return {...state, loginFetching: false, loginFetched: true, message: action.payload.data.message, error: false};
    }
    case "RESET_PASSWORD_CONFIRM_SUPER_ADMIN_ERROR": {
      return {...state, loginFetching: false, loginFetched: false, error: action.error.response.data.message};
    }
    case "ACCOUNT_SUPER_ADMIN_REQUEST":{
      return {...state};
    }
    case "ACCOUNT_SUPER_ADMIN_SUCCESS":{
      return {...state, loginFetching: false, loginFetched: true, data: action.payload.data.superadmin, error: false};
    }
    case "ACCOUNT_SUPER_ADMIN_ERROR":{
      return {...state};
    }
    case "RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_REQUEST":{
      return {...state,resetPasswordTokenCheckFetching:true,resetPasswordTokenCheckFetched:false};
    }
    case "RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_SUCCESS":{
      return {...state,resetPasswordTokenCheckFetching:false,resetPasswordTokenCheckFetched:true};
    }
    case "RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_ERROR":{
      return {...state,resetPasswordTokenCheckFetching:false,resetPasswordTokenCheckFetched:true};
    }
    case "CLEAN_MESSAGES": {
      return {...state, message: "", error: ""};
    }
  }
  return state;
}