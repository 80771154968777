import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";
import { Form, Field, FormSpy } from "react-final-form";
import moment from "moment";
import styled from "styled-components";
import {
  getIncidentToAnalysis,
  forwardAccident,
  saveAnalysis,
  generateAccidentCard,
  saveAnalysisData,
  setNextState,
} from "actions/accidentOWTWActions";
import { OnChange } from "react-final-form-listeners";

import { getDictionary } from "actions/dictionaryActions";

import { getUserWorkplacesWithout } from "actions/workplaceActions";
import { getUserByWorkplaceByUser, getUsersNames } from "actions/userActions";
import DayPicker from "lib/DayPicker";

import Box from "components/Box";
import { ButtonWrapper } from "components/ListComponents";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import Witnesses from "./components/WitnessAnalysis";
import ModalVictimExplains from "components/Modal/VictimExplains";
import ModalResponsibility, {
  modalResponsibilityState,
} from "components/Modal/ResponsibilityUser";
import AppComponent from "components/AppComponent";
import {
  FieldInput,
  FieldInput33,
  FieldInputCheckBox,
  FieldDayPicker33,
  FieldDayPicker,
  FieldSelectDitionary,
  FieldTextareaBox,
} from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";
import { setMessage } from "components/AppComponent";
import validator, { composeValidators } from "components/Validation";
import saveBlobFile from "components/saveBlobFile";
import { TimeWrapper } from "container/Incident/DefaultForm";

import {
  StyledLabel,
  StyledInputBox,
  StyledInputMessage,
} from "style/styleComponents/Form/input";
import ToggleSlider from "components/ToggleSlider";
import SideMenu from "./_SideMenu";
import ModalVictimData from "./components/VictimData";
import PayerData, { validatePayerData } from "./components/PayerData";

const TimeWrapperStyle = styled.div`
  display: flex;
  margin: 0 -10px 5px;
  & > * {
    margin: 0 10px;
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 20%;
    }
  }
`;

let FormSegment = styled.div`
  margin-bottom: 30px;
`;

let activeStage = 1;

const SubFieldCleaner = (props) => {
  let {
    watched,
    overwritten,
    cleanValue = "false",
    overrideValue = null,
  } = props;
  return (
    <FormSpy subscribe={{ form: true }}>
      {({ form }) => {
        return (
          <OnChange name={watched}>
            {(value) => {
              if (value == cleanValue) {
                form.change(overwritten, overrideValue);
              }
            }}
          </OnChange>
        );
      }}
    </FormSpy>
  );
};

let SubField = (props) => {
  let { children, watched, avaibleValue = "true" } = props;

  if (!children) {
    return null;
  }
  let rerenderChildren = ({ validate, disabled }) => {
    let betterChildren = React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        ...child.props,
        validate,
        disabled: child.props.disabled || disabled,
      });
    });
    return betterChildren;
  };

  return (
    <FormSpy subscribe={{ values: true }}>
      {({ values }) => {
        let disabled = values[watched] != avaibleValue;
        let validate = disabled
          ? () => {
              return false;
            }
          : validator.required;

        return <>{rerenderChildren({ validate, disabled })}</>;
      }}
    </FormSpy>
  );
};

class Analysis extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalResponsibility: { ...modalResponsibilityState },
    };

    this.handleSelectWorkplace = this.handleSelectWorkplace.bind(this);
    this.setUserAssign = this.setUserAssign.bind(this);
    this.setUserState = this.setUserState.bind(this);
    this.saveAnalysis = this.saveAnalysis.bind(this);
    this.generateAccidentCard = this.generateAccidentCard.bind(this);
    this.saveOnly = this.saveOnly.bind(this);
    this.moveToNextStage = this.moveToNextStage.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    Promise.all([
      getIncidentToAnalysis(id)(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getUserWorkplacesWithout()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
      getDictionary("accident-owtw-place")(dispatch).then(
        this.handleServerResponse()
      ),
      getUsersNames()(dispatch).then(
        this.handleServerResponse({}, (action) => {})
      ),
    ]).then(() => {
      const {
        accident_owtw: {
          singleData: {
            responsible_user_workplace_id = null,
            responsible_user_id = null,
            responsible_user_name,
            // incident_has_person,
            // profession_code,
          },
        },
        workplace: { data: workplaceData },
      } = this.props;

      if (responsible_user_workplace_id) {
        getUserByWorkplaceByUser(responsible_user_workplace_id)(
          this.props.dispatch
        ).then(
          this.handleServerResponse({}, (action) => {
            const respon_user = action.payload.data.users.find((u) => {
              return u.id === responsible_user_id;
            });

            let respon_name = respon_user.name;
            this.setState({
              modalResponsibility: {
                ...this.state.modalResponsibility,
                selectedUser: responsible_user_id,
                selectedWorkplace: responsible_user_workplace_id,
              },
              selectedUserName: respon_name,
            });
          })
        );
      }
    });
  }

  moveToNextStage() {
    const {
      dispatch,
      match,
      history,
      accident_owtw: {
        singleData: { stage },
      },
    } = this.props;
    const { id } = match.params;

    let push = () => {
      history.push(`/accident-owtw/attachment/${id}`);
    };

    if (stage == activeStage) {
      setNextState(
        id,
        activeStage
      )(dispatch).then(
        this.handleServerResponse({}, () => {
          push();
        })
      );
    } else {
      push();
    }
  }

  setUserState(value) {
    this.setState({
      modalResponsibility: {
        ...this.state.modalResponsibility,
        selectedUser: value,
      },
    });
  }

  setUserAssign() {
    const {
      users: { data: usersData },
      match,
      dispatch,
    } = this.props;
    const { id } = match.params;
    const { selectedUser: users_id } = this.state.modalResponsibility;

    // const mutators = this.form.mutators;
    forwardAccident(id, { users_id })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.form.change("responsible_user_id", users_id);
        const user =
          usersData[usersData.findIndex((item) => item.id === users_id)];
        this.setState({
          modalResponsibility: {
            ...this.state.modalResponsibility,
            open: false,
          },
          selectedUser: users_id,
          selectedUserName: user.name,
          message: setMessage(action.payload.data.message, "success"),
        });
      })
    );
  }

  saveOnly(rawvalues, callback) {
    const { dispatch, match, history } = this.props;
    let { id } = match.params;
    let {
      user,
      responsible_user_workplace_id,
      date_event_hour_minutes,
      date_event,
      ...rest
    } = rawvalues;
    let mutateBoolean = (key) => {
      rest[key] = rest[key] == "true" ? true : false;
    };
    mutateBoolean("is_institution_do_action");
    mutateBoolean("did_accident_cause_death");
    mutateBoolean("is_owtw");
    let time = date_event_hour_minutes.split(":");
    rest.date_event = moment(date_event).set({
      hour: time[0],
      minute: time[1],
    });
    saveAnalysisData(
      id,
      rest
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        callback && callback();
      })
    );
  }

  saveAnalysis(value) {
    let { match, dispatch, history } = this.props;
    let { id } = match.params;
    let {
      user,
      responsible_user_workplace_id,
      date_event_hour_minutes,
      date_event,
      ...rest
    } = value;
    let mutateBoolean = (key) => {
      rest[key] = rest[key] == "true" ? true : false;
    };
    mutateBoolean("is_institution_do_action");
    mutateBoolean("did_accident_cause_death");
    mutateBoolean("is_owtw");
    let time = date_event_hour_minutes.split(":");
    rest.date_event = moment(date_event).set({
      hour: time[0],
      minute: time[1],
    });

    saveAnalysis(
      id,
      rest
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/accident-owtw/attachment/${id}`);
      })
    );
  }

  handleSelectWorkplace(value) {
    this.setState(
      {
        ...this.state,
        modalResponsibility: {
          ...this.state.modalResponsibility,
          selectedWorkplace: value,
          selectedUser: null,
        },
      },
      () => {
        getUserByWorkplaceByUser(value)(this.props.dispatch).then(
          this.handleServerResponse()
        );
      }
    );
  }

  generateAccidentCard() {
    let { dispatch, match } = this.props;
    let { id } = match.params;

    generateAccidentCard(id)(dispatch).then((res) => {
      if (!res.error) {
        const file = res.payload.data;
        const fileName = `karta wypadku.pdf`;
        saveBlobFile(file, fileName);
      }
    });
  }

  render() {
    let { message } = this.state;
    let {
      match,
      accident_owtw: {
        singleData: accidentData,
        victim,
        witnesses,
        permission,
      },
      dictionary,
      workplace: { data: workplaceData },
      users: { data: usersData },
      dispatch,
    } = this.props;
    const { id: accident_id } = match.params;
    let setInicialValues = (accidentData) => {
      let initialValues = { ...accidentData };
      let mutateBoolean = (key) => {
        initialValues[key] = accidentData[key] ? "true" : "false";
      };
      initialValues.date_event_hour_minutes = moment(
        accidentData.date_event
      ).format("HH:mm");
      mutateBoolean("is_institution_do_action");
      mutateBoolean("did_accident_cause_death");
      mutateBoolean("is_owtw");

      return initialValues;
    };

    let readOnly = !permission[10];
    return (
      <LayoutContent
        mobileNotHideMenu={true}
        SideMenu={
          <SideMenu
            accident_id={accident_id}
            addNewIncident={this.addNewIncident}
            stage={accidentData.stage}
          />
        }
        error404={this.state.error_code == 404}
        message={message}
      >
        <Box title={"Analiza zgłoszenia"}>
          <Form
            onSubmit={this.saveAnalysis}
            initialValues={setInicialValues(accidentData)}
            validate={(values) => {
              let errors = {};
              validatePayerData(values, errors);
              if (!(values.hour_work_start || values.hour_work_end)) {
                errors.hour_work_start_end =
                  "Jedna z powyższych opcji musi być wypełniona";
              }
              return errors;
            }}
            render={({
              handleSubmit,
              pristine,
              invalid,
              form,
              values,
              errors,
            }) => {
              this.form = form;
              return (
                <form onSubmit={handleSubmit}>
                  <StyledInputBox
                    label="Osoba odpowiedzialna"
                    disable={true}
                    value={this.state.selectedUserName}
                  />
                  <FormSegment>
                    <ButtonWrapper>
                      <Button
                        type="button"
                        onClick={this.openModal("modalResponsibility")}
                      >
                        Przekaż zadanie
                      </Button>
                    </ButtonWrapper>
                  </FormSegment>
                  <FormSegment>
                    <ModalVictimData
                      dispatch={dispatch}
                      accident_id={accident_id}
                    />
                  </FormSegment>
                  <FormSegment>
                    <h4>Informacje uzyskane od świadków</h4>
                    <Witnesses accident_id={accident_id} />
                  </FormSegment>
                  <ToggleSlider
                    title={<h4>Karta wypadku w drodze do pracy lub z pracy</h4>}
                    duration={600}
                  >
                    <PayerData readOnly={readOnly} />

                    <h4>Dane identyfikacyjne poszkodowanego</h4>
                    <LeftPadding>
                      <TwoInputWrapper>
                        <StyledInputBox
                          disabled={true}
                          name={"victim_fullname"}
                          value={victim.full_name}
                          label={"Imię i nazwisko poszkodowanego"}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper>
                        <StyledInputBox
                          disabled={true}
                          name={"victim_pesel"}
                          value={victim.pesel}
                          label={"PESEL"}
                        />
                      </TwoInputWrapper>
                      <StyledLabel>
                        Dokument tożsamości (dowód osobisty lub paszport)
                      </StyledLabel>
                      <LeftPadding>
                        <TwoInputWrapper>
                          <StyledInputBox
                            disabled={true}
                            name={"victim_id_document_series"}
                            value={victim.id_document_series}
                            label={"Seria dokumentu"}
                          />
                          <StyledInputBox
                            disabled={true}
                            name={"victim_id_document_name"}
                            value={victim.id_document_name}
                            label={"Rodzaj dokumentu"}
                          />
                        </TwoInputWrapper>
                        <TwoInputWrapper>
                          <StyledInputBox
                            disabled={true}
                            name={"victim_id_document_name"}
                            value={victim.id_document_number}
                            label={"Numer dokumentu"}
                          />
                        </TwoInputWrapper>
                      </LeftPadding>
                      <TwoInputWrapper>
                        <StyledInputBox
                          disabled={true}
                          name={"victim_birth_date"}
                          value={
                            victim.birth_date
                              ? moment(victim.birth_date).format("YYYY-MM-DD")
                              : ""
                          }
                          label={"Data urodzenia"}
                        />
                        <StyledInputBox
                          disabled={true}
                          name={"victim_birth_place"}
                          value={victim.birth_place}
                          label={"Miejsce urodzenia"}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper>
                        <StyledInputBox
                          disabled={true}
                          name={"re_fulladdress"}
                          value={victim.resident_fulladdress}
                          label={"Adres zamieszkania"}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper>
                        <StyledInputBox
                          disabled={true}
                          name={"surence"}
                          value={victim.insurance_title}
                          label={"Tytuł ubezpieczenia"}
                        />
                      </TwoInputWrapper>
                    </LeftPadding>
                    <h4>Informacje o wypadku</h4>
                    <LeftPadding>
                      <TimeWrapperStyle>
                        <div>
                          <StyledLabel>Data wypadku</StyledLabel>
                          <Field
                            name="analysis_date_event"
                            render={({ input, meta, ...rest }) => (
                              <DayPicker
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : null
                                }
                                fullWidth
                                disabled={readOnly}
                                selectedDay={input.value}
                                handleChange={(value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          />
                        </div>
                      </TimeWrapperStyle>

                      <StyledLabel>W dniu wypadku poszkodowany</StyledLabel>
                      <TwoInputWrapper>
                        <FieldInput
                          disabled={readOnly}
                          name={"hour_work_start"}
                          validate={validator.timeHM}
                          label={"Miał rozpocząć pracę o godz."}
                        />
                        <FieldInput
                          disabled={readOnly}
                          name={"hour_work_end"}
                          validate={validator.timeHM}
                          label={"Zakończył pracę o godz."}
                        />
                      </TwoInputWrapper>
                      {!readOnly && errors && errors.hour_work_start_end && (
                        <StyledInputMessage>
                          {errors.hour_work_start_end}
                        </StyledInputMessage>
                      )}
                      <FieldSelectDitionary
                        name="accident_situation_id"
                        label={"Wypadek zdarzył się"}
                        validate={validator.required}
                        dictionary={dictionary.accident_owtw_place}
                        disabled={readOnly}
                      />

                      <FieldTextareaBox
                        disabled={readOnly}
                        name={"detail_description"}
                        validate={validator.required}
                        label={
                          "Szczegółowy opis okoliczności, miejsca i przyczyn wypadku:"
                        }
                      />
                      <FieldSelectDitionary
                        name="is_institution_do_action"
                        validate={validator.required}
                        disabled={readOnly}
                        label="Czy w sprawie wypadku były podjęte czynności przez odpowiednie organy"
                        dictionary={[
                          { id: "true", name: "Tak" },
                          { id: "false", name: "Nie" },
                        ]}
                      />
                      <SubFieldCleaner
                        watched="is_institution_do_action"
                        overwritten="institution_action"
                      />

                      <LeftPadding>
                        <SubField watched="is_institution_do_action">
                          <FieldInput
                            name={"institution_action"}
                            disabled={readOnly}
                          />
                        </SubField>
                      </LeftPadding>
                      <span>
                        Wypadek spowodował niezdolność do pracy (Podać okres
                        niezdolności do pracy objęty zaświadczeniem lekarskim,
                        które zostało przedłożone przed sporządzeniem karty
                        wypadku. Podmiot sporządzający kartę wypadku, niebędący
                        płatnikiem składek, wpisuje okres niezdolności do pracy
                        na podstawie oświadczenia poszkodowanego lub członka
                        jego rodziny)
                      </span>
                      <LeftPadding>
                        <TwoInputWrapper>
                          <FieldDayPicker
                            name={"unable_to_work_from"}
                            disabled={readOnly}
                            validate={validator.required}
                            label={"Od"}
                          />
                          <FieldDayPicker
                            disabled={readOnly}
                            name={"unable_to_work_to"}
                            label={"Do"}
                          />
                        </TwoInputWrapper>
                      </LeftPadding>

                      <FieldSelectDitionary
                        name="did_accident_cause_death"
                        disabled={readOnly}
                        validate={validator.required}
                        label="Wypadek spowodował zgon"
                        dictionary={[
                          { id: "true", name: "Tak" },
                          { id: "false", name: "Nie" },
                        ]}
                      />
                      <StyledLabel>Świadkowie wypadku</StyledLabel>
                      <LeftPadding>
                        <TwoInputWrapper>
                          <StyledLabel>Imię i nazwisko</StyledLabel>
                          <StyledLabel>Adres zamieszkania</StyledLabel>
                        </TwoInputWrapper>
                        {witnesses && witnesses.length
                          ? witnesses.map((item) => {
                              return (
                                <TwoInputWrapper>
                                  <StyledInputBox
                                    disabled={true}
                                    value={item.full_name}
                                  />
                                  <StyledInputBox
                                    disabled={true}
                                    value={item.residence_fulladdress}
                                  />
                                </TwoInputWrapper>
                              );
                            })
                          : null}
                      </LeftPadding>
                      <FieldSelectDitionary
                        name="is_owtw"
                        label="Wypadek jest wypadkiem w drodze do pracy lub z pracy"
                        validate={validator.required}
                        disabled={readOnly}
                        dictionary={[
                          { id: "true", name: "Tak" },
                          { id: "false", name: "Nie" },
                        ]}
                      />
                      <SubFieldCleaner
                        watched="is_owtw"
                        overwritten="is_owtw_results"
                        cleanValue="true"
                        overrideValue="Nie dotyczy"
                      />
                      <LeftPadding>
                        <SubField watched="is_owtw" avaibleValue="false">
                          <FieldTextareaBox
                            disabled={readOnly}
                            name={"is_owtw_results"}
                            label={
                              "Uzasadnienie nieuznania wypadku za wypadek w drodze do pracy lub z pracy"
                            }
                          />
                        </SubField>
                        <FieldSelectDitionary
                          disabled={readOnly}
                          name="accident_was_not_recognized"
                          label="Wypadek nie został uznany za wypadek"
                          dictionary={[
                            { id: 1, name: "Nie dotyczy" },
                            { id: 2, name: "W drodze do pracy" },
                            { id: 3, name: "W drodze z pracy" },
                          ]}
                        />
                      </LeftPadding>
                    </LeftPadding>
                    <h4>Pozostałe informacje</h4>
                    <LeftPadding>
                      <FieldDayPicker33
                        disabled={readOnly}
                        name="compliting_date"
                        validate={validator.required}
                        label="Kartę wypadku sporządzono w dniu"
                      />
                      <FieldInput33
                        disabled={readOnly}
                        name="compliting_person"
                        validate={validator.required}
                        label="Imie i nazwisko sporządzającego kartę wypadku"
                      />
                      <FieldInput33
                        disabled={readOnly}
                        name="compliting_place"
                        validate={validator.required}
                        label="Miejscowość sporządzenia karty wypadku"
                      />

                      <FieldTextareaBox
                        disabled={readOnly}
                        name={"preperation_dificulties"}
                        validate={validator.required}
                        label="Przeszkody i trudności uniemożliwiające sporządzenie karty wypadku w wymaganym terminie 14 dni:"
                      />
                    </LeftPadding>
                    <ButtonWrapper>
                      <FormSpy subscribe={{ form: true, values: true }}>
                        {({ values }) => {
                          return (
                            <Button
                              disabled={!permission[19] && readOnly}
                              onClick={() => {
                                this.saveOnly(
                                  values,
                                  this.generateAccidentCard
                                );
                              }}
                            >
                              Generuj kartę wypadku
                            </Button>
                          );
                        }}
                      </FormSpy>
                    </ButtonWrapper>
                  </ToggleSlider>
                  <ButtonWrapper>
                    <LinkContainer exact to="/accident-owtw">
                      <Button type="button">Anuluj</Button>
                    </LinkContainer>
                    <FormSpy subscribe={{ form: true, values: true }}>
                      {({ values }) => {
                        return (
                          <Button
                            type="button"
                            disabled={!permission[10]}
                            onClick={() => {
                              this.saveOnly(values);
                            }}
                          >
                            Zapisz
                          </Button>
                        );
                      }}
                    </FormSpy>
                    {permission[10] ? (
                      <Button
                        // onClick={handleSubmit}
                        onClick={() => {
                          this.saveOnly(values, this.moveToNextStage);
                        }}
                        type="button"
                        disabled={accidentData.stage < 1}
                      >
                        Dalej
                      </Button>
                    ) : (
                      <LinkContainer
                        exact
                        to={`/accident-owtw/attachment/${accident_id}`}
                      >
                        <Button type="button" disabled={accidentData.stage < 1}>
                          Dalej
                        </Button>
                      </LinkContainer>
                    )}
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button type="submit" bsStyle="primary">
                      Przygotuj do weryfikacji
                    </Button>
                  </ButtonWrapper>
                  <ModalResponsibility
                    modal={this.state.modalResponsibility}
                    accept={this.setUserAssign}
                    closeModal={this.closeModal("modalResponsibility")}
                    workplaceData={workplaceData}
                    usersData={usersData}
                    setWorkplace={this.handleSelectWorkplace}
                    setUser={this.setUserState}
                  />
                </form>
              );
            }}
          />
        </Box>
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  dictionary: store.dictionary,
  accident_owtw: store.accident_owtw,
  workplace: store.workplace,
  users: store.users,
}))(Analysis);
