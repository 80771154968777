import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Link } from "react-router-dom";

import AppComponent from "components/AppComponent";
import Box from "components/Box";

import { ButtonWithIcon } from "components/Buttons";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { TableListPage } from "components/Tables";
import { ButtonWrapper, Center } from "components/Wrappers";
import DragAndDrop from "components/DragAndDrop/RAAnalysis";
import ModalDelete from "components/Modal/SimpleAccept";

import Register from "./Register";
import Category from "./Category";
import SlideToogleLocal from "./SlideToogleLocal";

export default class AddRiskAssessmentReview extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, modalDelete: this.initModalState() };

    this.deleteRAAnalysisHazard = this.deleteRAAnalysisHazard.bind(this);
    this.deleteRAAnalysisHazardRequest = this.deleteRAAnalysisHazardRequest.bind(
      this
    );
  }

  deleteRAAnalysisHazardRequest(id) {
    return this.openModal("modalDelete", { entry_id: id });
  }

  deleteRAAnalysisHazard(data) {
    let { deleteRAAnalysisHazard } = this.props;
    let { entry_id } = this.state.modalDelete;
    deleteRAAnalysisHazard(entry_id)();
  }

  render() {
    let {
      draft,
      getGetHazard,
      SideMenu,
      controlList = [],
      controlListThreads = [],
      id,
      data,
      modelHazardList,
      method,
      hazards = [],
      addRAAnalysisHazard = () => {},
      deleteRAAnalysisHazard = () => {},
      addRAAnalysisHazardByThread = () => {},
      addCategory = () => {},
      changeOrder = () => {},
      saveHazard,
      permission,
      urls,
      setNextStage = () => {},
      saveModelHazard = () => {},
      readOnly,
      message,
    } = this.props;
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={SideMenu}
      >
        <Box
          title={"Dodaj nową ocenę ryzyka zawodowego - Analiza ryzyka"}
        ></Box>
        <SlideToogleLocal
          name={"Zagrożenia zidentyfikowane w liście kontrolnej"}
        >
          {/* <h4>Zagrożenia zidentyfikowane w liście kontrolnej</h4> */}
          <TableListPage>
            <thead>
              <td>Nazwa zagrożenia</td>
              <td>Czy skopiowany?</td>
              <td>Kopiuj</td>
            </thead>
            <tbody>
              {controlListThreads.map((item, index) => {
                let { name, data } = item;
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.copy && (
                        <i style={{ fontSize: "20px" }} class="fa fa-check" />
                      )}
                    </td>
                    <td>
                      <ButtonWithIcon
                        onClick={() => {
                          addRAAnalysisHazardByThread(item.id);
                        }}
                        icon="fa fa-files-o"
                      ></ButtonWithIcon>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableListPage>
        </SlideToogleLocal>
        <div style={{ textAlign: "center" }}>
          <h4>
            Karta oceny ryzyka metodą{" "}
            {method.ra_method_id ? (
              <Link to={urls.METHODEDIT(method.ra_method_id)}>
                {method.ra_method_name}
              </Link>
            ) : (
              <span>{method.ra_method_name}</span>
            )}
          </h4>
        </div>
        <DragAndDrop
          items={hazards}
          handleOnDragEnd={(result) => {
            let data = result.map((i) => {
              return i.id;
            });

            changeOrder(data);
          }}
          render={(i, DragAndDropMove) => {
            return (
              <>
                {i.category ? (
                  <SlideToogleLocal
                    name={"Kategoria"}
                    DragAndDropMove={DragAndDropMove}
                  >
                    <Category
                      saveHazard={saveHazard}
                      data={i}
                      deleteRAAnalysisHazard={
                        this.deleteRAAnalysisHazardRequest
                      }
                    />
                  </SlideToogleLocal>
                ) : (
                  <SlideToogleLocal
                    name={"Zagrożenie (zdarzenie)"}
                    DragAndDropMove={DragAndDropMove}
                  >
                    <Register
                      handleServerResponse={this.handleServerResponse}
                      saveHazard={saveHazard}
                      getGetHazard={getGetHazard}
                      deleteRAAnalysisHazard={
                        this.deleteRAAnalysisHazardRequest
                      }
                      data={i}
                      method={method}
                      modelHazardList={modelHazardList}
                      saveModelHazard={saveModelHazard}
                    />
                  </SlideToogleLocal>
                )}
              </>
            );
          }}
        ></DragAndDrop>

        <ButtonWrapper>
          <Button disabled={readOnly} onClick={addCategory}>
            Dodaj kategorię zagrożeń
          </Button>
          <Button disabled={readOnly} onClick={addRAAnalysisHazard}>
            Dodaj zagrożenie
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          {!draft && data.stage <= 3 ? (
            <Button onClick={setNextStage}>Dalej</Button>
          ) : (
            <>
              {urls.DRAFTTASK || urls.TASK ? (
                <LinkContainer
                  to={draft ? urls.DRAFTLIST : urls.TASK(id)}
                  exact
                >
                  <Button>{draft ? "Powrót do listy" : "Dalej"}</Button>
                </LinkContainer>
              ) : null}
            </>
          )}
        </ButtonWrapper>
        <ModalDelete
          title="Usuń metody oceny ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={this.state.modalDelete.open}
          handleAccept={this.deleteRAAnalysisHazard}
          handleClose={this.closeModal("modalDelete")}
        />
      </LayoutContent>
    );
  }
}
