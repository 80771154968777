import React from "react";
import AppComponent from "../../components/AppComponent";
import QuickAccessButton,{QuickAccessButtonWrapper} from "../../components/QuickAccessButton";

class Dashboard extends AppComponent {
  render(){
    return (
      <div>
        <QuickAccessButtonWrapper>
          <QuickAccessButton to="/business/localization">Ustawienia lokacji</QuickAccessButton>
          <QuickAccessButton to="/business/workplace">Ustawienia funkcji/stanowisk pracowników</QuickAccessButton>
          <QuickAccessButton to="/business/users-accounts">Ustawienia kont użytkowników</QuickAccessButton>
          <QuickAccessButton to="/business/responsibility">Ustawienia odpowiedzialności</QuickAccessButton>
          <QuickAccessButton to="/business/notification">Ustawienia automatycznych powiadomień</QuickAccessButton>
        </QuickAccessButtonWrapper>
      </div>
    );
  }
}

export default Dashboard;