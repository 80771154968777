import React from "react";
import { connect } from "react-redux";
import { getUserWorkplacesWithout } from "actions/workplaceActions";
import {
  getNotification,
  registerNotifications,
  deleteRegisteredNotification,
  deleteUserFromRegisterGroup,
  registerNotification,
  addUserToRegisterGroup,
  getIncidentToRegister,
  closeIncident,
  getHistory,
  getRA,
  closeRA,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";

import NotificationComponent from "components/NotificationComponent";
import SideMenu from "./_SideMenu";
import { setUrls } from "../_staticData";
export class Notification extends NotificationComponent {
  constructor(props) {
    super(props);

    let urls = setUrls();

    this.state = {
      ...this.state,
      urls,
      dictionary_progress_open: 1,
      mobileNotHideMenu: true,
      notification_group_id: 6,
      moduleListUrl: urls.LIST,
      permission_to_edit: 13,
    };
  }

  componentDidMount() {
    const { dispatch, match, singleData } = this.props;
    const { id } = match.params;

    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());
    getNotification(id)(dispatch).then(this.handleServerResponse());
    getRA(id)(dispatch).then(this.handleServerResponse());
    this.getHistory();
    if (!Object.keys(getRA).length || singleData.id != id) {
      getRA(id)(dispatch).then(this.handleServerResponse({}, (action) => {}));
    }
    this.setState({
      dictionary_progress_id: singleData.dictionary_progress_id,
    });

  }

  saveNotifications() {
    const { urls } = this.state;
    const { dispatch, match, history } = this.props;
    const { id } = match.params;
    const result = this.prepareData();
    registerNotifications(
      id,
      result
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.LIST);
      })
    );
  }

  handleAddUserToRegisterGroup(notification_group_id, user_id, user) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    addUserToRegisterGroup(
      id,
      user_id
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteUserFromRegisterGroup() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    deleteUserFromRegisterGroup(id, {
      register_notification_group_has_users_id: this.state.modalUserDelete
        .entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  handleRegisterNotification(data) {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    data.term = data.term == "null" ? null : data.term;
    registerNotification(id, data)(dispatch).then(this.handleServerResponse());
  }

  handleDeleteRegisteredNotification() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    const {
      modalNotificationDelete: { entry_id },
    } = this.state;
    deleteRegisteredNotification(id, {
      register_notification_id: entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  renderSideMenu() {
    const { match, singleData } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={singleData.stage} />;
  }

  closeInstance() {
    const { urls } = this.state;
    const { dispatch, history, match } = this.props;
    const { id } = match.params;
    closeRA(id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.LIST);
      })
    );
  }

  getHistory() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    getHistory(id)(dispatch).then(this.handleServerResponse());
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  workplace: store.workplace,
  notification: store.notification,
  singleData: store.riskAssessment.singleData,
}))(Notification);
