import React from "react";
import { FormSpy } from "react-final-form";
import diff from "object-diff";

class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = { values: props.values, submitting: false };
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { data: values, save, errors } = this.props;
    // This diff step is totally optional
    const prev = { ...this.state.values };
    let difference = diff(this.state.values, values);
    let errorsKeys = Object.keys(errors);

    difference = Object.keys(difference)
      .filter((key) => {
        return errorsKeys.findIndex((k) => k == key) == -1;
      })
      .reduce((obj, key) => {
        obj[key] = difference[key];
        return obj;
      }, {});

    if (Object.keys(difference).length) {
      // values have changed
      this.setState({ submitting: true, values });
      this.promise = save(difference, prev);
      await this.promise;
      delete this.promise;
      this.setState({ submitting: false });
    }
  };

  render() {
    return (
      this.state.submitting && <div className="submitting">Submitting...</div>
    );
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in save prop nicely
export default (props) => (
  <FormSpy
    // {...props}
    setFieldData={props.setFieldData}
    data={props.values}
    debounce={props.save}
    save={props.save}
    subscription={{ active: true, values: true, errors: true }}
    component={AutoSave}
  />
);
//  debounce={500}
//                   setFieldData={form.mutators.setFieldData}
//                   values={incidentData}
//                   save={this.updateChanges}
