export default function reducer(
  state = {
    message: "",
    error: "",
  },
  action
) {
  switch (action.type) {
    case "CLEAN_MESSAGES": {
      return { ...state, message: "", error: "" };
    }
    case "ADD_BUSINESSES_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "RESET_PASSWORD_CONFIRM_USER_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_LOCALIZATION_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_LOCALIZATION_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    // case "DELETE_LOCALIZATION_SUCCESS":{
    //   return {...state, message: action.payload.data.message, error: ""};
    // }
    case "ADD_DIVISION_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_DIVISION_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_WORKPLACES_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_WORKPLACES_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_USER_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_USER_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_CHARACTERISTIC_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_CHARACTERISTIC_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_AUDIT_TYPE_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "RESET_PASSWORD_CONFIRM_SUPER_ADMIN_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_ERROR": {
      return {
        ...state,
        message: "",
        error: action.error.response.data.message,
      };
    }
    case "RESET_PASSWORD_CONFIRM_USER_ERROR": {
      return {
        ...state,
        message: "",
        error: action.error.response.data.message,
      };
    }
    case "GET_USERS_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "REGISTER_INCIDENT_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_PAGE_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_AUDIT_TYPE_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_TASK_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_TASK_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "SAVE_PAGE_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "REALIZATION_TASK_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "DELETE_TASK_ATTACHMENT_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_TASK_ATTACHMENT_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }

    case "DELETE_RA_METHOD_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ADD_RA_METHOD_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "EDIT_RA_METHOD_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "ANALYSIS_INCIDENT_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "REGISTER_ACCIDENT_OWTW_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "DELETE_RA_DRAFT_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }

    case "SAVE_MODEL_HAZARD_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "SAVE_CONTROL_LIST_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "DELETE_CONTROL_LIST_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }

    case "ADD_RA_CONTROL_LIST_SUCCESS": {
      return { ...state, message: action.payload.data.message, error: "" };
    }
    case "SAVE_QE_TYPE_ITEM_ERROR": {
      return { ...state, message: action.payload.data.message, error: "" };
    }

    default: {
      let events = [
        "SAVE_QE_TYPE_ITEM_SUCCESS",
        "DELETE_QE_TYPE_ITEM_SUCCESS",
        "SAVE_ME_DRAFT_SUCCESS",
        "SAVE_REFERRAL_DRAFT_SUCCESS",
        "DELETE_ME_DRAFT_SUCCESS",
        "DELETE_CERTIFICATE_ITEM_SUCCESS",
        "EDIT_PUBLIC_USER_SUCCESS",
        "EDIT_RISK_ASSESSMENT_SUCCESS",
        "SAVE_RA_AS_DRAFT_SUCCESS",
        "ARCHIVE_RA_SUCCESS",
        "SAVE_RA_ANALYSIS_HAZARD_SUCCESS",
      ];

      let foundedEvent = events.find((i) => {
        return i == action.type;
      });

      if (foundedEvent) {
        return { ...state, message: action.payload.data.message, error: "" };
      }
      return { ...state };
      // nie da się zautomatyzować bo jest za dużo wiadomości które się nadpisują, a błędy są obsłużone po stronie AppComponent

      return { ...state };
    }
  }
  return state;
}
