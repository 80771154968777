import React, { useEffect } from "react";
import styled from "styled-components";

import Button from "react-bootstrap/lib/Button";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";

import AppComponent from "components/AppComponent";
import { DeleteButton } from "components/Buttons";
import {
  FieldInput,
  FieldDayPicker,
  FieldSelect,
  FieldInput33,
} from "components/Fields";
import EmployeesList from "components/EmployeesList";
import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import { LinkContainer } from "components/LinkContainer";
import {
  ButtonWrapper,
  LeftPadding,
  TwoInputWrapper,
} from "components/Wrappers";

import MedicalReferral from "components/MedicalReferral.Form";
import { FieldTextareaBox } from "components/Fields";
import { StyledLabel } from "style/styleComponents/Form/input";
import GetWorkplace from "./GetWorkplace";
import WorkplaceEmployesModal from "./WorkplaceEmployesModal";

import validator from "components/Validation";
const { v4: uuidv4 } = require("uuid");

let OpetarionLine = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 10px;
    &:nth-child(1) {
      flex: 1 1 50%;
    }
    &:nth-child(2) {
      flex: 1 1 50%;
    }
    &:nth-child(3) {
      flex: 0 0 50px;
    }
  }
`;

export default class WorplaceCharakteristicComponent extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      workplace_id: this.props.data ? this.props.data.workplace_id : null,
    };

    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const newData = { ...data };
    const { operations } = newData;
    for (const [index, item] of operations.entries()) {
      if (typeof item.id !== "number") {
        delete operations[index].id;
      }
    }

    this.props.submit(newData);
  }

  render() {
    let { message, workplace_id } = this.state;
    let {
      business_id,
      id,
      SideMenu,
      permission = {},
      draft,
      submit,
      localizations,
      sections,
      workers,
      getSections = () => {
        alert("mock fn");
      },
      addWorker = () => {
        alert("mock WCC");
      },
      deleteWorker = () => {
        alert("mock d");
      },
      addRAWorkersByWorkplace = () => {},
      workplaces = [],
      data = {},
      readOnly,
      title = "Dodaj wzorcową ocenę ryzyka zawodowego - Charakterystyka stanowiska pracy",
      urls,
    } = this.props;

    return (
      <>
        <LayoutContent
          mobileNotHideMenu={true}
          SideMenu={SideMenu}
          error404={this.state.error_code == 404}
          message={message}
        >
          <Box title={title}>
            <Form
              onSubmit={this.submit}
              initialValues={data}
              mutators={{ ...arrayMutators }}
              render={({
                handleSubmit,
                pristine,
                invalid,
                values = {},
                form,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {!draft && (
                      <>
                        <Field
                          name="workplace_id"
                          // validate={validator.required}
                          render={({ input, meta, ...rest }) => (
                            <>
                              <GetWorkplace
                                readOnly={readOnly}
                                input={input}
                                workplaces={workplaces}
                              />
                            </>
                          )}
                        />
                        <OnChange name="workplace_id">
                          {(value) => {
                            this.setState({
                              workplace_id: value,
                            });
                          }}
                        </OnChange>
                        <FieldSelect
                          disabled={readOnly}
                          validate={validator.required}
                          label="Lokalizacja"
                          name="localization_id"
                          data={localizations}
                        />
                        <OnChange name="localization_id">
                          {(value) => {
                            getSections(value);
                          }}
                        </OnChange>
                        <FieldSelect
                          disabled={readOnly}
                          label="Dział"
                          name="section_id"
                          data={sections}
                        />
                      </>
                    )}
                    <FieldInput
                      disabled={readOnly}
                      name="number_of_employees"
                      label="Liczba pracowników z zatrudnionych na ocenianym stanowisku"
                    />
                    <StyledLabel></StyledLabel>
                    <LeftPadding>
                      <FieldInput
                        disabled={readOnly}
                        name="women_number"
                        label="I. Kobiety"
                      />
                      <FieldInput
                        disabled={readOnly}
                        name="men_number"
                        label="II. Mężczyźni"
                      />
                      <FieldInput
                        disabled={readOnly}
                        name="young_number"
                        label="III. Młodociani"
                      />
                      <FieldInput
                        disabled={readOnly}
                        name="disabled_number"
                        label="IV. Niepełnosprawni"
                      />
                    </LeftPadding>

                    {!draft && (
                      <WorkplaceEmployesModal
                        permission={permission}
                        addRAWorkersByWorkplace={addRAWorkersByWorkplace}
                        workplace_id={workplace_id}
                        addWorker={addWorker}
                        deleteWorker={deleteWorker}
                        data={workers}
                      />
                    )}
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="description"
                      label="Charakterystyka ocenianego stanowiska pracy"
                    />

                    <StyledLabel>
                      Operacje i czynności występujące na stanowisku pracy
                    </StyledLabel>
                    <LeftPadding>
                      <OpetarionLine>
                        <div>
                          <StyledLabel>Nazwa operacji</StyledLabel>
                        </div>
                        <div>
                          <StyledLabel>Podstawowe czynności</StyledLabel>
                        </div>
                        <div></div>
                      </OpetarionLine>

                      <FieldArray name="operations">
                        {({ fields }) => (
                          <>
                            {fields.map((name, index) => (
                              <>
                                <OperationComponent
                                  key={fields.value[index].id}
                                  fields={fields}
                                  name={name}
                                  index={index}
                                  readOnly={readOnly}
                                />
                              </>
                            ))}

                            <ButtonWrapper>
                              <Button
                                disabled={readOnly}
                                onClick={() => {
                                  fields.push({ id: uuidv4() });
                                }}
                              >
                                Dodaj
                              </Button>
                            </ButtonWrapper>
                          </>
                        )}
                      </FieldArray>
                    </LeftPadding>
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="list_of_workplace_tools"
                      label="Wykaz narzędzi, sprzętu, maszyn i urządzeń użytkowanych na stanowisku"
                    />
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="work_environment_research_result"
                      label="Wyniki badań środowiska pracy"
                    />
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="required_training_qualification"
                      label="Wymagane szkolenia / uprawnienia kwalifikacyjne"
                    />
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="workplace_health_requirements"
                      label="Wymagania zdrowotne dla pracowników na stanowisku"
                    />
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="personal_protection"
                      label="Zastosowane środki ochrony indywidualnej"
                    />
                    <ButtonWrapper>
                      {!readOnly ? (
                        <Button type="submit">Dalej</Button>
                      ) : (
                        <LinkContainer to={urls.ANALYSIS(id)}>
                          <Button>Dalej</Button>
                        </LinkContainer>
                      )}
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Box>
        </LayoutContent>
      </>
    );
  }
}

function OperationComponent(props) {
  const { fields, name, index, readOnly } = props;

  return (
    <>
      <OpetarionLine>
        <div>
          <FieldInput disabled={readOnly} name={`${name}.name`} />
        </div>
        <div>
          <FieldTextareaBox disabled={readOnly} name={`${name}.description`} />
        </div>
        <div>
          <DeleteButton
            disabled={readOnly}
            onClick={() => {
              fields.remove(index);
            }}
            listButton={false}
          />
        </div>
      </OpetarionLine>
    </>
  );
}
