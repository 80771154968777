export default function reducer(
  state = {
    data: [],
    singleData: {},
    fetching: false,
    fetched: true,
  },
  action
) {
  switch (action.type) {
    case "GET_CERTIFICATE_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, data, permission };
    }
    case "GET_CERTIFICATE_ITEM_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, singleData: data, permission };
    }
    case "DELETE_CERTIFICATE_ITEM_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return {
        ...state,
        data: state.data.filter(
          (item) => item.id != action.payload.data.data.id
        ),
        permission,
      };
    }
  }

  return state;
}
