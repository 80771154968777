import React from "react";
import { connect } from "react-redux";

import {
  getDraftControlListList,
  deleteDraftControlList,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";

import List from "components/ControlList/List";
import SideMenu from "../_SideMenu";
import { setUrls } from "./_staticData";

class ControlList extends AppComponent {
  constructor(props) {
    super(props);

    this.deleteEntry = this.deleteEntry.bind(this);
  }

  componentDidMount() {
    this.updateMessage();
    getDraftControlListList()(this.props.dispatch);
  }

  deleteEntry(id) {
    let { dispatch } = this.props;
    deleteDraftControlList(id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { message } = this.state;
    let { controlList } = this.props;
    return (
      <>
        <List
          message={message}
          data={controlList}
          deleteEntry={this.deleteEntry}
          SideMenu={SideMenu}
          urls={setUrls()}
          superAdmin
        />
      </>
    );
  }
}

export default connect((store) => ({
  controlList: store.riskAssessment.controlList,
  message: store.message,
}))(ControlList);
