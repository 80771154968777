import React, { Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import {
  saveIncidentWitness,
  deleteIncidentWitness,
} from "actions/incidentActions";

import { saveVictimExplains } from "actions/incidentActions";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import DayPicker from "lib/DayPicker";

import { SpaceBlock } from "../AnalysisForm.style";
import { TwoInputWrapper } from "../AnalysisForm.style";
import AppComponent from "components/AppComponent";
import SimpleAcceptModalDump from "components/Modal/WitnessesForm/List";

class SimpleAcceptModal extends AppComponent {
  constructor(props) {
    super(props);
    this.deleteWitness = this.deleteWitness.bind(this);
  }

  deleteWitness(id, callback) {
    let { dispatch, incident_id } = this.props;
    deleteIncidentWitness({ incident_id, id })(dispatch).then(
      this.handleServerResponse({}, () => {
        if (callback) {
          callback();
        }
      })
    );
  }

  render() {
    let { ...props } = this.props;
    return (
      <SimpleAcceptModalDump {...props} deleteWitness={this.deleteWitness} />
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
}))(SimpleAcceptModal);

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
