import React from "react";
import { connect } from "react-redux";

import { addTaskByIncident } from "actions/taskActions";
import { addTaskByAudit } from "actions/taskActions";
import { getAudit } from "actions/auditActions";
import {
  pingIncidentTask,
  addTaskAttachmentLocal,
  deleteTaskAttachmentLocal,
} from "actions/taskActions";
import { clearTask } from "actions/taskActions";

import LoadingScreen from "components/LoadingScreen/";
import TaskForm from "components/Task.Form";
import dataToFormData from "components/dataToFormData";

import { TaskAdd } from "container/Task/Add";

import SideMenu from "../_SideMenu";

class AuditTaskAdd extends TaskAdd {
  componentDidMount() {
    const { match, dispatch } = this.props;
    const { audit_id } = match.params;
    getAudit(audit_id)(this.props.dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  handleSubmit(data) {
    const { dispatch, history, match,task } = this.props;
    const { audit_id, audit_question_id } = match.params;
    const { attachments } = task; 

    for (let attachment of attachments) {
      data[`task_new_attachment_file_${attachment.id}`] = attachment.fileData;
      data[`task_new_attachment_name_${attachment.id}`] = attachment.name;
    }

    const formData = dataToFormData(data);

    addTaskByAudit(
      audit_id,
      audit_question_id,
      formData
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/audit/task/${audit_id}`);
      })
    );
  }

  render() {
    const { message } = this.state;
    const {
      match,
      audit: { permission },
      task: { attachments },
    } = this.props;
    const { audit_id } = match.params;
    const LSideMenu = (props) => (
      <SideMenu permission={permission} audit_id={audit_id} />
    );

    if (permission[12]) {
      permission[2] = true;
      permission[3] = true;
    }

    return (
      <LoadingScreen fetched={true}>
        <TaskForm
          SideMenu={LSideMenu}
          attachments={attachments}
          permission={permission}
          message={message}
          readOnly={!permission[3]}
          noLocalization
          data={{
            priority: 2,
          }}
          handleSubmit={this.handleSubmit}
          backUrl={`/audit/task/${audit_id}`}
          createView
          addAttachment={addTaskAttachmentLocal}
          deleteAttachment={deleteTaskAttachmentLocal}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount(){
    clearTask()(this.props.dispatch);
  } 
}

export default connect((store) => ({
  audit: store.audit,
  task: store.task,
  localization: store.localization,
  section: store.section,
}))(AuditTaskAdd);
