function getParams(data = {}) {
  let params = new URLSearchParams();

  let keys = Object.keys(data);
  for (let k of keys) {
    params.set(k, data[k]);
  }

  return params ? `?${params}` : "";
}

export function getOSHList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_LIST",
      payload: {
        request: {
          url: `/osh/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getOSHListFilter(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_LIST",
      payload: {
        request: {
          url: `/osh/list`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getBusinessOSHSingle(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_SINGLE",
      payload: {
        request: {
          url: `/business/osh/user/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getOSHAccountData(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_SINGLE",
      payload: {
        request: {
          url: `/osh/user-account`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
export function getOSHTypeList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_TYPE_LIST",
      payload: {
        request: {
          url: `/osh-type/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessOSHTypeList(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_TYPE_LIST",
      payload: {
        request: {
          url: `/business/osh-type/list${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addOSH(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_OSH_ITEM",
      payload: {
        request: {
          url: `/osh/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addBusinessOSH(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_OSH_ITEM",
      payload: {
        request: {
          url: `/business/osh/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addOSHCertificate(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_OSH_CERTIFICATE",
      payload: {
        request: {
          url: `/certificate/osh/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getOSHCertificate(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_CERTIFICATE",
      payload: {
        request: {
          url: `/certificate/osh/:id`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getCertificates(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_OSH_CERTIFICATES",
      payload: {
        request: {
          url: `/certificate/osh`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteOSH(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_OSH",
      payload: {
        request: {
          url: `/osh/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteOSHCertificate(data) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_OSH_CERTIFICATE",
      payload: {
        request: {
          url: `/certificate/osh/delete/:id`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}
export function printOSHReport(data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/osh/print/report`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}
