import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
  getDraftRAMethod,
  saveDraftRAMethod,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import RiskAssessmentForm from "components/RiskAssessment/RiskAssessment.Form";
import { setUrls } from "./_staticData";
import SideMenu from "../_SideMenu";

class AuditAddTypes extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls(props.urls) };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { risk_assessment_id } = match.params;
    getDraftRAMethod(risk_assessment_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  handleSubmit(values) {
    const { urls } = this.state;
    const { dispatch, match, history } = this.props;
    const { risk_assessment_id } = match.params;
    saveDraftRAMethod(
      risk_assessment_id,
      values
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.LIST);
      })
    );
  }

  render() {
    const { urls } = this.state;
    const {
      riskAssessment: { raMethod },
    } = this.props;
    const { message } = this.state;
    return (
      <Fragment>
        <RiskAssessmentForm
          fetched={raMethod.fetched}
          SideMenu={<SideMenu />}
          data={raMethod}
          message={{ ...message, date: Date.now() }}
          handleSubmit={this.handleSubmit}
          urls={urls}
        />
      </Fragment>
    );
  }
  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
}))(AuditAddTypes);
