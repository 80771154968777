import React from "react";
import { connect } from "react-redux";

import { Notification } from "../EntryAddEdit/Notification";
import { setUrls } from "./_staticData";

class ArchivedNotification extends Notification {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls(), readOnlyState: true };
  }
}

export default connect((store) => ({
  workplace: store.workplace,
  notification: store.notification,
  singleData: store.riskAssessment.singleData,
}))(ArchivedNotification);
