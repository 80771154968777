import React from "react";
import { connect } from "react-redux";
import { getUserWorkplacesWithout } from "../../actions/workplaceActions";
import { getState } from "../../actions/incidentActions";
import NotificationComponent from "../../components/NotificationComponent";
import SideMenu from "./_SideMenu";
import { addNewIncident } from "./defaultComponent";

const getUrl = (id, stage) => {
  switch (stage) {
    case 5:
      return `/incident/analysis/${id}`;
    case 4:
      return `/incident/notification/${id}`;
    case 3:
      return `/incident/task/${id}`;
    case 2:
      return `/incident/analysis/${id}`;
    case 1:
      return `/incident/attachment/${id}`;
    case 0:
      return `/incident/register/${id}`;
    default:
      return `/incident/register/${id}`;
  }
};

class RedirectComponent extends NotificationComponent {
  componentDidMount() {
    const { dispatch, match, history } = this.props;
    const { incident_id } = match.params;
    getState(incident_id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { stage, dictionary_progress_id } = action.payload.data;
        if (dictionary_progress_id != 1) {
          history.replace(getUrl(incident_id, 0));
        } else {
          history.replace(getUrl(incident_id, stage));
        }
      })
    );
  }

  render() {
    return null;
  }
}

export default connect((store) => ({}))(RedirectComponent);
