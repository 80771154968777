import React from 'react';
import AppComponent from "../../components/AppComponent";
import {addIncident} from "../../actions/incidentActions";

export const addNewIncident = function() {
  const {dispatch, history} = this.props;
  addIncident()(dispatch)
    .then(this.handleServerResponse({}, (action) => {
      const {incident} = action.payload.data;
      history.push(`/incident/register/${incident}`);
    }));
};

class defaultIncidentComponent extends AppComponent {

  constructor(props){
    super(props);
    this.addNewIncident = addNewIncident.bind(this);
  }
}

export default defaultIncidentComponent;