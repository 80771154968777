import React from "react";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import WorkplaceForm from "../../components/Workplace.form";
import { connect } from "react-redux";
import {
  getProfessionCodes,
  getWorkplace,
  getWorkplacesParentWithout,
  editWorkplace,
  clearWorkplaceReducer,
} from "actions/workplaceActions";
import { getLocalizations } from "../../actions/localizationActions";
import { getUserByWorkplace } from "../../actions/userActions";
import LoadingScreen from "../../components/LoadingScreen";
import { getDictionaryProfessionCode } from "actions/dictionaryActions";

class WorkplaceEdit extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      localFetched: false,
      data_users: [],
      filter: {
        name: null,
        localization_id: null,
        localizationList: [],
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { workplace_id } = match.params;
    Promise.all([
      getWorkplace(workplace_id)(dispatch).then(this.handleServerResponse()),
      getWorkplacesParentWithout(workplace_id)(dispatch).then(
        this.handleServerResponse()
      ),
      getLocalizations()(dispatch).then(this.handleServerResponse()),
      getUserByWorkplace(workplace_id)(dispatch).then(
        this.handleServerResponse()
      ),
      getDictionaryProfessionCode()(dispatch),
    ]).then(() => {
      let {
        workplace: {
          singleData: { profession_code },
        },
      } = this.props;
      let code = profession_code;

      getDictionaryProfessionCode(code)(dispatch);

      this.setState({ localFetched: true });
    });
  }

  handleSubmit(data) {
    const { dispatch, match, history } = this.props;
    const { workplace_id } = match.params;
    editWorkplace(
      workplace_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/workplace");
      })
    );
  }

  render() {
    const { message, localFetched } = this.state;
    const {
      dictionary: { profession_code = [] },
      localization: { data: localizationData = [] },
      workplace: {
        data: workplaceData = [],
        singleData = {},
        fetched: workplaceFetched = false,
      },
      users: { data: usersData },
    } = this.props;

    const { permissions, ...data } = singleData;
    const employees = [
      { name: "Jan Kowalski", localization: "Dywizja 1 / Olsztyn" },
      { name: "Jan Kowalski", localization: "Dywizja 1 / Zatorze" },
    ];
    return (
      <LoadingScreen fetched={localFetched}>
        <WorkplaceForm
          data={data}
          boxTitle={"Edytuj stanowisko"}
          employees={employees}
          handleSubmit={this.handleSubmit}
          localizations={localizationData}
          message={message}
          permissions={permissions}
          professionCodes={profession_code}
          workplaces={workplaceData}
          workplaceFetched={workplaceFetched}
          users={usersData}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    clearWorkplaceReducer()(dispatch);
  }
}
export default connect((store) => ({
  dictionary: store.dictionary,
  message: store.message,
  localization: store.localization,
  workplace: store.workplace,
  module: store.module,
  users: store.users,
}))(WorkplaceEdit);
