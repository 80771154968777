import React from 'react';
import styled from 'styled-components'
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import {media} from '../../style/style-utils'
const StyledDragAndDropTitle = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${props=>props.theme.primaryColor};
  margin-bottom: 20px;
  cursor: move;

`
export const DragAndDropTitle =  SortableHandle(({title,isSorting}) =>
  <StyledDragAndDropTitle >{title}</StyledDragAndDropTitle>
);
//

const StyledDragAndDropItem = styled.div`
  padding: 0 20px 30px;
  width:33%;
  background-color: inherit;
  user-select: ${props=>props.isSorting ? 'none' : 'text'};
  ${media.md`
    width:50%
  `} 
  ${media.sm`
    width:50%
  `}
  ${media.xs`
    width:100%
  `}
`
export const DragAndDropItem = SortableElement(({title,content,children,isSorting}) =>
  <StyledDragAndDropItem isSorting={isSorting}>
    <DragAndDropTitle title={title} isSorting={isSorting} />
    {content}
    </StyledDragAndDropItem>
);

export const DragAndDropList = SortableContainer(({items,isSorting}) => {
  return (
    <div id="SortableList" style={{display:'flex',flexWrap:'wrap'}}>
      {items.map((value, index) => (
        <DragAndDropItem key={`item-${index}`} title={value.title} index={index} content={value.content} isSorting={isSorting}/>
      ))}
    </div>
  );
});

class DragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSorting: true
    }
    this.onSortStart = this.onSortStart.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortMove(){

  }

  onSortStart(){
    this.setState({isSorting:true});
  }

  onSortEnd({oldIndex, newIndex}){
    const {handleOnDragEnd,items} = this.props;
    const result = arrayMove(items, oldIndex, newIndex);
    handleOnDragEnd(result);
    this.setState({
      isSorting:false
    });
  };

  render() {
    const {items} = this.props;
    return <DragAndDropList items={items} axis="xy" useDragHandle={true} onSortStart={this.onSortStart} onSortEnd={this.onSortEnd} isSorting={this.state.isSorting}/>;
  }

}

export default DragAndDrop