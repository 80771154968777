import React from "react";
import { connect } from "react-redux";

import { saveRA, getRAMethods, getRA } from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import BasicForm from "components/RiskAssessment.Forms/Basic/index.js";

import SideMenu from "./_SideMenu";

import { setUrls } from "../_staticData";

import { setStageRA } from "actions/riskAssessmentAction";

class Add extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls(), draft: true };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    getRAMethods({ readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
    if (id) {
      getRA(id, { readOnly: true })(dispatch).then(this.handleServerResponse());
    }
  }

  submit(values) {
    let { urls } = this.state;
    let { history, dispatch, match } = this.props;
    let { id = null } = match.params;
    let data = { ...values, draft: true };
    saveRA(
      id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        if (action.payload.data) {
          let { data } = action.payload.data;
          history.push(urls.DRAFTDANGER(data.id));
        }
      })
    );
  }

  setNextStage() {
    let { urls } = this.state;
    let { dispatch, match, history } = this.props;
    let { id } = match.params;

    setStageRA(
      id,
      4
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.DRAFTLIST(id));
      })
    );
  }

  render() {
    let {
      riskAssessment: { methods = [], singleData, permission },
      match,
    } = this.props;
    let { id } = match.params;
    const readOnly =
      Boolean(singleData.business_id === 0) ||
      (match.params.id ? !permission[27] : !permission[26]);

    return (
      <BasicForm
        draft
        readOnly={readOnly}
        urls={this.state.urls}
        id={match.params.id}
        data={singleData}
        methods={methods}
        SideMenu={<SideMenu id={id} data={singleData.stage} />}
        submit={this.submit}
      />
    );
  }
}

export default connect((store) => ({
  riskAssessment: store.riskAssessment,
  data: store.riskAssessment.singleData,
}))(Add);
