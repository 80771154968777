import React, { Fragment } from "react";
import { Field } from "react-final-form";
import styled from "styled-components";

import { getDictionary } from "actions/dictionaryActions";

import Select from "components/Select";
import { StyledInputBox } from "style/styleComponents/Form/input";
import validator, { composeValidators } from "components/Validation/";
import AutoComplete from "components/AutoCompleteInput";

let InputsWrapper = styled.div`
  display: flex;
  margin: 0 -10px 10px;
  align-items: flex-end;

  & > * {
    margin: 0 10px;
    flex: 0 0 30%;

    &:nth-child(1) {
    }
  }
`;

export default class VictimSection extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      values = {},
      form,
      readOnly,
      searchedWorkplaces,
      person_status,
      autoCompleteUsers,
    } = this.props;
    return (
      values.personal == "true" && (
        <div>
          Osoby poszkodowane
          <InputsWrapper>
            <Field
              name="person.full_name"
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <Fragment>
                  <AutoComplete
                    label={"Imię i nazwisko"}
                    data={autoCompleteUsers}
                    disabled={readOnly}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    value={input.value}
                    maxListHeight={"260px"}
                    propValue={"id"}
                    propName={"full_name"}
                    fullData
                    handleChange={(value) => {
                      input.onChange(value);

                      form.change("person.user_id", "");
                      form.change("person.user_id", "");
                    }}
                    handleSelect={(value) => {
                      // autoCompleteUsers
                      input.onChange(value.full_name);
                      let user = autoCompleteUsers.find((item) => {
                        return item.id == value.id;
                      });

                      form.change("person.user_id", value.id);
                      form.change("person.user_id", value.id);
                      form.change("person.workplace_name", user.workplace_name);
                      form.change("person.workplace_name", user.workplace_name);
                      form.change("person.person_status_id", 1);
                    }}
                  />
                </Fragment>
                // <StyledInputBox
                //   label={"Imię i nazwisko"}
                //   disabled={readOnly}
                //   error={meta.error && meta.touched}
                //   helperText={meta.error && meta.touched ? meta.error : null}
                //   onFocus={input.onFocus}
                //   onBlur={input.onBlur}
                //   value={input.value}
                //   onChange={(event) => {
                //     input.onChange(event);
                //   }}
                // />
              )}
              type="text"
            />
            <Field
              name={`person.workplace_name`}
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <Fragment>
                  <AutoComplete
                    maxListHeight={"260px"}
                    wrapperStyleFullLength
                    label="Stanowisko pracy poszkodowanego"
                    error={meta.error && meta.touched}
                    disabled={readOnly}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    data={searchedWorkplaces || []}
                    value={input.value || ""}
                    propValue={"name"}
                    handleChange={(value) => {
                      input.onChange(value);
                    }}
                    selectOnBlur={false}
                    handleSelect={(value) => {
                      input.onChange(value.name);
                    }}
                  />
                </Fragment>
              )}
            />
            <Field
              name="person.person_status_id"
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <Fragment>
                  <Select
                    {...input}
                    label="Status powiązana służbowego"
                    disabled={readOnly}
                    fullWidth
                    title="-- wybierz --"
                    propValue={"id"}
                    name={"name"}
                    selected={input.value}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    data={person_status}
                    handleChange={(value) => {
                      input.onChange(value);
                    }}
                  />
                </Fragment>
              )}
              type="text"
            />
          </InputsWrapper>
        </div>
      )
    );
  }
}
