const saveBlobFile = (file,fileName) => {
  const blob = new Blob([file]);
  if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  }
  else{
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}

export default saveBlobFile;
