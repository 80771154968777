import React from "react";
import Panel from "react-bootstrap/lib/Panel";
import styled from "styled-components";
import PropTypes from "prop-types";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Button from "react-bootstrap/lib/Button";
import { bootstrapUtils } from "react-bootstrap/lib/utils";
import { withTheme } from "styled-components";
import { media } from "../style/style-utils";
import { LinkContainer } from "./LinkContainer";

bootstrapUtils.addStyle(Panel, "Task");
bootstrapUtils.addStyle(Button, "SideMenuButton");

const SideMenuBox = styled.div`
  ${media.sm`
    ${(props) => (props.mobileNotHide ? "padding: 0 15px;" : "display:none;")}
  `}
`;

const StyledLinkContainer = styled(LinkContainer)`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;
StyledLinkContainer.displayName = "StyledLinkContainer";

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
  & > button,
  & > a {
    &:first-child {
      border-radius: 0 !important;
    }
  }
`;

const createPanelHeadingStyle = (props) => ({
  textAlign: "center",
  backgroundColor: props.theme.navBar.backgroundColor,
  backgroundImage: `linear-gradient(to bottom, ${
    props.theme.navBar.backgroundColor
  } 0%, ${
    props.theme.navBar.backgroundDarknessColor
      ? `${props.theme.navBar.backgroundDarkColor} 60%, ${props.theme.navBar.backgroundDarknessColor} 100%)`
      : `${props.theme.navBar.backgroundDarkColor} 100%)`
  }`,
  color: props.theme.primaryTextColor,
});

const SideMenu = (props) => (
  <SideMenuBox mobileNotHide={props.mobileNotHide}>
    <style type="text/css">{`
    .btn-SideMenuButton {
       white-space:normal;
       border:none;
       background-color: #fff;
       background-image: none;
       &:hover{
          border-color: #49509d;
          background-color: #49509d;
          background-image: linear-gradient(to bottom, #5f7bc0 0%, #49509d 100%);
       }
    }

    .btn-SideMenuDropdownButton {
      background:none;
      border-color:rgb(221, 221, 221)
    }

    `}</style>
    <Panel>
      <Panel.Heading style={createPanelHeadingStyle(props)}>
        {props.title}
      </Panel.Heading>
      <Panel.Body style={{ padding: 0 }}>
        <StyledButtonGroup vertical>
          {(props.data || []).map((item, index) => {
            if (item.onClick) {
              return (
                <Button
                  disabled={item.disabled}
                  key={index}
                  onClick={item.onClick}
                  className={`btn-default ${item.active ? "active" : ""}`}
                  bsStyle="SideMenuButton"
                >
                  {item.name}
                </Button>
              );
            } else {
              return (
                <StyledLinkContainer
                  exact={item.notExact ? false : true}
                  key={index}
                  to={item.url}
                >
                  <Button
                    disabled={item.disabled}
                    className="btn-default"
                    bsStyle="SideMenuButton"
                  >
                    {item.name}
                  </Button>
                </StyledLinkContainer>
              );
            }
          })}
        </StyledButtonGroup>
      </Panel.Body>
    </Panel>
  </SideMenuBox>
);

export default withTheme(SideMenu);

SideMenu.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
};
