import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Form, Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import Button from "react-bootstrap/lib/Button";

import {
  generateStatisticCard,
  saveStatisticCard,
  UpdateGus,
} from "actions/incidentActions";

import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import { ButtonWrapper } from "components/ListComponents";
import saveBlobFile from "components/saveBlobFile";
import { EmptySeparator } from "./AnalysisForm.style";

import Select from "components/Select";
import DayPicker from "lib/DayPicker";
import { TwoInputWrapper, SpaceBlock, Width33 } from "./AnalysisForm.style";
import validator, { composeValidators } from "components/Validation";
import CodeInput from "components/CodeInput";
import {
  FieldSelectDitionary,
  FieldSelectDitionary33,
  FieldInput,
  FieldInput33,
  FieldInputCheckBox,
  FieldDayPicker,
} from "components/Fields";

import ToogleSlider from "components/ToggleSlider";

import FieldArrayComponent from "./Fields/FieldArray";
import GusCheckboxModal from "./container/gusCheckboxModal";
import AppComponent from "components/AppComponent";
import moment from "moment";
const TimeWrapperStyle = styled.div`
  display: flex;
  margin: 0 -10px 5px;
  & > * {
    margin: 0 10px;
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 20%;
    }
  }
`;
let getCodeOfWorkersCount = (value) => {
  if (value == 1) {
    return 0;
  }

  switch (true) {
    case value == 1: {
      return 0;
    }
    case value >= 2 && value <= 9: {
      return 1;
    }
    case value >= 10 && value <= 49: {
      return 2;
    }
    case value >= 50 && value <= 249: {
      return 3;
    }
    case value >= 250 && value <= 499: {
      return 4;
    }
    case value >= 500: {
      return 5;
    }
    default: {
      return 9;
    }
  }
};

let LeftPadding = styled.div`
  padding-left: 20px;
`;

let LocalStyledInputBox = styled(StyledInputBox)`
  width: 33%;
`;

let FormPartDescription = styled.div`
  text-align: justify;
  margin-bottom: 10px;
`;

let FormPartName = `so_`;
let FormPartOne = `so_po_`;
let FormPartTwo = `so_pt_`;

let statisticCardI = [
  `${FormPartName}employee_name`,
  `${FormPartName}employee_address`,
  `${FormPartName}accident_type_id`,
  `${FormPartName}statistic_number_from`,
  `${FormPartName}workers_count`,
  `${FormPartName}code`,
  `${FormPartOne}outdated_regon`,
  `${FormPartOne}unit_place`,
  `${FormPartOne}pkd`,
  `${FormPartOne}company_ownership_type_id`,
  `${FormPartOne}citizenship_id`,
  `${FormPartOne}employment_status_id`,
  `profession_code_id`,
  `${FormPartOne}hours_at_work_until_accident`,
  `${FormPartOne}victim_count`,
  `${FormPartOne}accident_consequences_id`,
  `${FormPartOne}unable_to_work_days`,
  `${FormPartOne}other_work_lost`,
  `${FormPartOne}estimated_material_losses`,
  `${FormPartOne}geographic_place`,
  `${FormPartOne}accident_occurred_place_id`,
  `${FormPartOne}work_process_id`,
  `${FormPartOne}accident_place_type_id`,
  `${FormPartOne}material_factor_related_to_activity_id`,
  `${FormPartOne}event_of_deviation_normal_state_id`,
  `${FormPartOne}material_factor_related_deviation_normal_state_id`,
  `${FormPartOne}event_causing_injury_id`,
  `${FormPartOne}material_factor_related_event_causing_injury_id`,
  `accident_cause`,
  `${FormPartOne}fullname_person_completing_form`,
  `${FormPartOne}phone_person_completing_form`,
  `${FormPartOne}place_person_completing_form`,
  `${FormPartOne}date_completing_form`,
  `so_po_date_event_hour`,
  `so_po_date_event`,
];

let statisticCardII = [
  ...statisticCardI,
  `${FormPartTwo}part_two_accident_consequences_part_two_id`,
  `${FormPartTwo}unable_to_work_days`,
  `${FormPartTwo}fullname_person_completing_form`,
  `${FormPartTwo}phone_person_completing_form`,
  `${FormPartTwo}place_person_completing_form`,
  `${FormPartTwo}date_completing_form`,
];

export class AccidentStatisticsCard extends AppComponent {
  constructor(props) {
    super(props);

    this.generateStatisticCardI = this.generateStatisticCardI.bind(this);
    this.saveStatisticCardI = this.saveStatisticCardI.bind(this);
    this.updateGus = this.updateGus.bind(this);
  }

  saveStatisticCardI(values, form) {
    let { incident_id, dispatch } = this.props;

    if (true) {
      let data = statisticCardI.reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});

      saveStatisticCard({ incident_id, data })(dispatch).then(
        this.handleServerResponse()
      );
    }
  }

  saveStatisticCardII(values, form) {
    let { incident_id, dispatch } = this.props;

    if (true) {
      let data = statisticCardII.reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});

      saveStatisticCard({ incident_id, data })(dispatch).then(
        this.handleServerResponse()
      );
    }
  }

  updateGus(name) {
    return (value) => {
      let { incident_id, dispatch } = this.props;
      UpdateGus(incident_id, {
        [name]: value,
        [`${name}_date`]: value ? moment().format("YYYY-MM-DD") : "",
      })(dispatch).then(this.handleServerResponse());
    };
  }

  generateStatisticCardI(values, form) {
    let { incident_id, dispatch } = this.props;
    let data = statisticCardI.reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});

    for (let item of statisticCardI) {
      form.mutators.setFieldTouched(item, true);
    }

    let invalid = statisticCardI.find((key) => {
      let field = form.getFieldState(key);
      if (field) {
        return field.invalid;
      } else {
        return false;
      }
    });
    if (!invalid) {
      generateStatisticCard({ incident_id, data })(dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `karta statystyczna.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    }
  }

  generateStatisticCardII(values, form) {
    let { incident_id, dispatch } = this.props;
    let data = statisticCardII.reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});

    for (let item of statisticCardII) {
      form.mutators.setFieldTouched(item, true);
    }

    let invalid = statisticCardII.find((key) => {
      let field = form.getFieldState(key);
      if (field) {
        return field.invalid;
      } else {
        return false;
      }
    });
    if (!invalid) {
      generateStatisticCard({ incident_id, data })(dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `karta statystyczna.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    }
  }

  render() {
    let {
      readOnly,
      dictionary,
      form,
      personData,
      incident: { singleData: incidentData },
    } = this.props;
    return (
      <Fragment>
        <ToogleSlider title="Karta statystyczna wypadku Z-KW" duration={1200}>
          <TwoInputWrapper>
            <FieldInput
              validate={validator.required}
              disabled={true}
              name={`incident_has_person.user.full_name`}
              label="Imie i nazwisko poszkodowanego"
            />
          </TwoInputWrapper>
          <EmptySeparator />
          <h4>Dane pracodawcy</h4>
          <TwoInputWrapper>
            <FieldInput
              validate={validator.required}
              disabled={readOnly}
              name={`${FormPartName}employee_name`}
              label="Nazwa pracodawcy"
            />
            <FieldInput
              validate={validator.required}
              disabled={readOnly}
              name={`${FormPartName}employee_address`}
              label="Adres pracodawcy"
            />
          </TwoInputWrapper>
          <FieldSelectDitionary
            validate={validator.required}
            label="Rodzaj wypadku"
            name={`${FormPartName}accident_type_id`}
            disabled={readOnly}
            dictionary={dictionary.statistic_card_accident_type}
          />
          <StyledLabel>
            Numer statystycznej karty wypadku w zakładzie od początku roku
          </StyledLabel>
          <FieldInput name={`statistic_card_number`} disabled={readOnly} />
          <TwoInputWrapper>
            <div>
              <StyledLabel>
                Liczba pracujących (bez przeliczenia na pełen etat)
              </StyledLabel>
              <FormSpy subscribe={{ values: true, form: true }}>
                {({ values, form }) => {
                  return (
                    <FieldInput
                      name={`${FormPartName}workers_count`}
                      disabled={readOnly}
                      onChange={(value) => {}}
                      onBlur={() => {
                        form.change(
                          `${FormPartName}code`,
                          getCodeOfWorkersCount(
                            values[`${FormPartName}workers_count`]
                          )
                        );
                      }}
                    />
                  );
                }}
              </FormSpy>
            </div>
            <div>
              <StyledLabel>Kod</StyledLabel>
              <FieldInput name={`${FormPartName}code`} disabled={true} />
            </div>
          </TwoInputWrapper>
          <h4>Część I</h4>
          <FormPartDescription>
            Czy jednostka spełnia jedno z podanych kryteriów: 1. nie posiada
            własnego numeru statystycznego REGON, a posługuje się numerem
            statystycznym jednostki macierzystej i co najmniej jedna z cech
            identyfikujących jednostkę (województwo, miasto, gmina, PKD, FW)
            jest różna od cech jednostki macierzystej, 2. posiada własny numer
            statystyczny REGON, lecz od chwili otrzymania tego numeru jednostka
            przeniosła swoją siedzibę do innego województwa (wyróżnik
            terytorialny w numerze REGON jest inny niż wyróżnik terytorialny
            województwa, na terenie którego znajduje się obecnie siedziba
            jednostki), 3. posiada numer statystyczny REGON, w którym nastąpiły
            zmiany w okresie sprawozdawczym.
          </FormPartDescription>
          <FieldSelectDitionary33
            validate={validator.required}
            name={`${FormPartOne}outdated_regon`}
            disabled={readOnly}
            handleChange={(input) => {
              return (value) => {
                input.onChange(value);
                if (value == "false") {
                  form.change(`${FormPartOne}unit_place`, null);
                  form.change(`${FormPartOne}pkd`, null);
                  form.change(`${FormPartOne}company_ownership_type_id`, null);
                }
              };
            }}
            dictionary={[
              {
                id: "false",
                name: "nie",
              },
              {
                id: "true",
                name: "tak",
              },
            ]}
          />
          <LeftPadding>
            <FormSpy subscribe={{ values: true }}>
              {({ values }) => {
                let validate = values[`${FormPartOne}outdated_regon`] == "true";
                let disabled =
                  values[`${FormPartOne}outdated_regon`] == "false";
                return (
                  <Fragment>
                    <FieldInput
                      validate={validate ? validator.required : undefined}
                      disabled={disabled || readOnly}
                      name={`${FormPartOne}unit_place`}
                      label="Miejscowość (gmina), na terenie której znajduje się jednostka"
                    />
                    <FieldInput
                      validate={validate ? validator.required : undefined}
                      disabled={disabled || readOnly}
                      name={`${FormPartOne}pkd`}
                      label="Rodzaj działalności wg PKD"
                    />
                    <FieldSelectDitionary
                      validate={validate ? validator.required : undefined}
                      label={"Forma własności"}
                      name={`${FormPartOne}company_ownership_type_id`}
                      dictionary={dictionary.company_ownership_type}
                      disabled={disabled || readOnly}
                    />
                  </Fragment>
                );
              }}
            </FormSpy>
          </LeftPadding>
          <h4>Dane poszkodowanego</h4>
          <FieldSelectDitionary
            label="Płeć poszkodowanego"
            name="incident_has_person.sex_id"
            disabled={true}
            dictionary={dictionary.sex}
          />
          <StyledInputBox
            label="Rok urodzenia"
            disabled={true}
            value={
              personData.birth_date
                ? moment(personData.birth_date).format("YYYY")
                : ""
            }
          />
          <FieldSelectDitionary
            label={"Obywatelstwo"}
            name={`${FormPartOne}citizenship_id`}
            disabled={readOnly}
            dictionary={dictionary.citizenship}
          />
          <FieldSelectDitionary
            label={"Status zatrudnienia"}
            name={`${FormPartOne}employment_status_id`}
            disabled={readOnly}
            dictionary={dictionary.employment_status}
          />
          <Field
            validate={validator.required}
            name={`profession_code_id`}
            render={(inputProps) => (
              <CodeInput
                label="Zawód wykonywany"
                disabled={readOnly}
                codes={dictionary.profession_code}
                {...inputProps}
              />
            )}
          />
          <FieldInput
            validate={validator.required}
            name="incident_has_person.job_seniority"
            label="Staż na zajmowanym stanowisku w zakładzie (w latach)"
            disabled={true}
          />
          <FieldInput
            validate={validator.required}
            name={`${FormPartOne}hours_at_work_until_accident`}
            label="Godziny przepracowane od podjęcia pracy do chwili wypadku"
            disabled={readOnly}
          />
          <h4>Skutki wypadku</h4>
          <LeftPadding>
            <TwoInputWrapper>
              <FieldSelectDitionary
                validate={validator.required}
                label={"Rodzaj urazu"}
                name={"incident_has_person.injury_id"}
                disabled={true}
                dictionary={dictionary.injury}
              />
              <FieldSelectDitionary
                validate={validator.required}
                label={"Umiejscowienie urazu"}
                name="incident_has_person.localization_injury_id"
                disabled={true}
                dictionary={dictionary.localization_injury}
              />
            </TwoInputWrapper>
            <FieldInput33
              validate={validator.required}
              name={`${FormPartOne}victim_count`}
              label="Liczba osób poszkodowanych"
              disabled={readOnly}
            />

            <FieldSelectDitionary33
              validate={validator.required}
              label={"Skutki wypadku"}
              name={`${FormPartOne}accident_consequences_id`}
              disabled={readOnly}
              dictionary={dictionary.accident_consequences}
            />
            <FieldInput33
              validate={validator.required}
              name={`${FormPartOne}unable_to_work_days`}
              label="Liczba dni niezdolności do pracy"
              disabled={readOnly}
            />
            <FieldInput33
              validate={validator.required}
              name={`${FormPartOne}other_work_lost`}
              label="Straty czasu pracy innych osób (w roboczogodzinach)"
              disabled={readOnly}
            />
            <FieldInput33
              validate={validator.required}
              name={`${FormPartOne}estimated_material_losses`}
              label="Szacunkowe straty materialne spowodowane wypadkiem – w tys. zł, z jednym znakiem po przecinku (bez strat związanych ze stratami czasu pracy)"
              disabled={readOnly}
            />
          </LeftPadding>
          <h4>Wypadek i jego przebieg</h4>
          <LeftPadding>
            <FieldInput
              validate={validator.required}
              name={`${FormPartOne}geographic_place`}
              label="Położenie geograficzne miejsca wypadku"
              disabled={readOnly}
            />
            <TimeWrapperStyle>
              <div>
                <StyledLabel>Data wypadku</StyledLabel>
                <Field
                  name="so_po_date_event"
                  render={({ input, meta, ...rest }) => (
                    <DayPicker
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                      fullWidth
                      disabled={readOnly}
                      selectedDay={input.value}
                      handleChange={(value) => {
                        input.onChange(value);
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <StyledLabel>Godzina wypadku (0-23)</StyledLabel>
                <Field
                  name="so_po_date_event_hour"
                  validate={composeValidators(
                    validator.required,
                    validator.timeHH
                  )}
                  render={({ input, meta, ...rest }) => (
                    <StyledInputBox
                      {...input}
                      placeholder={"HH"}
                      disabled={readOnly}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                      onChange={(event) => {
                        input.onChange(event.target.value);
                      }}
                    />
                  )}
                />
              </div>
            </TimeWrapperStyle>
            <FieldSelectDitionary33
              validate={validator.required}
              label={"Miejsce powstania wypadku"}
              name={`${FormPartOne}accident_occurred_place_id`}
              disabled={readOnly}
              dictionary={dictionary.accident_occurred_place}
            />
            <FieldSelectDitionary33
              validate={validator.required}
              label={"Proces pracy"}
              name={`${FormPartOne}work_process_id`}
              disabled={readOnly}
              dictionary={dictionary.work_process}
            />
            <FieldSelectDitionary33
              validate={validator.required}
              label={"Rodzaj miejsca wypadku"}
              name={`${FormPartOne}accident_place_type_id`}
              disabled={readOnly}
              dictionary={dictionary.accident_place_type}
            />
            <FieldSelectDitionary33
              validate={validator.required}
              label={"Czynność wykonywana w chwili wypadku"}
              name={`incident_has_person.activity_id`}
              disabled={true}
              dictionary={dictionary.activity}
            />
            <FieldSelectDitionary33
              validate={validator.required}
              label={
                "Czynnik materialny związany z czynnością wykonywaną przez poszkodowanego w chwili wypadku"
              }
              name={`${FormPartOne}material_factor_related_to_activity_id`}
              disabled={readOnly}
              dictionary={dictionary.material_factor_related_to_activity}
            />
            <FieldSelectDitionary
              validate={validator.required}
              label={"Wydarzenie będące odchyleniem od stanu normalnego"}
              name={`${FormPartOne}event_of_deviation_normal_state_id`}
              disabled={readOnly}
              dictionary={dictionary.event_of_deviation_normal_state}
            />
            <FieldSelectDitionary33
              validate={validator.required}
              label={"Czynnik materialny związany z odchyleniem"}
              name={`${FormPartOne}material_factor_related_deviation_normal_state_id`}
              disabled={readOnly}
              dictionary={dictionary.material_factor_related_to_activity}
            />
            <FieldSelectDitionary
              validate={validator.required}
              label={"Wydarzenie powodujące uraz"}
              name={`${FormPartOne}event_causing_injury_id`}
              disabled={readOnly}
              dictionary={dictionary.event_causing_injury}
            />
            <FieldSelectDitionary
              validate={validator.required}
              label={"Czynnik materialny będący źródłem urazu"}
              name={`${FormPartOne}material_factor_related_event_causing_injury_id`}
              disabled={readOnly}
              dictionary={dictionary.material_factor_related_to_activity}
            />

            <FieldArrayComponent
              validate={validator.accident_cause}
              defaultValue={{}}
              name={`accident_cause`}
              label={"Przyczyny wypadku"}
              readOnly={readOnly}
              atLeastOne
              render={({ name }) => {
                return (
                  <Fragment>
                    <FieldSelectDitionary
                      name={`${name}.dictionary_accident_cause_id`}
                      disabled={readOnly}
                      dictionary={dictionary.accident_cause}
                    />
                  </Fragment>
                );
              }}
            />

            <TwoInputWrapper>
              <FieldInput
                validate={validator.required}
                name={`${FormPartOne}fullname_person_completing_form`}
                label="Imie i nazwisko osoby wypełniającej"
                disabled={readOnly}
              />
              <FieldInput
                validate={validator.required}
                name={`${FormPartOne}phone_person_completing_form`}
                label="Numer telefonu osoby wypełniającej"
                disabled={readOnly}
              />
            </TwoInputWrapper>
            <TwoInputWrapper>
              <FieldInput
                validate={validator.required}
                name={`${FormPartOne}place_person_completing_form`}
                label="Miejscowość"
                disabled={readOnly}
              />

              <FieldDayPicker
                validate={validator.required}
                name={`${FormPartOne}date_completing_form`}
                label={"Data"}
                disabled={readOnly}
              />
            </TwoInputWrapper>
            <ButtonWrapper>
              <FormSpy subscribe={{ values: true }}>
                {({ values }) => {
                  return (
                    <Fragment>
                      <Button
                        onClick={() => {
                          this.saveStatisticCardI(values, form);
                        }}
                        disabled={readOnly}
                      >
                        Zapisz zmiany
                      </Button>
                      <Button
                        onClick={() => {
                          this.generateStatisticCardI(values, form);
                        }}
                        disabled={readOnly}
                      >
                        Generuj kartę statystyczną
                      </Button>
                    </Fragment>
                  );
                }}
              </FormSpy>
            </ButtonWrapper>
            <GusCheckboxModal
              disabled={readOnly}
              label={"Część I karty Z-KW wysłana do GUS"}
              accept={this.updateGus(`${FormPartOne}send_to_gus`)}
              value={incidentData[`${FormPartOne}send_to_gus`]}
            />
          </LeftPadding>
          <h4>Część II uzupełniająca</h4>
          <LeftPadding>
            <FieldSelectDitionary
              label={
                "Skutki wypadku (znane w momencie wypełniania części II karty)"
              }
              name={`${FormPartTwo}part_two_accident_consequences_part_two_id`}
              disabled={readOnly}
              dictionary={dictionary.accident_consequences}
            />
            <FieldInput33
              name={`${FormPartTwo}unable_to_work_days`}
              label="Liczba dni niezdolności do pracy"
              disabled={readOnly}
            />
            <TwoInputWrapper>
              <FieldInput
                name={`${FormPartTwo}fullname_person_completing_form`}
                label="Imie i nazwisko osoby wypełniającej"
                disabled={readOnly}
              />
              <FieldInput
                name={`${FormPartTwo}phone_person_completing_form`}
                label="Numer telefonu osoby wypełniającej"
                disabled={readOnly}
              />
            </TwoInputWrapper>
            <TwoInputWrapper>
              <FieldInput
                name={`${FormPartTwo}place_person_completing_form`}
                label="Miejscowość"
                disabled={readOnly}
              />

              <Field
                name={`${FormPartTwo}date_completing_form`}
                render={({ input, meta, ...rest }) => (
                  <DayPicker
                    canChangeYear={true}
                    label={"Data"}
                    onFocus={input.onFocus}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    fullWidth
                    disabled={readOnly}
                    selectedDay={input.value}
                    handleChange={(value) => {
                      input.onChange(value);
                    }}
                  />
                )}
              />
            </TwoInputWrapper>
            <ButtonWrapper>
              <FormSpy subscribe={{ values: true }}>
                {({ values }) => {
                  return (
                    <Fragment>
                      <Button
                        onClick={() => {
                          this.saveStatisticCardII(values, form);
                        }}
                        disabled={readOnly}
                      >
                        Zapisz zmiany
                      </Button>
                      <Button
                        onClick={() => {
                          this.generateStatisticCardII(values, form);
                        }}
                        disabled={readOnly}
                      >
                        Generuj kartę statystyczną
                      </Button>
                    </Fragment>
                  );
                }}
              </FormSpy>
            </ButtonWrapper>

            <GusCheckboxModal
              disabled={readOnly}
              label={"Część II karty Z-KW wysłana do GUS"}
              accept={this.updateGus(`${FormPartTwo}send_to_gus`)}
              value={incidentData[`${FormPartTwo}send_to_gus`]}
            />
          </LeftPadding>
        </ToogleSlider>
      </Fragment>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
}))(AccidentStatisticsCard);
