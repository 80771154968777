import React, { PureComponent, useEffect, useState } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import styled from "styled-components";

import { LeftPadding } from "components/Wrappers";
import { FieldInput, FieldSelect } from "components/Fields";
import { count } from "components/RiskAssessment/countMaxForFormula";
export default function RegisterMethodCalc(props) {
  let [variables, setVariables] = useState({});
  let {
    name,
    parameters,
    method,
    mathematical_formula,
    risk_level_description,
  } = props;

  let methodM = method.numberMethod ? 1 : 2;
  let updateVariable1 = (data) => {
    let { variableName, value } = data;
    let newVariableName = { ...variables };
    newVariableName[variableName] = value;

    setVariables(newVariableName);
  };

  let updateVariable2 = (data, index) => {
    let { parameter_id, variableName, value_id, value } = data;
    let newVariableName = { ...variables };
    newVariableName[index] = value_id;
    setVariables(newVariableName);
  };

  let updateVariable = (() => {
    switch (methodM) {
      case 1: {
        return updateVariable1;
      }
      case 2: {
        return updateVariable2;
      }
      default: {
        return () => {};
      }
    }
  })();
  return (
    <LeftPadding>
      {parameters.map((i, index) => (
        <CorrectionLine
          index={index}
          parameter={i}
          name={name}
          method={method}
          title="Skutki (nazwa parametru)"
          updateVariable={updateVariable}
        />
      ))}
      <ResultCalculationSiteProvider
        parameters={parameters}
        method={method}
        name={name}
        mathematical_formula={mathematical_formula}
        variableValue={variables}
        risk_level_description={risk_level_description}
        setVariables={setVariables}
      />
    </LeftPadding>
  );
}

let CorrectionLineWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
    &:nth-child(1) {
      flex: 1 1 20%;
    }
    &:nth-child(2) {
      flex: 1 1 80%;
    }
  }
`;

function CorrectionLine(props) {
  let { name, parameter = {}, method, updateVariable, index } = props;
  let { name: title, variables = [], id } = parameter;
  let [dictionaryVariables, setDictionaryVariables] = useState({});

  useEffect(() => {
    if (parameter && parameter.variables) {
      let dictionaryVariables = {};
      for (let variable of parameter.variables) {
        dictionaryVariables[variable.id] = parseInt(variable.number_value);
      }

      setDictionaryVariables(dictionaryVariables);
    }
  }, [parameter]);

  return (
    <>
      <div>{title}</div>
      <LeftPadding>
        <CorrectionLineWrapper>
          <FormSpy subscribe={{ form: true, values: true }}>
            {({ form, values }) => {
              return (
                <FieldSelect
                  name={`${name}_${parameter.id}.variable_id`}
                  label={"Wartość"}
                  data={variables.map((i) => {
                    let { name, id } = i;
                    return { name, id };
                  })}
                  handleChange={(value, input) => {
                    const targetDescription = variables.find((i) => {
                      return i.id == value;
                    });
                    const oldDescription =
                      values[`${name}_${parameter.id}`] &&
                      values[`${name}_${parameter.id}`].description;
                    if (
                      targetDescription &&
                      targetDescription.description
                      // !oldDescription
                    ) {
                      form.change(
                        `${name}_${parameter.id}.description`,
                        targetDescription.description
                      );
                    }
                    input.onChange(value);
                  }}
                />
              );
            }}
          </FormSpy>
          <FieldInput
            name={`${name}_${parameter.id}.description`}
            label="Opis"
          />
          <FormSpy subscribe={{ form: true, values: true }}>
            {({ values, form }) => {
              return (
                <>
                  <OnChange name={`${name}_${parameter.id}.variable_id`}>
                    {(value) => {
                      let variableData = variables.find((v) => {
                        return v.id == value;
                      });
                      if (variableData) {
                        let keys = Object.keys(values);
                        let targetParameterKeys = keys.filter((k) => {
                          let regexp = new RegExp(`^${name}`);
                          return regexp.test(k);
                        });

                        let data = {
                          parameter_id: parameter.id,
                          variableName: parameter.user_variable_name,
                          value_id: value,
                          value: variableData.number_value,
                        };

                        updateVariable(data, index);
                      }
                    }}
                  </OnChange>
                </>
              );
            }}
          </FormSpy>
        </CorrectionLineWrapper>
      </LeftPadding>
    </>
  );
}

function ResultCalculationSite(props) {
                                        let {
                                          name,
                                          mathematical_formula = "",
                                          variableValue = {},
                                          risk_level_description = [],
                                          form,
                                          values,
                                          method,
                                          parameters,
                                          setVariables,
                                        } = props;

                                        let [color, setColor] = useState(
                                          "inherit"
                                        );

                                        let nameValue = `${name}_result_value`;
                                        let nameDescription = `${name}_result_description`;
                                        let nameColor = `${name}_result_color`;
                                        let methodM = method.numberMethod
                                          ? 1
                                          : 2;

                                        //initializacja wartości z domyślnych parametrów przy edycji
                                        useEffect(() => {
                                          let reg = new RegExp(
                                            `^${name}_[0-9]+`
                                          );
                                          let formValues = Object.keys(values)
                                            .filter((k) => {
                                              return reg.test(k);
                                            })
                                            .map((k) => {
                                              return {
                                                ...values[k],
                                                parameter_id: parseInt(
                                                  k.split("_")[1]
                                                ),
                                              };
                                            })
                                            .reduce((obj, item) => {
                                              let parameter = parameters.find(
                                                (p) => {
                                                  return (
                                                    p.id == item.parameter_id
                                                  );
                                                }
                                              );
                                              if (parameter) {
                                                let variables =
                                                  parameter.variables;
                                                let variableData = variables.find(
                                                  (v) => {
                                                    return (
                                                      v.id == item.variable_id
                                                    );
                                                  }
                                                );

                                                if (variableData)
                                                  obj[
                                                    parameter.user_variable_name
                                                  ] = variableData.number_value;
                                              }

                                              return obj;
                                            }, {});

                                          setVariables(formValues);
                                        }, [parameters]);

                                        //obliczenie wartości ryzyka
                                        useEffect(() => {
                                          if (methodM == 1) {
                                            let result = count({
                                              mathematical_formula,
                                              variableValue,
                                            });
                                            let rresult = risk_level_description.find(
                                              (i) => {
                                                return (
                                                  i.from_value <= result &&
                                                  i.to_value >= result
                                                );
                                              }
                                            );
                                            form.change(nameValue, result);
                                            if (rresult)
                                              if (rresult) {
                                                setColor(rresult.color);
                                                form.change(
                                                  nameColor,
                                                  rresult.color
                                                );
                                                form.change(
                                                  nameDescription,
                                                  rresult.description
                                                );
                                              }
                                          }
                                          if (methodM == 2) {
                                            let dataFromForm = parameters.map(
                                              (p) => {
                                                return values[`${name}_${p.id}`]
                                                  .variable_id;
                                              }
                                            );
                                            let data =
                                              method.matrixMethod &&
                                              method.matrixMethod.find((i) => {
                                                return (
                                                  i.first_variable_id ==
                                                    dataFromForm[0] &&
                                                  i.second_variable_id ==
                                                    dataFromForm[1]
                                                );
                                              });

                                            if (data) {
                                              form.change(
                                                nameColor,
                                                data.color
                                              );
                                              form.change(
                                                nameDescription,
                                                data.description
                                              );
                                              setColor(data.color);
                                            }
                                          }
                                        }, [JSON.stringify(variableValue)]);

                                        return (
                                          <>
                                            <div>Ryzyko</div>
                                            <LeftPadding>
                                              <CorrectionLineWrapper>
                                                {methodM == 1 ? (
                                                  <FieldInput
                                                    backgroundColor={color}
                                                    disabled
                                                    name={nameValue}
                                                    label={"Wartość"}
                                                  />
                                                ) : (
                                                  <>
                                                    <div></div>
                                                  </>
                                                )}
                                                <FieldInput
                                                  backgroundColor={color}
                                                  disabled
                                                  label="Opis"
                                                  name={nameDescription}
                                                />
                                              </CorrectionLineWrapper>
                                            </LeftPadding>
                                          </>
                                        );
                                      }

function ResultCalculationSiteProvider(props) {
  return (
    <FormSpy subscribe={{ form: true, values: true }}>
      {({ form, values }) => {
        return <ResultCalculationSite {...props} form={form} values={values} />;
      }}
    </FormSpy>
  );
}
