import React from "react";

import { connect } from "react-redux";

import {
  addDraftRAControlList,
  getDraftControlListList,
  getDraftRAControlList,
  deleteDraftRAControlList,
  getDraftRAControlListQuestions,
  saveDraftRAControlListQuestions,
  addSARACLQuestionAttachment,
  deleteSARACLQuestionAttachment,
  downloadRAQuestionAttachment,
  download,
} from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";

import Danger from "components/Danger";

import saveBlobFile from "components/saveBlobFile";
import SideMenu from "./_SideMenu";

import { DangerList } from "container/RiskAssessment/EntryAddEdit/Danger/index";
import { setUrls } from "./_staticData";

let urls = {
  CHARACTERISTIC: (id) =>
    `/super-admin/draft/risk-assessment/characteristic/${id}`,
};

class DangerListDraft extends DangerList {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls() };
  }

  renderSideMenu() {
    const { match, permission, data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }
}

export default connect((store) => ({
  ra: store.riskAssessment.singleData,
  answers: store.riskAssessment.answers,
  controlList: store.riskAssessment.controlList,
  data: store.riskAssessment.raControlList,
  permission: store.riskAssessment.permission,
  questions: store.riskAssessment.questions,
}))(DangerListDraft);
