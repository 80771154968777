import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Form, Field } from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import DayPicker from "lib/DayPicker";
import styled from "styled-components";
import {
  addDraft,
  deleteDraft,
  getAccidentList,
  getAccidentListByFilter,
} from "actions/accidentOWTWActions";

import AppComponent from "components/AppComponent";
import {
  ButtonWithIcon,
  ButtonWrapper,
  StyledTableWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";

import { LayoutContent } from "components/Layout";
import PageHeader from "components/PageHeader";
import { TableSortHeader } from "components/TableSortable";
import Select from "components/Select";

import SideMenu from "./_SideMenu";

import { StyledInput } from "style/styleComponents/Form/input";
import PrintModal from "./List/PrintModal";

import Modal from "components/Modal/SimpleAccept";

export const ProgressTd = styled.td`
  background: ${(props) => props.color || "inherit"};
`;
const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "accident_number", () => null);
  changeValue(state, "date_event", () => null);
  changeValue(state, "victim_fullname", () => null);
  changeValue(state, "stage_name", () => null);
};

const getUrl = (id, stage) => {
  switch (stage) {
    case 3:
      return `/accident-owtw/notification/${id}`;
    case 2:
      return `/accident-owtw/attachment/${id}`;
    case 1:
      return `/accident-owtw/analysis/${id}`;
    case 0:
      return `/accident-owtw/register/${id}`;
    default:
      return `/accident-owtw/register/${id}`;
  }
};

class IncidendOnRoadList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalDelete: {
        entry_id: null,
      },
    };

    this.addDraft = this.addDraft.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
    this.getFiltredData = this.getFiltredData.bind(this);
  }

  addDraft() {
    let { dispatch, history } = this.props;
    addDraft()(dispatch).then(
      this.handleServerResponse({}, (action) => {
        const { accident } = action.payload.data;
        history.push(`/accident-owtw/register/${accident}`);
      })
    );
  }

  deleteDraft() {
    const { dispatch } = this.props;
    const { entry_id } = this.state.modalDelete;
    deleteDraft(entry_id)(dispatch).then(this.handleServerResponse());
  }

  componentDidMount() {
    let { dispatch, history } = this.props;
    this.updateMessage();
    getAccidentList()(dispatch);
  }

  getFiltredData(data) {
    const { dispatch } = this.props;
    getAccidentListByFilter(data)(dispatch).then(this.handleServerResponse());
  }

  render() {
    const { message, modalDelete } = this.state;
    const {
      history,
      accident: { data: accidents, permission },
    } = this.props;
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={<SideMenu addNewIncident={this.addDraft} />}
      >
        <PageHeader>Rejestr wypadków w drodze</PageHeader>
        <ButtonWrapper>
          <ButtonWithIcon
            onClick={() => {
              this.addDraft();
            }}
            disabled={!permission[9]}
            icon="fa fa-plus"
            name="Zgłoś nowy wypadek"
          />
        </ButtonWrapper>
        <Form
          ref={(form) => (this.form = form)}
          mutators={{ clearFilterMutator }}
          onSubmit={this.getFiltredData}
          render={({ handleSubmit, pristine, invalid, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <StyledTable cellspacing={0}>
                  <tr>
                    <td>
                      <Field
                        name="accident_number"
                        render={({ input, meta, ...rest }) => (
                          <StyledInput
                            {...input}
                            value={input.value}
                            placeholder={"wg Nr wypadku"}
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Field
                        name="date_event"
                        render={({ input, meta, ...rest }) => (
                          <DayPicker
                            selectedDay={input.value}
                            fullWidth
                            placeholder={"wg Daty"}
                            handleChange={(value) => {
                              input.onChange(
                                moment(value).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Field
                        name="victim_fullname"
                        render={({ input, meta, ...rest }) => (
                          <StyledInput
                            {...input}
                            value={input.value}
                            placeholder={"wg Imienia i nazwiska poszkodowanego"}
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      <Field
                        name="progress_id"
                        render={({ input, meta, ...rest }) => (
                          <Select
                            title="Postęp"
                            fullWidth
                            pullRight
                            propValue={"id"}
                            selected={input.value}
                            data={[
                              { name: "Wszystkie", id: null },
                              { name: "Otwarte", id: 1 },
                              { name: "Zakończone", id: 2 },
                            ]}
                            handleChange={input.onChange}
                          />
                        )}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="5" style={{ padding: "8px 40px 8px 8px" }}>
                      <ButtonWrapper>
                        <Button type="submit" bsStyle="primary">
                          Szukaj
                        </Button>
                        <Button
                          onClick={() => {
                            form.reset();
                            form.submit();
                          }}
                        >
                          Wyłącz filtr
                        </Button>
                      </ButtonWrapper>
                    </td>
                  </tr>
                  <TableSortHeader
                    headers={[
                      {
                        name: "Numer wypadku",
                        prop: "accident_number",
                        sort_type: "string",
                      },
                      { name: "Data wypadku", prop: "date_event" },
                      {
                        name: "Imie i nazwisko poszkowowanego",
                        prop: "victim_fullname",
                        sort_type: "string",
                      },
                      { name: "Postęp", prop: "progress_id" },
                      { name: "Usuń" },
                      { name: "Drukuj" },
                    ]}
                    data={accidents}
                    render={(sortedData) => {
                      return sortedData.map((item) => {
                        return (
                          <tr>
                            <td>
                              <Link to={getUrl(item.id, item.stage)}>
                                {item.dictionary_progress_id == 4
                                  ? "szkic"
                                  : item.accident_number}
                              </Link>
                            </td>
                            <td>{item.date_event}</td>
                            <td>{item.victim_fullname}</td>
                            <ProgressTd color={item.dictionary_progress_color}>
                              {item.dictionary_progress_name}
                            </ProgressTd>
                            <td>
                              <ButtonWithIcon
                                disabled={!permission[4]}
                                onClick={this.openModal("modalDelete", {
                                  entry_id: item.id,
                                })}
                                icon="fa fa-trash"
                              />
                            </td>
                            <td>
                              <PrintModal
                                data={item}
                                permission={permission}
                                dispatch={this.props.dispatch}
                              />
                            </td>
                          </tr>
                        );
                      });
                    }}
                  />
                </StyledTable>
              </form>
            );
          }}
        />
        <Modal
          title={"Usuń szkic wypadku"}
          description="Czy chcesz usunąć dany wpis?"
          open={modalDelete.open}
          handleAccept={this.deleteDraft}
          handleClose={this.closeModal("modalDelete", {
            // entry_id: null,
          })}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  accident: store.accident_owtw,
  message: store.message,
}))(IncidendOnRoadList);
