import React from "react";
import styled from "styled-components";
import { media } from "../../style/style-utils";
import Table from "react-bootstrap/lib/Table";

const pBoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
pBoxesWrapper.displayName = "BoxesWrapper";
export let BoxesWrapper = pBoxesWrapper;
// export let StyledTable = Table;
export const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;
