import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { getLocalizations } from "actions/localizationActions";
import { getWorkplacesWithout } from "actions/workplaceActions";
import {
  addUser,
  getUserPermissions,
  getHiredStatuses,
} from "actions/userActions";

import AppComponent from "components/AppComponent";
import UserAccountsForm from "components/UsersAccounts.Form/index";
import { getSections } from "../../actions/sectionActions";

class UserAccountsAdd extends AppComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getUserPermissions()(dispatch).then(this.handleServerResponse());
    getWorkplacesWithout(null)(dispatch).then(this.handleServerResponse());
    getLocalizations()(dispatch).then(this.handleServerResponse());
    getHiredStatuses()(dispatch).then(this.handleServerResponse());
  }

  handleSubmit(data) {
    const { dispatch, history } = this.props;

    addUser(data)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/users-accounts");
      })
    );
  }

  render() {
    const {
      users: { data: userParent, singleData = {} },
      localization: { data: localizationData = [] },
      workplace: { data: workplaceData = [] },
      modules: { data: modules },
    } = this.props;
    const { message } = this.state;
    const { permissions } = singleData;
    return (
      <UserAccountsForm
        admin
        businessView
        boxTitle="Dodaj użytkownika"
        create
        createView
        handleSubmit={this.handleSubmit}
        localizations={localizationData}
        message={message}
        modules={modules}
        parent_workplaces={workplaceData}
        permissions={permissions}
        workplaces={workplaceData}
        getSections={getSections}
      />
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  users: store.users,
  workplace: store.workplace,
  message: store.message,
  modules: store.modules,
}))(UserAccountsAdd);
