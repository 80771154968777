import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";
import { ButtonWrapper } from "components/Wrappers";
import ModalListWithFilter from "components/Modal/ListWithFilter";
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TdClickable = styled.td`
  cursor: pointer;
`;

class GetDraftModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  selectItem(id) {
    let that = this;
    return () => {
      let { onSelect = () => {} } = this.props;
      onSelect(id);
      that.closeModal();
    };
  }

  render() {
    let { open, data = [], readOnly } = this.props;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Dodaj listę kontrolną</span>
                <Button
                  disabled={readOnly}
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit }) => {
                return (
                  <form>
                    <FieldInput name="filter" placeholder="Szukaj..." />
                    <TableListPage>
                      <thead>
                        <th>Nazwa</th>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr>
                            <TdClickable onClick={this.selectItem(item.id)}>
                              {item.name}
                            </TdClickable>
                          </tr>
                        ))}
                      </tbody>
                    </TableListPage>
                    <ButtonWrapper>
                      <Button onClick={this.closeModal}>Wybierz</Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class GetDraft extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
  }

  render() {
    let { open } = this.state;
    let { data, readOnly, onSelect } = this.props;
    return (
      <>
        <ModalListWithFilter
          disabled={readOnly}
          data={data}
          buttonName="Dodaj listę kontrolną"
          modalTitle="Dodaj listę kontrolną"
          onSelect={(item) => {
            onSelect(item.id);
          }}
        />
      </>
    );
  }
}
