import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";

import { saveRAAsDraft } from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldInput, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";

import validator, { composeValidators } from "components/Validation";
class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  openModal() {
    this.setState({ open: true });
  }

  submit(data) {
    let { id, submit, dispatch, saveAsDraft } = this.props;

    saveAsDraft(id, data, () => {
      // this.closeModal();
    });
  }

  render() {
    let { id } = this.props;

    return (
      <>
        <ButtonWithIcon
          onClick={this.openModal}
          icon="fa fa-file-text-o"
        ></ButtonWithIcon>

        <FormModal
          open={this.state.open}
          title="Zapisz jako szablon"
          acceptButtonName="Zapisz"
          cancelButtonName="Anuluj"
          handleSubmit={this.submit}
          handleClose={this.closeModal}
        >
          <TwoInputWrapper>
            <FieldInput
              validate={validator.required}
              name="name"
              label="Nazwa szablonu"
            />
          </TwoInputWrapper>
        </FormModal>
      </>
    );
  }
}

export default connect((store) => ({}))(Printmodal);
