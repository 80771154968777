import React, { Fragment } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import { Field } from "react-final-form";
import { StyledTable } from "../../components/ListComponents";
import { StyledInputBox } from "../../style/styleComponents/Form/input";
import ModalSchema from "../Modal/_Schema";
import CodeInput from "components/CodeInput";
const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
`;
const ModalTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

let InputWrapper = styled.div`
  display: flex;
  margin 10px -5px 10px;
  & > * {
    display: block;
    margin: 0 5px;
    &:nth-child(1){
      width: 50px;
      flex: 0 0 50px;
      text-align: center;
    }
    &:nth-child(2){
      flex: 1 1 100%;
    }
     &:nth-child(3){
      flex: 0 0 auto;
    } 
   
  }
 
`;

export default class ProfessionCodeInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    let { values, codes, label } = this.props;
    let chosesItem =
      codes.find((item) => {
        return item.id == values.profession_code_id;
      }) || {};
    let { code, description } = chosesItem;
    return (
      <Fragment>
        <Field
          name="profession_code_id"
          render={(inputProps) => (
            <CodeInput
              label={label}
              values={values}
              codes={codes}
              field={{ name: "profession_code_id" }}
              {...inputProps}
            />
          )}
        />
      </Fragment>
    );
  }
}
