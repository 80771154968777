import React from "react";
import { Provider } from "react-redux";
import "normalize.css";
import { store } from "./store.js";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./style/Theme/";
import { CookiesProvider } from "react-cookie";

import Sandbox from "./container/Sandbox";
import NotFound from "./container/NotFound";

import PrivateApp from "./apps/PrivateApp/";
import PublicApp from "./apps/PublicApp";
import BusinessApp from "./apps/BusinessApp";
import SuperAdminApp from "./apps/SuperAdminApp";

class MainApp extends React.Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <CookiesProvider>
            <ThemeProvider theme={defaultTheme}>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={PublicApp} />
                  <Route exact path="/login" component={PublicApp} />
                  <Route exact path="/business/login" component={PublicApp} />
                  <Route exact path="/reset-password" component={PublicApp} />
                  <Route
                    exact
                    path="/reset-password/:token"
                    component={PublicApp}
                  />
                  <Route exact path="/business/login" component={PublicApp} />
                  <Route
                    exact
                    path="/business/reset-password"
                    component={PublicApp}
                  />
                  <Route
                    exact
                    path="/business/reset-password/:token"
                    component={PublicApp}
                  />
                  <Route exact path="/contact" component={PublicApp} />
                  <Route exact path="/helpdesk" component={PublicApp} />
                  <Route exact path="/tutorial" component={PublicApp} />
                  <Route
                    exact
                    path="/super-admin/login"
                    component={PublicApp}
                  />
                  <Route
                    exact
                    path="/super-admin/reset-password"
                    component={PublicApp}
                  />
                  <Route
                    exact
                    path="/super-admin/reset-password/:token"
                    component={PublicApp}
                  />
                  <Route
                    exact
                    path="/super-admin/businesses"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/businesses/add"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/businesses/edit/:id"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/dashboard"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/clients-accounts"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/clients-accounts/add"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/clients-accounts/edit/:id"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/mails"
                    component={SuperAdminApp}
                  />
                  <Route
                    exact
                    path="/super-admin/reset-account/:token"
                    component={PublicApp}
                  />
                  <Route
                    exact
                    path="/dashboard-template"
                    component={PrivateApp}
                  />
                  <Route exact path="/sandbox" component={Sandbox} />
                  <Route exact path="/business*" component={BusinessApp} />
                  <Route exact path="/super-admin*" component={SuperAdminApp} />
                  <Route exact path="/*" component={PrivateApp} />
                  <Route component={NotFound} />
                </Switch>
              </BrowserRouter>
            </ThemeProvider>
          </CookiesProvider>
        </Provider>
      </>
    );
  }
}

export default MainApp;
