import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "react-bootstrap/lib/Button";
import { Form } from "react-final-form";
import moment from "moment";

import {
  getQEList,
  getQETypeList,
  addQE,
  getQEListFilter,
  printQEReport,
} from "actions/QEActions";
import {
  getUsersLocalizations,
  getUsersLocalizationsAndDivisions,
} from "actions/localizationActions";
import { getUserSectionsIndependently } from "actions/sectionActions";
import {
  getUserWorkplacesWithout,
  getWorkplacesTreeByLocalization,
} from "actions/workplaceActions";
import { getHiredStatuses } from "actions/userActions";

import AppComponent from "components/AppComponent";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import { ButtonWithIcon } from "components/ListComponents";
import { LayoutContent } from "components/Layout";
import Pagination from "components/Pagination";
import PageHeader from "components/PageHeader";
import { TableSortHeader } from "components/TableSortable";
import TableSortableWithPagination from "components/TableSortable/Register";
import { StyledTableWrapper } from "components/ListComponents";
import { ButtonWrapper } from "components/Wrappers";
import { StyledTableListWithSearch as StyledTable } from "components/ListComponents";
import { getLocalizationDivisionName } from "../../util/localization";

import SideMenu from "./_SideMenu";

import GenerateRaport from "./List/GenerateRaport";
import QEWorker from "./List/QEWorker";
import UserModal from "./List/UserModal";
import ModalAddUser from "container/UsersAccounts/Modal";
import saveBlobFile from "components/saveBlobFile";

import AddQE from "components/QE/AddQE";
const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "last_name", () => null);
  changeValue(state, "first_name", () => null);
  changeValue(state, "localization_id", () => "0");
  changeValue(state, "section_name", () => "0");
  changeValue(state, "workplace_id", () => "0");
  changeValue(state, "qe_type_id", () => "0");
  changeValue(state, "date_get", () => null);
  changeValue(state, "date_deadline", () => null);
  changeValue(state, "hired_status_id", () => "0");
  changeValue(state, "is_qe_actual", () => "0");
};

let AStyled = styled.a`
  cursor: pointer;
`;

let getDate = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") : null;
};

export const ProgressTdStyle = styled.td`
  background: ${(props) => props.color || "inherit"};
`;

function ProgressTd(props) {
  let { days_left, is_qe_actual } = props.item;
  let greenColor = "rgb(178, 255, 145)";
  if (days_left === null || isNaN(days_left)) {
    if (is_qe_actual) {
      return (
        <ProgressTdStyle color={greenColor}>{props.children}</ProgressTdStyle>
      );
    }

    return <td>{props.children}</td>;
  }

  let color = (() => {
    switch (true) {
      case days_left >= 45: {
        return greenColor;
      }
      case days_left < 45 && days_left >= 0: {
        return "yellow";
      }
      case days_left < 0: {
        return "red";
      }
    }
  })();

  return <ProgressTdStyle color={color}>{props.children}</ProgressTdStyle>;
}

class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      hired_statuses: [
        {
          id: "0",
          name: "Status zatrudnienia",
          disabled: true,
        },
        {
          id: 1,
          name: "Niezatrudniony",
        },
        {
          id: 2,
          name: "Zatrudniony",
        },
      ],
      modalUserDetails: {
        open: false,
        data: {},
      },
      modalAddQE: {
        open: false,
      },
      modalUser: {
        open: false,
      },
      modalAddUser: {
        open: false,
        user: {},
      },
    };

    this.addQE = this.addQE.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.getQEActual = this.getQEActual.bind(this);
    this.getQEList = this.getQEList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.print = this.print.bind(this);
    this.saveUserCallback = this.saveUserCallback.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;
    this.getQEList();
    getQETypeList({ readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
    getUsersLocalizationsAndDivisions()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    // getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());
    getWorkplacesTreeByLocalization()(dispatch).then(
      this.handleServerResponse()
    );
  }

  getQEList(cb) {
    let { dispatch } = this.props;
    getQEList()(dispatch).then(this.handleServerResponse({}, cb));
  }

  addQE(data) {
    let { dispatch } = this.props;
    addQE(data)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        this.getQEList(() => {});
        // getQEList()(dispatch).then(this.handleServerResponse());
      })
    );
  }

  filterSubmit(data) {
    let { dispatch } = this.props;

    let filterZeroId = (data) => {
      let keys = [
        "qe_type_id",
        "localization_id",
        "section_name",
        "workplace_id",
        "hired_status_id",
        "is_qe_actual",
      ];

      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = null;
        }
      }
      return data;
    };

    const sendData = { ...data };

    const {
      localization: { data: localizations },
    } = this.props;

    if (sendData.localization_id) {
      const localizationsData = localizations.find((i) => {
        return i.id == sendData.localization_id;
      });
      if (localizationsData && localizationsData.division) {
        sendData.division_id = data.localization_id;
        delete sendData.localization_id;
      }
    }

    getQEListFilter(filterZeroId(sendData))(dispatch).then(
      this.handleServerResponse()
    );
  }

  getHiredStatus(id) {
    if (!id) {
      return "Niezatrudniony";
    }

    return this.state.hired_statuses.find((i) => {
      return i.id == id;
    }).name;
  }

  getQEActual(item) {
    let { is_qe_actual } = item;
    if (is_qe_actual === true) {
      return "TAK";
    }
    if (is_qe_actual === false) {
      return "NIE";
    }
    return null;
  }

  handlePageChange(data) {
    this.setState({ data });
  }

  clearFilter(form) {
    let { dispatch } = this.props;
    this.form.form.mutators.clearFilterMutator();
    getQEList()(dispatch).then(this.handleServerResponse());
  }
  print(data) {
    let { dispatch } = this.props;

    printQEReport(data)(dispatch).then((res) => {
      if (!res.error) {
        let file;
        let fileName;
        let filePrefix = "Rejestr uprawnień kwalifikacyjnych";
        if (data.pdf) {
          file = res.payload.data;
          fileName = `${filePrefix}.pdf`;
        }
        if (data.xml || data.xls) {
          file = res.payload.data;
          fileName = `${filePrefix}.xls`;
        }

        saveBlobFile(file, fileName);
      }
    });
  }

  saveUserCallback({ message }) {
    this.getQEList(() => {
      this.setSuccessMessage(message);
    });
    this.closeModal("modalAddUser");
  }

  render() {
    let { data, message } = this.state;
    let {
      types,
      localization: { data: localizationData },
      section: { data: sectionData },
      workplace: { data: workplaceData },
      permission,
    } = this.props;

    return (
      <LayoutContent message={message} SideMenu={<SideMenu />}>
        <PageHeader>Rejestr uprawnień kwalifikacyjnych</PageHeader>
        <ButtonWrapper>
          <GenerateRaport disabled={!permission[49]} print={this.print} />

          <ButtonWithIcon
            disabled={!permission[63]}
            onClick={this.openModal("modalAddUser")}
            icon="fa fa-plus"
            name="Dodaj pracownika"
          />
        </ButtonWrapper>

        <TableSortableWithPagination
          data={this.props.data}
          headers={[
            { name: "Nazwisko", prop: "last_name" },
            { name: "Imię", prop: "first_name" },
            {
              name: "Lokalizacja",
              prop: "localization_id",
            },
            { name: "Dział", prop: "section_name" },
            { name: "Stanowisko", prop: "workplace_id" },
            { name: "Rodzaj uprawnień", prop: "qe_type_id" },
            { name: "Data nabycia", prop: "date" },
            { name: "Termin ważności", prop: "date_execution" },
            { name: "Status zatrudnienia", prop: "hired_status_id" },
            {
              name: "liczba dni do wygaśnięcia",
              prop: "date_deadline",
            },
            {
              name: "Aktualność uprawnień",
              prop: "is_qe_actual",
            },
            { name: "Edytuj dane" },
          ]}
          renderSearcher={
            <Form
              initialValues={{
                localization_id: "0",
                section_name: "0",
                workplace_id: "0",
                qe_type_id: "0",
                hired_status_id: "0",
                is_qe_actual: "0",
              }}
              mutators={{ clearFilterMutator }}
              onSubmit={this.filterSubmit}
              render={({ handleSubmit, pristine, invalid, form }) => {
                this.form = { form };

                return (
                  <>
                    <tr>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"last_name"}
                          placeholder={"wg Nazwiska"}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldInput
                          name={"first_name"}
                          placeholder={"wg Imienia"}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"localization_id"}
                          data={[{ id: "0", name: "Lokalizacja" }].concat(
                            localizationData
                          )}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"section_name"}
                          propValue="value"
                          data={[{ value: "0", name: "Dział" }].concat(
                            sectionData
                          )}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"workplace_id"}
                          data={[{ id: "0", name: "Stanowisko" }].concat(
                            workplaceData
                          )}
                        />
                      </td>
                      <td>
                        <FieldSelect
                          name={"qe_type_id"}
                          data={[
                            {
                              id: "0",
                              name: "Rodzaj uprawnień",
                              disabled: true,
                            },
                          ].concat(types)}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_get"} />
                      </td>
                      <td style={{ minWidth: "120px" }}>
                        <FieldDayPicker name={"date_deadline"} />
                      </td>

                      <td>
                        <FieldSelect
                          name={"hired_status_id"}
                          data={this.state.hired_statuses}
                        />
                      </td>
                      <td></td>
                      <td>
                        <FieldSelect
                          name={"is_qe_actual"}
                          data={[
                            {
                              id: "0",
                              name: "Aktualność badań",
                              disabled: true,
                            },
                            {
                              id: "true",
                              name: "Tak",
                            },
                            {
                              id: "false",
                              name: "Nie",
                            },
                          ]}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan="13" style={{ padding: "8px 40px 8px 8px" }}>
                        <ButtonWrapper nowrap>
                          <Button
                            type="button"
                            bsStyle="primary"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Szukaj
                          </Button>
                          <Button onClick={this.clearFilter}>
                            Wyłącz filtr
                          </Button>
                        </ButtonWrapper>
                      </td>
                    </tr>
                  </>
                );
              }}
            />
          }
          render={(sortedData) => {
            return sortedData.map((item) => {
              return (
                <tr>
                  <td>
                    <AStyled
                      onClick={this.openModal("modalUserDetails", {
                        data: item,
                      })}
                    >
                      {item.last_name}
                    </AStyled>
                  </td>
                  <td>{item.first_name}</td>
                  <td>{getLocalizationDivisionName(item)}</td>
                  <td>{item.section_name}</td>
                  <td>{item.workplace_name}</td>
                  <td>{item.qe_type_name}</td>
                  <td>{getDate(item.date_get)}</td>
                  <td>{getDate(item.date_deadline)}</td>
                  <td>{this.getHiredStatus(item.hired_status_id)}</td>
                  <ProgressTd item={item}>{item.days_left}</ProgressTd>
                  <ProgressTd item={item}>{this.getQEActual(item)}</ProgressTd>
                  <td>
                    <ButtonWithIcon
                      disabled={!permission[65]}
                      onClick={this.openModal("modalAddUser", {
                        users_id: item.users_id,
                      })}
                      icon="fa fa-pencil-square-o"
                    />
                  </td>
                </tr>
              );
            });
          }}
        />
        <QEWorker
          addNewQE={this.openModal("modalAddQE", {
            data: this.state.modalUserDetails.data,
          })}
          data={this.state.modalUserDetails.data}
          open={this.state.modalUserDetails.open}
          closeModal={this.closeModal("modalUserDetails")}
          permission={permission}
        />
        <AddQE
          addQE={this.addQE}
          open={this.state.modalAddQE.open}
          data={this.state.modalAddQE.data}
          closeModal={this.closeModal("modalAddQE")}
          types={this.props.types}
          permission={permission}
          isAdd
        />
        <UserModal
          open={this.state.modalUser.open}
          closeModal={this.closeModal("modalUser")}
          permission={permission}
        />
        <ModalAddUser
          {...this.state.modalAddUser}
          accept={this.saveUserCallback}
          closeModal={this.closeModal("modalAddUser", { users_id: null })}
          permission={permission}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  permission: store.qe.permission,
  data: store.qe.data,
  types: store.qe.types,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  hired_statuses: store.users.hired_statuses,
  message: store.message,
}))(List);
