import React from "react";
import { connect } from "react-redux";

import {
  getRA,
  getRAAnalysisHazard,
  getRAAHTMEntry,
  getRAPermission,
  addRAAHTMEntry,
  clearRiskAssessment,
  setStageRA,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import TaskDecreaseRisk from "components/RiskAssessment.Forms/TaskDecreaseRisk/";

import SideMenu from "./_SideMenu";
import { setUrls } from "./_staticData";

import { RAAnalysis } from "container/RiskAssessment/EntryAddEdit/Analysis/index";
import { TaskDecreaseRiskContainer } from "container/RiskAssessment/EntryAddEdit/TaskDecreaseRisk";
class TaskDecreaseRiskContainerDraft extends TaskDecreaseRiskContainer {
  constructor(props) {
    super(props);
    this.state = { ...this.state, urls: setUrls() };
  }

  renderSideMenu() {
    const { match, permission, ra: data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }
}

export default connect((store) => ({
  ra: store.riskAssessment.singleData,
  permission: store.riskAssessment.permission,
  raTM: store.riskAssessment.raTM,
  hazards: store.riskAssessment.raHazards,
  message: store.message,
}))(TaskDecreaseRiskContainerDraft);
