import { defaultUrls as moduleUrls } from "../_staticData";

export const defaultUrls = {
  ...moduleUrls,
  ADD: moduleUrls.ARCHIVEBASIC,
  BASIC: moduleUrls.ARCHIVEBASIC,
  CHARACTERISTIC: moduleUrls.ARCHIVECHARACTERISTIC,
  DANGER: moduleUrls.ARCHIVEDANGER,
  ANALYSIS: moduleUrls.ARCHIVEANALYSIS,
  TASK: moduleUrls.ARCHIVETASK,
  NOTIFICATION: moduleUrls.ARCHIVENOTIFICATION,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
