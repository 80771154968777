import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";
import { ButtonWrapper } from "components/Wrappers";
import { StyledInputBox, StyledLabel } from "style/styleComponents/Form/input";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let GetLine = styled.div`
  display: flex;
  margin: 0 0 0 -10px;
  & > * {
    padding: 0 10px;
    &:nth-child(1) {
      flex: 1 1 70%;
    }
    &:nth-child(2) {
      flex: 1 1 30%;
    }
  }
`;

class GetDraftModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      visibleData: [],
      filter: "",
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setState({ visibleData: this.props.workplaces });
  }

  componentDidUpdate(prevProps, prevState) {
    let { visibleData, filter } = this.state;
    let { workplaces } = this.props;
    function varsDiff() {
      return visibleData.length !== workplaces;
    }
    if (workplaces && !prevProps.workplaces.length) {
      this.setState({ visibleData: this.props.workplaces });
    }

    // if (varsDiff()) {
    // }

    if (filter !== prevState.filter) {
      let regexp = new RegExp(filter, "i");

      let filteredData = this.props.workplaces.filter((i) => {
        return regexp.test(i.name);
      });
      this.setState({
        visibleData: filteredData,
      });
    }
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { visibleData } = this.state;
    let { open, workplaces, setWorkplace } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Wybierz stanowisko pracy</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit }) => {
                return (
                  <form>
                    <StyledInputBox
                      name="filter"
                      placeholder="Szukaj..."
                      onChange={(event) => {
                        this.setState({ filter: event.target.value });
                      }}
                    />
                    <TableListPage>
                      <thead>
                        <th>Nazwa</th>
                      </thead>
                      <tbody>
                        {visibleData.map((item) => (
                          <tr>
                            <td
                              onClick={() => {
                                setWorkplace(item);
                                this.closeModal();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {item.name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableListPage>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class GetDraft extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: false,
      workplace: { id: null, name: null },
    };

    this.setWorkplace = this.setWorkplace.bind(this);
  }

  componentDidUpdate() {
    let { workplace } = this.state;
    let { input, workplaces } = this.props;

    if (input.value && input.value !== workplace.id) {
      let foundedWorkplace = workplaces.find((i) => {
        return i.id == input.value;
      });

      if (foundedWorkplace) {
        this.setState({
          workplace: {
            id: foundedWorkplace.id,
            name: foundedWorkplace.name,
          },
        });
      }
    }
  }

  setWorkplace(item) {
    let { id, name } = item;
    this.setState({ workplace: { id, name } });
  }

  render() {
    let { open, workplace } = this.state;
    let { workplaces, input, readOnly } = this.props;

    return (
      <>
        <>
          <StyledLabel value="Stanowisko pracy"></StyledLabel>
          <GetLine>
            <StyledInputBox disabled value={workplace.name}></StyledInputBox>
            <Button
              style={{ height: "34px" }}
              disabled={readOnly}
              onClick={() => {
                this.setState({ open: true });
              }}
            >
              Wybierz stanowisko pracy
            </Button>
          </GetLine>
          <GetDraftModal
            workplaces={workplaces}
            open={open}
            setWorkplace={(item) => {
              this.setWorkplace(item);
              input.onChange(item.id);
            }}
            closeModal={() => {
              this.setState({ open: false });
            }}
          />
        </>
      </>
    );
  }
}
