import React from "react";
import Table from "react-bootstrap/lib/Table";
import { StyledTable } from "components/ListComponents";
import styled from "styled-components";

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const HistoryTable = styled(Table)`
  font-size: 12px;
  &.table {
  }
  & th {
    border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    padding: 5px;
    box-sizing: border-box;
  }
  & tr {
    & > td {
      &:nth-child(1) {
        width: 25px;
      }
      &:nth-child(2) {
        width: 110px;
      }
      &:nth-child(4) {
        width: 100px;
      }
    }
  }
`;

const NotificationHistoryList = ({ data }) => (
  <div>
    <TableWrapper>
      <StyledTable firstNarrowColumn>
        <thead>
          <tr>
            <th>L.p.</th>
            <th>Imię i nazwisko</th>
            <th>Okoliczności powiadomienia</th>
            <th>Data wysłania</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item.users_name}</td>
              <td>{item.notification_name}</td>
              <td>{item.send_date}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  </div>
);

export default NotificationHistoryList;
