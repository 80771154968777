import React from "react";
import PropTypes from "prop-types";

export const InputCloneButtonDelete = props => (
  <button onClick={props.onClick}>
    {props.children}
  </button>
);

export const InputCloneButtonAdd = props => (
  <button onClick={props.onClick}>
    {props.children}
  </button>
);

class InputClone extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      blockUpdate:false,
      inputs: props.data || [],
      counter: 0
    };

    this.addInput = this.addInput.bind(this);
    this.renderButtonAdd = this.renderButtonAdd.bind(this);
    this.renderButtonDelete = this.renderButtonDelete.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data))
      this.setState({inputs: nextProps.data,blockUpdate:true});
  }

  componentDidUpdate() {
    // this.handleChange();
  }

  addInput() {
    const inputs = [...this.state.inputs];
    inputs.push({value: "",number:this.state.counter});
    this.setState({inputs: inputs,counter:this.state.counter+1});
  }

  changeValue(index, event) {
    const inputs = [...this.state.inputs];
    const {value} = event.target;
    inputs[index].value = value;
    this.setState({inputs});
  }

  handleChange() {
    const {
      handleChange = () => {
      }
    } = this.props;
    handleChange(this.state.inputs);
  }

  deleteInput(index,callback = ()=>{}) {
    return ()=>{
      const inputs = [...this.state.inputs];
      inputs.splice(index, 1);
      callback();
      this.setState({inputs: inputs});
    };
  }

  renderInput(input, index) {
    if (this.props.customInput) {
      return this.props.customInput({
        Button: (this.renderButtonDelete(this.deleteInput.bind(this, index))),
        onChange: this.changeValue.bind(this, index),
        handleDelete:this.deleteInput.bind(this, index),
        input,
        index
      });
    }
    else {
      const Button = this.props.customButtonDelete;
      return (<div key={index}>
        <input name={`name[${input.id}]`} value={input.value} onChange={this.changeValue.bind(this, index)}/>
        {Button
          ? <Button onClick={this.deleteInput.bind(this, index)()}></Button>
          : <InputCloneButtonDelete onClick={this.deleteInput.bind(this, index)()}/>}
      </div>);
    }
  }

  renderInputs() {
    const {inputs} = this.state;
    return inputs.map(this.renderInput);
  }

  renderButtonAdd() {
    const Component = this.props.customButtonAdd || InputCloneButtonAdd;
    return (<Component onClick={this.addInput}>Dodaj</Component>);
  }

  renderButtonDelete(fn) {
    const Component = this.props.customButtonDelete || InputCloneButtonDelete;
    return Component;
  }

  render() {
    return (
      <div>
        <div id="inputWrapper">
          {this.renderInputs()}
          {this.renderButtonAdd()}
        </div>
      </div>
    );
  }
}

export default InputClone;

InputClone.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node
    })
  ),
  handleChange: PropTypes.func
};