import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LayoutContent } from "./Layout";
import SideMenu from "./SideMenu";
import Box from "./Box";
import {
  StyledInputBox,
  StyledInputCheckBox,
} from "../style/styleComponents/Form/input";
import Button from "react-bootstrap/lib/Button";
import { media } from "../style/style-utils";
import FormModal from "./Modal/FormModal";
import { Form, Field } from "react-final-form";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import { LinkContainer } from "./LinkContainer";
import validator from "./Validation/";
import ProfileImage from "./ProfileImage";
import Modal from "./Modal/SimpleAccept";
import ModalCheckList from "./Modal/CheckList";
import dataToFormData from "./dataToFormData";
import { FieldInputCheckBox } from "components/Fields";

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

let id_name = {
  draftAuditType: "draft_audit_type_id_",
  draftRA: "draft_ra_id_",
  draftRAMethods: "draft_ra_method_id_",
  draftControlList: "draft_control_list_id_",
  draftHazards: "draft_hazards_id_",
  draftQEType: "draft_qe_type_id_",
};

let modalDirectory = {
  audit: {
    modalProp: "modalDraftAudit",
    dataProp: "auditTypesDrafts",
    subFormProp: "draftAuditType",
    buttonName: " Szablony audytowych list kontrolnych",
  },
  ra: {
    modalProp: "modalDraftRa",
    dataProp: "ra",
    subFormProp: "draftRA",
    buttonName: "Szablony wzorcowych ocen ryzyka zawodowego",
  },
  raMethod: {
    modalProp: "modalDraftRaMethod",
    dataProp: "raMethod",
    subFormProp: "draftRAMethods",
    buttonName: "Szablony metod oceny ryzyka zawodowego",
  },
  controlList: {
    modalProp: "modalDraftControlList",
    dataProp: "controlList",
    subFormProp: "draftControlList",
    buttonName: "Szablony list kontrolnych identyfikacji zagrożeń",
  },
  hazards: {
    modalProp: "modalDraftHazards",
    dataProp: "hazards",
    subFormProp: "draftHazards",
    buttonName: "Wzorcowe zagrożenia",
  },
  QEType: {
    modalProp: "modalDraftQEType",
    dataProp: "QETypes",
    subFormProp: "draftQEType",
    buttonName: "Rodzaje uprawnień kwalifikacyjnych",
  },
};

const BasicWrapper = styled.div`
  margin-bottom: 40px;
  ${media.sm`
    margin-botton:10px
  `}
`;

const MainWrapper = styled.div`
  ${media.lg`
    display: flex;
    justify-content: space-between; 
    & > div { 
      width: 48%;
    }
  `}
`;
const ModulesCheckboxWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin: 0 -5px;
  & > * {
    margin: 0 5px;
  }
`;

const DraftsSection = styled.div`
  margin-bottom: 30px;
  ${media.sm`
    margin-bottom: 10px;
  `};
`;

const DraftsButtonWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

const DraftsButton = styled(Button)`
  margin-bottom: 5px !important;
`;

let factoryModalListState = (propName) => {
  return { [propName]: { open: false } };
};

let modalState = () => {
  let result = Object.keys(modalDirectory).reduce((obj, key) => {
    let newData = factoryModalListState(modalDirectory[key].modalProp);
    obj = { ...obj, ...newData };
    return obj;
  }, {});

  return result;
};

class BusinessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        open: false,
      },
      modalLogoDelete: {
        open: false,
        handleOpen: this.handleOpenModal.bind(this)("modalLogo"),
        handleClose: this.handleCloseModal.bind(this)("modalLogo"),
      },

      ...modalState(),
      fetched: false,
      imagePreviewUrl: null,
      defaultImage: "/images/default_avatar.png",
      subForms: {},
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDeleteLogoRequest = this.handleDeleteLogoRequest.bind(this);
    this.submit = this.submit.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleSubmitCheckList = this.handleSubmitCheckList.bind(this);
    this.factoryModalCheckList = this.factoryModalCheckList.bind(this);
    this.factoryModalChecklistButton = this.factoryModalChecklistButton.bind(
      this
    );
    this.setSubFormInitialData = this.setSubFormInitialData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { data = {} } = nextProps;
    if (this.state.imagePreviewUrl !== data.avatar_url) {
      this.setState({
        fetched: true,
        imagePreviewUrl: data.logo_url ? data.logo_url : null,
      });
    }

    return true;
  }

  setSubFormInitialData(prop) {
    let { dataProp, subFormProp } = modalDirectory[prop];
    let data = this.props[dataProp];
    if (
      this.state.subForms &&
      !this.state.subForms[subFormProp] &&
      data &&
      data.length > 0
    ) {
      let result = data
        .filter((item) => {
          return item.checked;
        })
        .reduce((obj, item) => {
          obj[`${id_name[subFormProp]}${item.id}`] = true;
          return obj;
        }, {});

      this.setState({
        ...this.state,
        subForms: {
          ...this.state.subForms,
          [subFormProp]: result,
        },
      });
    }
  }

  componentDidUpdate() {
    this.setSubFormInitialData("audit");
    this.setSubFormInitialData("ra");
    this.setSubFormInitialData("raMethod");
    this.setSubFormInitialData("controlList");
    this.setSubFormInitialData("hazards");
    this.setSubFormInitialData("QEType");
  }

  factoryModalChecklistButton(prop) {
    let { modalProp, buttonName } = modalDirectory[prop];

    return (
      <DraftsButton onClick={this.handleModalOpen(modalProp)}>
        {buttonName}
      </DraftsButton>
    );
  }

  factoryModalCheckList(prop) {
    let { modalProp, dataProp, subFormProp } = modalDirectory[prop];
    let data = this.props[dataProp] || [];
    return (
      <ModalCheckList
        open={this.state[modalProp].open}
        data={data}
        id_name={id_name[subFormProp]}
        initialValues={this.state.subForms[subFormProp]}
        handleClose={this.handleModalClose(modalProp)}
        handleSubmit={this.handleSubmitCheckList(subFormProp)}
      />
    );
  }

  handleOpenModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: true } });
    };
  }

  handleCloseModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: false } });
    };
  }

  handleDeleteLogoRequest() {
    this.handleCloseModal("modalLogoDelete")(this.props.handleDeleteAvatar);
    if (this.props.handleDeleteLogo) {
      this.props.handleDeleteLogo(() => {
        this.setState({ imagePreviewUrl: null });
      });
    }
    if (this.props.createView) {
      this.setState({ imagePreviewUrl: null });
    }
  }

  handleModalClose(modalName) {
    return () => {
      this.setState({
        [modalName]: { ...this.state[modalName], open: false },
      });
    };
  }

  handleModalOpen(modalName) {
    return () => {
      this.setState({
        [modalName]: { ...this.state[modalName], open: true },
      });
    };
  }

  handleImageChange(e) {
    e.preventDefault();
    const that = this;
    const { handleLogoAutoUpload } = this.props;
    let reader = new FileReader();
    let file = e.target.files[0];

    if (handleLogoAutoUpload) {
      const formData = dataToFormData({ logo: file });
      handleLogoAutoUpload(formData, function (url) {
        that.setState({ imagePreviewUrl: url });
      });
    } else {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };

      reader.readAsDataURL(file);
    }
  }

  submit(data) {
    const { subForms } = this.state;
    const { handleSubmit } = this.props;
    const { name, active, ...rest } = data;

    const modules = Object.keys(data)
      .filter((key) => {
        return /module\_[0-9]{1,}/.test(key);
      })
      .map((keys) => keys.split("_")[1])
      .filter((id) => data[`module_${id}`])
      .join(",");

    let subFormsValue = Object.keys(subForms).reduce((obj, key) => {
      let newObj = { ...obj, ...subForms[key] };
      return newObj;
    }, {});

    let endData = {
      ...data,
      ...subFormsValue,
      module: modules,
    };
    const formData = dataToFormData(endData);

    handleSubmit(formData);
  }

  handleModalSubmit(data) {
    const { handleModalSubmit } = this.props;
    handleModalSubmit(data);
    this.handleModalClose("modalNotificationDelete");
  }

  handleSubmitCheckList(formName) {
    return (data) => {
      this.setState({ subForms: { ...this.state.subForms, [formName]: data } });
    };
  }

  render() {
    const { modal } = this.state;
    const {
      modules = [],
      createView = false,
      boxTitle,
      handleSubmit,
      data = {},
      admin = {},
      message = {},
      user = {},
    } = this.props;
    const initialModules = modules.reduce((obj, item) => {
      obj[`module_${item.id}`] = item.active;
      return obj;
    }, {});

    return (
      <LayoutContent
        SideMenu={
          <SideMenu
            title={"Konta firm"}
            data={[
              { name: "Lista kont", url: "/super-admin/businesses" },
              { name: "Dodaj konto", url: "/super-admin/businesses/add" },
            ]}
          />
        }
      >
        {message && message.text && (
          <Alert bsStyle={message.className}>{message.text}</Alert>
        )}
        <Box title={boxTitle}>
          <Form
            onSubmit={this.submit}
            initialValues={{ ...data, ...initialModules }}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit}>
                <MainWrapper>
                  <div>
                    <BasicWrapper>
                      <Field
                        name="name"
                        validate={validator.required}
                        render={({ input, meta, ...rest }) => (
                          <StyledInputBox
                            {...input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="Nazwa"
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                      <Field
                        name="active"
                        render={({ input, meta, ...rest }) => (
                          <StyledInputCheckBox
                            {...input}
                            checked={input.value}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="Konto firmy aktywne"
                            value={input.value}
                            onChange={(event) => {
                              input.onChange(event.target.checked);
                            }}
                          />
                        )}
                      />
                    </BasicWrapper>
                    <ModulesCheckboxWrapper>
                      <h4>Dostępne moduły</h4>
                      {modules.map((item, index) => (
                        <Field
                          key={index}
                          name={`module_${item.id}`}
                          render={({ input, meta, ...rest }) => (
                            <StyledInputCheckBox
                              {...input}
                              value={item.id}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                              key={index}
                              label={item.name}
                              checked={input.value}
                              onChange={(event) => {
                                input.onChange(event.target.checked);
                              }}
                            />
                          )}
                        />
                      ))}
                    </ModulesCheckboxWrapper>
                    <DraftsSection>
                      <h3>Dostępne szablony dokumentów</h3>
                      <DraftsButtonWrapper>
                        {this.factoryModalChecklistButton("audit")}
                        {this.factoryModalChecklistButton("raMethod")}
                        {this.factoryModalChecklistButton("ra")}
                        {this.factoryModalChecklistButton("controlList")}
                        {this.factoryModalChecklistButton("hazards")}
                        {this.factoryModalChecklistButton("QEType")}
                      </DraftsButtonWrapper>
                    </DraftsSection>
                    {!createView && (
                      <Button onClick={this.handleModalOpen("modal")}>
                        {Object.keys(user).length
                          ? "Edytuj konto administratora"
                          : "Dodaj konto administratora"}
                      </Button>
                    )}
                  </div>
                  <div>
                    <h4>Dane firmy</h4>
                    <Field
                      name="full_name"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          {...input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          label="Pełna nazwa firmy"
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="main_address"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          {...input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          label="Adres siedziby głównej"
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="nip"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          {...input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          label="NIP"
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="regon"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          {...input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          label="REGON"
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="pkd"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <StyledInputBox
                          {...input}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          label="Kod PKD"
                          onChange={(event) => {
                            input.onChange(event.target.value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="logo"
                      render={({ input, meta, ...rest }) => (
                        <ProfileImage
                          imageSrc={this.state.imagePreviewUrl}
                          handleDelete={this.handleOpenModal("modalLogoDelete")}
                          handleChange={(event) => {
                            this.handleImageChange(event);
                            input.onChange(event.target.files[0]);
                          }}
                          defaultImage={this.state.defaultImage}
                        />
                      )}
                    />
                    <FieldInputCheckBox
                      name="show_logo_in_pdf"
                      label="Używaj logo w raportach z audytów"
                      stringValue
                    />
                  </div>
                </MainWrapper>
                <ButtonWrapper>
                  <Button type="submit" bsStyle="primary">
                    {createView ? "Dodaj" : "Zapisz"}
                  </Button>
                  <LinkContainer exact to={"/super-admin/businesses"}>
                    <Button>Anuluj</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}
          />
          <FormModal
            title={"Konto administratora"}
            initialValues={user}
            open={modal.open}
            onSubmit={handleSubmit}
            handleClose={this.handleModalClose("modal")}
            handleSubmit={this.handleModalSubmit}
            acceptButtonName={
              user && Object.keys(user).length ? "Zapisz" : "Dodaj"
            }
            cancelButtonName="Anuluj"
          >
            <Field
              name="first_name"
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="Imię"
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              )}
            />
            <Field
              name="last_name"
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="Nazwisko"
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              )}
            />
            <Field
              name="email"
              validate={composeValidators(
                validator.required,
                validator.mustBeEmail
              )}
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="E-mail"
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              )}
            />
            <Field
              name="phone"
              render={({ input, meta, ...rest }) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="Nr telefonu"
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              )}
            />
          </FormModal>
          <Modal
            open={this.state.modalLogoDelete.open}
            title="Usuń awatar"
            description="Czy chcesz usunąć logo firmy?"
            handleClose={this.handleCloseModal("modalLogoDelete")}
            handleAccept={this.handleDeleteLogoRequest}
          />

          {this.factoryModalCheckList("audit")}
          {this.factoryModalCheckList("raMethod")}
          {this.factoryModalCheckList("ra")}
          {this.factoryModalCheckList("controlList")}
          {this.factoryModalCheckList("hazards")}
          {this.factoryModalCheckList("QEType")}
        </Box>
      </LayoutContent>
    );
  }
}

export default BusinessForm;

BusinessForm.propTypes = {
  boxTitle: PropTypes.string,
  data: PropTypes.object,
  admin: PropTypes.object,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.node,
      active: PropTypes.bool,
    })
  ),
  message: PropTypes.string,
  createView: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleModalSubmit: PropTypes.func,
};
