export default function reducer(
  state = {
    data: [],
    fetching: false,
    fetched: true,
  },
  action
) {
  switch (action.type) {
    case "GET_MODULES_REQUEST": {
      return { ...state, fetching: true };
    }
    case "GET_MODULES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.modules,
      };
    }
    case "GET_MODULES_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_BUSINESS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.modules,
      };
    }
    case "LOGOUT_USER_SUCCESS": {
      return { ...state, data: [] };
    }
  }

  return state;
}
