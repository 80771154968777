import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import Modal from "components/Modal/SimpleAccept";

import validator from "components/Validation";
import {
  StyledInputBox,
  StyledInputMessage,
} from "style/styleComponents/Form/input";
import { StyledLabel } from "style/styleComponents/Form/input";
import { ButtonWrapper } from "components/Wrappers";

const InputWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  align-items: flex-start;
  & > * {
    margin: 10px;
  }
  & > div {
    flex-basis: ${(props) => (props.atLeastOne ? "100%" : "80%")};
  }
`;

export default class FieldArrayComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        open: false,
      },
    };
  }

  acceptModal() {
    const { callback } = this.state.modal;
    callback();
    this.modalClose();
  }

  openModal() {
    this.setState({
      modal: { ...this.state.modal, open: true },
    });
  }

  closeModal() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
        entry_id: false,
        entry_name: null,
        callback: () => {},
      },
    });
  }

  render() {
    let { modal } = this.state;
    let {
      defaultValue = {},
      name,
      render: propsRender,
      label,
      atLeastOne,
      readOnly,
      validate,
    } = this.props;

    return (
      <Fragment>
        <StyledLabel>{label}</StyledLabel>
        <FieldArray name={name} validate={validate}>
          {({ fields, meta }) => (
            <Fragment>
              {fields.map((name, index) => {
                const field = fields.value[index];
                return (
                  <InputWrapper key={`${index}_${fields.length}`} atLeastOne>
                    {propsRender({ name, index })}
                    {!(atLeastOne && index == 0) && (
                      <Button
                        disabled={readOnly}
                        onClick={() => {
                          if (this.props.removeProp) {
                            this.props.removeProp(fields, index);
                          } else {
                            fields.remove(index);
                          }
                        }}
                      >
                        <i className={"fa fa-trash"} />
                      </Button>
                    )}
                  </InputWrapper>
                );
              })}
              <ButtonWrapper>
                <Button
                  disabled={readOnly || fields.length > 6}
                  onClick={() => fields.push(defaultValue)}
                >
                  Dodaj kolejną przyczynę
                </Button>
              </ButtonWrapper>
              <StyledInputMessage>
                {meta.error && meta.touched ? meta.error : null}
              </StyledInputMessage>
            </Fragment>
          )}
        </FieldArray>
        <Modal
          open={modal.open}
          title={`Usuń wpis`}
          description={"Czy na pewno chcesz usunąć dany wpis? "}
          handleAccept={this.acceptModal}
          handleClose={this.closeModal}
        />{" "}
      </Fragment>
    );
  }
}
