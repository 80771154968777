import React, { Fragment } from "react";
import Image from "react-bootstrap/lib/Image";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import { bootstrapUtils } from "react-bootstrap/lib/utils";
import { isBrowser, isMobile } from "react-device-detect";
import Media from "react-media";
import { LinkContainer } from "../LinkContainer";
import CollapseItem from "./CollapseItem";
import {
  CustomToggle,
  MobileDropDownToggle,
  CustomStyledNavItemStyles,
  CustomStyledNavItem2,
  NavItemHoverNavWrapperMobileStyle,
  NavItemHoverNavWrapper,
  NavHead,
  NavDropboxContainer,
  NavHeadTitle,
  HoverMenuMobileStyles,
  MenuLinkStyle,
} from "./_styleComponents";
import styled from "styled-components";
import { media } from "../../style/style-utils/index";
import MenuLink from "./_MenuLink";
import AnimateHeight from "react-animate-height";
import default_avatar from "../../style/image/default_avatar.png";
import { CSSTransition } from "react-transition-group";

bootstrapUtils.addStyle(Image, "avatar");

const UserAvatarImageBox = styled.div`
  text-align: center;
  width: 100%;

  ${isMobile
    ? `
    width:50px;
    max-width:50px;
  `
    : ""}
  ${media.xs`
    max-width:50px;
  }`}
  & img {
    width: 38px;
    ${isMobile
      ? `
      width:46px;
    `
      : ""}
    ${media.xs`
      width:46px;
    `}
    max-width:50px;
  }
`;

const UserAvatarImage = (props) => (
  <UserAvatarImageBox>
    <Image src={props.src || default_avatar} circle />
  </UserAvatarImageBox>
);

const UlWrapper = styled.div`
  overflow: hidden;
  max-height: 0;
  z-index: 1000;

  &.slide-enter {
    display: block;
    max-height: 0;

    &-done {
      display: block;
      overflow: hidden;
      max-height: ${(props) =>
        props.length ? `${props.length * 26 + 14}` : "0"}px;
      transition: max-height 0.3s linear;
    }
  }

  &.slide-exit {
    overflow: hidden;
    display: block;
    max-height: ${(props) =>
      props.length ? `${props.length * 26 + 14}` : "0"}px;
    &-done {
      display: block;
      overflow: hidden;
      transition: max-height 0.3s linear;
    }
  }
`;

const Ula = styled.ul`
  &.dropdown-menu {
    display: block;
    position: relative;
    top: 0;
    min-width: 100%;
  }
`;

class SlideDown extends React.Component {
  render() {
    let { children, open } = this.props;
    return (
      <Fragment>
        <li className={`dropdown open`} style={{ fontSize: "32px" }}>
          <CSSTransition in={open} classNames="slide" timeout={0}>
            <UlWrapper length={children.length}>
              <Ula className="dropdown-menu">{children}</Ula>
            </UlWrapper>
          </CSSTransition>
        </li>
      </Fragment>
    );
  }
}

export default class HoverNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleOpen(fn) {
    return () => {
      if (window.innerWidth >= 767) {
        fn();
      }
    };
  }

  handleClose(fn) {
    return () => {
      if (window.innerWidth >= 767) {
        fn();
      }
    };
  }

  handleClick() {
    if (this.state.isOpen) {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      title,
      icon,
      pullRight,
      url,
      onSelectCollapse,
      collapseOutParent,
      updateParentOpenChild,
      open,
      close,
      index,
      parentOpen,
      image,
      images,
      imageUrl,
      account,
      className,
      title2,
    } = this.props;

    return (
      <CollapseItem
        onSelectCollapse={onSelectCollapse}
        collapseOutParent={collapseOutParent}
        updateParentOpenChild={updateParentOpenChild}
        parentOpen={parentOpen}
        onMouseEnter={isBrowser ? this.handleOpen : undefined}
        onMouseLeave={isBrowser ? this.handleClose : undefined}
        index={index}
        open={open}
        close={close}
        renderItem={({ collapse, children, open, collapseIn, collapseOut }) => (
          <NavItemHoverNavWrapper
            account={account}
            pullRight={pullRight}
            onMouseEnter={isBrowser ? this.handleOpen(collapseIn) : undefined}
            onMouseLeave={isBrowser ? this.handleClose(collapseOut) : undefined}
          >
            <NavHead>
              <CustomStyledNavItem2
                account={account}
                hoverItem
                pullRight={pullRight}
                href="#"
                style={{ fontSize: "32px" }}
              >
                {url ? (
                  <Fragment>
                    {image ? (
                      <LinkContainer exact to={url}>
                        <MenuLinkStyle>
                          <UserAvatarImage src={imageUrl} />
                          <NavHeadTitle>{title}</NavHeadTitle>
                        </MenuLinkStyle>
                      </LinkContainer>
                    ) : (
                      <MenuLink
                        url={url}
                        title={title}
                        className={icon}
                        images={images}
                      />
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {image ? (
                      <Fragment>
                        <Media query="(max-width: 767px)">
                          {(matches) =>
                            matches || isMobile ? (
                              <Fragment>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <MenuLinkStyle
                                    onClick={collapse}
                                    title={title}
                                    width={"auto"}
                                  >
                                    <UserAvatarImage src={imageUrl} />
                                  </MenuLinkStyle>
                                  <NavHeadTitle account={account}>
                                    {title} ??
                                  </NavHeadTitle>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <MenuLinkStyle onClick={collapse} title={title}>
                                  <UserAvatarImage src={imageUrl} />
                                </MenuLinkStyle>
                                <NavHeadTitle account={account}>
                                  {title}
                                </NavHeadTitle>
                              </Fragment>
                            )
                          }
                        </Media>
                      </Fragment>
                    ) : (
                      <MenuLink
                        onClick={collapse}
                        title={title}
                        className={icon}
                        images={images}
                      />
                    )}
                  </Fragment>
                )}
              </CustomStyledNavItem2>
              {url && (
                <MobileDropDownToggle
                  onClick={collapse}
                  className={`fa ${open ? "fa-minus" : "fa-plus"} fa-navitem`}
                ></MobileDropDownToggle>
              )}
            </NavHead>
            <NavDropboxContainer>
              <Media query={{ maxWidth: 767 }}>
                {(matches) =>
                  matches || isMobile ? (
                    <AnimateHeight duration={500} height={open ? "auto" : 0}>
                      <NavDropdown
                        open={true}
                        noCaret
                        id="language-switcher-container"
                        title={""}
                        onToggle={() => {}}
                        style={{ fontSize: "32px" }}
                      >
                        {children}
                      </NavDropdown>
                    </AnimateHeight>
                  ) : (
                    <Fragment>
                      <SlideDown open={open}>{children}</SlideDown>
                      {/* <NavDropdown 
                      open={open}
                      noCaret 
                      id="language-switcher-container"
                      title={""}
                      onToggle={() => {
                      }}
                      style={{fontSize: "32px"}}
                    >
                        {children}
                    </NavDropdown> */}
                    </Fragment>
                  )
                }
              </Media>
            </NavDropboxContainer>
          </NavItemHoverNavWrapper>
        )}
      >
        {this.props.children}
      </CollapseItem>
    );
  }
}
