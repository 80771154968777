export function getWorkplaces() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_REQUEST",
        "GET_WORKPLACES_SUCCESS",
        "GET_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: "/business/workplace",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacesDenest() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_REQUEST",
        "GET_WORKPLACES_DENESTED_SUCCESS",
        "GET_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: "/business/workplace",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacesWithout(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_WITHOUT_REQUEST",
        "GET_WORKPLACES_WITHOUT_SUCCESS",
        "GET_WORKPLACES_WITHOUT_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/without/${
            workplace_id ? `${workplace_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacesParentWithout(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_WITHOUT_REQUEST",
        "GET_WORKPLACES_WITHOUT_SUCCESS",
        "GET_WORKPLACES_WITHOUT_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/parent-without/${
            workplace_id ? `${workplace_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getParentWorkplacesWithout(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_PARENT_WORKPLACES_WITHOUT_REQUEST",
        "GET_PARENT_WORKPLACES_WITHOUT_SUCCESS",
        "GET_PARENT_WORKPLACES_WITHOUT_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/without/${
            workplace_id ? `${workplace_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplace(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACE_REQUEST",
        "GET_WORKPLACE_SUCCESS",
        "GET_WORKPLACE_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/edit/${
            workplace_id ? `${workplace_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacesTree(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_TREE_REQUEST",
        "GET_WORKPLACES_TREE_SUCCESS",
        "GET_WORKPLACES_TREE_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/tree/${workplace_id ? `${workplace_id}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacesTreeByLocalization() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_TREE_REQUEST",
        "GET_WORKPLACES_TREE_SUCCESS",
        "GET_WORKPLACES_TREE_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/meoshqe-tree-localization`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addWorkplace(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_WORKPLACES_REQUEST",
        "ADD_WORKPLACES_SUCCESS",
        "ADD_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: "/business/workplace/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editWorkplace(workplace_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_WORKPLACES_REQUEST",
        "EDIT_WORKPLACES_SUCCESS",
        "EDIT_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/edit/${workplace_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteWorkplace(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_WORKPLACES_REQUEST",
        "DELETE_WORKPLACES_SUCCESS",
        "DELETE_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: `/business/workplace/delete/${workplace_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getWorkplacePermissions() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACE_REQUEST",
        "GET_WORKPLACE_SUCCESS",
        "GET_WORKPLACE_ERROR",
      ],
      payload: {
        request: {
          url: "/business/workplace/permissions",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserWorkplacesWithout(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_WITHOUT_REQUEST",
        "GET_WORKPLACES_WITHOUT_SUCCESS",
        "GET_WORKPLACES_WITHOUT_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/without/${workplace_id ? `${workplace_id}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserWorkplacesWithoutTrim(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_WORKPLACES_WITHOUT_TRIM_REQUEST",
        "GET_WORKPLACES_WITHOUT_TRIM_SUCCESS",
        "GET_WORKPLACES_WITHOUT_TRIM_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/without/${workplace_id ? `${workplace_id}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserWorkplacesWithoutOnParentField(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_PARENT_WORKPLACES_WITHOUT_REQUEST",
        "GET_PARENT_WORKPLACES_WITHOUT_SUCCESS",
        "GET_PARENT_WORKPLACES_WITHOUT_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/without/${workplace_id ? `${workplace_id}` : ""}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getSearchedWorkplace(name, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SEARCHED_WORKPLACES_REQUEST",
        "GET_SEARCHED_WORKPLACES_SUCCESS",
        "GET_SEARCHED_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/get-by-name`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            name: name,
            index: index,
          },
        },
      },
    });
}

export function getSearchedWorkplaceInitial(workplace_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_INITIAL_SEARCH_WORKPLACES_REQUEST",
        "GET_INITIAL_SEARCH_WORKPLACES_SUCCESS",
        "GET_INITIAL_SEARCH_WORKPLACES_ERROR",
      ],
      payload: {
        request: {
          url: `/workplace/search-initial/${
            workplace_id ? `${workplace_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function localSetSearched(workplaces, index) {
  return (dispatch) =>
    dispatch({
      type: "LOCAL_SET_DEFAULT_WORKPLACE_SEARCHED",
      payload: {
        workplaces,
        index,
      },
    });
}

export function clearWorkplaceReducer() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_WORKPLACE",
      payload: {},
    });
}
