import React from 'react';
import styled from "styled-components";
import AppComponent from "../components/AppComponent";
import {Link} from "react-router-dom";

class NotFound extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state,
      mainPage: "/"
    };
    this.back = this.back.bind(this);
  }

  back(){
    const {history} = this.props;
    history.back();
  }

  render(){
    return (
      <div>
        <h3>404 Strona nie istnieje</h3>
        <p>
          <ul>
            <li><span>Powrót do poprzedniej strony</span></li>
            <li><Link to={this.state.mainPage}>Powrót na stronę główną</Link></li>
          </ul>
        </p>
      </div>
    );
  }
}

export default NotFound;