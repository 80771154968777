const defaultState = {
  ability_to_work: [],
  accident_owth_insurance: [],
  accident_owtw_place: [],
  accident_cause: [],
  accident_consequences: [],
  accident_occurred_place: [],
  accident_place_type: [],
  protocol_accident_type: [],
  statistic_card_accident_type: [],
  activity: [],
  aknowledged_to_document_person: [],
  company_ownership_type: [],
  citizenship: [],
  employment_status: [],
  event_causing_injury: [],
  event_of_deviation_normal_state: [],
  hired_status: [],
  injury: [],
  localization_injury: [],
  material_factor_related_to_activity: [],
  osh_form: [],
  person_status: [],
  profession_code: [],
  sex: [],
  subcontractor_insurance: [],
  work_process: [],
};

let codeDictionariesv1 = [
  "accident_cause",
  "accident_consequences",
  "accident_occurred_place",
  "accident_place_type",
  "company_ownership_type",
  "citizenship",
  "employment_status",
  "event_causing_injury",
  "event_of_deviation_normal_state",

  "material_factor_related_to_activity",
  "statistic_card_accident_type",
  "subcontractor_insurance",
  "profession_codes",
  "work_process",
];

let codeDictionaries = ["activity", "localization_injury", "injury"];

export default function reducer(state = { ...defaultState }, action) {
  switch (action.type) {
    case "GET_DICTIONARY_SUCCESS": {
      let { data } = action.payload;
      let keys = Object.keys(data);

      data = Object.keys(data).reduce((obj, key) => {
        let indexv1 = codeDictionariesv1.findIndex((item) => item == key);
        if (indexv1 > -1) {
          obj[key] = dictionaryWithCodev1(obj[key]);
        }
        let index = codeDictionaries.findIndex((item) => item == key);
        if (index > -1) {
          obj[key] = dictionaryWithCode(obj[key]);
        }

        return obj;
      }, data);

      return { ...state, ...data };
    }
  }

  return state;
}
function dictionaryWithCodev1(data) {
  return data
    .map((item) => {
      return {
        ...item,
        name: `${item.code ? `${item.code} ` : ""}${item.name}`,
        disabled: item.code ? false : true,
      };
    })
    .sort((a, b) => {
      if (a.parent_id && b.parent_id) {
        //porównanie dzieci tych samych rodziców
        if (a.parent_id == b.parent_id) {
          return a.code > b.code ? 1 : -1;
        }
        //porównanie dzieci różnych rodziców
        else {
          return a.parent_id > b.parent_id ? 1 : -1;
        }
      }
      //porównanie rodziców
      if (!a.parent_id && !b.parent_id) {
        return a.id > b.id ? 1 : -1;
      }

      //sieroty - coś nie tak nie wiem coś z b?
      let isOrphan = (item) => {
        return !item.parent_id && item.code ? true : false;
      };
      if (isOrphan(a) || isOrphan(b)) {
        return isOrphan(a) ? 1 : -1;
      }

      //porównanie rodziców do losowych dzieci
      let aIsParent = !a.parent_id;
      if (aIsParent) {
        return a.id > b.parent_id ? 1 : -1;
      } else {
        return b.id > a.parent_id ? -1 : 1;
      }
    });
}
function dictionaryWithCode(data) {
  return data
    .map((item) => {
      return {
        ...item,
        name: `${item.code ? `${item.code} ` : ""}${item.name}`,
        disabled: item.code ? false : true,
      };
    })
    .sort((a, b) => {
      return a.no > b.no ? 1 : -1;
    });
}
