export default function reducer(
  state = {
    data: [],
    singleData: {},
    fetching: false,
    permission: {},
    globalFileFetching: false,
    fetched: true,
    printFetching: false,
  },
  action
) {
  switch (action.type) {
    case "GET_CHARACTERISTICS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_CHARACTERISTICS_SUCCESS": {
      const { characteristics, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: characteristics,
        permission,
      };
    }
    case "GET_CHARACTERISTICS_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_CHARACTERISTIC_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_CHARACTERISTIC_SUCCESS": {
      const { characteristic } = action.payload.data;

      return {
        ...state,
        singleData: characteristic,
        fetching: false,
        fetched: true,
      };
    }
    case "GET_CHARACTERISTIC_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }

    case "DELETE_CHARACTERISTIC_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "DELETE_CHARACTERISTIC_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data.filter(
          (item) => item.id != action.payload.data.characteristic.id
        ),
      };
    }
    case "DELETE_CHARACTERISTIC_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "ADD_CHARACTERISTIC_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "ADD_CHARACTERISTIC_SUCCESS": {
      return { ...state, fetching: false, fetched: true };
    }
    case "ADD_CHARACTERISTIC_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "DOWNLOAD_CHARACTERISTIC_REQUEST": {
      const { index } = action.payload.request.data;
      const newData = [...state.data];
      newData[index].fetching = true;
      return { ...state, data: newData, globalFileFetching: true };
    }
    case "DOWNLOAD_CHARACTERISTIC_SUCCESS": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newData = [...state.data];
      newData[index].fetching = false;
      return { ...state, data: newData, globalFileFetching: false };
    }
    case "DOWNLOAD_CHARACTERISTIC_ERROR": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newData = [...state.data];
      newData[index].fetching = false;
      return { ...state, data: newData, globalFileFetching: false };
    }
    case "EDIT_CHARACTERISTIC_REQUEST": {
      return { ...state, fetching: false, fetched: false };
    }
    case "EDIT_CHARACTERISTIC_SUCCESS": {
      return { ...state, fetching: false, fetched: false };
    }
    case "EDIT_CHARACTERISTIC_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "PRINT_CHARACTERISTICS_REGISTER_REQUEST": {
      return { ...state, printFetching: true };
    }
    case "PRINT_CHARACTERISTICS_REGISTER_SUCCESS": {
      return { ...state, printFetching: false };
    }
    case "PRINT_CHARACTERISTICS_REGISTER_ERROR": {
      return { ...state, printFetching: false };
    }
  }

  return state;
}
