export default function (data) {
  const formData = Object.keys(data).reduce((formData, key) => {
    let value = data[key];

    if (value) {
      let type = Object(value).constructor.name;
      if (type == "Object" || type == "Array") {
        value = JSON.stringify(value);
      }

      formData.append(key, value);
    }
    return formData;
  }, new FormData());

  return formData;
}
