import React from "react";
import { connect } from "react-redux";
import { getUserWorkplacesWithout } from "actions/workplaceActions";
import {
  getAuditNotification,
  registerNotifications,
  getAudit,
  registerNotification,
  deleteRegisteredNotification,
  addUserToRegisterGroup,
  deleteUserFromRegisterGroup,
  getHistory,
} from "actions/auditActions";
import { deleteNotification, localAddUser } from "actions/notificationActions";

import NoticicationComponent from "components/NotificationComponent";

import SideMenu from "./_SideMenu";
class AuditNotification extends NoticicationComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      dictionary_progress_open: 10,
      notification_group_id: 2,
      moduleListUrl: "/audit",
    };
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      audit: { singleData: auditData },
    } = this.props;
    const { dictionary_progress_id } = auditData;
    const { audit_id } = match.params;

    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());

    getAuditNotification(audit_id)(dispatch).then(this.handleServerResponse());

    getHistory(audit_id)(dispatch).then(this, this.handleServerResponse());

    if (auditData.id !== audit_id) {
      getAudit(audit_id)(dispatch).then(
        this.handleServerResponse({}, (action) => {
          const {
            audit: {
              singleData: { dictionary_progress_id },
            },
          } = this.props;
          this.setState({ dictionary_progress_id });
        })
      );
    } else {
      this.setState({ dictionary_progress_id });
    }
  }

  saveNotifications() {
    const { dispatch, match, history } = this.props;
    const { audit_id } = match.params;
    const result = this.prepareData();
    registerNotifications(
      audit_id,
      result
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/audit");
      })
    );
  }

  handleAddUserToRegisterGroup(notification_group_id, user_id, user) {
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    addUserToRegisterGroup(
      audit_id,
      user_id
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteUserFromRegisterGroup() {
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    deleteUserFromRegisterGroup(audit_id, {
      register_notification_group_has_users_id: this.state.modalUserDelete
        .entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  handleRegisterNotification(data) {
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    registerNotification(
      audit_id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  handleDeleteRegisteredNotification() {
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    const {
      modalNotificationDelete: { entry_id },
    } = this.state;
    deleteRegisteredNotification(audit_id, {
      register_notification_id: entry_id,
    })(dispatch).then(this.handleServerResponse());
  }

  renderSideMenu() {
    const {
      match,
      audit: { permission },
    } = this.props;
    const { audit_id } = match.params;
    return <SideMenu audit_id={audit_id} permission={permission} />;
  }
}

export default connect((store) => ({
  workplace: store.workplace,
  notification: store.notification,
  audit: store.audit,
}))(AuditNotification);
