import React from "react";
import {connect} from "react-redux";
import AppComponent from "../../components/AppComponent";
import ResetPasswordConfirmView from "../../components/ResetPasswordConfirmView";
import {checkResetPasswordToken,sendNewPassword} from "../../actions/accountPanelActions";

export class AccountResetPasswordConfirm extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServerResponse.bind(this);
  }

  componentDidMount(){
    const {dispatch,match} = this.props;
    const {token} = match.params;
    checkResetPasswordToken(token)(dispatch)
      .then(this.handleServerResponse());
  }

  handleSubmit(data){
    const {dispatch, history,match} = this.props;
    const {token} = match.params;
    sendNewPassword(token,data)(dispatch)
      .then(this.handleServerResponse({}, function () {
        history.push("/business/login");
      }));
  }

  render() {
    const {message} = this.state;
    return (<ResetPasswordConfirmView
      handleSubmit={this.handleSubmit}
      resetPasswordLink="/business/reset-password"
      alert={message}
    />);
  }
}

export default connect(store=>({
  account: store.account
}))(AccountResetPasswordConfirm);