import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Accordion from "./Accordion";
import { media } from "style/style-utils/index";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;  
  border-top: 1px solid ${(props) => props.theme.tableBorderColor};
  // border-bottom: 1px solid ${(props) => props.theme.tableBorderColor};

  & > div {
    width: 100%;
    &:nth-child(1) {
      height: 35px;
    }
  }
  & > .accordion {
    &:nth-child(2n) {
      background: #f9f9f9;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  // border-top: 1px solid ${(props) => props.theme.tableBorderColor};

  & > div:nth-child(2n) {
    background-color: #f9f9f9;
  }
`;

const StyledAccordionItemTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > h3 {
    margin: 0;
  }
`;

export const AccordionLineBody = styled.div`
  display:flex;
  flex-shrink: 1;
  font-size:12px;
  height: ${(props) => props.lineHeight};
  
  & i {
    font-size:14px;
  }
  
  & > div {
    padding: 8px;
    border-right: 1px solid ${(props) => props.theme.tableBorderColor};
    border-bottom: ${(props) =>
      props.header
        ? `2px solid ${props.theme.tableBorderColor}`
        : `1px solid ${props.theme.tableBorderColor}`};
    ${(props) => (props.header ? "font-weight:700" : "")};
  }
  & > div:nth-child(1){
    border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    border-right: none;
    flex-basis: 50px;
    box-sizing: border-box;
    flex-shrink: 1;

  }
  & > div:nth-child(2){
    flex-basis: ${(props) =>
      props.optionColumnWidth ? props.optionColumnWidth : "17%"});
    flex-shrink: 2;
    padding-left: ${(props) => (props.level ? props.level * 20 : 0)}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  & > div:nth-child(3){
    flex-basis: ${(props) =>
      props.optionColumnWidth ? props.optionColumnWidth : "17%"};
    ${media.custom({ minWidth: 768 })`
      white-space: nowrap;
    `}
    text-align: center;
    flex-shrink: 0;
  }
`;
AccordionLineBody.displayName = "TableLine";

export const AccordionLineNameColumn = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
AccordionLineNameColumn.displayName = "AccordionLineNameColumn";

export const AccordionLineOptionColumn = AccordionLineNameColumn.extend`
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 1;
  width: ${({ width }) => (width ? width : "auto")};
`;

export const AccordionButton = ({ handleChange, children, open }) => (
  <StyledAccordionItemTitle onClick={handleChange}>
    <i className={children ? `fa ${open ? "fa-minus" : "fa-plus"}` : ""} />
  </StyledAccordionItemTitle>
);

const AccordionLine = ({
  open,
  url,
  name,
  lineHeight,
  optionColumnWidth,
  optionColumn,
  level,
  handleChange,
  children,
  className,
}) => (
  <AccordionLineBody className={`accordion-item ${className}`} level={level}>
    <AccordionButton handleChange={handleChange} children={children} />
    <AccordionLineNameColumn>
      {url ? <Link to={url}>{name}</Link> : name}
    </AccordionLineNameColumn>
    <AccordionLineOptionColumn>
      {/*{optionColumn || optionColumn(item)}*/}
    </AccordionLineOptionColumn>
  </AccordionLineBody>
);
const StyledAccordionLine = styled(AccordionLine)`
  line-height: 25px;
`;

class AccordionList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, reverse: false };
  }

  render() {
    let { reverse } = this.state;
    let { data = [], header = [], LineComponent, sort } = this.props;
    return (
      <Wrapper>
        <LineComponent
          name={header[0]}
          no={header[1]}
          option_name={header[header.length - 1]}
          header={true}
        />
        {data.map((item, index) => (
          <StyledAccordion
            sort={sort}
            data={item}
            key={index}
            LineComponent={LineComponent || StyledAccordionLine}
          />
        ))}
      </Wrapper>
    );
  }
}

export default AccordionList;

AccordionButton.propType = {
  open: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  children: PropTypes.number,
};

AccordionList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.url,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.url,
        })
      ),
    })
  ),
  lineHeight: PropTypes.string,
  optionColumn: PropTypes.func,
  optionColumnWidth: PropTypes.string,
};
