export function addCharacteristic(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_CHARACTERISTIC_REQUEST",
        "ADD_CHARACTERISTIC_SUCCESS",
        "ADD_CHARACTERISTIC_ERROR",
      ],
      payload: {
        request: {
          url: "/characteristic/add",
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function editCharacteristic(entry_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_CHARACTERISTIC_REQUEST",
        "EDIT_CHARACTERISTIC_SUCCESS",
        "EDIT_CHARACTERISTIC_ERROR",
      ],
      payload: {
        request: {
          url: `/characteristic/edit/${entry_id}`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function archiveCharacteristic(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ARCHIVE_CHARACTERISTIC_REQUEST",
        "ARCHIVE_CHARACTERISTIC_SUCCESS",
        "ARCHIVE_CHARACTERISTIC_ERROR",
      ],
      payload: {
        request: {
          url: "/characteristic/archive/characteristic_id",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getCharacteristics() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_CHARACTERISTICS_REQUEST",
        "GET_CHARACTERISTICS_SUCCESS",
        "GET_CHARACTERISTICS_ERROR",
      ],
      payload: {
        request: {
          url: "/characteristic",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getCharacteristic(characteristic_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_CHARACTERISTIC_REQUEST",
        "GET_CHARACTERISTIC_SUCCESS",
        "GET_CHARACTERISTIC_ERROR",
      ],
      payload: {
        request: {
          url: `/characteristic/edit/${characteristic_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteCharacteristic(entry_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_CHARACTERISTIC_REQUEST",
        "DELETE_CHARACTERISTIC_SUCCESS",
        "DELETE_CHARACTERISTIC_ERROR",
      ],
      payload: {
        request: {
          url: `/characteristic/delete/${entry_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function downloadCharacteristic(url, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_CHARACTERISTIC_REQUEST",
        "DOWNLOAD_CHARACTERISTIC_SUCCESS",
        "DOWNLOAD_CHARACTERISTIC_ERROR",
      ],
      payload: {
        request: {
          url: url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            index,
          },
        },
      },
    });
}

export function getCharacteristicsByFilter(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_CHARACTERISTICS_REQUEST",
        "GET_CHARACTERISTICS_SUCCESS",
        "GET_CHARACTERISTICS_ERROR",
      ],
      payload: {
        request: {
          url: `/characteristic/`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function pingCharacteristic() {
  return (dispatch) =>
    dispatch({
      types: [
        "PING_CHARACTERISTICS_REQUEST",
        "PING_CHARACTERISTICS_SUCCESS",
        "PING_CHARACTERISTICS_ERROR",
      ],
      payload: {
        request: {
          url: "/characteristic/ping",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function printCharacteristicRegister(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "PRINT_CHARACTERISTICS_REGISTER_REQUEST",
        "PRINT_CHARACTERISTICS_REGISTER_SUCCESS",
        "PRINT_CHARACTERISTICS_REGISTER_ERROR",
      ],
      payload: {
        request: {
          url: "/characteristic/print/register",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            characteristic_ids: data.characteristic_ids,
          },
          responseType: "blob",
        },
      },
    });
}
