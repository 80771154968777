import React from "react";
import Button from "react-bootstrap/lib/Button";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldDayPicker, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";

let checkboxes = [
  {
    name: "section0",
    label: "Informacje ogólne",
  },
  {
    name: "section1",
    label: "Informacje ogólne  - informacje dodatkowe",
  },
  {
    name: "section2",
    label: "Charakterystyka ocenianego stanowiska pracy",
  },
  {
    name: "section3",
    label: "Identyfikacja zagrożeń",
  },
  {
    name: "section4",
    label: "Analiza ryzyka zawodowego",
  },
  {
    name: "section5",
    label: "Plan działań zmiejszających ryzyka zawodowe",
  },
  {
    name: "section6",
    label:
      "Pracownicy zatrudnieni na stanowisku - potwierdzenie zapoznania z oceną ryzyka zawodowego",
  },
];

export default class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    let { handleClose } = this.props;
    handleClose();
    // this.setState({ open: false });
  }

  // openModal() {
  //   this.setState({ open: true });
  // }

  render() {
    let { handleAccept } = this.props;

    return (
      <>
        <FormModal
          open={this.props.open}
          title="Pobierz"
          acceptButtonName="Wygeneruj PDF"
          cancelButtonName="Anuluj"
          handleSubmit={handleAccept}
          handleClose={this.closeModal}
        >
          <h5>Drukuj rejestr</h5>
          <LeftPadding>
            {checkboxes.map((item) => {
              return <FieldInputCheckBox name={item.name} label={item.label} />;
            })}
          </LeftPadding>
        </FormModal>
      </>
    );
  }
}
