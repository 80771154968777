import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CheckBoxLineStyle = styled.div`
  cursor: pointer;
  margin-bottom:5px

`;
CheckBoxLineStyle.displayName = "CheckBoxLineStyle";

const CheckBoxLineText = styled.span`
  padding-left:15px
`;

export const CheckBoxLine = props => (
  <CheckBoxLineStyle onClick={props.handleChange}>
    <label style={{display: "block", maxWidth: "auto", marginBottom: "0", fontWeight: "normal", cursor: "pointer"}}>
      <input value={props.value} type="checkbox" defaultChecked={props.checked}/>
      <CheckBoxLineText>{props.name}</CheckBoxLineText>
    </label>
  </CheckBoxLineStyle>
);

class CheckboxListOfGroups extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    const {handleChange} = this.props;
    return () => {
      handleChange(value);
    };
  }

  render() {
    const {data = [], handleChange} = this.props;
    return (<div>
      {data.map((item, index) => {
        return (
          <div key={index}>
            <h4>{item.title}</h4>
            {item.values.map((item, subindex) => (
              <CheckBoxLine
                key={subindex}
                name={item.name}
                value={item.id}
                handleChange={this.handleChange(item.id)}
                checked={item.value}/>))}
          </div>
        );
      })}
    </div>);
  }
}

export default CheckboxListOfGroups;

CheckBoxLine.propTypes = {
  name: PropTypes.string,
  value: PropTypes.node,
  handleChange: PropTypes.func.isRequired

};

CheckboxListOfGroups.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.node,
          value: PropTypes.bool,
          name: PropTypes.string
        })
      ).isRequired
    })
  ),
  handleChange: PropTypes.func.isRequired
};
