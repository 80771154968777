import React from "react";
import Core from "./_core";

class NotFoundBusiness extends Core {
  constructor(props){
    super(props);
    this.state = {...this.state,
      mainPage:"/dashboard"
    };
  }
}

export default NotFoundBusiness;