import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Form, Field} from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import {StyledInputBox} from "../style/styleComponents/Form/input";
import {PublicAuthView} from "../style/styleComponents/Wrappers";
import {LinkContainer} from "../components/LinkContainer";
import {media} from "../style/style-utils";
import validator from "./Validation/";

const LoginBoxFooter = styled.div`
  padding-top:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.xs`
    align-items: stretch;
    flex-direction: column;
    & > button {
      margin-bottom:10px;
    }
  `}
`;
LoginBoxFooter.displayName = "LoginBoxFooter";
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
export default class ResetPasswordConfirm extends React.Component {
  render() {
    const {handleSubmit,alert}= this.props;
    return (<PublicAuthView
      title={"Nowe hasło"}
      alert={alert}
    >
      <Form
        onSubmit={handleSubmit}
        validate={values => {
          const errors = {};
          if (values) {
            const repeatPassword =  validator.mustBeRepeatedPassword(values.password,values.repeatPassword);
            if(repeatPassword){
              errors.repeatPassword = repeatPassword;
            }
          }

          return Object.keys(errors).length ? errors : undefined;
        }}
        render={({handleSubmit, pristine, invalid}) => (
          <form onSubmit={handleSubmit}>
            <Field

              name="password"
              validate={composeValidators(validator.required,validator.mustBePassword)}
              render={({input, meta, ...rest}) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="Nowe hasło"
                  type="password"
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              )}
            />
            <Field
              name="repeatPassword"
              render={({input, meta, ...rest}) => (
                <StyledInputBox
                  {...input}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  label="Powtórz nowe hasło"
                  type="password"
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              )}
            />
            <LoginBoxFooter>
              <Button type="submit" bsStyle="primary">Zapisz</Button>

            </LoginBoxFooter>
          </form>
        )}
      />
    </PublicAuthView>);
  }
}

ResetPasswordConfirm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetPasswordLink: PropTypes.string.isRequired,
  alert: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  })
};