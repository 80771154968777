import React from "react";
import styled from "styled-components";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const getInitialTiles = function (props) {
  return props.selects && props.selects.length
    ? props.selects.map((obj) => obj.title)
    : ["select 1", "select 2"];
};

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  padding: 0 10px;

  & > div {
    width: calc(50% - 10px);
  }
`;

const StyledDropdownButton = styled(DropdownButton)`
  width: 100%;

  & div > button {
    width: 100% !important;
  }
`;
const ChartBox = styled.div`
  display: flex;
  justify-content: center;
`;

class SimpleBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: props.defaultSelected || [0, 0],
      titles: getInitialTiles(props),
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(eventKey) {
    const { select, titles } = this.state;
    select[eventKey[0]] = eventKey[1];
    titles[eventKey[0]] = this.props.selects[eventKey[0]].data[
      eventKey[2]
    ].name;
    this.props.selectFunction(select[0], select[1]);
    this.setState({ ...this.state, select: select, titles });
  }

  render() {
    const { data = [], selects = [], bars = {}, title = "" } = this.props;
    const { titles } = this.state;
    return (
      <div>
        <SelectWrapper>
          {selects.map((select, index) => (
            <StyledDropdownButton
              title={titles[index]}
              key={index}
              id={`bar-chart-${title}-${index}`}
            >
              {select.data.map((option, subindex) => {
                if (option.divider) {
                  return <MenuItem key={subindex} divider />;
                } else {
                  return (
                    <MenuItem
                      key={subindex}
                      eventKey={[index, option.value, subindex]}
                      onSelect={this.handleSelectChange}
                    >
                      {option.name}
                    </MenuItem>
                  );
                }
              })}
            </StyledDropdownButton>
          ))}
        </SelectWrapper>
        <ChartBox>
          <BarChart
            width={320}
            height={320}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {Object.keys(bars).map((key, index) => (
              <Bar dataKey={key} key={index} fill={bars[key]}></Bar>
            ))}
          </BarChart>
        </ChartBox>
      </div>
    );
  }
}

export default SimpleBarChart;

SimpleBarChart.propTypes = {
  selects: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          name: PropTypes.string,
        })
      ),
    })
  ),
  selectFunction: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  bars: PropTypes.object,
};
