import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import ModalSchema from "components/Modal/./_Schema";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";

import { getFullResidencePlace } from "container/Incident/AnalysisForm/common/index.js";
import { TwoInputWrapper } from "components/Wrappers";

import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInput,
} from "style/styleComponents/Form/input";

import {
  FieldSelectDitionary,
  FieldSelectDitionary33,
  FieldInput,
  FieldInput33,
  FieldTextareaBox,
  FieldInputCheckBox,
  FieldDayPicker,
} from "../Fields";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
  }

  componentDidMount() {}

  accept(values, callback) {
    const { accept = () => {}, closeModal, dispatch, incident_id } = this.props;
    accept(values, () => {
      closeModal();
    });
  }

  openAddModal() {
    let { openAddUser } = this.props;
    openAddUser();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    const {
      open,
      getUser,
      user,
      form,
      readOnly,
      title,
      dictionary = [],
      data = {},
    } = this.props;
    let { ...initialValues } = data;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={initialValues}
              onSubmit={this.accept}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Fragment>
                      <TwoInputWrapper>
                        <FieldInput
                          label={"Imię i nazwisko"}
                          name="full_name"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                        <FieldDayPicker
                          label={"Data urodzenia"}
                          name="birth_date"
                          validate={validator.required}
                          disabled={readOnly}
                        />

                        {/* <StyledInputBox value={user.birth_date} label="Data urodzenia" /> */}
                      </TwoInputWrapper>
                      <TwoInputWrapper>
                        <FieldInput
                          label={"Miejsce urodzenia"}
                          name="birth_place"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                        <FieldInput
                          name={"resident_fulladdress"}
                          label="Adres zamieszkania"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper style={{ alignItems: "flex-end" }}>
                        <FieldInput
                          name={"pesel"}
                          label="PESEL"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                        <FieldInput
                          name={"id_document_name"}
                          label="Rodzaj dokumentu potwierdzającego tożsamość"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper style={{ alignItems: "flex-end" }}>
                        <FieldInput
                          name={"id_document_series"}
                          label="Seria dokumentu potwierdzającego tożsamość"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                        <FieldInput
                          name={"id_document_number"}
                          label="Nr dokumentu potwierdzającego tożsamość"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <FieldSelectDitionary
                        name={"insurance_id"}
                        label={
                          "Tytuł ubezpieczenia wypadkowego zgodnie z art. 3 ust. 3 ustawy z dnia 30 października 2002 r. O ubezpieczeniu społecznym z tytułu wypadków przy pracy i chorób zawodowych"
                        }
                        validate={validator.required}
                        dictionary={dictionary}
                      />
                      <TwoInputWrapper>
                        <FieldInput
                          name={"insurance_title"}
                          label="Tytuł ubezpieczenia"
                          validate={validator.required}
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <ButtonWrapper>
                        <Button type="submit" bsStyle="primary">
                          Akceptuj
                        </Button>
                        <Button type="button" onClick={this.closeModal}>
                          Anuluj
                        </Button>
                      </ButtonWrapper>
                    </Fragment>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class VictimData extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    let { open } = this.state;
    let {
      accept,
      dictionary,
      data,
      buttonName = "Dane poszkodowanego",
    } = this.props;

    return (
      <>
        <TwoInputWrapper style={{ height: "39px" }}>
          <StyledInputBox disabled={true} value={data.full_name} />

          <Button type="button" onClick={this.openModal}>
            {buttonName}
          </Button>
        </TwoInputWrapper>
        <SimpleAcceptModal
          data={data}
          open={open}
          dictionary={dictionary}
          closeModal={this.closeModal}
          accept={accept}
        />
      </>
    );
  }
}
