import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import { bootstrapUtils } from "react-bootstrap/lib/utils/index";
import { media } from "../style/style-utils";
import Table from "react-bootstrap/lib/Table";

bootstrapUtils.addStyle(Button, "list-delete-button");

//@deprecate use react\src\components\Wrappers\index.js
const ButtonWrapperRaw = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -10px 20px;

  ${media.sm`
    flex-wrap:wrap;
  `}

  & > * {
    margin: 0 10px;
    ${media.xs`
      ${(props) =>
        props.nowrap
          ? ""
          : `width:100%; margin-bottom:10px !important;&:last-child {margin-bottom:0px;}`}
   
    `}
  }
`;
ButtonWrapperRaw.displayName = "ButtonWrapper";

export const ButtonWrapper = ButtonWrapperRaw;

export const ButtonWithIcon = (props) => {

  const bsStyle = [
    props.bsStyle ? props.bsStyle : null,
    props.listButton ? "list-delete-button" : null,
  ]
    .join(" ")
    .trim();

  return (
    <Button
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      className="btn-default"
      bsStyle={bsStyle}
    >
      <i className={props.icon}></i>
      {props.name && props.name.length ? (
        <span style={{ margin: "0 5px" }}>{props.name}</span>
      ) : (
        ""
      )}
    </Button>
  );
};

export const DeleteButton = (props) => {
  return (
    <ButtonWithIcon
      {...props}
      disabled={props.disabled}
      icon="fa fa-trash"
      listButton
      name="Usuń"
    />
  );
};

export const DownloadButton = (props) => {
  return (
    <ButtonWithIcon
      {...props}
      icon={
        props.fetching
          ? "fa fa-spinner fa-spin"
          : props.icon || "fa fa-cloud-download"
      }
    />
  );
};

export const LpTd = styled.td`
  width: 30px;
`;

const SpanAsLinkR = styled.span`
  cursor: pointer;
  color: #337ab7;
  &:hover {
    text-decoration: underline;
    color: #23527c;
  }
`;
SpanAsLinkR.displayName = "SpanAsLink";
export const SpanAsLink = SpanAsLinkR;

export const StyledTableWrapper = styled.div`
  font-size: 12px;
  padding: 0 1px;
  ${media.sm`
    overflow-x: auto;
  `}
`;

const quickMenuStyle = (props) => {
  const style = `
    width: 70px; 
    text-align: center;
    vertical-align: center;
  `;
  return props.quickMenu
    ? `
      ${
        props.quickMenuTrird
          ? `
        &:nth-last-child(3){
          ${style}
        }
      `
          : ""
      }
      &:nth-last-child(2){
        ${style}
      }
      &:nth-last-child(1){
        ${style}
      }
    `
    : "";
};

export const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  font-size: 12px;
  &.table {
  }
  &.table > thead > tr > th {
    border-left: 1px solid ${(props) => props.theme.tableBorderColor || "#ddd"};
    padding: 8px;
    box-sizing: border-box;
    vertical-align: top;
    &:first-child {
      ${(props) => (props.firstNarrowColumn ? `width: 30px;` : "")}
    }
    &:last-child {
      ${(props) => (props.lastCenter ? "text-align: center;" : "")}
    }
    ${(props) =>
      props.nowrapTitle
        ? `
      white-space: nowrap;
    `
        : ""}

    ${quickMenuStyle}
  }

  &.table tr {
    td {
      ${quickMenuStyle}
    }
  }

  &.table > tbody > tr {
    &:nth-child(2n + 1) {
      background: #f9f9f9;
    }

    & > td {
      vertical-align: middle;
      padding: 8px;
      &:first-child {
        ${(props) => (props.firstNarrowColumn ? `width: 30px;` : "")}
      }
      ${quickMenuStyle}
    }
    &:nth-child(1) {
      td {
        padding: 8px;
        box-sizing: border-box;
      }
    }
    &:nth-child(n + 3) {
      border-top: 1px solid ${(props) => props.theme.tableBorderColor || "#ddd"};
      border-left: 1px solid
        ${(props) => props.theme.tableBorderColor || "#ddd"};
      & > td {
        height: 33px;
        padding: 8px;
        box-sizing: border-box;

        &:nth-child(n + 2) {
          border-left: 1px solid ${(props) => props.theme.tableBorderColor};
        }
        &:last-child {
          border-right: 1px solid ${(props) => props.theme.tableBorderColor};
        }
      }
    }

    &:nth-child(n + 3) {
    }

    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

export const StyledTableList = styled(
  ({ className, children, ...restProps }) => (
    <StyledTable
      firstNarrowColumn
      lastCenter
      {...restProps}
      className={className}
    >
      {children}
    </StyledTable>
  )
)``;

export const StyledTableListWithSearch = styled(Table)`
  font-size: 12px;
  &.table {
  }
  & th {
    border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    padding: 5px;
    box-sizing: border-box;
  }
  & tr {
    &:nth-child(2n) {
      background: #f9f9f9;
    }
    &:nth-child(-n + 2) {
      border: none;
      background: inherit;
      &:hover {
        background: inherit;
      }
      & > td {
        border: none;
      }
    }
    & > td {
      & > * {
        // width: 100%;
      }
      &:last-child {
        width: 70px !important;
        text-align: center;
      }
    }
    &:nth-child(1) {
      td {
        padding: 8px;
        box-sizing: border-box;
        &:fist-child {
          & * {
            max-width: 48px;
          }
        }
      }
    }
    &:nth-child(3) {
      & > td {
        font-weight: 700;
        vertical-align: top;
      }
    }
    &:nth-child(n + 3) {
      border-top: 1px solid ${(props) => props.theme.tableBorderColor};
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
      & > td {
        height: 33px;
        padding: 8px;
        box-sizing: border-box;
        &:nth-child(1) {
          max-width: 30px;
          ${(props) =>
            props.firstColumnWidth ? `width:${props.firstColumnWidth}` : ""}
          text-align:center;
        }
        &:nth-child(n + 2) {
          border-left: 1px solid ${(props) => props.theme.tableBorderColor};
        }
        &:last-child {
          border-right: 1px solid ${(props) => props.theme.tableBorderColor};
        }
      }
    }

    &:nth-child(n + 4) {
      vertical-align: center;
    }
    &:nth-child(4) {
      & > td {
        border-top: 2px solid ${(props) => props.theme.tableBorderColor};
      }
    }
    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
  &.table > tbody > tr {
    &:nth-child(n + 4) {
      & > td {
        vertical-align: middle;
      }
    }
  }
`;

ButtonWithIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  listButton: PropTypes.bool,
};
