import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  getMEDraftList,
  deleteMEDraft,
  getMEDraftListFilter,
} from "actions/meActions";
import { getUsersLocalizations } from "actions/localizationActions";
import { getUserSectionsIndependently } from "actions/sectionActions";

import AppComponent from "components/AppComponent";
import { ButtonWrapper } from "components/Wrappers";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import LoadingScreen from "components/LoadingScreen";
import PageHeader from "components/PageHeader";
import { LayoutContent } from "components/Layout";
import { StyledTableWrapper } from "components/ListComponents";
import {
  ButtonWithIcon,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
import Pagination from "components/Pagination";
import { LinkContainer } from "components/LinkContainer";
import { TableSortHeader } from "components/TableSortable";
import ModalDelete from "components/Modal/SimpleAccept";

import { mockData } from "./ReferralList.mock";
import SideMenu from "./_SideMenu";
import { setUrls } from "./_staticData";
import { getDate } from "./_common";
import TableSortableWithPagination from "components/TableSortable/Register";

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "workplace_name", () => null);
  changeValue(state, "localization_id", () => "0");
  changeValue(state, "section_name", () => "0");
  changeValue(state, "date_add", () => null);
};
class ReferralList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      urls: setUrls(),
      modalDelete: this.initModalState(),
    };

    this.deleteEntry = this.deleteEntry.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    let { dispatch } = this.props;

    getMEDraftList()(dispatch).then(this.handleServerResponse({}, () => {}));
    getUsersLocalizations()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    this.updateMessage();
  }

  clearFilter(form) {
    let { dispatch } = this.props;
    this.form.mutators.clearFilterMutator();
    getMEDraftList()(dispatch).then(this.handleServerResponse());
  }

  deleteEntry() {
    let { dispatch } = this.props;
    let { entry_id } = this.state.modalDelete;
    deleteMEDraft(entry_id)(dispatch).then(this.handleServerResponse());
  }

  filter(data) {
    let { dispatch } = this.props;
    let sendData = this.filterZeroId(["localization_id", "section_name"])(data);
    getMEDraftListFilter(sendData)(dispatch).then(this.handleServerResponse());
  }

  handleFilterSubmit(data) {
    alert(`Wyszukiwanie w sytemie po danych ww${JSON.stringify()}`);
  }

  render() {
    let { data, urls, message } = this.state;
    let { localizations = [], sections = [], permission } = this.props;
    return (
      <>
        <LayoutContent message={message} SideMenu={<SideMenu />}>
          <PageHeader>Wzory skierowań na badania</PageHeader>
          <ButtonWrapper>
            <LinkContainer to={urls.MEREFERRALMODELADD}>
              <Button disabled={!permission[54]}>Dodaj wzór skierowania</Button>
            </LinkContainer>
          </ButtonWrapper>
          <TableSortableWithPagination
            data={this.props.data}
            headers={[
              { name: "Stanowisko pracy", prop: "workplace_name" },
              { name: "Lokacja", prop: "localization_id" },
              {
                name: "Dział",
                prop: "section_name",
              },
              { name: "Data wykonania", prop: "date_add" },
              { name: "Usuń" },
            ]}
            renderSearcher={
              <Form
                // ref={(form) => (this.form = form)}
                initialValues={{
                  localization_id: "0",
                  section_name: "0",
                }}
                mutators={{ clearFilterMutator }}
                onSubmit={this.filter}
                render={({ handleSubmit, pristine, invalid, form }) => {
                  this.form = form;
                  return (
                    <>
                      <tr>
                        <td style={{ minWidth: "120px" }}>
                          <FieldInput
                            name={"workplace_name"}
                            placeholder={"wg Stanowiska"}
                          />
                        </td>

                        <td style={{ minWidth: "120px" }}>
                          <FieldSelect
                            name={"localization_id"}
                            data={[{ id: "0", name: "Lokalizacja" }].concat(
                              localizations
                            )}
                          />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldSelect
                            name={"section_name"}
                            propValue="value"
                            data={[{ value: "0", name: "Dział" }].concat(
                              sections
                            )}
                          />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldDayPicker name="date_add" />
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan="5" style={{ padding: "8px 40px 8px 8px" }}>
                          <ButtonWrapper nowrap>
                            <Button
                              type="button"
                              onClick={() => {
                                handleSubmit();
                              }}
                              bsStyle="primary"
                            >
                              Szukaj
                            </Button>
                            <Button onClick={this.clearFilter}>
                              Wyłącz filtr
                            </Button>
                          </ButtonWrapper>
                        </td>
                      </tr>
                    </>
                  );
                }}
              />
            }
            render={(sortedData) => {
              return sortedData.map((item) => {
                return (
                  <tr>
                    <td>
                      <Link to={urls.MEREFERRALMODELEDIT(item.id)}>
                        {item.workplace_name}
                      </Link>
                    </td>
                    <td>{item.localization_name}</td>
                    <td>{item.section_name}</td>
                    <td>{getDate(item.date_add)}</td>
                    <td>
                      <ButtonWithIcon
                        disabled={!permission[58]}
                        icon="fa fa-trash"
                        onClick={this.openModal("modalDelete", {
                          entry_id: item.id,
                        })}
                      >
                        Usuń
                      </ButtonWithIcon>
                    </td>
                  </tr>
                );
              });
            }}
          />
        </LayoutContent>
        <ModalDelete
          title={"Usuń wzór skierowania"}
          description="Czy chcesz usunąć dany wpis?"
          open={this.state.modalDelete.open}
          handleAccept={this.deleteEntry}
          handleClose={this.closeModal("modalDelete")}
        />
      </>
    );
  }
}

export default connect((store) => ({
  permission: store.me.permission,
  data: store.me.drafts,
  localizations: store.localization.data,
  sections: store.section.data,
  message: store.message,
}))(ReferralList);
