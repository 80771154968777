import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import { StyledInputBox } from "../style/styleComponents/Form/input";
import { PublicAuthView } from "../style/styleComponents/Wrappers";
import { LinkContainer } from "../components/LinkContainer";
import { media } from "../style/style-utils";

import validator from "./Validation/";
const LoginBoxFooter = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.xs`
    align-items: stretch;
    flex-direction: column;
    & > button {
      margin-bottom:10px;
    }
  `}
`;

LoginBoxFooter.displayName = "LoginBoxFooter";

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export default class LoginView extends React.Component {
  render() {
    const { handleSubmit, resetPasswordLink, alert } = this.props;
    return (
      <PublicAuthView title={"Logowanie"} alert={alert}>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                validate={composeValidators(
                  validator.required,
                  validator.mustBeEmail
                )}
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    label="E-mail"
                    type="email"
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}
              />
              <Field
                name="password"
                validate={composeValidators(
                  validator.required,
                  validator.mustBePassword
                )}
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    label="Hasło"
                    type="password"
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}
              />
              <LoginBoxFooter>
                <Button type="submit" bsStyle="primary">
                  Zaloguj się
                </Button>
                <LinkContainer to={resetPasswordLink}>
                  <Button>Zapomniałem hasła</Button>
                </LinkContainer>
              </LoginBoxFooter>
            </form>
          )}
        />
      </PublicAuthView>
    );
  }
}

LoginView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetPasswordLink: PropTypes.string.isRequired,
  alert: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
  }),
};
