import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AutoComplete from "components/AutoCompleteInput";
import ModalSchema from "components/Modal/./_Schema";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";

import { getFullResidencePlace } from "../common/index.js";
import { SpaceBlock, TwoInputWrapper } from "../AnalysisForm.style";
import { saveIncidentPerson } from "actions/incidentActions";
import {
  FieldSelectDitionary,
  FieldSelectDitionary33,
  FieldInput,
  FieldInput33,
  FieldTextareaBox,
  FieldInputCheckBox,
  FieldDayPicker,
} from "../Fields";
import { ButtonWrapper } from "components/Wrappers";
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";

// let FormChangeInputBox = (props) => {
//   let
//   <StyledInputBox
//     value={`${user.first_name || ""} ${user.last_name || ""}`}
//     label="Imię i nazwisko"
//     onChange={(value) => {

//     }}
//   />;
// };

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
  }

  componentDidMount() {}

  accept(values) {
    const { accept = () => {}, closeModal, dispatch, incident_id } = this.props;
    let {
      full_name,
      birth_date,
      birth_place,
      resident_fulladdress,
      pesel,
      id_document_name,
      id_document_number,
      id_document_series,
      subcontractor_insurance_id,
    } = values;

    let data = {
      full_name,
      birth_date,
      birth_place,
      resident_fulladdress,
      pesel,
      id_document_name,
      id_document_number,
      id_document_series,
      subcontractor_insurance_id,
    };

    saveIncidentPerson(incident_id, data)(dispatch).then();
    // accept(values);
    closeModal();
  }

  openAddModal() {
    let { openAddUser } = this.props;
    openAddUser();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    const {
      data,
      handleAccept,
      open,
      title,
      getUser,
      user,
      defaultUser,
      form,
      readOnly,
      dictionary = [],
      incident: { singleData: incidentData },
    } = this.props;
    let person = incidentData.incident_has_person || {};
    let { user: AAAAAA, ...initialValues } = person;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={initialValues}
              onSubmit={this.accept}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Fragment>
                      <TwoInputWrapper>
                        <FieldInput
                          label={"Imię i nazwisko"}
                          name="full_name"
                          disabled={readOnly}
                        />
                        <FieldDayPicker
                          label={"Data urodzenia"}
                          name="birth_date"
                          disabled={readOnly}
                        />

                        {/* <StyledInputBox value={user.birth_date} label="Data urodzenia" /> */}
                      </TwoInputWrapper>
                      <TwoInputWrapper>
                        <FieldInput
                          label={"Miejsce urodzenia"}
                          name="birth_place"
                          disabled={readOnly}
                        />
                        <FieldInput
                          name={"resident_fulladdress"}
                          label="Adres zamieszkania"
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper style={{ alignItems: "flex-end" }}>
                        <FieldInput
                          name={"pesel"}
                          label="PESEL"
                          disabled={readOnly}
                        />
                        <FieldInput
                          name={"id_document_name"}
                          label="Rodzaj dokumentu potwierdzającego tożsamość"
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <TwoInputWrapper style={{ alignItems: "flex-end" }}>
                        <FieldInput
                          name={"id_document_series"}
                          label="Seria dokumentu potwierdzającego tożsamość"
                          disabled={readOnly}
                        />
                        <FieldInput
                          name={"id_document_number"}
                          label="Nr dokumentu potwierdzającego tożsamość"
                          disabled={readOnly}
                        />
                      </TwoInputWrapper>
                      <FieldSelectDitionary
                        name={"subcontractor_insurance_id"}
                        label={
                          "Tytuł ubezpieczenia wypadkowego zgodnie z art. 3 ust. 3 ustawy z dnia 30 października 2002 r. O ubezpieczeniu społecznym z tytułu wypadków przy pracy i chorób zawodowych"
                        }
                        dictionary={dictionary}
                      />
                      <ButtonWrapper>
                        <Button type="submit" bsStyle="primary">
                          Zapisz
                        </Button>
                        <Button type="button" onClick={this.closeModal}>
                          Anuluj
                        </Button>
                      </ButtonWrapper>
                    </Fragment>
                  </form>
                );
              }}
            />

            {/*  */}
          </Modal.Body>
          <Modal.Footer>
            <TwoInputWrapper>
              <div>
                {/* <Fragment>
                  <Button type="submit" bsStyle="primary">
                    Zapisz
                  </Button>
                  <Button type="button" onClick={this.closeModal}>
                    Anuluj
                  </Button>
                </Fragment> */}
              </div>
            </TwoInputWrapper>
          </Modal.Footer>
        </StyledModal>
      </div>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
}))(SimpleAcceptModal);

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
