import React, { Fragment } from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import { Field } from "react-final-form";

import AppComponent from "components/AppComponent";
import { StyledTable } from "components/ListComponents";
import {
  StyledInputBox,
  StyledLabel,
  StyledInputMessage,
} from "style/styleComponents/Form/input";
import ModalSchema from "components/Modal/_SchemaAppComponent";
import { getDictionaryProfessionCode } from "actions/dictionaryActions";
let minCodeReserch = 3;
let maxCodesToSearch = 20;
const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  max-height: & > * {
    margin: 0 10px;
  }
`;
const ModalTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

let InputWrapper = styled.div`
  display: flex;
  margin 10px -5px 10px;
  & > * {
    display: block;
    margin: 0 5px;
    &:nth-child(1){
      width: 75px;
      flex: 0 0 75px;
      /* text-align: center; */
    }
    &:nth-child(2){
      flex: 1 1 100%;
    }
     &:nth-child(3){
      flex: 0 0 auto;
    }
   
  }
 
`;

let OverflowScroll = styled.div`
  overflow: scroll;
  max-height: 75%;
`;
let TR = styled.tr`
  & > td {
    font-weight: ${(props) => (props.disabled ? 700 : 400)};
    &:hover {
    }
  }
`;

function arraydiff(first, second) {
  if (!Array.isArray(first)) return true;

  let difference = first.filter((x) => second.indexOf(x) === -1);
  let difference2 = second.filter((x) => first.indexOf(x) === -1);

  return difference.length || difference2.length ? true : false;
}

class ProfessionCodeInput extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getProfessionCode = this.getProfessionCode.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  getProfessionCode(code, filter) {
    let { dispatch } = this.props;
    getDictionaryProfessionCode(code, filter)(dispatch);
  }

  render() {
    let { codes, input, meta, field, disabled, label } = this.props;
    let { value } = input;
    let chosesItem =
      codes.find((item) => {
        return item.id == value;
      }) || {};
    let { code, name } = chosesItem;

    return (
      <Fragment>
        <StyledLabel>{label}</StyledLabel>
        <InputWrapper>
          <StyledInputBox
            placeholder={"Kod"}
            disabled={true}
            value={code}
            centerValue
          />
          <StyledInputBox placeholder="Opis" disabled={true} value={name} />
          <Button onClick={this.openModal} disabled={disabled}>
            Wybierz
          </Button>
        </InputWrapper>
        {!disabled && (
          <SimpleAcceptModal
            getProfessionCode={this.getProfessionCode}
            open={this.state.open}
            handleClose={this.closeModal}
            accept={(value) => {
              input.onChange(value);
            }}
            data={codes}
          />
        )}
        <StyledInputMessage>
          {meta.error && meta.touched ? meta.error : null}
        </StyledInputMessage>
      </Fragment>
    );
  }
}

let leTimer;

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      code: "",
    };
    this.accept = this.accept.bind(this);
    this.filterData = this.filterData.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
  }

  accept() {
    const {
      handleAccept = () => {},
      handleClose,
      autoAcceptClose = true,
    } = this.props;
    handleAccept();
    autoAcceptClose && handleClose();
  }

  componentDidUpdate(prevProps, prevState) {
    let { getProfessionCode } = this.props;
    if (
      (this.state.codes &&
        this.state.codes.length &&
        this.state.codes.length <= maxCodesToSearch &&
        arraydiff(prevState.codes, this.state.codes)) ||
      prevState.filter != this.state.filter
    ) {
      getProfessionCode(this.state.codes, this.state.filter);
    }
  }

  inputOnChange(event) {
    let filter = event.target.value;

    clearTimeout(leTimer);
    leTimer = setTimeout(() => {
      let { data } = this.props;

      let regex = new RegExp(filter, "i");

      let codes = data
        .filter((item) => {
          let { code, name } = item;
          return (
            (regex.test(code) || regex.test(name)) &&
            code.length >= minCodeReserch
          );
        })
        .map((item) => {
          return item.code.slice(0, minCodeReserch);
        });

      codes = [...new Set(codes)];

      let code;
      if (codes.length == 1) {
        code = codes[0];
      }

      this.setState({
        filter,
        codes,
      });
    }, 500);

    // }
  }

  filterData(data) {
    let { filter } = this.state;
    if (!filter || !filter.length) {
      return data;
    } else {
      let regex = new RegExp(filter, "i");

      return data.filter((item) => {
        let { code, name } = item;
        return regex.test(code) || regex.test(name);
      });
    }
  }

  render() {
    const { open, title, description, accept, handleClose, data } = this.props;
    let filtredData = this.filterData(data);
    return (
      <div className="static-modal">
        <Modal show={open} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitle>{title}</ModalTitle>
              <ButtonWrapper>
                <Button onClick={handleClose}>
                  <i className="fa fa-times" /> Zamknij
                </Button>
              </ButtonWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <StyledInputBox onChange={this.inputOnChange} />
            <OverflowScroll>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Kod</th>
                    <th>Opis</th>
                  </tr>
                </thead>
                <tbody>
                  {filtredData.map((item, index) => {
                    let disabled = item.code.length < 6;
                    return (
                      <TR
                        disabled={disabled}
                        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
                        key={index}
                        onClick={() => {
                          if (!disabled) {
                            accept(item.id);
                            handleClose();
                          }
                        }}
                      >
                        <td>{item.code}</td>
                        <td>{item.name}</td>
                      </TR>
                    );
                  })}
                </tbody>
              </StyledTable>
            </OverflowScroll>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button onClick={() => {}} bsStyle="primary">
              Zapisz
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default connect((store) => ({
  dictionary: store.dictionary,
}))(ProfessionCodeInput);
