import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import { Link } from "react-router-dom";
import { LayoutContent } from "../../components/Layout";
import Pagination from "../../components/Pagination";

import Select from "../../components/Select";
import Table from "react-bootstrap/lib/Table";
import Modal from "../../components/Modal/Simple";
import Alert from "react-bootstrap/lib/Alert";
import { SpanAsLink } from "../../components/ListComponents";
import PageHeader from "../../components/PageHeader";
import {
  getResponsibilities,
  assignUser,
} from "../../actions/responsibilityActions";
import { getWorkplacesWithout } from "../../actions/workplaceActions";
import { getUserByWorkplace } from "../../actions/userActions";
import LoadingScreen from "../../components/LoadingScreen/";
import { StyledTable } from "../../components/ListComponents";
import { TableSortableWithPagination } from "components/TableSortableWithPagination";
const PersonWrapper = styled(SpanAsLink)``;
PersonWrapper.displayName = "PersonWrapper";
const SelectWrapper = styled.div`
  margin-bottom: 15px;
`;

const workplacesR = [
  {
    value: 1,
    name: "Kierownik pierwszy",
    children: [
      {
        value: 2,
        name: "Starszy elektryk",
        children: [{ value: 3, name: "Elektryk" }],
      },
      { value: 4, name: "Budowlaniec" },
    ],
  },
  { value: 5, name: "Inspektor" },
  { value: 6, name: "Ekspert" },
];

const usersRaw = [
  { value: 1, name: "Jan Kowalski" },
  { value: 2, name: "Andrzej Nowak" },
];

class SingleLocationResponsibilityList extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: [],
      modal: {
        open: false,
        selectedWorkplace: null,
        selectedUser: null,
      },
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSelectWorkplace = this.handleSelectWorkplace.bind(this);
    this.handleUserChoose = this.handleUserChoose.bind(this);
    this.handleUserAssign = this.handleUserAssign.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    getResponsibilities(localization_id)(dispatch).then(
      this.handleServerResponse()
    );
    getWorkplacesWithout(null)(this.props.dispatch).then(
      this.handleServerResponse()
    );
  }

  handleOpenModal(responsibility_id) {
    return (workplace_id, user_id) => {
      const that = this;
      return () => {
        that.setState(
          {
            modal: {
              ...this.state.modal,
              open: true,
              selectedWorkplace: workplace_id || null,
              selectedUser: user_id || null,
              responsibility_id: responsibility_id,
            },
          },
          () => {
            getUserByWorkplace(workplace_id)(this.props.dispatch).then(
              this.handleServerResponse()
            );
          }
        );
      };
    };
  }

  handleCloseModal() {
    this.setState(
      {
        modal: {
          ...this.state.modal,
          open: false,
          selected: null,
          responsibility_id: null,
        },
      },
      () => {}
    );
  }

  handlePageChange(data) {
    this.setState({ data: data });
  }

  handleAccept(user_id) {
    this.handleCloseModal();
  }

  handleSelectWorkplace(value) {
    const userFetching = value != "0";
    let mutator = userFetching ? {} : { selectedUser: null };
    this.setState(
      { modal: { ...this.state.modal, selectedWorkplace: value, ...mutator } },
      () => {
        if (userFetching) {
          getUserByWorkplace(value)(this.props.dispatch).then(
            this.handleServerResponse()
          );
        }
      }
    );
  }

  handleUserAssign() {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    const { responsibility_id, selectedUser: user_id } = this.state.modal;
    const trueUserId = user_id != "0" ? user_id : null;
    assignUser(
      localization_id,
      responsibility_id,
      trueUserId
    )(dispatch).then(this.handleServerResponse());
  }

  handleUserChoose(value) {
    this.setState({ modal: { ...this.state.modal, selectedUser: value } });
  }

  render() {
    const {
      responsibility: {
        data: responsibilityData,
        fetched: responsibilityFetched,
      },
      workplace: { data: workplaceData, fetched: workplaceFetched },
      users: { data: userData },
    } = this.props;
    const { modal, data, message } = this.state;
    const { selectedWorkplace } = modal;
    const users = [{ id: "0", name: "Brak" }].concat(userData);

    return (
      <LoadingScreen fetched={responsibilityFetched && workplaceFetched}>
        <LayoutContent>
          {message && message.text && (
            <Alert bsStyle={message.className}>{message.text}</Alert>
          )}
          <PageHeader>Lista odpowiedzialności</PageHeader>
          <TableSortableWithPagination
            headers={[
              { name: "Odpowiedzialności", prop: "name", sort_type: "string" },
              {
                name: "Osoba odpowiedzialna",
                prop: "user_name",
                sort_type: "string",
              },
            ]}
            data={responsibilityData}
          >
            {(data) => {
              if (!data) return false;
              return data.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>
                    <PersonWrapper
                      onClick={this.handleOpenModal(item.id)(
                        item.users_workplace_id,
                        item.user_id
                      )}
                    >
                      {item.user_name ? (
                        item.user_name
                      ) : (
                        <i
                          className="fa fa-times"
                          style={{ color: "red", fontSize: "24px" }}
                        />
                      )}
                    </PersonWrapper>
                  </td>
                </tr>
              ));
            }}
          </TableSortableWithPagination>

          <Modal
            handleAccept={this.handleUserAssign}
            handleClose={this.handleCloseModal}
            title="Osoba odpowiedzialna"
            open={modal.open}
          >
            <SelectWrapper>
              <Select
                fullWidth
                title="Stanowisko"
                selected={modal.selectedWorkplace}
                data={
                  workplaceData && workplaceData.length
                    ? [{ id: "0", name: "Brak" }].concat(workplaceData)
                    : [
                        {
                          id: null,
                          name: "Nie znaleziono stanowiska do wyboru",
                          disabled: true,
                        },
                      ]
                }
                propValue={"id"}
                handleChange={this.handleSelectWorkplace}
              />
            </SelectWrapper>
            {selectedWorkplace && selectedWorkplace != "0" && (
              <Select
                fullWidth
                selected={modal.selectedUser}
                propValue={"id"}
                handleChange={this.handleUserChoose}
                title="Osoba"
                data={users}
              />
            )}
          </Modal>
        </LayoutContent>
      </LoadingScreen>
    );
  }
}

export default connect((store) => ({
  responsibility: store.responsibility,
  users: store.users,
  workplace: store.workplace,
}))(SingleLocationResponsibilityList);
