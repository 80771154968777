import React from "react";
import styled from "styled-components";
import SlideToggle from "react-slide-toggle";

let TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:nth-child(1) {
    flex: 1 1 auto;
  }
  &:nth-child(2) {
    flex: 0 0 50px;
  }
`;

let ToggleButton = function (props) {
  return (
    <div onClick={props.onClick}>
      <i
        className={`fa fa-arrow-${props.open ? "up" : "down"} ${
          props.className
        }`}
      />
    </div>
  );
};
let ToggleButtonStyled = styled(ToggleButton)`
  font-size: 32px !important;

  padding: 20px 10px 0px 0px;
  cursor: pointer;
`;

let ToggleWrapper = styled.div`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "30px"};
`;

export default class ToggleSlider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    let { title, children, marginBottom } = this.props;
    return (
      <ToggleWrapper marginBottom={marginBottom}>
        <SlideToggle
          collapsed={true}
          duration={this.props.duration}
          render={({ toggle, toggleState, setCollapsibleElement }) => {
            let { open } = this.state;
            let style =
              toggleState == "EXPANDED"
                ? { overflow: "visible" }
                : { overflow: "hidden" };

            return (
              <>
                {open}
                <div className="my-collapsible">
                  <TitleBox>
                    <h3>{title}</h3>
                    <ToggleButtonStyled
                      open={open}
                      onClick={() => {
                        this.setState({ open: !this.state.open });
                        toggle();
                      }}
                    />
                  </TitleBox>

                  <div
                    className="my-collapsible__content"
                    ref={setCollapsibleElement}
                    style={style}
                  >
                    <div className="my-collapsible__content-inner">
                      {children}
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        />
      </ToggleWrapper>
    );
  }
}
