import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";
import { ButtonWrapper } from "components/Wrappers";
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TDPointer = styled.td`
  cursor: pointer;
`;

class GetDraftModal extends ModalSchema {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      visibleData: [],
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setState({ visibleData: this.props.modelHazardList });
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  componentDidUpdate(prevProps, prevState) {
    let { visibleData, filter } = this.state;
    let { workplaces } = this.props;

    if (workplaces && !prevProps.workplaces.length) {
      this.setState({ visibleData: this.props.workplaces });
    }

    if (filter !== prevState.filter) {
      let regexp = new RegExp(filter, "i");

      let filteredData = this.props.modelHazardList.filter((i) => {
        return regexp.test(i.name);
      });
      this.setState({
        visibleData: filteredData,
      });
    }
  }

  render() {
    let { visibleData } = this.state;
    let {
      open,
      modelHazardList = [],
      setHazard,
      form: parentForm,
    } = this.props;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Wybierz zagrożenie z bazy</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit }) => {
                return (
                  <form>
                    <FieldInput
                      name="filter"
                      placeholder="Szukaj..."
                      onChange={(value) => {
                        this.setState({ filter: value });
                      }}
                    />
                    <TableListPage>
                      <thead>
                        <th>Nazwa</th>
                      </thead>
                      <tbody>
                        {visibleData.map((item) => (
                          <tr>
                            <TDPointer
                              onClick={() => {
                                setHazard(item, parentForm)();
                                this.closeModal();
                              }}
                            >
                              {item.name}
                            </TDPointer>
                          </tr>
                        ))}
                      </tbody>
                    </TableListPage>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class GetDraft extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
  }

  render() {
    let { open } = this.state;

    return (
      <>
        <Button
          style={{ height: "34px" }}
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Wybierz z bazy
        </Button>
        <GetDraftModal
          {...this.props}
          open={open}
          closeModal={() => {
            this.setState({ open: false });
          }}
        />
      </>
    );
  }
}
