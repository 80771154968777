import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import ModalSchema from "./_Schema"
import styled from "styled-components";

const ModalTitle = styled.div`
  overflow:hidden;
  text-overflow: ellipsis;
`

export default class SimpleAcceptModal extends ModalSchema {

  constructor(props){
    super(props);

    this.accept = this.accept.bind(this);
  }

  accept(){
    const {handleAccept = ()=>{},handleClose,autoAcceptClose = true} = this.props;
    handleAccept();
    autoAcceptClose && handleClose();
  }

  render(){
    const {open,title,description,accept} = this.props;
    return (
      <div className="static-modal">
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title><ModalTitle>{title}</ModalTitle></Modal.Title>
          </Modal.Header>

          <Modal.Body>{description}</Modal.Body>

          <Modal.Footer>
            <Button onClick={this.accept} bsStyle="primary">Tak</Button>
            <Button onClick={this.handleClose}>Nie</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleAccept:PropTypes.func.isRequired,
  handleClose:PropTypes.func.isRequired
};