export const defaultUrls = {
  DRAFTADD: `/risk-assessment/template-doc/basic`,
  DRAFTBASIC: (id) => `/risk-assessment/template-doc/basic/${id}`,
  DRAFTDANGER: (id) => `/risk-assessment/template-doc/danger/${id}`,
  DRAFTCHARACTERISTIC: (id) =>
    `/risk-assessment/template-doc/characteristic/${id}`,
  DRAFTANALYSIS: (id) => `/risk-assessment/template-doc/analysis/${id}`,
  ADD: `/risk-assessment/template-doc/basic`,
  BASIC: (id) => `/risk-assessment/template-doc/basic/${id}`,
  DANGER: (id) => `/risk-assessment/template-doc/danger/${id}`,
  CHARACTERISTIC: (id) => `/risk-assessment/template-doc/characteristic/${id}`,
  ANALYSIS: (id) => `/risk-assessment/template-doc/analysis/${id}`,
  TASK: (id) => `/risk-assessment/template-doc/task-decrease-risk/${id}`,

  // ADD: `/risk-assessment/basic`,
  // BASIC: (id) => `/risk-assessment/basic/${id}`,
  // CHARACTERISTIC: (id) => `/risk-assessment/characteristic/${id}`,
  // DANGER: (id) => `/risk-assessment/danger/${id}`,
  // ANALYSIS: (id) => `/risk-assessment/analysis/${id}`,
  // TASK: (id) => `/risk-assessment/task-decrease-risk/${id}`,
  TASKENTRY: (id, ra_ah_tme_id, task_id) =>
    task_id
      ? `/risk-assessment/task-decrease-risk/${id}/task/${ra_ah_tme_id}/${task_id}`
      : `/risk-assessment/task-decrease-risk/${id}/task/${ra_ah_tme_id}`,
  NOTIFICATION: (id) => `/risk-assessment/notification/${id}`,
  UPDATE: (id) => `/risk-assessment/update/${id}`,
  LIST: `/risk-assessment`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
