import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import { DeleteButton } from "components/Buttons";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import ModalSchema from "components/Modal/./_Schema";
import {
  FieldInput,
  //   FieldInputCheckBox,
  //   FieldInputCheckBoxRadio,
  //   FieldTextareaBox,
} from "components/Fields";
import { TableListPage } from "components/Tables";
import {
  ButtonWrapper,
  LeftPadding,
  StyledInputCheckBoxWrapper,
  TwoInputWrapper,
} from "components/Wrappers";
import QuestionsForm from "components/QuestionsForm/Form";

import { StyledLabel } from "style/styleComponents/Form/input";

const StyledModal = styled(Modal)`
  width: "800px !important";
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

let mockData = [
  {
    fullname: "Jan Kowalski",
    have_account: true,
  },
  {
    fullname: "Jan Kowalski",
    have_account: false,
  },
  {
    fullname: "Jan Kowalski",
    have_account: true,
  },
  {
    fullname: "Jan Kowalski",
    have_account: false,
  },
  {
    fullname: "Jan Kowalski",
    have_account: false,
  },
];

class ModalComponent extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open } = this.props;
    // let data = {
    //   first_name: "Jan",
    //   last_name: "Kowalski",
    //   workplace_name: "Elektryk",
    //   date_initial: "2020-02-20",
    //   date_execution: "2020-02-20",
    //   ability_to_work_name: "ddd",
    //   date_next: "2020-02-20",
    //   additiona_description: "",
    // };

    let {
      getUsers = () => {
        alert("mock");
      },
      addUser,
      deleteUser = () => {
        alert("mock fn");
      },
      data = [],
    } = this.props;

    return (
      <div className="static-modal" style={{ width: "1000px" }}>
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Pracownicy zatrudnieni na stanowisku</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Form
            onSubmit={() => {}}
            render={({ handleSubmit }) => {
              return (
                <>
                  <Modal.Body>
                    <ButtonWrapper>
                      <Button onClick={getUsers}>
                        Pobierz listę zatrudnionych na stanowisku
                      </Button>
                      <Button onClick={addUser}>Dodaj pracownika</Button>
                    </ButtonWrapper>
                    <TableListPage>
                      <thead>
                        <th>L.p</th>
                        <th>Imie i nazwisko</th>
                        <th>Konto w systemie</th>
                        <th>Usuń</th>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>
                                {item.have_account && <i class="fa fa-check" />}
                              </td>
                              <td>
                                <DeleteButton
                                  onClick={() => {
                                    deleteUser(item.id);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </TableListPage>
                  </Modal.Body>
                </>
              );
            }}
          />
        </StyledModal>
      </div>
    );
  }
}

class ModalGetComponent extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open } = this.props;
    let mockData = [
      { name: "Kierowca" },
      { name: "Spawacz" },
      { name: "Księgowa" },
    ];

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Wybierz stanowisko pracy</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit }) => {
                return (
                  <form>
                    <FieldInput name="filter" placeholder="Szukaj..." />
                    <TableListPage>
                      <thead>
                        <th>Nazwa</th>
                      </thead>
                      <tbody>
                        {mockData.map((item) => (
                          <tr>
                            <td>aaaa</td>
                          </tr>
                        ))}
                      </tbody>
                    </TableListPage>
                    <ButtonWrapper>
                      <Button>Zapisz</Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

class ModalAddComponent extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  addUser(data) {
    let { addUser } = this.props;
    addUser(data, () => {
      this.closeModal();
    });
  }

  render() {
    let {
      open,
      addUser = () => {
        alert("mock fn");
      },
    } = this.props;

    return (
      <div className="static-modal">
        <StyledModal
          show={open}
          onHide={() => {
            this.closeModal();
            this.props.openListModal();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Dodaj pracownika</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={this.addUser}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FieldInput name="name" label="Imię i nazwisko" />

                    <ButtonWrapper>
                      <Button type="submit">Dodaj</Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class ModalContainer extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      main: {
        open: false,
      },
      add: {
        open: false,
      },
      get: {
        open: false,
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addUser = this.addUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  openModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: true } });
    };
  }

  closeModal(name) {
    return () => {
      this.setState({ [name]: { ...this.state[name], open: false } });
    };
  }

  addUser() {
    this.setState({
      main: { ...this.state.main, open: false },
      add: { ...this.state.main, open: true },
      get: { ...this.state.main, open: false },
    });
  }

  getUsers() {
    let { addRAWorkersByWorkplace, workplace_id } = this.props;

    addRAWorkersByWorkplace(workplace_id);
    // this.setState({
    //   main: { ...this.state.main, open: false },
    //   add: { ...this.state.main, open: false },
    //   get: { ...this.state.main, open: true },
    // });
  }

  render() {
    let {
      addWorker = () => {
        alert("mock");
      },
      deleteWorker = () => {
        alert("mock");
      },

      data = [],
      workplace_id,
    } = this.props;

    return (
      <>
        <Button onClick={this.openModal("main")}>
          Pracownicy zatrudnieni na stanowisku
        </Button>
        <ModalComponent
          open={this.state.main.open}
          addUser={this.addUser}
          deleteUser={deleteWorker}
          getUsers={this.getUsers}
          closeModal={this.closeModal("main")}
          data={data}
        />
        <ModalGetComponent
          open={this.state.get.open}
          closeModal={this.closeModal("get")}
        />
        <ModalAddComponent
          open={this.state.add.open}
          addUser={addWorker}
          openListModal={() => {}}
          closeModal={() => {
            this.closeModal("add")();
            this.openModal("main")();
          }}
        />
      </>
    );
  }
}
