import React from "react";
import Button from "react-bootstrap/lib/Button";

import AppComponent from "components/AppComponent";
import { ButtonWithIcon } from "components/ListComponents";
import FormModal from "components/Modal/FormModal";
import { FieldInput, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";

let checkboxes = [
  {
    name: "a",
    label: "Informacje ogólne",
  },
  {
    name: "b",
    label: "Informacje ogólne  - informacje dodatkowe",
  },
  {
    name: "c",
    label: "Charakterystyka ocenianego stanowiska pracy",
  },
  {
    name: "d",
    label: "Identyfikacja zagrożeń",
  },
  {
    name: "e",
    label: "Analiza ryzyka zawodowego",
  },
  {
    name: "f",
    label: "Plan działań zmiejszających ryzyka zawodowe",
  },
  {
    name: "g",
    label:
      "Pracownicy zatrudnieni na stanowisku - potwierdzenie zapoznania z oceną ryzyka zawodowego",
  },
];

export default class Printmodal extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      open: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  openModal() {
    this.setState({ open: true });
  }

  render() {
    return (
      <>
        <ButtonWithIcon
          onClick={this.openModal}
          icon="fa fa-file-text-o"
        ></ButtonWithIcon>

        <FormModal
          open={this.state.open}
          title="Zapisz jako szablon"
          acceptButtonName="Zapisz"
          cancelButtonName="Anuluj"
          handleSubmit={() => {
            alert("wybranie wzoru [ph]");
          }}
          handleClose={this.closeModal}
        >
          <TwoInputWrapper>
            <FieldInput name="a" label="Nazwa szablonu" />
          </TwoInputWrapper>
        </FormModal>
      </>
    );
  }
}
