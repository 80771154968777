import React from "react";
import styled from "styled-components";
import AppComponent from "../../components/AppComponent";
import TaskForm from "../../components/Task.Form";
//TODO Must sprawdzić czy widok jest przestarzały i usunąć
class TaskShow extends AppComponent {
  render() {
    const localizations = [{ name: "Olsztyn" }, { name: "Poznań" }];

    const sections = [{ name: "Producja" }, { name: "Magazyn" }];

    const data = {
      symbol: "OLS-AI-1026-0001",
      title: "Przygotowanie szkolenia BHP",
      responsible_person: "Jan Kowal",
      selected_location: 1,
      selected_section: 1,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam non risus lorem. Fusce gravida sodales ligula quis consectetur. Donec tellus tellus, ullamcorper eget suscipit in, commodo ut orci. Fusce mattis mauris dignissim turpis consequat vulputate. Integer quis lacinia ex. Curabitur facilisis semper mi. Phasellus mauris libero, feugiat ac placerat in, elementum id metus. Integer dictum auctor dui rhoncus commodo.\n",
      priority: "2",
    };

    return (
      <TaskForm
        localizations={localizations}
        sections={sections}
        data={data}
        readOnly
      />
    );
  }
}

export default TaskShow;
