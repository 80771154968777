import React from "react";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";

import { ModelComponent } from "components/AppComponent";
import { ButtonWrapper } from "components/Wrappers";
import { ButtonWithIcon } from "components/ListComponents";
import { LayoutContent } from "components/Layout";
import { TableListPage } from "components/Tables";
import PageHeader from "components/PageHeader";
import Modal from "components/Modal/SimpleAccept";
import { LinkContainer } from "components/LinkContainer";

import AddDraft from "./ModalAddDraft";
import ControlForm from "./ModalControlForm";

let defaultUrls = {
  CHARACTERISTIC: () => "/",
};
export default class DangerList extends ModelComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalControlForm: {
        open: false,
      },
      modal: this.initModalState(),
      urls: this.setUrls(defaultUrls),
    };

    this.deleteEntry = this.deleteEntry.bind(this);
    this.openControlList = this.openControlList.bind(this);
  }

  deleteEntry() {
    let { entry_id } = this.state.modal;
    let { deleteDanger } = this.props;
    deleteDanger(entry_id);
  }

  openControlList(modalOptions) {
    let { modalControlForm } = this.state;
    let { getQuestions } = this.props;
    let { entry_id: id } = modalOptions;
    return () => {
      getQuestions(id);
      this.openModal("modalControlForm", modalOptions)();
    };
  }

  render() {
    let { modal, modalControlForm, urls } = this.state;
    let {
      addAttachmentAction = () => {},
      addNewDanger = () => {},
      deleteAttachmentAction = () => {},
      deleteDanger = () => {},
      controlList,
      draft,
      downloadAction,
      message,
      SideMenu,
      data,
      id,
      questions,
      permission = {},
      readOnly: pReadOnly,
      saveQuestions = () => {},
      setNextStage = () => {},
      stage,
    } = this.props;
    let readOnly = pReadOnly || (!draft && !permission[22]);
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={SideMenu}
      >
        <PageHeader>
          Dodaj nową ocenę ryzyka zawodowego - Identyfikacja zagrożeń
        </PageHeader>

        <ButtonWrapper>
          <AddDraft
            data={controlList}
            onSelect={addNewDanger}
            readOnly={readOnly}
          />
        </ButtonWrapper>
        <TableListPage>
          <thead>
            <th>L.p.</th>
            <th>Nazwa</th>
            <th>Usuń</th>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <a
                    href="#"
                    onClick={this.openControlList({
                      entry_id: item.id,
                    })}
                  >
                    {item.name}
                  </a>
                </td>
                <td>
                  <ButtonWithIcon
                    disabled={readOnly}
                    onClick={this.openModal("modal", { entry_id: item.id })}
                    icon="fa fa-trash"
                    name="Usuń"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableListPage>
        <ButtonWrapper>
          {!draft && stage <= 1 ? (
            <Button onClick={setNextStage}>Dalej</Button>
          ) : (
            <LinkContainer to={urls.CHARACTERISTIC(id)}>
              <Button>Dalej</Button>
            </LinkContainer>
          )}
        </ButtonWrapper>
        <ControlForm
          readOnly={readOnly}
          {...this.state.modalControlForm}
          questions={questions}
          saveQuestions={saveQuestions}
          addAttachmentAction={addAttachmentAction}
          deleteAttachmentAction={deleteAttachmentAction}
          downloadAction={downloadAction}
          closeModal={this.closeModal("modalControlForm")}
        />
        <Modal
          title="Usuń metody oceny ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modal.open}
          handleAccept={this.deleteEntry}
          handleClose={this.closeModal("modal", this.initModalState)}
        />
      </LayoutContent>
    );
  }
}
