import React from "react";
import { connect } from "react-redux";
import SideMenu from "./_SideMenu";

import {
  getDraftRA,
  saveDraftRACharacteristic,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import WorkplaceCharakteristicAdd from "components/RiskAssessment.Forms/WorkplaceCharacteristic/";
import { setUrls } from "./_staticData";

class Component extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let { match, dispatch } = this.props;
    let { id } = match.params;
    getDraftRA(id)(dispatch).then(this.handleServerResponse());
  }

  submit(data) {
    let { urls } = this.state;

    let { dispatch, match, history } = this.props;
    let { id } = match.params;

    saveDraftRACharacteristic(
      id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.LIST);
      })
    );
  }

  render() {
    let { urls } = this.state;
    let { match, data } = this.props;
    let { id } = match.params;
    return (
      <WorkplaceCharakteristicAdd
        data={data}
        readonly={false}
        urls={urls}
        id={id}
        draft
        SideMenu={<SideMenu id={id} />}
        submit={this.submit}
        nextUrl={`/super-admin/draft/risk-assessment/analysis/${id}`}
      />
    );
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
}))(Component);
