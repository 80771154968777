import React from "react";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";

import AppComponent from "components/AppComponent";
import Box from "components/Box";
import { ButtonWrapper } from "components/Wrappers";
import { FieldInput } from "components/Fields";
import { LayoutContent } from "components/Layout";

import { LinkContainer } from "components/LinkContainer";
import validator from "components/Validation";

let defaultNames = {
  TITLE: "[PH TITLE]",
};

export default class QualificationEntitlementsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    let { urls = {}, names = {} } = this.props;
    this.state = {
      ...this.state,
      names: { ...defaultNames, ...names },
    };
  }

  render() {
    let { names } = this.state;

    let {
      SideMenu,
      data,
      submit = () => {
        alert("submit [ph]");
      },
      message,
      urls,
      permission = {},
      superAdminView,
    } = this.props;
    let readOnly = superAdminView
      ? false
      : data && data.business_id === 0
      ? true
      : data && data.id
      ? !permission[51]
      : !permission[50];

    return (
      <LayoutContent
        message={message}
        SideMenu={SideMenu ? <SideMenu /> : undefined}
      >
        <Box title={names.TITLE}>
          <Form
            initialValues={data}
            onSubmit={submit}
            render={({ handleSubmit, pristine, invalid, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FieldInput
                    disabled={readOnly}
                    name={"name"}
                    validate={validator.required}
                    label="Nazwa uprawnień"
                  />
                  <ButtonWrapper>
                    <LinkContainer exact to={urls.LISTTYPE}>
                      <Button>Anuluj</Button>
                    </LinkContainer>
                    <Button disabled={readOnly} type="submit" bsStyle="primary">
                      Zapisz
                    </Button>
                  </ButtonWrapper>
                </form>
              );
            }}
          />
        </Box>
      </LayoutContent>
    );
  }
}
