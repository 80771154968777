import React, { PureComponent } from "react";
import styled from "styled-components";
import SlideToggle from "react-slide-toggle";
import Box, { createPanelHeadingStyle } from "components/Box";
import Panel from "react-bootstrap/lib/Panel";
import { withTheme } from "styled-components";

let TitleBar = styled.div`
  display: flex;
  & > * {
    &:nth-child(1) {
      flex: 0 0 28px;
      cursor: pointer;
      padding: 0 7px;
      line-height: 26px;
    }
    &:nth-child(2) {
      flex: 1 1 100%;
      line-height: 26px;
    }
    &:nth-child(3) {
      flex: 0 0 40px;
      cursor: pointer;
      padding: 0 7px;
      line-height: 26px;
    }
  }
`;

let ToggleButton = function (props) {
  return (
    <div onClick={props.onClick}>
      <i
        className={`fa fa-arrow-${props.open ? "up" : "down"} ${
          props.className
        }`}
      />
    </div>
  );
};
let ToggleButtonStyled = styled(ToggleButton)`
  font-size: 32px !important;

  padding: 20px 10px 0px 0px;
  cursor: pointer;
`;

class Test extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state };
  }

  render() {
    let {
      DragAndDropMove = () => {
        return <span></span>;
      },
      name,
    } = this.props;

    return (
      <SlideToggle
        collapsed={true}
        duration={600}
        render={({ toggle, toggleState, setCollapsibleElement }) => {
          let style =
            toggleState == "EXPANDED"
              ? { overflow: "visible" }
              : { overflow: "hidden" };

          return (
            <Panel>
              <Panel.Heading style={createPanelHeadingStyle(this.props)}>
                <TitleBar>
                  <DragAndDropMove />
                  <span>{name}</span>
                  <ToggleButton
                    onClick={() => {
                      toggle();
                    }}
                    open={
                      toggleState == "EXPANDED" || toggleState == "EXPANDING"
                        ? true
                        : false
                    }
                  />{" "}
                </TitleBar>
              </Panel.Heading>
              <Panel.Body>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                  style={style}
                >
                  <div className="my-collapsible__content-inner">
                    {this.props.children}
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          );
        }}
      />
    );
  }
}
export default withTheme(Test);
