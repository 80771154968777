import React, { Fragment } from "react";
import { Field } from "react-final-form";
import DayPicker from "../../lib/DayPicker";
import FormModal from "../Modal/FormModal";
import { FieldDayPicker } from "components/Fields";
import Select from "../../components/Select";
import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
  StyledTextareaBox,
} from "../../style/styleComponents/Form/input";
import validator from "../Validation/";

let setInitialValues = (data) => {
  let keys = [
    "id",
    "medical_examinations_type_id",
    "users_id",
    "date_issue",
    "date_next",
    "ability_to_work_id",
    "additional_description",
  ];
  return keys.reduce((obj, k) => {
    obj[k] = data[k];
    return obj;
  }, {});
};

export default class SaveMedicalExaminationsFormModal extends React.PureComponent {
  render() {
    let {
      businessView,
      closeModal,
      data,
      modalSettings,
      open,
      permission = {},
      saveME,
    } = this.props;
    let readOnly = businessView
      ? false
      : data && data.id
      ? !permission[39]
      : !permission[38];
    return (
      <FormModal
        open={open}
        initialValues={setInitialValues(data)}
        title={data && data.id ? "Edytuj badanie" : "Dodaj nowe badanie"}
        description="Opis"
        handleSubmit={saveME}
        handleClose={closeModal}
        acceptDisabled={readOnly}
        acceptButtonName="Zapisz"
        cancelButtonName="Anuluj"
      >
        <Field
          name="medical_examinations_type_id"
          validate={validator.required}
          render={({ input, meta, ...rest }) => (
            <Select
              disabled={readOnly}
              label="Rodzaj badania"
              fullWidth
              title="Brak"
              propValue={"id"}
              data={[
                { id: 1, name: "Badania wstępne" },
                { id: 2, name: "Badania okresowe" },
                { id: 3, name: "Badania kontrolne" },
              ]}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              selected={input.value}
              handleChange={(data) => {
                input.onChange(data);
              }}
            />
          )}
        />
        <FieldDayPicker
          readOnly={readOnly}
          name="date_issue"
          label="Data wykonania badania"
        />
        <Field
          name="ability_to_work_id"
          validate={validator.required}
          render={({ input, meta, ...rest }) => (
            <Select
              disabled={readOnly}
              label="Orzeczenie o zdolności do pracy"
              fullWidth
              title="Brak"
              propValue={"id"}
              data={[
                {
                  id: 1,
                  name: "zdolny do pracy ",
                },
                {
                  id: 2,
                  name: "niezdolny do pracy ",
                },
              ]}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              selected={input.value}
              handleChange={(data) => {
                input.onChange(data);
              }}
            />
          )}
        />
        <FieldDayPicker
          readOnly={readOnly}
          name="date_next"
          label="Data następnego badania"
        />
        <Field
          name="additional_description"
          render={({ input, meta, ...rest }) => (
            <StyledTextareaBox
              {...input}
              disabled={readOnly}
              label="Informacje dodatkowe / uwagi z orzeczenia lekarskiego"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              value={input.value}
            />
          )}
        />
      </FormModal>
    );
  }
}
