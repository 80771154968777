import React from "react";
import AppComponent from "components/AppComponent";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getModules } from "actions/moduleActions";
import { addBusiness } from "actions/businessActions";
import { getDraftAuditTypes, clearAudit } from "actions/auditActions";
import {
  getDraftRAMethods,
  getDraftRAs,
  getDraftControlListList,
  getDraftHazardList,
  clearRiskAssessment,
} from "actions/riskAssessmentAction";

import BusinessForm from "components/Business.Form";
class BusinessAdd extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServerResponse = this.handleServerResponse.bind(this);
  }

  componentWillMount() {
    getModules()(this.props.dispatch);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    getDraftAuditTypes({})(this.props.dispatch).then();

    getDraftRAMethods()(dispatch).then(this.handleServerResponse({}, () => {}));
    getDraftRAs()(dispatch).then(this.handleServerResponse());
    getDraftControlListList()(dispatch).then(this.handleServerResponse());
    getDraftHazardList()(dispatch).then(this.handleServerResponse()); //   this.handleServerResponse()
  }

  handleSubmit(data) {
    const { dispatch, history } = this.props;
    addBusiness(data)(dispatch).then(
      this.handleServerResponse({}, function () {
        history.push("/super-admin/businesses");
      })
    );
  }

  render() {
    const {
      modules,
      audit,
      ra,
      raMethods,
      controlList,
      modelHazardList,
    } = this.props;

    const { data: modulesData = [] } = modules;
    return (
      <BusinessForm
        auditTypesDrafts={audit.typeData}
        ra={ra}
        raMethod={raMethods}
        controlList={controlList}
        hazards={modelHazardList}
        boxTitle="Dodaj konto firmy"
        createView
        handleSubmit={this.handleSubmit}
        modules={modulesData}
      />
    );
  }

  componentWillUnmount() {
    clearAudit()(this.props.dispatch);
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  modules: store.modules,
  audit: store.audit,
  riskAssessment: store.riskAssessment,
  ra: store.riskAssessment.data,
  raMethods: store.riskAssessment.methods,
  controlList: store.riskAssessment.controlList,
  modelHazardList: store.riskAssessment.modelHazardList,
}))(BusinessAdd);

BusinessAdd.propTypes = {
  modules: PropTypes.object,
};
