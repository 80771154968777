export default function reducer(state = {
  data: [],
  singleData: {},
  profileFetching: false,
  profileFetched: false,
  fetching: false,
  fetched: true
}, action) {
  switch (action.type) {
    case "GET_ACCOUNT_DATA_REQUEST": {
      return {...state, fetching: true, fetched: false,profileFetched:false};
    }
    case "GET_ACCOUNT_DATA_SUCCESS": {
      return {...state, fetching: false, fetched: true, singleData: action.payload.data.account, profileFetched:true};
    }
    case "GET_ACCOUNT_DATA_ERROR": {
      return {...state, fetching: false, fetched: false,profileFetched:false};
    }
    case "SAVE_AVATAR_SUCCESS":{
      const {avatar_url} = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {...state.singleData, avatar: null, avatar_url: avatar_url}
      };
    }
    case "DELETE_AVATAR_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: {...state.singleData, avatar: null, avatar_url: null}
      };
    }
    case "SAVE_ACCOUNT_SUCCESS": {
      return {...state, fetching: false, fetched: true, singleData: {...state.singleData, ...action.payload.data.user}};
    }

    case "GET_FULL_ACCOUNT_DATA_REQUEST": {
      return {...state, fetching: true, fetched: false};
    }
    case "GET_FULL_ACCOUNT_DATA_SUCCESS": {
      return {...state, fetching: false, fetched: true, singleData: action.payload.data.account,profileFetched:true};
    }
    case "GET_FULL_ACCOUNT_DATA_ERROR": {
      return {...state, fetching: false, fetched: false};
    }
    case "CHANGE_SHOW_SETTINGS_ACCOUNT_SUCCESS": {
      const {id, create} = action.payload.data.show_settings;
      const settings = state.singleData;
      const newSingleData = {
        ...settings, show_settings: settings.show_settings.map(item => {
          return {...item,settings:item.settings.map(item => {
            if (item.id == id) {
              return {...item, value: create};
            }
            return item;
          })};
        })
      };
      return {...state, fetching: false, fetched: true,singleData:newSingleData};
    }
    case "LOGOUT_USER_SUCCESS": {
      return {...state,singleData:{}};
    }
    case "LOGOUT_BUSINESS_ADMIN_SUCCESS":{
      return {...state,singleData:{}};
    }
  }

  return state;
}