import { createURLParams, createURLParamsString } from "./_util";

export function getAuditTypes(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_TYPES_REQUEST",
        "GET_AUDIT_TYPES_SUCCESS",
        "GET_AUDIT_TYPES_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/types${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAuditTypesWithDrafts() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_TYPES_REQUEST",
        "GET_AUDIT_TYPES_SUCCESS",
        "GET_AUDIT_TYPES_ERROR",
      ],
      payload: {
        request: {
          url: "/audit/types/attached",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftAuditTypes(data = {}) {
  let { business_id } = data;
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_TYPES_REQUEST",
        "GET_AUDIT_TYPES_SUCCESS",
        "GET_AUDIT_TYPES_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/audit-type/list${
            business_id ? `/${business_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAuditType(audit_type_id, audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_TYPE_REQUEST",
        "GET_AUDIT_TYPE_SUCCESS",
        "GET_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/type/${audit_type_id}${
            audit_id ? `/${audit_id}` : ""
          }${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftAuditType(audit_type_id, audit_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_TYPE_REQUEST",
        "GET_AUDIT_TYPE_SUCCESS",
        "GET_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/audit-type/edit/${audit_type_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addAuditType(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_AUDIT_TYPE_REQUEST",
        "ADD_AUDIT_TYPE_SUCCESS",
        "ADD_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: "/audit/type/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addDraftAuditType(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_AUDIT_TYPE_REQUEST",
        "ADD_AUDIT_TYPE_SUCCESS",
        "ADD_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: "/super-admin/draft/audit-type/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addAuditNewQuestionLocal() {
  return (dispatch) =>
    dispatch({
      type: "ADD_AUDIT_NEW_QUESTION_LOCAL",
      payload: {},
    });
}

export function editAuditType(audit_type_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_AUDIT_TYPE_REQUEST",
        "EDIT_AUDIT_TYPE_SUCCESS",
        "EDIT_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/type/edit/${audit_type_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editDraftAuditType(audit_type_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_AUDIT_TYPE_REQUEST",
        "EDIT_AUDIT_TYPE_SUCCESS",
        "EDIT_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/audit-type/edit/${audit_type_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteAuditType(audit_type_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_AUDIT_TYPE_REQUEST",
        "DELETE_AUDIT_TYPE_SUCCESS",
        "DELETE_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/type/delete/${audit_type_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteDraftAuditType(audit_type_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_AUDIT_TYPE_REQUEST",
        "DELETE_AUDIT_TYPE_SUCCESS",
        "DELETE_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/audit/type/delete/${audit_type_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteAuditCustomQuestion(audit_id, audit_question_id) {
  return (dispatch) => {
    dispatch({
      types: [
        "DELETE_AUDIT_CUSTOM_QUESTIONS_REQUEST",
        "DELETE_AUDIT_CUSTOM_QUESTIONS_SUCCESS",
        "DELETE_AUDIT_CUSTOM_QUESTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/question/${audit_id}/delete/${audit_question_id}`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      },
    });
  };
}

export function deleteAuditCustomQuestionLocal() {
  return (dispatch) =>
    dispatch({
      type: "DELETE_AUDIT_CUSTOM_QUESTION_LOCAL",
      payload: {},
    });
}

export function registerAudit(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_AUDIT_REQUEST",
        "REGISTER_AUDIT_SUCCESS",
        "REGISTER_AUDIT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function quickRegisterAudit(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_AUDIT_REQUEST",
        "REGISTER_AUDIT_SUCCESS",
        "REGISTER_AUDIT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/quick-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getAudits() {
  return (dispatch) =>
    dispatch({
      types: ["GET_AUDITS_REQUEST", "GET_AUDITS_SUCCESS", "GET_AUDITS_ERROR"],
      payload: {
        request: {
          url: `/audit`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAudit(audit_id) {
  return (dispatch) =>
    dispatch({
      types: ["GET_AUDIT_REQUEST", "GET_AUDIT_SUCCESS", "GET_AUDIT_ERROR"],
      payload: {
        request: {
          url: `/audit/data/${audit_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveAuditData(audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: ["SAVE_AUDIT_REQUEST", "SAVE_AUDIT_SUCCESS", "SAVE_AUDIT_ERROR"],
      payload: {
        request: {
          url: `/audit/data/${audit_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getAuditQuestions(audit_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_QUESTIONS_REQUEST",
        "GET_AUDIT_QUESTIONS_SUCCESS",
        "GET_AUDIT_QUESTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/question/${audit_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveAuditQuestionsAnswers(audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_AUDIT_QUESTIONS_REQUEST",
        "SAVE_AUDIT_QUESTIONS_SUCCESS",
        "SAVE_AUDIT_QUESTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/question/${audit_id}`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function getAuditTask(audit_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_QUESTIONS_TASK_REQUEST",
        "GET_AUDIT_QUESTIONS_TASK_SUCCESS",
        "GET_AUDIT_QUESTIONS_TASK_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/task/${audit_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAuditNotificationSchema(user_id, localization_id, nolimit) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATIONS_REQUEST",
        "GET_NOTIFICATIONS_SUCCESS",
        "GET_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/scheme/${user_id}/${localization_id}`,
          method: "get",
          params: {
            nolimit: !!nolimit,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAuditNotification(audit_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATIONS_REQUEST",
        "GET_NOTIFICATIONS_SUCCESS",
        "GET_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function registerNotifications(audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_NOTIFICATIONS_REQUEST",
        "REGISTER_NOTIFICATIONS_SUCCESS",
        "REGISTER_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getAuditsByFilter(data) {
  return (dispatch) =>
    dispatch({
      types: ["GET_AUDITS_REQUEST", "GET_AUDITS_SUCCESS", "GET_AUDITS_ERROR"],
      payload: {
        request: {
          url: "/audit/",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}
export function downloadCompleteAuditPdf(audit_type_id, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_COMPLETE_AUDIT_REQUEST",
        "DOWNLOAD_COMPLETE_AUDIT_SUCCESS",
        "DOWNLOAD_COMPLETE_AUDIT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/print/${audit_type_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            index: index,
          },
          responseType: "blob",
        },
      },
    });
}

export function downloadAuditType(audit_type_id, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_AUDIT_TYPE_REQUEST",
        "DOWNLOAD_AUDIT_TYPE_SUCCESS",
        "DOWNLOAD_AUDIT_TYPE_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/type/print/${audit_type_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            index: index,
          },
          responseType: "blob",
        },
      },
    });
}

export function pingAudit() {
  return (dispatch) =>
    dispatch({
      types: ["PING_AUDIT_REQUEST", "PING_AUDIT_SUCCESS", "PING_AUDIT_ERROR"],
      payload: {
        request: {
          url: "/audit/ping",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
export function setNextStage(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "NEXT_STAGE_INCIDENT_REQUEST",
        "NEXT_STAGE_INCIDENT_SUCCESS",
        "NEXT_STAGE_INCIDENT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/set-next-stage/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteUserFromRegisterGroup(audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_FROM_NOTIFICATION_GROUP_REQUEST",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_SUCCESS",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}/delete-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerNotification(audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_NOTIFICATIONS_REQUEST",
        "ADD_NOTIFICATIONS_SUCCESS",
        "ADD_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}/add-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteRegisteredNotification(audit_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_NOTIFICATION_REQUEST",
        "DELETE_USER_NOTIFICATION_SUCCESS",
        "DELETE_USER_NOTIFICATION_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}/delete-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addUserToRegisterGroup(audit_id, user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_USER_TO_NOTIFICATION_GROUP_REQUEST",
        "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS",
        "ADD_USER_TO_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}/add-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: { user_id },
        },
      },
    });
}

export function getHistory(audit_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATION_HISTORY_REQUEST",
        "GET_NOTIFICATION_HISTORY_SUCCESS",
        "GET_NOTIFICATION_HISTORY_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/notification/${audit_id}/history`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAuditsAuditors() {
  return (dispatch) =>
    dispatch({
      types: ["GET_USERS_REQUEST", "GET_USERS_SUCCESS", "GET_USERS_ERROR"],
      payload: {
        request: {
          url: "/audit/auditors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteAudit(incident_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_AUDIT_REQUEST",
        "DELETE_AUDIT_SUCCESS",
        "DELETE_AUDIT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/delete/${incident_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function downloadAuditQuestionAttachment(
  url,
  attachmentIndex,
  index,
  subIndex
) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_AUDIT_QUESTION_ATTACHMENT_REQUEST",
        "DOWNLOAD_AUDIT_QUESTION_ATTACHMENT_SUCCESS",
        "DOWNLOAD_AUDIT_QUESTION_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            attachmentIndex,
            index,
            subIndex,
          },
        },
      },
    });
}

export function addAuditQuestionAttachment(audit_id, audit_question_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_AUDIT_QUESTION_ATTACHMENT_REQUEST",
        "ADD_AUDIT_QUESTION_ATTACHMENT_SUCCESS",
        "ADD_AUDIT_QUESTION_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/${audit_id}/question/${audit_question_id}/add-attachment`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function addAuditQuestionAttachmentLocal(payload) {
  return (dispatch) =>
    dispatch({
      type: "ADD_AUDIT_QUESTION_ATTACHMENT_LOCAL",
      payload,
    });
}

export function deleteAuditQuestionAttachmentLocal(payload) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_AUDIT_QUESTION_ATTACHMENT_LOCAL",
      payload,
    });
}

export function deleteAuditQuestionAttachment(
  audit_id,
  audit_question_id,
  attachment_id
) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_AUDIT_QUESTION_ATTACHMENT_REQUEST",
        "DELETE_AUDIT_QUESTION_ATTACHMENT_SUCCESS",
        "DELETE_AUDIT_QUESTION_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/${audit_id}/question/${audit_question_id}/delete-attachment/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        },
      },
    });
}

export function getAuditResponsivePerson(localization_id) {
  return (dispatch) =>
    dispatch({
      types: ["???_REQUEST", "???_SUCCESS", "???_ERROR"],
      payload: {
        request: {
          url: `/audit/get-responsive-person/${localization_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        },
      },
    });
}

export function getAuditAddData() {
  return (dispatch) =>
    dispatch({
      types: ["GET_AUDIT_REQUEST", "GET_AUDIT_SUCCESS", "GET_AUDIT_ERROR"],
      payload: {
        request: {
          url: `/audit/add`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getAuditDefaultPermission() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_AUDIT_DEFAULT_PERMISSION_REQUEST",
        "GET_AUDIT_DEFAULT_PERMISSION_SUCCESS",
        "GET_AUDIT_DEFAULT_PERMISSION_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/get-default-permission`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addTaskAttachment({ audit_id, task_id }, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_TASK_ATTACHMENT_REQUEST",
        "ADD_TASK_ATTACHMENT_SUCCESS",
        "ADD_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/${audit_id}/task/${task_id}/attachment/add`,

          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteTaskAttachment({ audit_id, task_id }, attachment_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_TASK_ATTACHMENT_REQUEST",
        "DELETE_TASK_ATTACHMENT_SUCCESS",
        "DELETE_TASK_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/audit/${audit_id}/task/${task_id}/attachment/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function resetAuditNewQuestionLocal() {
  return (dispatch) =>
    dispatch({
      type: "RESET_NEW_QUESTION_LOCAL",
      payload: {},
    });
}

export function clearAudit() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_AUDIT",
      payload: {},
    });
}

export function commentOpeningAction(payload) {
  return (dispatch) =>
    dispatch({
      type: "COMMENT_OPENNING",
      payload,
    });
}
