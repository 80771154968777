export function getDraftHazardIdentifications(data = {}) {
  let { business_id } = data;
  return (dispatch) =>
    dispatch({
      types: [
        "GET_HAZARD_IDENTIFICATION_REQUEST",
        "GET_HAZARD_IDENTIFICATION_SUCCESS",
        "GET_HAZARD_IDENTIFICATION_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/hazard-identification/list${
            business_id ? `/${business_id}` : ""
          }`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addHazardIdentification(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_HAZARD_IDENTIFICATION_REQUEST",
        "ADD_HAZARD_IDENTIFICATION_SUCCESS",
        "ADD_HAZARD_IDENTIFICATION_ERROR",
      ],
      payload: {
        request: {
          url: "/super-admin/draft/hazard-identification/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function clearHazardIdentification() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_HAZARD_IDENTIFICATION",
      payload: {},
    });
}
