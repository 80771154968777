import React, { useState, useEffect } from "react";
import { FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import { FieldInput, FieldInputCheckBox } from "components/Fields";
import { LeftPadding, TwoInputWrapper } from "components/Wrappers";
import validator, { composeValidators } from "components/Validation";

import messages from "messages/messages.json";

let keysClass = (function () {
  let keys = [
    { name: "payer_fullname", validate: true },
    { name: "payer_fulladdress", validate: true },
    { name: "payer_nip", validate: true },
    { name: "payer_regon", validate: true },
    { name: "payer_pesel", validate: false },
    { name: "payer_id_document_name", validate: false },
    { name: "payer_id_document_series", validate: false },
    { name: "payer_id_document_number", validate: false },
  ];

  let value = keys.map((i) => i.name);
  let validate = keys.filter((i) => i.validate).map((i) => i.name);
  return {
    get value() {
      return value;
    },
    get validate() {
      return validate;
    },
  };
})();

export let validatePayerData = (values, errors) => {
  let disabled = values.is_not_insurance_payer == true;

  for (let key of keysClass.validate) {
    errors[key] = disabled
      ? undefined
      : values[key]
      ? undefined
      : messages["validation_message.required"];
  }
};

function PayerData({ readOnly }) {
  return (
    <>
      <h5>Dane identyfikacyjne pładnika składek</h5>
      <LeftPadding>
        <FormSpy subscribe={{ values: true, form: true }}>
          {({ values, form }) => {
            let disabled = readOnly || values.is_not_insurance_payer == true;

            return (
              <>
                <FieldInputCheckBox
                  name="is_not_insurance_payer"
                  validate={validator.required}
                  label={
                    "Podmiot NIE JEST płatnikiem składek na ubezpieczenia rentowne/chorobowe"
                  }
                />
                <OnChange name="is_not_insurance_payer">
                  {(value) => {
                    if (value == true) {
                      for (let key of keysClass.value) {
                        form.change(key, null);
                      }
                    }
                  }}
                </OnChange>
                <TwoInputWrapper>
                  <FieldInput
                    name={"payer_fullname"}
                    label={
                      "Imię i nazwisko lub nazwa płatnika składek na ubezpieczenie społeczne"
                    }
                    disabled={disabled}
                  />
                  <FieldInput
                    name={"payer_fulladdress"}
                    label={"Adres płatnika składek na ubezpieczenie społeczne"}
                    disabled={disabled}
                  />
                </TwoInputWrapper>
                <TwoInputWrapper>
                  <FieldInput
                    name={"payer_nip"}
                    label={"NIP"}
                    disabled={disabled}
                  />
                  <FieldInput
                    name={"payer_regon"}
                    label={"REGON"}
                    disabled={disabled}
                  />
                </TwoInputWrapper>
                <TwoInputWrapper>
                  <FieldInput
                    name={"payer_pesel"}
                    label={"PESEL"}
                    disabled={disabled}
                  />
                </TwoInputWrapper>
                <TwoInputWrapper>
                  <FieldInput
                    name={"payer_id_document_name"}
                    label={"Rodzaj dokumentu potwierdzającego tożsamość"}
                    disabled={disabled}
                  />
                  <FieldInput
                    name={"payer_id_document_series"}
                    label={"Seria dokumentu potwierdzającego tożsamość"}
                    disabled={disabled}
                  />
                </TwoInputWrapper>
                <TwoInputWrapper>
                  <FieldInput
                    name={"payer_id_document_number"}
                    label={"Nr dokumentu potwierdzającego tożsamość"}
                    disabled={disabled}
                  />
                </TwoInputWrapper>
              </>
            );
          }}
        </FormSpy>
      </LeftPadding>
    </>
  );
}

export default PayerData;
