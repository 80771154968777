import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";
import { ButtonWrapper } from "components/Wrappers";
import ModalListWithFilter from "components/Modal/ListWithFilter";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TdStyled = styled.td`
  cursor: pointer;
`;

class GetDraftModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  getData(id) {
    return () => {
      let { getData } = this.props;
      getData(id, () => {
        this.closeModal();
      });
    };
  }

  render() {
    let { open, data = [] } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Pobierz dane z szablonu</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <FieldInput name="filter" placeholder="Szukaj..." /> */}
            <TableListPage>
              <thead>
                <th>Nazwa</th>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr>
                    <TdStyled onClick={this.getData(item.id)}>
                      {item.name}
                    </TdStyled>
                  </tr>
                ))}
              </tbody>
            </TableListPage>
            <ButtonWrapper>
              <Button>Wybierz</Button>
            </ButtonWrapper>
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

class GetDraft extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
  }

  getData(id) {
    return () => {
      let { getData } = this.props;
      getData(id, () => {
        this.closeModal();
      });
    };
  }

  render() {
    let { open } = this.state;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Pobierz dane z szablonu
        </Button>
        <GetDraftModal
          {...this.props}
          open={open}
          closeModal={() => {
            this.setState({ open: false });
          }}
        />
      </>
    );
  }
}

export default class AAAA extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
    this.getData = this.getData.bind(this);
  }

  getData(item) {
    let { getData } = this.props;
    getData(item.id, () => {});
  }

  render() {
    return (
      <>
        <ModalListWithFilter
          buttonName={"Pobierz dane z szablonu"}
          modalTitle="Pobierz dane z szablonu"
          onSelect={this.getData}
          {...this.props}
        />
      </>
    );
  }
}
