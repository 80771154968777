import React from "react";
import styled from "styled-components";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import PropTypes from "prop-types";
import NotFound from "../container/NotFound";
import { media } from "../style/style-utils";
import Alert from "react-bootstrap/lib/Alert";
const Layout = (props) => (
  <Grid>
    {props.children}
    {/* <div style={{ textAlign: "center" }}>
      <img src="/images/logaski.png" />
    </div> */}
  </Grid>
);
const CustomCol = styled(Col)`
  ${media.xs`
    ${(props) => (props.mobileNotHideMenu ? "" : "display:none")};
  `}
`;

const LayoutContentContainer = styled.div`
  padding-bottom: 20px;
`;

const ColumnWrapper = styled.div`
  margin: 0 -15px;
  display: flex;
  justify-content: flex-end;
  ${media.sm`
    flex-wrap:wrap;
  `}
`;

const SideMenuColumn = styled.div`
  padding: 0 15px;
  width: 16.66666667%;
  ${media.sm`
    width: 83.33333333%;
  `}
  ${media.xs`
    width: 100%;
  `}
`;

const ContentColumn = styled.div`
  padding: 0 15px;
  width: 83.33333333%;
  ${media.xs`
    width: 100%;
  `}
`;

export const LayoutContent = (props) => (
  <LayoutContentContainer>
    {!props.error404 || !(props.error_code == 404) ? (
      <ColumnWrapper>
        <SideMenuColumn mobileNotHideMenu={props.mobileNotHideMenu}>
          <Row>
            <Col xs={12}>{props.SideMenu}</Col>
          </Row>
        </SideMenuColumn>
        <ContentColumn>
          {props.message && props.message.text && (
            <Alert bsStyle={props.message.className}>
              {props.message.text}
            </Alert>
          )}
          {props.children}
        </ContentColumn>
      </ColumnWrapper>
    ) : (
      <NotFound />
    )}
  </LayoutContentContainer>
);

export default Layout;

LayoutContent.propTypes = {
  SideMenu: PropTypes.element,
  children: PropTypes.node.isRequired,
  mobileNotHideMenu: PropTypes.bool,
};
