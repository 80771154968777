export function getBusinesses() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_BUSINESSES_REQUEST",
        "GET_BUSINESSES_SUCCESS",
        "GET_BUSINESSES_ERROR",
      ],
      payload: {
        request: {
          url: "/super-admin/business",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusiness(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_BUSINESS_REQUEST",
        "GET_BUSINESS_SUCCESS",
        "GET_BUSINESS_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/edit/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addBusiness(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_BUSINESSES_REQUEST",
        "ADD_BUSINESSES_SUCCESS",
        "ADD_BUSINESSES_ERROR",
      ],
      payload: {
        request: {
          url: "/super-admin/business/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function editBusiness(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_BUSINESSES_REQUEST",
        "EDIT_BUSINESSES_SUCCESS",
        "EDIT_BUSINESSES_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/edit/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteBusiness(business_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_BUSINESSES_REQUEST",
        "DELETE_BUSINESSES_SUCCESS",
        "DELETE_BUSINESSES_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/delete/${business_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteLogo(business_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_BUSINESSES_LOGO_REQUEST",
        "DELETE_BUSINESSES_LOGO_SUCCESS",
        "DELETE_BUSINESSES_LOGO_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/delete-logo/${business_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveLogo(business_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_BUSINESSES_LOGO_REQUEST",
        "SAVE_BUSINESSES_LOGO_SUCCESS",
        "SAVE_BUSINESSES_LOGO_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/save-logo/${business_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function saveBusinessAdmin(business_id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_BUSINESSES_ADMIN_REQUEST",
        "SAVE_BUSINESSES_ADMIN_SUCCESS",
        "SAVE_BUSINESSES_ADMIN_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/editAdmin/${business_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function loginToBusiness(business_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "LOGIN_TO_BUSINESSES_REQUEST",
        "LOGIN_TO_BUSINESSES_SUCCESS",
        "LOGIN_TO_BUSINESSES_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/business/login/${business_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
