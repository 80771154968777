import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import { connect } from "react-redux";
import { FormSpy, Field } from "react-final-form";
import styled from "styled-components";

import {
  FieldSelectDitionary,
  FieldSelectDitionary33,
  FieldInput,
  FieldInput33,
  FieldTextareaBox,
  FieldInputCheckBox,
  FieldDayPicker,
} from "components/Fields";
import { generateAccidentCard } from "actions/incidentActions";
import { ButtonWrapper } from "components/ListComponents";
import saveBlobFile from "components/saveBlobFile";

import { StyledLabel } from "style/styleComponents/Form/input";
import validator, { composeValidators } from "components/Validation";
import ProtocolAccidentSeverity from "./container/protocolAccidentSeverity";
import ToogleSlider from "components/ToggleSlider";

import {
  TwoInputWrapper,
  LeftPadding,
  Width33,
  FlexLineAccidentType,
} from "./AnalysisForm.style";

let IsAccidentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
  & > * {
    margin: 0 20px;
    &:nth-child(1) {
      flex: 1 0 20%;
    }
    &:nth-child(2) {
      flex: 0 1 80%;
    }
  }
`;
let prefix = "ac_";

let AccidentCardKeys = [
  "ac_payer_fullname",
  "ac_payer_address",
  "ac_payer_nip",
  "ac_payer_regon",
  "ac_payer_pesel",
  "ac_payer_id_document_name",
  "ac_payer_id_document_series",
  "ac_payer_id_document_number",
  "ac_register_fullname",
  "register_date,",
  "ac_accident_fulldescription",
  "protocol_work_accident_is_accident",
  "ac_work_accident_is_accident_description",
  "protocol_non_compliance_employee",
  "protocol_intoxication_employee",
  "protocol_accident_type_id",
  "protocol_accident_severity_fatal",
  "protocol_accident_severity_serious",
  "protocol_accident_severity_causing_unable_on_time",
  "protocol_accept_person",
  "protocol_aknowledged_date",
  "ac_completing_subject_business",
  "ac_completing_fullname",
  "protocol_accept_person",
  "protocol_accept_date",
  "protocol_prepare_difficulties",
  "protocols_attachment_list",
];
class AccidentCard extends React.PureComponent {
  generateAccidentCard(values) {
    let { incident_id, dispatch, form } = this.props;

    for (let item of AccidentCardKeys) {
      form.mutators.setFieldTouched(item, true);
    }

    let invalid = AccidentCardKeys.find((key) => {
      let field = form.getFieldState(key);
      if (field) {
        return field.invalid;
      } else {
        return false;
      }
    });

    if (!invalid) {
      let data = AccidentCardKeys.reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});

      generateAccidentCard({ incident_id, data })(dispatch).then((res) => {
        if (!res.error) {
          const file = res.payload.data;
          const fileName = `karta wypadku.pdf`;
          saveBlobFile(file, fileName);
        }
      });
    }
  }

  getSubcontractorInsurance() {
    let { dictionary, personData } = this.props;
    if (dictionary && dictionary.subcontractor_insurance) {
      let insurence = dictionary.subcontractor_insurance.find((item) => {
        return personData.subcontractor_insurance_id == item.id;
      });
      if (insurence) {
        return insurence.code;
      }
    }
    return "";
  }

  render() {
    let { readOnly, dictionary } = this.props;
    return (
      <Fragment>
        <ToogleSlider title="Karta wypadku" duration={600}>
          <h4>Dane identyfikacyjne płatnika składek</h4>
          <LeftPadding>
            <TwoInputWrapper>
              <FieldInput
                validate={validator.required}
                name={`${prefix}payer_fullname`}
                label={"Imię i nazwiska lub nazwa płatnika składek"}
                disabled={readOnly}
              />
              <FieldInput
                validate={validator.required}
                name={`${prefix}payer_address`}
                label={"Adres siedziby"}
                disabled={readOnly}
              />
            </TwoInputWrapper>
            <TwoInputWrapper>
              <FieldInput
                validate={validator.required}
                name={`${prefix}payer_nip`}
                label={"NIP"}
                disabled={readOnly}
              />
              <FieldInput
                validate={validator.required}
                name={`${prefix}payer_regon`}
                label={"REGON"}
                disabled={readOnly}
              />
            </TwoInputWrapper>
            <TwoInputWrapper>
              <FieldInput
                name={`${prefix}payer_pesel`}
                label={"PESEL"}
                disabled={readOnly}
              />
              <FieldInput
                name={`${prefix}payer_id_document_name`}
                label={"Rodzaj dokumentu potwierdzającego tożsamość"}
                disabled={readOnly}
              />
            </TwoInputWrapper>
            <TwoInputWrapper>
              <FieldInput
                name={`${prefix}payer_id_document_series`}
                label={"Seria dokumentu potwierdzającego tożsamość"}
                disabled={readOnly}
              />
              <FieldInput
                name={`${prefix}payer_id_document_number`}
                label={"Nr dokumentu potwierdzającego tożsamość"}
                disabled={readOnly}
              />
            </TwoInputWrapper>
          </LeftPadding>
          <h4>Informacje o wypadku</h4>

          <LeftPadding>
            <FieldInput
              validate={validator.required}
              name={`reporting_person`}
              label={"Imię i nazwisko osoby zgłaszającej wypadek"}
              disabled={readOnly}
            />
            <FieldDayPicker
              validate={validator.required}
              name={`register_date`}
              required
              label={"Data zgłoszenia"}
              disabled={readOnly}
            />
            <FieldTextareaBox
              validate={validator.required}
              name={`${prefix}accident_fulldescription`}
              label={
                "Informacje dotyczące okoliczności, przyczyn, czasu i miejsca wypadku, rodzaju i umiejscowienia urazu"
              }
              disabled={readOnly}
            />
            <StyledLabel>Stwierdza się, że wypadek</StyledLabel>
            <IsAccidentWrapper>
              <FieldSelectDitionary
                validate={validator.required}
                name={`protocol_work_accident_is_accident`}
                disabled={readOnly}
                dictionary={[
                  {
                    id: "true",
                    name: "jest",
                  },
                  {
                    id: "false",
                    name: "nie jest",
                  },
                ]}
              />
              <div>
                <div>
                  wypadkiem przy pracy określonym w art. 3 ust. 3 pkt{" "}
                  {this.getSubcontractorInsurance()}&nbsp;ustawy z dnia 30
                  października 2002 r. O ubezpieczeniu społecznym z tytułu
                  wypadków przy pracy i chorób zawodowych (uzasadnić i wskazać
                  dowody, jeżeli zdarzenia nie uznano za wypadek przy pracy)
                </div>

                <FieldTextareaBox
                  name={`${prefix}work_accident_is_accident_description`}
                  disabled={readOnly}
                />
              </div>
            </IsAccidentWrapper>
            <FieldTextareaBox
              validate={validator.required}
              name={`protocol_non_compliance_employee`}
              label={
                "Stwierdzono, że wyłączną przyczyną wypadku było udowodnione naruszenie przez poszkodowanego przepisów dotyczących ochrony życia i zdrowia, spowodowane przez niego umyślnie lub wskutek rażącego niedbalstwa (wskazać dowody)"
              }
              disabled={readOnly}
            />
            <FieldTextareaBox
              validate={validator.required}
              name="protocol_intoxication_employee"
              label="Stwierdzono, że poszkodowany, będąc w stanie nietrzeźwości lub pod wpływem środków odurzających lub substancji psychotropowych, przyczynił się w znacznym stopniu do spowodowania wypadku (wskazać dowody, a w przypadku odmowy przez poszkodowanego poddania się badaniu na zawartość tych substancji w organizmie – zamieścić informację o tym fakcie)"
              disabled={readOnly}
            />
            <FieldSelectDitionary33
              validate={validator.required}
              name="protocol_accident_type_id"
              label="Rodzaj wypadku"
              dictionary={dictionary.protocol_accident_type}
              disabled={readOnly}
            />
            <ProtocolAccidentSeverity readOnly={readOnly} />
          </LeftPadding>
          <h4>Pozostałe informacje</h4>
          <LeftPadding>
            <FieldInput
              name="protocol_accept_person"
              label="Imię i nazwisko poszkodowanego lub członka rodziny zapoznanego z treścią karty wypadku"
              disabled={readOnly}
            />
            <StyledLabel>Data zapoznania z treścią karty wypadku</StyledLabel>
            <FieldDayPicker
              disabled={readOnly}
              name="protocol_aknowledged_date"
            />
            <StyledLabel>Kartę wypadku sporządzono w dniu</StyledLabel>
            <FieldDayPicker disabled={readOnly} name="protocol_accept_date" />

            <FieldInput33
              name={`${prefix}completing_subject_business`}
              label={
                "Nazwa podmiotu obowiązanego do sporządzenia karty wypadku"
              }
              disabled={readOnly}
            />
            <FieldInput33
              name={`${prefix}completing_fullname`}
              label={"Imię i nazwisko sporzadzającego kartę wypadku"}
              disabled={readOnly}
            />
            <FieldTextareaBox
              name="protocol_prepare_difficulties"
              label="Przeszkody i trudności uniemożliwiające sporządzenie karty wypadku w wymaganym terminie 14 dni:"
              disabled={readOnly}
            />
            <FieldTextareaBox
              name="protocols_attachment_list"
              label="Załączniki"
              disabled={readOnly}
            />
          </LeftPadding>
          <ButtonWrapper>
            <FormSpy subscribe={{ values: true }}>
              {({ values }) => {
                return (
                  <Button
                    onClick={() => {
                      this.generateAccidentCard(values);
                    }}
                  >
                    Generuj kartę wypadku
                  </Button>
                );
              }}
            </FormSpy>
          </ButtonWrapper>
        </ToogleSlider>
      </Fragment>
    );
  }
}
export default connect((store) => ({}))(AccidentCard);
