import React from "react";
import {connect} from "react-redux"
import AppComponent from "../../components/AppComponent";
import {StyledTableWrapper} from "../../components/ListComponents";
import LoadingScreen from "../../components/LoadingScreen";
import {LayoutContent} from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Table from "react-bootstrap/lib/Table";
import {Link} from "react-router-dom";
import {getPages,deletePage} from "../../actions/pageActions"
import {DeleteButton} from "../../components/ListComponents";
import Modal from "../../components/Modal/SimpleAccept";
import AccordionList,
{AccordionButton,AccordionLineNameColumn,AccordionLineOptionColumn,AccordionLineBody}
  from "../../components/AccordionList";
import {ButtonWrapper,ButtonWithIcon} from "../../components/ListComponents";
import {LinkContainer} from "../../components/LinkContainer";
import Button from "react-bootstrap/lib/Button";
export class PageList extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state,
      modal:{
        open:false,
        entry_id:null
      }
    };

    this.deleteRequest = this.deleteRequest.bind(this);
    this.deletePage = this.deletePage.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const {dispatch} = this.props;
    this.updateMessage();

    getPages()(dispatch)
      .then(this.handleServerResponse({},()=>{}));
  }

  deleteRequest(entry_id){
    return ()=>{
      this.setState({modal:{...this.state.modal,open:true,entry_id}});
    };
  }

  closeModal(){
    this.setState({modal:{...this.state.modal,open:false,entry_id:null}});
  }

  deletePage(){
    const {dispatch} = this.props;
    const {entry_id} = this.state.modal;
    deletePage(entry_id)(dispatch)
      .then(this.handleServerResponse());
  }

  render() {
    const {modal,message} = this.state;
    const {page: {data,fetched}} = this.props;

    const AccordionLine = ({id,open,url,name,option_name,lineHeight,optionColumnWidth,optionColumn,level,handleChange,children,className,header,no})=>(
      <AccordionLineBody lineHeight={"45px"} className={"accordion-item"} level={level} optionColumnWidth={"90px"} header={header}>
        <AccordionButton handleChange={handleChange} children={children} open={open}/>
        <AccordionLineNameColumn>
          {url ?  (<Link to={`/super-admin/page/${id}`}>{name}</Link>)  : name}
        </AccordionLineNameColumn>
        <AccordionLineOptionColumn>
          {no}
        </AccordionLineOptionColumn>
        <AccordionLineOptionColumn width={"90px"}>
          {!header
            ? (<DeleteButton onClick={this.deleteRequest(id)} name="Usuń"/>)
            : (<div>{header &&option_name}</div>)}
        </AccordionLineOptionColumn>
      </AccordionLineBody>
    );

    return (
      <LoadingScreen fetched={fetched}>
        <LayoutContent
          message={message}
          SideMenu={(
          <SideMenu
            title="Strony statyczne"
            data={[
              {name: "Lista stron", url: "/super-admin/page"},
              {name: "Dodaj stronę", url: "/super-admin/page/add"}
            ]}
          />
        )}>
          <PageHeader>Strony statyczne</PageHeader>
          <ButtonWrapper>
            <LinkContainer to="/super-admin/page/add"><Button>Dodaj stronę</Button></LinkContainer>
          </ButtonWrapper>
          <AccordionList
            data={data}
            header={["Nazwa","Kolejność","Usuń"]}
            LineComponent={AccordionLine}
          />
          <Modal
            title="Usuń stronę statyczną"
            description={"Czy chcesz usunąć dany wpis?"}
            open={modal.open}
            handleAccept={this.deletePage}
            handleClose={this.closeModal}
          />
        </LayoutContent>
      </LoadingScreen>);
  }
}

export default connect(store => ({
  page: store.page,
  message:store.message
}))(PageList);