import React from "react";
import Button from "react-bootstrap/lib/Button";
import { connect } from "react-redux";

import {
  getControlListList,
  deleteControlList,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import List from "components/ControlList/List";
import { setUrls } from "./_staticData";

import SideMenu from "../_SideMenu";

class ControlList extends AppComponent {
  constructor(props) {
    super(props);

    this.deleteEntry = this.deleteEntry.bind(this);
  }

  componentDidMount() {
    this.updateMessage();
    getControlListList({ readOnly: true })(this.props.dispatch);
  }

  deleteEntry(id) {
    let { dispatch } = this.props;
    deleteControlList(id)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { message } = this.state;
    let { controlList, permission, match } = this.props;
    let { id } = match.params;
    return (
      <>
        <List
          id={id}
          permission={permission}
          message={message}
          data={controlList}
          deleteEntry={this.deleteEntry}
          SideMenu={SideMenu}
          urls={setUrls()}
        />
      </>
    );
  }
}

export default connect((store) => ({
  controlList: store.riskAssessment.controlList,
  message: store.message,
  permission: store.riskAssessment.permission,
}))(ControlList);
