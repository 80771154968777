import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";
import { ButtonWrapper } from "components/Wrappers";

import { ModalHazardForm } from "components/ModelHazard/Form";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

class GetDraftModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  submit(data) {
    let { saveModelHazard } = this.props;
    saveModelHazard(data);
    this.closeModal();
  }

  render() {
    let { open, data, saveModelHazard } = this.props;
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Zapisz zagrożenie jako szablon</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ModalHazardForm
              data={data}
              submit={this.submit}
              submitButtonName="Zapisz"
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class GetDraft extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
  }

  render() {
    let { open } = this.state;
    let { data, saveModelHazard } = this.props;
    return (
      <>
        <Button
          style={{ height: "34px" }}
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Zapisz zagrożenie jako szablon
        </Button>
        <GetDraftModal
          open={open}
          data={data}
          saveModelHazard={saveModelHazard}
          closeModal={() => {
            this.setState({ open: false });
          }}
        />
      </>
    );
  }
}
