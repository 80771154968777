export function getSections(localization_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SECTIONS_REQUEST",
        "GET_SECTIONS_SUCCESS",
        "GET_SECTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/business/localization/sections/${localization_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getSectionsIndependently() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SECTIONS_REQUEST",
        "GET_SECTIONS_SUCCESS",
        "GET_SECTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/business/sections`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserSections(localization_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SECTIONS_REQUEST",
        "GET_SECTIONS_SUCCESS",
        "GET_SECTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/localization/sections/${localization_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserSectionsIndependently() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SECTIONS_REQUEST",
        "GET_SECTIONS_SUCCESS",
        "GET_SECTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/sections`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getUserSectionsIndependentlyV2() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_SECTIONS_REQUEST",
        "GET_SECTIONS_SUCCESS",
        "GET_SECTIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/sections/v2`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function clearSections() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_SECTIONS",
      payload: [],
    });
}
