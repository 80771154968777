import React from "react";
import {connect} from "react-redux"
import AppComponent from "../../components/AppComponent";
import {Form, Field} from "react-final-form";
import PageHeader from "../../components/PageHeader";
import {StyledTextareaBox} from "../../style/styleComponents/Form/input";
import LoadingScreen from "../../components/LoadingScreen";
import {LayoutContent} from "../../components/Layout";
import SideMenu from "../../components/SideMenu";
import {ButtonWithIcon} from "../../components/ListComponents";
import {ButtonWrapper} from "../../components/ListComponents";
import Button from "react-bootstrap/lib/Button";
import {getPage, savePage, getPageWithout, addPage, getPagePotentialParent} from "../../actions/pageActions";
import PageForm from "../../components/Page.Form";
export class Page extends AppComponent {

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const {dispatch, match} = this.props;
    const {page_id} = match.params;
    getPagePotentialParent(page_id)(dispatch)
      .then(this.handleServerResponse());
  }

  submit(values) {
    const {dispatch, history} = this.props;

    addPage(values)(dispatch)
      .then(this.handleServerResponse({},()=>{
        history.push("/super-admin/page");
      }));
  }

  render() {
    const {message} = this.state;
    const {page: {data:parentPages}} = this.props;

    return (<LoadingScreen fetched={true}>
      <PageForm
        parentPages={parentPages}
        handleSubmit={this.submit}
      />
    </LoadingScreen>);
  }
}

export default connect(store => ({
  page: store.page
}))(Page);