export const defaultUrls = {
  ADD: "/super-admin/draft/risk-assessment-control-list-template/add",
  EDIT: (id) =>
    `/super-admin/draft/risk-assessment-control-list-template/edit/${id}`,
  LIST: `/super-admin/draft/risk-assessment-control-list-template`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
