import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import moment from "moment";
import {
  generateVictimExplains,
  saveVictimExplains,
} from "actions/incidentActions";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import ModalSchema from "components/Modal/./_SchemaAppComponent";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";
import DayPicker from "lib/DayPicker";

import { FieldDayPicker, FieldInput, FieldTextareaBox } from "../Fields";

import { SpaceBlock } from "../AnalysisForm.style";
import saveBlobFile from "components/saveBlobFile";

const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;

const LocalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

StyledModal.displayName = "Modal";

class SimpleAcceptModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {}

  accept() {
    const { accept = () => {}, closeModal } = this.props;

    accept();
    closeModal();
  }

  openAddModal() {
    let { openAddUser } = this.props;
    openAddUser();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  submit(values) {
    const { incident_id, dispatch } = this.props;

    let { file, ...data } = values;

    if (!data.explain_date) {
      data.explain_date = moment().format("YYYY-MM-DD");
    }

    saveVictimExplains({ incident_id, data })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        if (file) {
          generateVictimExplains({ incident_id })(dispatch).then((res) => {
            if (!res.error) {
              const file = res.payload.data;
              const fileName = `wyjaśnienia.pdf`;
              saveBlobFile(file, fileName);
              this.closeModal();
            }
          });
        } else {
          this.closeModal();
        }
      })
    );
  }

  render() {
    const {
      children,
      data = {},
      handleAccept,
      open,
      title,
      getUser,
      user,
      readOnly,
      incident: { singleData: incidentData, victim_explain },
    } = this.props;

    let { explain_place, explain_date, explain_description } = victim_explain;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialValues={{
                explain_place,
                explain_date,
                explain_description,
              }}
              onSubmit={this.submit}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FieldInput
                      disabled={readOnly}
                      name={`explain_place`}
                      label="Miejscowość"
                    />
                    <FieldDayPicker
                      name="explain_date"
                      label="Data"
                      disabled={readOnly}
                    />
                    <FieldTextareaBox
                      name="explain_description"
                      disabled={readOnly}
                      label="Wyjaśnienia:"
                    />

                    <LocalButtonWrapper>
                      <Button
                        style={{ marginBottom: "20px" }}
                        type="button"
                        bsStyle="primary"
                        onClick={() => {
                          form.change("file", true);
                          handleSubmit();
                        }}
                      >
                        Zapisz i wygeneruj
                      </Button>
                      <ButtonWrapper>
                        <Button
                          type="submit"
                          bsStyle="primary"
                          onClick={() => {
                            form.change("file", false);
                            handleSubmit();
                          }}
                        >
                          Zapisz
                        </Button>
                        <Button type="button" onClick={this.closeModal}>
                          Anuluj
                        </Button>
                      </ButtonWrapper>
                    </LocalButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </StyledModal>
      </div>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
  workplace: store.workplace,
  users: store.users,
}))(SimpleAcceptModal);

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
