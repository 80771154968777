import React from "react";
import SideMenu from "components/SideMenu";

const LSideMenu = () => (
  <SideMenu
    title="Szablony dokumentów"
    data={[
      {
        name: "Szablony audytowych",
        url: "/super-admin/draft/audit-type",
        notExact: true,
      },
      {
        name: "Szablony metod oceny ryzyka zawodowego",
        url: "/super-admin/draft/ra-method",
        notExact: true,
      },
      {
        name: "Szablony wzorcowych ocen ryzyka zawodowego",
        url: "/super-admin/draft/risk-assessment",
        notExact: true,
      },
      {
        name: "Szablony list kontrolnych",
        url: "/super-admin/draft/risk-assessment-control-list-template",
        notExact: true,
      },
      {
        name: "Szablony rodzajów uprawnień kwalifikacyjnych",
        url: "/super-admin/draft/qe-type",
        notExact: true,
      },
      {
        name: "Szablony wzorcowych zagrożeń",
        url: "/super-admin/draft/model-hazards",
        notExact: true,
      },
    ]}
  />
);

export default LSideMenu;
