import React, { Component } from "react";
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let StyledEditor = styled.div`
  margin-bottom: 20px;
  & .ck.ck-editor__editable_inline {
    min-height: 200px;
  }
`;

export default class TextEditor extends Component {
  render() {
    let { data, disabled } = this.props;

    return (
      <StyledEditor>
        <CKEditor
          editor={ClassicEditor}
          height={200}
          data={data}
          disabled={disabled}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            let data = editor.getData();
            this.props.onChange(data);
          }}
          onBlur={(event, editor) => {
            // console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            // console.log("Focus.", editor);
          }}
        />
      </StyledEditor>
    );
  }
}
