const defaultState = {
  data: [],
  singleData: {},
  fetching: false,
  fetched: true,
  types: [],
  type: {},
  permission: {},
};
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "GET_OSH_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, data, permission };
    }

    case "GET_OSH_TYPE_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;
      return { ...state, types: data, permission };
    }

    case "GET_OSH_SINGLE_SUCCESS": {
      const { data } = action.payload.data;

      return { ...state, singleData: data };
    }
  }

  return state;
}
