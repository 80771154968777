import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";

import { getUserByWorkplaceByUser } from "actions/userActions";
import { getUserSections } from "actions/sectionActions";
import { getAuditResponsivePerson } from "actions/auditActions";

import { LayoutContent } from "./Layout";
import Box from "./Box";
import DayPicker from "../lib/DayPicker";
import Select from "components/Select";
import { StyledLabel } from "style/styleComponents/Form/input";

import { ButtonWrapper } from "./ListComponents";
import AppComponent from "./AppComponent";
import moment from "moment";
import validator from "./Validation";
import { composeValidators } from "./Validation";
import { LinkContainer } from "./LinkContainer";
import { FieldSelect, FieldDayPicker } from "components/Fields";

const StyledLabelExtend = styled(StyledLabel)`
  padding-top: 10px;
`;

const TwoInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5px;
  margin-bottom: 10px;
  & > * {
    flex-basis: 48%;
  }
`;

class AuditDataForm extends AppComponent {
  constructor(props) {
    super(props);
    this.handleLocalizationChange = this.handleLocalizationChange.bind(this);
    this.handleWorkplaceChange = this.handleWorkplaceChange.bind(this);
  }

  handleLocalizationChange(form) {
    return (localization_id) => {
      const { dispatch } = this.props;
      getUserSections(localization_id)(dispatch).then(
        this.handleServerResponse()
      );

      // const form = this.form.form;
      if (!form.getFieldState("users_id").value) {
        getAuditResponsivePerson(localization_id)(dispatch).then(
          this.handleServerResponse({}, (action) => {
            const { responsiblePerson } = action.payload.data;
            if (responsiblePerson) {
              const { workplace_id, users_id } = responsiblePerson;
              form.getFieldState("workplace_id").change(workplace_id);
              getUserByWorkplaceByUser(workplace_id)(dispatch).then(
                this.handleServerResponse({}, () => {
                  form.getFieldState("users_id").change(users_id);
                })
              );
            }
          })
        );
      }
    };
  }

  handleWorkplaceChange(workplace_id) {
    const { dispatch } = this.props;
    getUserByWorkplaceByUser(workplace_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  render() {
    const {
      auditData = {},
      typeData,
      localizationData,
      sectionData,
      workplaceData,
      usersData,
      submit,
      register,
      message,
      readOnly,
      audit_id,
      SideMenu,
    } = this.props;
    const { dictionary_progress_id, audit_type_title } = auditData;
    auditData.section_id = auditData.section_id || "0";
    return (
      <LayoutContent
        error404={this.props.error_code}
        message={message}
        SideMenu={SideMenu}
      >
        <Box title="Wprowadź dane audytu">
          <Form
            ref={(form) => (this.form = form)}
            onSubmit={submit}
            initialValues={{
              date: moment().format("YYYY-MM-DD"),
              ...auditData,
            }}
            render={({ handleSubmit, pristine, invalid, values, form }) => (
              <form onSubmit={handleSubmit}>
                <FieldDayPicker
                  name="date"
                  validate={validator.required}
                  label="Data audytu"
                  disabled={readOnly}
                />
                {dictionary_progress_id != 7 ? (
                  <Select
                    label="Rodzaj audytu"
                    disabled={readOnly}
                    fullWidth
                    title="-- wybierz --"
                    disabled={readOnly}
                    data={[
                      {
                        id: "0",
                        name: audit_type_title,
                        disabled: true,
                      },
                    ]}
                    propValue={"id"}
                    selected={"0"}
                  />
                ) : (
                  <FieldSelect
                    name="audit_type_id"
                    title="-- wybierz --"
                    label="Rodzaj audytu"
                    validate={validator.required}
                    disabled={readOnly}
                    data={
                      typeData.length
                        ? typeData.map((item) => ({
                            ...item,
                            name: item.title,
                          }))
                        : [
                            {
                              id: null,
                              name: "Nie znaleziono audytu do wyboru",
                              disabled: true,
                            },
                          ]
                    }
                  />
                )}

                <FieldSelect
                  name="localization_id"
                  validate={validator.required}
                  label="Lokacja"
                  disabled={readOnly}
                  data={
                    localizationData.length
                      ? localizationData
                      : [
                          {
                            id: null,
                            name: "Brak zdefiniowanych danych",
                            disabled: true,
                          },
                        ]
                  }
                  handleChange={(value, input) => {
                    this.handleLocalizationChange(form)(value);
                    input.onChange(value);
                  }}
                />
                <FieldSelect
                  name="section_id"
                  label="Dział"
                  disabled={readOnly}
                  data={[{ id: "0", name: "Brak" }].concat(sectionData)}
                />

                <StyledLabelExtend>
                  Osoba przeprowadzająca audyt
                </StyledLabelExtend>
                <TwoInputWrapper>
                  <Field
                    name="workplace_id"
                    render={({ input, meta, ...rest }) => (
                      <Select
                        label="Stanowisko"
                        disabled={readOnly}
                        fullWidth
                        title="-- wybierz --"
                        data={
                          workplaceData.length
                            ? workplaceData
                            : [
                                {
                                  id: null,
                                  name: "Brak zdefiniowanych danych.",
                                  disabled: true,
                                },
                              ]
                        }
                        propValue={"id"}
                        selected={input.value}
                        handleChange={(value) => {
                          this.handleWorkplaceChange(value);
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name="users_id"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <Select
                        label="Osoba"
                        disabled={readOnly}
                        fullWidth
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        title="-- wybierz --"
                        data={
                          usersData.length
                            ? usersData
                            : [{ id: null, name: "Brak.", disabled: true }]
                        }
                        propValue={"id"}
                        selected={input.value}
                        handleChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                </TwoInputWrapper>
                {/*TODO Must powiadomienia*/}
                <ButtonWrapper>
                  {readOnly ? (
                    <LinkContainer to={`/audit/question/${audit_id}`}>
                      <Button bsStyle="primary">Dalej</Button>
                    </LinkContainer>
                  ) : (
                    <Button type="submit" bsStyle="primary">
                      {register ? "Zapisz" : "Dalej"}
                    </Button>
                  )}
                  <LinkContainer to={`/audit/`} exact>
                    <Button>Anuluj</Button>
                  </LinkContainer>
                </ButtonWrapper>
              </form>
            )}
          />
        </Box>
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  users: store.users,
}))(AuditDataForm);
