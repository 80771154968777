import React from "react";
import styled from "styled-components";
import {
  StyledInputBox,
  StyledInput,
} from "../../style/styleComponents/Form/input";
import Select from "../Select";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import { media } from "../../style/style-utils";
import { StyledTable } from "../ListComponents";

import Media from "react-media";

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  ${media.sm`
    flex-wrap: wrap;
  `}
`;

const FilterI = styled.div`
  display: flex;
  align-items: flex-start;
  ${media.sm`
    flex-wrap:wrap;
    justify-content:space-between;
    width:100%;
    margin-bottom:10px;
  `}
  ${media.xs`
    flex-direction:column;
    align-items: stretch;
  `}
  & > * {
    margin: 0 5px;
  }
  & > .btn-group {
    & > button {
      ${media.xs`
            width: 100%;
  
      `}
    }
    & > ul.dropdown-menu {
      width: 100%;
    }
  }
`;

const FilterII = styled.div`
  display: flex;
  margin: 0 -5px;
  & > * {
    margin: 0 5px;
  }
  & > span {
  }
  ${media.sm`
    justify-content: space-between;
    width:100%;

  `}
`;

const MarginBottomDiv = styled.div`
  min-width: "105px";
  ${media.sm`
    margin-bottom:10px
  `}
`;

const FilterStyledInput = styled(StyledInput)`
  ${media.sm`
    margin-bottom:10px;
  `}
`;

const LocalDivisionLocalizationSelect = ({
  division_id,
  localization_id,
  selected,
  ...props
}) => {
  return division_id ? (
    <Select
      style={{ width: "110px" }}
      selected={division_id}
      extended={["division"]}
      {...props}
    />
  ) : (
    <Select
      style={{ width: "110px" }}
      selected={localization_id}
      extended={["division"]}
      {...props}
    />
  );
};

class PersonnelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      name: "",
      division_id: null,
      localization_id: null,
      countOnPage: 10,
      page: 0,
      maxPages: 0,
    };
    this.disableFilter = this.disableFilter.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleChangeLocalization = this.handleChangeLocalization.bind(this);
    this.filter = this.filter.bind(this);
  }

  disableFilter() {
    let { employees } = this.props;

    this.setState({
      employees,
      name: "",
      division_id: null,
      localization_id: null,
    });
  }

  setPage(modify) {
    return () => {
      const result = this.state.page + modify;
      if (result > -1 && result < this.state.maxPages + 1) {
        this.setState({ page: this.state.page + modify });
      }
    };
  }

  handleFilterChange(propName, value) {
    this.setState({ [propName]: value });
  }

  handleChangeLocalization(data) {
    const { value, division } = data;
    if (division) {
      this.setState({ division_id: value, localization_id: null });
    } else {
      this.setState({ division_id: null, localization_id: value });
    }
  }

  handlePageChange(page) {
    const { countOnPage } = this.state;
    const { employees } = this.props;
    const newData = [...employees];
    return newData.splice(page * countOnPage, countOnPage);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if(nextProps.employees !== this.props.employees){return true}
    if (
      JSON.stringify(nextState.employees) !=
      JSON.stringify(this.state.employees)
    ) {
      return true;
    }
    if (nextState.name !== this.state.name) {
      return true;
    }
    if (nextState.localization_id !== this.state.localization_id) {
      return true;
    }
    if (nextState.page !== this.state.page) {
      return true;
    }
    if (nextState.countOnPage !== this.state.countOnPage) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.filter();
  }

  componentDidUpdate() {}

  filter() {
    const {
      name,
      localization_id,
      division_id,
      page,
      countOnPage,
    } = this.state;
    const newRegex = new RegExp(name);
    const newEmployees = this.props.employees.filter(
      (item) =>
        (name && name.length ? newRegex.test(item.name) : true) &&
        (localization_id ? item.localization_id == localization_id : true) &&
        (division_id ? item.localization_division_id == division_id : true)
    );

    const newEmployeesToShow = newEmployees.slice(
      page * countOnPage,
      page * countOnPage + countOnPage
    );
    const maxPages = Math.ceil(newEmployees.length / countOnPage);

    this.setState({ employees: newEmployeesToShow, maxPages });
  }

  render() {
    const {
      page,
      countOnPage,
      division_id,
      localization_id,
      name,
      employees,
      maxPages,
    } = this.state;
    const { localizations, handleFilterChange } = this.props;
    const filterEmployers = employees;

    return (
      <div>
        <FilterWrapper>
          <FilterI>
            <FilterStyledInput
              ref={(input) => (this.nameInput = input)}
              placeholder="Imię i nazwisko"
              value={name}
              onChange={(event) => {
                this.handleFilterChange("name", event.target.value);
              }}
            />
            <MarginBottomDiv>
              <Media query="(max-width: 767px)">
                {(matches) =>
                  matches ? (
                    <LocalDivisionLocalizationSelect
                      division_id={division_id}
                      localization_id={localization_id}
                      ref={(input) => (this.localizationSelect = input)}
                      fullWidth
                      title={"Lokalizacja"}
                      id="localization-filter"
                      propValue={"id"}
                      selected={localization_id}
                      handleChange={this.handleChangeLocalization}
                      data={localizations}
                    />
                  ) : (
                    <LocalDivisionLocalizationSelect
                      ref={(input) => (this.localizationSelect = input)}
                      division_id={division_id}
                      localization_id={localization_id}
                      title={"Lokalizacja"}
                      id="localization-filter"
                      propValue={"id"}
                      selected={localization_id}
                      handleChange={this.handleChangeLocalization}
                      data={localizations}
                    />
                  )
                }
              </Media>
            </MarginBottomDiv>
            <Button type="button" onClick={this.filter}>
              Szukaj
            </Button>
            <Button type="button" onClick={this.disableFilter}>
              Wyłącz filtr
            </Button>
          </FilterI>
          <FilterII>
            <span>Pokaż wyników</span>
            <Select
              title={"10"}
              id="superior-filter"
              selected={countOnPage}
              handleChange={(value) => {
                this.setState({ countOnPage: value, page: 0 });
              }}
              data={[
                { value: 10, name: "10" },
                { value: 20, name: "20" },
                { value: 50, name: "50" },
                { value: 100, name: "100" },
                { value: 200, name: "200" },
                { value: 500, name: "500" },
              ]}
            />
          </FilterII>
        </FilterWrapper>
        <StyledTable firstNarrowColumn>
          <thead>
            <tr>
              <th>L.p.</th>
              <th>Imię i nazwisko</th>
              <th>Dywizja / lokalizacja</th>
            </tr>
          </thead>
          <tbody>
            {filterEmployers.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  {item.localization_division_name}{" "}
                  {item.localization_name ? `/ ${item.localization_name}` : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              width: "80px",
              display: "flex",
              padding: "0 8px 2px",
              justifyContent: "space-between",
              boxSizing: "content-box",
            }}
          >
            {page > 0 && (
              <Button onClick={this.setPage(-1)}>
                <i className="fa fa-chevron-left" />
              </Button>
            )}
            {page < maxPages - 1 && (
              <Button
                style={{ alignSelf: "flex-end", marginLeft: "auto" }}
                onClick={this.setPage(1)}
              >
                <i className="fa fa-chevron-right" />
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PersonnelList;
