import React from "react";
import { connect } from "react-redux";

import SideMenu from "../_SideMenu";

import {
  getRA,
  getHazardList,
  getRAControlListByQuestionAnswerYes,
  getRAControlListConfirmedThreats,
  addRAAnalysisHazard,
  getRAAnalysisHazard,
  deleteRAAnalysisHazard,
  getMethodEntry,
  getHazard,
  saveRAAnalysisHazard,
  changeOrderRAAnalysisHazard,
  clearRiskAssessment,
  setStageRA,
  saveHazard,
} from "actions/riskAssessmentAction";

import AppComponent from "components/AppComponent";
import AddRiskAssessmentReview from "components/RiskAssessment.Forms/Analysis";

import { setUrls } from "../../_staticData";
export class RAAnalysis extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      urls: setUrls(),
      draft: false,
    };

    this.addRAAnalysisHazard = this.addRAAnalysisHazard.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.addRAAnalysisHazardByThread = this.addRAAnalysisHazardByThread.bind(
      this
    );
    this.deleteRAAnalysisHazard = this.deleteRAAnalysisHazard.bind(this);
    this.saveHazard = this.saveHazard.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
    this.setNextStage = this.setNextStage.bind(this);
    this.saveModelHazard = this.saveModelHazard.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;

    getRAControlListByQuestionAnswerYes(id)(dispatch).then(
      this.handleServerResponse()
    );

    getRA(id, { readOnly: true })(dispatch).then(this.handleServerResponse());

    getMethodEntry(id)(dispatch).then(this.handleServerResponse());
    getHazardList({ readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
    getRAAnalysisHazard(id)(dispatch).then(this.handleServerResponse());

    getRAControlListConfirmedThreats(id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  addRAAnalysisHazard() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addRAAnalysisHazard(id)(dispatch).then(this.handleServerResponse());
  }

  addRAAnalysisHazardByThread(ra_control_list_question_id) {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addRAAnalysisHazard(id, { ra_control_list_question_id })(dispatch).then(
      this.handleServerResponse()
    );
  }

  addCategory() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    addRAAnalysisHazard(id, { category: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  changeOrder(data) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;
    changeOrderRAAnalysisHazard(
      ra_id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  deleteRAAnalysisHazard(id) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;

    return () => {
      deleteRAAnalysisHazard(
        ra_id,
        id
      )(dispatch).then(this.handleServerResponse());
    };
  }

  renderSideMenu() {
    const { match, permission, data } = this.props;
    const { id } = match.params;
    return <SideMenu id={id} stage={data.stage} />;
  }

  saveHazard(data) {
    let { dispatch, match } = this.props;
    let { id: ra_id } = match.params;
    let { id } = data;
    saveRAAnalysisHazard(
      ra_id,
      id,
      data
    )(dispatch).then(this.handleServerResponse());
  }

  saveModelHazard(data) {
    let { dispatch, history } = this.props;
    saveHazard(null, data)(dispatch).then(this.handleServerResponse());
  }

  setNextStage() {
    let { urls } = this.state;
    let { dispatch, match, history } = this.props;
    let { id } = match.params;

    setStageRA(
      id,
      4
    )(dispatch).then(
      this.handleServerResponse({}, (action) => {
        history.push(urls.TASK(id));
      })
    );
  }

  render() {
    let { urls, draft } = this.state;
    let {
      controlList,
      modelHazardList,
      hazards,
      method,
      match,
      permission,
      data,
      controlListThreads,
    } = this.props;
    let { id } = match.params;

    const readOnly = Boolean(data.business_id === 0) || !permission[24];

    return (
      <AddRiskAssessmentReview
        addCategory={this.addCategory}
        addRAAnalysisHazard={this.addRAAnalysisHazard}
        addRAAnalysisHazardByThread={this.addRAAnalysisHazardByThread}
        changeOrder={this.changeOrder}
        controlList={controlList}
        controlListThreads={controlListThreads}
        copyRAControlList={this.copyRAControlList}
        data={data}
        deleteRAAnalysisHazard={this.deleteRAAnalysisHazard}
        draft={draft}
        getGetHazard={getHazard}
        hazards={hazards}
        id={id}
        modelHazardList={modelHazardList}
        method={method}
        permission={permission}
        readOnly={readOnly}
        saveHazard={this.saveHazard}
        saveModelHazard={this.saveModelHazard}
        SideMenu={this.renderSideMenu()}
        setNextStage={this.setNextStage}
        urls={urls}
      />
    );
  }

  componentWillUnmount() {
    clearRiskAssessment()(this.props.dispatch);
  }
}

export default connect((store) => ({
  data: store.riskAssessment.singleData,
  permission: store.riskAssessment.permission,
  controlList: store.riskAssessment.raControlList,
  modelHazardList: store.riskAssessment.modelHazardList,
  hazards: store.riskAssessment.raHazards,
  method: store.riskAssessment.raMethodEntry,
  controlListThreads: store.riskAssessment.controlListThreads,
}))(RAAnalysis);
