import React from "react";
import { connect } from "react-redux";

import { saveDraftHazard } from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import Form from "components/ModelHazard/Form";

import SideMenu from "../_SideMenu";

let urls = {
  LIST: "/super-admin/draft/model-hazards",
};

class ModelHazardList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      urls,
    };

    this.submit = this.submit.bind(this);
  }

  submit(data) {
    let { dispatch, history } = this.props;
    saveDraftHazard(
      null,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(this.state.urls.LIST);
      })
    );
  }

  render() {
    return <Form superAdmin submit={this.submit} SideMenu={SideMenu} />;
  }
}

export default connect((store) => ({
  modelHazardList: store.riskAssessment.ModelHazardList,
}))(ModelHazardList);
