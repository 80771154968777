export const getLocalizationDivisionName = ({
  division_name,
  localization_name,
  localization_division_name,
}) => {
  if (localization_division_name) {
    return `${localization_division_name} / ${localization_name}`;
  }
  if (division_name) {
    return `${division_name}`;
  }
  return localization_name;
};
