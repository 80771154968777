import React from "react";
import { connect } from "react-redux";

import AppComponent from "components/AppComponent";
import QualificationEntitlementsTypeForm from "components/QE/FormType";
import SideMenu from "../_SideMenu";
import { getDraftQEType, saveDraftQEType } from "actions/QEActions";
import { setUrls } from "./_staticData";

let names = {
  TITLE: "Edytuj rodzaj uprawnień",
};
class Edit extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, urls: setUrls() };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;
    getDraftQEType(id)(dispatch).then(this.handleServerResponse());
  }

  submit(data) {
    const { dispatch, match } = this.props;
    let { id } = match.params;
    saveDraftQEType(id, data)(dispatch).then(this.handleServerResponse());
  }

  render() {
    const { urls, message } = this.state;
    const { data } = this.props;
    return (
      <QualificationEntitlementsTypeForm
        SideMenu={SideMenu}
        title="Dodaj rodzaj uprawnień"
        names={names}
        submit={this.submit}
        urls={urls}
        data={data}
        message={message}
        superAdminView
      />
    );
  }
}

export default connect((store) => ({
  data: store.qe.type,
  message: store.message,
}))(Edit);
