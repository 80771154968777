import React from "react";
import styled from "styled-components";

const AAA = styled.div`
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const AAB = styled.div`
  animation: fadein 0.5s;

  @keyframes fadein {
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
  }
`

const FetchingComponent = props =>(
  <AAA>
    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </AAA>
)

const FetchingContainer = props => {
  if(props.fetched) {
    return ( <AAB>{props.children}</AAB>);
  }
  else{
    return (<FetchingComponent/>);
  }
}

export default FetchingContainer