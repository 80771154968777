export default function reducer(
  state = {
    data: [],
    singleData: {},
    fetching: false,
    fetched: true,
  },
  action
) {
  switch (action.type) {
    case "GET_RESPONSIBILITIES_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_RESPONSIBILITIES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.responsibilities,
      };
    }
    case "GET_RESPONSIBILITIES_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "ASSIGN_USER_TO_RESPONSIBILITY_SUCCESS": {
      const { responsibility_id, ...rest } = action.payload.data.responsibility;
      const index = state.data.findIndex(
        (item) => item.id == responsibility_id
      );
      if (index > -1) {
        const newResponsibilities = [...state.data];
        const newResponsibility = { ...state.data[index], ...rest };
        newResponsibilities[index] = newResponsibility;
        return {
          ...state,
          fetching: false,
          fetched: true,
          data: newResponsibilities,
        };
      }

      return { ...state, fetching: false, fetched: true };
    }
  }

  return state;
}
