export function login(data){
  return dispatch => dispatch({
    types: ["LOGIN_SUPER_ADMIN_REQUEST","LOGIN_SUPER_ADMIN_SUCCESS","LOGIN_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function logout(data){
  return dispatch => dispatch({
    types: ["LOGOUT_SUPER_ADMIN_REQUEST","LOGOUT_SUPER_ADMIN_SUCCESS","LOGOUT_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/logout",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function sendResetRequest(data){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_SUPER_ADMIN_REQUEST","RESET_PASSWORD_SUPER_ADMIN_SUCCESS","RESET_PASSWORD_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/reset-password",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function checkResetPasswordToken(token){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_REQUEST","RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_SUCCESS","RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/reset-password/"+token,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function sendNewPassword(token,data){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_CONFIRM_SUPER_ADMIN_REQUEST","RESET_PASSWORD_CONFIRM_SUPER_ADMIN_SUCCESS","RESET_PASSWORD_CONFIRM_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/reset-password/"+token,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}

export function checkResetAccountToken(token){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_REQUEST","RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_SUCCESS","RESET_PASSWORD_TOKEN_CHECK_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/reset-account/"+token,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  });
}

export function sendNewAccountPassword(token,data){
  return dispatch => dispatch({
    types: ["RESET_PASSWORD_CONFIRM_SUPER_ADMIN_REQUEST","RESET_PASSWORD_CONFIRM_SUPER_ADMIN_SUCCESS","RESET_PASSWORD_CONFIRM_SUPER_ADMIN_ERROR"],
    payload:{
      request: {
        url: "/super-admin/reset-account/"+token,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data:data
      },
    }
  });
}
