import React from "react";
import PropTypes from "prop-types";

let sortSwitch = (value) => {
  switch (value) {
    case 0:
      return 1;
    case 1:
      return -1;
    default:
      return 0;
  }
};

export let sortData = ({ sortProp, stateData, sort_direction, sortType }) => {
  return stateData.sort(function (a, b) {
    if (sortType == "string") {
      let ta = typeof a[sortProp] !== "string" ? a.toString() : a[sortProp];
      let tb = typeof b[sortProp] !== "string" ? b.toString() : b[sortProp];
      return (
        ta.localeCompare(tb, "pl", {
          caseFirst: "lower",
          numeric: true,
          sensitivity: "base",
        }) * sort_direction
      );
    } else {
      return a[sortProp] > b[sortProp] ? sort_direction : sort_direction * -1;
    }
  });
};

export class TableSortHeader extends React.Component {
  constructor(props) {
    let headers = (props.headers || []).map((item) => ({ ...item, sort: 0 }));
    super(props);
    this.state = {
      ...this.state,
      data: [...props.data] || [],
      headers,
      sort: {
        direction: 0,
        prop: "",
      },
    };
    this.sort = this.sort.bind(this);
  }

  componentDidUpdate() {
    if (this.state.data.length !== this.props.data.length)
      this.setState({
        data: [...this.props.data],
      });
  }

  sort(index) {
    return () => {
      let newData = [...this.state.headers];
      let sort_direction = 0;
      let sort_prop;
      let sort_type;
      newData = newData.map((item, subindex) => {
        if (subindex == index) {
          sort_direction = sortSwitch(item.sort);
          sort_prop =
            sort_direction == 1 || sort_direction == -1 ? item.prop : undefined;
          sort_type =
            sort_direction == 1 || sort_direction == -1
              ? item.sort_type
              : undefined;
          return {
            ...item,
            sort: sort_direction,
          };
        } else {
          return {
            ...item,
            sort: 0,
          };
        }
      });

      this.setState({
        headers: newData,
        sort: {
          ...this.state.sort,
          direction: sort_direction,
          prop: sort_prop,
          type: sort_type,
        },
      });
    };
  }

  render() {
    let sort_direction = this.state.sort.direction;
    let sortProp = this.state.sort.prop;
    let sortType = this.state.sort.type;
    return (
      <React.Fragment>
        <tr>
          {this.state.headers.map((item, index) => {
            return (
              <td
                style={{
                  cursor: item.prop && this.sort(index) ? "pointer" : "default",
                }}
                onClick={item.prop && this.sort(index)}
              >
                {item.name}{" "}
                {item.sort ? (
                  <i
                    className={`fa fa-long-arrow-${
                      item.sort > 0 ? "up" : "down"
                    }`}
                  />
                ) : item.prop ? (
                  <>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <i className={`fa fa-long-arrow-up`} />
                      <i className={`fa fa-long-arrow-down`} />
                    </span>
                  </>
                ) : (
                  ""
                )}
              </td>
            );
          })}
        </tr>
        {this.props.render(
          sortProp
            ? sortData({
                sortProp,
                stateData: this.state.data,
                sort_direction,
                sortType,
              })
            : this.props.data
        )}
      </React.Fragment>
    );
  }
}

export class TableSortable extends TableSortHeader {
  constructor(props) {
    super(props);
  }

  render() {
    let sort_direction = this.state.sort.direction;
    let sortProp = this.state.sort.prop;
    let sortType = this.state.sort.type;

    return (
      <React.Fragment>
        <thead>
          <tr>
            {this.state.headers.map((item, index) => {
              return (
                <th onClick={item.prop && this.sort(index)}>
                  {item.name}{" "}
                  {item.sort ? (
                    <i
                      className={`fa fa-long-arrow-${
                        item.sort > 0 ? "up" : "down"
                      }`}
                    />
                  ) : (
                    ""
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {this.props.render(
            sortProp
              ? sortData({
                  sortProp,
                  stateData: this.state.data,
                  sort_direction,
                  sortType,
                  propsData: this.props.data,
                })
              : this.props.data
          )}
        </tbody>
      </React.Fragment>
    );
  }
}
export default TableSortable;
TableSortHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      prop: PropTypes.string,
    })
  ),
  render: PropTypes.func,
};
