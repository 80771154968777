let defaultState = {
  accident_cause: [],
  attachmentAddFetching: false,
  attachments: [],
  data: [],
  incident_witnesses: [],
  fetching: false,
  fetched: true,
  fetchingInput: false,
  fetchedInput: true,
  fetchingSave: false,
  permission: {},
  progresses: [],
  singleData: {},
  responsible_user: {
    workpalce_id: null,
    name: null,
    responsible_user_id: null,
  },
  victim_explain: {},
};

export default function reducer(state = { ...defaultState }, action) {
  switch (action.type) {
    case "GET_INCIDENTS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_INCIDENTS_SUCCESS": {
      const { incidents, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: incidents,
        permission,
      };
    }
    case "GET_INCIDENTS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_INCIDENTS_BY_FILTER_REQUEST": {
      return { ...state, fetching: true, fetched: true };
    }
    case "GET_INCIDENTS_BY_FILTER_SUCCESS": {
      const { incidents, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: incidents,
        permission,
      };
    }
    case "GET_INCIDENTS_BY_FILTER_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "UPDATE_FIELD_INCIDENT_REQUEST": {
      return { ...state, fetchingInput: true };
    }
    case "UPDATE_FIELD_INCIDENT_SUCCESS": {
      return { ...state, fetchingInput: false };
    }
    case "UPDATE_FIELD_INCIDENT_ERROR": {
      return { ...state, fetchingInput: false };
    }
    case "GET_INCIDENT_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_INCIDENT_SUCCESS": {
      const { incident, permission = {} } = action.payload.data;
      incident.incident_has_person = incident.incident_has_person || {};
      let { user_id, full_name, ...rest } = incident.incident_has_person;
      incident.incident_has_person = {
        ...rest,
        full_name,
        user: { id: user_id, full_name },
      };

      let dictionary = [
        "protocol",
        "statistic_card",
        "work_accident",
        "part_one",
        "part_two",
      ];

      let incident_edited = incident;

      let {
        explain_place,
        explain_date,
        explain_description,
      } = incident.incident_has_person ? incident.incident_has_person : {};

      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: incident_edited,
        victim_explain: {
          explain_place,
          explain_date,
          explain_description,
        },
        permission,
      };
    }
    case "GET_INCIDENT_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_INCIDENT_ATTACHMENTS_REQUEST": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_INCIDENT_ATTACHMENTS_SUCCESS": {
      const { attachments, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: false,
        attachments: attachments,
        permission,
      };
    }
    case "GET_INCIDENT_ATTACHMENTS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "DELETE_INCIDENT_ATTACHMENT_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        attachments: state.attachments.filter(
          (item) => item.id != action.payload.data.attachment.id
        ),
      };
    }
    case "ADD_INCIDENT_ATTACHMENT_REQUEST": {
      return { ...state, attachmentAddFetching: true };
    }
    case "ADD_INCIDENT_ATTACHMENT_SUCCESS": {
      const newAttachment = [...state.attachments];
      newAttachment.push(action.payload.data.attachment);
      return {
        ...state,
        attachments: newAttachment,
        attachmentAddFetching: false,
      };
    }
    case "ADD_INCIDENT_ATTACHMENT_ERROR": {
      return { ...state, attachmentAddFetching: false };
    }
    case "GET_INCIDENT_PROGRESS_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        progresses: action.payload.data.progresses,
      };
    }
    case "DELETE_DRAFT_INCIDENT_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data.filter(
          (item) => item.id != action.payload.data.incident.id
        ),
      };
    }
    case "CHANGE_TYPE_INCIDENT_SUCCESS": {
      const { id, type_id, symbol } = action.payload.data.incident;
      const index = state.data.findIndex((item) => item.id == id);
      if (index > -1) {
        const newIncident = { ...state.data[index], type_id, symbol };
        const newData = [...state.data];
        newData[index] = newIncident;
        return { ...state, fetching: false, fetched: true, data: newData };
      }
      return state;
    }
    case "REGISTER_INCIDENT_REQUEST": {
      return { ...state, fetchingSave: true };
    }
    case "REGISTER_INCIDENT_SUCCESS": {
      return { ...state, fetchingSave: false };
    }
    case "REGISTER_INCIDENT_ERROR": {
      return { ...state, fetchingSave: false };
    }
    case "ADD_INCIDENT_SUCCESS": {
      return { ...state, singleData: {} };
    }
    case "DOWNLOAD_INCIDENT_REQUEST": {
      const { index } = action.payload.request.data;
      const newAttachments = [...state.attachments];
      newAttachments[index].fetching = true;
      return { ...state, attachments: newAttachments };
    }
    case "DOWNLOAD_INCIDENT_SUCCESS": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newAttachments = [...state.attachments];
      newAttachments[index].fetching = false;
      return { ...state, attachments: newAttachments };
    }
    case "DOWNLOAD_INCIDENT_ERROR": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newAttachments = [...state.attachments];
      newAttachments[index].fetching = false;
      return { ...state, attachments: newAttachments };
    }
    case "GET_INCIDENT_WITNESS_SUCCESS": {
      const incident_witnesses = action.payload.data.incident_witnesses;

      return { ...state, incident_witnesses };
    }
    case "SAVE_INCIDENT_WITNESS_SUCCESS": {
      const incident_witness = action.payload.data.witness;
      let index = state.incident_witnesses.findIndex((item) => {
        return item.id == incident_witness.id;
      });
      if (index > -1) {
        state.incident_witnesses[index] = incident_witness;
      } else {
        state.incident_witnesses.push(incident_witness);
      }
      return { ...state };
    }
    case "INCIDENT_HAS_PERSON_SUCCESS": {
      let { singleData } = state;
      let { incidentPerson } = action.payload.data;

      let { user_id, full_name, ...rest } = incidentPerson;
      let newIncidentPerson = {
        ...rest,
        full_name,
        user: { id: user_id, full_name },
      };

      let newSingleData = {
        ...singleData,
        incident_has_person: newIncidentPerson,
      };

      return { ...state, singleData: newSingleData };
    }
    case "UPDATE_GUS_REQUEST": {
      const incidentData = action.payload.data;
      return {
        ...state,
        singleData: { ...state.singleData, ...incidentData },
      };
    }
    case "UPDATE_GUS_SUCCESS": {
      const incidentData = action.payload.data.incident;
      return {
        ...state,
        singleData: { ...state.singleData, ...incidentData },
      };
    }
    case "UPDATE_GUS_IN_LIST_SUCCESS": {
      const incidentData = action.payload.data.incident;

      let index = state.data.findIndex((item) => {
        return item.id == incidentData.id;
      });

      let newData = [...state.data];
      newData[index] = { ...newData[index], ...incidentData };
      return {
        ...state,
        data: newData,
      };
    }
    case "GET_RESPONSIBLE_USER_INCIDENT_SUCCESS": {
      const { user } = action.payload.data;

      return { ...state, responsible_user: user || {} };
    }
    case "FORWARD_INCIDENT_SUCCESS": {
      const { user } = action.payload.data;

      return { ...state, responsible_user: user || {} };
    }
    case "SAVE_VICTIM_EXPLAIN_SUCCESS": {
      const { victim } = action.payload.data;

      return { ...state, victim_explain: victim || {} };
    }
    case "ANALYSIS_INCIDENT_REQUEST": {
      return { ...state, fetching: true };
    }
    case "ANALYSIS_INCIDENT_SUCCESS": {
      const { stage } = action.payload.data;

      return {
        ...state,
        fetching: false,
        singleData: { ...state.singleData, stage },
      };
    }
    case "ANALYSIS_INCIDENT_ERROR": {
      return { ...state, fetching: false };
    }
    case "DELETE_INCIDENT_WITNESS_SUCCESS": {
      const { witness } = action.payload.data;

      let incident_witnesses = state.incident_witnesses.filter(
        (item) => item.id != witness.id
      );

      return { ...state, incident_witnesses };
    }
    case "NEXT_STAGE_INCIDENT_SUCCESS": {
      const { stage } = action.payload.data;

      return { ...state, singleData: { ...state.singleData, stage } };
    }
    case "CLEAR_INCIDENT": {
      return {
        ...defaultState,
      };
    }
  }

  return state;
}
