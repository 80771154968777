import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";

import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import Select from "../../components/Select";
import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
  StyledTextareaBox,
} from "../../style/styleComponents/Form/input";
import {
  StyledTableWrapper,
  // StyledTable,
} from "../../components/ListComponents";
import validator from "../Validation/";
import { TableSortHeader } from "../TableSortable";

import { ButtonWrapper } from "../ListComponents";
import FormModal from "../Modal/FormModal";
import { StyledTable } from "./_stylesComponents";

export default class ListWithModalExec extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: {
        open: false,
        addFunction: () => {},
        initialData: {},
        handleOpen: this.handleOpenModal.bind(this)("modalAdd"),
        handleClose: this.handleCloseModal.bind(this)("modalAdd"),
      },
    };
    this.addTableEntry = this.addTableEntry.bind(this);
  }

  addTableEntry(data) {
    this.state.modalAdd.addFunction(data);
  }

  handleOpenModal(modalName, addFunction, initialValues = {}) {
    return () => {
      this.setState({
        [modalName]: {
          ...this.state[modalName],
          open: true,
          addFunction,
          initialValues,
        },
      });
    };
  }

  handleCloseModal(modalName) {
    return () => {
      this.setState({
        [modalName]: {
          ...this.state[modalName],
          open: false,
          addFunction: () => {},
        },
      });
    };
  }

  render() {
    let {
      values = [],
      tableLabel,
      tableAddButtonName,
      FormModal,
      tableFieldName,
      handleSubmit,
      tableHeader = [],
      tableContentCallback,
    } = this.props;
    values = values || [];
    return (
      <Fragment>
        <FieldArray name={tableFieldName}>
          {({ fields }) => (
            <div>
              <ButtonWrapper>
                <Button onClick={this.handleOpenModal("modalAdd", fields.push)}>
                  {tableAddButtonName}
                </Button>
              </ButtonWrapper>
              <h5>{tableLabel}</h5>
              <StyledTableWrapper>
                <StyledTable striped bordered hover>
                  <TableSortHeader
                    headers={tableHeader}
                    data={values.map((item, index) => {
                      return { ...item, logicIndex: index };
                    })}
                    render={(sortedData) => {
                      return sortedData.map(tableContentCallback(fields));
                    }}
                  ></TableSortHeader>
                </StyledTable>
              </StyledTableWrapper>
            </div>
          )}
        </FieldArray>
        <FormModal
          modalSettings={this.state.modalAdd}
          handleSubmit={handleSubmit || this.addTableEntry}
        />
      </Fragment>
    );
  }
}
