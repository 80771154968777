import React from "react";
import SideMenu from "../../components/SideMenu";

const LSideMenu = ({
  accident_id,
  stage,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <div>
    <SideMenu
      title="B / S / U"
      data={[
        { name: "Badania profilaktyczne", url: "/medical-examination" },
        { name: "Szkolenia BHP", url: "/osh" },
        { name: "Uprawnienia", url: "/qualification-entitlements" },
        { name: "Formularze", url: "/forms" },
      ]}
    />
    <SideMenu
      title="Badania profilaktyczne"
      mobileNotHide
      data={[
        {
          name: "Rejestr badań lekarski",
          url: `/medical-examination`,
        },
        {
          name: "Wzory skierowań na badania",
          url: `/medical-examination/referral-list`,
        },
        {
          name: "Archiwum skierowań na badania",
          url: `/medical-examination/archived-referral-list`,
        },
      ]}
    />
  </div>
);

export default LSideMenu;
