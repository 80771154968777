import React from "react";
import { connect } from "react-redux";

import { getDraftHazard, saveDraftHazard } from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import Form from "components/ModelHazard/Form";

import SideMenu from "../_SideMenu";

let urls = {
  LIST: "/super-admin/draft/model-hazards",
};

class ModelHazardList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      urls,
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;

    getDraftHazard(id)(dispatch).then(this.handleServerResponse());
  }

  submit(data) {
    let { dispatch, history, match } = this.props;
    let { id } = match.params;
    saveDraftHazard(id, data)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { message } = this.state;
    let { modelHazardItem } = this.props;

    return (
      <Form
        superAdmin
        message={message}
        submit={this.submit}
        data={modelHazardItem}
        SideMenu={SideMenu}
      />
    );
  }
}

export default connect((store) => ({
  modelHazardItem: store.riskAssessment.singleData,
  message: store.message,
}))(ModelHazardList);
