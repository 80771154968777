import React, { Fragment } from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import styled from "styled-components";
import Modal from "react-bootstrap/lib/Modal";

import { TwoInputWrapper, ButtonWrapper } from "components/Wrappers";

import { LinkContainer } from "components/LinkContainer";
import ModalSchema from "components/Modal/./_Schema";
import { StyledTableWrapper } from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";

import { media } from "style/style-utils/index";
import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
import { getDate } from "./_common";
import OSHSegment from "components/OSH/OSHSegment";
const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

class SummaryMedicalExaminationsModal extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.addOSH = this.addOSH.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  addOSH() {
    let { addOSH } = this.props;
    // this.closeModal();
    addOSH();
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open, data = {}, permission } = this.props;

    let {
      date_initial,
      date_execution,
      ability_to_work_name,
      date_next,
      additiona_description,
      date_periodic,
      date_workplace,
      osh_training_description,
    } = this.props;

    let history = data && data.user_osh ? data.user_osh : [];
    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span> Podsumowanie szkoleń BHP pracownika</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h4>Dane ogólne</h4>
            <TwoInputWrapper>
              <div>
                <StyledInputBox
                  label="Imię"
                  value={data.first_name}
                  disabled={true}
                />
                <StyledInputBox
                  label="Nazwisko"
                  value={data.last_name}
                  disabled={true}
                />
                <StyledInputBox
                  label="Funkcja / stanowisko"
                  value={data.workplace_name}
                  disabled={true}
                />
              </div>
            </TwoInputWrapper>
            <OSHSegment
              data={data}
              addOSH={this.addOSH}
              readOnly={!permission[42]}
            />

            <ButtonWrapper>
              <Button
                bsStyle="primary"
                id="ModalListClose"
                onClick={this.closeModal}
              >
                Zapisz
              </Button>
            </ButtonWrapper>
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class SummaryMedicalExaminations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      open: true,
    };
  }

  openModal() {}

  render() {
    let { open, closeModal, addOSH } = this.props;

    return (
      <>
        <SummaryMedicalExaminationsModal {...this.props} />
      </>
    );
  }
}
