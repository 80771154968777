import { createURLParams, createURLParamsString } from "./_util";
function getParams(data = {}) {
  let params = new URLSearchParams();

  let keys = Object.keys(data);
  for (let k of keys) {
    params.set(k, data[k]);
  }

  return params ? `?${params}` : "";
}

export function addDraftRAMethod(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_RA_METHOD_REQUEST",
        "ADD_RA_METHOD_SUCCESS",
        "ADD_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: "/super-admin/draft/ra-method/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addRAMethod() {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_RA_METHOD_REQUEST",
        "ADD_RA_METHOD_SUCCESS",
        "ADD_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: "/ra-method/add",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteDraftRAMethod(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_RA_METHOD_REQUEST",
        "DELETE_RA_METHOD_SUCCESS",
        "DELETE_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra-method/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteBusinessRAMethod(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_RA_METHOD_REQUEST",
        "DELETE_RA_METHOD_SUCCESS",
        "DELETE_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/business/ra-method/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRAMethod(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_RA_METHOD_REQUEST",
        "DELETE_RA_METHOD_SUCCESS",
        "DELETE_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/ra-method/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftRAMethods(data = {}) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHODS_REQUEST",
        "GET_RA_METHODS_SUCCESS",
        "GET_RA_METHODS_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra-method/list${createURLParamsString(
            data
          )}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessRAMethods(business_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHODS_REQUEST",
        "GET_RA_METHODS_SUCCESS",
        "GET_RA_METHODS_ERROR",
      ],
      payload: {
        request: {
          url: `/business/ra-method/list`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAMethods(data = {}) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHODS_REQUEST",
        "GET_RA_METHODS_SUCCESS",
        "GET_RA_METHODS_ERROR",
      ],
      payload: {
        request: {
          url: `/ra-method/list${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftRAMethod(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_METHOD",
      payload: {
        request: {
          url: `/super-admin/draft/ra-method/edit/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessRAMethodsList(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHODS_REQUEST",
        "GET_RA_METHODS_SUCCESS",
        "GET_RA_METHODS_ERROR",
      ],
      payload: {
        request: {
          url: `/business/ra-methods${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAMethodsList(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHODS_REQUEST",
        "GET_RA_METHODS_SUCCESS",
        "GET_RA_METHODS_ERROR",
      ],
      payload: {
        request: {
          url: `/ra-methods${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftMethodEntry(ra_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHOD_REQUEST",
        "GET_RA_METHOD_ENTRY_SUCCESS",
        "GET_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra/method-entry/${ra_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getMethodEntry(ra_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHOD_REQUEST",
        "GET_RA_METHOD_ENTRY_SUCCESS",
        "GET_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/method-entry/${ra_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getBusinessMethod(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHOD_REQUEST",
        "GET_RA_METHOD_SUCCESS",
        "GET_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/business/ra-method/edit/${id}${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAMethod(id,data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_METHOD_REQUEST",
        "GET_RA_METHOD_SUCCESS",
        "GET_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/ra-method/edit/${id}${getParams(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addBusinessMethod(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_RA_METHOD_REQUEST",
        "ADD_RA_METHOD_SUCCESS",
        "ADD_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/business/ra-method/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addMethod(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_RA_METHOD_REQUEST",
        "ADD_RA_METHOD_SUCCESS",
        "ADD_RA_METHOD_ERROR",
      ],
      payload: {
        request: {
          url: `/ra-method/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveDraftRAMethod(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_RISK_ASSESSMENT_REQUEST",
        "EDIT_RISK_ASSESSMENT_SUCCESS",
        "EDIT_RISK_ASSESSMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra-method/edit/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveBusinessMethod(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_RISK_ASSESSMENT_REQUEST",
        "EDIT_RISK_ASSESSMENT_SUCCESS",
        "EDIT_RISK_ASSESSMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/business/ra-method/edit/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveMethod(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "EDIT_RISK_ASSESSMENT_REQUEST",
        "EDIT_RISK_ASSESSMENT_SUCCESS",
        "EDIT_RISK_ASSESSMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/ra-method/edit/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveDraftRA(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_RISK_ASSESSMENT_DRAFT_REQUEST",
        "SAVE_RISK_ASSESSMENT_DRAFT_SUCCESS",
        "SAVE_RISK_ASSESSMENT_DRAFT_ERROR",
      ],
      payload: {
        request: {
          url: id
            ? `/super-admin/draft/ra/basic/${id}`
            : `/super-admin/draft/ra/basic`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function copyRA(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "COPY_RISK_ASSESSMENT_REQUEST",
        "COPY_RISK_ASSESSMENT_SUCCESS",
        "COPY_RISK_ASSESSMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/copy/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addUpdateRA(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_UPDATE_RISK_ASSESSMENT_REQUEST",
        "ADD_UPDATE_RISK_ASSESSMENT_SUCCESS",
        "ADD_UPDATE_RISK_ASSESSMENT_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/add-update/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveRA(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_RISK_ASSESSMENT_DRAFT_REQUEST",
        "SAVE_RISK_ASSESSMENT_DRAFT_SUCCESS",
        "SAVE_RISK_ASSESSMENT_DRAFT_ERROR",
      ],
      payload: {
        request: {
          url: id ? `/ra/basic/${id}` : `/ra/basic`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function updateRA(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "SAVE_RISK_ASSESSMENT_DRAFT_REQUEST",
        "SAVE_RISK_ASSESSMENT_DRAFT_SUCCESS",
        "SAVE_RISK_ASSESSMENT_DRAFT_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/basic/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getDraftRAs(data = {}) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_LIST_REQUEST",
        "GET_RA_LIST_SUCCESS",
        "GET_RA_LIST_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra/list${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getRAs(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_LIST_REQUEST",
        "GET_RA_LIST_SUCCESS",
        "GET_RA_LIST_ERROR",
      ],
      payload: {
        request: {
          url: "/ra/list",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getRADrafts(data = {}) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_LIST_DRAFT_REQUEST",
        "GET_RA_LIST_DRAFT_SUCCESS",
        "GET_RA_LIST_DRAFT_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/list-draft${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAsFilter(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_LIST_REQUEST",
        "GET_RA_LIST_SUCCESS",
        "GET_RA_LIST_ERROR",
      ],
      payload: {
        request: {
          url: "/ra/list",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getRAsArchive() {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_LIST_REQUEST",
        "GET_RA_LIST_SUCCESS",
        "GET_RA_LIST_ERROR",
      ],
      payload: {
        request: {
          url: "/ra/archived-list",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAsArchiveFilter(data) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_LIST_REQUEST",
        "GET_RA_LIST_SUCCESS",
        "GET_RA_LIST_ERROR",
      ],
      payload: {
        request: {
          url: "/ra/archived-list",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteDraftRA(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_RA_DRAFT_REQUEST",
        "DELETE_RA_DRAFT_SUCCESS",
        "DELETE_RA_DRAFT_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRA(id) {
  return (dispatch) =>
    dispatch({
      types: ["DELETE_RA_REQUEST", "DELETE_RA_SUCCESS", "DELETE_RA_ERROR"],
      payload: {
        request: {
          url: `/ra/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRADraft(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_RA_DRAFT_REQUEST",
        "DELETE_RA_DRAFT_SUCCESS",
        "DELETE_RA_DRAFT_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftRA(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_ITEM_REQUEST",
        "GET_RA_ITEM_SUCCESS",
        "GET_RA_ITEM_ERROR",
      ],
      payload: {
        request: {
          url: `/super-admin/draft/ra/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRA(id, data = {}) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_RA_ITEM_REQUEST",
        "GET_RA_ITEM_SUCCESS",
        "GET_RA_ITEM_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/${id}${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAShadow(id, data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_ITEM_SHADOW",
      payload: {
        request: {
          url: `/ra/${id}${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftHazardList(data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_MODEL_HAZARD_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/model-hazard/list${createURLParamsString(
            data
          )}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getHazardList(data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_MODEL_HAZARD_LIST",
      payload: {
        request: {
          url: `/model-hazard/list${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftHazard(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_MODEL_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/model-hazard/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getHazard(id, data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_MODEL_HAZARD",
      payload: {
        request: {
          url: `/model-hazard/${id}${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveDraftHazard(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_MODEL_HAZARD",
      payload: {
        request: {
          url: id
            ? `/super-admin/draft/model-hazard/edit/${id}`
            : `/super-admin/draft/model-hazard/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveHazard(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_MODEL_HAZARD",
      payload: {
        request: {
          url: id ? `/model-hazard/edit/${id}` : `/model-hazard/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteDraftHazard(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_MODEL_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/model-hazard/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteHazard(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_MODEL_HAZARD",
      payload: {
        request: {
          url: `/model-hazard/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftControlListList(data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_CONTROL_LIST_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/control-list/list${createURLParamsString(
            data
          )}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getControlListList(data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_CONTROL_LIST_LIST",
      payload: {
        request: {
          url: `/control-list/list${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftControlList(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_CONTROL_LIST_ITEM",
      payload: {
        request: {
          url: `/super-admin/draft/control-list/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getControlList(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_CONTROL_LIST_ITEM",
      payload: {
        request: {
          url: `/control-list/${id}${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveDraftControlList(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_CONTROL_LIST",
      payload: {
        request: {
          url: id
            ? `/super-admin/draft/control-list/edit/${id}`
            : `/super-admin/draft/control-list/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveControlList(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_CONTROL_LIST",
      payload: {
        request: {
          url: id ? `/control-list/edit/${id}` : `/control-list/add`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteControlList(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_CONTROL_LIST",
      payload: {
        request: {
          url: `/control-list/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteDraftControlList(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_CONTROL_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/control-list/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftRAControlList(ra_id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list/${ra_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAControlList(ra_id, data = {}) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/ra-control-list/${ra_id}${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addDraftRAControlList(ra_id, data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list/add/${ra_id}`,

          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addRAControlList(ra_id, data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/ra-control-list/add/${ra_id}`,

          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteDraftRAControlList(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRAControlList(id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/ra-control-list/delete/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getDraftRAControlListQuestions(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST_QUESTIONS",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list-questions/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAControlListQuestions(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST_QUESTIONS",
      payload: {
        request: {
          url: `/ra-control-list-questions/${id}${createURLParamsString(data)}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveDraftRAControlListQuestions(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RA_CONTROL_LIST_QUESTIONS",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list-questions/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveRAControlListQuestions(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RA_CONTROL_LIST_QUESTIONS",
      payload: {
        request: {
          url: `/ra-control-list-questions/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addSARACLQuestionAttachment(id, data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RACL_QUESTION_ATTACHMENT",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list-questions/${id}/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function addRACLQuestionAttachment(id, data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RACL_QUESTION_ATTACHMENT",
      payload: {
        request: {
          url: `/ra-control-list-questions/${id}/add`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        },
      },
    });
}

export function deleteSARACLQuestionAttachment(id, attachment_id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RACL_QUESTION_ATTACHMENT",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list-questions/${id}/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRACLQuestionAttachment(id, attachment_id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RACL_QUESTION_ATTACHMENT",
      payload: {
        request: {
          url: `/ra-control-list-questions/${id}/delete/${attachment_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveDraftRACharacteristic(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RISK_ASSESSMENT_DRAFT",
      payload: {
        request: {
          url: `/super-admin/draft/ra-characteristic/save/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveRACharacteristic(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RISK_ASSESSMENT_DRAFT",
      payload: {
        request: {
          url: `/ra-characteristic/save/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getDraftRAControlListByQuestionAnswerYes(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/super-admin/draft/ra-control-list/${id}/by-question-yes`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAControlListByQuestionAnswerYes(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST",
      payload: {
        request: {
          url: `/ra-control-list/${id}/by-question-yes`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAControlListConfirmedThreats(ra_id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_CONTROL_LIST_CONFIRMED_THREATS",
      payload: {
        request: {
          url: `/risk-assessment/${ra_id}/control-list-confirmed-threats`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}


export function getDraftRAAnalysisHazard(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/risk-assessment/analysis/${id}/get-hazards`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAAnalysisHazard(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/risk-assessment/analysis/${id}/get-hazards`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addDraftRAAnalysisHazard(id, data = {}) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/risk-assessment/analysis/${id}/add-hazard`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addRAAnalysisHazard(id, data = {}) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/risk-assessment/analysis/${id}/add-hazard`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function addRAAnalysisHazardByControlList(id, ra_control_list_id) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/risk-assessment/analysis/${id}/add-hazard/${ra_control_list_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRAAnalysisHazardDraft(ra_id, id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/risk-assessment/analysis/${ra_id}/delete-hazard/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function deleteRAAnalysisHazard(ra_id, id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/risk-assessment/analysis/${ra_id}/delete-hazard/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function saveRAAnalysisHazardDraft(ra_id, id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/risk-assessment/analysis/${ra_id}/save-hazard/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function saveRAAnalysisHazard(ra_id, id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/risk-assessment/analysis/${ra_id}/save-hazard/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function changeOrderRAAnalysisHazardDraft(ra_id, data) {
  return (dispatch) =>
    dispatch({
      type: "CHANGE_ORDER_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/super-admin/draft/risk-assessment/analysis/${ra_id}/change-order`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function changeOrderRAAnalysisHazard(ra_id, data) {
  return (dispatch) =>
    dispatch({
      type: "CHANGE_ORDER_RA_ANALYSIS_HAZARD",
      payload: {
        request: {
          url: `/risk-assessment/analysis/${ra_id}/change-order`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getRAAHTMEntry(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_AHTM_ENTRY",
      payload: {
        request: {
          url: `/ra/task/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addRAAHTMEntry(ra_id, ra_analysis_hazard_id) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_AHTM_ENTRY",
      payload: {
        request: {
          url: `/ra/task/${ra_id}/hazard/${ra_analysis_hazard_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function getRAPermission(id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_PERMISSION",
      payload: {
        request: {
          url: `/ra/permission`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

//powiadomienia
export function getNotification(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATIONS_REQUEST",
        "GET_NOTIFICATIONS_SUCCESS",
        "GET_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}
export function getHistory(id) {
  return (dispatch) =>
    dispatch({
      types: [
        "GET_NOTIFICATION_HISTORY_REQUEST",
        "GET_NOTIFICATION_HISTORY_SUCCESS",
        "GET_NOTIFICATION_HISTORY_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}/history`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addUserToRegisterGroup(id, user_id) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_USER_TO_NOTIFICATION_GROUP_REQUEST",
        "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS",
        "ADD_USER_TO_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}/add-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: { user_id },
        },
      },
    });
}

export function deleteUserFromRegisterGroup(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_FROM_NOTIFICATION_GROUP_REQUEST",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_SUCCESS",
        "DELETE_USER_FROM_NOTIFICATION_GROUP_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}/delete-user`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerNotification(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "ADD_NOTIFICATIONS_REQUEST",
        "ADD_NOTIFICATIONS_SUCCESS",
        "ADD_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}/add-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function registerNotifications(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "REGISTER_NOTIFICATIONS_REQUEST",
        "REGISTER_NOTIFICATIONS_SUCCESS",
        "REGISTER_NOTIFICATIONS_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function deleteRegisteredNotification(id, data) {
  return (dispatch) =>
    dispatch({
      types: [
        "DELETE_USER_NOTIFICATION_REQUEST",
        "DELETE_USER_NOTIFICATION_SUCCESS",
        "DELETE_USER_NOTIFICATION_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/notification/${id}/delete-register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function archiveRA(data) {
  return (dispatch) =>
    dispatch({
      type: "ARCHIVE_RA",
      payload: {
        request: {
          url: "/ra/archive",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        },
      },
    });
}

export function getRAWorkers(ra_id) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_WORKERS",
      payload: {
        request: {
          url: `/ra/workers/${ra_id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addRAWorker(ra_id, data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_WORKERS",
      payload: {
        request: {
          url: `/ra/workers/add/${ra_id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function deleteRAWorker(ra_id, id) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_RA_WORKERS",
      payload: {
        request: {
          url: `/ra/workers/delete/${ra_id}/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    });
}

export function addRAWorkersByWorkplace(id, data) {
  return (dispatch) =>
    dispatch({
      type: "GET_RA_WORKERS",
      payload: {
        request: {
          url: `/ra/workers/add-workers-by-workplace/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function getNextSymbol(data) {
  return (dispatch) =>
    dispatch({
      type: "GET_NEXT_SYMBOL",
      payload: {
        request: {
          url: `/ra/get-next-symbol`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
      data,
    });
}

export function addAttachmentLocal(data) {
  return (dispatch) =>
    dispatch({
      type: "ADD_RA_ATTACHMENT_LOCAL",
      payload: data,
    });
}

export function deleteAttachmentLocal(task_id, attachment_id, additionalData) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RA_ATTACHMENT_LOCAL",
      payload: {
        task_id,
        attachment_id,
        additionalData,
      },
    });
}

export function download(url, index) {
  return (dispatch) =>
    dispatch({
      types: [
        "DOWNLOAD_RA_ATTACHMENT_REQUEST",
        "DOWNLOAD_RA_ATTACHMENT_SUCCESS",
        "DOWNLOAD_RA_ATTACHMENT_ERROR",
      ],
      payload: {
        request: {
          url: url,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            index,
          },
        },
      },
    });
}
export function setStageRA(id, nextStage) {
  return (dispatch) =>
    dispatch({
      types: [
        "SET_STAGE_RA_REQUEST",
        "SET_STAGE_RA_SUCCESS",
        "SET_STAGE_RA_ERROR",
      ],
      payload: {
        request: {
          url: `/ra/set-stage/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            stage: nextStage,
          },
        },
      },
    });
}

export function closeRA(id, nextStage) {
  return (dispatch) =>
    dispatch({
      types: ["CLOSE_RA_REQUEST", "CLOSE_RA_SUCCESS", "CLOSE_RA_ERROR"],
      payload: {
        request: {
          url: `/ra/close/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            stage: nextStage,
          },
        },
      },
    });
}

export function saveRAAsDraft(id, data) {
  return (dispatch) =>
    dispatch({
      type: "SAVE_RA_AS_DRAFT",
      payload: {
        request: {
          url: `/ra/save-as-draft/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        },
      },
    });
}

export function printRAReport(data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/ra/print/register`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}

export function printRATaskReport(id, data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/ra/print/task-register/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}

export function printRADocument(id, data) {
  return (dispatch) =>
    dispatch({
      type: "???",
      payload: {
        request: {
          url: `/ra/print/document/${id}`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data,
        },
      },
    });
}

export function clearRiskAssessment() {
  return (dispatch) =>
    dispatch({
      type: "CLEAR_RISK_ASSESSMENT",
      payload: {},
    });
}
