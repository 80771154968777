import React from "react";
import { connect } from "react-redux";
import "normalize.css";
import { Route, Switch } from "react-router-dom";
import {withCookies } from "react-cookie";
import Header from "../components/Header/";
import Layout from "../components/Layout";
import AppSchema from "./AppSchema";

import AccountLogin from "../container/Account/Login";
import AccountResetPassword from "../container/Account/ResetPassword";
import AccountResetPasswordConfirm from "../container/Account/ResetPasswordConfirm";
import AccountPanelLogin from "../container/AccountPanel/Login";
import AccountPanelResetPassword from "../container/AccountPanel/ResetPassword";
import AccountPanelResetPasswordConfirm from "../container/AccountPanel/ResetPasswordConfirm";

import SuperAdminAccountReset from "../container/SuperAdmin/ResetAccountConfirm";
import * as SuperAdmin from "../container/SuperAdmin";
import {getPublicMenuPages} from "../actions/pageActions";

class PublicAppSchema extends AppSchema {

  componentDidMount(){
    getPublicMenuPages()(this.props.dispatch);
  }

  render() {
    const Menu = {
      left:[],
      right: []
    };

    return (<div style={{ minHeight: "100vh" }}>
      <Header menu={Menu} />
      <Layout>
        <Switch>
          <Route exact path="/super-admin/login" component={SuperAdmin.Login} />
          <Route exact path="/super-admin/reset-password" component={SuperAdmin.ResetPassword} />
          <Route exact path="/super-admin/reset-password/:token" component={SuperAdmin.ResetPasswordConfirm} />
          <Route exact path="/" component={AccountLogin} />
          <Route exact path="/login" component={AccountLogin} />
          <Route exact path="/business/login" component={AccountPanelLogin} />
          <Route exact path="/reset-password" component={AccountResetPassword} />
          <Route exact path="/business/reset-password" component={AccountPanelResetPassword} />
          <Route exact path="/reset-password/:token" component={AccountResetPasswordConfirm} />
          <Route exact path="/business/reset-password/:token" component={AccountPanelResetPasswordConfirm} />
          <Route exact path="/super-admin/reset-account/:token" component={SuperAdminAccountReset} />

          <Route exact path="/business/login" component={AccountPanelLogin} />
        </Switch>
      </Layout>
    </div>);
  }
}

const PublicApp = connect(store => ({
  page: store.page
}))(withCookies(PublicAppSchema));

export default PublicApp;