import React, { Fragment } from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import Button from "react-bootstrap/lib/Button";
import {
  ButtonWithIcon,
  DownloadButton,
  ButtonWrapper,
} from "../../components/ListComponents";
import {
  StyledInputCheckBoxRadio,
  StyledInputBox,
  StyledTextareaBox,
} from "../../style/styleComponents/Form/input";
import { media } from "../../style/style-utils/index";
import validator, {
  composeValidators,
} from "../../components/Validation/index";
import { StyledInputMessage } from "../../style/styleComponents/Form/input";
import { SpanAsLink } from "../../components/ListComponents";

const StyledInputCheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  ${media.sm`
    flex-wrap:wrap;
    justify-content:space-around;
  `}
  & > div {
    &:first-child {
      ${(props) => (props.category ? "padding-left:20px;" : "")};
    }
    margin-right: 15px;
  }
`;

const CommentButtonWrapper = styled(ButtonWrapper)`
  margin: 0 -10px 10px;
`;

const CheckboxWrapper = styled.div`
  width: 40%;
  ${media.sm`
    width:100%
  `}
`;

const FormWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  ${media.sm`
    flex-wrap:wrap;
  `}
  & > * {
    flex-grow: 0;
    &:nth-child(2) {
      margin: 0 0 0 7px;
      flex-grow: 1;
    }
  }
  & > button {
    height: 40px;
    flex-grow: 2;
    ${media.sm`
      width:100%;
    `}
  }
`;

const AttachmentList = styled.div`
  width: 60%;
  ${media.sm`
    width:100%
  `}
  display:flex;
  flex-direction: column;
`;

const CommentWrapper = styled.div`
  margin-right: 15px;
  padding-top: 15px;
`;

const FileItem = styled.div`
  display: flex;
  margin-bottom: 5px;
  ${media.custom({ maxWidth: 500 })`
    flex-wrap:wrap;
    justify-content: space-around;
    margin-bottom:10px;
  `}
  & > button {
    flex-grow: 0;
    width: 34px;
    margin-left: 3px;
  }
`;

const FileItemName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  ${media.custom({ maxWidth: 500 })`
    width:100%;
    padding-bottom: 10px;
  `}
`;

const WrapperA = styled.div``;

export const StyledSavingBox = styled((props) => (
  <div className={props.className}>
    {props.fetching ? <i className="fa fa-spinner fa-spin" /> : ""}
  </div>
))`
  line-height:40px
  font-size: 18px;
  text-align: center;
  width:14px;
  & > i{
    @keyframes rotating
	  {
	    from {
	    		transform: rotate(0deg);
	    }
	    to {
	      transform: rotate(360deg);
	    }
	  }
    animation: rotating 3s linear infinite;
  }
`;

const QuestionLine = (props) => {
  const {
    field,
    answerData,
    category,
    disabled,
    readOnly,
    attachmentFetching,
    index,
    subIndex,
    permission = {},
    handleCommentFieldOpening = () => {},
    openViewModal = () => {},
    submitAttachment = () => {},
    deleteAttachment = () => {
      return () => {};
    },
    deleteCustomQuestion = () => {},
    downloadFunc = () => {
      return () => {};
    },
  } = props;
  let customQuestion = !field.audit_question_id && !field.questions;
  const attachments = field.attachments || [];
  return (
    <Fragment>
      <StyledInputCheckBoxWrapper category={category}>
        <CheckboxWrapper>
          {customQuestion ? (
            <div>
              <Field
                name={`question_${field.id ? field.id : `new_${index}`}_name`}
                validate={validator.required}
                render={({ input, meta, ...rest }) => (
                  <StyledInputBox
                    {...input}
                    disabled={readOnly}
                    value={input.value}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : null}
                    onChange={(event) => {
                      input.onChange(event.target.value);
                    }}
                  />
                )}
              />
              <Field
                name={`question_${
                  field.id ? field.id : `new_${index}`
                }_answer_id`}
                validate={validator.required}
                render={({ input, meta, ...rest }) => (
                  <div style={{ marginBottom: "5px" }}>
                    <StyledInputCheckBoxWrapper>
                      {answerData.map((item) => (
                        <StyledInputCheckBoxRadio
                          disabled={disabled}
                          label={item.name}
                          value={input.value}
                          checked={input.value == item.id}
                          onChange={(event) => {
                            input.onChange(item.id);
                          }}
                        />
                      ))}
                    </StyledInputCheckBoxWrapper>
                    {meta.error && meta.touched ? (
                      <StyledInputMessage>{meta.error}</StyledInputMessage>
                    ) : null}
                  </div>
                )}
              />
            </div>
          ) : (
            <Field
              name={`question_${field.id}_answer_id`}
              validate={validator.required}
              render={({ input, meta, ...rest }) => (
                <div style={{ marginBottom: "5px" }}>
                  <StyledInputCheckBoxWrapper>
                    {answerData.map((item) => (
                      <StyledInputCheckBoxRadio
                        disabled={disabled}
                        label={item.name}
                        value={input.value}
                        checked={input.value == item.id}
                        onChange={(event) => {
                          input.onChange(item.id);
                        }}
                      />
                    ))}
                  </StyledInputCheckBoxWrapper>
                  {meta.error && meta.touched ? (
                    <StyledInputMessage>{meta.error}</StyledInputMessage>
                  ) : null}
                </div>
              )}
            />
          )}
        </CheckboxWrapper>
        <ButtonWithIcon
          type="button"
          onClick={deleteCustomQuestion}
          icon="fa fa-trash"
        ></ButtonWithIcon>
        <AttachmentList>
          <Form
            onSubmit={submitAttachment(field.id || index)}
            render={({
              handleSubmit,
              submitting,
              pristine,
              invalid,
              values,
              form,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormWrapper>
                  <Field
                    name={`question_${field.id}_attachment`}
                    validate={validator.attachment}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        disabled={disabled}
                        type="file"
                        width="auto"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.files[0]);
                        }}
                      />
                    )}
                  />
                  <ButtonWithIcon
                    icon={`fa ${
                      field.fetching ? "fa-spinner fa-spin" : "fa-plus"
                    }`}
                    type="submit"
                    disabled={
                      submitting || pristine || field.fetching || readOnly
                    }
                    name={"Dodaj załącznik"}
                  ></ButtonWithIcon>
                </FormWrapper>
              </form>
            )}
          />
          {attachments.map((item, attachmentIndex) => (
            <FileItem>
              <FileItemName
                onClick={openViewModal({
                  file: item.file,
                  name: field.name,
                  attachmentIndex: attachmentIndex,
                  index: index,
                  subIndex: subIndex,
                })}
              >
                {/\.(pdf|jpg|png|svg)$/.test(item.file) ? (
                  <SpanAsLink>{item.file}</SpanAsLink>
                ) : (
                  item.file
                )}
              </FileItemName>
              <DownloadButton
                fetching={item.fetching}
                listButton
                icon="fa fa-download"
                onClick={downloadFunc(
                  item.file,
                  field.name,
                  attachmentIndex,
                  index,
                  subIndex
                )}
              />
              <ButtonWithIcon
                disabled={!permission[3]}
                icon="fa fa-trash"
                onClick={deleteAttachment(field.id, item.id, item.file)}
              />
            </FileItem>
          ))}
        </AttachmentList>
      </StyledInputCheckBoxWrapper>
      <CommentWrapper>
        <CommentButtonWrapper>
          <ButtonWithIcon
            type="button"
            onClick={handleCommentFieldOpening(field.id, index, subIndex)}
            name={`${field.comment_open ? "Usuń" : "Dodaj"} komentarz`}
            icon={`fa fa-${field.comment_open ? "minus" : "plus"}`}
          ></ButtonWithIcon>
        </CommentButtonWrapper>
        <Field
          name={`question_${field.id ? field.id : `new_${index}`}_comment`}
          render={({ input, meta, ...rest }) => (
            <div style={{ marginBottom: "5px" }}>
              {field.comment_open ? (
                <StyledTextareaBox
                  {...input}
                  /* label="komentarz" */
                  value={input.value}
                  disabled={readOnly}
                  error={meta.error && meta.touched}
                  minHeight={"100px"}
                  helperText={meta.error && meta.touched ? meta.error : null}
                  onChange={(event) => {
                    input.onChange(event.target.value);
                  }}
                />
              ) : (
                <Fragment>
                  {(() => {
                    input.onChange("");
                  })()}
                </Fragment>
              )}
            </div>
          )}
        />
      </CommentWrapper>
    </Fragment>
  );
};

export default QuestionLine;
