import React from "react";
import AppSchema from "apps/AppSchema";
import { getPublicMenuPages } from "actions/pageActions";
import {
  getAccountData,
  loginToBusinessAccount,
  logoutUser,
} from "actions/accountActions";
import { getBusinessUserModules } from "actions/moduleActions";
import { WhiteBorder } from "components/Header/_styleComponents";

const getPermissionDisabled = (moduleData, index, permission) => {
  return !(
    moduleData[index] &&
    moduleData[index].permission &&
    moduleData[index].permission[permission]
  );
};

let incidentItem = (permissions) => {
  if (!permissions) {
    return null;
  }

  let p1 = permissions.some((i) => i === 1);
  let p5 = permissions.some((i) => i === 5);

  if (!p1 && !p5) {
    return null;
  }

  let children = [];
  if (p1) {
    children.push({ name: "Zdarzenia", url: "/incident" });
  }
  if (p5) {
    children.push({ name: "Wypadki w drodze", url: "/accident-owtw" });
  }

  if (!children.length) {
    children = undefined;
  }

  return {
    class: "ehs-menu ehs-menu-incidents",
    title: p5 && !p1 ? "Wypadki w drodze" : "Zdarzenia",
    url: p5 && !p1 ? "/accident-owtw" : "/incident",
    children,
    images: {
      base: "incidents.svg",
      hover: "incidents_1.svg",
    },
  };
};

let getModulesItems = (moduleData) => {
  const permissions = moduleData.reduce((array, item) => {
    if (item.active) {
      array.push(item.id);
    }
    return array;
  }, []);
  return [incidentItem(permissions)].filter((i) => !!i);
};

class PrivateAppSchema extends AppSchema {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.createMenu = this.createMenu.bind(this);
    this.handleLoginToAdmin = this.handleLoginToAdmin.bind(this);
  }

  componentDidMount() {
    const {
      account: { singleData: accountData },
      dispatch,
    } = this.props;
    getPublicMenuPages()(dispatch);
    if (!Object.keys(accountData).length) {
      getAccountData()(dispatch).then(this.handleServerResponse());
    }
    getBusinessUserModules({ self: true })(dispatch).then(
      this.handleServerResponse(),
      {}
    );
  }

  handleLoginToAdmin() {
    const { dispatch, history } = this.props;
    loginToBusinessAccount()(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/business/dashboard");
      })
    );
  }

  createMenu() {
    //TODO MUST podmienić domyślny avatar
    const {
      account: {
        singleData: { first_name, last_name, avatar_url, admin },
      },
      dispatch,
      history,
      modules: { data: moduleData = [] },
      page: { pageMenu = [] },
    } = this.props;
    const permissions = moduleData.reduce((array, item) => {
      if (item.active) {
        array.push(item.id);
      }
      return array;
    }, []);

    const extendAccount = admin
      ? [
          {
            name: "Kliencki panel administratora",
            onClick: this.handleLoginToAdmin,
          },
        ]
      : [];

    const LeftMenu = {
      2: {
        class: "ehs-menu ehs-menu-audit",
        title: "Audyty",
        url: "/audit",
        children: [
          { name: "Rejestr audytów", url: "/audit" },
          {
            name: "Zaplanuj audyt",
            url: "/audit/register",
            disabled: getPermissionDisabled(moduleData, 1, 2),
          },
          {
            name: "Wprowadź nowy audyt",
            url: "/audit/add",
            disabled: getPermissionDisabled(moduleData, 1, 2),
          },
          { name: "Zarządzanie audytami", url: "/audit/type" },
        ],
        images: {
          base: "audit.svg",
          hover: "audit_1.svg",
        },
      },
      3: {
        class: "ehs-menu ehs-menu-action-items",
        url: "/task",
        title: "Działania",
        children: [
          { name: "Rejestr działań", url: "/task" },
          {
            name: "Dodaj działanie",
            url: "/task/add",
            disabled: getPermissionDisabled(moduleData, 2, 2),
          },
        ],
        images: {
          base: "action_items.svg",
          hover: "action items_1.svg",
        },
      },
      4: {
        url: "/characteristic",
        title: "Karty Charakterystyk",
        class: "ehs-menu ehs-menu-msds",
        images: {
          base: "msds.svg",
          hover: "msds_1.svg",
        },
      },
      6: {
        title: "Ocena ryzyka",
        class: "ehs-menu ehs-menu-dashboard",
        children: [
          {
            name: "Rejestr kart oceny ryzyka zawodowego",
            url: "/risk-assessment",
          },
          {
            name: "Dodaj nową ocenę ryzyka zawodowego",
            url: "/risk-assessment/basic",
          },
          {
            name: "Wzorcowe dokumentacje",
            url: "/risk-assessment/template-doc",
          },
          {
            name: "Wzorcowe zagrożenia",
            url: "/risk-assessment/model-hazards/list",
          },
          {
            name: "Listy kontrolne identyfikacji zagrożeń",
            url: "/risk-assessment/control/list",
          },
          { name: "Archiwum ORZ", url: "/risk-assessment/archive-orz/list" },
          {
            name: "Szablony metod oceny ryzyka zawodowego",
            url: "/risk-assessment/ra-method",
          },
        ],
        images: {
          base: "ORZ.svg",
          hover: "ORZ_1.svg",
        },
      },
      7: {
        title: "B S U",
        class: "ehs-menu ehs-menu-dashboard",
        children: [
          { name: "Badania profilaktyczne", url: "/medical-examination" },
          { name: "Szkolenia BHP", url: "/osh" },
          {
            name: "Uprawnienia kwalifikacyjne",
            url: "/qualification-entitlements",
          },
          { name: "Formularze", url: "/forms" },
        ],
        images: {
          base: "szkolenia_badania_uprawnienia.svg",
          hover: "szkolenia_badania_uprawnienia_1.svg",
        },
      },
    };
    return {
      left: [
        {
          url: "/dashboard",
          title: "Strona główna",
          class: "ehs-menu ehs-menu-dashboard",
          images: {
            base: "dashboard.svg",
            hover: "dashboard_1.svg",
          },
        },
        ...getModulesItems(moduleData),
        ...permissions.map((item) => {
          return LeftMenu[item];
        }),

        {
          render: (props) => <WhiteBorder />,
        },
        {
          class: "",
          title: `${first_name} ${last_name}`,
          title2: last_name,
          account: true,
          image: true,
          imageUrl: avatar_url,
          children: [
            { name: "Ustawienia konta", url: "/account/edit" },
            ...extendAccount,
          ],
        },
        {
          class: "ehs-menu ehs-menu-help",
          title: "Pomoc",
          children: pageMenu,
          images: {
            base: "help.svg",
            hover: "help_1.svg",
          },
        },
        {
          url: "/dashboard",
          title: "Wyloguj",
          class: "ehs-menu ehs-menu-logout",
          onClick: this.handleLogout,
          images: {
            base: "logout.svg",
            hover: "logout_1.svg",
          },
        },
      ],
      right: [],
    };
  }

  handleLogout() {
    const { dispatch, history } = this.props;

    logoutUser()(dispatch).then(() => {
      history.push("/");
    });
  }
}

export default PrivateAppSchema;
