import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import setFieldData from "final-form-set-field-data";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { ButtonWrapper } from "../../../components/ListComponents";

import {
  getIncidentWitness,
  addIncidentWitness,
  updateIncidentWitness,
  removeIncidentWitness,
} from "actions/incidentActions";

import AutoSave from "./AutoSave";

import { StyledLabel, StyledInputBox } from "style/styleComponents/Form/input";
import validator, { composeValidators } from "components/Validation/";
import {
  ButtonWithIcon,
  DeleteButton,
  DownloadButton,
} from "components/ListComponents";

let InputWrapper = styled.div`
  display: flex;
  margin: 0 -10px 10px;

  & > * {
    margin: 0 10px;
  }
`;

let WitnessField = (props) => {
  let { addWitness, removeIncidentWitness, readOnly } = props;

  return (
    <>
      <FormSpy subscribe={{ values: true }}>
        {({ values }) => {
          return (
            <>
              <FieldArray name="incident_witnesses">
                {({ fields }) => {
                  return (
                    <div>
                      {fields.map((name, index) => (
                        <InputWrapper>
                          <Field
                            key={index}
                            name={`${name}.full_name`}
                            validate={validator.required}
                            type="text"
                            render={({ input, meta, ...rest }) => (
                              <>
                                <StyledInputBox
                                  disabled={readOnly}
                                  placeholder={"Imię i nazwisko świadka"}
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched
                                      ? meta.error
                                      : null
                                  }
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  value={
                                    (values.incident_witnesses[index] &&
                                      values.incident_witnesses[index]
                                        .full_name) ||
                                    undefined
                                  }
                                  onChange={(event) => {
                                    input.onChange(event);
                                  }}
                                />
                              </>
                            )}
                          />
                          <Button
                            onClick={
                              removeIncidentWitness
                                ? removeIncidentWitness(fields, index)
                                : () => {
                                    fields.remove(index);
                                  }
                            }
                          >
                            <i className={"fa fa-trash"} />
                          </Button>
                        </InputWrapper>
                      ))}

                      <div>
                        <ButtonWrapper>
                          <Button
                            type="button"
                            disabled={readOnly}
                            onClick={
                              addWitness ? addWitness(fields) : fields.push
                            }
                          >
                            Dodaj świadka
                          </Button>
                        </ButtonWrapper>
                      </div>
                    </div>
                  );
                }}
              </FieldArray>
            </>
          );
        }}
      </FormSpy>
    </>
  );
};

class WitnessessSection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emptyRush: true,
    };

    this.addWitness = this.addWitness.bind(this);
    this.removeIncidentWitness = this.removeIncidentWitness.bind(this);
  }

  componentDidMount() {
    let { dispatch, incident_id, handleServerResponse } = this.props;

    getIncidentWitness({ incident_id })(dispatch).then(handleServerResponse());
  }

  addWitness(fields) {
    let { dispatch, incident_id, handleServerResponse } = this.props;
    return () => {
      addIncidentWitness({ incident_id })(dispatch).then(
        handleServerResponse({}, (action) => {
          let { id } = action.payload.data.incident_witness;
          fields.push({ id });
        })
      );
    };
  }

  updateChanges(form) {
    return (value, prev) => {
      const { dispatch, incident_id } = this.props;
      // const { incident_id } = match.params;
      if (!this.state.emptyRush) {
        updateIncidentWitness({
          incident_id,
          data: {
            value,
          },
        })(dispatch);
      } else {
        this.state.emptyRush = false;
      }
    };
  }

  removeIncidentWitness(fields, index) {
    let { dispatch, incident_id, handleServerResponse } = this.props;
    let witness_id = fields.value[index].id;
    return () => {
      removeIncidentWitness({ incident_id, id: witness_id })(dispatch).then(
        handleServerResponse({}, (action) => {
          fields.remove(index);
        })
      );
    };
  }

  render() {
    let {
      readOnly = false,
      incident_id,
      incident,
      permission,
      values = {},
    } = this.props;
    let { incident_witnesses, singleData } = incident;
    let stage = singleData.stage;

    return (
      <Fragment>
        <StyledLabel>Świadkowie</StyledLabel>
        {stage ? (
          <>
            <WitnessField readOnly={readOnly} />
          </>
        ) : (
          <Form
            onSubmit={() => {}}
            initialValues={{ incident_witnesses }}
            mutators={{ ...arrayMutators, setFieldData }}
            render={({ handleSubmit, pristine, invalid, form, values }) => {
              return (
                <form>
                  <AutoSave
                    debounce={500}
                    setFieldData={form.mutators.setFieldData}
                    values={values}
                    save={this.updateChanges(form)}
                  />
                  <WitnessField
                    addWitness={this.addWitness}
                    readOnly={readOnly}
                    removeIncidentWitness={this.removeIncidentWitness}
                  />
                </form>
              );
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default connect((store) => ({
  incident: store.incident,
}))(WitnessessSection);
