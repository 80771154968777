import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import { Link } from "react-router-dom";
import ModalSchema from "./_Schema";
import TableSortable from "../TableSortable";
import { Form, Field } from "react-final-form";
import {
  StyledInputBox,
  StyledInputCheckBox,
} from "../../style/styleComponents/Form/input";
const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin: 0 -5px;
  & > * {
    margin: 0 5px;
  }
`;

class ModalCheckList extends ModalSchema {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    this.props.handleSubmit(data);
    this.props.handleClose();
  }

  render() {
    const {
      title,
      data = [],
      initialValues = {},
      headers = [],
      open,
      handleClose,
      id_name,
    } = this.props;
    return (
      <div className="static-modal">
        <Modal id="ModalList" show={open} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title id="ModalListTitle">
              <ModalTitleWrapper>
                <span>{title}</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={handleClose}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="ModalListBody">
            <Form
              onSubmit={this.submit}
              initialValues={initialValues}
              render={({ handleSubmit, pristine, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                  <Table striped bordered condensed hover>
                    <TableSortable
                      headers={[
                        { name: "" },
                        { name: "L.p" },
                        { name: "Nazwa" },
                        ...headers,
                      ]}
                      data={data}
                      render={(sortedData) => {
                        return sortedData.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Field
                                key={index}
                                name={`${id_name}${item.id}`}
                                render={({ input, meta, ...rest }) => (
                                  <StyledInputCheckBox
                                    {...input}
                                    value={item.id}
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : null
                                    }
                                    key={index}
                                    // label={item.name}
                                    checked={input.value}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                  />
                                )}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{item.title || item.name}</td>
                          </tr>
                        ));
                      }}
                    />
                  </Table>
                  <ButtonWrapper>
                    <Button type="submit" bsStyle="primary">
                      Zapisz
                    </Button>
                  </ButtonWrapper>
                </form>
              )}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModalCheckList;

ModalCheckList.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      open: PropTypes.bool,
      handleClose: PropTypes.func,
    })
  ),
};
