import React from "react";
import Button from "react-bootstrap/lib/Button";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import {
  archiveRA,
  copyRA,
  addUpdateRA,
  deleteRA,
  getRAs,
  getRAsFilter,
  getRAMethods,
  printRAReport,
  printRADocument,
  saveRAAsDraft,
} from "actions/riskAssessmentAction.js";
import { getUsersLocalizations } from "actions/localizationActions";
import { getUserSectionsIndependently } from "actions/sectionActions";
import { getUserWorkplacesWithout } from "actions/workplaceActions";

import AppComponent from "components/AppComponent";
import { FieldInput, FieldSelect, FieldDayPicker } from "components/Fields";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import PageHeader from "components/PageHeader";
import { ButtonWrapper } from "components/Wrappers";
import Pagination from "components/Pagination";
import Modal from "components/Modal/SimpleAccept";
import TableSortableWithPagination from "components/TableSortable/Register";
import saveBlobFile from "components/saveBlobFile";
import {
  ButtonWithIcon,
  StyledTableWrapper,
  StyledTableListWithSearch as StyledTable,
} from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";

import PrintModal from "./PrintModal";
import SaveAsModal from "./SaveAsModal";
import SideMenu from "../_SideMenu";
import { defaultUrls as urls } from "../_staticData";
import ModalSimpleAccept from "components/Modal/SimpleAccept";
import { setUrls } from "../_staticData";

function TdRaw(props) {
  let { children, className } = props;
  return <td className={className}>{children}</td>;
}

let TdText = styled(TdRaw)`
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const clearFilterMutator = ([name], state, { changeValue }) => {
  changeValue(state, "name", () => null);
  changeValue(state, "date_execution", () => null);
  changeValue(state, "date_update", () => null);
  changeValue(state, "symbol", () => null);
  changeValue(state, "ra_method_id", () => "0");
  changeValue(state, "localization_id", () => "0");
  changeValue(state, "section_name", () => "0");
  changeValue(state, "workplace_id", () => "0");
};

class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modal: this.initModalState(),
      archiveModal: this.initModalState(),
      urls: setUrls(),

      modalPrint: {
        open: false,
      },
      selectedData: [],
    };

    this.deleteRA = this.deleteRA.bind(this);
    this.archive = this.archive.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.copy = this.copy.bind(this);
    this.addUpdate = this.addUpdate.bind(this);
    this.printRADocuments = this.printRADocuments.bind(this);
    this.printRegister = this.printRegister.bind(this);
    this.saveAsDraft = this.saveAsDraft.bind(this);
  }

  addUpdate(id) {
    let { urls } = this.state;
    let { dispatch, history, match } = this.props;

    addUpdateRA(id)(dispatch).then(
      this.handleServerResponse({}, (action) => {
        let { id } = action.payload.data.data;
        history.push(urls.UPDATE(id));
      })
    );
  }

  archive() {
    let {
      archiveModal: { entry_id },
      urls,
    } = this.state;
    let { dispatch, history } = this.props;

    archiveRA({ id: entry_id })(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(urls.ARCHIVEDLIST);
      })
    );
  }

  componentDidMount() {
    let { dispatch } = this.props;
    getRAs()(dispatch).then(this.handleServerResponse());
    getUsersLocalizations()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getUserSectionsIndependently()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
    getRAMethods()(dispatch).then(this.handleServerResponse());
    getUserWorkplacesWithout()(dispatch).then(this.handleServerResponse());
  }

  clearFilter(form) {
    let { dispatch } = this.props;
    return () => {
      form.mutators.clearFilterMutator();
      getRAs()(dispatch).then(this.handleServerResponse());
    };
  }

  copy(id) {
    return () => {
      let { dispatch } = this.props;
      copyRA(id)(dispatch).then(this.handleServerResponse());
    };
  }

  deleteRA() {
    let { modal } = this.state;
    let { dispatch } = this.props;

    let { entry_id } = modal;

    deleteRA(entry_id)(dispatch).then(this.handleServerResponse());
  }

  filterSubmit(data) {
    let { dispatch } = this.props;
    let filterZeroId = (data) => {
      let keys = [
        "ra_method_id",
        "localization_id",
        "section_name",
        "workplace_id",
      ];

      for (let k of keys) {
        if (data[k] == "0") {
          data[k] = null;
        }
      }
      return data;
    };

    getRAsFilter(filterZeroId(data))(dispatch).then(
      this.handleServerResponse()
    );
  }

  printRegister() {
    let { dispatch } = this.props;
    let data = this.state.selectedData;
    if (!(data && data.length)) {
      return null;
    }

    printRAReport(data)(dispatch).then((res) => {
      if (!res.error) {
        let file;
        let fileName;

        let fileNamePrefix = `Rejestr ocen ryzyka zawodowego - ${moment().format(
          "YYYY-MM-DD"
        )}`;

        if (true) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.pdf`;
        }
        if (data.xml) {
          file = res.payload.data;
          fileName = `${fileNamePrefix}.xls`;
        }

        saveBlobFile(file, fileName);
      }
    });
  }

  printRADocuments(data) {
    let { modalPrint } = this.state;
    let { id } = modalPrint;
    let { dispatch } = this.props;

    printRADocument(
      id,
      data
    )(dispatch).then((res) => {
      if (!res.error) {
        let file = res.payload.data;
        let fileName = `Ocena ryzyka zawodowego.pdf`;
        saveBlobFile(file, fileName);
      }
    });
  }

  saveAsDraft(id, data, cb) {
    let { dispatch } = this.props;
    saveRAAsDraft(id, { ...data })(dispatch).then(
      this.handleServerResponse({}, (action) => {
        this.setSuccessMessage(action.payload.data.message);
        if (cb) {
          cb();
        }
      })
    );
  }

  render() {
    let { message, modal, archiveModal } = this.state;
    let {
      data,
      localizationData,
      sectionData,
      workplaceData,
      methods,
    } = this.props;

    return (
      <>
        <LayoutContent
          error404={this.state.error_code == 404}
          message={message}
          SideMenu={<SideMenu />}
        >
          <PageHeader>Rejestr kart oceny ryzyka zawodowego</PageHeader>

          <ButtonWrapper>
            <LinkContainer to={urls.ADD}>
              <Button>Dodaj nową ocenę ryzyka zawodowego</Button>
            </LinkContainer>
            <Button onClick={this.printRegister}>Drukuj rejestr</Button>
          </ButtonWrapper>
          <TableSortableWithPagination
            data={this.props.data}
            headers={[
              { name: "Nazwa", prop: "name" },
              { name: "Data wykonania", prop: "date_execution" },
              { name: "Data aktualizacji", prop: "date_update" },
              { name: "Symbol", prop: "symbol" },
              { name: "Metoda oceny", prop: "method_id" },

              {
                name: "Lokalizacja",
                prop: "localization_id",
              },
              { name: "Dział", prop: "section_id" },
              { name: "Stanowisko pracy", prop: "workplace_id" },
              { name: "Pobierz" },
              { name: "Duplikuj" },
              { name: "Usuń" },
              { name: "Aktualizuj" },
              { name: "Zapisz jako szablon" },
              { name: "Archiwizuj" },
            ]}
            getSortedData={(data) => {
              this.setState({ selectedData: data.map((i) => i.id) });
            }}
            renderSearcher={
              <Form
                ref={(form) => (this.form = form)}
                initialValues={{
                  ra_method_id: "0",
                  localization_id: "0",
                  section_name: "0",
                  workplace_id: "0",
                }}
                mutators={{ clearFilterMutator }}
                onSubmit={this.filterSubmit}
                render={({ handleSubmit, pristine, invalid, form, values }) => {
                  return (
                    <>
                      <tr>
                        <td style={{ minWidth: "120px" }}>
                          <FieldInput name={"name"} placeholder={"wg Nazwy"} />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldDayPicker name={"date_execution"} />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldDayPicker name={"date_update"} />
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <FieldInput
                            name={"symbol"}
                            placeholder={"wg Symbolu"}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"ra_method_id"}
                            data={[{ id: "0", name: "Metoda" }].concat(methods)}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"localization_id"}
                            data={[{ id: "0", name: "Lokalizacja" }].concat(
                              localizationData
                            )}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"section_name"}
                            propValue="value"
                            data={[{ value: "0", name: "Dział" }].concat(
                              sectionData
                            )}
                          />
                        </td>
                        <td>
                          <FieldSelect
                            name={"workplace_id"}
                            data={[{ id: "0", name: "Stanowisko" }].concat(
                              workplaceData
                            )}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="13"
                          style={{ padding: "8px 40px 8px 8px" }}
                        >
                          <ButtonWrapper nowrap>
                            <Button
                              type="button"
                              onClick={handleSubmit}
                              bsStyle="primary"
                            >
                              Szukaj
                            </Button>
                            <FormSpy subscribe={{ form: true }}>
                              {({ form }) => (
                                <Button onClick={this.clearFilter(form)}>
                                  Wyłącz filtr
                                </Button>
                              )}
                            </FormSpy>
                          </ButtonWrapper>
                        </td>
                      </tr>
                    </>
                  );
                }}
              />
            }
            render={(sortedData) => {
              return sortedData.map((item) => {
                return (
                  <tr>
                    <TdText>
                      <Link
                        to={
                          item.update
                            ? urls.UPDATE(item.id)
                            : urls.BASIC(item.id)
                        }
                      >
                        {item.name}
                      </Link>
                    </TdText>
                    <td>{item.date_execution}</td>
                    <td>{item.date_update}</td>
                    <td>{item.symbol}</td>
                    <td>{item.method_name}</td>
                    <td>{item.localization_name}</td>
                    <td>{item.section_name}</td>
                    <td>{item.workplace_name}</td>
                    <td>
                      <ButtonWithIcon
                        onClick={this.openModal("modalPrint", { ...item })}
                        icon="fa fa-print"
                      ></ButtonWithIcon>
                    </td>
                    <td>
                      <ButtonWithIcon
                        onClick={this.copy(item.id)}
                        icon="fa fa-files-o"
                      ></ButtonWithIcon>
                    </td>
                    <td>
                      <ButtonWithIcon
                        onClick={this.openModal("modal", {
                          entry_id: item.id,
                        })}
                        icon="fa fa-trash"
                      ></ButtonWithIcon>
                    </td>
                    <td>
                      <ButtonWithIcon
                        onClick={() => {
                          this.addUpdate(item.id);
                        }}
                        icon="fa fa-pencil-square-o"
                      ></ButtonWithIcon>
                    </td>
                    <td>
                      <SaveAsModal
                        id={item.id}
                        saveAsDraft={this.saveAsDraft}
                      />
                    </td>
                    <td>
                      <ButtonWithIcon
                        onClick={this.openModal("archiveModal", {
                          entry_id: item.id,
                        })}
                        icon="fa fa-folder"
                      ></ButtonWithIcon>
                    </td>
                  </tr>
                );
              });
            }}
          />
        </LayoutContent>
        <ModalSimpleAccept
          open={archiveModal.open}
          handleClose={this.closeModal("archiveModal")}
          handleAccept={this.archive}
          title="Archiwizuj kartę"
          description="Czy chcesz zarchiwizować ocenę ryzyka zawodowego?"
        />
        <Modal
          title="Usuń ocenę ryzyka"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modal.open}
          handleAccept={this.deleteRA}
          handleClose={this.closeModal("modal")}
        />
        <PrintModal
          open={this.state.modalPrint.open}
          handleClose={this.closeModal("modalPrint", {})}
          handleAccept={this.printRADocuments}
        />
      </>
    );
  }
}
export default connect((store) => ({
  data: store.riskAssessment.data,
  methods: store.riskAssessment.methods,
  message: store.message,
  localizationData: store.localization.data,
  sectionData: store.section.data,
  workplaceData: store.workplace.data,
}))(List);
