import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/lib/Button";
import styled from "styled-components";

import { setNextStage, clearAudit } from "actions/auditActions";
import { getAudit, getAuditTask } from "actions/auditActions";
import { deleteTaskByAudit } from "actions/taskActions";

import AppComponent from "components/AppComponent";
import Box from "components/Box";
import { ButtonWrapper } from "components/ListComponents";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import Modal from "components/Modal/SimpleAccept";
import { TableSortHeader } from "components/TableSortable";

import SideMenu from "../_SideMenu";
import TaskLine from "./_taskLine";

class AuditTask extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modalDelete: {
        name: null,
        open: false,
        entry_id: null,
        questionIndex: null,
        subQuestionIndex: null,
      },
    };

    this.setNextStage = this.setNextStage.bind(this);
    this.deleteRequest = this.deleteRequest.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
      audit: { singleData: auditData },
    } = this.props;
    const { audit_id } = match.params;

    if (!Object.keys(auditData).length) {
      getAudit(audit_id)(dispatch).then(this.handleServerResponse());
    }

    getAuditTask(audit_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        this.updateMessage();
      })
    );
  }

  deleteRequest(entry_id, entry_name, questionIndex, subQuestionIndex) {
    return () => {
      this.setState({
        modalDelete: {
          ...this.state.modalDelete,
          open: true,
          entry_id,
          name: entry_name,
          questionIndex,
          subQuestionIndex,
        },
      });
    };
  }

  closeModal() {
    this.setState({
      modalDelete: {
        ...this.state.modalDelete,
        open: false,
        entry_id: null,
        name: null,
      },
    });
  }

  deleteTask() {
    const {
      entry_id,
      questionIndex,
      subQuestionIndex,
    } = this.state.modalDelete;
    const { dispatch, match } = this.props;
    const { audit_id } = match.params;
    deleteTaskByAudit(
      audit_id,
      entry_id,
      questionIndex,
      subQuestionIndex
    )(dispatch).then(this.handleServerResponse());
  }

  setNextStage() {
    const { dispatch, match, history } = this.props;
    const { audit_id } = match.params;
    setNextStage(audit_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/audit/notification/${audit_id}`);
      })
    );
  }

  render() {
    const { modalDelete, message } = this.state;
    const {
      audit: { singleData, questionsData, permission },
      match,
      task: { permission: taskPermission },
    } = this.props;
    const { audit_id } = match.params;
    const readOnly = !permission[12];
    return (
      <LayoutContent
        SideMenu={
          <SideMenu
            permission={permission}
            audit_id={audit_id}
            stage={singleData.stage}
          />
        }
        message={message}
      >
        <Box title={"Działania naprawcze"}>
          {questionsData.map((item, questionIndex) => (
            <div>
              {item.questions && item.questions.length ? (
                <div>
                  {item.name}
                  {item.questions.map((subitem, subQuestionIndex) => (
                    <TaskLine
                      permission={permission}
                      taskPermission={taskPermission}
                      category
                      audit_id={audit_id}
                      item={subitem}
                      tasks={subitem.tasks}
                      deleteEvent={this.deleteRequest}
                      questionIndex={questionIndex}
                      subQuestionIndex={subQuestionIndex}
                    />
                  ))}
                </div>
              ) : (
                <TaskLine
                  permission={permission}
                  taskPermission={taskPermission}
                  audit_id={audit_id}
                  item={item}
                  tasks={item.tasks}
                  deleteEvent={this.deleteRequest}
                  questionIndex={questionIndex}
                />
              )}
            </div>
          ))}
          <ButtonWrapper>
            {readOnly ? (
              <LinkContainer to={`/audit/notification/${audit_id}`}>
                <Button disabled={singleData.stage == 2} bsStyle="primary">
                  Dalej
                </Button>
              </LinkContainer>
            ) : (
              <Button onClick={this.setNextStage} bsStyle="primary">
                Dalej
              </Button>
            )}
            <LinkContainer to={`/audit/`} exact>
              <Button>Powrót do listy</Button>
            </LinkContainer>
          </ButtonWrapper>
        </Box>
        <Modal
          title={`Usuń działanie - ${modalDelete.name}`}
          description="Czy chcesz usunąć dany wpis?"
          open={modalDelete.open}
          handleAccept={this.deleteTask}
          handleClose={this.closeModal}
        />
      </LayoutContent>
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    clearAudit()(dispatch);
  }
}

export default connect((store) => ({
  audit: store.audit,
  task: store.task,
  message: store.message,
}))(AuditTask);
