import LList from "./List";

import { Basic as LBasic, Update as LUpdate } from "./EntryAddEdit/Basic.js";
import LWorkplaceCharacteristic from "./EntryAddEdit/WorkplaceCharacteristic/";
import LAddTaskDecreaseRisk from "./EntryAddEdit/TaskDecreaseRisk/index";
import LTaskAdd from "./EntryAddEdit/TaskDecreaseRisk/Add";
import LTaskEdit from "./EntryAddEdit/TaskDecreaseRisk/Edit";
import LDanger from "./EntryAddEdit/Danger";

import LTemplateList from "./Draft/List.js";
import LTemplateAdd from "./Draft/Basic.js";
import LTemplateCharacteristic from "./Draft/Charakteristic.js";
import LTemplateAnalysis from "./Draft/Analysis.js";
import LTemplateDanger from "./Draft/Danger.js";
import LTemplateTask from "./Draft/Task.js";

import LTemplateDangerList from "./TemplateDangerList.js";
import LTemplateDangerAdd from "./TemplateDangerAdd.js";
import LControlList from "./ControlList/";
import LControlAdd from "./ControlList/Add.js";
import LControlEdit from "./ControlList/Edit.js";
import LNotification from "./EntryAddEdit/Notification.js";
import LAnalysis from "./EntryAddEdit/Analysis/index";
import LModelHazardList from "./ModelHazards/List";
import LModelHazardAdd from "./ModelHazards/Add";
import LModelHazardEdit from "./ModelHazards/Edit";

import LArchivedORZList from "./ArchivedORZ/List.js";
import LArchivedORZBasic from "./ArchivedORZ/Basic.js";
import LArchivedORZCharacteristic from "./ArchivedORZ/Characteristic.js";
import LArchivedORZAnalysis from "./ArchivedORZ/Analysis.js";
import LArchivedORZTaskDecreaseRisk from "./ArchivedORZ/TaskDecreaseRisk.js";
import LArchivedORZNotification from "./ArchivedORZ/Notification.js";

import LMethodList from "./Method/List";
import LMethodAdd from "./Method/Add";
import LMethodEdit from "./Method/Edit";

export const List = LList;
export const Basic = LBasic;
export const Update = LUpdate;
export const Danger = LDanger;
export const TemplateList = LTemplateList;
export const TemplateAdd = LTemplateAdd;
export const TemplateCharacteristic = LTemplateCharacteristic;
export const TemplateAnalysis = LTemplateAnalysis;
export const TemplateDanger = LTemplateDanger;
export const TemplateTask = LTemplateTask;

export const TemplateDangerList = LTemplateDangerList;
export const TemplateDangerAdd = LTemplateDangerAdd;
export const WorkplaceCharacteristic = LWorkplaceCharacteristic;
export const ControlList = LControlList;
export const ControlAdd = LControlAdd;
export const ControlEdit = LControlEdit;
export const Notification = LNotification;
export const AddTaskDecreaseRisk = LAddTaskDecreaseRisk;
export const Analysis = LAnalysis;
export const TaskAdd = LTaskAdd;
export const TaskEdit = LTaskEdit;

export const ArchivedORZList = LArchivedORZList;
export const ArchivedORZBasic = LArchivedORZBasic;
export const ArchivedORZCharacteristic = LArchivedORZCharacteristic;
export const ArchivedORZAnalysis = LArchivedORZAnalysis;
export const ArchivedORZTaskDecreaseRisk = LArchivedORZTaskDecreaseRisk;
export const ArchivedORZNotification = LArchivedORZNotification;

export const MethodList = LMethodList;
export const MethodAdd = LMethodAdd;
export const MethodEdit = LMethodEdit;

export const ModelHazardsList = LModelHazardList;
export const ModelHazardsAdd = LModelHazardAdd;
export const ModelHazardsEdit = LModelHazardEdit;
