import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";

const WrapperPages = styled.div`
  display: flex;
  margin: 15px -2px;
  justify-content: flex-end;
  & > button,&>span {
    margin: 0 2px
`;
WrapperPages.displayName = "WrapperPages";

const WrapperCount = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -10px;
  & > div {
    margin: 0 10px;
  }
`;

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...props.data],
      countOnPage: 10,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageChange(0)();
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.state.data)) {
      this.setState({ data: nextProps.data }, () => {
        this.handlePageChange(0)();
      });
    }
  }

  handlePageChange(page) {
    const { countOnPage } = this.state;
    const { handlePageChange, data } = this.props;
    return () => {
      const newData = [...data];
      const nextData = newData.splice(page * countOnPage, countOnPage);
      handlePageChange(nextData);
    };
  }

  handleSelectChange(eventKey) {
    this.setState({ countOnPage: eventKey }, () => {
      this.handlePageChange(0)();
    });
  }

  renderButton() {
    const count = (this.props.data || []).length;
    const { countOnPage } = this.state;
    const numberOfPages = Math.ceil(count / countOnPage);
    return Array.from(Array(numberOfPages).keys()).map((item, index) => (
      <Button key={index} onClick={this.handlePageChange(item)}>
        {item + 1}
      </Button>
    ));
  }

  render() {
    return (
      <div>
        <WrapperPages>{this.renderButton()}</WrapperPages>
        <WrapperCount>
          <span>Wpisów na stronę</span>
          <DropdownButton
            title={this.state.countOnPage}
            id={"dropdown-pagination-count-on-page"}
          >
            <MenuItem eventKey={10} onSelect={this.handleSelectChange}>
              10
            </MenuItem>
            <MenuItem eventKey={20} onSelect={this.handleSelectChange}>
              20
            </MenuItem>
            <MenuItem eventKey={50} onSelect={this.handleSelectChange}>
              50
            </MenuItem>
            <MenuItem eventKey={100} onSelect={this.handleSelectChange}>
              100
            </MenuItem>
            <MenuItem eventKey={200} onSelect={this.handleSelectChange}>
              200
            </MenuItem>
            <MenuItem eventKey={500} onSelect={this.handleSelectChange}>
              500
            </MenuItem>
          </DropdownButton>
        </WrapperCount>
      </div>
    );
  }
}

export default Pagination;

Pagination.propTypes = {
  data: PropTypes.array,
  handlePageChange: PropTypes.func,
};
