import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap_white.css';
import {StyledTooltip} from "../ToolTip";

export const StyledLabel = styled.div`
  margin-bottom:5px;
  flex: 1 1 auto; 
`;
StyledLabel.displayName = "StyledLabel";

export const StyledInput = styled.input`
  border:1px solid #ccc;
  line-height:20px;
  padding: 6px 12px;
  width: ${props=>props.width || "100%"}; 
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
  text-align: ${props=>props.centerValue ? "center" :"left"}; 

 ${props=>props.backgroundColor ? `
 background-color: ${props.backgroundColor};
 &:disabled {
  background-color: ${props.backgroundColor};
 }` : ""};

}  

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`; 

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 6px 12px;
  border:1px solid #ccc;
  resize: none;
  min-height: ${props=>props.minHeight || "250px"}; 

`;

const InputBox = styled.div`
  margin-bottom:10px;
  width:100%;
  display: flex;
  flex-flow: column;
`;

export const StyledInputMessage = styled.span`
  color: #dc453c;
`;

class DelayInputChange extends React.PureComponent {}

export const StyledInputBox = props => (
  <InputBox className={props.className}>
    {props.label ? <StyledLabel>{props.label}</StyledLabel> : ''}
    <StyledInput
      ref={props.setRef}
      id={props.id}
      name={props.name}
      value={props.value}
      type={props.type}
      placeholder={props.placeholder}
      disabled={props.disabled}
      onChange={props.onChange}
      onFocus={props.onFocus}
      error={props.error}
      onBlur={props.onBlur}
      centerValue={props.centerValue}
      backgroundColor={props.backgroundColor}
    />
    <StyledInputMessage>{props.helperText}</StyledInputMessage>
  </InputBox>
);  
 
export const StyledTextareaBox = props => (
  <InputBox>
    <StyledLabel>{props.label}</StyledLabel>
    <StyledTextarea
      id={props.id}
      style={props.style}
      onChange={props.onChange}
      disabled={props.disabled}
      value={props.value}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      minHeight={props.minHeight}
    />
    <StyledInputMessage>{props.helperText}</StyledInputMessage>
  </InputBox>
); 

export const CheckBoxLineStyle = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
`;

const CheckBoxLineText = styled.span`
  padding-left: 15px;
`;

const RadioLineText = styled.span`
  padding-left: 5px;
`;


export const StyledInputCheckBox = ({handleCheckbox,checked,value,label,type="checkbox",name,disabled,onChange,defaultChecked,helperText,onClick}) => (
  <CheckBoxLineStyle onClick={onClick}>
    <label style={{display:"block",maxWidth:"auto",marginBottom:"0",fontWeight:"normal",cursor:"pointer"}}>
      <input value={value} name={name} disabled={disabled} type={type} defaultChecked={defaultChecked} checked={checked} onChange={onChange}/>
      <CheckBoxLineText>{label}</CheckBoxLineText>
    </label>
    <StyledInputMessage>{helperText}</StyledInputMessage>
  </CheckBoxLineStyle>
);

export const StyledInputCheckBoxWithToolTip = ({handleCheckbox,checked,value,label,type="checkbox",name,disabled,onChange,defaultChecked,overlay}) => (
  <CheckBoxLineStyle>
    <StyledTooltip placement="bottom" trigger={['hover']} overlay={overlay}>
      <label style={{maxWidth:"auto",marginBottom:"0",fontWeight:"normal",cursor:"pointer"}}>
        <input value={value} name={name} disabled={disabled} type={type} defaultChecked={defaultChecked} checked={checked} onChange={onChange}/>
        <CheckBoxLineText>{label}</CheckBoxLineText>
      </label>
    </StyledTooltip>
  </CheckBoxLineStyle> 
);

export const StyledInputCheckBoxRadio = ({handleCheckbox,checked,value,label,type="radio",name,disabled,title,onChange,onBlur,onFocus,onClick,helperText}) => (
  <CheckBoxLineStyle onClick={handleCheckbox} title={title}>
    <label style={{display:"block",maxWidth:"auto",marginBottom:"0",fontWeight:"normal",cursor:"pointer"}}>
      <input value={value} name={name} disabled={disabled} type={type} checked={checked} onChange={onChange} onBlur={onBlur} onFocus={onFocus}/>
      <RadioLineText>{label}</RadioLineText>
    </label>
    <StyledInputMessage>{helperText}</StyledInputMessage>
  </CheckBoxLineStyle>
);


StyledInputBox.propType = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

StyledInputCheckBox.propType = {
  handleCheckbox:PropTypes.func,
  checked:PropTypes.bool,
  value:PropTypes.string,
  label:PropTypes.string,
  type:PropTypes.string,
  name:PropTypes.string,
  disabled: PropTypes.bool
};