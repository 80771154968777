import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  editTask,
  getTask,
  addAttachment,
  deleteAttachment,
  getAttachments,
  download,
  clearTask,
} from "actions/taskActions";
import { getUsersLocalizations } from "actions/localizationActions";
import { getUserSections } from "actions/sectionActions";
import { getSearchedUsers } from "actions/userActions";
import { acceptTask, confirmTask, closeTask } from "actions/taskActions";

import AppComponent from "components/AppComponent";
import TaskForm from "components/Task.Form";
import LoadingScreen from "components/LoadingScreen/";
import SideMenu from "./_SideMenu";

export class TaskEdit extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      localFetched: false,
      parentUrl: "/task",
      permisionNumber: 3,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.acceptTask = this.acceptTask.bind(this);
    this.confirmTask = this.confirmTask.bind(this);
    this.closeTask = this.closeTask.bind(this);
    this._getTask = this._getTask.bind(this);
    this.setMessege = this.setMessage.bind(this);
  }

  _getTask() {
    const { dispatch, match } = this.props;
    const { task_id } = match.params;
    return getTask(task_id)(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { task_id } = match.params;
    Promise.all([
      this._getTask(),
      getUsersLocalizations()(dispatch).then(
        this.handleServerResponse({}, () => {})
      ),
    ]).then(() => {
      const {
        singleData: { localization_id, users_name },
      } = this.props.task;
      const promises = [];
      if (localization_id) {
        promises.push(
          getUserSections(localization_id)(dispatch).then(
            this.handleServerResponse()
          )
        );
      }
      if (users_name) {
        promises.push(
          getSearchedUsers(users_name)(dispatch).then(
            this.handleServerResponse()
          )
        );
      }
      promises.push(
        getAttachments(task_id)(dispatch).then(this.handleServerResponse())
      );

      Promise.all(promises).then(() => {
        this.setState({ localFetched: true });
      });
    });
  }

  setMessage(message) {
    this.setState({
      message,
    });
  }

  acceptTask() {
    const { dispatch, match, history } = this.props;
    const { task_id } = match.params;
    const url = this.state.parentUrl;
    acceptTask(task_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(url);
      })
    );
  }

  confirmTask() {
    const { dispatch, match, history } = this.props;
    const { task_id } = match.params;
    const url = this.state.parentUrl;
    confirmTask(task_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(url);
      })
    );
  }

  closeTask() {
    const { dispatch, match, history } = this.props;
    const { task_id } = match.params;
    const url = this.state.parentUrl;
    closeTask(task_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(url);
      })
    );
  }

  handleSubmit(data) {
    const { dispatch, match, history } = this.props;
    const { task_id } = match.params;
    editTask(
      task_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/task");
      })
    );
  }

  render() {
    const {
      task: { singleData, permission, attachments, attachmentAddFetching },
      localization: { data: localizationData },
      section: { data: sectionData },
      match,
    } = this.props;
    const { task_id } = match.params;
    const { message } = this.state;
    let readOnly = !permission[this.state.permisionNumber];
    return (
      <LoadingScreen fetched={true}>
        <TaskForm
          task_id={task_id}
          SideMenu={SideMenu}
          permission={permission}
          readOnly={readOnly}
          error_code={this.state.error_code}
          handleSubmit={this.handleSubmit}
          localizations={localizationData}
          sections={sectionData}
          data={singleData}
          message={message}
          setMessage={this.setMessege}
          attachments={attachments}
          acceptTask={this.acceptTask}
          confirmTask={this.confirmTask}
          closeTask={this.closeTask}
          editView={!this.state.repeatMode}
          repeatView={this.state.repeatMode}
          addAttachment={addAttachment}
          deleteAttachment={deleteAttachment}
          attachmentAddFetching={attachmentAddFetching}
          downloadAction={download}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    clearTask()(this.props.dispatch);
  }
}

export default connect((store) => ({
  task: store.task,
  localization: store.localization,
  section: store.section,
}))(TaskEdit);
