import React, { Fragment } from "react";
import styled from "styled-components";

import ModalSchema from "components/Modal/./_SchemaAppComponent";
import Modal from "react-bootstrap/lib/Modal";
import { UserForm } from "components/UsersAccounts.Form/index";

const StyledModal = styled(Modal)`
  & .modal-dialog {
    width: 90%;
    max-width: 900px;
  }
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
export default class UserFormModal extends ModalSchema {
  handleSubmit() {}

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  render() {
    let { open } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Dane pracownika</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <UserForm
              acceptButtonName="Zapisz"
              boxTitle="Dodaj użytkownika"
              cancelFunction={() => {
                this.closeModal();
              }}
              create
              createView
              handleSubmit={this.handleSubmit}
              localizations={[]}
              modules={[]}
              parent_workplaces={[]}
              users={{}}
              workplaces={[]}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}
