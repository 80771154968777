import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";

import { getUserByWorkplaceByUserOnParentField } from "actions/userActions";
import { getUserSections } from "actions/sectionActions";
import { localRemoveUser } from "actions/notificationActions";
import {
  getAuditNotificationSchema,
  getAuditResponsivePerson,
} from "actions/auditActions";

import { LayoutContent } from "components/Layout";
import Box from "components/Box";
import DayPicker from "lib/DayPicker";
import Select from "components/Select";
import { ButtonWrapper } from "components/ListComponents";
import validator from "components/Validation";
import { LinkContainer } from "components/LinkContainer";
import Notification from "components/NotificationComponent";
import { FieldSelect, FieldDayPicker } from "components/Fields";

import { UserNotificationList } from "components/NotificationComponent/NotificationList";
import { StyledLabel } from "style/styleComponents/Form/input";
import Modal from "./Modal/SimpleAccept";

const StyledLabelExtend = styled(StyledLabel)`
  padding-top: 10px;
`;

const TwoInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5px;
  margin-bottom: 10px;
  & > * {
    flex-basis: 48%;
  }
`;

class AuditDataForm extends Notification {
  constructor(props) {
    super(props);
    this.state = { ...this.state, notification_group_id: 2 };
    this.handleLocalizationChange = this.handleLocalizationChange.bind(this);
    this.handleWorkplaceChange = this.handleWorkplaceChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSchemaNotificationIfCan = this.getSchemaNotificationIfCan.bind(
      this
    );
    this.getSchemaNotification = this.getSchemaNotification.bind(this);
  }

  componentDidMount() {
    const { dispatch, account, random } = this.props;
    if (random) {
      const { singleData } = account;
      const { id, admin, workplace_id, localization_id } = singleData;
      const that = this;
      const form = this.form;
      if (!admin) {
        getUserByWorkplaceByUserOnParentField(workplace_id)(dispatch).then(
          this.handleServerResponse({}, () => {
            that.getSchemaNotification(id, localization_id);
          })
        );
      }
    }
  }

  handleLocalizationChange(localization_id, form) {
    const { dispatch } = this.props;
    getUserSections(localization_id)(dispatch).then(
      this.handleServerResponse()
    );
    const that = this;
    if (!form.getFieldState("users_id").value) {
      getAuditResponsivePerson(localization_id)(dispatch).then(
        this.handleServerResponse({}, (action) => {
          const { responsiblePerson } = action.payload.data;
          if (responsiblePerson) {
            const { workplace_id, users_id } = responsiblePerson;
            form.getFieldState("workplace_id").change(workplace_id);
            getUserByWorkplaceByUserOnParentField(workplace_id)(dispatch).then(
              this.handleServerResponse({}, () => {
                form.getFieldState("users_id").change(users_id);
                that.getSchemaNotification(users_id, localization_id);
              })
            );
          }
        })
      );
    }
  }
  handleWorkplaceChange(workplace_id) {
    const { dispatch } = this.props;
    getUserByWorkplaceByUserOnParentField(workplace_id)(dispatch).then(
      this.handleServerResponse()
    );
  }

  getSchemaNotification(user_id, localization_id) {
    const { dispatch, random } = this.props;
    getAuditNotificationSchema(
      user_id,
      localization_id,
      random
    )(dispatch).then(this.handleServerResponse());
  }

  getSchemaNotificationIfCan(users_id, localization_id) {
    if (users_id && localization_id) {
      this.getSchemaNotification(users_id, localization_id);
    }
  }

  prepareData() {
    const {
      notification: { data: notificationData },
    } = this.props;
    let notification_group_id = this.state.notification_group_id;
    let users = [];
    if (notificationData && notificationData.length) {
      let { notification_group_id: ln, users: lu } = notificationData[0];
      notification_group_id = ln;
      users = lu;
    }

    return [].concat.apply(
      [],
      users.map((item) => {
        const { user_id, notifications } = item;

        const newNotification = notifications.map(
          ({
            notification_id,
            term,
            notification_instance_id,
            audit_automatic_notification,
          }) => {
            return {
              registered_notification_group_has_users_id: notification_instance_id,
              notification_id,
              term,
              audit_automatic_notification:
                audit_automatic_notification || undefined,
            };
          }
        );
        return {
          notification_group_id,
          users_id: user_id,
          notifications: newNotification,
        };
      })
    );
  }

  handleSubmit(values) {
    values.notifications = this.prepareData();
    this.props.submit(values);
  }

  handleDeleteUserFromGroup() {
    const { dispatch } = this.props;
    const {
      entry_id: notification_group_has_users_id,
      userIndex,
    } = this.state.modalUserDelete;
    localRemoveUser({ userIndex })(dispatch);
  }

  render() {
    const {
      modalNotificationDelete,
      modalUserDelete,
      message,
      dictionary_progress_id,
      moduleListUrl,
    } = this.state;
    const {
      permission,
      auditData = {},
      typeData,
      localizationData,
      sectionData,
      workplaceData,
      usersData,
      submit,
      register,
      readOnly,
      audit_id,
      SideMenu,
      notification: { data: notificationData, history },
    } = this.props;
    return (
      <LayoutContent
        error404={this.props.error_code}
        message={message}
        SideMenu={SideMenu}
      >
        <Box title="Zaplanuj audyt">
          <Form
            ref={(form) => {
              this.form = form;
            }}
            onSubmit={this.handleSubmit}
            initialValues={{
              date: moment().format("YYYY-MM-DD"),
              ...auditData,
            }}
            render={({ handleSubmit, pristine, invalid, values, form }) => {
              this.form = form;
              return (
                <form onSubmit={handleSubmit}>
                  <FieldDayPicker
                    name="date"
                    validate={validator.required}
                    label="Data audytu"
                    disabled={readOnly}
                  />

                  <Field
                    name="audit_type_id"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <Select
                        label="Rodzaj audytu"
                        disabled={readOnly}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        fullWidth
                        title="-- wybierz --"
                        data={
                          typeData.length
                            ? typeData.map((item) => ({
                                ...item,
                                name: item.title,
                              }))
                            : [
                                {
                                  id: null,
                                  name: "Nie znaleziono audytu do wyboru",
                                  disabled: true,
                                },
                              ]
                        }
                        propValue={"id"}
                        selected={input.value}
                        handleChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name="localization_id"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <Select
                        label="Lokacja"
                        disabled={readOnly}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        fullWidth
                        title="-- wybierz --"
                        data={
                          localizationData.length
                            ? localizationData
                            : [
                                {
                                  id: null,
                                  name: "Brak zdefiniowanych danych",
                                  disabled: true,
                                },
                              ]
                        }
                        propValue={"id"}
                        selected={input.value}
                        handleChange={(value) => {
                          this.handleLocalizationChange(value, form);
                          this.getSchemaNotificationIfCan(
                            values.users_id,
                            value
                          );
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name="section_id"
                    render={({ input, meta, ...rest }) => (
                      <Select
                        label="Dział"
                        disabled={readOnly}
                        fullWidth
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        title="-- wybierz --"
                        data={[{ id: "0", name: "Brak" }].concat(sectionData)}
                        propValue={"id"}
                        selected={input.value}
                        handleChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <StyledLabelExtend>
                    Osoba przeprowadzająca audyt
                  </StyledLabelExtend>
                  <TwoInputWrapper>
                    <Field
                      name="workplace_id"
                      render={({ input, meta, ...rest }) => (
                        <Select
                          label="Stanowisko"
                          disabled={readOnly}
                          fullWidth
                          title="-- wybierz --"
                          data={
                            workplaceData.length
                              ? workplaceData
                              : [
                                  {
                                    id: null,
                                    name: "Brak zdefiniowanych danych.",
                                    disabled: true,
                                  },
                                ]
                          }
                          propValue={"id"}
                          selected={input.value}
                          handleChange={(value) => {
                            this.handleWorkplaceChange(value);
                            input.onChange(value);
                          }}
                        />
                      )}
                    />
                    <Field
                      name="users_id"
                      validate={validator.required}
                      render={({ input, meta, ...rest }) => (
                        <Select
                          label="Osoba"
                          disabled={readOnly}
                          fullWidth
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : null
                          }
                          title="-- wybierz --"
                          data={
                            usersData.length
                              ? usersData
                              : [{ id: null, name: "Brak.", disabled: true }]
                          }
                          propValue={"id"}
                          selected={input.value}
                          handleChange={(value) => {
                            input.onChange(value);
                            this.getSchemaNotificationIfCan(
                              value,
                              values.localization_id
                            );
                          }}
                        />
                      )}
                    />
                  </TwoInputWrapper>

                  {notificationData.map((item) => (
                    <UserNotificationList
                      permission={permission[2]}
                      workplaces={workplaceData}
                      notification_group_id={this.state.notification_group_id}
                      handleAddNotification={this.handleAddNotification}
                      handleAddUserToGroup={this.handleAddUserToGroup}
                      handleDelete={this.handleModalOpen(
                        "modalNotificationDelete"
                      )}
                      handleDeleteUser={this.handleModalOpen("modalUserDelete")}
                      notifications={item.notifications}
                      name={"Ustawienia powiadomień"}
                      data={item.users}
                      readOnly
                    />
                  ))}

                  {dictionary_progress_id == 2 && !readOnly ? (
                    <ButtonWrapper>
                      <Button onClick={this.closeInstance}>
                        Zakończ i zamknij
                      </Button>
                    </ButtonWrapper>
                  ) : (
                    ""
                  )}

                  <ButtonWrapper>
                    {readOnly ? (
                      <LinkContainer to={`/audit/question/${audit_id}`}>
                        <Button bsStyle="primary">Dalej</Button>
                      </LinkContainer>
                    ) : (
                      <Button type="submit" bsStyle="primary">
                        {register
                          ? this.props.random
                            ? "Zapisz"
                            : "Zaplanuj audyt"
                          : "Dalej"}
                      </Button>
                    )}
                    <LinkContainer to={`/audit/`} exact>
                      <Button>Anuluj</Button>
                    </LinkContainer>
                  </ButtonWrapper>
                </form>
              );
            }}
          />
          <Modal
            title={modalNotificationDelete.title}
            description="Czy chcesz usunąć powiadomienie?"
            open={modalNotificationDelete.open}
            handleClose={this.handleModalClose("modalNotificationDelete")}
            handleAccept={this.handleDeleteNotification}
          />
          <Modal
            title={modalUserDelete.title}
            description="Czy chcesz usunąć użytkownika z grupy powiadomień?"
            open={modalUserDelete.open}
            handleClose={this.handleModalClose("modalUserDelete")}
            handleAccept={this.handleDeleteUserFromGroup}
          />
        </Box>
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  account: store.account,
  notification: store.notification,
  localization: store.localization,
  section: store.section,
  workplace: store.workplace,
  users: store.users,
}))(AuditDataForm);
