import React from "react";
import SideMenu from "components/SideMenu";
import { defaultUrls as urls } from "./_staticData";

const LSideMenu = ({
  stage,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <>
    <SideMenu
      title="Ocena ryzyka zawodowego"
      data={[
        { name: "Rejestr kart oceny ryzyka", url: "/risk-assessment" },
        { name: "Dodaj nową ocenę ryzyka", url: "/risk-assessment/basic" },
        {
          name: "Wzorcowe dokumentacje",
          url: "/risk-assessment/template-doc",
        },
        {
          name: "Wzorcowe zagrożenia",
          url: "/risk-assessment/model-hazards/list",
        },
        {
          name: "Listy kontrolne identyfikacji zagrożeń",
          url: "/risk-assessment/control/list",
        },
        { name: "Archiwum ORZ", url: "/risk-assessment/archive-orz/list" },
        {
          name: "Szablony metod oceny ryzyka",
          url: "/risk-assessment/ra-method",
        },
      ]}
    />
  </>
);

export default LSideMenu;
