import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import AppComponent from "components/AppComponent";

import moment from "moment";
import { Button, ButtonWithIconLoading } from "components/Buttons";
import { getLocalizationsOnly } from "actions/localizationActions";

import {
  getAccidentToRegister,
  registerAccident,
  addAttachment,
  addAttachmentRegister,
  deleteAttachment,
  getAttachments,
  getRegisterAttachments,
  download,
} from "actions/accidentOWTWActions";

import AttachmentList from "components/AttachmentList";
import Box from "components/Box";
import { ButtonWrapper, ButtonWithIcon } from "components/ListComponents";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";

import Select from "components/Select";
import SideMenu from "./_SideMenu";

import validator, { composeValidators } from "components/Validation/";
import WitnessesSection from "./components/WitnessSection";

import { FieldSelect, FieldInput } from "components/Fields";
import { TimeWrapper } from "container/Incident/DefaultForm";

import {
  StyledInputBox,
  StyledLabel,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";

let InputWrapper = styled.div`
  display: flex;
  margin: 0 -10px 10px;

  & > * {
    margin: 0 10px;
  }
`;

let getInitialValues = (props = []) => (data) => {
  return props.reduce((obj, key) => {
    obj[key] = data[key];
    return obj;
  }, {});
};

let getInitialValuesL = getInitialValues([
  "reporting_person",
  "type_id",
  "localization_id",
  "victim_fullname",
  "short_description",
  "date_event",
  "date_event_hour_minutes",
  "witnesses",
]);

class AccidentRegister extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      initialValues: {},
    };
    this.register = this.register.bind(this);
    this.setMessege = this.setMessege.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    Promise.all([
      getAccidentToRegister(id)(dispatch).then(this.handleServerResponse()),
      getLocalizationsOnly()(dispatch).then(this.handleServerResponse()),
      getRegisterAttachments(id)(dispatch).then(this.handleServerResponse()),
    ]).then(() => {
      let {
        match,
        accident: { singleData },
        localization: { data: localizationsData },
        dispatch,
      } = this.props;

      let initialValues = getInitialValuesL(singleData);
      initialValues.date_event_hour = moment(
        initialValues.date_event_hour
      ).format("HH");
      initialValues.date_event_minutes = moment(
        initialValues.date_event_minutes
      ).format("mm");
      this.setState({ initialValues: initialValues });
    });
  }

  register(values) {
    let { dispatch, history, match } = this.props;
    let { id } = match.params;
    let { date_event, date_event_hour, date_event_minutes, ...data } = values;
    data.date_event = moment(date_event)
      .set({
        hour: date_event_hour,
        minute: date_event_minutes,
      })
      .format("YYYY-MM-DD");
    registerAccident(
      id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/accident-owtw");
      })
    );
  }
  setMessege(message) {
    this.setState({
      message,
    });
  }

  render() {
    let { message, initialValues } = this.state;
    let {
      match,
      accident: {
        fetching,
        singleData,
        attachments,
        permission,
        atachments,
        attachmentAddFetching,
      },
      localization: { data: localizationsData },
      dispatch,
    } = this.props;
    const { id: accident_id } = match.params;
    let readOnly = !permission[9];
    let { dictionary_progress_id, reporting_person } = singleData;
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={
          <SideMenu accident_id={accident_id} stage={singleData.stage} />
        }
      >
        <Box title={"Zgłoszenie"}>
          <Form
            ref={(form) => (this.form = form)}
            initialValues={initialValues}
            onSubmit={this.register}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, pristine, invalid, form, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FieldInput
                    name="reporting_person"
                    label={"Imię i nazwisko osoby zgłaszającej zdarzenie"}
                    validate={validator.required}
                    disabled={readOnly}
                  />

                  <StyledLabel>Data i godzina zdarzenia</StyledLabel>
                  <TimeWrapper readOnly={readOnly} />
                  <Field
                    name="type_id"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <Select
                        fullwidth
                        label="Rodzaj wypadku"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        disabled={readOnly}
                        fullWidth
                        title="-- wybierz --"
                        data={[
                          {
                            id: 1,
                            name: "W drodze do pracy",
                          },
                          { id: 2, name: "W drodze z pracy" },
                        ]}
                        propValue={"id"}
                        selected={input.value}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                        handleChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <FieldSelect
                    validate={validator.required}
                    data={localizationsData}
                    disabled={readOnly}
                    name="localization_id"
                    label="Lokalizacja"
                  />
                  <Field
                    name="victim_fullname"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledInputBox
                        {...input}
                        label={"Imię i nazwisko poszkodowanego"}
                        disabled={readOnly}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                  <Field
                    name="short_description"
                    validate={validator.required}
                    render={({ input, meta, ...rest }) => (
                      <StyledTextareaBox
                        label="Krótki opis okoliczności i przebiegu wypadku"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : null
                        }
                        disabled={readOnly}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                        value={input.value}
                        onChange={(event) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    )}
                  />
                  <StyledLabel>Świadkowie</StyledLabel>
                  <FieldArray name="witnesses">
                    {({ fields }) => (
                      <div>
                        {fields.map((name, index) => (
                          <InputWrapper>
                            <Field
                              name={`${name}.full_name`}
                              validate={validator.required}
                              type="text"
                              render={({ input, meta, ...rest }) => (
                                <StyledInputBox
                                  disabled={readOnly}
                                  placeholder={"Imię i nazwisko świadka"}
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched
                                      ? meta.error
                                      : null
                                  }
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  value={
                                    (values.witnesses[index] &&
                                      values.witnesses[index].full_name) ||
                                    undefined
                                  }
                                  onChange={(event) => {
                                    input.onChange(event);
                                  }}
                                />
                              )}
                            />
                            <Button
                              onClick={() => {
                                fields.remove(index);
                              }}
                            >
                              <i className={"fa fa-trash"} />
                            </Button>
                          </InputWrapper>
                        ))}

                        <div>
                          <ButtonWrapper>
                            <Button
                              type="button"
                              disabled={readOnly}
                              onClick={() => {
                                fields.push({});
                              }}
                            >
                              Dodaj świadka
                            </Button>
                          </ButtonWrapper>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {permission[11] && (
                    <AttachmentList
                      dispatch={dispatch}
                      entry_id={accident_id}
                      attachments={attachments}
                      permission={permission}
                      addAttachmentAction={addAttachmentRegister}
                      attachmentAddFetching={attachmentAddFetching}
                      deleteAttachmentAction={deleteAttachment}
                      downloadAction={download}
                      setParentMessage={this.setMessege}
                    />
                  )}
                  <ButtonWrapper>
                    {readOnly ? (
                      <LinkContainer
                        to={`/accident-owtw/analysis/${accident_id}`}
                      >
                        <Button
                          type="submit"
                          bsStyle="primary"
                          disabled={dictionary_progress_id == 4}
                        >
                          Dalej
                        </Button>
                      </LinkContainer>
                    ) : (
                      <ButtonWithIconLoading
                        fetching={fetching}
                        type="submit"
                        bsStyle="primary"
                      >
                        {dictionary_progress_id == 4
                          ? "Wyślij zgłoszenie"
                          : "Dalej"}
                      </ButtonWithIconLoading>
                    )}
                  </ButtonWrapper>
                </form>
              );
            }}
          />
        </Box>
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  accident: store.accident_owtw,
  localization: store.localization,
}))(AccidentRegister);
