import React from 'react'
import {connect} from "react-redux"
import AppComponent from "../components/AppComponent";
import {LayoutContent} from "../components/Layout";
import {StyledInput, StyledInputBox, StyledTextareaBox} from "../style/styleComponents/Form/input";
import {Form, Field} from "react-final-form";
import {ButtonWrapper} from "../components/ListComponents";
import Button from "react-bootstrap/lib/Button";
import PageHeader from "../components/PageHeader";
import {getMails, getMailsGroup, setMail} from "../actions/mailActions";
import SideMenu from "../components/SideMenu";
import Alert from "react-bootstrap/lib/Alert";
import validator from "../components/Validation/";
import htmlParser from "../lib/HtmlToReactParse";
import ReactQuill from "../lib/ReactQuill/index";
class Mails extends AppComponent {

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      message_id: '',
      sideMenuData: [
        {name: "Wszystkie", onClick: this.getMailsOnClick(), url: "/super-admin/mails"},
        {id: 1,name: "Wiadomości ogólne", onClick: this.getMailsOnClick(1), url: "/super-admin/mails/1"},
        {id: 2,name: "Wiadomości powiadomień", onClick: this.getMailsOnClick(2), url: "/super-admin/mails/2"},
      ]
    };

    this.getMails = this.getMails.bind(this);
    this.getMailsOnClick = this.getMailsOnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {match} = this.props;
    const {mailing_group_id} = match.params;
    this.getMails(mailing_group_id);

  }

  getMails(mailing_group_id) {
    const {dispatch} = this.props;
    const that = this;
    if (mailing_group_id) {
      return getMailsGroup(mailing_group_id)(dispatch)
        .then(this.handleServerResponse({}, function () {
          that.setState({
            sideMenuData: that.state.sideMenuData.map(item => {
              return {...item,active: item.id==mailing_group_id};
            })
          });
        }));
    }
    else {
      return getMails()(this.props.dispatch)
        .then(this.handleServerResponse({}, function () {
          that.setState({
            sideMenuData: that.state.sideMenuData.map(item => {
              return {...item,active: item.id==mailing_group_id};
            })
          });
        }));
    }
  }

  getMailsOnClick(mailing_group_id) {
    return () => {
      return this.getMails(mailing_group_id);
    };
  }

  handleSubmit(id) {
    return (data) => {
      const {dispatch} = this.props;
      const that = this;
      this.setState({message_id: id}, () => {
        setMail(id, data)(dispatch)
          .then(this.handleServerResponse());
      });

    };
  }

  render() {
    const {message_id, message} = this.state;
    const {mail: {data}} = this.props;
    return (
      <LayoutContent SideMenu={<SideMenu
        title={`Wiadomości`}
        data={this.state.sideMenuData}
      />}>
        <PageHeader>Treści wiadomości e-mail</PageHeader>
        {data.map((item) => (
          <div className="page-header">
            <h4>{item.name}</h4>
            {message_id && message_id == item.id && message && message.text ?
              <Alert bsStyle={message.className}>{message.text}</Alert> : ''}
            <Form
              onSubmit={this.handleSubmit(item.id)}
              initialValues={{...item}}
              render={({handleSubmit, pristine, invalid}) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="subject"
                    validate={validator.required}
                    render={({input, meta, ...rest}) => (
                      <StyledInputBox
                        {...input}
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched ? meta.error : null}
                        value={input.value}
                        label="Tytuł"
                      />
                    )}
                  />
                  <Field
                    name="content"
                    validate={validator.required}
                    render={({input, meta, ...rest}) => (
                      <ReactQuill
                        {...input}
                        label="Treść"
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched ? meta.error : null}
                        onChange={value => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  />
                  <ButtonWrapper>
                    <Button type="submit" bsStyle="primary">Zapisz</Button>
                  </ButtonWrapper>
                </form>
              )}/>
            <div>
              Krótki opis zmiennych:
              {htmlParser(item.description)}
            </div>
          </div>

        ))}

      </LayoutContent>
    );
  }
}

export default connect(store => ({
  mail: store.mail
}))(Mails);