import React from "react";
import { connect } from "react-redux";

import { confirmTask, editTaskByAudit, getTask } from "actions/taskActions";
import { clearTask } from "actions/taskActions";
import { addTaskAttachment, deleteTaskAttachment } from "actions/auditActions";

import LoadingScreen from "components/LoadingScreen/";
import TaskForm from "components/Task.Form";

import { TaskEdit } from "container/Task/Edit";

import SideMenu from "../_SideMenu";
import {
  download,
} from "actions/taskActions";
class AuditTaskEdit extends TaskEdit {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { audit_id } = match.params;
    this.state = { ...this.state, parentUrl: `/audit/task/${audit_id}` };
  }

  handleSubmit(data) {
    const { dispatch, history, match } = this.props;
    const { audit_id, task_id } = match.params;

    editTaskByAudit(
      audit_id,
      task_id,
      data
    )(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/audit/task/${audit_id}`);
      })
    );
  }

  _getTask() {
    const { dispatch, match } = this.props;
    const { task_id } = match.params;
    return getTask(task_id)(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  confirmTask() {
    const { dispatch, match, history } = this.props;
    const { audit_id, task_id } = match.params;
    confirmTask(task_id)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push(`/audit/task/${audit_id}`);
      })
    );
  }

  render() {
    const {
      task: { singleData, permission , attachments},
      localization: { data: localizationData },
      section: { data: sectionData },
      match,
    } = this.props;
    const { audit_id,task_id} = match.params;
    const { message } = this.state;
    const LSideMenu = (props) => (
      <SideMenu permission={permission} audit_id={audit_id} />
    );

    return (
      <LoadingScreen fetched={true}>
        <TaskForm
          permission={permission}
          SideMenu={LSideMenu}
          noLocalization
          acceptTask={this.acceptTask}
          confirmTask={this.confirmTask}
          closeTask={this.closeTask}
          handleSubmit={this.handleSubmit}
          localizations={localizationData}
          sections={sectionData}
          data={singleData} 
          message={message}
          editView
          backUrl={`/audit/task/${audit_id}`} 
          parent={{
            url: `/audit/task/${audit_id}`,
            name: "name",
          }}
          attachments={attachments}

          audit_id={audit_id}
          task_id={task_id}
          setMessage={this.setMessege}
          addAttachment={addTaskAttachment}
          deleteAttachment={deleteTaskAttachment} 
          downloadAction={download}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount(){
    clearTask()(this.props.dispatch);
  } 
}

export default connect((store) => ({
  task: store.task,
  localization: store.localization,
  section: store.section,
}))(AuditTaskEdit);
