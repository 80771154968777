export const defaultUrls = {
  CERTIFICATELIST: "/medical-examination",
  CERTIFICATEADD: "/forms/certificate-templates/osh",
  CERTIFICATEEDIT: (id) => `/forms/certificate-templates/osh/${id}`,
};

const setUrlsR = (defaultUrls) => (urls) => {
  let u = typeof urls === "object" && urls !== null ? urls : {};
  return { ...defaultUrls, ...u };
};

export const setUrls = setUrlsR(defaultUrls);
