import React from "react";
import { connect } from "react-redux";

import { getHazard, saveHazard } from "actions/riskAssessmentAction";
import AppComponent from "components/AppComponent";
import Form from "components/ModelHazard/Form";

import SideMenu from "../_SideMenu";

import { setUrls } from "../_staticData.js";

class ModelHazardList extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      urls: setUrls(),
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    let { dispatch, match } = this.props;
    let { id } = match.params;

    getHazard(id, { readOnly: true })(dispatch).then(
      this.handleServerResponse()
    );
  }

  submit(data) {
    let { dispatch, history, match } = this.props;
    let { id } = match.params;
    saveHazard(id, data)(dispatch).then(this.handleServerResponse());
  }

  render() {
    let { message } = this.state;
    let { modelHazardItem, permission } = this.props;
    const readOnly =
      Boolean(modelHazardItem.business_id === 0) || !permission[30];
    return (
      <Form
        readOnly={readOnly}
        message={message}
        submit={this.submit}
        data={modelHazardItem}
        SideMenu={SideMenu}
      />
    );
  }
}

export default connect((store) => ({
  modelHazardItem: store.riskAssessment.singleData,
  message: store.message,
  permission: store.riskAssessment.permission,
}))(ModelHazardList);
