import React from "react";
import AppComponent from "components/AppComponent";
import { LayoutContent } from "components/Layout";
import QuickAccessButton, {
  QuickAccessButtonWrapper,
} from "components/QuickAccessButton";

import SideMenu from "./_SideMenu";

export default class Forms extends AppComponent {
  render() {
    return (
      <LayoutContent SideMenu={<SideMenu />}>
        <QuickAccessButtonWrapper>
          <QuickAccessButton exact to="/medical-examination/referral">
            Wystaw skierowanie na badania profilaktyczne
          </QuickAccessButton>
          <QuickAccessButton exact to="/forms/certificate-templates/osh">
            Wystaw zaświadczenie ze szkolenia BHP
          </QuickAccessButton>
          <QuickAccessButton exact to="/forms/certificate-templates">
            Wzory zaświadczeń
          </QuickAccessButton>
        </QuickAccessButtonWrapper>
      </LayoutContent>
    );
  }
}
