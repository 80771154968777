import React, { Fragment } from "react";
import styled from "styled-components";
import { Field } from "react-final-form";
import Select from "components/Select";
import { Width33 } from "./Fields.style";
import AutoComplete from "components/AutoCompleteInput";

import validator, { composeValidators } from "components/Validation";
import DayPicker from "lib/DayPicker";

import {
  StyledInputCheckBox,
  StyledInputBox,
  StyledLabel,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledInputMessage,
} from "style/styleComponents/Form/input";

import FieldArrayL from "./FieldArray";
import FieldTextEditorL from "./FieldTextEditor";
import { StyledInputCheckBoxWrapper } from "components/Wrappers";
export const FieldArrayComponent = FieldArrayL;
export const FieldTextEditor = FieldTextEditorL;

export const FieldInput = (props) => {
  let {
    label,
    name,
    disabled,
    validate,
    placeholder,
    type,
    onChange,
    onBlur,
  } = props;

  return (
    <Field
      name={name}
      validate={validate}
      subscription={{
        touched: true,
        error: true,
        value: true,
      }}
      render={({ input, meta, ...rest }) => (
        <Fragment>
          <StyledInputBox
            {...input}
            placeholder={placeholder}
            type={type}
            label={label}
            disabled={disabled}
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            onChange={(event) => {
              input.onChange(event.target.value);
              onChange && onChange(event.target.value);
            }}
            onBlur={onBlur}
            backgroundColor={props.backgroundColor}
          />
        </Fragment>
      )}
    />
  );
};
let LocalStyledInputBox = styled(StyledInputBox)`
  width: 33%;
`;

export const FieldInput33 = (props) => {
  let { label, name, disabled, validate, type } = props;
  return (
    <Fragment>
      <div>
        <StyledLabel>{label}</StyledLabel>
        <Field
          validate={validate}
          name={name}
          subscription={{
            touched: true,
            error: true,
            value: true,
          }}
          render={({ input, meta, ...rest }) => (
            <LocalStyledInputBox
              {...input}
              disabled={disabled}
              type={type}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              onChange={(event) => {
                input.onChange(event.target.value);
              }}
            />
          )}
        />
      </div>
    </Fragment>
  );
};

export const FieldSelectDitionary = (props) => {
  let {
    dictionary,
    disabled,
    label,
    name,
    fullValue = false,
    validate,
  } = props;
  return (
    <Field
      name={name}
      validate={validate}
      subscription={{
        touched: true,
        error: true,
        value: true,
      }}
      render={({ input, meta, ...rest }) => (
        <Select
          style={{ marginBottom: "10px" }}
          disabled={disabled}
          label={label}
          fullWidth
          propValue={"id"}
          helperText={meta.error && meta.touched ? meta.error : null}
          selected={input.value}
          data={dictionary}
          handleChange={(value) => {
            input.onChange(value);
          }}
        />
      )}
    />
  );
};

export const FieldSelect = (props) => {
  let {
    data,
    disabled,
    label,
    name,
    fullValue = false,
    validate,
    handleChange,
    title,
    propValue = "id",
  } = props;
  return (
    <Field
      name={name}
      validate={validate}
      subscription={{
        touched: true,
        error: true,
        value: true,
      }}
      render={({ input, meta, ...rest }) => (
        <Select
          style={{ marginBottom: "10px" }}
          disabled={disabled}
          label={label}
          fullWidth
          title
          propValue={propValue}
          helperText={meta.error && meta.touched ? meta.error : null}
          selected={input.value}
          data={data}
          handleChange={(value) => {
            if (handleChange) {
              handleChange(value, input);
            } else {
              input.onChange(value);
            }
          }}
        />
      )}
    />
  );
};

export const FieldSelectDitionary33 = (props) => {
  let { dictionary, disabled, label, name, validate } = props;
  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>

      <Field
        name={name}
        validate={validate}
        subscription={{
          touched: true,
          error: true,
          value: true,
        }}
        render={({ input, meta, ...rest }) => (
          <Select
            oneThirdButton
            disabled={disabled}
            fullWidth
            helperText={meta.error && meta.touched ? meta.error : null}
            propValue={"id"}
            selected={input.value}
            data={dictionary}
            handleChange={(value) => {
              input.onChange(value);
            }}
          />
        )}
      />
    </Fragment>
  );
};

export const FieldTextareaBox = (props) => {
  let {
    id,
    label,
    name,
    disabled,
    validate,
    placeholder,
    minHeight = "100px",
  } = props;
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta, ...rest }) => (
        <StyledTextareaBox
          id={id}
          label={label}
          placeholder={placeholder}
          value={input.value}
          error={meta.error && meta.touched}
          helperText={meta.error && meta.touched ? meta.error : null}
          minHeight={minHeight}
          disabled={disabled}
          onChange={(event) => {
            input.onChange(event.target.value);
          }}
        />
      )}
    />
  );
};

export const FieldInputCheckBox = (props) => {
  let { label, name, disabled, stringValue } = props;
  return (
    <Field
      name={name}
      render={({ input, meta, ...rest }) => {
        return (
          <StyledInputCheckBox
            {...input}
            value={stringValue ? input.value || "false" : input.value}
            checked={stringValue ? input.value == "true" : input.value}
            label={label}
            disabled={disabled}
            onChange={(e) => {
              console.log(e.target.checked);
              input.onChange(
                stringValue
                  ? e.target.checked
                    ? "true"
                    : "false"
                  : e.target.checked
              );
            }}
          />
        );
      }}
    />
  );
};

export const FieldDayPicker = (props) => {
  let { label, name, disabled, readOnly, validate, required } = props;

  return (
    <Fragment>
      <Field
        name={name}
        validate={validate}
        render={({ input, meta, ...rest }) => (
          <div style={{ marginBottom: "10px" }}>
            <DayPicker
              required={validate || required}
              canChangeYear={true}
              label={label}
              onFocus={input.onFocus}
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              fullWidth
              disabled={readOnly || disabled}
              selectedDay={input.value}
              handleChange={(value) => {
                input.onChange(value);
              }}
            />
          </div>
        )}
      />
    </Fragment>
  );
};

export const FieldDayPicker33 = (props) => {
  let { label, name, disabled, readOnly, validate, required } = props;

  return (
    <Fragment>
      <StyledLabel>{label}</StyledLabel>
      <Field
        name={name}
        validate={validate}
        render={({ input, meta, ...rest }) => (
          <Width33>
            <div style={{ marginBottom: "10px" }}>
              <DayPicker
                required={validate || required}
                canChangeYear={true}
                onFocus={input.onFocus}
                error={meta.error && meta.touched}
                helperText={meta.error && meta.touched ? meta.error : null}
                fullWidth
                disabled={readOnly || disabled}
                selectedDay={input.value}
                handleChange={(value) => {
                  input.onChange(value);
                }}
              />
            </div>
          </Width33>
        )}
      />
    </Fragment>
  );
};

export const FieldInputCheckBoxRadio = (props) => {
  let { valueProp = "value", inputs = [], name, disabled } = props;
  return (
    <Field
      name={name}
      validate={validator.required}
      render={({ input, meta, ...rest }) => (
        <div style={{ marginBottom: "5px" }}>
          <StyledInputCheckBoxWrapper>
            {inputs.map((item) => (
              <StyledInputCheckBoxRadio
                disabled={disabled || item.disabled}
                label={item.label}
                value={input.value}
                checked={input.value == item[valueProp]}
                onChange={(event) => {
                  input.onChange(item[valueProp]);
                }}
              />
            ))}
          </StyledInputCheckBoxWrapper>
          {/* {meta.error && meta.touched ? (
            <StyledInputMessage>{meta.error}</StyledInputMessage>
          ) : null} */}
        </div>
      )}
    />
  );
};
