const defaultState = {
  data: [],
  history: [],
  permission: {},
  singleData: {},
  fetching: false,
  fetched: true,
  sendFetching: false,
  sendFetched: false,
};

export default function reducer(state = {...defaultState}, action) {
  switch (action.type) {
    case "GET_NOTIFICATIONS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_NOTIFICATIONS_SUCCESS": {
      const { notification_groups, permission } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: notification_groups,
        permission,
      };
    }
    case "GET_NOTIFICATIONS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "ADD_NOTIFICATIONS_SUCCESS": {
      const {
        notification: { notification_group_id, users_id, ...rest },
      } = action.payload.data;
      const index = state.data.findIndex(
        (item) => item.notification_group_id == notification_group_id
      );
      if (index > -1) {
        const { users } = state.data[index];
        const userIndex = users.findIndex((item) => item.user_id == users_id);
        if (userIndex > -1) {
          const newUsers = [...users];
          newUsers[userIndex].notifications.push(rest);
          const newGroups = [...state.data];
          newGroups[index] = { ...newGroups[index], users: newUsers };
          return { ...state, fetching: false, fetched: false, data: newGroups };
        }
        return state;
      }
      return state;
    }
    case "ADD_USER_TO_NOTIFICATION_GROUP_SUCCESS": {
      const {
        user: { notification_group_id, ...rest },
      } = action.payload.data;
      const index = state.data.findIndex(
        (item) => item.notification_group_id == notification_group_id
      );
      if (index > -1) {
        const newGroups = [...state.data];
        newGroups[index].users.push(rest);
        return { ...state, data: newGroups };
      }
      return { ...state };
    }
    case "DELETE_USER_NOTIFICATION_SUCCESS": {
      const {
        notification: {
          notification_group_id,
          notification_instance_id,
          users_id,
        },
      } = action.payload.data;
      const index = state.data.findIndex(
        (item) => item.notification_group_id == notification_group_id
      );
      if (index > -1) {
        const { users } = state.data[index];
        const userIndex = users.findIndex((item) => item.user_id == users_id);
        const { notifications } = users[userIndex];
        const newNotifications = notifications.filter(
          (item) => item.notification_instance_id != notification_instance_id
        );
        const newUser = {
          ...users[userIndex],
          notifications: newNotifications,
        };
        const newUsers = [...users];
        newUsers[userIndex] = newUser;
        const newGroups = [...state.data];
        newGroups[index] = { ...newGroups[index], users: newUsers };
        return { ...state, fetching: false, fetched: false, data: newGroups };
      }
      return state;
    }
    case "DELETE_USER_FROM_NOTIFICATION_GROUP_SUCCESS": {
      const {
        notificationUserGroup: { notification_group_id, users_id },
      } = action.payload.data;
      const index = state.data.findIndex(
        (item) => item.notification_group_id == notification_group_id
      );
      if (index > -1) {
        const { users } = state.data[index];
        const newUsers = users.filter((item) => item.user_id != users_id);
        const newGroups = [...state.data];
        newGroups[index].users = newUsers;
        return { ...state, data: newGroups };
      }
      return state;
    }
    case "REMOVE_USER_FROM_NOTIFICATION": {
      const { notification_group_has_users_id } = action.payload;
      const usersArray = state.data[0].users;
      const newUsersArray = usersArray.filter(
        (user) =>
          user.notification_group_has_users_id !=
          notification_group_has_users_id
      );
      const newData = [...state.data];
      newData[0].users = newUsersArray;
      return { ...state, data: newData };
    }
    case "REMOVE_NOTIFICATION": {
      const {
        notification_group_has_users_id,
        notification_id,
      } = action.payload;
      const usersArray = state.data[0].users;
      const index = usersArray.findIndex((user) => {
        return (
          user.notification_group_has_users_id ==
          notification_group_has_users_id
        );
      });
      if (index > -1) {
        const newNotifications = usersArray[index].notifications.filter(
          (notification) => notification.id != notification_id
        );
        const newUsers = [...usersArray];
        newUsers[index].notifications = newNotifications;
        const newData = [...state.data];
        newData[0].users = newUsers;
        return { ...state, data: newData };
      }
      return { ...state };
    }
    case "LOCAL_REMOVE_NOTIFICATION": {
      const {
        notification_group_has_users_id,
        notification_id,
        userIndex,
        subIndex,
      } = action.payload;
      const usersArray = state.data[0].users;
      const index = userIndex;
      if (index > -1) {
        let newNotifications = [];
        if (notification_id) {
          newNotifications = usersArray[index].notifications.filter(
            (notification) => notification.id != notification_id
          );
        } else {
          const temp = usersArray[index].notifications;
          temp.splice(subIndex, 1);
          newNotifications = temp;
        }
        const newUsers = [...usersArray];
        newUsers[index].notifications = newNotifications;
        const newData = [...state.data];
        newData[0].users = newUsers;
        return { ...state, data: newData };
      }
      return state;
    }
    case "LOCAL_REMOVE_USER_FROM_NOTIFICATION": {
      const { userIndex } = action.payload;
      const newData = [...state.data];
      newData[0].users.splice(userIndex, 1);
      return { ...state, data: newData };
    }
    case "GET_NOTIFICATION_HISTORY_SUCCESS": {
      const { history } = action.payload.data;
      return { ...state, history };
    }
    case "REGISTER_NOTIFICATIONS_REQUEST": {
      return { ...state, sendFetching: true };
    }
    case "REGISTER_NOTIFICATIONS_SUCCESS": {
      return { ...state, sendFetching: false };
    }
    case "REGISTER_NOTIFICATIONS_ERROR": {
      return { ...state, sendFetching: false };
    }
    case "CLEAN_NOTIFICATION": {
      return defaultState;
    }
  }

  return state;
}