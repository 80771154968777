import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AppComponent from "components/AppComponent";
import { ButtonWrapper } from "components/Wrappers";
import ModalSchema from "components/Modal/./_Schema";
import { FieldInput } from "components/Fields";
import { TableListPage } from "components/Tables";

const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;

let Center = styled.div`
  display: flex;
  justify-content: center;
`;

function hexToRgb(hex) {
  if (!hex) return null;

  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

const getFontColorBaseOnBackgroundColor = (backgroundColor) => {
  if (!backgroundColor) return "black";
  const rgb = hexToRgb(backgroundColor);
  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) /
      1000
  );
  return brightness > 125 ? "black" : "white";
};

class AddTaskModalComponent extends ModalSchema {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      hazards: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.addRAAHTMEntry = this.addRAAHTMEntry.bind(this);
  }

  closeModal() {
    let { closeModal } = this.props;
    closeModal();
  }

  componentDidUpdate(prevProps) {
    let { data: prevData = [], hazards: prevHazards = [] } = prevProps || {};
    let { data = [], hazards = [] } = this.props;

    if (
      prevData.length != data.length ||
      prevHazards.length != hazards.length
    ) {
      let { hazards = [], data = [] } = this.props;
      let newHazards = hazards.filter((i) => {
        let index = data.findIndex((si) => {
          return si.ra_analysis_hazard_id == i.id;
        });
        return index < 0;
      });

      this.setState({ hazards: newHazards });
    }
  }

  addRAAHTMEntry(id) {
    let {
      addRAAHTMEntry = () => {
        alert("mock");
      },
    } = this.props;
    addRAAHTMEntry(id, () => {
      this.closeModal();
    });
  }

  render() {
    let { hazards } = this.state;
    let { open } = this.props;

    return (
      <div className="static-modal">
        <StyledModal show={open} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <ModalTitleWrapper>
                <span>Dodaj działanie</span>
                <Button
                  bsStyle="danger"
                  id="ModalListClose"
                  onClick={this.closeModal}
                >
                  X
                </Button>
              </ModalTitleWrapper>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit }) => {
                return (
                  <form>
                    <TableListPage>
                      <thead>
                        <th>Nazwa</th>
                        <th>Ryzyko</th>
                        <th>Zaplanuj działania ograniczające ryzyko</th>
                      </thead>
                      <tbody>
                        {hazards.map((item) => (
                          <tr>
                            <td>{item.name}</td>
                            <td
                              style={{
                                backgroundColor: item.parameters1_result_color,
                                color: getFontColorBaseOnBackgroundColor(
                                  item.parameters1_result_color
                                ),
                              }}
                            >
                              Wartość R: {item.parameters1_result_value} $
                              {item.parameters1_result_description}
                            </td>
                            <td>
                              <Center>
                                <Button
                                  onClick={() => {
                                    this.addRAAHTMEntry(item.id);
                                  }}
                                >
                                  Zaplanuj
                                </Button>
                              </Center>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableListPage>
                    <ButtonWrapper>
                      <Button>Wybierz</Button>
                    </ButtonWrapper>
                  </form>
                );
              }}
            />
          </Modal.Body>
        </StyledModal>
      </div>
    );
  }
}

export default class AddTaskModalContainer extends AppComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, open: false };
  }

  render() {
    let { open } = this.state;
    let { readOnly } = this.props;
    return (
      <>
        <Button
          disabled={readOnly}
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          Dodaj działanie
        </Button>
        <AddTaskModalComponent
          {...this.props}
          open={open}
          closeModal={() => {
            this.setState({ open: false });
          }}
        />
      </>
    );
  }
}
