import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../AppComponent";
import { LayoutContent } from "../../Layout";
import Box from "../../Box";

import styled from "styled-components";
import {
  StyledInputCheckBoxRadio,
  StyledLabel,
  StyledInputBox,
} from "../../../style/styleComponents/Form/input";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Button from "react-bootstrap/lib/Button";
import dataToFormData from "../../dataToFormData";
import { ButtonWrapper } from "../../ListComponents";
import { LinkContainer } from "../../LinkContainer";
import saveBlobFile from "../../saveBlobFile";
import { ButtonWithIcon } from "../../ListComponents";
import QuestionLine from "./_questionLine";
import Modal from "../../Modal/SimpleAccept";
import PDFModal, { PDFViewModalSchema } from "../../Modal/PDFViewModal";
import ImageModal, { ImageViewModalSchema } from "../../Modal/ImageViewModal";

export default class QuestionsForm extends AppComponent {
  constructor(props) {
    super(props);
  }

  handleSubmit(values) {}
}
