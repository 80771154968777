import moment from "moment";

let defaultState = {
  answers: [],
  attachments: [],
  questionsAttachments: {},
  controlList: [],
  controlListThreads: [],
  questions: [],
  data: [],
  raDraftList: [],
  raControlList: [],
  modelHazardList: [],
  methods: [],
  permission: {},
  singleData: {},
  raMethod: {},
  raMethodEntry: {},
  raHazards: [],
  raTM: [],
  raWorkers: [],
};

let dataList = (data) => {
  return data.map((i) => {
    return {
      ...i,
      date_execution: i.date_execution
        ? moment(i.date_execution).format("YYYY-MM-DD")
        : i.date_execution,
      date_update: i.date_update
        ? moment(i.date_update).format("YYYY-MM-DD")
        : i.date_update,
    };
  });
};

export default function reducer(state = defaultState, action) {
  function markIfCopied(controlListThreads, raHazards) {
    return controlListThreads.map((i) => {
      let r = raHazards.find((h) => h.ra_control_list_question_id == i.id);
      if (r) {
        i.copy = true;
      } else {
        i.copy = false;
      }
      return i;
    });
  }

  const autoIncrementer = function () {
    let value = 0;

    return {
      get value() {
        value++;
        return value;
      },
    };
  };

  const decorateRaHazardWithLP = function (items) {
    let ai = autoIncrementer();

    return items.map((i) => {
      if (!i.category) {
        i.lp = ai.value;
      }

      return i;
    });
  };

  switch (action.type) {
    case "DELETE_RA_METHOD_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        methods: state.methods.filter(
          (item) => item.id != action.payload.data.data.id
        ),
      };
    }
    case "GET_RA_METHODS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_RA_METHODS_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        methods: data,
        permission,
      };
    }
    case "GET_RA_METHODS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_RA_METHODS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_RA_METHOD_SUCCESS": {
      const { data, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        raMethod: data,
        permission,
      };
    }
    case "GET_RA_METHOS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }

    case "GET_RA_METHOD_ENTRY_SUCCESS": {
      const { data, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        raMethodEntry: data,
        permission,
      };
    }

    case "GET_RA_LIST_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }

    case "GET_RA_LIST_SUCCESS": {
      let { data, permission = {} } = action.payload.data;
      data = dataList(data);

      return { ...state, fetching: false, fetched: true, data, permission };
    }
    case "GET_RA_LIST_DRAFT_SUCCESS": {
      let { data, permission = {} } = action.payload.data;
      data = dataList(data);

      return {
        ...state,
        fetching: false,
        fetched: true,
        raDraftList: data,
        permission,
      };
    }

    case "GET_RA_LIST_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }

    case "GET_RA_ITEM_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }

    case "GET_RA_ITEM_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: data,
        permission,
      };
    }

    case "GET_RA_ITEM_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "DELETE_RA_SUCCESS": {
      const { data } = action.payload.data;
      let newData = [...state.data].filter((i) => i.id != data.id);

      return { ...state, data: newData };
    }
    case "DELETE_RA_DRAFT_SUCCESS": {
      const { data } = action.payload.data;
      // let newData = [...state.data].filter((i) => i.id != data.id);
      let newRaDraftList = [...state.raDraftList].filter(
        (i) => i.id != data.id
      );
      return { ...state, raDraftList: newRaDraftList };
    }

    case "GET_MODEL_HAZARD_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;
      return { ...state, modelHazardList: data, permission };
    }

    case "GET_MODEL_HAZARD_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return { ...state, singleData: data, raHazard: data, permission };
    }

    case "DELETE_MODEL_HAZARD_SUCCESS": {
      const { data } = action.payload.data;
      let newData = [...state.modelHazardList].filter((i) => i.id != data.id);
      return { ...state, modelHazardList: newData };
    }

    case "GET_CONTROL_LIST_LIST_SUCCESS": {
      const { data, permission = {} } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        controlList: data,
        permission,
      };
    }

    case "GET_CONTROL_LIST_ITEM_SUCCESS": {
      const { data, permission = {} } = action.payload.data;
      let { name: title, ...rest } = data;
      let newData = { ...data, title };

      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: newData,
        permission,
      };
    }

    case "DELETE_CONTROL_LIST_SUCCESS": {
      const { data } = action.payload.data;
      let newData = [...state.controlList].filter((i) => i.id != data.id);
      return { ...state, controlList: newData };
    }

    case "GET_RA_CONTROL_LIST_SUCCESS": {
      const { data, permission } = action.payload.data;
      return { ...state, raControlList: data, permission };
    }

    case "GET_RA_CONTROL_LIST_QUESTIONS_SUCCESS": {
      const { questions, answers } = action.payload.data;
      return { ...state, questions, answers };
    }

    case "DELETE_RA_CONTROL_LIST_SUCCESS": {
      const { data } = action.payload.data;
      let newData = [...state.raControlList].filter((i) => i.id != data.id);
      return { ...state, raControlList: newData };
    }

    case "ADD_RA_ATTACHMENT_LOCAL": {
      let data = action.payload;
      let { id } = data;

      let newQuestionsData = { ...state.questionsAttachments };

      let newAttachments = state.questionsAttachments[id] || [];

      let ids = newAttachments.map((item) => item.id);
      ids.push(0);
      let lastId = Math.max.apply(null, ids);

      let fileFulldata = {
        id: lastId + 1,
        name: data.name,
        fileData: data.attachment,
      };
      newAttachments.push(fileFulldata);

      return {
        ...state,
        newAttachments: newAttachments,
        attachments: newAttachments,
      };

      return { ...state };
    }

    case "DELETE_RA_ATTACHMENT_LOCAL": {
      return { ...state };
    }

    case "ADD_RACL_QUESTION_ATTACHMENT_SUCCESS": {
      let { attachment } = action.payload.data;
      let {
        id,
        parent_id,
        file,
        ra_control_list_question_id: question_id,
        url,
        name,
      } = attachment;
      let enchantQuestion = (questions, parent_id) => {
        let newQuestions = [...questions];
        if (parent_id) {
          let parentQuestionIndex = newQuestions.findIndex(
            (i) => i.id == parent_id
          );
          let question = newQuestions[parentQuestionIndex];
          question.questions = enchantQuestion(question.questions);
        } else {
          let questionIndex = newQuestions.findIndex(
            (i) => i.id == question_id
          );
          let question = newQuestions[questionIndex];
          if (!Array.isArray(question.attachments)) question.attachments = [];
          question.attachments.push({ ...attachment, name: name || file });
        }

        return newQuestions;
      };

      let newQuestions = enchantQuestion(state.questions, parent_id);

      return { ...state, questions: newQuestions };
    }
    case "DELETE_RACL_QUESTION_ATTACHMENT_SUCCESS": {
      let { attachment } = action.payload.data;
      let {
        id,
        parent_id,
        file,
        ra_control_list_question_id: question_id,
        url,
        name,
      } = attachment;

      let enchantQuestion = (questions, parent_id) => {
        let newQuestions = [...questions];
        if (parent_id) {
          let parentQuestionIndex = newQuestions.findIndex(
            (i) => i.id == parent_id
          );
          let question = newQuestions[parentQuestionIndex];
          question.questions = enchantQuestion(question.questions);
        } else {
          let questionIndex = newQuestions.findIndex(
            (i) => i.id == question_id
          );
          let question = newQuestions[questionIndex];
          question.attachments = question.attachments.filter((i) => i.id != id);
        }

        return newQuestions;
      };

      let newQuestions = enchantQuestion(state.questions, parent_id);

      return { ...state, questions: newQuestions };
    }
    case "GET_RA_ANALYSIS_HAZARD_SUCCESS": {
      let { data } = action.payload.data;
      let newControlListThreads = markIfCopied(state.controlListThreads, data);
      let raHazards = decorateRaHazardWithLP(data);

      return { ...state, raHazards, controlListThreads: newControlListThreads };
    }
    case "ADD_RA_ANALYSIS_HAZARD_SUCCESS": {
      let { data } = action.payload.data;
      let newData = [...state.raHazards];
      newData.push(data);
      let newControlListThreads = markIfCopied(
        state.controlListThreads,
        newData
      );
      newData = decorateRaHazardWithLP(newData);

      return {
        ...state,
        raHazards: newData,
        controlListThreads: newControlListThreads,
      };
    }
    case "DELETE_RA_ANALYSIS_HAZARD_SUCCESS": {
      let { data } = action.payload.data;
      let newData = [...state.raHazards].filter((i) => i.id != data.id);
      let newControlListThreads = markIfCopied(
        state.controlListThreads,
        newData
      );
      newData = decorateRaHazardWithLP(newData);

      return {
        ...state,
        raHazards: newData,
        controlListThreads: newControlListThreads,
      };
    }
    case "CHANGE_ORDER_RA_ANALYSIS_HAZARD": {
      let sortingArr = action.payload.request.data;

      let newRaHazard = state.raHazards.sort(
        (a, b) => sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id)
      );

      return { ...state, raHazards: newRaHazard };
    }

    case "GET_RA_AHTM_ENTRY_SUCCESS": {
      let { data } = action.payload.data;

      return { ...state, raTM: data };
    }
    case "ADD_RA_AHTM_ENTRY_SUCCESS": {
      let { data } = action.payload.data;
      let newRATM = [...state.raTM];
      newRATM.push(data);
      return { ...state, raTM: newRATM };
    }

    case "GET_RA_WORKERS_SUCCESS": {
      let { data } = action.payload.data;

      return { ...state, raWorkers: data };
    }

    case "ADD_RA_WORKERS_SUCCESS": {
      let { data } = action.payload.data;
      let newRaWorkers = [...state.raWorkers];
      newRaWorkers.push(data);
      return { ...state, raWorkers: newRaWorkers };
    }

    case "DELETE_RA_WORKERS_SUCCESS": {
      let { data } = action.payload;
      let newRaWorkers = [...state.raWorkers];
      newRaWorkers = newRaWorkers.filter((i) => {
        return i.id != data.id;
      });
      return { ...state, raWorkers: newRaWorkers };
    }

    case "GET_RA_PERMISSION_SUCCESS": {
      let { permission } = action.payload.data;
      return { ...state, permission };
    }

    case "COPY_RISK_ASSESSMENT_SUCCESS": {
      let { data } = action.payload.data;
      let newData = [...state.data];
      newData.push(data);
      newData = dataList(newData);
      return { ...state, data: newData };
    }
    case "SAVE_RISK_ASSESSMENT_DRAFT_ERROR": {
    }

    case "SAVE_RA_ANALYSIS_HAZARD_SUCCESS": {
      let { data } = action.payload.data;

      let newRaHazard = [...state.raHazards];
      const index = newRaHazard.findIndex((i) => {
        return i.id === data.id;
      });
      newRaHazard.splice(index, 1, data);
      return {
        ...state,
        raHazards: newRaHazard,
      };
    }

    default: {
      return state;
    }
    case "GET_RA_CONTROL_LIST_CONFIRMED_THREATS_SUCCESS": {
      let { data } = action.payload.data;
      return { ...state, controlListThreads: data };
    }

    case "CLEAR_RISK_ASSESSMENT": {
      return defaultState;
    }
  }

  return state;
}
