let defaultState = {
  data: [],
  parentWorkplaces: [],
  searchedWorkplaces: [],
  singleData: {},
  fetching: false,
  fetched: true,
  parentWorkplacesFetched: true,
  professionCodes: [],
  workplace_permissions: [],
};

let denest = (values) => {
  let newValues = [];
  for (let item of values) {
    let { children, ...rest } = item;
    newValues.push(rest);
    if (children) {
      let orphanedChildren = denest(children).map((orphan) => {
        orphan.name = "\u00A0\u00A0" + orphan.name;
        return orphan;
      });
      newValues = newValues.concat(orphanedChildren);
    }
  }

  return newValues;
};

export default function reducer(state = { ...defaultState }, action) {
  switch (action.type) {
    case "GET_WORKPLACES_REQUEST": {
      return { ...state, fetching: true };
    }
    case "GET_WORKPLACES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.workplaces,
      };
    }
    case "GET_WORKPLACES_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_WORKPLACES_WITHOUT_TRIM_SUCCESS": {
      let workplaces = action.payload.data.workplaces;

      let denest = (values) => {
        let newValues = [];
        for (let item of values) {
          let { children, ...rest } = item;
          newValues.push(rest);
          if (children) {
            let orphanedChildren = denest(children).map((orphan) => {
              orphan.name = orphan.name;
              return orphan;
            });
            newValues = newValues.concat(orphanedChildren);
          }
        }

        return newValues;
      };

      return {
        ...state,
        fetching: false,
        fetched: true,
        data: denest(workplaces),
      };
    }
    case "GET_WORKPLACES_WITHOUT_SUCCESS": {
      let workplaces = action.payload.data.workplaces;

      let denest = (values) => {
        let newValues = [];
        for (let item of values) {
          let { children, ...rest } = item;
          newValues.push(rest);
          if (children) {
            let orphanedChildren = denest(children).map((orphan) => {
              orphan.name = "\u00A0\u00A0" + orphan.name;
              return orphan;
            });
            newValues = newValues.concat(orphanedChildren);
          }
        }

        return newValues;
      };

      return {
        ...state,
        fetching: false,
        fetched: true,
        data: denest(workplaces),
      };
    }
    case "GET_WORKPLACES_DENESTED_SUCCESS": {
      let workplaces = action.payload.data.workplaces;

      let denest = (values) => {
        let newValues = [];
        for (let item of values) {
          let { children, ...rest } = item;
          newValues.push(rest);
          if (children) {
            let orphanedChildren = denest(children).map((orphan) => {
              orphan.name = "\u00A0\u00A0" + orphan.name;
              return orphan;
            });
            newValues = newValues.concat(orphanedChildren);
          }
        }

        return newValues;
      };

      return {
        ...state,
        fetching: false,
        fetched: true,
        data: denest(workplaces),
      };
    }
    case "DELETE_WORKPLACES_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data.workplaces,
      };
    }
    case "GET_WORKPLACE_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_WORKPLACE_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        singleData: action.payload.data.workplace,
      };
    }
    case "GET_WORKPLACE_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }

    case "GET_PARENT_WORKPLACES_WITHOUT_REQUEST": {
      return { ...state, fetching: false, parentWorkplacesFetched: false };
    }
    case "GET_PARENT_WORKPLACES_WITHOUT_SUCCESS": {
      return {
        ...state,
        fetching: false,
        parentWorkplacesFetched: true,
        parentWorkplaces: action.payload.data.workplaces,
      };
    }
    case "GET_SEARCHED_WORKPLACES_SUCCESS": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newSearchedWorkplaces = [...state.searchedWorkplaces];
      newSearchedWorkplaces[index] = action.payload.data.workplaces;
      return { ...state, searchedWorkplaces: newSearchedWorkplaces };
    }
    case "LOCAL_SET_DEFAULT_WORKPLACE_SEARCHED": {
      const { index, workplaces } = action.payload;
      const newSearchedWorkplaces = [...state.searchedWorkplaces];
      newSearchedWorkplaces[index] = workplaces;
      return { ...state, searchedWorkplaces: newSearchedWorkplaces };
    }
    case "GET_INITIAL_SEARCH_WORKPLACES_SUCCESS": {
      const { workplaces = [] } = action.payload.data;
      return { ...state, searchedWorkplaces: workplaces };
    }
    case "GET_WORKPLACE_PERMISSION_SUCCESS": {
      let workplace_permissions = action.payload.data;
      return { ...state, workplace_permissions };
    }

    // "GET_WORKPLACES_TREE_REQUEST",
    // "GET_WORKPLACES_TREE_SUCCESS",
    // "GET_WORKPLACES_TREE_ERROR",
    case "GET_WORKPLACES_TREE_SUCCESS": {
      const { workplaces = [] } = action.payload.data;
      return { ...state, data: denest(workplaces) };
    }

    case "CLEAR_WORKPLACE": {
      return {
        ...defaultState,
      };
    }
  }

  return state;
}
