import React from "react";
import { connect } from "react-redux";
import AppComponent from "../../components/AppComponent";
import LocalizationForm from "../../components/Localization.Form";
import {
  addLocalization,
  getBusinessMainataToLocalization,
  clearLocationReducer,
} from "actions/localizationActions";

class AddLocalization extends AppComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getBusinessData = this.getBusinessData.bind(this);
  }

  handleSubmit(data) {
    const { dispatch, history } = this.props;
    addLocalization(data)(dispatch).then(
      this.handleServerResponse({}, function () {
        history.push("/business/localization");
      })
    );
  }

  getBusinessData(callback) {
    const { dispatch, match } = this.props;
    const { localization_id } = match.params;
    getBusinessMainataToLocalization()(dispatch).then(
      this.handleServerResponse({}, (action) => {
        callback(action.payload.data.business);
      })
    );
  }

  render() {
    const { message } = this.state;
    const { localization, dispatch } = this.props;
    const { singleData: base } = localization;
    return (
      <div>
        <LocalizationForm
          dispatch={dispatch}
          title={"Dodaj lokację"}
          createView={true}
          handleSubmit={this.handleSubmit}
          base={base}
          sections={[]}
          alert={message}
          getBusinessData={this.getBusinessData}
        />
      </div>
    );
  }

  componentWillMount() {
    let { dispatch } = this.props;
    clearLocationReducer()(dispatch);
  }
}

export default connect((store) => ({
  localization: store.localization,
  section: store.section,
  message: store.message,
}))(AddLocalization);
