import React from "react";
import { connect } from "react-redux";
import { getUsersLocalizations } from "actions/localizationActions";

import { getUserSections } from "actions/sectionActions";
import { pingTask } from "actions/taskActions";
import {
  addTask,
  addTaskAttachmentLocal,
  deleteTaskAttachmentLocal,
  clearTask,
} from "actions/taskActions";
import LoadingScreen from "components/LoadingScreen/";
import SideMenu from "./_SideMenu";

import dataToFormData from "components/dataToFormData";
import AppComponent from "components/AppComponent";
import TaskForm from "components/Task.Form";
export class TaskAdd extends AppComponent {
  constructor(props) {
    super(props);
    this.loadSections = this.loadSections.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._pingTask = this._pingTask.bind(this);
  }

  _pingTask() {
    const { dispatch } = this.props;
    pingTask()(dispatch).then(this.handleServerResponse());
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this._pingTask();
    getUsersLocalizations()(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  loadSections(localization_id) {
    const { dispatch } = this.props;
    getUserSections(localization_id)(dispatch).then(
      this.handleServerResponse({}, () => {})
    );
  }

  handleSubmit(data) {
    const { dispatch, history, task } = this.props;
    const { attachments } = task;
    data.section_id = data.section_id != "0" ? data.section_id : null;
    for (let attachment of attachments) {
      data[`task_new_attachment_file_${attachment.id}`] = attachment.fileData;
      data[`task_new_attachment_name_${attachment.id}`] = attachment.name;
    }
    const formData = dataToFormData(data);
    addTask(formData)(dispatch).then(
      this.handleServerResponse({}, () => {
        history.push("/task");
      })
    );
  }

  render() {
    const {
      localization: { data: localizationsData, fetched },
      section: { data: sectionsData },
      task: { permission, attachments },
    } = this.props;

    return (
      <LoadingScreen fetched={fetched}>
        <TaskForm
          attachments={attachments}
          message={this.state.message}
          permission={permission}
          error_code={this.state.error_code}
          data={{
            priority: 2,
          }}
          handleSubmit={this.handleSubmit}
          localizations={localizationsData}
          loadSections={this.loadSections}
          sections={sectionsData}
          createView
          addAttachment={addTaskAttachmentLocal}
          deleteAttachment={deleteTaskAttachmentLocal}
          SideMenu={SideMenu}
        />
      </LoadingScreen>
    );
  }

  componentWillUnmount() {
    clearTask()(this.props.dispatch);
  }
}

export default connect((store) => ({
  localization: store.localization,
  section: store.section,
  task: store.task,
}))(TaskAdd);
