import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Tooltip from "react-bootstrap/lib/Tooltip";
import { bootstrapUtils } from "react-bootstrap/lib/utils/index";
import PropTypes from "prop-types";
bootstrapUtils.addStyle(Tooltip, "calendar-hover");

export const StyledDay = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 35px;
  border-left: solid 1px #cccccc;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;

  background-color: ${(props) =>
    props.hasEvents ? props.theme.primaryColor : "inherit"};
  color: ${(props) =>
    props.hasEvents ? props.theme.primaryTextColor : "inherit"};

  &:hover {
    background: ${(props) => props.theme.primaryLighterColor};
    color: ${(props) => props.theme.primaryTextColor};
  }

  &:first-child {
    border-left: none;
  }
  &.today {
    background: #dceaf8;
  }
  &.different-month {
    color: #c0c0c0;
    &:hover {
      color: ${(props) => props.theme.primaryTextColor};
    }
  }

  &.selected {
    background: ${(props) => props.theme.primaryDarknessColor};
    color: #ffffff;
  }
`;

const StyledDay2 = StyledDay.extend`
  position: relative;
`;

const StyledDayEvent = styled.div`
  position: absolute;
  left: 50%;
  bottom: 7px;
  color: #000000;
`;

const EventLink = styled(Link)`
  display: block;
  margin-bottom: 5px;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

class Day extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      events: [],
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  render() {
    const {
      day,
      day: { date, isCurrentMonth, isToday, number },
      select,
      selected,
      events = [],
    } = this.props;

    return (
      <StyledDay2
        key={date.toString()}
        className={
          "day" +
          (isToday ? " today" : "") +
          (isCurrentMonth ? "" : " different-month") +
          (date.isSame(selected) ? " selected" : "")
        }
        hasEvents={!!events.length}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {number}
        {this.state.hover && events.length ? (
          <StyledDayEvent>
            <Tooltip
              placement="bottom"
              className="in"
              id="tooltip-right"
              bsStyle="calendar-hover"
            >
              {events.map((item, index) => (
                <EventLink to={item.url} key={index}>
                  {item.name}
                </EventLink>
              ))}
            </Tooltip>
          </StyledDayEvent>
        ) : null}
      </StyledDay2>
    );
  }
}

Day.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default Day;
