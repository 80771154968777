const defaultState = {
  data: [],
  newAttachments: [],
  attachments: [],
  singleData: {},
  progresses: [],
  permission: {},
  fetching: false,
  fetched: true,
}; 
export default function reducer(
  state = { ...defaultState }, 
  action
) {
  switch (action.type) {
    case "GET_TASKS_REQUEST": {
      return { ...state, fetching: true, fetched: false };
    }
    case "GET_TASKS_SUCCESS": {
      const { tasks, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: tasks,
        permission,
      };
    }
    case "GET_TASKS_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "GET_TASK_REQUEST": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_TASK_SUCCESS": {
      const { task, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: false,
        singleData: task,
        permission,
      };
    }
    case "GET_TASK_ERROR": {
      return { ...state, fetching: false, fetched: true };
    }
    case "DELETE_TASK_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: state.data.filter(
          (item) => item.id != action.payload.data.task.id
        ),
      };
    }
    case "GET_PROGRESS_SUCCESS": {
      return { ...state, progresses: action.payload.data.progresses };
    }
    case "GET_AUDIT_QUESTIONS_TASK_SUCCESS": {
      const { taskPermission = {} } = action.payload.data;
      return { ...state, permission: taskPermission };
    }
    case "GET_TASK_ATTACHMENTS_REQUEST": {
      return { ...state, fetching: false, fetched: false };
    }
    case "GET_TASK_ATTACHMENTS_SUCCESS": {
      const { attachments, permission = {} } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: false,
        attachments: attachments,
        permission,
      };
    }
    case "GET_TASK_ATTACHMENTS_ERROR": {
      return { ...state, fetching: false, fetched: false };
    }
    case "DELETE_TASK_ATTACHMENT_SUCCESS": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        attachments: state.attachments.filter(
          (item) => item.id != action.payload.data.attachment.id
        ),
      };
    }
    case "ADD_TASK_ATTACHMENT_REQUEST": {
      return { ...state, attachmentAddFetching: true };
    }
    case "ADD_TASK_ATTACHMENT_SUCCESS": {
      const newAttachment = [...state.attachments];
      newAttachment.push(action.payload.data.attachment);
      return {
        ...state,
        attachments: newAttachment,
        attachmentAddFetching: false,
      };
    }
    case "ADD_TASK_ATTACHMENT_ERROR": {
      return { ...state, attachmentAddFetching: false };
    }
    case "ADD_TASK_ATTACHMENT_LOCAL": {
      const data = action.payload;
      let getId;
      // business_id: 93;
      // fileName: "adsasda.pdf";
      // id: 3;
      // name: "adsasda";
      // task_id: 794;
      // url: "/uploads/task/794/attachment/bcfc65303996e4f30c82b22a0502ef01.pdf";
      let newAttachments = [...state.newAttachments];
      let ids = newAttachments.map((item) => item.id);
      ids.push(0);
      let lastId = Math.max.apply(null, ids);

      let fileFulldata = {
        id: lastId + 1,
        name: data.name,
        fileData: data.attachment,
      };
      newAttachments.push(fileFulldata);
      return {
        ...state,
        newAttachments: newAttachments,
        attachments: newAttachments,
      };
    }
    case "DELETE_TASK_ATTACHMENT_LOCAL": {
      const { attachment_id } = action.payload;
      let newAttachments = [...state.attachments];
      newAttachments = newAttachments.filter((item) => {
        return item.id != attachment_id;
      });

      return {
        ...state,
        newAttachments: newAttachments,
        attachments: newAttachments,
      };
    }
    case "PING_TASK_SUCCESS": {
      const data = action.payload;
      const { permission } = data.data;
      return {
        ...state,
        permission,
      };
    }
    case "DOWNLOAD_TASK_ATTACHMENT_REQUEST": {
      const { index } = action.payload.request.data;
      const newAttachments = [...state.attachments];
      newAttachments[index].fetching = true;
      return { ...state, attachments: newAttachments };
    }
    case "DOWNLOAD_TASK_ATTACHMENT_SUCCESS": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newAttachments = [...state.attachments];
      newAttachments[index].fetching = false;
      return { ...state, attachments: newAttachments };
    }
    case "DOWNLOAD_TASK_ATTACHMENT_ERROR": {
      const { index } = action.meta.previousAction.payload.request.data;
      const newAttachments = [...state.attachments];
      newAttachments[index].fetching = false;
      return { ...state, attachments: newAttachments };
    }
    case "CLEAR_TASK":{
      return defaultState;
    }
  }

  return state;
}
