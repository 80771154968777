import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import AppComponent from "../../components/AppComponent";
import DivisionForm from "../../components/Localization.Division.Form";
import {addDivision,getDivisionsLocalization} from "../../actions/localizationActions";
import LoadingScreen from "../../components/LoadingScreen";

class AddDivision extends AppComponent {

  constructor(props){
    super(props);
    this.state = {...this.state};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount(){
    this.updateMessage();
    getDivisionsLocalization()(this.props.dispatch)
      .then(this.handleServerResponse({},()=>{}));
  }

  handleSubmit(data){
    const {dispatch,history} = this.props;
    addDivision(data)(dispatch)
      .then(this.handleServerResponse({},function () {
        history.push("/business/localization");
      }));
  }

  render() {
    const {localization} = this.props;
    const options = localization.data.map(item=>({
      id:item.id,value:item.name
    }));
    const selectedOptions = localization.data.filter(item=>item.selected).map(item=>item.id);
    const data={
      name: "",
      localizations: selectedOptions
    };

    return (
      <LoadingScreen fetched={localization.fetched}>
        <DivisionForm
          title={"Dodaj dywizję"}
          data={data}
          options={options}
          handleSubmit={this.handleSubmit}
        />
      </LoadingScreen>);
  }
}

export default connect(store=>({
  localization: store.localization,
  message: store.message
}))(AddDivision);