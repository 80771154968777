import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LayoutContent } from "./Layout";
import Box from "./Box";
import { Form, Field } from "react-final-form";
import { StyledInputBox } from "../style/styleComponents/Form/input";
import InputClone from "./InputClone";
import Button from "react-bootstrap/lib/Button";
import { media } from "../style/style-utils";
import { bootstrapUtils } from "react-bootstrap/lib/utils";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import AppComponent from "./AppComponent";
import {
  getSpeculateSymbol,
  checkSlugAvailable,
} from "../actions/localizationActions";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import validator from "./Validation";
import { composeValidators } from "./Validation";
import Message from "../messages/messages.json";
import Modal from "./Modal/SimpleAccept";
import { LinkContainer } from "./LinkContainer";
import { ButtonWrapper } from "./ListComponents";
import ProfileImage from "./ProfileImage";
import dataToFormData from "./dataToFormData";
bootstrapUtils.addStyle(Button, "localization-inputclone-delete");

const LocalRow = styled.div`
  margin-bottom: 30px;
`;

const NameRow = LocalRow.extend`
  max-width: 530px;
  width: 100%;
`;

const ButtonRow = LocalRow.extend`
  display: flex;
  justify-content: flex-end;
`;

const MainWrapper = styled.div`
  ${media.lg`
    display: flex;
    justify-content: space-between; 
    & > div { 
      width: 48%;
    }
  `}
`;

const StyledTitle = styled.div`
  margin-bottom: 15px;
  ${media.sm`
    padding-top:15px
  `}
`;

const AddButton = (props) => (
  <Button className="fa fa-plus" onClick={props.onClick}></Button>
);
const DeleteButton = (props) => (
  <Button className="fa fa-minus" onClick={props.onClick}></Button>
);

const InputWrapper = styled.div`
  display: flex;
  margin: 0 -10px;
  align-items: flex-start;
  & > * {
    margin: 10px;
  }
  & > div {
    max-width: 530px;
    flex-basis: 80%;
  }
`;

const CustomInput1 = ({ button, input, index, onChange }) => {
  return (
    <InputWrapper key={index}>
      <StyledInputBox
        name={`section[${input.id || 0}]`}
        value={input.value}
        onChange={onChange}
      />
      {button}
    </InputWrapper>
  );
};

const CustomInput = ({ button, input, index, onChange, handleDelete }) => {
  return (
    <Field
      name={
        input && input.id
          ? `section_${input.id}`
          : `section_new[${input.number}]`
      }
      render={({ input, meta, ...rest }) => (
        <InputWrapper key={index}>
          <StyledInputBox
            id={index}
            {...input}
            onChange={(event) => {
              input.onChange(event.target.value);
            }}
          />
          <Button
            onClick={handleDelete(function () {
              input.onChange(null);
            })}
          >
            <i className={"fa fa-minus"} />
          </Button>
        </InputWrapper>
      )}
    />
  );
};

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg) => {
    if (!lastArg || arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

const Child = ({ setRef }) => <input type="text" ref={setRef} />;

let setInitialValues = (base = {}, sections = []) => {
  return { ...base, section: sections };
};

export default class LocalizationForm extends AppComponent {
  constructor(props) {
    super(props);
    this.checkSlug = this.checkSlug.bind(this);

    this.state = {
      ...this.state,
      modal: {
        open: false,
        entry_id: null,
        entry_name: null,
        callback: () => {},
      },
      initialValues: setInitialValues(),
      modalLogoDelete: {
        open: false,
        handleOpen: this.handleOpenModal.bind(this)("modalLogo"),
        handleClose: this.handleCloseModal.bind(this)("modalLogo"),
      },
      slug: {
        value: "ABC",
        available: false,
      },
    };
    this.getNewSymbol = this.getNewSymbol.bind(this);
    this.checkSlug = this.checkSlug.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.handleModalAccept = this.handleModalAccept.bind(this);
    this.setRef = this.setRef.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleGetBusinessData = this.handleGetBusinessData.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { section: psections = [], ...pbase } = this.state.initialValues;
    const { base = {}, sections = [], createView } = this.props;
    if (
      !createView &&
      (Object.keys(base).length !== Object.keys(pbase).length ||
        sections.length !== psections.length)
    ) {
      this.setState({ initialValues: setInitialValues(base, sections) });
    }
  }

  handleOpenModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: true } });
    };
  }

  handleCloseModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: false } });
    };
  }

  submit(data) {
    const { handleSubmit } = this.props;
    const section = Object.keys(data)
      .filter((item) => /^section_[0-9]$/.test(item))
      .reduce((obj, key) => {
        obj[key.split("_")[1]] = data[key];
        return obj;
      }, {});
    const { name, address, slug, section_new = [] } = data;
    const newData = {
      name,
      address,
      slug,
      section: section,
      section_new: section_new.filter((i) => i),
    };
    handleSubmit(data);
  }

  getNewSymbol(value, form) {
    return () => {
      const { dispatch } = this.props;
      getSpeculateSymbol({ name: value })(dispatch).then(
        this.handleServerResponse({}, (action) => {
          const { speculatedSlug } = action.payload.data;
          if (!form.getFieldState("slug").value) {
            form.change("slug", speculatedSlug);
          }
        })
      );
    };
  }

  modalOpen(name, callback) {
    return () => {
      this.setState({
        modal: {
          ...this.state.modal,
          open: true,
          entry_id: false,
          entry_name: name,
          callback,
        },
      });
    };
  }

  handleGetBusinessData(form) {
    return () => {
      this.props.getBusinessData(function (data) {
        Object.keys(data).forEach((key) => {
          form.change(key, data[key]);
        });
      });
    };
  }

  handleModalAccept() {
    const { callback } = this.state.modal;
    callback();
    this.modalClose();
  }

  modalClose() {
    this.setState({
      modal: {
        ...this.state.modal,
        open: false,
        entry_id: false,
        entry_name: null,
        callback: () => {},
      },
    });
  }

  async checkSlug(value) {
    const { dispatch, localization_id } = this.props;

    if (!value) {
      return Message["validation_message.required"];
    } else if (!/^([^\x00-\x7F]|\w){3}$/.test(value)) {
      return Message["validation_message.localization_slug_length"];
    } else if (value == this.state.slug.value) {
      return this.state.slug.available
        ? undefined
        : Message["validation_message.localization_slug_not_available"];
    } else {
      const { available } = await new Promise((resolve, reject) => {
        checkSlugAvailable({ slug: value, localization_id })(dispatch).then(
          this.handleServerResponse({}, (action) => {
            resolve(action.payload.data);
          })
        );
      });

      this.state.slug = { value, available };

      return available
        ? undefined
        : Message["validation_message.localization_slug_not_available"];
    }
  }

  setRef(input) {
    this.childInput = input;
  }

  render() {
    const { modal } = this.state;
    const { title, base = {}, sections = [], alert = {} } = this.props;
    const newSection = sections.map((i) => ({ id: i.id, value: i.name }));
    const initialSections = newSection.reduce((obj, item) => {
      obj[`section_${item.id}`] = item.value;
      return obj;
    }, {});
    //TODO Must Listening for External Changes
    return (
      <LayoutContent>
        {alert && alert.text && (
          <Col lg={12}>
            <Alert bsStyle={alert.className}>{alert.text}</Alert>
          </Col>
        )}
        <Box title={title}>
          <Form
            onSubmit={this.submit.bind(this)}
            mutators={{ ...arrayMutators }}
            initialValues={this.state.initialValues}
            render={({ handleSubmit, pristine, invalid, form }) => {
              this.form = form;
              return (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <MainWrapper>
                    <div>
                      <NameRow>
                        <Field
                          name="name"
                          validate={validator.required}
                          render={({ input, meta, ...rest }) => (
                            <StyledInputBox
                              {...input}
                              label={"Nazwa lokacji"}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                              onBlur={this.getNewSymbol(input.value, form)}
                              onChange={(event) => {
                                input.onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                        <Field
                          name="address"
                          validate={validator.required}
                          render={({ input, meta, ...rest }) => (
                            <StyledInputBox
                              {...input}
                              label={"Adres"}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                              onChange={(event) => {
                                input.onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                        <Field
                          name="slug"
                          ref={(slug) => {
                            this.slugInput = slug;
                          }}
                          validate={composeValidators(
                            validator.required,
                            this.checkSlug
                          )}
                          render={({ input, meta, ...rest }) => (
                            <StyledInputBox
                              value={input.value}
                              label={"Skrót literowy"}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                              onChange={(event) => {
                                input.onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                      </NameRow>
                      <LocalRow>
                        <StyledTitle>Działy</StyledTitle>
                        <FieldArray name="section">
                          {({ fields }) => (
                            <div>
                              {fields.map((name, index) => {
                                const field = fields.value[index];
                                return (
                                  <InputWrapper>
                                    <Field
                                      name={`${name}.name`}
                                      validate={validator.required}
                                      render={({ input, meta, ...rest }) => (
                                        <StyledInputBox
                                          value={input.value}
                                          error={meta.error && meta.touched}
                                          helperText={
                                            meta.error && meta.touched
                                              ? meta.error
                                              : null
                                          }
                                          onChange={(event) => {
                                            input.onChange(event.target.value);
                                          }}
                                        />
                                      )}
                                    />
                                    <Button
                                      onClick={() =>
                                        this.modalOpen(field.name, () => {
                                          fields.remove(index);
                                        })()
                                      }
                                    >
                                      <i className={"fa fa-minus"} />
                                    </Button>
                                  </InputWrapper>
                                );
                              })}
                              <Button onClick={() => fields.push({})}>
                                <i className={"fa fa-plus"} />
                              </Button>
                            </div>
                          )}
                        </FieldArray>
                      </LocalRow>
                    </div>
                    <div>
                      <h4>Dane firmy</h4>
                      <Field
                        name="full_name"
                        validate={validator.required}
                        render={({ input, meta, ...rest }) => (
                          <StyledInputBox
                            {...input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="Pełna nazwa firmy"
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                      <Field
                        name="main_address"
                        validate={validator.required}
                        render={({ input, meta, ...rest }) => (
                          <StyledInputBox
                            {...input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="Adres siedziby głównej"
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                      <Field
                        name="nip"
                        validate={validator.required}
                        render={({ input, meta, ...rest }) => (
                          <StyledInputBox
                            {...input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="NIP"
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                      <Field
                        name="regon"
                        validate={validator.required}
                        render={({ input, meta, ...rest }) => (
                          <StyledInputBox
                            {...input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="REGON"
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                      <Field
                        name="pkd"
                        validate={validator.required}
                        render={({ input, meta, ...rest }) => (
                          <StyledInputBox
                            {...input}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            label="Kod PKD"
                            onChange={(event) => {
                              input.onChange(event.target.value);
                            }}
                          />
                        )}
                      />
                      {this.props.getBusinessData && (
                        <ButtonWrapper>
                          <Button
                            type="button"
                            onClick={this.handleGetBusinessData(form)}
                          >
                            Pobierz dane firmy
                          </Button>
                        </ButtonWrapper>
                      )}
                    </div>
                  </MainWrapper>
                  <ButtonWrapper>
                    <Button type="submit" bsStyle="primary">
                      Zapisz
                    </Button>
                    <LinkContainer to={"/business/localization"} exact>
                      <Button>Anuluj</Button>
                    </LinkContainer>
                  </ButtonWrapper>
                </form>
              );
            }}
          />
        </Box>

        <Modal
          open={modal.open}
          title={`Usuń wydział${
            modal.entry_name ? ` - ${modal.entry_name}` : ""
          }.`}
          description={"Czy na pewno chcesz usunąć dany wpis? "}
          handleAccept={this.handleModalAccept}
          handleClose={this.modalClose}
        />
        <Modal
          open={this.state.modalLogoDelete.open}
          title="Usuń awatar"
          description="Czy chcesz usunąć logo firmy?"
          handleClose={this.handleCloseModal("modalAvatarDelete")}
          handleAccept={this.handleDeleteLogoRequest}
        />
      </LayoutContent>
    );
  }
}

LocalizationForm.propTypes = {
  title: PropTypes.string,
  base: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    letter_abbreviation: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
    })
  ),
  handleSubmit: PropTypes.func,
};
