import React from "react";
import SideMenu from "components/SideMenu";
import { defaultUrls as urls } from "./_staticData";
import UpMenu from "../_SideMenu";

const LSideMenu = ({
  id,
  stage = 0,
  permission = {},
  addNewIncident,
  dictionary_progress_id = 4,
}) => (
  <div>
    <UpMenu />
    <SideMenu
      title="Dodaj nową ocenę ryzyka"
      data={[
        {
          name: "Informacje ogólne",
          url: id ? urls.BASIC(id) : urls.ADD,
          notExact: true,
        },
        {
          disabled: !id,
          name: "Identyfikacji zagrożeń",
          url: urls.DANGER(id),
          notExact: true,
        },
        {
          disabled: !id,
          name: "Charakterystyka",
          url: urls.CHARACTERISTIC(id),
          notExact: true,
        },
        {
          disabled: !id,
          name: "Analiza ryzyka",
          url: urls.ANALYSIS(id),
          notExact: true,
        },
        {
          disabled: !id,
          notExact: true,
          name: "Działania zmniejszające ryzyko",
          url: urls.TASK(id),
        },
        {
          disabled: !id,
          notExact: true,
          name: "Powiadomienia",
          url: urls.NOTIFICATION(id),
        },
      ]}
    />
  </div>
);

export default LSideMenu;
