import React from "react";
import styled from "styled-components";

const EmployeesListLabel = styled.div`
  margin-bottom: 5px;
`;

const EmployeesListBox = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 15px;
`;

const EmployeesList = (props) => (
  <div>
    {props.label && (
      <EmployeesListLabel id="EmployeesListTitle">
        {props.label}
      </EmployeesListLabel>
    )}
    <EmployeesListBox id="EmployeesListBody">
      {(props.value || []).map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </EmployeesListBox>
  </div>
);

export default EmployeesList;
